// BulkColumnEdit.js

import React, { useState, useEffect } from 'react';
import Select from 'react-select/creatable';
import Modal from 'react-modal';
import Papa from 'papaparse';
import {
  Box,
  Button,
  TextField,
  Typography,
  Grid,
  Paper,
  Snackbar,
  Alert,
  Chip,
  InputAdornment,
} from '@mui/material';
import PropTypes from 'prop-types';
import {
  doc,
  setDoc,
  onSnapshot,
} from 'firebase/firestore';
import { db } from './firebaseConfig';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import ColumnSettingsManager from './ColumnSettingsManager'; // インポートを追加

// モーダルのアクセシビリティ設定
Modal.setAppElement('#root');

function BulkColumnEdit({
  data = [],
  setData,
  initialData,
  setInitialData,
  columns = [],
  setColumns,
  userId,
  setIsDataModified,
  columnOrderTemplates,
  setColumnOrderTemplates,
  applyColumnTemplate,
  selectedTemplateName,
  setSelectedTemplateName,
  setColumnOrder,
  columnWidth,
  setColumnWidth,
}) {
  // ステート変数の定義
  const [selectedColumn, setSelectedColumn] = useState('');
  const [newValue, setNewValue] = useState('');
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'info',
  });

  // 削除ワード関連のステート
  const [error, setError] = useState(null);
  const [deleteWords, setDeleteWords] = useState([]); // 削除ワードのステート
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [rowsToDelete, setRowsToDelete] = useState([]);
  const [deletionLog, setDeletionLog] = useState([]);
  const [titleIndex, setTitleIndex] = useState(-1);
  const [jpTitleIndex, setJpTitleIndex] = useState(-1);
  const [jpDescIndex, setJpDescIndex] = useState(-1); // jp_descのインデックス
  const [isDeletionLogOpen, setIsDeletionLogOpen] = useState(false);
  const [isManageModalOpen, setIsManageModalOpen] = useState(false); // 管理モーダルのステート
  const [importText, setImportText] = useState(''); // 一括インポート用のローカルステート

  // 検索機能のステート
  const [searchQuery, setSearchQuery] = useState(''); // 削除ワード検索用
  const [filteredDeleteWords, setFilteredDeleteWords] = useState([]); // フィルタされた削除ワード

  // テンプレート関連のステート
  const [templates, setTemplates] = useState([]); // テンプレートのリスト
  const [selectedTemplateNameLocal, setSelectedTemplateNameLocal] = useState(''); // 選択されたテンプレート名（ローカル）
  const [newTemplateName, setNewTemplateName] = useState(''); // 新しいテンプレート名

  // Effect hooks
  useEffect(() => {
    if (data && data.length > 0) {
      const headers = columns;
      setTitleIndex(headers.findIndex((header) => header.toLowerCase() === 'title'));
      setJpTitleIndex(headers.findIndex((header) => header.toLowerCase() === 'jp_title'));
      setJpDescIndex(headers.findIndex((header) => header.toLowerCase() === 'jp_desc'));
    }
  }, [data, columns]);

  // テンプレートをFirestoreから読み込む
  useEffect(() => {
    if (!userId) return;

    const docRef = doc(db, 'users', userId, 'templates', 'deleteWordTemplates');
    const unsubscribe = onSnapshot(docRef, (docSnap) => {
      if (docSnap.exists()) {
        setTemplates(docSnap.data().templates || []);
      }
    });

    return () => unsubscribe();
  }, [userId]);

  // 削除ワードをFirestoreから読み込む
  useEffect(() => {
    if (!userId) return;

    const docRef = doc(db, 'users', userId, 'deleteWords', 'words');
    const unsubscribe = onSnapshot(docRef, (docSnap) => {
      if (docSnap.exists()) {
        const wordsArray = docSnap.data().words || [];
        const formattedWords = wordsArray.map((word) => ({ value: word, label: word }));
        setDeleteWords(formattedWords);
      }
    });

    return () => unsubscribe();
  }, [userId]);

  // 選択されたテンプレート名をFirestoreから読み込む
  useEffect(() => {
    if (!userId) return;

    const docRef = doc(db, 'users', userId, 'selectedTemplate', 'name');
    const unsubscribe = onSnapshot(docRef, (docSnap) => {
      if (docSnap.exists()) {
        setSelectedTemplateNameLocal(docSnap.data().selectedTemplateName || '');
      }
    });

    return () => unsubscribe();
  }, [userId]);

  // テンプレートの変更をFirestoreに保存
  useEffect(() => {
    if (!userId) return;
    if (templates.length === 0) return;

    const saveTemplates = async () => {
      try {
        const docRef = doc(db, 'users', userId, 'templates', 'deleteWordTemplates');
        await setDoc(docRef, { templates }, { merge: true });
      } catch (error) {
        console.error('Error saving templates to Firestore:', error);
        setError('テンプレートの保存に失敗しました。');
      }
    };
    saveTemplates();
  }, [templates, userId]);

  // 削除ワードの変更をFirestoreに保存
  useEffect(() => {
    if (!userId) return;
    if (deleteWords.length === 0) return;

    const wordsArray = deleteWords.map((word) => word.value);

    const saveDeleteWords = async () => {
      try {
        const docRef = doc(db, 'users', userId, 'deleteWords', 'words');
        await setDoc(docRef, { words: wordsArray }, { merge: true });
      } catch (error) {
        console.error('Error saving delete words to Firestore:', error);
        setError('削除ワードの保存に失敗しました。');
      }
    };
    saveDeleteWords();
  }, [deleteWords, userId]);

  // 選択されたテンプレート名をFirestoreに保存
  useEffect(() => {
    if (!userId) return;
    if (!selectedTemplateNameLocal) return;

    const saveSelectedTemplateName = async () => {
      try {
        const docRef = doc(db, 'users', userId, 'selectedTemplate', 'name');
        await setDoc(docRef, { selectedTemplateName: selectedTemplateNameLocal }, { merge: true });
      } catch (error) {
        console.error('Error saving selected template name to Firestore:', error);
        setError('選択されたテンプレート名の保存に失敗しました。');
      }
    };
    saveSelectedTemplateName();
  }, [selectedTemplateNameLocal, userId]);

  // テンプレートの適用
  useEffect(() => {
    if (selectedTemplateNameLocal && templates.length > 0) {
      const template = templates.find((t) => t.name === selectedTemplateNameLocal);
      if (template) {
        // テンプレートを適用
        const formattedWords = template.words.map((word) => ({
          value: word,
          label: word,
        }));
        setDeleteWords(formattedWords);
      } else {
        // テンプレートが見つからない場合、選択を解除
        setSelectedTemplateNameLocal('');
      }
    }
  }, [selectedTemplateNameLocal, templates]);

  // 削除ワードの検索・フィルタリング
  useEffect(() => {
    if (searchQuery.trim() === '') {
      setFilteredDeleteWords(deleteWords);
    } else {
      const lowerQuery = searchQuery.toLowerCase();
      setFilteredDeleteWords(
        deleteWords.filter((word) =>
          word.value.toLowerCase().includes(lowerQuery)
        )
      );
    }
  }, [searchQuery, deleteWords]);

  // 削除ワード機能の関数

  const splitTitleIntoWords = (title) => {
    if (!title) return [];
    return title.split(/\s+/).filter(Boolean);
  };

  const handleDeleteWordsChange = (newWords) => {
    setDeleteWords(newWords || []);
  };

  const handleDeleteRowsByWord = () => {
    if (deleteWords.length === 0) {
      setError('削除したいワードを入力してください。');
      return;
    }

    if (data.length === 0) {
      setError('データがありません。');
      return;
    }

    try {
      if (titleIndex === -1 && jpTitleIndex === -1 && jpDescIndex === -1) {
        setError('"Title" カラム、"jp_title" カラム、または "jp_desc" カラムが見つかりません。');
        return;
      }

      const words = deleteWords.map((word) => word.value).filter((w) => w);

      if (words.length === 0) {
        setError('有効な削除ワードがありません。');
        return;
      }

      const wordsLower = words.map((word) => word.toLowerCase());

      const rowsToDeleteTemp = [];
      const deletionLogTemp = [];

      for (let i = 0; i < data.length; i++) {
        const row = data[i];
        const titleCell = titleIndex !== -1 ? row[columns[titleIndex]] || '' : '';
        const jpTitleCell = jpTitleIndex !== -1 ? row[columns[jpTitleIndex]] || '' : '';
        const jpDescCell = jpDescIndex !== -1 ? row[columns[jpDescIndex]] || '' : '';

        // タイトルと説明を空白で分割して単語の配列を取得
        const titleWords = splitTitleIntoWords(titleCell);
        const jpTitleWords = splitTitleIntoWords(jpTitleCell);
        const jpDescWords = splitTitleIntoWords(jpDescCell);

        const titleWordsLower = titleWords.map((word) => word.toLowerCase());
        const jpTitleWordsLower = jpTitleWords.map((word) => word.toLowerCase());
        const jpDescWordsLower = jpDescWords.map((word) => word.toLowerCase());

        // 削除ワードとタイトルおよび説明内の単語を比較
        const titleMatches = wordsLower.some((word) => titleWordsLower.includes(word));
        const jpTitleMatches = wordsLower.some((word) => jpTitleWordsLower.includes(word));
        const jpDescMatches = wordsLower.some((word) => jpDescWordsLower.includes(word));

        if (titleMatches || jpTitleMatches || jpDescMatches) {
          rowsToDeleteTemp.push(i);
          deletionLogTemp.push({
            rowIndex: i,
            rowData: row,
          });
        }
      }

      if (rowsToDeleteTemp.length === 0) {
        setError('指定したワードを含む行は見つかりませんでした。');
        return;
      }

      rowsToDeleteTemp.sort((a, b) => b - a);

      setRowsToDelete(rowsToDeleteTemp);
      setDeletionLog((prevLog) => [...prevLog, ...deletionLogTemp]);
      setIsConfirmOpen(true);
      setError(null);
    } catch (err) {
      console.error('Error preparing rows for deletion:', err);
      setError('行の削除準備中にエラーが発生しました');
    }
  };

  const confirmDeleteRows = () => {
    try {
      let newData = [...data];
      for (let rowIndex of rowsToDelete) {
        newData.splice(rowIndex, 1);
      }
      setData(newData);
      setIsConfirmOpen(false);
      setRowsToDelete([]);
      setError(null);
      setIsDataModified(true); // データが変更されたので状態を更新
    } catch (err) {
      console.error('Error deleting rows:', err);
      setError('行の削除に失敗しました');
      setIsConfirmOpen(false);
    }
  };

  const cancelDeleteRows = () => {
    setIsConfirmOpen(false);
    setRowsToDelete([]);
  };

  const handleRestoreRow = (log) => {
    try {
      const newData = [...data];
      newData.push(log.rowData);
      setData(newData);

      setDeletionLog((prevLog) => prevLog.filter((item) => item !== log));

      setError(null);
      setIsDataModified(true); // データが変更されたので状態を更新
    } catch (err) {
      console.error('Error restoring row:', err);
      setError('行の復元に失敗しました');
    }
  };

  // ワード管理用モーダルのハンドラー
  const openManageModal = () => {
    setIsManageModalOpen(true);
  };

  const closeManageModal = () => {
    setIsManageModalOpen(false);
  };

  // モーダル内でのワード管理（追加・削除）
  const handleManageAddWord = (newWord) => {
    if (newWord && !deleteWords.some((word) => word.value === newWord)) {
      setDeleteWords([...deleteWords, { value: newWord, label: newWord }]);
    }
  };

  const handleManageDeleteWord = (wordToDelete) => {
    setDeleteWords(
      deleteWords.filter((word) => word.value !== wordToDelete.value)
    );
  };

  // 一括インポート機能のハンドラー
  const handleImportWords = () => {
    if (importText.trim() === '') return;

    // カンマ、全角カンマ、改行で分割
    const importedWords = importText
      .split(/[,、\n]+/)
      .map((w) => w.trim())
      .filter((w) => w);
    const uniqueImportedWords = Array.from(new Set(importedWords));
    const formattedImportedWords = uniqueImportedWords.map((w) => ({
      value: w,
      label: w,
    }));

    setDeleteWords((prevWords) => {
      const updatedWords = [...prevWords];
      formattedImportedWords.forEach((w) => {
        if (!updatedWords.some((word) => word.value === w.value)) {
          updatedWords.push(w);
        }
      });
      return updatedWords;
    });

    setImportText(''); // テキストエリアをクリア
  };

  // テンプレートを保存
  const saveTemplate = () => {
    if (!newTemplateName.trim()) {
      setError('テンプレート名を入力してください。');
      return;
    }

    // 同じ名前のテンプレートが存在するか確認
    const existingTemplate = templates.find(
      (t) => t.name === newTemplateName.trim()
    );
    if (existingTemplate) {
      setError('同じ名前のテンプレートが既に存在します。別の名前を使用してください。');
      return;
    }

    // 削除ワードを文字列の配列に変換
    const wordsArray = deleteWords.map((word) => word.value);

    const newTemplate = {
      name: newTemplateName.trim(),
      words: wordsArray,
    };

    setTemplates([...templates, newTemplate]);
    setNewTemplateName('');
    setSnackbar({
      open: true,
      message: `テンプレート "${newTemplateName.trim()}" を保存しました。`,
      severity: 'success',
    });
    setError(null);
  };

  // テンプレートを適用
  const applyTemplate = (template) => {
    // 削除ワードを { value, label } の形式に変換
    const formattedWords = template.words.map((word) => ({
      value: word,
      label: word,
    }));
    setDeleteWords(formattedWords);
    setSelectedTemplateNameLocal(template.name);
  };

  // テンプレートを削除
  const deleteTemplate = (templateToDelete) => {
    const updatedTemplates = templates.filter((t) => t.name !== templateToDelete.name);
    setTemplates(updatedTemplates);
    if (selectedTemplateNameLocal === templateToDelete.name) {
      setSelectedTemplateNameLocal('');
      setDeleteWords([]);
    }
  };

  // 新しいテンプレートの作成
  const handleCreateNewTemplate = () => {
    setDeleteWords([]); // 削除ワードをクリア
    setSelectedTemplateNameLocal(''); // 選択されたテンプレート名をクリア
    setNewTemplateName(''); // 新しいテンプレート名をクリア
    setSnackbar({
      open: true,
      message: '新しいテンプレートを作成します。削除ワードを追加してください。',
      severity: 'info',
    });
  };

  // 削除ワードをすべてクリア
  const handleClearAllWords = () => {
    setDeleteWords([]);
    setSnackbar({
      open: true,
      message: '削除ワードをすべてクリアしました。',
      severity: 'info',
    });
  };

  // モーダルのスタイル設定
  const confirmDialogStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      transform: 'translate(-50%, -50%)',
      width: '70%', // 幅を70%に拡大
      maxHeight: '80%', // 高さを80%に拡大
      overflow: 'auto',
      backgroundColor: '#fff', // 背景を白に設定
      zIndex: 1001, // モーダルのコンテンツのz-indexを設定
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.7)', // オーバーレイの不透明度を上げる
      zIndex: 1000, // オーバーレイのz-indexを設定
    },
  };

  const deletionLogModalStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      transform: 'translate(-50%, -50%)',
      width: '90%', // 幅を90%に拡大
      maxHeight: '90%', // 高さを90%に拡大
      overflow: 'auto',
      backgroundColor: '#fff', // 背景を白に設定
      zIndex: 1001, // モーダルのコンテンツのz-indexを設定
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.7)', // オーバーレイの不透明度を上げる
      zIndex: 1000, // オーバーレイのz-indexを設定
    },
  };

  const manageModalStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      transform: 'translate(-50%, -50%)',
      width: '80%', // 幅を80%に拡大
      maxHeight: '90%', // 高さを90%に拡大
      overflow: 'auto',
      backgroundColor: '#fff', // 背景を白に設定
      zIndex: 1001, // モーダルのコンテンツのz-indexを設定
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.7)', // オーバーレイの不透明度を上げる
      zIndex: 1000, // オーバーレイのz-indexを設定
    },
  };

  // カラム一括編集のハンドラー
  const handleBulkEdit = () => {
    if (!selectedColumn || !newValue) {
      setSnackbar({
        open: true,
        message: '列名と新しい値を入力してください',
        severity: 'error',
      });
      return;
    }

    const updatedData = data.map((row) => ({
      ...row,
      [selectedColumn]: newValue,
    }));

    setData(updatedData);

    // initialDataも更新
    if (initialData && initialData.length > 0) {
      const updatedInitialData = initialData.map((row) => ({
        ...row,
        [selectedColumn]: newValue,
      }));
      setInitialData(updatedInitialData);
    }

    setSnackbar({
      open: true,
      message: `列 "${selectedColumn}" が更新されました`,
      severity: 'success',
    });
    setIsDataModified(true); // データが変更されたので状態を更新
  };

  // CSVダウンロードのハンドラー
  const handleCsvDownload = () => {
    if (!data || data.length === 0) {
      setSnackbar({
        open: true,
        message: 'ダウンロードするデータがありません',
        severity: 'error',
      });
      return;
    }

    const csv = Papa.unparse(data);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });

    // 現在の日付を取得
    const currentDate = new Date().toISOString().split('T')[0]; // YYYY-MM-DD形式

    // 最初の商品タイトルを取得し、先頭10文字を抜き出す
    const firstTitle = data[0]['Title'] || data[0]['title'] || 'Untitled';
    const truncatedTitle = firstTitle.substring(0, 10);

    // ファイル名に使用できない文字を置換する関数
    const sanitizeFileName = (name) => name.replace(/[\\/:*?"<>|]/g, '_');

    // サニタイズしたタイトルを取得
    const safeTitle = sanitizeFileName(truncatedTitle);

    // ファイル名を生成
    const fileName = `${safeTitle}_${currentDate}.csv`;

    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    setSnackbar({
      open: true,
      message: `CSVのダウンロードが完了しました。ファイル名: ${fileName}`,
      severity: 'success',
    });
  };

  // レンダリング部分
  return (
    <Paper elevation={3} sx={{ p: 2, mt: 0 }}>
      <Grid container spacing={2}>
        {/* カラム一括編集 */}
        <Grid item xs={12} md={4}>
          <Typography variant="subtitle1">カラム一括編集</Typography>
          <TextField
            select
            label="列名を選択"
            value={selectedColumn}
            onChange={(e) => setSelectedColumn(e.target.value)}
            variant="outlined"
            margin="normal"
            fullWidth
            SelectProps={{
              native: true,
            }}
          >
            <option value="" disabled>
              列名を選択してください
            </option>
            {Array.isArray(columns) &&
              columns.map((column, index) => (
                <option key={index} value={column}>
                  {column}
                </option>
              ))}
          </TextField>
          <TextField
            type="text"
            value={newValue}
            onChange={(e) => setNewValue(e.target.value)}
            label="新しい値"
            variant="outlined"
            margin="normal"
            fullWidth
          />
          <Button
            onClick={handleBulkEdit}
            variant="contained"
            color="primary"
            fullWidth
          >
            アップデート
          </Button>
        </Grid>

        {/* 削除ワード入力と管理ボタン */}
        <Grid item xs={12} md={4}>
          <Typography variant="subtitle1">削除ワードによる行の削除</Typography>
          <Select
            isMulti
            isClearable
            onChange={handleDeleteWordsChange}
            options={deleteWords}
            value={deleteWords}
            placeholder="削除したいワードを入力"
            components={{
              DropdownIndicator: null,
            }}
            styles={{
              multiValue: (styles) => ({
                ...styles,
                backgroundColor: '#e0e0e0',
              }),
              multiValueLabel: (styles) => ({
                ...styles,
                color: '#333',
              }),
              multiValueRemove: (styles) => ({
                ...styles,
                color: '#666',
                ':hover': {
                  backgroundColor: '#ccc',
                  color: '#000',
                },
              }),
            }}
          />
          <Button
            onClick={openManageModal}
            variant="outlined"
            color="primary"
            fullWidth
            sx={{ mt: 1 }}
          >
            ワードを管理
          </Button>
          <Button
            onClick={handleDeleteRowsByWord}
            variant="contained"
            color="secondary"
            fullWidth
            sx={{ mt: 1 }}
          >
            指定ワードを含む行を削除
          </Button>
          <Button
            onClick={() => setIsDeletionLogOpen(true)}
            variant="outlined"
            color="secondary"
            fullWidth
            sx={{ mt: 1 }}
          >
            削除された商品を見る
          </Button>
        </Grid>

        {/* CSVダウンロードとカラム設定マネージャーをここに追加 */}
        <Grid item xs={12} md={4}>
          <Typography variant="subtitle1">CSVダウンロード</Typography>
          <Button
            onClick={handleCsvDownload}
            variant="contained"
            color="primary"
            fullWidth
            sx={{ mt: 1 }}
          >
            CSVをダウンロード
          </Button>
          <Typography variant="subtitle1" sx={{ mt: 4 }}>
            カラム設定
          </Typography>
          <ColumnSettingsManager
            data={data}
            setData={setData}
            initialData={initialData}
            setInitialData={setInitialData}
            columns={columns}
            setColumns={setColumns}
            columnOrderTemplates={columnOrderTemplates}
            setColumnOrderTemplates={setColumnOrderTemplates}
            applyColumnTemplate={applyColumnTemplate}
            selectedTemplateName={selectedTemplateName}
            setSelectedTemplateName={setSelectedTemplateName}
            setColumnOrder={setColumnOrder}
            columnWidth={columnWidth}
            setColumnWidth={setColumnWidth}
          />
        </Grid>
      </Grid>

      {/* エラーメッセージ表示 */}
      {error && (
        <Typography color="error" variant="body1" sx={{ mt: 2 }}>
          {error}
        </Typography>
      )}

      {/* 確認ダイアログ */}
      <Modal
        isOpen={isConfirmOpen}
        onRequestClose={cancelDeleteRows}
        contentLabel="削除確認"
        style={confirmDialogStyles}
      >
        <div className="confirm-dialog-content">
          <p>以下の {rowsToDelete.length} 行を削除します。よろしいですか？</p>
          <ul>
            {rowsToDelete.map((rowIndex, idx) => (
              <li key={idx}>
                行 {rowIndex + 1}:{' '}
                {titleIndex !== -1 &&
                  `Title="${data[rowIndex][columns[titleIndex]]}" `}
                {jpTitleIndex !== -1 &&
                  `jp_title="${data[rowIndex][columns[jpTitleIndex]]}" `}
                {jpDescIndex !== -1 &&
                  `jp_desc="${data[rowIndex][columns[jpDescIndex]]}"`}
              </li>
            ))}
          </ul>
          <Button onClick={confirmDeleteRows} variant="contained" color="secondary">
            削除する
          </Button>
          <Button
            onClick={cancelDeleteRows}
            variant="outlined"
            color="primary"
            sx={{ ml: 2 }}
          >
            キャンセル
          </Button>
        </div>
      </Modal>

      {/* 削除ログモーダル */}
      <Modal
        isOpen={isDeletionLogOpen}
        onRequestClose={() => setIsDeletionLogOpen(false)}
        contentLabel="削除された商品"
        style={deletionLogModalStyles}
      >
        <div className="deletion-log-content">
          <h3>削除された商品</h3>
          <Button
            onClick={() => setIsDeletionLogOpen(false)}
            variant="outlined"
            color="primary"
            sx={{ mb: 2 }}
          >
            閉じる
          </Button>
          {deletionLog.length > 0 ? (
            <table className="deleted-items-table">
              <thead>
                <tr>
                  <th>行番号</th>
                  {titleIndex !== -1 && <th>Title</th>}
                  {jpTitleIndex !== -1 && <th>jp_title</th>}
                  {jpDescIndex !== -1 && <th>jp_desc</th>}
                  <th>操作</th>
                </tr>
              </thead>
              <tbody>
                {deletionLog.map((log, index) => (
                  <tr key={index}>
                    <td>{log.rowIndex + 1}</td>
                    {titleIndex !== -1 && (
                      <td>{log.rowData[columns[titleIndex]]}</td>
                    )}
                    {jpTitleIndex !== -1 && (
                      <td>{log.rowData[columns[jpTitleIndex]]}</td>
                    )}
                    {jpDescIndex !== -1 && (
                      <td>{log.rowData[columns[jpDescIndex]]}</td>
                    )}
                    <td>
                      <Button
                        onClick={() => handleRestoreRow(log)}
                        variant="contained"
                        color="primary"
                      >
                        復元
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p>削除された商品はありません。</p>
          )}
          <p>復元された商品はデータの末尾に追加されます。</p>
        </div>
      </Modal>

      {/* ワード管理モーダル */}
      <Modal
        isOpen={isManageModalOpen}
        onRequestClose={closeManageModal}
        contentLabel="削除ワード管理"
        style={manageModalStyles}
      >
        {/* 改善されたモーダルの内容 */}
        <Box sx={{ p: 2 }}>
          <Typography variant="h6" gutterBottom>
            削除ワードを管理
          </Typography>
          {/* テンプレートと削除ワードを横並びに配置 */}
          <Grid container spacing={2}>
            {/* テンプレートを選択 */}
            <Grid item xs={12} md={6}>
              <Typography variant="subtitle1" gutterBottom>
                テンプレートを選択
              </Typography>
              <Button
                onClick={handleCreateNewTemplate}
                variant="outlined"
                color="primary"
                size="small"
                sx={{ mb: 1 }}
              >
                新しいテンプレート
              </Button>
              <Box sx={{ mb: 2 }}>
                {templates.map((template, index) => (
                  <Chip
                    key={index}
                    label={template.name}
                    onClick={() => applyTemplate(template)}
                    onDelete={() => deleteTemplate(template)}
                    color={
                      selectedTemplateNameLocal === template.name ? 'primary' : 'default'
                    }
                    sx={{ m: 0.5 }}
                  />
                ))}
              </Box>
              <TextField
                label="新しいテンプレート名"
                value={newTemplateName}
                onChange={(e) => setNewTemplateName(e.target.value)}
                variant="outlined"
                size="small"
                fullWidth
                sx={{ mt: 1 }}
              />
              <Button
                onClick={saveTemplate}
                variant="contained"
                color="primary"
                size="small"
                fullWidth
                sx={{ mt: 1 }}
              >
                テンプレートを保存
              </Button>
            </Grid>

            {/* 削除ワードの管理 */}
            <Grid item xs={12} md={6}>
              <Typography variant="subtitle1" gutterBottom>
                削除ワードの管理
              </Typography>
              {/* 検索バー */}
              <TextField
                label="削除ワードを検索"
                variant="outlined"
                size="small"
                fullWidth
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
              {/* 新しいワードの追加 */}
              <Box sx={{ display: 'flex', mt: 1 }}>
                <TextField
                  placeholder="新しいワードを入力"
                  variant="outlined"
                  size="small"
                  fullWidth
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                      const newWord = e.target.value.trim();
                      if (newWord) {
                        handleManageAddWord(newWord);
                        e.target.value = '';
                      }
                    }
                  }}
                />
                <Button
                  onClick={() => {
                    const input = document.querySelector(
                      '.manage-words-section input'
                    );
                    const newWord = input.value.trim();
                    if (newWord) {
                      handleManageAddWord(newWord);
                      input.value = '';
                    }
                  }}
                  variant="contained"
                  color="primary"
                  size="small"
                  sx={{ ml: 1 }}
                >
                  追加
                </Button>
              </Box>
              {/* 一括インポート機能 */}
              <Box sx={{ mt: 1 }}>
                <TextField
                  placeholder="一括でワードを入力（カンマ、改行で区切る）"
                  multiline
                  rows={3}
                  value={importText}
                  onChange={(e) => setImportText(e.target.value)}
                  variant="outlined"
                  fullWidth
                  size="small"
                />
                <Button
                  onClick={handleImportWords}
                  variant="contained"
                  color="primary"
                  size="small"
                  fullWidth
                  sx={{ mt: 1 }}
                >
                  一括インポート
                </Button>
              </Box>
              {/* すべてクリアボタンの追加 */}
              <Button
                onClick={handleClearAllWords}
                variant="outlined"
                color="secondary"
                size="small"
                fullWidth
                sx={{ mt: 1 }}
              >
                すべてクリア
              </Button>
              {/* 削除ワードリスト */}
              <div
                style={{
                  maxHeight: '200px',
                  overflow: 'auto',
                  marginTop: '8px',
                  border: '1px solid #ccc',
                  padding: '8px',
                }}
              >
                {filteredDeleteWords.length > 0 ? (
                  <Box
                    sx={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      gap: '8px',
                    }}
                  >
                    {filteredDeleteWords.map((word, index) => (
                      <Chip
                        key={index}
                        label={word.label}
                        onDelete={() => handleManageDeleteWord(word)}
                        sx={{ m: 0.5 }}
                        size="small"
                      />
                    ))}
                  </Box>
                ) : (
                  <Typography variant="body2">削除ワードがありません。</Typography>
                )}
              </div>
            </Grid>
          </Grid>
          {/* 閉じるボタン */}
          <Button
            onClick={closeManageModal}
            variant="outlined"
            color="primary"
            size="small"
            fullWidth
            sx={{ mt: 2 }}
          >
            閉じる
          </Button>
        </Box>
      </Modal>

      {/* Snackbarによる通知 */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Paper>
  );
}

BulkColumnEdit.propTypes = {
  data: PropTypes.array.isRequired,
  setData: PropTypes.func.isRequired,
  initialData: PropTypes.array.isRequired,
  setInitialData: PropTypes.func.isRequired,
  columns: PropTypes.array.isRequired,
  setColumns: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
  setIsDataModified: PropTypes.func.isRequired,
  columnOrderTemplates: PropTypes.object.isRequired,
  setColumnOrderTemplates: PropTypes.func.isRequired,
  applyColumnTemplate: PropTypes.func.isRequired,
  selectedTemplateName: PropTypes.string.isRequired,
  setSelectedTemplateName: PropTypes.func.isRequired,
  setColumnOrder: PropTypes.func.isRequired,
  columnWidth: PropTypes.number.isRequired,
  setColumnWidth: PropTypes.func.isRequired,
};

export default BulkColumnEdit;





// // BulkColumnEdit.js

// import React, { useState, useEffect } from 'react';
// import Select from 'react-select/creatable';
// import Modal from 'react-modal';
// import Papa from 'papaparse';
// import {
//   Box,
//   Button,
//   TextField,
//   Typography,
//   Grid,
//   Paper,
//   Snackbar,
//   Alert,
//   Chip,
//   InputAdornment,
// } from '@mui/material';
// import PropTypes from 'prop-types';
// import {
//   doc,
//   setDoc,
//   onSnapshot,
// } from 'firebase/firestore';
// import { db } from './firebaseConfig';
// import DeleteIcon from '@mui/icons-material/Delete';
// import SearchIcon from '@mui/icons-material/Search';
// import ColumnSettingsManager from './ColumnSettingsManager'; // インポートを追加

// // モーダルのアクセシビリティ設定
// Modal.setAppElement('#root');

// function BulkColumnEdit({
//   data = [],
//   setData,
//   initialData,
//   setInitialData,
//   columns = [],
//   setColumns,
//   userId,
//   setIsDataModified,
//   columnOrderTemplates,
//   setColumnOrderTemplates,
//   applyColumnTemplate,
//   selectedTemplateName,
//   setSelectedTemplateName,
//   setColumnOrder,
//   columnWidth,
//   setColumnWidth,
// }) {
//   // ステート変数の定義
//   const [selectedColumn, setSelectedColumn] = useState('');
//   const [newValue, setNewValue] = useState('');
//   const [snackbar, setSnackbar] = useState({
//     open: false,
//     message: '',
//     severity: 'info',
//   });

//   // 削除ワード関連のステート
//   const [error, setError] = useState(null);
//   const [deleteWords, setDeleteWords] = useState([]); // 削除ワードのステート
//   const [isConfirmOpen, setIsConfirmOpen] = useState(false);
//   const [rowsToDelete, setRowsToDelete] = useState([]);
//   const [deletionLog, setDeletionLog] = useState([]);
//   const [titleIndex, setTitleIndex] = useState(-1);
//   const [jpTitleIndex, setJpTitleIndex] = useState(-1);
//   const [jpDescIndex, setJpDescIndex] = useState(-1); // jp_descのインデックス
//   const [isDeletionLogOpen, setIsDeletionLogOpen] = useState(false);
//   const [isManageModalOpen, setIsManageModalOpen] = useState(false); // 管理モーダルのステート
//   const [importText, setImportText] = useState(''); // 一括インポート用のローカルステート

//   // 検索機能のステート
//   const [searchQuery, setSearchQuery] = useState(''); // 削除ワード検索用
//   const [filteredDeleteWords, setFilteredDeleteWords] = useState([]); // フィルタされた削除ワード

//   // テンプレート関連のステート
//   const [templates, setTemplates] = useState([]); // テンプレートのリスト
//   const [selectedTemplateNameLocal, setSelectedTemplateNameLocal] = useState(''); // 選択されたテンプレート名（ローカル）
//   const [newTemplateName, setNewTemplateName] = useState(''); // 新しいテンプレート名

//   // Effect hooks
//   useEffect(() => {
//     if (data && data.length > 0) {
//       const headers = columns;
//       setTitleIndex(headers.findIndex((header) => header.toLowerCase() === 'title'));
//       setJpTitleIndex(headers.findIndex((header) => header.toLowerCase() === 'jp_title'));
//       setJpDescIndex(headers.findIndex((header) => header.toLowerCase() === 'jp_desc'));
//     }
//   }, [data, columns]);

//   // テンプレートをFirestoreから読み込む
//   useEffect(() => {
//     if (!userId) return;

//     const docRef = doc(db, 'users', userId, 'templates', 'deleteWordTemplates');
//     const unsubscribe = onSnapshot(docRef, (docSnap) => {
//       if (docSnap.exists()) {
//         setTemplates(docSnap.data().templates || []);
//       }
//     });

//     return () => unsubscribe();
//   }, [userId]);

//   // 削除ワードをFirestoreから読み込む
//   useEffect(() => {
//     if (!userId) return;

//     const docRef = doc(db, 'users', userId, 'deleteWords', 'words');
//     const unsubscribe = onSnapshot(docRef, (docSnap) => {
//       if (docSnap.exists()) {
//         const wordsArray = docSnap.data().words || [];
//         const formattedWords = wordsArray.map((word) => ({ value: word, label: word }));
//         setDeleteWords(formattedWords);
//       }
//     });

//     return () => unsubscribe();
//   }, [userId]);

//   // 選択されたテンプレート名をFirestoreから読み込む
//   useEffect(() => {
//     if (!userId) return;

//     const docRef = doc(db, 'users', userId, 'selectedTemplate', 'name');
//     const unsubscribe = onSnapshot(docRef, (docSnap) => {
//       if (docSnap.exists()) {
//         setSelectedTemplateNameLocal(docSnap.data().selectedTemplateName || '');
//       }
//     });

//     return () => unsubscribe();
//   }, [userId]);

//   // テンプレートの変更をFirestoreに保存
//   useEffect(() => {
//     if (!userId) return;
//     if (templates.length === 0) return;

//     const saveTemplates = async () => {
//       try {
//         const docRef = doc(db, 'users', userId, 'templates', 'deleteWordTemplates');
//         await setDoc(docRef, { templates }, { merge: true });
//       } catch (error) {
//         console.error('Error saving templates to Firestore:', error);
//         setError('テンプレートの保存に失敗しました。');
//       }
//     };
//     saveTemplates();
//   }, [templates, userId]);

//   // 削除ワードの変更をFirestoreに保存
//   useEffect(() => {
//     if (!userId) return;
//     if (deleteWords.length === 0) return;

//     const wordsArray = deleteWords.map((word) => word.value);

//     const saveDeleteWords = async () => {
//       try {
//         const docRef = doc(db, 'users', userId, 'deleteWords', 'words');
//         await setDoc(docRef, { words: wordsArray }, { merge: true });
//       } catch (error) {
//         console.error('Error saving delete words to Firestore:', error);
//         setError('削除ワードの保存に失敗しました。');
//       }
//     };
//     saveDeleteWords();
//   }, [deleteWords, userId]);

//   // 選択されたテンプレート名をFirestoreに保存
//   useEffect(() => {
//     if (!userId) return;
//     if (!selectedTemplateNameLocal) return;

//     const saveSelectedTemplateName = async () => {
//       try {
//         const docRef = doc(db, 'users', userId, 'selectedTemplate', 'name');
//         await setDoc(docRef, { selectedTemplateName: selectedTemplateNameLocal }, { merge: true });
//       } catch (error) {
//         console.error('Error saving selected template name to Firestore:', error);
//         setError('選択されたテンプレート名の保存に失敗しました。');
//       }
//     };
//     saveSelectedTemplateName();
//   }, [selectedTemplateNameLocal, userId]);

//   // テンプレートの適用
//   useEffect(() => {
//     if (selectedTemplateNameLocal && templates.length > 0) {
//       const template = templates.find((t) => t.name === selectedTemplateNameLocal);
//       if (template) {
//         // テンプレートを適用
//         const formattedWords = template.words.map((word) => ({
//           value: word,
//           label: word,
//         }));
//         setDeleteWords(formattedWords);
//       } else {
//         // テンプレートが見つからない場合、選択を解除
//         setSelectedTemplateNameLocal('');
//       }
//     }
//   }, [selectedTemplateNameLocal, templates]);

//   // 削除ワードの検索・フィルタリング
//   useEffect(() => {
//     if (searchQuery.trim() === '') {
//       setFilteredDeleteWords(deleteWords);
//     } else {
//       const lowerQuery = searchQuery.toLowerCase();
//       setFilteredDeleteWords(
//         deleteWords.filter((word) =>
//           word.value.toLowerCase().includes(lowerQuery)
//         )
//       );
//     }
//   }, [searchQuery, deleteWords]);

//   // 削除ワード機能の関数

//   const splitTitleIntoWords = (title) => {
//     if (!title) return [];
//     return title.split(/\s+/).filter(Boolean);
//   };

//   const handleDeleteWordsChange = (newWords) => {
//     setDeleteWords(newWords || []);
//   };

//   const handleDeleteRowsByWord = () => {
//     if (deleteWords.length === 0) {
//       setError('削除したいワードを入力してください。');
//       return;
//     }

//     if (data.length === 0) {
//       setError('データがありません。');
//       return;
//     }

//     try {
//       if (titleIndex === -1 && jpTitleIndex === -1 && jpDescIndex === -1) {
//         setError('"Title" カラム、"jp_title" カラム、または "jp_desc" カラムが見つかりません。');
//         return;
//       }

//       const words = deleteWords.map((word) => word.value).filter((w) => w);

//       if (words.length === 0) {
//         setError('有効な削除ワードがありません。');
//         return;
//       }

//       const wordsLower = words.map((word) => word.toLowerCase());

//       const rowsToDeleteTemp = [];
//       const deletionLogTemp = [];

//       for (let i = 0; i < data.length; i++) {
//         const row = data[i];
//         const titleCell = titleIndex !== -1 ? row[columns[titleIndex]] || '' : '';
//         const jpTitleCell = jpTitleIndex !== -1 ? row[columns[jpTitleIndex]] || '' : '';
//         const jpDescCell = jpDescIndex !== -1 ? row[columns[jpDescIndex]] || '' : '';

//         // タイトルと説明を空白で分割して単語の配列を取得
//         const titleWords = splitTitleIntoWords(titleCell);
//         const jpTitleWords = splitTitleIntoWords(jpTitleCell);
//         const jpDescWords = splitTitleIntoWords(jpDescCell);

//         const titleWordsLower = titleWords.map((word) => word.toLowerCase());
//         const jpTitleWordsLower = jpTitleWords.map((word) => word.toLowerCase());
//         const jpDescWordsLower = jpDescWords.map((word) => word.toLowerCase());

//         // 削除ワードとタイトルおよび説明内の単語を比較
//         const titleMatches = wordsLower.some((word) => titleWordsLower.includes(word));
//         const jpTitleMatches = wordsLower.some((word) => jpTitleWordsLower.includes(word));
//         const jpDescMatches = wordsLower.some((word) => jpDescWordsLower.includes(word));

//         if (titleMatches || jpTitleMatches || jpDescMatches) {
//           rowsToDeleteTemp.push(i);
//           deletionLogTemp.push({
//             rowIndex: i,
//             rowData: row,
//           });
//         }
//       }

//       if (rowsToDeleteTemp.length === 0) {
//         setError('指定したワードを含む行は見つかりませんでした。');
//         return;
//       }

//       rowsToDeleteTemp.sort((a, b) => b - a);

//       setRowsToDelete(rowsToDeleteTemp);
//       setDeletionLog((prevLog) => [...prevLog, ...deletionLogTemp]);
//       setIsConfirmOpen(true);
//       setError(null);
//     } catch (err) {
//       console.error('Error preparing rows for deletion:', err);
//       setError('行の削除準備中にエラーが発生しました');
//     }
//   };

//   const confirmDeleteRows = () => {
//     try {
//       let newData = [...data];
//       for (let rowIndex of rowsToDelete) {
//         newData.splice(rowIndex, 1);
//       }
//       setData(newData);
//       setIsConfirmOpen(false);
//       setRowsToDelete([]);
//       setError(null);
//       setIsDataModified(true); // データが変更されたので状態を更新
//     } catch (err) {
//       console.error('Error deleting rows:', err);
//       setError('行の削除に失敗しました');
//       setIsConfirmOpen(false);
//     }
//   };

//   const cancelDeleteRows = () => {
//     setIsConfirmOpen(false);
//     setRowsToDelete([]);
//   };

//   const handleRestoreRow = (log) => {
//     try {
//       const newData = [...data];
//       newData.push(log.rowData);
//       setData(newData);

//       setDeletionLog((prevLog) => prevLog.filter((item) => item !== log));

//       setError(null);
//       setIsDataModified(true); // データが変更されたので状態を更新
//     } catch (err) {
//       console.error('Error restoring row:', err);
//       setError('行の復元に失敗しました');
//     }
//   };

//   // ワード管理用モーダルのハンドラー
//   const openManageModal = () => {
//     setIsManageModalOpen(true);
//   };

//   const closeManageModal = () => {
//     setIsManageModalOpen(false);
//   };

//   // モーダル内でのワード管理（追加・削除）
//   const handleManageAddWord = (newWord) => {
//     if (newWord && !deleteWords.some((word) => word.value === newWord)) {
//       setDeleteWords([...deleteWords, { value: newWord, label: newWord }]);
//     }
//   };

//   const handleManageDeleteWord = (wordToDelete) => {
//     setDeleteWords(
//       deleteWords.filter((word) => word.value !== wordToDelete.value)
//     );
//   };

//   // 一括インポート機能のハンドラー
//   const handleImportWords = () => {
//     if (importText.trim() === '') return;

//     // カンマ、全角カンマ、改行で分割
//     const importedWords = importText
//       .split(/[,、\n]+/)
//       .map((w) => w.trim())
//       .filter((w) => w);
//     const uniqueImportedWords = Array.from(new Set(importedWords));
//     const formattedImportedWords = uniqueImportedWords.map((w) => ({
//       value: w,
//       label: w,
//     }));

//     setDeleteWords((prevWords) => {
//       const updatedWords = [...prevWords];
//       formattedImportedWords.forEach((w) => {
//         if (!updatedWords.some((word) => word.value === w.value)) {
//           updatedWords.push(w);
//         }
//       });
//       return updatedWords;
//     });

//     setImportText(''); // テキストエリアをクリア
//   };

//   // テンプレートを保存
//   const saveTemplate = () => {
//     if (!newTemplateName.trim()) {
//       setError('テンプレート名を入力してください。');
//       return;
//     }

//     // 同じ名前のテンプレートが存在するか確認
//     const existingTemplate = templates.find(
//       (t) => t.name === newTemplateName.trim()
//     );
//     if (existingTemplate) {
//       setError('同じ名前のテンプレートが既に存在します。別の名前を使用してください。');
//       return;
//     }

//     // 削除ワードを文字列の配列に変換
//     const wordsArray = deleteWords.map((word) => word.value);

//     const newTemplate = {
//       name: newTemplateName.trim(),
//       words: wordsArray,
//     };

//     setTemplates([...templates, newTemplate]);
//     setNewTemplateName('');
//     setSnackbar({
//       open: true,
//       message: `テンプレート "${newTemplateName.trim()}" を保存しました。`,
//       severity: 'success',
//     });
//     setError(null);
//   };

//   // テンプレートを適用
//   const applyTemplate = (template) => {
//     // 削除ワードを { value, label } の形式に変換
//     const formattedWords = template.words.map((word) => ({
//       value: word,
//       label: word,
//     }));
//     setDeleteWords(formattedWords);
//     setSelectedTemplateNameLocal(template.name);
//   };

//   // テンプレートを削除
//   const deleteTemplate = (templateToDelete) => {
//     const updatedTemplates = templates.filter((t) => t.name !== templateToDelete.name);
//     setTemplates(updatedTemplates);
//     if (selectedTemplateNameLocal === templateToDelete.name) {
//       setSelectedTemplateNameLocal('');
//       setDeleteWords([]);
//     }
//   };

//   // 新しいテンプレートの作成
//   const handleCreateNewTemplate = () => {
//     setDeleteWords([]); // 削除ワードをクリア
//     setSelectedTemplateNameLocal(''); // 選択されたテンプレート名をクリア
//     setNewTemplateName(''); // 新しいテンプレート名をクリア
//     setSnackbar({
//       open: true,
//       message: '新しいテンプレートを作成します。削除ワードを追加してください。',
//       severity: 'info',
//     });
//   };

//   // 削除ワードをすべてクリア
//   const handleClearAllWords = () => {
//     setDeleteWords([]);
//     setSnackbar({
//       open: true,
//       message: '削除ワードをすべてクリアしました。',
//       severity: 'info',
//     });
//   };

//   // モーダルのスタイル設定
//   const confirmDialogStyles = {
//     content: {
//       top: '50%',
//       left: '50%',
//       right: 'auto',
//       bottom: 'auto',
//       transform: 'translate(-50%, -50%)',
//       width: '70%', // 幅を70%に拡大
//       maxHeight: '80%', // 高さを80%に拡大
//       overflow: 'auto',
//       backgroundColor: '#fff', // 背景を白に設定
//       zIndex: 1001, // モーダルのコンテンツのz-indexを設定
//     },
//     overlay: {
//       backgroundColor: 'rgba(0, 0, 0, 0.7)', // オーバーレイの不透明度を上げる
//       zIndex: 1000, // オーバーレイのz-indexを設定
//     },
//   };

//   const deletionLogModalStyles = {
//     content: {
//       top: '50%',
//       left: '50%',
//       right: 'auto',
//       bottom: 'auto',
//       transform: 'translate(-50%, -50%)',
//       width: '90%', // 幅を90%に拡大
//       maxHeight: '90%', // 高さを90%に拡大
//       overflow: 'auto',
//       backgroundColor: '#fff', // 背景を白に設定
//       zIndex: 1001, // モーダルのコンテンツのz-indexを設定
//     },
//     overlay: {
//       backgroundColor: 'rgba(0, 0, 0, 0.7)', // オーバーレイの不透明度を上げる
//       zIndex: 1000, // オーバーレイのz-indexを設定
//     },
//   };

//   const manageModalStyles = {
//     content: {
//       top: '50%',
//       left: '50%',
//       right: 'auto',
//       bottom: 'auto',
//       transform: 'translate(-50%, -50%)',
//       width: '80%', // 幅を80%に拡大
//       maxHeight: '90%', // 高さを90%に拡大
//       overflow: 'auto',
//       backgroundColor: '#fff', // 背景を白に設定
//       zIndex: 1001, // モーダルのコンテンツのz-indexを設定
//     },
//     overlay: {
//       backgroundColor: 'rgba(0, 0, 0, 0.7)', // オーバーレイの不透明度を上げる
//       zIndex: 1000, // オーバーレイのz-indexを設定
//     },
//   };

//   // カラム一括編集のハンドラー
//   const handleBulkEdit = () => {
//     if (!selectedColumn || !newValue) {
//       setSnackbar({
//         open: true,
//         message: '列名と新しい値を入力してください',
//         severity: 'error',
//       });
//       return;
//     }

//     const updatedData = data.map((row) => ({
//       ...row,
//       [selectedColumn]: newValue,
//     }));

//     setData(updatedData);

//     // initialDataも更新
//     if (initialData && initialData.length > 0) {
//       const updatedInitialData = initialData.map((row) => ({
//         ...row,
//         [selectedColumn]: newValue,
//       }));
//       setInitialData(updatedInitialData);
//     }

//     setSnackbar({
//       open: true,
//       message: `列 "${selectedColumn}" が更新されました`,
//       severity: 'success',
//     });
//     setIsDataModified(true); // データが変更されたので状態を更新
//   };

//   // CSVダウンロードのハンドラー
//   const handleCsvDownload = () => {
//     if (!data || data.length === 0) {
//       setSnackbar({
//         open: true,
//         message: 'ダウンロードするデータがありません',
//         severity: 'error',
//       });
//       return;
//     }

//     const csv = Papa.unparse(data);
//     const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });

//     // 現在の日付を取得
//     const currentDate = new Date().toISOString().split('T')[0]; // YYYY-MM-DD形式

//     // 最初の商品タイトルを取得し、先頭10文字を抜き出す
//     const firstTitle = data[0]['Title'] || data[0]['title'] || 'Untitled';
//     const truncatedTitle = firstTitle.substring(0, 10);

//     // ファイル名に使用できない文字を置換する関数
//     const sanitizeFileName = (name) => name.replace(/[\\/:*?"<>|]/g, '_');

//     // サニタイズしたタイトルを取得
//     const safeTitle = sanitizeFileName(truncatedTitle);

//     // ファイル名を生成
//     const fileName = `${safeTitle}_${currentDate}.csv`;

//     const link = document.createElement('a');
//     link.href = URL.createObjectURL(blob);
//     link.setAttribute('download', fileName);
//     document.body.appendChild(link);
//     link.click();
//     document.body.removeChild(link);

//     setSnackbar({
//       open: true,
//       message: `CSVのダウンロードが完了しました。ファイル名: ${fileName}`,
//       severity: 'success',
//     });
//   };

//   // レンダリング部分
//   return (
//     <Paper elevation={3} sx={{ p: 2, mt: 0 }}>
//       <Grid container spacing={2}>
//         {/* カラム一括編集 */}
//         <Grid item xs={12} md={4}>
//           <Typography variant="subtitle1">カラム一括編集</Typography>
//           <TextField
//             select
//             label="列名を選択"
//             value={selectedColumn}
//             onChange={(e) => setSelectedColumn(e.target.value)}
//             variant="outlined"
//             margin="normal"
//             fullWidth
//             SelectProps={{
//               native: true,
//             }}
//           >
//             <option value="" disabled>
//               列名を選択してください
//             </option>
//             {Array.isArray(columns) &&
//               columns.map((column, index) => (
//                 <option key={index} value={column}>
//                   {column}
//                 </option>
//               ))}
//           </TextField>
//           <TextField
//             type="text"
//             value={newValue}
//             onChange={(e) => setNewValue(e.target.value)}
//             label="新しい値"
//             variant="outlined"
//             margin="normal"
//             fullWidth
//           />
//           <Button
//             onClick={handleBulkEdit}
//             variant="contained"
//             color="primary"
//             fullWidth
//           >
//             アップデート
//           </Button>
//         </Grid>

//         {/* 削除ワード入力と管理ボタン */}
//         <Grid item xs={12} md={4}>
//           <Typography variant="subtitle1">削除ワードによる行の削除</Typography>
//           <Select
//             isMulti
//             isClearable
//             onChange={handleDeleteWordsChange}
//             options={deleteWords}
//             value={deleteWords}
//             placeholder="削除したいワードを入力"
//             components={{
//               DropdownIndicator: null,
//             }}
//             styles={{
//               multiValue: (styles) => ({
//                 ...styles,
//                 backgroundColor: '#e0e0e0',
//               }),
//               multiValueLabel: (styles) => ({
//                 ...styles,
//                 color: '#333',
//               }),
//               multiValueRemove: (styles) => ({
//                 ...styles,
//                 color: '#666',
//                 ':hover': {
//                   backgroundColor: '#ccc',
//                   color: '#000',
//                 },
//               }),
//             }}
//           />
//           <Button
//             onClick={openManageModal}
//             variant="outlined"
//             color="primary"
//             fullWidth
//             sx={{ mt: 1 }}
//           >
//             ワードを管理
//           </Button>
//           <Button
//             onClick={handleDeleteRowsByWord}
//             variant="contained"
//             color="secondary"
//             fullWidth
//             sx={{ mt: 1 }}
//           >
//             指定ワードを含む行を削除
//           </Button>
//           <Button
//             onClick={() => setIsDeletionLogOpen(true)}
//             variant="outlined"
//             color="secondary"
//             fullWidth
//             sx={{ mt: 1 }}
//           >
//             削除された商品を見る
//           </Button>
//         </Grid>

//         {/* カラム設定マネージャーをここに追加 */}
//         <Grid item xs={12} md={4}>
//           <Typography variant="subtitle1">カラム設定</Typography>
//           <ColumnSettingsManager
//             data={data}
//             setData={setData}
//             initialData={initialData}
//             setInitialData={setInitialData}
//             columns={columns}
//             setColumns={setColumns}
//             columnOrderTemplates={columnOrderTemplates}
//             setColumnOrderTemplates={setColumnOrderTemplates}
//             applyColumnTemplate={applyColumnTemplate}
//             selectedTemplateName={selectedTemplateName}
//             setSelectedTemplateName={setSelectedTemplateName}
//             setColumnOrder={setColumnOrder}
//             columnWidth={columnWidth}
//             setColumnWidth={setColumnWidth}
//           />
//         </Grid>
//       </Grid>

//       {/* エラーメッセージ表示 */}
//       {error && (
//         <Typography color="error" variant="body1" sx={{ mt: 2 }}>
//           {error}
//         </Typography>
//       )}

//       {/* 確認ダイアログ */}
//       <Modal
//         isOpen={isConfirmOpen}
//         onRequestClose={cancelDeleteRows}
//         contentLabel="削除確認"
//         style={confirmDialogStyles}
//       >
//         <div className="confirm-dialog-content">
//           <p>以下の {rowsToDelete.length} 行を削除します。よろしいですか？</p>
//           <ul>
//             {rowsToDelete.map((rowIndex, idx) => (
//               <li key={idx}>
//                 行 {rowIndex + 1}:{' '}
//                 {titleIndex !== -1 &&
//                   `Title="${data[rowIndex][columns[titleIndex]]}" `}
//                 {jpTitleIndex !== -1 &&
//                   `jp_title="${data[rowIndex][columns[jpTitleIndex]]}" `}
//                 {jpDescIndex !== -1 &&
//                   `jp_desc="${data[rowIndex][columns[jpDescIndex]]}"`}
//               </li>
//             ))}
//           </ul>
//           <Button onClick={confirmDeleteRows} variant="contained" color="secondary">
//             削除する
//           </Button>
//           <Button
//             onClick={cancelDeleteRows}
//             variant="outlined"
//             color="primary"
//             sx={{ ml: 2 }}
//           >
//             キャンセル
//           </Button>
//         </div>
//       </Modal>

//       {/* 削除ログモーダル */}
//       <Modal
//         isOpen={isDeletionLogOpen}
//         onRequestClose={() => setIsDeletionLogOpen(false)}
//         contentLabel="削除された商品"
//         style={deletionLogModalStyles}
//       >
//         <div className="deletion-log-content">
//           <h3>削除された商品</h3>
//           <Button
//             onClick={() => setIsDeletionLogOpen(false)}
//             variant="outlined"
//             color="primary"
//             sx={{ mb: 2 }}
//           >
//             閉じる
//           </Button>
//           {deletionLog.length > 0 ? (
//             <table className="deleted-items-table">
//               <thead>
//                 <tr>
//                   <th>行番号</th>
//                   {titleIndex !== -1 && <th>Title</th>}
//                   {jpTitleIndex !== -1 && <th>jp_title</th>}
//                   {jpDescIndex !== -1 && <th>jp_desc</th>}
//                   <th>操作</th>
//                 </tr>
//               </thead>
//               <tbody>
//                 {deletionLog.map((log, index) => (
//                   <tr key={index}>
//                     <td>{log.rowIndex + 1}</td>
//                     {titleIndex !== -1 && (
//                       <td>{log.rowData[columns[titleIndex]]}</td>
//                     )}
//                     {jpTitleIndex !== -1 && (
//                       <td>{log.rowData[columns[jpTitleIndex]]}</td>
//                     )}
//                     {jpDescIndex !== -1 && (
//                       <td>{log.rowData[columns[jpDescIndex]]}</td>
//                     )}
//                     <td>
//                       <Button
//                         onClick={() => handleRestoreRow(log)}
//                         variant="contained"
//                         color="primary"
//                       >
//                         復元
//                       </Button>
//                     </td>
//                   </tr>
//                 ))}
//               </tbody>
//             </table>
//           ) : (
//             <p>削除された商品はありません。</p>
//           )}
//           <p>復元された商品はデータの末尾に追加されます。</p>
//         </div>
//       </Modal>

//       {/* ワード管理モーダル */}
//       <Modal
//         isOpen={isManageModalOpen}
//         onRequestClose={closeManageModal}
//         contentLabel="削除ワード管理"
//         style={manageModalStyles}
//       >
//         {/* 改善されたモーダルの内容 */}
//         <Box sx={{ p: 2 }}>
//           <Typography variant="h6" gutterBottom>
//             削除ワードを管理
//           </Typography>
//           {/* テンプレートと削除ワードを横並びに配置 */}
//           <Grid container spacing={2}>
//             {/* テンプレートを選択 */}
//             <Grid item xs={12} md={6}>
//               <Typography variant="subtitle1" gutterBottom>
//                 テンプレートを選択
//               </Typography>
//               <Button
//                 onClick={handleCreateNewTemplate}
//                 variant="outlined"
//                 color="primary"
//                 size="small"
//                 sx={{ mb: 1 }}
//               >
//                 新しいテンプレート
//               </Button>
//               <Box sx={{ mb: 2 }}>
//                 {templates.map((template, index) => (
//                   <Chip
//                     key={index}
//                     label={template.name}
//                     onClick={() => applyTemplate(template)}
//                     onDelete={() => deleteTemplate(template)}
//                     color={
//                       selectedTemplateNameLocal === template.name ? 'primary' : 'default'
//                     }
//                     sx={{ m: 0.5 }}
//                   />
//                 ))}
//               </Box>
//               <TextField
//                 label="新しいテンプレート名"
//                 value={newTemplateName}
//                 onChange={(e) => setNewTemplateName(e.target.value)}
//                 variant="outlined"
//                 size="small"
//                 fullWidth
//                 sx={{ mt: 1 }}
//               />
//               <Button
//                 onClick={saveTemplate}
//                 variant="contained"
//                 color="primary"
//                 size="small"
//                 fullWidth
//                 sx={{ mt: 1 }}
//               >
//                 テンプレートを保存
//               </Button>
//             </Grid>

//             {/* 削除ワードの管理 */}
//             <Grid item xs={12} md={6}>
//               <Typography variant="subtitle1" gutterBottom>
//                 削除ワードの管理
//               </Typography>
//               {/* 検索バー */}
//               <TextField
//                 label="削除ワードを検索"
//                 variant="outlined"
//                 size="small"
//                 fullWidth
//                 value={searchQuery}
//                 onChange={(e) => setSearchQuery(e.target.value)}
//                 InputProps={{
//                   endAdornment: (
//                     <InputAdornment position="end">
//                       <SearchIcon />
//                     </InputAdornment>
//                   ),
//                 }}
//               />
//               {/* 新しいワードの追加 */}
//               <Box sx={{ display: 'flex', mt: 1 }}>
//                 <TextField
//                   placeholder="新しいワードを入力"
//                   variant="outlined"
//                   size="small"
//                   fullWidth
//                   onKeyDown={(e) => {
//                     if (e.key === 'Enter') {
//                       e.preventDefault();
//                       const newWord = e.target.value.trim();
//                       if (newWord) {
//                         handleManageAddWord(newWord);
//                         e.target.value = '';
//                       }
//                     }
//                   }}
//                 />
//                 <Button
//                   onClick={() => {
//                     const input = document.querySelector(
//                       '.manage-words-section input'
//                     );
//                     const newWord = input.value.trim();
//                     if (newWord) {
//                       handleManageAddWord(newWord);
//                       input.value = '';
//                     }
//                   }}
//                   variant="contained"
//                   color="primary"
//                   size="small"
//                   sx={{ ml: 1 }}
//                 >
//                   追加
//                 </Button>
//               </Box>
//               {/* 一括インポート機能 */}
//               <Box sx={{ mt: 1 }}>
//                 <TextField
//                   placeholder="一括でワードを入力（カンマ、改行で区切る）"
//                   multiline
//                   rows={3}
//                   value={importText}
//                   onChange={(e) => setImportText(e.target.value)}
//                   variant="outlined"
//                   fullWidth
//                   size="small"
//                 />
//                 <Button
//                   onClick={handleImportWords}
//                   variant="contained"
//                   color="primary"
//                   size="small"
//                   fullWidth
//                   sx={{ mt: 1 }}
//                 >
//                   一括インポート
//                 </Button>
//               </Box>
//               {/* すべてクリアボタンの追加 */}
//               <Button
//                 onClick={handleClearAllWords}
//                 variant="outlined"
//                 color="secondary"
//                 size="small"
//                 fullWidth
//                 sx={{ mt: 1 }}
//               >
//                 すべてクリア
//               </Button>
//               {/* 削除ワードリスト */}
//               <div
//                 style={{
//                   maxHeight: '200px',
//                   overflow: 'auto',
//                   marginTop: '8px',
//                   border: '1px solid #ccc',
//                   padding: '8px',
//                 }}
//               >
//                 {filteredDeleteWords.length > 0 ? (
//                   <Box
//                     sx={{
//                       display: 'flex',
//                       flexWrap: 'wrap',
//                       gap: '8px',
//                     }}
//                   >
//                     {filteredDeleteWords.map((word, index) => (
//                       <Chip
//                         key={index}
//                         label={word.label}
//                         onDelete={() => handleManageDeleteWord(word)}
//                         sx={{ m: 0.5 }}
//                         size="small"
//                       />
//                     ))}
//                   </Box>
//                 ) : (
//                   <Typography variant="body2">削除ワードがありません。</Typography>
//                 )}
//               </div>
//             </Grid>
//           </Grid>
//           {/* 閉じるボタン */}
//           <Button
//             onClick={closeManageModal}
//             variant="outlined"
//             color="primary"
//             size="small"
//             fullWidth
//             sx={{ mt: 2 }}
//           >
//             閉じる
//           </Button>
//         </Box>
//       </Modal>

//       {/* Snackbarによる通知 */}
//       <Snackbar
//         open={snackbar.open}
//         autoHideDuration={6000}
//         onClose={() => setSnackbar({ ...snackbar, open: false })}
//       >
//         <Alert
//           onClose={() => setSnackbar({ ...snackbar, open: false })}
//           severity={snackbar.severity}
//           sx={{ width: '100%' }}
//         >
//           {snackbar.message}
//         </Alert>
//       </Snackbar>
//     </Paper>
//   );
// }

// BulkColumnEdit.propTypes = {
//   data: PropTypes.array.isRequired,
//   setData: PropTypes.func.isRequired,
//   initialData: PropTypes.array.isRequired,
//   setInitialData: PropTypes.func.isRequired,
//   columns: PropTypes.array.isRequired,
//   setColumns: PropTypes.func.isRequired,
//   userId: PropTypes.string.isRequired,
//   setIsDataModified: PropTypes.func.isRequired,
//   columnOrderTemplates: PropTypes.object.isRequired,
//   setColumnOrderTemplates: PropTypes.func.isRequired,
//   applyColumnTemplate: PropTypes.func.isRequired,
//   selectedTemplateName: PropTypes.string.isRequired,
//   setSelectedTemplateName: PropTypes.func.isRequired,
//   setColumnOrder: PropTypes.func.isRequired,
//   columnWidth: PropTypes.number.isRequired,
//   setColumnWidth: PropTypes.func.isRequired,
// };

// export default BulkColumnEdit;





// // BulkColumnEdit.js

// import React, { useState, useEffect } from 'react';
// import Select from 'react-select/creatable';
// import Modal from 'react-modal';
// import Papa from 'papaparse';
// import {
//   Box,
//   Button,
//   TextField,
//   Typography,
//   Grid,
//   Paper,
//   Snackbar,
//   Alert,
//   Chip,
//   InputAdornment,
// } from '@mui/material';
// import PropTypes from 'prop-types';
// import {
//   doc,
//   setDoc,
//   onSnapshot,
// } from 'firebase/firestore';
// import { db } from './firebaseConfig';
// import DeleteIcon from '@mui/icons-material/Delete';
// import SearchIcon from '@mui/icons-material/Search';
// import ColumnSettingsManager from './ColumnSettingsManager'; // インポートを追加

// // モーダルのアクセシビリティ設定
// Modal.setAppElement('#root');

// function BulkColumnEdit({
//   data = [],
//   setData,
//   columns = [],
//   setColumns, // ここで setColumns を受け取る
//   userId,
//   setIsDataModified,
//   // 以下を追加
//   columnOrderTemplates,
//   setColumnOrderTemplates,
//   applyColumnTemplate,
//   selectedTemplateName,
//   setSelectedTemplateName,
//   setColumnOrder,
//   columnWidth,
//   setColumnWidth,
// }) {
//   // ステート変数の定義
//   const [selectedColumn, setSelectedColumn] = useState('');
//   const [newValue, setNewValue] = useState('');
//   const [snackbar, setSnackbar] = useState({
//     open: false,
//     message: '',
//     severity: 'info',
//   });

//   // 削除ワード関連のステート
//   const [error, setError] = useState(null);
//   const [deleteWords, setDeleteWords] = useState([]); // 削除ワードのステート
//   const [isConfirmOpen, setIsConfirmOpen] = useState(false);
//   const [rowsToDelete, setRowsToDelete] = useState([]);
//   const [deletionLog, setDeletionLog] = useState([]);
//   const [titleIndex, setTitleIndex] = useState(-1);
//   const [jpTitleIndex, setJpTitleIndex] = useState(-1);
//   const [jpDescIndex, setJpDescIndex] = useState(-1); // jp_descのインデックス
//   const [isDeletionLogOpen, setIsDeletionLogOpen] = useState(false);
//   const [isManageModalOpen, setIsManageModalOpen] = useState(false); // 管理モーダルのステート
//   const [importText, setImportText] = useState(''); // 一括インポート用のローカルステート

//   // 検索機能のステート
//   const [searchQuery, setSearchQuery] = useState(''); // 削除ワード検索用
//   const [filteredDeleteWords, setFilteredDeleteWords] = useState([]); // フィルタされた削除ワード

//   // テンプレート関連のステート
//   const [templates, setTemplates] = useState([]); // テンプレートのリスト
//   const [selectedTemplateNameLocal, setSelectedTemplateNameLocal] = useState(''); // 選択されたテンプレート名（ローカル）
//   const [newTemplateName, setNewTemplateName] = useState(''); // 新しいテンプレート名

//   // Effect hooks
//   useEffect(() => {
//     if (data && data.length > 0) {
//       const headers = Object.keys(data[0]);
//       setTitleIndex(headers.findIndex((header) => header.toLowerCase() === 'title'));
//       setJpTitleIndex(headers.findIndex((header) => header.toLowerCase() === 'jp_title'));
//       setJpDescIndex(headers.findIndex((header) => header.toLowerCase() === 'jp_desc'));
//     }
//   }, [data]);

//   // テンプレートをFirestoreから読み込む
//   useEffect(() => {
//     if (!userId) return;

//     const docRef = doc(db, 'users', userId, 'templates', 'deleteWordTemplates');
//     const unsubscribe = onSnapshot(docRef, (docSnap) => {
//       if (docSnap.exists()) {
//         setTemplates(docSnap.data().templates || []);
//       }
//     });

//     return () => unsubscribe();
//   }, [userId]);

//   // 削除ワードをFirestoreから読み込む
//   useEffect(() => {
//     if (!userId) return;

//     const docRef = doc(db, 'users', userId, 'deleteWords', 'words');
//     const unsubscribe = onSnapshot(docRef, (docSnap) => {
//       if (docSnap.exists()) {
//         const wordsArray = docSnap.data().words || [];
//         const formattedWords = wordsArray.map((word) => ({ value: word, label: word }));
//         setDeleteWords(formattedWords);
//       }
//     });

//     return () => unsubscribe();
//   }, [userId]);

//   // 選択されたテンプレート名をFirestoreから読み込む
//   useEffect(() => {
//     if (!userId) return;

//     const docRef = doc(db, 'users', userId, 'selectedTemplate', 'name');
//     const unsubscribe = onSnapshot(docRef, (docSnap) => {
//       if (docSnap.exists()) {
//         setSelectedTemplateNameLocal(docSnap.data().selectedTemplateName || '');
//       }
//     });

//     return () => unsubscribe();
//   }, [userId]);

//   // テンプレートの変更をFirestoreに保存
//   useEffect(() => {
//     if (!userId) return;
//     if (templates.length === 0) return;

//     const saveTemplates = async () => {
//       try {
//         const docRef = doc(db, 'users', userId, 'templates', 'deleteWordTemplates');
//         await setDoc(docRef, { templates }, { merge: true });
//       } catch (error) {
//         console.error('Error saving templates to Firestore:', error);
//         setError('テンプレートの保存に失敗しました。');
//       }
//     };
//     saveTemplates();
//   }, [templates, userId]);

//   // 削除ワードの変更をFirestoreに保存
//   useEffect(() => {
//     if (!userId) return;
//     if (deleteWords.length === 0) return;

//     const wordsArray = deleteWords.map((word) => word.value);

//     const saveDeleteWords = async () => {
//       try {
//         const docRef = doc(db, 'users', userId, 'deleteWords', 'words');
//         await setDoc(docRef, { words: wordsArray }, { merge: true });
//       } catch (error) {
//         console.error('Error saving delete words to Firestore:', error);
//         setError('削除ワードの保存に失敗しました。');
//       }
//     };
//     saveDeleteWords();
//   }, [deleteWords, userId]);

//   // 選択されたテンプレート名をFirestoreに保存
//   useEffect(() => {
//     if (!userId) return;
//     if (!selectedTemplateNameLocal) return;

//     const saveSelectedTemplateName = async () => {
//       try {
//         const docRef = doc(db, 'users', userId, 'selectedTemplate', 'name');
//         await setDoc(docRef, { selectedTemplateName: selectedTemplateNameLocal }, { merge: true });
//       } catch (error) {
//         console.error('Error saving selected template name to Firestore:', error);
//         setError('選択されたテンプレート名の保存に失敗しました。');
//       }
//     };
//     saveSelectedTemplateName();
//   }, [selectedTemplateNameLocal, userId]);

//   // テンプレートまたはテンプレートのリストが更新されたときに、選択されたテンプレートを適用
//   useEffect(() => {
//     if (selectedTemplateNameLocal && templates.length > 0) {
//       const template = templates.find((t) => t.name === selectedTemplateNameLocal);
//       if (template) {
//         // テンプレートを適用
//         const formattedWords = template.words.map((word) => ({
//           value: word,
//           label: word,
//         }));
//         setDeleteWords(formattedWords);
//       } else {
//         // テンプレートが見つからない場合、選択を解除
//         setSelectedTemplateNameLocal('');
//       }
//     }
//   }, [selectedTemplateNameLocal, templates]);

//   // 削除ワードの検索・フィルタリング
//   useEffect(() => {
//     if (searchQuery.trim() === '') {
//       setFilteredDeleteWords(deleteWords);
//     } else {
//       const lowerQuery = searchQuery.toLowerCase();
//       setFilteredDeleteWords(
//         deleteWords.filter((word) =>
//           word.value.toLowerCase().includes(lowerQuery)
//         )
//       );
//     }
//   }, [searchQuery, deleteWords]);

//   // 削除ワード機能の関数

//   const splitTitleIntoWords = (title) => {
//     if (!title) return [];
//     return title.split(/\s+/).filter(Boolean);
//   };

//   const handleDeleteWordsChange = (newWords) => {
//     setDeleteWords(newWords || []);
//   };

//   const handleDeleteRowsByWord = () => {
//     if (deleteWords.length === 0) {
//       setError('削除したいワードを入力してください。');
//       return;
//     }

//     if (data.length === 0) {
//       setError('データがありません。');
//       return;
//     }

//     try {
//       if (titleIndex === -1 && jpTitleIndex === -1 && jpDescIndex === -1) {
//         setError('"Title" カラム、"jp_title" カラム、または "jp_desc" カラムが見つかりません。');
//         return;
//       }

//       const words = deleteWords.map((word) => word.value).filter((w) => w);

//       if (words.length === 0) {
//         setError('有効な削除ワードがありません。');
//         return;
//       }

//       const wordsLower = words.map((word) => word.toLowerCase());

//       const rowsToDeleteTemp = [];
//       const deletionLogTemp = [];

//       for (let i = 0; i < data.length; i++) {
//         const row = data[i];
//         const titleCell = titleIndex !== -1 ? row[columns[titleIndex]] || '' : '';
//         const jpTitleCell = jpTitleIndex !== -1 ? row[columns[jpTitleIndex]] || '' : '';
//         const jpDescCell = jpDescIndex !== -1 ? row[columns[jpDescIndex]] || '' : '';

//         // タイトルと説明を空白で分割して単語の配列を取得
//         const titleWords = splitTitleIntoWords(titleCell);
//         const jpTitleWords = splitTitleIntoWords(jpTitleCell);
//         const jpDescWords = splitTitleIntoWords(jpDescCell);

//         const titleWordsLower = titleWords.map((word) => word.toLowerCase());
//         const jpTitleWordsLower = jpTitleWords.map((word) => word.toLowerCase());
//         const jpDescWordsLower = jpDescWords.map((word) => word.toLowerCase());

//         // 削除ワードとタイトルおよび説明内の単語を比較
//         const titleMatches = wordsLower.some((word) => titleWordsLower.includes(word));
//         const jpTitleMatches = wordsLower.some((word) => jpTitleWordsLower.includes(word));
//         const jpDescMatches = wordsLower.some((word) => jpDescWordsLower.includes(word));

//         if (titleMatches || jpTitleMatches || jpDescMatches) {
//           rowsToDeleteTemp.push(i);
//           deletionLogTemp.push({
//             rowIndex: i,
//             rowData: row,
//           });
//         }
//       }

//       if (rowsToDeleteTemp.length === 0) {
//         setError('指定したワードを含む行は見つかりませんでした。');
//         return;
//       }

//       rowsToDeleteTemp.sort((a, b) => b - a);

//       setRowsToDelete(rowsToDeleteTemp);
//       setDeletionLog((prevLog) => [...prevLog, ...deletionLogTemp]);
//       setIsConfirmOpen(true);
//       setError(null);
//     } catch (err) {
//       console.error('Error preparing rows for deletion:', err);
//       setError('行の削除準備中にエラーが発生しました');
//     }
//   };

//   const confirmDeleteRows = () => {
//     try {
//       let newData = [...data];
//       for (let rowIndex of rowsToDelete) {
//         newData.splice(rowIndex, 1);
//       }
//       setData(newData);
//       setIsConfirmOpen(false);
//       setRowsToDelete([]);
//       setError(null);
//       setIsDataModified(true); // データが変更されたので状態を更新
//     } catch (err) {
//       console.error('Error deleting rows:', err);
//       setError('行の削除に失敗しました');
//       setIsConfirmOpen(false);
//     }
//   };

//   const cancelDeleteRows = () => {
//     setIsConfirmOpen(false);
//     setRowsToDelete([]);
//   };

//   const handleRestoreRow = (log) => {
//     try {
//       const newData = [...data];
//       newData.push(log.rowData);
//       setData(newData);

//       setDeletionLog((prevLog) => prevLog.filter((item) => item !== log));

//       setError(null);
//       setIsDataModified(true); // データが変更されたので状態を更新
//     } catch (err) {
//       console.error('Error restoring row:', err);
//       setError('行の復元に失敗しました');
//     }
//   };

//   // ワード管理用モーダルのハンドラー
//   const openManageModal = () => {
//     setIsManageModalOpen(true);
//   };

//   const closeManageModal = () => {
//     setIsManageModalOpen(false);
//   };

//   // モーダル内でのワード管理（追加・削除）
//   const handleManageAddWord = (newWord) => {
//     if (newWord && !deleteWords.some((word) => word.value === newWord)) {
//       setDeleteWords([...deleteWords, { value: newWord, label: newWord }]);
//     }
//   };

//   const handleManageDeleteWord = (wordToDelete) => {
//     setDeleteWords(
//       deleteWords.filter((word) => word.value !== wordToDelete.value)
//     );
//   };

//   // 一括インポート機能のハンドラー
//   const handleImportWords = () => {
//     if (importText.trim() === '') return;

//     // カンマ、全角カンマ、改行で分割
//     const importedWords = importText
//       .split(/[,、\n]+/)
//       .map((w) => w.trim())
//       .filter((w) => w);
//     const uniqueImportedWords = Array.from(new Set(importedWords));
//     const formattedImportedWords = uniqueImportedWords.map((w) => ({
//       value: w,
//       label: w,
//     }));

//     setDeleteWords((prevWords) => {
//       const updatedWords = [...prevWords];
//       formattedImportedWords.forEach((w) => {
//         if (!updatedWords.some((word) => word.value === w.value)) {
//           updatedWords.push(w);
//         }
//       });
//       return updatedWords;
//     });

//     setImportText(''); // テキストエリアをクリア
//   };

//   // テンプレートを保存
//   const saveTemplate = () => {
//     if (!newTemplateName.trim()) {
//       setError('テンプレート名を入力してください。');
//       return;
//     }

//     // 同じ名前のテンプレートが存在するか確認
//     const existingTemplate = templates.find(
//       (t) => t.name === newTemplateName.trim()
//     );
//     if (existingTemplate) {
//       setError('同じ名前のテンプレートが既に存在します。別の名前を使用してください。');
//       return;
//     }

//     // 削除ワードを文字列の配列に変換
//     const wordsArray = deleteWords.map((word) => word.value);

//     const newTemplate = {
//       name: newTemplateName.trim(),
//       words: wordsArray,
//     };

//     setTemplates([...templates, newTemplate]);
//     setNewTemplateName('');
//     setSnackbar({
//       open: true,
//       message: `テンプレート "${newTemplateName.trim()}" を保存しました。`,
//       severity: 'success',
//     });
//     setError(null);
//   };

//   // テンプレートを適用
//   const applyTemplate = (template) => {
//     // 削除ワードを { value, label } の形式に変換
//     const formattedWords = template.words.map((word) => ({
//       value: word,
//       label: word,
//     }));
//     setDeleteWords(formattedWords);
//     setSelectedTemplateNameLocal(template.name);
//   };

//   // テンプレートを削除
//   const deleteTemplate = (templateToDelete) => {
//     const updatedTemplates = templates.filter((t) => t.name !== templateToDelete.name);
//     setTemplates(updatedTemplates);
//     if (selectedTemplateNameLocal === templateToDelete.name) {
//       setSelectedTemplateNameLocal('');
//       setDeleteWords([]);
//     }
//   };

//   // 新しいテンプレートの作成
//   const handleCreateNewTemplate = () => {
//     setDeleteWords([]); // 削除ワードをクリア
//     setSelectedTemplateNameLocal(''); // 選択されたテンプレート名をクリア
//     setNewTemplateName(''); // 新しいテンプレート名をクリア
//     setSnackbar({
//       open: true,
//       message: '新しいテンプレートを作成します。削除ワードを追加してください。',
//       severity: 'info',
//     });
//   };

//   // 削除ワードをすべてクリア
//   const handleClearAllWords = () => {
//     setDeleteWords([]);
//     setSnackbar({
//       open: true,
//       message: '削除ワードをすべてクリアしました。',
//       severity: 'info',
//     });
//   };

//   // モーダルのスタイル設定
//   const confirmDialogStyles = {
//     content: {
//       top: '50%',
//       left: '50%',
//       right: 'auto',
//       bottom: 'auto',
//       transform: 'translate(-50%, -50%)',
//       width: '70%', // 幅を70%に拡大
//       maxHeight: '80%', // 高さを80%に拡大
//       overflow: 'auto',
//       backgroundColor: '#fff', // 背景を白に設定
//       zIndex: 1001, // モーダルのコンテンツのz-indexを設定
//     },
//     overlay: {
//       backgroundColor: 'rgba(0, 0, 0, 0.7)', // オーバーレイの不透明度を上げる
//       zIndex: 1000, // オーバーレイのz-indexを設定
//     },
//   };

//   const deletionLogModalStyles = {
//     content: {
//       top: '50%',
//       left: '50%',
//       right: 'auto',
//       bottom: 'auto',
//       transform: 'translate(-50%, -50%)',
//       width: '90%', // 幅を90%に拡大
//       maxHeight: '90%', // 高さを90%に拡大
//       overflow: 'auto',
//       backgroundColor: '#fff', // 背景を白に設定
//       zIndex: 1001, // モーダルのコンテンツのz-indexを設定
//     },
//     overlay: {
//       backgroundColor: 'rgba(0, 0, 0, 0.7)', // オーバーレイの不透明度を上げる
//       zIndex: 1000, // オーバーレイのz-indexを設定
//     },
//   };

//   const manageModalStyles = {
//     content: {
//       top: '50%',
//       left: '50%',
//       right: 'auto',
//       bottom: 'auto',
//       transform: 'translate(-50%, -50%)',
//       width: '80%', // 幅を80%に拡大
//       maxHeight: '90%', // 高さを90%に拡大
//       overflow: 'auto',
//       backgroundColor: '#fff', // 背景を白に設定
//       zIndex: 1001, // モーダルのコンテンツのz-indexを設定
//     },
//     overlay: {
//       backgroundColor: 'rgba(0, 0, 0, 0.7)', // オーバーレイの不透明度を上げる
//       zIndex: 1000, // オーバーレイのz-indexを設定
//     },
//   };

//   // カラム一括編集のハンドラー
//   const handleBulkEdit = () => {
//     if (!selectedColumn || !newValue) {
//       setSnackbar({
//         open: true,
//         message: '列名と新しい値を入力してください',
//         severity: 'error',
//       });
//       return;
//     }

//     const updatedData = data.map((row) => ({
//       ...row,
//       [selectedColumn]: newValue,
//     }));

//     setData(updatedData);
//     setSnackbar({
//       open: true,
//       message: `列 "${selectedColumn}" が更新されました`,
//       severity: 'success',
//     });
//     setIsDataModified(true); // データが変更されたので状態を更新
//   };

//   // CSVダウンロードのハンドラー
//   const handleCsvDownload = () => {
//     if (!data || data.length === 0) {
//       setSnackbar({
//         open: true,
//         message: 'ダウンロードするデータがありません',
//         severity: 'error',
//       });
//       return;
//     }

//     const csv = Papa.unparse(data);
//     const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });

//     // 現在の日付を取得
//     const currentDate = new Date().toISOString().split('T')[0]; // YYYY-MM-DD形式

//     // 最初の商品タイトルを取得し、先頭10文字を抜き出す
//     const firstTitle = data[0]['Title'] || data[0]['title'] || 'Untitled';
//     const truncatedTitle = firstTitle.substring(0, 10);

//     // ファイル名に使用できない文字を置換する関数
//     const sanitizeFileName = (name) => name.replace(/[\\/:*?"<>|]/g, '_');

//     // サニタイズしたタイトルを取得
//     const safeTitle = sanitizeFileName(truncatedTitle);

//     // ファイル名を生成
//     const fileName = `${safeTitle}_${currentDate}.csv`;

//     const link = document.createElement('a');
//     link.href = URL.createObjectURL(blob);
//     link.setAttribute('download', fileName);
//     document.body.appendChild(link);
//     link.click();
//     document.body.removeChild(link);

//     setSnackbar({
//       open: true,
//       message: `CSVのダウンロードが完了しました。ファイル名: ${fileName}`,
//       severity: 'success',
//     });
//   };

//   // レンダリング部分
//   return (
//     <Paper elevation={3} sx={{ p: 2, mt: 0 }}>
//       <Grid container spacing={2}>
//         {/* カラム一括編集 */}
//         <Grid item xs={12} md={4}>
//           <Typography variant="subtitle1">カラム一括編集</Typography>
//           <TextField
//             select
//             label="列名を選択"
//             value={selectedColumn}
//             onChange={(e) => setSelectedColumn(e.target.value)}
//             variant="outlined"
//             margin="normal"
//             fullWidth
//             SelectProps={{
//               native: true,
//             }}
//           >
//             <option value="" disabled>
//               列名を選択してください
//             </option>
//             {Array.isArray(columns) &&
//               columns.map((column, index) => (
//                 <option key={index} value={column}>
//                   {column}
//                 </option>
//               ))}
//           </TextField>
//           <TextField
//             type="text"
//             value={newValue}
//             onChange={(e) => setNewValue(e.target.value)}
//             label="新しい値"
//             variant="outlined"
//             margin="normal"
//             fullWidth
//           />
//           <Button
//             onClick={handleBulkEdit}
//             variant="contained"
//             color="primary"
//             fullWidth
//           >
//             アップデート
//           </Button>
//         </Grid>

//         {/* 削除ワード入力と管理ボタン */}
//         <Grid item xs={12} md={4}>
//           <Typography variant="subtitle1">削除ワードによる行の削除</Typography>
//           <Select
//             isMulti
//             isClearable
//             onChange={handleDeleteWordsChange}
//             options={deleteWords}
//             value={deleteWords}
//             placeholder="削除したいワードを入力"
//             components={{
//               DropdownIndicator: null,
//             }}
//             styles={{
//               multiValue: (styles) => ({
//                 ...styles,
//                 backgroundColor: '#e0e0e0',
//               }),
//               multiValueLabel: (styles) => ({
//                 ...styles,
//                 color: '#333',
//               }),
//               multiValueRemove: (styles) => ({
//                 ...styles,
//                 color: '#666',
//                 ':hover': {
//                   backgroundColor: '#ccc',
//                   color: '#000',
//                 },
//               }),
//             }}
//           />
//           <Button
//             onClick={openManageModal}
//             variant="outlined"
//             color="primary"
//             fullWidth
//             sx={{ mt: 1 }}
//           >
//             ワードを管理
//           </Button>
//           <Button
//             onClick={handleDeleteRowsByWord}
//             variant="contained"
//             color="secondary"
//             fullWidth
//             sx={{ mt: 1 }}
//           >
//             指定ワードを含む行を削除
//           </Button>
//           <Button
//             onClick={() => setIsDeletionLogOpen(true)}
//             variant="outlined"
//             color="secondary"
//             fullWidth
//             sx={{ mt: 1 }}
//           >
//             削除された商品を見る
//           </Button>
//         </Grid>

//         {/* CSVダウンロード */}
//         <Grid item xs={12} md={4}>
//           <Typography variant="subtitle1">CSVダウンロード</Typography>
//           <Button
//             onClick={handleCsvDownload}
//             variant="contained"
//             color="primary"
//             fullWidth
//             sx={{ mt: 4 }}
//           >
//             CSVをダウンロード
//           </Button>
//           {/* カラム設定マネージャーをここに追加 */}
//           <ColumnSettingsManager
//             columns={columns}
//             setColumns={setColumns} // ここで setColumns を渡す
//             columnOrderTemplates={columnOrderTemplates}
//             setColumnOrderTemplates={setColumnOrderTemplates}
//             applyColumnTemplate={applyColumnTemplate}
//             selectedTemplateName={selectedTemplateName}
//             setSelectedTemplateName={setSelectedTemplateName}
//             setColumnOrder={setColumnOrder}
//             columnWidth={columnWidth}
//             setColumnWidth={setColumnWidth}
//           />
//         </Grid>
//       </Grid>

//       {/* エラーメッセージ表示 */}
//       {error && (
//         <Typography color="error" variant="body1" sx={{ mt: 2 }}>
//           {error}
//         </Typography>
//       )}

//       {/* 確認ダイアログ */}
//       <Modal
//         isOpen={isConfirmOpen}
//         onRequestClose={cancelDeleteRows}
//         contentLabel="削除確認"
//         style={confirmDialogStyles}
//       >
//         <div className="confirm-dialog-content">
//           <p>以下の {rowsToDelete.length} 行を削除します。よろしいですか？</p>
//           <ul>
//             {rowsToDelete.map((rowIndex, idx) => (
//               <li key={idx}>
//                 行 {rowIndex + 1}:{' '}
//                 {titleIndex !== -1 &&
//                   `Title="${data[rowIndex][columns[titleIndex]]}" `}
//                 {jpTitleIndex !== -1 &&
//                   `jp_title="${data[rowIndex][columns[jpTitleIndex]]}" `}
//                 {jpDescIndex !== -1 &&
//                   `jp_desc="${data[rowIndex][columns[jpDescIndex]]}"`}
//               </li>
//             ))}
//           </ul>
//           <Button onClick={confirmDeleteRows} variant="contained" color="secondary">
//             削除する
//           </Button>
//           <Button
//             onClick={cancelDeleteRows}
//             variant="outlined"
//             color="primary"
//             sx={{ ml: 2 }}
//           >
//             キャンセル
//           </Button>
//         </div>
//       </Modal>

//       {/* 削除ログモーダル */}
//       <Modal
//         isOpen={isDeletionLogOpen}
//         onRequestClose={() => setIsDeletionLogOpen(false)}
//         contentLabel="削除された商品"
//         style={deletionLogModalStyles}
//       >
//         <div className="deletion-log-content">
//           <h3>削除された商品</h3>
//           <Button
//             onClick={() => setIsDeletionLogOpen(false)}
//             variant="outlined"
//             color="primary"
//             sx={{ mb: 2 }}
//           >
//             閉じる
//           </Button>
//           {deletionLog.length > 0 ? (
//             <table className="deleted-items-table">
//               <thead>
//                 <tr>
//                   <th>行番号</th>
//                   {titleIndex !== -1 && <th>Title</th>}
//                   {jpTitleIndex !== -1 && <th>jp_title</th>}
//                   {jpDescIndex !== -1 && <th>jp_desc</th>}
//                   <th>操作</th>
//                 </tr>
//               </thead>
//               <tbody>
//                 {deletionLog.map((log, index) => (
//                   <tr key={index}>
//                     <td>{log.rowIndex + 1}</td>
//                     {titleIndex !== -1 && (
//                       <td>{log.rowData[columns[titleIndex]]}</td>
//                     )}
//                     {jpTitleIndex !== -1 && (
//                       <td>{log.rowData[columns[jpTitleIndex]]}</td>
//                     )}
//                     {jpDescIndex !== -1 && (
//                       <td>{log.rowData[columns[jpDescIndex]]}</td>
//                     )}
//                     <td>
//                       <Button
//                         onClick={() => handleRestoreRow(log)}
//                         variant="contained"
//                         color="primary"
//                       >
//                         復元
//                       </Button>
//                     </td>
//                   </tr>
//                 ))}
//               </tbody>
//             </table>
//           ) : (
//             <p>削除された商品はありません。</p>
//           )}
//           <p>復元された商品はデータの末尾に追加されます。</p>
//         </div>
//       </Modal>

//       {/* ワード管理モーダル */}
//       <Modal
//         isOpen={isManageModalOpen}
//         onRequestClose={closeManageModal}
//         contentLabel="削除ワード管理"
//         style={manageModalStyles}
//       >
//         {/* 改善されたモーダルの内容 */}
//         <Box sx={{ p: 2 }}>
//           <Typography variant="h6" gutterBottom>
//             削除ワードを管理
//           </Typography>
//           {/* テンプレートと削除ワードを横並びに配置 */}
//           <Grid container spacing={2}>
//             {/* テンプレートを選択 */}
//             <Grid item xs={12} md={6}>
//               <Typography variant="subtitle1" gutterBottom>
//                 テンプレートを選択
//               </Typography>
//               <Button
//                 onClick={handleCreateNewTemplate}
//                 variant="outlined"
//                 color="primary"
//                 size="small"
//                 sx={{ mb: 1 }}
//               >
//                 新しいテンプレート
//               </Button>
//               <Box sx={{ mb: 2 }}>
//                 {templates.map((template, index) => (
//                   <Chip
//                     key={index}
//                     label={template.name}
//                     onClick={() => applyTemplate(template)}
//                     onDelete={() => deleteTemplate(template)}
//                     color={
//                       selectedTemplateNameLocal === template.name ? 'primary' : 'default'
//                     }
//                     sx={{ m: 0.5 }}
//                   />
//                 ))}
//               </Box>
//               <TextField
//                 label="新しいテンプレート名"
//                 value={newTemplateName}
//                 onChange={(e) => setNewTemplateName(e.target.value)}
//                 variant="outlined"
//                 size="small"
//                 fullWidth
//                 sx={{ mt: 1 }}
//               />
//               <Button
//                 onClick={saveTemplate}
//                 variant="contained"
//                 color="primary"
//                 size="small"
//                 fullWidth
//                 sx={{ mt: 1 }}
//               >
//                 テンプレートを保存
//               </Button>
//             </Grid>

//             {/* 削除ワードの管理 */}
//             <Grid item xs={12} md={6}>
//               <Typography variant="subtitle1" gutterBottom>
//                 削除ワードの管理
//               </Typography>
//               {/* 検索バー */}
//               <TextField
//                 label="削除ワードを検索"
//                 variant="outlined"
//                 size="small"
//                 fullWidth
//                 value={searchQuery}
//                 onChange={(e) => setSearchQuery(e.target.value)}
//                 InputProps={{
//                   endAdornment: (
//                     <InputAdornment position="end">
//                       <SearchIcon />
//                     </InputAdornment>
//                   ),
//                 }}
//               />
//               {/* 新しいワードの追加 */}
//               <Box sx={{ display: 'flex', mt: 1 }}>
//                 <TextField
//                   placeholder="新しいワードを入力"
//                   variant="outlined"
//                   size="small"
//                   fullWidth
//                   onKeyDown={(e) => {
//                     if (e.key === 'Enter') {
//                       e.preventDefault();
//                       const newWord = e.target.value.trim();
//                       if (newWord) {
//                         handleManageAddWord(newWord);
//                         e.target.value = '';
//                       }
//                     }
//                   }}
//                 />
//                 <Button
//                   onClick={() => {
//                     const input = document.querySelector(
//                       '.manage-words-section input'
//                     );
//                     const newWord = input.value.trim();
//                     if (newWord) {
//                       handleManageAddWord(newWord);
//                       input.value = '';
//                     }
//                   }}
//                   variant="contained"
//                   color="primary"
//                   size="small"
//                   sx={{ ml: 1 }}
//                 >
//                   追加
//                 </Button>
//               </Box>
//               {/* 一括インポート機能 */}
//               <Box sx={{ mt: 1 }}>
//                 <TextField
//                   placeholder="一括でワードを入力（カンマ、改行で区切る）"
//                   multiline
//                   rows={3}
//                   value={importText}
//                   onChange={(e) => setImportText(e.target.value)}
//                   variant="outlined"
//                   fullWidth
//                   size="small"
//                 />
//                 <Button
//                   onClick={handleImportWords}
//                   variant="contained"
//                   color="primary"
//                   size="small"
//                   fullWidth
//                   sx={{ mt: 1 }}
//                 >
//                   一括インポート
//                 </Button>
//               </Box>
//               {/* すべてクリアボタンの追加 */}
//               <Button
//                 onClick={handleClearAllWords}
//                 variant="outlined"
//                 color="secondary"
//                 size="small"
//                 fullWidth
//                 sx={{ mt: 1 }}
//               >
//                 すべてクリア
//               </Button>
//               {/* 削除ワードリスト */}
//               <div
//                 style={{
//                   maxHeight: '200px',
//                   overflow: 'auto',
//                   marginTop: '8px',
//                   border: '1px solid #ccc',
//                   padding: '8px',
//                 }}
//               >
//                 {filteredDeleteWords.length > 0 ? (
//                   <Box
//                     sx={{
//                       display: 'flex',
//                       flexWrap: 'wrap',
//                       gap: '8px',
//                     }}
//                   >
//                     {filteredDeleteWords.map((word, index) => (
//                       <Chip
//                         key={index}
//                         label={word.label}
//                         onDelete={() => handleManageDeleteWord(word)}
//                         sx={{ m: 0.5 }}
//                         size="small"
//                       />
//                     ))}
//                   </Box>
//                 ) : (
//                   <Typography variant="body2">削除ワードがありません。</Typography>
//                 )}
//               </div>
//             </Grid>
//           </Grid>
//           {/* 閉じるボタン */}
//           <Button
//             onClick={closeManageModal}
//             variant="outlined"
//             color="primary"
//             size="small"
//             fullWidth
//             sx={{ mt: 2 }}
//           >
//             閉じる
//           </Button>
//         </Box>
//       </Modal>

//       {/* Snackbarによる通知 */}
//       <Snackbar
//         open={snackbar.open}
//         autoHideDuration={6000}
//         onClose={() => setSnackbar({ ...snackbar, open: false })}
//       >
//         <Alert
//           onClose={() => setSnackbar({ ...snackbar, open: false })}
//           severity={snackbar.severity}
//           sx={{ width: '100%' }}
//         >
//           {snackbar.message}
//         </Alert>
//       </Snackbar>
//     </Paper>
//   );
// }

// BulkColumnEdit.propTypes = {
//   data: PropTypes.array.isRequired,
//   setData: PropTypes.func.isRequired,
//   columns: PropTypes.array.isRequired,
//   setColumns: PropTypes.func.isRequired, // setColumns を追加
//   userId: PropTypes.string.isRequired,
//   setIsDataModified: PropTypes.func.isRequired,
//   // 以下を追加
//   columnOrderTemplates: PropTypes.object.isRequired,
//   setColumnOrderTemplates: PropTypes.func.isRequired,
//   applyColumnTemplate: PropTypes.func.isRequired,
//   selectedTemplateName: PropTypes.string.isRequired,
//   setSelectedTemplateName: PropTypes.func.isRequired,
//   setColumnOrder: PropTypes.func.isRequired,
//   columnWidth: PropTypes.number.isRequired,
//   setColumnWidth: PropTypes.func.isRequired,
// };

// export default BulkColumnEdit;