// applyAITitles.js

import axios from 'axios';
import PQueue from 'p-queue';

// キャッシュ機能
const titleCache = {};

const getCachedTitle = (key) => titleCache[key];
const setCachedTitle = (key, value) => {
  titleCache[key] = value;
};

// タイトル生成のための関数
export const applyAITitles = async (currentData, apiKey, progressCallback, customizationOptions) => {
  if (!apiKey) {
    throw new Error('OpenAI APIキーが設定されていません');
  }

  // カスタマイズオプションを展開
  const {
    deleteStrings = [],
    replacePairs = [],
    prependText = '',
    appendText = '',
    limitTitleLength = false, // trueなら78文字制限を行う
  } = customizationOptions;

  // p-queueの設定
  const CONCURRENCY = 2;
  const INTERVAL = 1000;
  const INTERVAL_CAP = 5;
  const queue = new PQueue({
    concurrency: CONCURRENCY,
    interval: INTERVAL,
    intervalCap: INTERVAL_CAP,
  });

  let totalItems = 0;
  let completedItems = 0;
  const errorItems = [];

  // 全ファイルに対して処理
  const updatedData = await Promise.all(
    currentData.map(async (file) => {
      const Papa = require('papaparse');
      const parsedResult = Papa.parse(file.content, { header: true });
      const data = parsedResult.data;

      totalItems += data.length;

      // タイトル生成タスクの配列
      const generateTitleTasks = data.map((item, index) => async () => {
        const originalTitle = item.Title || '';
        const jpDesc = item.jp_desc || item.JP_Description || '';

        // キャッシュキーの生成
        const cacheKey = originalTitle + jpDesc.slice(0, 150);
        const cachedTitle = getCachedTitle(cacheKey);

        if (cachedTitle) {
          // キャッシュ済みタイトルにカスタマイズ適用
          const customizedTitle = applyTitleCustomizations(
            cachedTitle,
            deleteStrings,
            replacePairs,
            prependText,
            appendText,
            limitTitleLength
          );
          item.Title = customizedTitle;
          completedItems++;
          if (progressCallback) {
            progressCallback({ totalItems, completedItems });
          }
          return;
        }

        try {
          const generatedTitle = await generateSingleTitle(
            originalTitle,
            jpDesc,
            apiKey
          );

          // カスタマイズを適用
          const customizedTitle = applyTitleCustomizations(
            generatedTitle,
            deleteStrings,
            replacePairs,
            prependText,
            appendText,
            limitTitleLength
          );

          item.Title = customizedTitle;
          setCachedTitle(cacheKey, generatedTitle);
        } catch (error) {
          console.error(`タイトル生成エラー（行 ${index + 1}）:`, error);
          // エラー時は元のタイトルをそのまま使用
          errorItems.push({
            fileName: file.name,
            rowIndex: index + 1,
            error: error.message || error,
          });
        } finally {
          completedItems++;
          if (progressCallback) {
            progressCallback({ totalItems, completedItems });
          }
        }
      });

      // タスクをキューに追加して実行
      await queue.addAll(generateTitleTasks);

      // CSVに変換
      const csvContent = Papa.unparse(data);

      return {
        ...file,
        content: csvContent,
      };
    })
  );

  return { updatedData, errorItems };
};

// 単一の商品タイトルを生成する関数
async function generateSingleTitle(originalTitle, jpDesc, apiKey) {
  const url = 'https://api.openai.com/v1/chat/completions';
  const limitedJpDesc = jpDesc.slice(0, 150);
  const requestData = {
    model: 'gpt-4o-mini',
    messages: [
      {
        role: 'system',
        content: `You are an AI assistant specializing in eBay product title optimization. Based on the given original title and Japanese product information, create an effective English title within 80 characters.
Order of title composition:
1. brand name (if applicable)
2. model name or product name/character name (if applicable)
3. product type (if applicable)
4. key features (color, material, size) (if applicable)
5. distinctive elements or uniqueness (if applicable)
6. condition (new/used) (if applicable)
7. important keywords (if applicable)
NOTES:
- Add or optimize new information while retaining important information from the original title
- Avoid unnecessary adjectives and words such as “eBay”
- Use abbreviations and common names appropriately
- Think from the searcher's perspective and include search terms that buyers are likely to use
- If information is unclear or ambiguous, omit it.
- Use only reliable information and do not include guesswork or uncertain information.
- Do not extract from Japanese descriptions that are a list of words.
- Titles should be generated in English and should not exceed 80 characters.`,
      },
      {
        role: 'user',
        content: `元のタイトル：「${originalTitle}」
日本語の商品説明：「${limitedJpDesc}」
この情報を基に、最適化された新しいeBayの商品タイトルを生成してください。また日本語の商品説明が空の場合は元のタイトルから生成してください`,
      },
    ],
    max_tokens: 100,
  };

  const response = await axios.post(url, requestData, {
    headers: { Authorization: `Bearer ${apiKey}` },
  });

  const generatedTitle = response.data.choices[0].message.content.trim();
  return generatedTitle;
}

// タイトルカスタマイズ関数
function applyTitleCustomizations(
  title,
  deleteStrings,
  replacePairs,
  prependText,
  appendText,
  limitTitleLength
) {
  let newTitle = title;

  // 削除したい単語を削除
  deleteStrings.forEach((deleteStr) => {
    if (deleteStr) {
      const escapedDeleteStr = escapeRegExp(deleteStr);
      const regex = new RegExp(escapedDeleteStr, 'gi');
      newTitle = newTitle.replace(regex, '');
    }
  });

  // 置換ペアを適用
  replacePairs.forEach(({ from, to }) => {
    if (from) {
      const escapedFrom = escapeRegExp(from);
      const regex = new RegExp(escapedFrom, 'gi');
      newTitle = newTitle.replace(regex, to);
    }
  });

  // 先頭と末尾に文字列を追加
  if (prependText) {
    newTitle = `${prependText} ${newTitle}`;
  }
  if (appendText) {
    newTitle = `${newTitle} ${appendText}`;
  }

  // 不要な空白をトリム
  newTitle = newTitle.trim();

  // 最終的な78文字制限を適用
  if (limitTitleLength && newTitle.length > 79) {
    newTitle = newTitle.slice(0, 79).trim();
  }

  return newTitle;
}

// 正規表現の特殊文字をエスケープする関数
function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}






// // applyAITitles.js

// import axios from 'axios';
// import PQueue from 'p-queue';

// // キャッシュ機能
// const titleCache = {};

// const getCachedTitle = (key) => titleCache[key];
// const setCachedTitle = (key, value) => {
//   titleCache[key] = value;
// };

// // タイトル生成のための関数
// export const applyAITitles = async (currentData, apiKey, progressCallback, customizationOptions) => {
//   if (!apiKey) {
//     throw new Error('OpenAI APIキーが設定されていません');
//   }

//   // カスタマイズオプションを展開
//   const { deleteStrings, replacePairs, prependText, appendText, limitTitleLength } = customizationOptions;

//   // p-queueの設定
//   const CONCURRENCY = 2;
//   const INTERVAL = 1000;
//   const INTERVAL_CAP = 5;
//   const queue = new PQueue({
//     concurrency: CONCURRENCY,
//     interval: INTERVAL,
//     intervalCap: INTERVAL_CAP,
//   });

//   let totalItems = 0;
//   let completedItems = 0;
//   const errorItems = [];

//   // 全ファイルに対して処理
//   const updatedData = await Promise.all(
//     currentData.map(async (file) => {
//       // CSVデータをパース
//       const Papa = require('papaparse');
//       const parsedResult = Papa.parse(file.content, { header: true });
//       const data = parsedResult.data;

//       totalItems += data.length;

//       // タイトル生成タスクの配列
//       const generateTitleTasks = data.map((item, index) => async () => {
//         const originalTitle = item.Title || '';
//         const jpDesc = item.jp_desc || item.JP_Description || '';

//         // キャッシュキーの生成
//         const cacheKey = originalTitle + jpDesc.slice(0, 150);
//         const cachedTitle = getCachedTitle(cacheKey);
//         if (cachedTitle) {
//           // カスタマイズを適用
//           let customizedTitle = applyTitleCustomizations(
//             cachedTitle,
//             deleteStrings,
//             replacePairs,
//             prependText,
//             appendText,
//             limitTitleLength
//           );
//           item.Title = customizedTitle;
//           completedItems++;
//           if (progressCallback) {
//             progressCallback({ totalItems, completedItems });
//           }
//           return;
//         }

//         try {
//           const generatedTitle = await generateSingleTitle(
//             originalTitle,
//             jpDesc,
//             apiKey
//           );

//           // カスタマイズを適用
//           let customizedTitle = applyTitleCustomizations(
//             generatedTitle,
//             deleteStrings,
//             replacePairs,
//             prependText,
//             appendText,
//             limitTitleLength
//           );

//           item.Title = customizedTitle;
//           setCachedTitle(cacheKey, generatedTitle);
//         } catch (error) {
//           console.error(`タイトル生成エラー（行 ${index + 1}）:`, error);
//           // エラー時は元のタイトルをそのまま使用
//           errorItems.push({
//             fileName: file.name,
//             rowIndex: index + 1,
//             error: error.message || error,
//           });
//         } finally {
//           completedItems++;
//           if (progressCallback) {
//             progressCallback({ totalItems, completedItems });
//           }
//         }
//       });

//       // タスクをキューに追加して実行
//       await queue.addAll(generateTitleTasks);

//       // CSVに変換
//       const csvContent = Papa.unparse(data);

//       return {
//         ...file,
//         content: csvContent,
//       };
//     })
//   );

//   return { updatedData, errorItems };
// };

// // 単一の商品タイトルを生成する関数
// async function generateSingleTitle(originalTitle, jpDesc, apiKey) {
//   const url = 'https://api.openai.com/v1/chat/completions';
//   const limitedJpDesc = jpDesc.slice(0, 150);
//   const requestData = {
//     model: 'gpt-4o-mini',
//     messages: [
//       {
//         role: 'system',
//         content: `You are an AI assistant specializing in eBay product title optimization. Based on the given original title and Japanese product information, create an effective English title within 80 characters.
// Order of title composition:
// 1. brand name (if applicable)
// 2. model name or product name/character name (if applicable)
// 3. product type (if applicable)
// 4. key features (color, material, size) (if applicable)
// 5. distinctive elements or uniqueness (if applicable)
// 6. condition (new/used) (if applicable)
// 7. important keywords (if applicable)
// NOTES:
// - Add or optimize new information while retaining important information from the original title
// - Avoid unnecessary adjectives and words such as “eBay”
// - Use abbreviations and common names appropriately
// - Think from the searcher's perspective and include search terms that buyers are likely to use
// - If information is unclear or ambiguous, omit it.
// - Use only reliable information and do not include guesswork or uncertain information.
// - Do not extract from Japanese descriptions that are a list of words.
// - Titles should be generated in English and should not exceed 80 characters.`,
//       },
//       {
//         role: 'user',
//         content: `元のタイトル：「${originalTitle}」
// 日本語の商品説明：「${limitedJpDesc}」
// この情報を基に、最適化された新しいeBayの商品タイトルを生成してください。また日本語の商品説明が空の場合は元のタイトルから生成してください`,
//       },
//     ],
//     max_tokens: 100,
//   };

//   const response = await axios.post(url, requestData, {
//     headers: { Authorization: `Bearer ${apiKey}` },
//   });

//   const generatedTitle = response.data.choices[0].message.content.trim();
//   return generatedTitle;
// }

// // タイトルカスタマイズ関数
// function applyTitleCustomizations(title, deleteStrings, replacePairs, prependText, appendText, limitTitleLength) {
//   let newTitle = title;

//   // 削除したい単語を削除
//   deleteStrings.forEach((deleteStr) => {
//     if (deleteStr) {
//       const escapedDeleteStr = escapeRegExp(deleteStr);
//       const regex = new RegExp(escapedDeleteStr, 'gi');
//       newTitle = newTitle.replace(regex, '');
//     }
//   });

//   // 置換ペアを適用
//   replacePairs.forEach(({ from, to }) => {
//     if (from) {
//       const escapedFrom = escapeRegExp(from);
//       const regex = new RegExp(escapedFrom, 'gi');
//       newTitle = newTitle.replace(regex, to);
//     }
//   });

//   // 先頭と末尾に文字列を追加
//   if (prependText) {
//     newTitle = `${prependText} ${newTitle}`;
//   }
//   if (appendText) {
//     newTitle = `${newTitle} ${appendText}`;
//   }

//   // タイトルを80文字に制限
//   if (limitTitleLength) {
//     newTitle = newTitle.slice(0, 78);
//   }

//   return newTitle.trim();
// }

// // 正規表現の特殊文字をエスケープする関数
// function escapeRegExp(string) {
//   return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
// }