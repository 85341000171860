// EditableSpreadsheet.js

import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import "./EditableSpreadsheet.css";
import BulkColumnEdit from "./BulkColumnEdit";
import { v4 as uuidv4 } from "uuid"; // ユニークID生成用

// モーダルのアクセシビリティ設定
Modal.setAppElement("#root");

function EditableSpreadsheet({
  data,
  setData,
  columns,
  setColumns,
  setIsDataModified,
  userId,
}) {
  // ステート変数の定義
  const [error, setError] = useState(null);
  const [selectedColumn, setSelectedColumn] = useState(null);
  // セル編集用のステートを追加
  const [isCellModalOpen, setIsCellModalOpen] = useState(false);
  const [cellModalValue, setCellModalValue] = useState("");
  const [cellModalRowIndex, setCellModalRowIndex] = useState(null);
  const [cellModalColKey, setCellModalColKey] = useState(null);
  const [isUpdatingCell, setIsUpdatingCell] = useState(false);

  // 初期データを保持（初回のみ設定し、不変とする）
  const [initialData, setInitialData] = useState([]);

  useEffect(() => {
    // dataが初めて読み込まれた際にIDを付与し、その時点のデータをinitialDataとして保持
    if (initialData.length === 0 && data.length > 0) {
      // dataにユニークIDを付加（初回のみ）
      const dataWithId = data.map((item) => {
        return {
          ...item,
          _id: item._id || uuidv4(), // _idがなければ新たに付与
        };
      });
      setData(dataWithId);
      setInitialData(JSON.parse(JSON.stringify(dataWithId))); // ディープコピーで初期値を不変的に保持
    }
  }, [data, initialData.length, setData]);

  // 必須変更カラム
  const requiredColumns = [
    "Title",
    "StartPrice",
    "Description",
    "Category",
    "PaymentProfileName",
    "ReturnProfileName",
    "ShippingProfileName",
    "MinimumBestOfferPrice",
    "BestOfferAutoAcceptPrice",
  ];

  // 推奨変更カラム（'C:' で始まるカラム）
  const recommendedColumns = columns.filter((col) => col.startsWith("C:"));

  // セルの値が空または 'NA' かを判定する関数
  const isCellValueEmptyOrNA = (value) => {
    return (
      value === undefined || value === null || value === "" || value === "NA"
    );
  };

  // プレビュー用のステート
  const [previewItem, setPreviewItem] = useState(null); // プレビューするアイテム
  const [isPreviewOpen, setIsPreviewOpen] = useState(false); // プレビューモーダルの開閉

  // カラム幅を一律に管理するステートを追加
  const [columnWidth, setColumnWidth] = useState(150); // デフォルトの幅を150pxに設定

  // カラム順序を管理するステートを追加
  const [columnOrder, setColumnOrder] = useState(columns);

  // カラム順序テンプレートを管理する状態
  const [columnOrderTemplates, setColumnOrderTemplates] = useState({});

  // 選択されたテンプレート名
  const [selectedTemplateName, setSelectedTemplateName] = useState("");

  // ローカルストレージからテンプレートを読み込む
  useEffect(() => {
    const savedTemplates = localStorage.getItem("columnOrderTemplates");
    if (savedTemplates) {
      setColumnOrderTemplates(JSON.parse(savedTemplates));
    }
  }, []);

  // テンプレートをローカルストレージに保存
  useEffect(() => {
    localStorage.setItem(
      "columnOrderTemplates",
      JSON.stringify(columnOrderTemplates)
    );
  }, [columnOrderTemplates]);

  // dataが更新されたときにcolumnsを更新
  useEffect(() => {
    if (data.length > 0) {
      const dataColumns = Object.keys(data[0]);
      setColumns(dataColumns);
    }
  }, [data, setColumns]);

  // columnsまたはcolumnOrderが更新された場合、columnOrderを更新
  useEffect(() => {
    const newColumnOrder = columnOrder.filter((col) => columns.includes(col));
    const addedColumns = columns.filter((col) => !newColumnOrder.includes(col));
    setColumnOrder([...newColumnOrder, ...addedColumns]);
  }, [columns]);

  // カラム順序テンプレートを適用する関数
  const applyColumnTemplate = (templateName) => {
    if (!templateName) {
      // テンプレート名が空の場合、デフォルトのカラム順序を適用
      setColumnOrder(columns);
      return;
    }
    const templateOrder = columnOrderTemplates[templateName];
    if (templateOrder) {
      const newColumnOrder = templateOrder.filter((col) =>
        columns.includes(col)
      );
      const remainingColumns = columns.filter(
        (col) => !newColumnOrder.includes(col)
      );
      // 残りのカラムを末尾に追加
      setColumnOrder([...newColumnOrder, ...remainingColumns]);
    }
  };

  // セルの更新
  const updateCell = (rowIndex, colKey, newValue) => {
    try {
      setIsUpdatingCell(true); // 更新開始
      const newData = [...data];
      newData[rowIndex] = { ...newData[rowIndex], [colKey]: newValue };
      setData(newData);
      // セルの値が変更された場合、initialDataは変更しない（不変）
      setIsDataModified(true);
      setIsUpdatingCell(false); // 更新終了
    } catch (err) {
      console.error("Error updating cell:", err);
      setError("セルの更新に失敗しました");
      setIsUpdatingCell(false);
    }
  };

  // カラムのクリックハンドラー
  const handleColumnClick = (colIndex) => {
    setSelectedColumn(colIndex);
  };

  // セルのレンダリング
  const renderCell = (cell, rowIndex, colKey) => {
    const header = colKey.toLowerCase();

    // セルのスタイルを動的に設定
    let cellStyle = {
      cursor: "pointer",
      width: "100%",
      boxSizing: "border-box",
    };

    // セルの値を取得
    const cellValue = cell;

    // 必須変更カラムの場合、値が 'NA' または空の場合に背景色を赤色に設定
    if (requiredColumns.includes(colKey) && isCellValueEmptyOrNA(cellValue)) {
      cellStyle.backgroundColor = "#ffcccc"; // 薄い赤色
    }
    // 推奨変更カラムの場合、値が 'NA' または空の場合に背景色を黄色に設定
    else if (
      recommendedColumns.includes(colKey) &&
      isCellValueEmptyOrNA(cellValue)
    ) {
      cellStyle.backgroundColor = "#ffffcc"; // 薄い黄色
    }
    // セルが変更されていて、値が 'NA' でない場合、背景色を緑色に設定
    if (
      initialData.length > 0 &&
      initialData[rowIndex] &&
      initialData[rowIndex][colKey] !== undefined &&
      initialData[rowIndex][colKey] !== cellValue &&
      !isCellValueEmptyOrNA(cellValue)
    ) {
      cellStyle.backgroundColor = "#ccffcc"; // 薄い緑色
    }

    // PicURL や 画像 カラムの処理
    if (header === "picurl" || header === "画像") {
      const imageUrls = cell?.split("|") || [];
      return (
        <img
          src={imageUrls[0]}
          alt="Product"
          style={{ maxWidth: "100px", maxHeight: "100px" }}
        />
      );
    } else {
      return (
        <input
          value={cell || ""}
          onClick={() => {
            setCellModalValue(cell || "");
            setCellModalRowIndex(rowIndex);
            setCellModalColKey(colKey);
            setIsCellModalOpen(true);
          }}
          readOnly // 直接編集を防ぐ場合はreadOnlyを設定
          style={cellStyle}
        />
      );
    }
  };

  // 単一行を削除
  const handleDeleteRow = (rowIndex) => {
    if (rowIndex < 0) {
      setError("ヘッダー行は削除できません。");
      return;
    }

    try {
      // initialDataは不変なので更新しない
      // ここでdataのみ削除を行う
      const newData = data.filter((_, index) => index !== rowIndex);
      setData(newData);
      setIsDataModified(true);
      setError(null);
    } catch (err) {
      console.error("Error deleting row:", err);
      setError("行の削除に失敗しました");
    }
  };

  // プレビューを表示する関数
  const handlePreview = (row) => {
    setPreviewItem(row);
    setIsPreviewOpen(true);
  };

  // プレビューモーダルを閉じる関数
  const closePreviewModal = () => {
    setIsPreviewOpen(false);
    setPreviewItem(null);
  };

  // モーダルのスタイル設定
  const cellModalStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      transform: "translate(-50%, -50%)",
      width: "600px",
      maxHeight: "80%",
      overflow: "auto",
      padding: "20px",
      boxSizing: "border-box",
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.5)",
    },
  };

  const previewModalStyles = {
    content: {
      backgroundColor: "white",
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "90%",
      maxWidth: "1000px",
      maxHeight: "90%",
      overflow: "auto",
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.8)",
      zIndex: 1000,
    },
  };

  const warningModalStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      transform: "translate(-50%, -50%)",
      width: "600px",
      maxHeight: "80%",
      overflow: "auto",
      padding: "20px",
      boxSizing: "border-box",
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.5)",
    },
  };

  // ページネーションのステート
  const [itemsPerPage, setItemsPerPage] = useState(50); // 表示件数
  const [currentPage, setCurrentPage] = useState(1); // 現在のページ
  const [isLoading, setIsLoading] = useState(false); // ローディング状態

  // useEffect(() => {
  //   // データが変更されたときにcurrentPageを1にリセット
  //   setCurrentPage(1);
  // }, [data]);

  const itemsPerPageValue = itemsPerPage === "All" ? data.length : itemsPerPage;
  const totalPages =
    itemsPerPage === "All" ? 1 : Math.ceil(data.length / itemsPerPageValue);

  useEffect(() => {
    setIsLoading(true);
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 500);
    return () => clearTimeout(timer);
  }, [itemsPerPage, currentPage, data]);

  const handleItemsPerPageChange = (e) => {
    const value = e.target.value;
    if (value === "All") {
      setItemsPerPage("All");
    } else {
      setItemsPerPage(parseInt(value));
    }
    setCurrentPage(1); // ページをリセット
  };

  const handlePageChange = (newPage) => {
    if (newPage < 1 || newPage > totalPages) return;
    setCurrentPage(newPage);
  };

  let currentData = [];
  if (itemsPerPage === "All") {
    currentData = data;
  } else {
    const startIndex = (currentPage - 1) * itemsPerPageValue;
    const endIndex = startIndex + itemsPerPageValue;
    currentData = data.slice(startIndex, endIndex);
  }

  // StartPriceの値をチェックし、該当商品のリストを返す関数（IDベースで対応付け）
  const getProductsWithLowerStartPrice = () => {
    if (!columns || columns.length === 0) {
      return [];
    }

    const startPriceKey = columns.find(
      (key) => key.toLowerCase() === "startprice"
    );

    if (!startPriceKey) {
      return [];
    }

    const warningProducts = [];

    // dataに存在する商品のみに対して比較する
    for (let i = 0; i < data.length; i++) {
      const currentItem = data[i];
      const currentId = currentItem._id;
      const currentPriceStr = currentItem[startPriceKey];

      const initialItem = initialData.find((item) => item._id === currentId);
      if (!initialItem) {
        // initialDataに存在しない（新規追加された等）の場合はスキップ
        continue;
      }

      const initialPriceStr = initialItem[startPriceKey];

      const currentPrice = parseFloat(currentPriceStr);
      const initialPrice = parseFloat(initialPriceStr);

      if (!isNaN(currentPrice) && !isNaN(initialPrice)) {
        // ここで条件を調整。「変更なしまたは値下げで警告」など
        // 例: initialPriceより低いか同じ場合に警告を表示する
        if (currentPrice <= initialPrice) {
          warningProducts.push({
            ...currentItem,
            index: i + 1, // 行番号（1から始まる）
          });
        }
      }
    }
    return warningProducts;
  };

  // CSVダウンロード関連処理
  const [isWarningModalOpen, setIsWarningModalOpen] = useState(false);
  const [warningProducts, setWarningProducts] = useState([]);
  const [pendingFilename, setPendingFilename] = useState("data.csv");

  const handleDownloadCSV = () => {
    const filename = prompt("保存するCSVファイル名を入力してください（例: data.csv）", "data.csv");
    if (filename) {
      const warningProductsList = getProductsWithLowerStartPrice();
      if (warningProductsList.length > 0) {
        setWarningProducts(warningProductsList);
        setIsWarningModalOpen(true);
        setPendingFilename(filename);
      } else {
        proceedToDownloadCSV(filename);
      }
    }
  };

  const proceedToDownloadCSV = (filename = "data.csv") => {
    const csvContent = generateCSVContent(data);
    downloadCSV(csvContent, filename);
  };

  const generateCSVContent = (data) => {
    if (!data || data.length === 0) return "";

    // _idは内部管理用フィールドであれば除外可能
    // 必要なら残してもよい
    const headers = Object.keys(data[0]).filter((h) => h !== "_id");
    const csvRows = [];

    // ヘッダー行
    csvRows.push(headers.join(","));

    // データ行
    data.forEach((row) => {
      const values = headers.map((header) => {
        const value =
          row[header] !== undefined && row[header] !== null ? row[header] : "";
        const escaped = ("" + value).replace(/"/g, '""');
        return `"${escaped}"`;
      });
      csvRows.push(values.join(","));
    });

    return csvRows.join("\n");
  };

  const downloadCSV = (csvContent, filename) => {
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    if (navigator.msSaveBlob) {
      navigator.msSaveBlob(blob, filename);
    } else {
      const link = document.createElement("a");
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", filename);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  };

  return (
    <div className="editable-spreadsheet-container">
      <BulkColumnEdit
        data={data}
        setData={setData}
        columns={columns}
        setColumns={setColumns}
        columnOrder={columnOrder}
        setIsDataModified={setIsDataModified}
        userId={userId}
        columnOrderTemplates={columnOrderTemplates}
        setColumnOrderTemplates={setColumnOrderTemplates}
        applyColumnTemplate={applyColumnTemplate}
        selectedTemplateName={selectedTemplateName}
        setSelectedTemplateName={setSelectedTemplateName}
        setColumnOrder={setColumnOrder}
        columnWidth={columnWidth}
        setColumnWidth={setColumnWidth}
        initialData={initialData} // initialDataは不変
        setInitialData={setInitialData} // 基本的に初期セット以降変更しないようにするが、ここではpropとして残す
      />

      {error && <div className="error-message">{error}</div>}

      <div className="top-controls">
        <div className="pagination-controls">
          <label htmlFor="itemsPerPage">表示件数:</label>
          <select
            id="itemsPerPage"
            value={itemsPerPage}
            onChange={handleItemsPerPageChange}
          >
            <option value={50}>50</option>
            <option value={100}>100</option>
            <option value={200}>200</option>
            <option value="All">すべて</option>
          </select>
        </div>

        {totalPages > 1 && (
          <div className="pagination-controls">
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              前へ
            </button>
            <span>
              {currentPage} / {totalPages}
            </span>
            <button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              次へ
            </button>
          </div>
        )}

        <div className="csv-download-button-container">
          <button
            onClick={handleDownloadCSV}
            className="csv-download-button"
          >
            CSVをダウンロード
          </button>
        </div>
      </div>

      {isLoading && <div className="loading-indicator">読み込み中...</div>}

      <Modal
        isOpen={isPreviewOpen}
        onRequestClose={closePreviewModal}
        contentLabel="商品プレビュー"
        style={previewModalStyles}
      >
        {previewItem && (
          <div className="preview-modal-content">
            <button className="close-modal-button" onClick={closePreviewModal}>
              ×
            </button>
            <div className="preview-header">
              <h2>{previewItem["Title"] || previewItem["title"]}</h2>
            </div>
            <div className="preview-body">
              <div className="preview-images">
                {(() => {
                  const picUrl =
                    previewItem["PicURL"] || previewItem["picurl"] || "";
                  const imageUrls = picUrl.split("|").filter(Boolean);
                  if (imageUrls.length > 0) {
                    return (
                      <div className="image-gallery">
                        <div className="main-image">
                          <img
                            src={imageUrls[0]}
                            alt="Product Main"
                            style={{ maxWidth: "100%", maxHeight: "400px" }}
                          />
                        </div>
                        {imageUrls.length > 1 && (
                          <div className="additional-images">
                            {imageUrls.slice(1).map((url, index) => (
                              <img
                                key={index}
                                src={url}
                                alt={`Product ${index + 2}`}
                                style={{
                                  maxWidth: "100px",
                                  maxHeight: "100px",
                                  margin: "5px",
                                }}
                              />
                            ))}
                          </div>
                        )}
                      </div>
                    );
                  } else {
                    return <p>画像がありません。</p>;
                  }
                })()}
              </div>
              <div className="preview-details">
                <p>
                  <strong>価格：</strong>{" "}
                  {previewItem["StartPrice"] ||
                    previewItem["startprice"] ||
                    "N/A"}
                </p>
                <p>
                  <strong>説明：</strong>
                </p>
                <div
                  className="item-description"
                  dangerouslySetInnerHTML={{
                    __html:
                      previewItem["Description"] ||
                      previewItem["description"] ||
                      "",
                  }}
                />
              </div>
            </div>
          </div>
        )}
      </Modal>

      <Modal
        isOpen={isCellModalOpen}
        onRequestClose={() => setIsCellModalOpen(false)}
        contentLabel="セルの編集"
        style={cellModalStyles}
      >
        <div>
          <h2 className="cell-modal-title">セルの編集</h2>
          <textarea
            value={cellModalValue}
            onChange={(e) => setCellModalValue(e.target.value)}
            style={{
              width: "100%",
              height: "200px",
              fontSize: "16px",
              lineHeight: "1.5",
              fontFamily: "Arial, sans-serif",
              color: "#333",
              padding: "10px",
              boxSizing: "border-box",
            }}
            disabled={isUpdatingCell}
          />
          <div style={{ marginTop: "20px", textAlign: "right" }}>
            <button
              onClick={() => {
                updateCell(cellModalRowIndex, cellModalColKey, cellModalValue);
                setIsCellModalOpen(false);
              }}
              className="cell-modal-button"
              style={{ marginRight: "10px" }}
              disabled={isUpdatingCell}
            >
              保存
            </button>
            <button
              onClick={() => setIsCellModalOpen(false)}
              className="cell-modal-button"
              disabled={isUpdatingCell}
            >
              キャンセル
            </button>
            {isUpdatingCell && (
              <div
                className="loader"
                style={{ display: "inline-block", marginLeft: "10px" }}
              ></div>
            )}
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={isWarningModalOpen}
        onRequestClose={() => setIsWarningModalOpen(false)}
        contentLabel="警告"
        style={warningModalStyles}
      >
        <div>
          <h2>警告</h2>
          <p>
            StartPriceの値が初期インポートされた値と同一またはそれ以下になっている商品があります。CSVをダウンロードしますか？
          </p>
          <p>該当の商品:</p>
          <ul>
            {warningProducts.map((product, index) => (
              <li key={index}>
                {product["Title"] || product["title"] || "タイトルなし"}（行{" "}
                {product.index}）
              </li>
            ))}
          </ul>
          <div style={{ textAlign: "right" }}>
            <button
              onClick={() => {
                setIsWarningModalOpen(false);
                proceedToDownloadCSV(pendingFilename);
              }}
              style={{ marginRight: "10px" }}
            >
              ダウンロードを続行
            </button>
            <button onClick={() => setIsWarningModalOpen(false)}>
              キャンセル
            </button>
          </div>
        </div>
      </Modal>

      {currentData.length === 0 ? (
        <div className="no-data-message">
          データがありません。CSVファイルをインポートしてください。
        </div>
      ) : (
        <div className="spreadsheet-container">
          <div className="table-wrapper">
            <table className="editable-spreadsheet">
              <thead>
                <tr>
                  <th
                    className="row-number-header"
                    style={{
                      width: "50px",
                      position: "relative",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                    }}
                  >
                    #
                  </th>
                  <th
                    style={{
                      width: "100px",
                      position: "relative",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                    }}
                  >
                    操作
                  </th>
                  {columnOrder.map((header, colIndex) => (
                    <th
                      key={colIndex}
                      onClick={() => handleColumnClick(colIndex)}
                      className={
                        selectedColumn === colIndex ? "selected-column" : ""
                      }
                      style={{
                        minWidth: "50px",
                        width: `${columnWidth}px`,
                        maxWidth: `${columnWidth}px`,
                        position: "relative",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                      }}
                      title={header}
                    >
                      {header}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {currentData.map((row, rowIndex) => {
                  const globalRowIndex =
                    (currentPage - 1) * itemsPerPageValue + rowIndex;
                  return (
                    <tr key={globalRowIndex}>
                      <td className="row-number" style={{ width: "50px" }}>
                        {globalRowIndex + 1}
                      </td>
                      <td style={{ width: "100px" }}>
                        <div className="action-buttons">
                          <button
                            className="preview-button"
                            onClick={() => handlePreview(row)}
                          >
                            プレビュー
                          </button>
                          <button
                            className="delete-button"
                            onClick={() => handleDeleteRow(globalRowIndex)}
                          >
                            削除
                          </button>
                        </div>
                      </td>
                      {columnOrder.map((colKey, colIndex) => (
                        <td
                          key={colIndex}
                          className={
                            selectedColumn === colIndex ? "selected-column" : ""
                          }
                          style={{
                            minWidth: "50px",
                            width: `${columnWidth}px`,
                            maxWidth: `${columnWidth}px`,
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            backgroundColor: (() => {
                              const cellValue = row[colKey];
                              if (
                                requiredColumns.includes(colKey) &&
                                isCellValueEmptyOrNA(cellValue)
                              ) {
                                return "#ffcccc"; // 薄い赤色
                              } else if (
                                recommendedColumns.includes(colKey) &&
                                isCellValueEmptyOrNA(cellValue)
                              ) {
                                return "#ffffcc"; // 薄い黄色
                              } else if (
                                initialData.length > 0 &&
                                initialData[globalRowIndex] &&
                                initialData[globalRowIndex][colKey] !==
                                  undefined &&
                                initialData[globalRowIndex][colKey] !==
                                  cellValue &&
                                !isCellValueEmptyOrNA(cellValue)
                              ) {
                                return "#ccffcc"; // 薄い緑色
                              }
                              return "transparent";
                            })(),
                          }}
                          title={row[colKey]}
                        >
                          {renderCell(row[colKey], globalRowIndex, colKey)}
                        </td>
                      ))}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
}

export default EditableSpreadsheet;





// // EditableSpreadsheet.js

// import React, { useState, useEffect } from "react";
// import Modal from "react-modal";
// import "./EditableSpreadsheet.css";
// import BulkColumnEdit from "./BulkColumnEdit";

// // モーダルのアクセシビリティ設定
// Modal.setAppElement("#root");

// function EditableSpreadsheet({
//   data,
//   setData,
//   columns,
//   setColumns,
//   setIsDataModified,
//   userId,
// }) {
//   // ステート変数の定義
//   const [error, setError] = useState(null);
//   const [selectedColumn, setSelectedColumn] = useState(null);
//   // セル編集用のステートを追加
//   const [isCellModalOpen, setIsCellModalOpen] = useState(false);
//   const [cellModalValue, setCellModalValue] = useState("");
//   const [cellModalRowIndex, setCellModalRowIndex] = useState(null);
//   const [cellModalColKey, setCellModalColKey] = useState(null);
//   const [isUpdatingCell, setIsUpdatingCell] = useState(false);

//   // 初期データを保持
//   const [initialData, setInitialData] = useState([]);

//   useEffect(() => {
//     if (initialData.length === 0 && data.length > 0) {
//       // データが初期インポートされたときのみ初期データを設定
//       setInitialData(JSON.parse(JSON.stringify(data))); // ディープコピー
//     }
//   }, [data]);

//   // 必須変更カラム
//   const requiredColumns = [
//     "Title",
//     "StartPrice",
//     "Description",
//     "Category",
//     "PaymentProfileName",
//     "ReturnProfileName",
//     "ShippingProfileName",
//     "MinimumBestOfferPrice",
//     "BestOfferAutoAcceptPrice",
//   ];

//   // 推奨変更カラム（'C:' で始まるカラム）
//   const recommendedColumns = columns.filter((col) => col.startsWith("C:"));

//   // セルの値が空または 'NA' かを判定する関数
//   const isCellValueEmptyOrNA = (value) => {
//     return (
//       value === undefined || value === null || value === "" || value === "NA"
//     );
//   };

//   // プレビュー用のステート
//   const [previewItem, setPreviewItem] = useState(null); // プレビューするアイテム
//   const [isPreviewOpen, setIsPreviewOpen] = useState(false); // プレビューモーダルの開閉

//   // カラム幅を一律に管理するステートを追加
//   const [columnWidth, setColumnWidth] = useState(150); // デフォルトの幅を150pxに設定

//   // カラム順序を管理するステートを追加
//   const [columnOrder, setColumnOrder] = useState(columns);

//   // カラム順序テンプレートを管理する状態
//   const [columnOrderTemplates, setColumnOrderTemplates] = useState({});

//   // 選択されたテンプレート名
//   const [selectedTemplateName, setSelectedTemplateName] = useState("");

//   // ローカルストレージからテンプレートを読み込む
//   useEffect(() => {
//     const savedTemplates = localStorage.getItem("columnOrderTemplates");
//     if (savedTemplates) {
//       setColumnOrderTemplates(JSON.parse(savedTemplates));
//     }
//   }, []);

//   // テンプレートをローカルストレージに保存
//   useEffect(() => {
//     localStorage.setItem(
//       "columnOrderTemplates",
//       JSON.stringify(columnOrderTemplates)
//     );
//   }, [columnOrderTemplates]);

//   // dataが更新されたときにcolumnsを更新
//   useEffect(() => {
//     if (data.length > 0) {
//       const dataColumns = Object.keys(data[0]);
//       setColumns(dataColumns);
//     }
//   }, [data, setColumns]);

//   // columnsまたはcolumnOrderが更新された場合、columnOrderを更新
//   useEffect(() => {
//     const newColumnOrder = columnOrder.filter((col) => columns.includes(col));
//     const addedColumns = columns.filter((col) => !newColumnOrder.includes(col));
//     setColumnOrder([...newColumnOrder, ...addedColumns]);
//   }, [columns]);

//   // カラム順序テンプレートを適用する関数
//   const applyColumnTemplate = (templateName) => {
//     if (!templateName) {
//       // テンプレート名が空の場合、デフォルトのカラム順序を適用
//       setColumnOrder(columns);
//       return;
//     }
//     const templateOrder = columnOrderTemplates[templateName];
//     if (templateOrder) {
//       const newColumnOrder = templateOrder.filter((col) =>
//         columns.includes(col)
//       );
//       const remainingColumns = columns.filter(
//         (col) => !newColumnOrder.includes(col)
//       );
//       // 残りのカラムを末尾に追加
//       setColumnOrder([...newColumnOrder, ...remainingColumns]);
//     }
//   };

//   // セルの更新
//   const updateCell = (rowIndex, colKey, newValue) => {
//     try {
//       setIsUpdatingCell(true); // 更新開始
//       const newData = [...data];
//       newData[rowIndex] = { ...newData[rowIndex], [colKey]: newValue };
//       setData(newData);
//       // セルの値が変更された場合、initialDataは変更しない
//       setIsDataModified(true);
//       setIsUpdatingCell(false); // 更新終了
//     } catch (err) {
//       console.error("Error updating cell:", err);
//       setError("セルの更新に失敗しました");
//       setIsUpdatingCell(false);
//     }
//   };

//   // カラムのクリックハンドラー
//   const handleColumnClick = (colIndex) => {
//     setSelectedColumn(colIndex);
//   };

//   // セルのレンダリング
//   const renderCell = (cell, rowIndex, colKey) => {
//     const header = colKey.toLowerCase();

//     // セルのスタイルを動的に設定
//     let cellStyle = {
//       cursor: "pointer",
//       width: "100%",
//       boxSizing: "border-box",
//     };

//     // セルの値を取得
//     const cellValue = cell;

//     // 必須変更カラムの場合、値が 'NA' または空の場合に背景色を赤色に設定
//     if (requiredColumns.includes(colKey) && isCellValueEmptyOrNA(cellValue)) {
//       cellStyle.backgroundColor = "#ffcccc"; // 薄い赤色
//     }
//     // 推奨変更カラムの場合、値が 'NA' または空の場合に背景色を黄色に設定
//     else if (
//       recommendedColumns.includes(colKey) &&
//       isCellValueEmptyOrNA(cellValue)
//     ) {
//       cellStyle.backgroundColor = "#ffffcc"; // 薄い黄色
//     }
//     // セルが変更されていて、値が 'NA' でない場合、背景色を緑色に設定
//     if (
//       initialData[rowIndex] &&
//       initialData[rowIndex][colKey] !== undefined &&
//       initialData[rowIndex][colKey] !== cellValue &&
//       !isCellValueEmptyOrNA(cellValue)
//     ) {
//       cellStyle.backgroundColor = "#ccffcc"; // 薄い緑色
//     }

//     // PicURL や 画像 カラムの処理
//     if (header === "picurl" || header === "画像") {
//       const imageUrls = cell?.split("|") || [];
//       return (
//         <img
//           src={imageUrls[0]}
//           alt="Product"
//           style={{ maxWidth: "100px", maxHeight: "100px" }}
//         />
//       );
//     } else {
//       return (
//         <input
//           value={cell || ""}
//           onClick={() => {
//             setCellModalValue(cell || "");
//             setCellModalRowIndex(rowIndex);
//             setCellModalColKey(colKey);
//             setIsCellModalOpen(true);
//           }}
//           readOnly // 直接編集を防ぐ場合はreadOnlyを設定
//           style={cellStyle}
//         />
//       );
//     }
//   };

//   // 単一行を削除
//   const handleDeleteRow = (rowIndex) => {
//     if (rowIndex < 0) {
//       setError("ヘッダー行は削除できません。");
//       return;
//     }

//     try {
//       const newData = data.filter((_, index) => index !== rowIndex);
//       const newInitialData = initialData.filter((_, index) => index !== rowIndex);
//       setData(newData);
//       setInitialData(newInitialData); // initialDataを更新
//       setIsDataModified(true); // データが変更されたので状態を更新
//       setError(null);
//     } catch (err) {
//       console.error("Error deleting row:", err);
//       setError("行の削除に失敗しました");
//     }
//   };

//   // プレビューを表示する関数
//   const handlePreview = (row) => {
//     setPreviewItem(row);
//     setIsPreviewOpen(true);
//   };

//   // プレビューモーダルを閉じる関数
//   const closePreviewModal = () => {
//     setIsPreviewOpen(false);
//     setPreviewItem(null);
//   };

//   // モーダルのスタイル設定
//   const cellModalStyles = {
//     content: {
//       top: "50%",
//       left: "50%",
//       right: "auto",
//       bottom: "auto",
//       transform: "translate(-50%, -50%)",
//       width: "600px",
//       maxHeight: "80%",
//       overflow: "auto",
//       padding: "20px",
//       boxSizing: "border-box",
//     },
//     overlay: {
//       backgroundColor: "rgba(0, 0, 0, 0.5)",
//     },
//   };

//   const previewModalStyles = {
//     content: {
//       backgroundColor: "white",
//       top: "50%",
//       left: "50%",
//       right: "auto",
//       bottom: "auto",
//       marginRight: "-50%",
//       transform: "translate(-50%, -50%)",
//       width: "90%",
//       maxWidth: "1000px",
//       maxHeight: "90%",
//       overflow: "auto",
//     },
//     overlay: {
//       backgroundColor: "rgba(0, 0, 0, 0.8)",
//       zIndex: 1000,
//     },
//   };

//   const warningModalStyles = {
//     content: {
//       top: "50%",
//       left: "50%",
//       right: "auto",
//       bottom: "auto",
//       transform: "translate(-50%, -50%)",
//       width: "600px",
//       maxHeight: "80%",
//       overflow: "auto",
//       padding: "20px",
//       boxSizing: "border-box",
//     },
//     overlay: {
//       backgroundColor: "rgba(0, 0, 0, 0.5)",
//     },
//   };

//   // ページネーションのステート
//   const [itemsPerPage, setItemsPerPage] = useState(50); // 表示件数
//   const [currentPage, setCurrentPage] = useState(1); // 現在のページ
//   const [isLoading, setIsLoading] = useState(false); // ローディング状態

//   useEffect(() => {
//     // データが変更されたときにcurrentPageを1にリセット
//     setCurrentPage(1);
//   }, [data]);

//   const itemsPerPageValue = itemsPerPage === "All" ? data.length : itemsPerPage;
//   const totalPages =
//     itemsPerPage === "All" ? 1 : Math.ceil(data.length / itemsPerPageValue);

//   useEffect(() => {
//     setIsLoading(true);
//     const timer = setTimeout(() => {
//       setIsLoading(false);
//     }, 500);
//     return () => clearTimeout(timer);
//   }, [itemsPerPage, currentPage, data]);

//   const handleItemsPerPageChange = (e) => {
//     const value = e.target.value;
//     if (value === "All") {
//       setItemsPerPage("All");
//     } else {
//       setItemsPerPage(parseInt(value));
//     }
//     setCurrentPage(1); // ページをリセット
//   };

//   const handlePageChange = (newPage) => {
//     if (newPage < 1 || newPage > totalPages) return;
//     setCurrentPage(newPage);
//   };

//   let currentData = [];
//   if (itemsPerPage === "All") {
//     currentData = data;
//   } else {
//     const startIndex = (currentPage - 1) * itemsPerPageValue;
//     const endIndex = startIndex + itemsPerPageValue;
//     currentData = data.slice(startIndex, endIndex);
//   }

//   // StartPriceの値をチェックし、該当商品のリストを返す関数
//   const getProductsWithLowerStartPrice = () => {
//     if (!columns || columns.length === 0) {
//       return [];
//     }

//     const startPriceKey = columns.find(
//       (key) => key.toLowerCase() === "startprice"
//     );

//     if (!startPriceKey) {
//       return [];
//     }

//     const warningProducts = [];

//     for (let i = 0; i < data.length; i++) {
//       const currentPriceStr = data[i][startPriceKey];
//       const initialPriceStr = initialData[i]?.[startPriceKey];

//       if (!initialData[i] || initialPriceStr === undefined) {
//         continue;
//       }

//       const currentPrice = parseFloat(currentPriceStr);
//       const initialPrice = parseFloat(initialPriceStr);

//       if (!isNaN(currentPrice) && !isNaN(initialPrice)) {
//         if (currentPrice <= initialPrice) {
//           warningProducts.push({
//             ...data[i],
//             index: i + 1, // 行番号（1から始まる）
//           });
//         }
//       }
//     }
//     return warningProducts;
//   };

//   // CSVダウンロード関連処理
//   const [isWarningModalOpen, setIsWarningModalOpen] = useState(false);
//   const [warningProducts, setWarningProducts] = useState([]);
//   const [pendingFilename, setPendingFilename] = useState("data.csv");

//   const handleDownloadCSV = () => {
//     const filename = prompt("保存するCSVファイル名を入力してください（例: data.csv）", "data.csv");
//     if (filename) {
//       const warningProductsList = getProductsWithLowerStartPrice();
//       if (warningProductsList.length > 0) {
//         setWarningProducts(warningProductsList);
//         setIsWarningModalOpen(true);
//         setPendingFilename(filename);
//       } else {
//         proceedToDownloadCSV(filename);
//       }
//     }
//   };

//   const proceedToDownloadCSV = (filename = "data.csv") => {
//     const csvContent = generateCSVContent(data);
//     downloadCSV(csvContent, filename);
//   };

//   const generateCSVContent = (data) => {
//     if (!data || data.length === 0) return "";

//     const headers = Object.keys(data[0]);
//     const csvRows = [];

//     // ヘッダー行
//     csvRows.push(headers.join(","));

//     // データ行
//     data.forEach((row) => {
//       const values = headers.map((header) => {
//         const value =
//           row[header] !== undefined && row[header] !== null ? row[header] : "";
//         const escaped = ("" + value).replace(/"/g, '""');
//         return `"${escaped}"`;
//       });
//       csvRows.push(values.join(","));
//     });

//     return csvRows.join("\n");
//   };

//   const downloadCSV = (csvContent, filename) => {
//     const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
//     if (navigator.msSaveBlob) {
//       navigator.msSaveBlob(blob, filename);
//     } else {
//       const link = document.createElement("a");
//       if (link.download !== undefined) {
//         const url = URL.createObjectURL(blob);
//         link.setAttribute("href", url);
//         link.setAttribute("download", filename);
//         link.style.visibility = "hidden";
//         document.body.appendChild(link);
//         link.click();
//         document.body.removeChild(link);
//       }
//     }
//   };

//   return (
//     <div className="editable-spreadsheet-container">
//       <BulkColumnEdit
//         data={data}
//         setData={setData}
//         columns={columns}
//         setColumns={setColumns}
//         columnOrder={columnOrder}
//         setIsDataModified={setIsDataModified}
//         userId={userId}
//         columnOrderTemplates={columnOrderTemplates}
//         setColumnOrderTemplates={setColumnOrderTemplates}
//         applyColumnTemplate={applyColumnTemplate}
//         selectedTemplateName={selectedTemplateName}
//         setSelectedTemplateName={setSelectedTemplateName}
//         setColumnOrder={setColumnOrder}
//         columnWidth={columnWidth}
//         setColumnWidth={setColumnWidth}
//         initialData={initialData}
//         setInitialData={setInitialData}
//       />

//       {error && <div className="error-message">{error}</div>}

//       <div className="top-controls">
//         <div className="pagination-controls">
//           <label htmlFor="itemsPerPage">表示件数:</label>
//           <select
//             id="itemsPerPage"
//             value={itemsPerPage}
//             onChange={handleItemsPerPageChange}
//           >
//             <option value={50}>50</option>
//             <option value={100}>100</option>
//             <option value={200}>200</option>
//             <option value="All">すべて</option>
//           </select>
//         </div>

//         {totalPages > 1 && (
//           <div className="pagination-controls">
//             <button
//               onClick={() => handlePageChange(currentPage - 1)}
//               disabled={currentPage === 1}
//             >
//               前へ
//             </button>
//             <span>
//               {currentPage} / {totalPages}
//             </span>
//             <button
//               onClick={() => handlePageChange(currentPage + 1)}
//               disabled={currentPage === totalPages}
//             >
//               次へ
//             </button>
//           </div>
//         )}

//         <div className="csv-download-button-container">
//           <button
//             onClick={handleDownloadCSV}
//             className="csv-download-button"
//           >
//             CSVをダウンロード
//           </button>
//         </div>
//       </div>

//       {isLoading && <div className="loading-indicator">読み込み中...</div>}

//       <Modal
//         isOpen={isPreviewOpen}
//         onRequestClose={closePreviewModal}
//         contentLabel="商品プレビュー"
//         style={previewModalStyles}
//       >
//         {previewItem && (
//           <div className="preview-modal-content">
//             <button className="close-modal-button" onClick={closePreviewModal}>
//               ×
//             </button>
//             <div className="preview-header">
//               <h2>{previewItem["Title"] || previewItem["title"]}</h2>
//             </div>
//             <div className="preview-body">
//               <div className="preview-images">
//                 {(() => {
//                   const picUrl =
//                     previewItem["PicURL"] || previewItem["picurl"] || "";
//                   const imageUrls = picUrl.split("|").filter(Boolean);
//                   if (imageUrls.length > 0) {
//                     return (
//                       <div className="image-gallery">
//                         <div className="main-image">
//                           <img
//                             src={imageUrls[0]}
//                             alt="Product Main"
//                             style={{ maxWidth: "100%", maxHeight: "400px" }}
//                           />
//                         </div>
//                         {imageUrls.length > 1 && (
//                           <div className="additional-images">
//                             {imageUrls.slice(1).map((url, index) => (
//                               <img
//                                 key={index}
//                                 src={url}
//                                 alt={`Product ${index + 2}`}
//                                 style={{
//                                   maxWidth: "100px",
//                                   maxHeight: "100px",
//                                   margin: "5px",
//                                 }}
//                               />
//                             ))}
//                           </div>
//                         )}
//                       </div>
//                     );
//                   } else {
//                     return <p>画像がありません。</p>;
//                   }
//                 })()}
//               </div>
//               <div className="preview-details">
//                 <p>
//                   <strong>価格：</strong>{" "}
//                   {previewItem["StartPrice"] ||
//                     previewItem["startprice"] ||
//                     "N/A"}
//                 </p>
//                 <p>
//                   <strong>説明：</strong>
//                 </p>
//                 <div
//                   className="item-description"
//                   dangerouslySetInnerHTML={{
//                     __html:
//                       previewItem["Description"] ||
//                       previewItem["description"] ||
//                       "",
//                   }}
//                 />
//               </div>
//             </div>
//           </div>
//         )}
//       </Modal>

//       <Modal
//         isOpen={isCellModalOpen}
//         onRequestClose={() => setIsCellModalOpen(false)}
//         contentLabel="セルの編集"
//         style={cellModalStyles}
//       >
//         <div>
//           <h2 className="cell-modal-title">セルの編集</h2>
//           <textarea
//             value={cellModalValue}
//             onChange={(e) => setCellModalValue(e.target.value)}
//             style={{
//               width: "100%",
//               height: "200px",
//               fontSize: "16px",
//               lineHeight: "1.5",
//               fontFamily: "Arial, sans-serif",
//               color: "#333",
//               padding: "10px",
//               boxSizing: "border-box",
//             }}
//             disabled={isUpdatingCell}
//           />
//           <div style={{ marginTop: "20px", textAlign: "right" }}>
//             <button
//               onClick={() => {
//                 updateCell(cellModalRowIndex, cellModalColKey, cellModalValue);
//                 setIsCellModalOpen(false);
//               }}
//               className="cell-modal-button"
//               style={{ marginRight: "10px" }}
//               disabled={isUpdatingCell}
//             >
//               保存
//             </button>
//             <button
//               onClick={() => setIsCellModalOpen(false)}
//               className="cell-modal-button"
//               disabled={isUpdatingCell}
//             >
//               キャンセル
//             </button>
//             {isUpdatingCell && (
//               <div
//                 className="loader"
//                 style={{ display: "inline-block", marginLeft: "10px" }}
//               ></div>
//             )}
//           </div>
//         </div>
//       </Modal>

//       <Modal
//         isOpen={isWarningModalOpen}
//         onRequestClose={() => setIsWarningModalOpen(false)}
//         contentLabel="警告"
//         style={warningModalStyles}
//       >
//         <div>
//           <h2>警告</h2>
//           <p>
//             StartPriceの値が初期インポートされた値と同一またはそれ以下になっている商品があります。CSVをダウンロードしますか？
//           </p>
//           <p>該当の商品:</p>
//           <ul>
//             {warningProducts.map((product, index) => (
//               <li key={index}>
//                 {product["Title"] || product["title"] || "タイトルなし"}（行{" "}
//                 {product.index}）
//               </li>
//             ))}
//           </ul>
//           <div style={{ textAlign: "right" }}>
//             <button
//               onClick={() => {
//                 setIsWarningModalOpen(false);
//                 proceedToDownloadCSV(pendingFilename);
//               }}
//               style={{ marginRight: "10px" }}
//             >
//               ダウンロードを続行
//             </button>
//             <button onClick={() => setIsWarningModalOpen(false)}>
//               キャンセル
//             </button>
//           </div>
//         </div>
//       </Modal>

//       {currentData.length === 0 ? (
//         <div className="no-data-message">
//           データがありません。CSVファイルをインポートしてください。
//         </div>
//       ) : (
//         <div className="spreadsheet-container">
//           <div className="table-wrapper">
//             <table className="editable-spreadsheet">
//               <thead>
//                 <tr>
//                   <th
//                     className="row-number-header"
//                     style={{
//                       width: "50px",
//                       position: "relative",
//                       overflow: "hidden",
//                       whiteSpace: "nowrap",
//                       textOverflow: "ellipsis",
//                     }}
//                   >
//                     #
//                   </th>
//                   <th
//                     style={{
//                       width: "100px",
//                       position: "relative",
//                       overflow: "hidden",
//                       whiteSpace: "nowrap",
//                       textOverflow: "ellipsis",
//                     }}
//                   >
//                     操作
//                   </th>
//                   {columnOrder.map((header, colIndex) => (
//                     <th
//                       key={colIndex}
//                       onClick={() => handleColumnClick(colIndex)}
//                       className={
//                         selectedColumn === colIndex ? "selected-column" : ""
//                       }
//                       style={{
//                         minWidth: "50px",
//                         width: `${columnWidth}px`,
//                         maxWidth: `${columnWidth}px`,
//                         position: "relative",
//                         overflow: "hidden",
//                         whiteSpace: "nowrap",
//                         textOverflow: "ellipsis",
//                       }}
//                       title={header}
//                     >
//                       {header}
//                     </th>
//                   ))}
//                 </tr>
//               </thead>
//               <tbody>
//                 {currentData.map((row, rowIndex) => {
//                   const globalRowIndex =
//                     (currentPage - 1) * itemsPerPageValue + rowIndex;
//                   return (
//                     <tr key={globalRowIndex}>
//                       <td className="row-number" style={{ width: "50px" }}>
//                         {globalRowIndex + 1}
//                       </td>
//                       <td style={{ width: "100px" }}>
//                         <div className="action-buttons">
//                           <button
//                             className="preview-button"
//                             onClick={() => handlePreview(row)}
//                           >
//                             プレビュー
//                           </button>
//                           <button
//                             className="delete-button"
//                             onClick={() => handleDeleteRow(globalRowIndex)}
//                           >
//                             削除
//                           </button>
//                         </div>
//                       </td>
//                       {columnOrder.map((colKey, colIndex) => (
//                         <td
//                           key={colIndex}
//                           className={
//                             selectedColumn === colIndex ? "selected-column" : ""
//                           }
//                           style={{
//                             minWidth: "50px",
//                             width: `${columnWidth}px`,
//                             maxWidth: `${columnWidth}px`,
//                             overflow: "hidden",
//                             whiteSpace: "nowrap",
//                             textOverflow: "ellipsis",
//                             backgroundColor: (() => {
//                               const cellValue = row[colKey];
//                               if (
//                                 requiredColumns.includes(colKey) &&
//                                 isCellValueEmptyOrNA(cellValue)
//                               ) {
//                                 return "#ffcccc"; // 薄い赤色
//                               } else if (
//                                 recommendedColumns.includes(colKey) &&
//                                 isCellValueEmptyOrNA(cellValue)
//                               ) {
//                                 return "#ffffcc"; // 薄い黄色
//                               } else if (
//                                 initialData[globalRowIndex] &&
//                                 initialData[globalRowIndex][colKey] !==
//                                   undefined &&
//                                 initialData[globalRowIndex][colKey] !==
//                                   cellValue &&
//                                 !isCellValueEmptyOrNA(cellValue)
//                               ) {
//                                 return "#ccffcc"; // 薄い緑色
//                               }
//                               return "transparent";
//                             })(),
//                           }}
//                           title={row[colKey]}
//                         >
//                           {renderCell(row[colKey], globalRowIndex, colKey)}
//                         </td>
//                       ))}
//                     </tr>
//                   );
//                 })}
//               </tbody>
//             </table>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// }

// export default EditableSpreadsheet;






// // EditableSpreadsheet.js

// import React, { useState, useEffect } from "react";
// import Modal from "react-modal";
// import "./EditableSpreadsheet.css";
// import BulkColumnEdit from "./BulkColumnEdit";

// // モーダルのアクセシビリティ設定
// Modal.setAppElement("#root");

// function EditableSpreadsheet({
//   data,
//   setData,
//   columns,
//   setColumns,
//   setIsDataModified,
//   userId,
// }) {
//   // ステート変数の定義
//   const [error, setError] = useState(null);
//   const [selectedColumn, setSelectedColumn] = useState(null);
//   // セル編集用のステートを追加
//   const [isCellModalOpen, setIsCellModalOpen] = useState(false);
//   const [cellModalValue, setCellModalValue] = useState("");
//   const [cellModalRowIndex, setCellModalRowIndex] = useState(null);
//   const [cellModalColKey, setCellModalColKey] = useState(null);
//   const [isUpdatingCell, setIsUpdatingCell] = useState(false);

//   // 初期データを保持
//   const [initialData, setInitialData] = useState([]);

//   useEffect(() => {
//     if (initialData.length === 0 && data.length > 0) {
//       // データが初期インポートされたときのみ初期データを設定
//       setInitialData(JSON.parse(JSON.stringify(data))); // ディープコピー
//     }
//   }, [data]);

//   // 必須変更カラム（ユーザーが必ず変更する必要があるカラム）
//   const requiredColumns = [
//     "Title",
//     "StartPrice",
//     "Description",
//     "Category",
//     "PaymentProfileName",
//     "ReturnProfileName",
//     "ShippingProfileName",
//     "MinimumBestOfferPrice",
//     "BestOfferAutoAcceptPrice",
//   ];

//   // 推奨変更カラム（'C:' で始まるカラム）
//   const recommendedColumns = columns.filter((col) => col.startsWith("C:"));

//   // セルの値が空または 'NA' かを判定する関数
//   const isCellValueEmptyOrNA = (value) => {
//     return (
//       value === undefined || value === null || value === "" || value === "NA"
//     );
//   };

//   // プレビュー用のステート
//   const [previewItem, setPreviewItem] = useState(null); // プレビューするアイテム
//   const [isPreviewOpen, setIsPreviewOpen] = useState(false); // プレビューモーダルの開閉

//   // カラム幅を一律に管理するステートを追加
//   const [columnWidth, setColumnWidth] = useState(150); // デフォルトの幅を150pxに設定

//   // カラム順序を管理するステートを追加
//   const [columnOrder, setColumnOrder] = useState(columns);

//   // カラム順序テンプレートを管理する状態
//   const [columnOrderTemplates, setColumnOrderTemplates] = useState({});

//   // 選択されたテンプレート名
//   const [selectedTemplateName, setSelectedTemplateName] = useState("");

//   // ローカルストレージからテンプレートを読み込む
//   useEffect(() => {
//     const savedTemplates = localStorage.getItem("columnOrderTemplates");
//     if (savedTemplates) {
//       setColumnOrderTemplates(JSON.parse(savedTemplates));
//     }
//   }, []);

//   // テンプレートをローカルストレージに保存
//   useEffect(() => {
//     localStorage.setItem(
//       "columnOrderTemplates",
//       JSON.stringify(columnOrderTemplates)
//     );
//   }, [columnOrderTemplates]);

//   // dataが更新されたときにcolumnsを更新
//   useEffect(() => {
//     if (data.length > 0) {
//       const dataColumns = Object.keys(data[0]);
//       setColumns(dataColumns);
//     }
//   }, [data, setColumns]);

//   // columnsまたはcolumnOrderが更新された場合、columnOrderを更新
//   useEffect(() => {
//     const newColumnOrder = columnOrder.filter((col) => columns.includes(col));
//     const addedColumns = columns.filter((col) => !newColumnOrder.includes(col));
//     setColumnOrder([...newColumnOrder, ...addedColumns]);
//   }, [columns]);

//   // カラム順序テンプレートを適用する関数
//   const applyColumnTemplate = (templateName) => {
//     if (!templateName) {
//       // テンプレート名が空の場合、デフォルトのカラム順序を適用
//       setColumnOrder(columns);
//       return;
//     }
//     const templateOrder = columnOrderTemplates[templateName];
//     if (templateOrder) {
//       const newColumnOrder = templateOrder.filter((col) =>
//         columns.includes(col)
//       );
//       const remainingColumns = columns.filter(
//         (col) => !newColumnOrder.includes(col)
//       );
//       // 残りのカラムを末尾に追加
//       setColumnOrder([...newColumnOrder, ...remainingColumns]);
//     }
//   };

//   // セルの更新
//   const updateCell = (rowIndex, colKey, newValue) => {
//     try {
//       setIsUpdatingCell(true); // 更新開始
//       const newData = [...data];
//       newData[rowIndex] = { ...newData[rowIndex], [colKey]: newValue };
//       setData(newData);
//       // セルの値が変更された場合、initialDataは変更しない
//       setIsDataModified(true);
//       setIsUpdatingCell(false); // 更新終了
//     } catch (err) {
//       console.error("Error updating cell:", err);
//       setError("セルの更新に失敗しました");
//       setIsUpdatingCell(false);
//     }
//   };

//   // カラムのクリックハンドラー
//   const handleColumnClick = (colIndex) => {
//     setSelectedColumn(colIndex);
//   };

//   // セルのレンダリング
//   const renderCell = (cell, rowIndex, colKey) => {
//     const header = colKey.toLowerCase();

//     // セルのスタイルを動的に設定
//     let cellStyle = {
//       cursor: "pointer",
//       width: "100%",
//       boxSizing: "border-box",
//     };

//     // セルの値を取得
//     const cellValue = cell;

//     // 必須変更カラムの場合、値が 'NA' または空の場合に背景色を赤色に設定
//     if (requiredColumns.includes(colKey) && isCellValueEmptyOrNA(cellValue)) {
//       cellStyle.backgroundColor = "#ffcccc"; // 薄い赤色
//     }
//     // 推奨変更カラムの場合、値が 'NA' または空の場合に背景色を黄色に設定
//     else if (
//       recommendedColumns.includes(colKey) &&
//       isCellValueEmptyOrNA(cellValue)
//     ) {
//       cellStyle.backgroundColor = "#ffffcc"; // 薄い黄色
//     }
//     // セルが変更されていて、値が 'NA' でない場合、背景色を緑色に設定
//     if (
//       initialData[rowIndex] &&
//       initialData[rowIndex][colKey] !== undefined &&
//       initialData[rowIndex][colKey] !== cellValue &&
//       !isCellValueEmptyOrNA(cellValue)
//     ) {
//       cellStyle.backgroundColor = "#ccffcc"; // 薄い緑色
//     }

//     // PicURL や 画像 カラムの処理
//     if (header === "picurl" || header === "画像") {
//       const imageUrls = cell?.split("|") || [];
//       return (
//         <img
//           src={imageUrls[0]}
//           alt="Product"
//           style={{ maxWidth: "100px", maxHeight: "100px" }}
//         />
//       );
//     } else {
//       return (
//         <input
//           value={cell || ""}
//           onClick={() => {
//             setCellModalValue(cell || "");
//             setCellModalRowIndex(rowIndex);
//             setCellModalColKey(colKey);
//             setIsCellModalOpen(true);
//           }}
//           readOnly // 直接編集を防ぐ場合はreadOnlyを設定
//           style={cellStyle}
//         />
//       );
//     }
//   };

//   // 単一行を削除
//   const handleDeleteRow = (rowIndex) => {
//     if (rowIndex < 0) {
//       setError("ヘッダー行は削除できません。");
//       return;
//     }

//     try {
//       const newData = data.filter((_, index) => index !== rowIndex);
//       const newInitialData = initialData.filter((_, index) => index !== rowIndex);
//       setData(newData);
//       setInitialData(newInitialData); // initialDataを更新
//       setIsDataModified(true); // データが変更されたので状態を更新
//       setError(null);
//     } catch (err) {
//       console.error("Error deleting row:", err);
//       setError("行の削除に失敗しました");
//     }
//   };

//   // プレビューを表示する関数
//   const handlePreview = (row) => {
//     setPreviewItem(row);
//     setIsPreviewOpen(true);
//   };

//   // プレビューモーダルを閉じる関数
//   const closePreviewModal = () => {
//     setIsPreviewOpen(false);
//     setPreviewItem(null);
//   };

//   // モーダルのスタイル設定
//   const cellModalStyles = {
//     content: {
//       top: "50%",
//       left: "50%",
//       right: "auto",
//       bottom: "auto",
//       transform: "translate(-50%, -50%)",
//       width: "600px",
//       maxHeight: "80%",
//       overflow: "auto",
//       padding: "20px",
//       boxSizing: "border-box",
//     },
//     overlay: {
//       backgroundColor: "rgba(0, 0, 0, 0.5)",
//     },
//   };

//   const previewModalStyles = {
//     content: {
//       backgroundColor: "white", // モーダルの背景色を白に設定
//       top: "50%",
//       left: "50%",
//       right: "auto",
//       bottom: "auto",
//       marginRight: "-50%",
//       transform: "translate(-50%, -50%)",
//       width: "90%",
//       maxWidth: "1000px",
//       maxHeight: "90%",
//       overflow: "auto",
//     },
//     overlay: {
//       backgroundColor: "rgba(0, 0, 0, 0.8)", // 背景を濃いめの半透明黒に設定
//       zIndex: 1000,
//     },
//   };

//   // 警告モーダルのスタイル設定
//   const warningModalStyles = {
//     content: {
//       top: "50%",
//       left: "50%",
//       right: "auto",
//       bottom: "auto",
//       transform: "translate(-50%, -50%)",
//       width: "600px",
//       maxHeight: "80%",
//       overflow: "auto",
//       padding: "20px",
//       boxSizing: "border-box",
//     },
//     overlay: {
//       backgroundColor: "rgba(0, 0, 0, 0.5)",
//     },
//   };

//   // ページネーションのステート
//   const [itemsPerPage, setItemsPerPage] = useState(50); // 表示件数
//   const [currentPage, setCurrentPage] = useState(1); // 現在のページ
//   const [isLoading, setIsLoading] = useState(false); // ローディング状態

//   useEffect(() => {
//     // データが変更されたときにcurrentPageを1にリセット
//     setCurrentPage(1);
//   }, [data]);

//   const itemsPerPageValue = itemsPerPage === "All" ? data.length : itemsPerPage;
//   const totalPages =
//     itemsPerPage === "All" ? 1 : Math.ceil(data.length / itemsPerPageValue);

//   // ページまたは表示件数が変更されたときの処理
//   useEffect(() => {
//     setIsLoading(true);
//     const timer = setTimeout(() => {
//       setIsLoading(false);
//     }, 500); // ローディングインジケーターを表示する時間
//     return () => clearTimeout(timer);
//   }, [itemsPerPage, currentPage, data]);

//   // 表示件数の変更ハンドラー
//   const handleItemsPerPageChange = (e) => {
//     const value = e.target.value;
//     if (value === "All") {
//       setItemsPerPage("All");
//     } else {
//       setItemsPerPage(parseInt(value));
//     }
//     setCurrentPage(1); // ページをリセット
//   };

//   // ページ変更ハンドラー
//   const handlePageChange = (newPage) => {
//     if (newPage < 1 || newPage > totalPages) return;
//     setCurrentPage(newPage);
//   };

//   // 現在のページに表示するデータ
//   let currentData = [];
//   if (itemsPerPage === "All") {
//     currentData = data;
//   } else {
//     const startIndex = (currentPage - 1) * itemsPerPageValue;
//     const endIndex = startIndex + itemsPerPageValue;
//     currentData = data.slice(startIndex, endIndex);
//   }

//   // StartPriceの値をチェックし、該当商品のリストを返す関数
//   const getProductsWithLowerStartPrice = () => {
//     if (!columns || columns.length === 0) {
//       return [];
//     }

//     // "StartPrice"キーを大文字小文字を区別せずに検索
//     const startPriceKey = columns.find(
//       (key) => key.toLowerCase() === "startprice"
//     );

//     if (!startPriceKey) {
//       // StartPrice カラムがない場合は空のリストを返す
//       return [];
//     }

//     const warningProducts = [];

//     for (let i = 0; i < data.length; i++) {
//       const currentPriceStr = data[i][startPriceKey];
//       const initialPriceStr = initialData[i]?.[startPriceKey];

//       // initialData[i] または initialPriceStr が未定義の場合はスキップ
//       if (!initialData[i] || initialPriceStr === undefined) {
//         continue;
//       }

//       const currentPrice = parseFloat(currentPriceStr);
//       const initialPrice = parseFloat(initialPriceStr);

//       if (!isNaN(currentPrice) && !isNaN(initialPrice)) {
//         if (currentPrice <= initialPrice) {
//           // 該当商品の情報を追加（行番号も含める）
//           warningProducts.push({
//             ...data[i],
//             index: i + 1, // 行番号（1から始まる）
//           });
//         }
//       }
//     }
//     return warningProducts;
//   };

//   // CSVダウンロード処理
//   const handleDownloadCSV = () => {
//     const warningProductsList = getProductsWithLowerStartPrice();
//     if (warningProductsList.length > 0) {
//       // 該当商品がある場合は警告モーダルを表示
//       setWarningProducts(warningProductsList);
//       setIsWarningModalOpen(true);
//     } else {
//       // 該当商品がない場合は直接ダウンロード
//       proceedToDownloadCSV();
//     }
//   };

//   // CSVダウンロードを実行する関数
//   const proceedToDownloadCSV = () => {
//     const csvContent = generateCSVContent(data);
//     downloadCSV(csvContent, "data.csv");
//   };

//   // CSVコンテンツを生成する関数
//   const generateCSVContent = (data) => {
//     if (!data || data.length === 0) return "";

//     const headers = Object.keys(data[0]);
//     const csvRows = [];

//     // ヘッダー行を追加
//     csvRows.push(headers.join(","));

//     // データ行を追加
//     data.forEach((row) => {
//       const values = headers.map((header) => {
//         const value =
//           row[header] !== undefined && row[header] !== null ? row[header] : "";
//         const escaped = ("" + value).replace(/"/g, '""');
//         return `"${escaped}"`;
//       });
//       csvRows.push(values.join(","));
//     });

//     return csvRows.join("\n");
//   };

//   // CSVをダウンロードする関数
//   const downloadCSV = (csvContent, filename) => {
//     const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
//     if (navigator.msSaveBlob) {
//       // IE 10+
//       navigator.msSaveBlob(blob, filename);
//     } else {
//       const link = document.createElement("a");
//       if (link.download !== undefined) {
//         // ブラウザがHTML5のダウンロード属性をサポートしている場合
//         const url = URL.createObjectURL(blob);
//         link.setAttribute("href", url);
//         link.setAttribute("download", filename);
//         link.style.visibility = "hidden";
//         document.body.appendChild(link);
//         link.click();
//         document.body.removeChild(link);
//       }
//     }
//   };

//   // 警告モーダルの表示制御用のステートを追加
//   const [isWarningModalOpen, setIsWarningModalOpen] = useState(false);
//   const [warningProducts, setWarningProducts] = useState([]);

//   // レンダリング部分
//   return (
//     <div className="editable-spreadsheet-container">
//       {/* カラム一括編集と削除ワード管理機能を含むBulkColumnEditコンポーネント */}
//       <BulkColumnEdit
//         data={data}
//         setData={setData}
//         columns={columns}
//         setColumns={setColumns}
//         columnOrder={columnOrder} // 追加
//         setIsDataModified={setIsDataModified}
//         userId={userId}
//         columnOrderTemplates={columnOrderTemplates}
//         setColumnOrderTemplates={setColumnOrderTemplates}
//         applyColumnTemplate={applyColumnTemplate}
//         selectedTemplateName={selectedTemplateName}
//         setSelectedTemplateName={setSelectedTemplateName}
//         setColumnOrder={setColumnOrder}
//         columnWidth={columnWidth}
//         setColumnWidth={setColumnWidth}
//         initialData={initialData} // 追加
//         setInitialData={setInitialData} // 追加
//       />

//       {/* エラーメッセージ表示 */}
//       {error && <div className="error-message">{error}</div>}

//       {/* 表示件数の選択、ページネーション、CSVダウンロードボタンを横並びに配置 */}
//       <div className="top-controls">
//         {/* 表示件数の選択 */}
//         <div className="pagination-controls">
//           <label htmlFor="itemsPerPage">表示件数:</label>
//           <select
//             id="itemsPerPage"
//             value={itemsPerPage}
//             onChange={handleItemsPerPageChange}
//           >
//             <option value={50}>50</option>
//             <option value={100}>100</option>
//             <option value={200}>200</option>
//             <option value="All">すべて</option>
//           </select>
//         </div>

//         {/* ページネーションコントロール */}
//         {totalPages > 1 && (
//           <div className="pagination-controls">
//             <button
//               onClick={() => handlePageChange(currentPage - 1)}
//               disabled={currentPage === 1}
//             >
//               前へ
//             </button>
//             <span>
//               {currentPage} / {totalPages}
//             </span>
//             <button
//               onClick={() => handlePageChange(currentPage + 1)}
//               disabled={currentPage === totalPages}
//             >
//               次へ
//             </button>
//           </div>
//         )}

//         {/* CSVダウンロードボタン */}
//         <div className="csv-download-button-container">
//           <button
//             onClick={handleDownloadCSV}
//             className="csv-download-button"
//           >
//             CSVをダウンロード
//           </button>
//         </div>
//       </div>

//       {/* ローディングインジケーター */}
//       {isLoading && <div className="loading-indicator">読み込み中...</div>}

//       {/* プレビューモーダル */}
//       <Modal
//         isOpen={isPreviewOpen}
//         onRequestClose={closePreviewModal}
//         contentLabel="商品プレビュー"
//         style={previewModalStyles}
//       >
//         {previewItem && (
//           <div className="preview-modal-content">
//             <button className="close-modal-button" onClick={closePreviewModal}>
//               ×
//             </button>
//             <div className="preview-header">
//               <h2>{previewItem["Title"] || previewItem["title"]}</h2>
//             </div>
//             <div className="preview-body">
//               <div className="preview-images">
//                 {/* 画像を表示 */}
//                 {(() => {
//                   const picUrl =
//                     previewItem["PicURL"] || previewItem["picurl"] || "";
//                   const imageUrls = picUrl.split("|").filter(Boolean);
//                   if (imageUrls.length > 0) {
//                     return (
//                       <div className="image-gallery">
//                         {/* メイン画像 */}
//                         <div className="main-image">
//                           <img
//                             src={imageUrls[0]}
//                             alt="Product Main"
//                             style={{ maxWidth: "100%", maxHeight: "400px" }}
//                           />
//                         </div>
//                         {/* その他の画像 */}
//                         {imageUrls.length > 1 && (
//                           <div className="additional-images">
//                             {imageUrls.slice(1).map((url, index) => (
//                               <img
//                                 key={index}
//                                 src={url}
//                                 alt={`Product ${index + 2}`}
//                                 style={{
//                                   maxWidth: "100px",
//                                   maxHeight: "100px",
//                                   margin: "5px",
//                                 }}
//                               />
//                             ))}
//                           </div>
//                         )}
//                       </div>
//                     );
//                   } else {
//                     return <p>画像がありません。</p>;
//                   }
//                 })()}
//               </div>
//               <div className="preview-details">
//                 <p>
//                   <strong>価格：</strong>{" "}
//                   {previewItem["StartPrice"] ||
//                     previewItem["startprice"] ||
//                     "N/A"}
//                 </p>
//                 <p>
//                   <strong>説明：</strong>
//                 </p>
//                 <div
//                   className="item-description"
//                   dangerouslySetInnerHTML={{
//                     __html:
//                       previewItem["Description"] ||
//                       previewItem["description"] ||
//                       "",
//                   }}
//                 />
//               </div>
//             </div>
//           </div>
//         )}
//       </Modal>

//       {/* セル編集用のモーダル */}
//       <Modal
//         isOpen={isCellModalOpen}
//         onRequestClose={() => setIsCellModalOpen(false)}
//         contentLabel="セルの編集"
//         style={cellModalStyles}
//       >
//         <div>
//           <h2 className="cell-modal-title">セルの編集</h2>
//           <textarea
//             value={cellModalValue}
//             onChange={(e) => setCellModalValue(e.target.value)}
//             style={{
//               width: "100%",
//               height: "200px",
//               fontSize: "16px",
//               lineHeight: "1.5",
//               fontFamily: "Arial, sans-serif",
//               color: "#333",
//               padding: "10px",
//               boxSizing: "border-box",
//             }}
//             disabled={isUpdatingCell} // 更新中は入力を無効化
//           />
//           <div style={{ marginTop: "20px", textAlign: "right" }}>
//             <button
//               onClick={() => {
//                 updateCell(cellModalRowIndex, cellModalColKey, cellModalValue);
//                 setIsCellModalOpen(false);
//               }}
//               className="cell-modal-button"
//               style={{ marginRight: "10px" }}
//               disabled={isUpdatingCell} // 更新中はボタンを無効化
//             >
//               保存
//             </button>
//             <button
//               onClick={() => setIsCellModalOpen(false)}
//               className="cell-modal-button"
//               disabled={isUpdatingCell} // 更新中はボタンを無効化
//             >
//               キャンセル
//             </button>
//             {isUpdatingCell && (
//               <div
//                 className="loader"
//                 style={{ display: "inline-block", marginLeft: "10px" }}
//               ></div>
//             )}
//           </div>
//         </div>
//       </Modal>

//       {/* 警告モーダル */}
//       <Modal
//         isOpen={isWarningModalOpen}
//         onRequestClose={() => setIsWarningModalOpen(false)}
//         contentLabel="警告"
//         style={warningModalStyles}
//       >
//         <div>
//           <h2>警告</h2>
//           <p>
//             StartPriceの値が初期インポートされた値と同一またはそれ以下になっている商品があります。CSVをダウンロードしますか？
//           </p>
//           <p>該当の商品:</p>
//           <ul>
//             {warningProducts.map((product, index) => (
//               <li key={index}>
//                 {product["Title"] || product["title"] || "タイトルなし"}（行{" "}
//                 {product.index}）
//               </li>
//             ))}
//           </ul>
//           <div style={{ textAlign: "right" }}>
//             <button
//               onClick={() => {
//                 setIsWarningModalOpen(false);
//                 proceedToDownloadCSV();
//               }}
//               style={{ marginRight: "10px" }}
//             >
//               ダウンロードを続行
//             </button>
//             <button onClick={() => setIsWarningModalOpen(false)}>
//               キャンセル
//             </button>
//           </div>
//         </div>
//       </Modal>

//       {/* スプレッドシートの表示 */}
//       {currentData.length === 0 ? (
//         <div className="no-data-message">
//           データがありません。CSVファイルをインポートしてください。
//         </div>
//       ) : (
//         <div className="spreadsheet-container">
//           <div className="table-wrapper">
//             <table className="editable-spreadsheet">
//               <thead>
//                 <tr>
//                   <th
//                     className="row-number-header"
//                     style={{
//                       width: "50px",
//                       position: "relative",
//                       overflow: "hidden",
//                       whiteSpace: "nowrap",
//                       textOverflow: "ellipsis",
//                     }}
//                   >
//                     #
//                   </th>
//                   <th
//                     style={{
//                       width: "100px",
//                       position: "relative",
//                       overflow: "hidden",
//                       whiteSpace: "nowrap",
//                       textOverflow: "ellipsis",
//                     }}
//                   >
//                     操作
//                   </th>
//                   {columnOrder.map((header, colIndex) => (
//                     <th
//                       key={colIndex}
//                       onClick={() => handleColumnClick(colIndex)}
//                       className={
//                         selectedColumn === colIndex ? "selected-column" : ""
//                       }
//                       style={{
//                         minWidth: "50px",
//                         width: `${columnWidth}px`,
//                         maxWidth: `${columnWidth}px`,
//                         position: "relative",
//                         overflow: "hidden",
//                         whiteSpace: "nowrap",
//                         textOverflow: "ellipsis",
//                       }}
//                       title={header}
//                     >
//                       {header}
//                     </th>
//                   ))}
//                 </tr>
//               </thead>
//               <tbody>
//                 {currentData.map((row, rowIndex) => {
//                   const globalRowIndex =
//                     (currentPage - 1) * itemsPerPageValue + rowIndex;
//                   return (
//                     <tr key={globalRowIndex}>
//                       <td className="row-number" style={{ width: "50px" }}>
//                         {globalRowIndex + 1}
//                       </td>
//                       <td style={{ width: "100px" }}>
//                         <div className="action-buttons">
//                           <button
//                             className="preview-button"
//                             onClick={() => handlePreview(row)}
//                           >
//                             プレビュー
//                           </button>
//                           <button
//                             className="delete-button"
//                             onClick={() => handleDeleteRow(globalRowIndex)}
//                           >
//                             削除
//                           </button>
//                         </div>
//                       </td>
//                       {columnOrder.map((colKey, colIndex) => (
//                         <td
//                           key={colIndex}
//                           className={
//                             selectedColumn === colIndex ? "selected-column" : ""
//                           }
//                           style={{
//                             minWidth: "50px",
//                             width: `${columnWidth}px`,
//                             maxWidth: `${columnWidth}px`,
//                             overflow: "hidden",
//                             whiteSpace: "nowrap",
//                             textOverflow: "ellipsis",
//                             backgroundColor: (() => {
//                               const cellValue = row[colKey];
//                               // セルの背景色を動的に設定
//                               if (
//                                 requiredColumns.includes(colKey) &&
//                                 isCellValueEmptyOrNA(cellValue)
//                               ) {
//                                 return "#ffcccc"; // 薄い赤色
//                               } else if (
//                                 recommendedColumns.includes(colKey) &&
//                                 isCellValueEmptyOrNA(cellValue)
//                               ) {
//                                 return "#ffffcc"; // 薄い黄色
//                               } else if (
//                                 initialData[globalRowIndex] &&
//                                 initialData[globalRowIndex][colKey] !==
//                                   undefined &&
//                                 initialData[globalRowIndex][colKey] !==
//                                   cellValue &&
//                                 !isCellValueEmptyOrNA(cellValue)
//                               ) {
//                                 return "#ccffcc"; // 薄い緑色
//                               }
//                               return "transparent";
//                             })(),
//                           }}
//                           title={row[colKey]}
//                         >
//                           {renderCell(row[colKey], globalRowIndex, colKey)}
//                         </td>
//                       ))}
//                     </tr>
//                   );
//                 })}
//               </tbody>
//             </table>
//           </div>
//         </div>
//       )}

//       {/* ページネーションコントロール（下部）は削除します */}
//     </div>
//   );
// }

// export default EditableSpreadsheet;





// // EditableSpreadsheet.js

// import React, { useState, useEffect } from "react";
// import Modal from "react-modal";
// import "./EditableSpreadsheet.css";
// import BulkColumnEdit from "./BulkColumnEdit";

// // モーダルのアクセシビリティ設定
// Modal.setAppElement("#root");

// function EditableSpreadsheet({
//   data,
//   setData,
//   columns,
//   setColumns,
//   setIsDataModified,
//   userId,
// }) {
//   // ステート変数の定義
//   const [error, setError] = useState(null);
//   const [selectedColumn, setSelectedColumn] = useState(null);
//   // セル編集用のステートを追加
//   const [isCellModalOpen, setIsCellModalOpen] = useState(false);
//   const [cellModalValue, setCellModalValue] = useState("");
//   const [cellModalRowIndex, setCellModalRowIndex] = useState(null);
//   const [cellModalColKey, setCellModalColKey] = useState(null);
//   const [isUpdatingCell, setIsUpdatingCell] = useState(false);

//   // 初期データを保持
//   const [initialData, setInitialData] = useState([]);

//   useEffect(() => {
//     if (initialData.length === 0 && data.length > 0) {
//       // データが初期インポートされたときのみ初期データを設定
//       setInitialData(JSON.parse(JSON.stringify(data))); // ディープコピー
//     }
//   }, [data]);

//   // 必須変更カラム（ユーザーが必ず変更する必要があるカラム）
//   const requiredColumns = [
//     "Title",
//     "StartPrice",
//     "Description",
//     "Category",
//     "PaymentProfileName",
//     "ReturnProfileName",
//     "ShippingProfileName",
//     "MinimumBestOfferPrice",
//     "BestOfferAutoAcceptPrice",
//   ];

//   // 推奨変更カラム（'C:' で始まるカラム）
//   const recommendedColumns = columns.filter((col) => col.startsWith("C:"));

//   // セルの値が空または 'NA' かを判定する関数
//   const isCellValueEmptyOrNA = (value) => {
//     return (
//       value === undefined || value === null || value === "" || value === "NA"
//     );
//   };

//   // プレビュー用のステート
//   const [previewItem, setPreviewItem] = useState(null); // プレビューするアイテム
//   const [isPreviewOpen, setIsPreviewOpen] = useState(false); // プレビューモーダルの開閉

//   // カラム幅を一律に管理するステートを追加
//   const [columnWidth, setColumnWidth] = useState(150); // デフォルトの幅を150pxに設定

//   // カラム順序を管理するステートを追加
//   const [columnOrder, setColumnOrder] = useState(columns);

//   // カラム順序テンプレートを管理する状態
//   const [columnOrderTemplates, setColumnOrderTemplates] = useState({});

//   // 選択されたテンプレート名
//   const [selectedTemplateName, setSelectedTemplateName] = useState("");

//   // ローカルストレージからテンプレートを読み込む
//   useEffect(() => {
//     const savedTemplates = localStorage.getItem("columnOrderTemplates");
//     if (savedTemplates) {
//       setColumnOrderTemplates(JSON.parse(savedTemplates));
//     }
//   }, []);

//   // テンプレートをローカルストレージに保存
//   useEffect(() => {
//     localStorage.setItem(
//       "columnOrderTemplates",
//       JSON.stringify(columnOrderTemplates)
//     );
//   }, [columnOrderTemplates]);

//   // dataが更新されたときにcolumnsを更新
//   useEffect(() => {
//     if (data.length > 0) {
//       const dataColumns = Object.keys(data[0]);
//       setColumns(dataColumns);
//     }
//   }, [data, setColumns]);

//   // columnsまたはcolumnOrderが更新された場合、columnOrderを更新
//   useEffect(() => {
//     const newColumnOrder = columnOrder.filter((col) => columns.includes(col));
//     const addedColumns = columns.filter((col) => !newColumnOrder.includes(col));
//     setColumnOrder([...newColumnOrder, ...addedColumns]);
//   }, [columns]);

//   // カラム順序テンプレートを適用する関数
//   const applyColumnTemplate = (templateName) => {
//     if (!templateName) {
//       // テンプレート名が空の場合、デフォルトのカラム順序を適用
//       setColumnOrder(columns);
//       return;
//     }
//     const templateOrder = columnOrderTemplates[templateName];
//     if (templateOrder) {
//       const newColumnOrder = templateOrder.filter((col) =>
//         columns.includes(col)
//       );
//       const remainingColumns = columns.filter(
//         (col) => !newColumnOrder.includes(col)
//       );
//       // 残りのカラムを末尾に追加
//       setColumnOrder([...newColumnOrder, ...remainingColumns]);
//     }
//   };

//   // セルの更新
//   const updateCell = (rowIndex, colKey, newValue) => {
//     try {
//       setIsUpdatingCell(true); // 更新開始
//       const newData = [...data];
//       newData[rowIndex] = { ...newData[rowIndex], [colKey]: newValue };
//       setData(newData);
//       setIsDataModified(true);
//       setIsUpdatingCell(false); // 更新終了
//     } catch (err) {
//       console.error("Error updating cell:", err);
//       setError("セルの更新に失敗しました");
//       setIsUpdatingCell(false);
//     }
//   };

//   // カラムのクリックハンドラー
//   const handleColumnClick = (colIndex) => {
//     setSelectedColumn(colIndex);
//   };

//   // セルのレンダリング
//   const renderCell = (cell, rowIndex, colKey) => {
//     const header = colKey.toLowerCase();

//     // セルのスタイルを動的に設定
//     let cellStyle = {
//       cursor: "pointer",
//       width: "100%",
//       boxSizing: "border-box",
//     };

//     // セルの値を取得
//     const cellValue = cell;

//     // 必須変更カラムの場合、値が 'NA' または空の場合に背景色を赤色に設定
//     if (requiredColumns.includes(colKey) && isCellValueEmptyOrNA(cellValue)) {
//       cellStyle.backgroundColor = "#ffcccc"; // 薄い赤色
//     }
//     // 推奨変更カラムの場合、値が 'NA' または空の場合に背景色を黄色に設定
//     else if (
//       recommendedColumns.includes(colKey) &&
//       isCellValueEmptyOrNA(cellValue)
//     ) {
//       cellStyle.backgroundColor = "#ffffcc"; // 薄い黄色
//     }
//     // セルが変更されていて、値が 'NA' でない場合、背景色を緑色に設定
//     if (
//       initialData[rowIndex] &&
//       initialData[rowIndex][colKey] !== undefined &&
//       initialData[rowIndex][colKey] !== cellValue &&
//       !isCellValueEmptyOrNA(cellValue)
//     ) {
//       cellStyle.backgroundColor = "#ccffcc"; // 薄い緑色
//     }

//     // PicURL や 画像 カラムの処理
//     if (header === "picurl" || header === "画像") {
//       const imageUrls = cell?.split("|") || [];
//       return (
//         <img
//           src={imageUrls[0]}
//           alt="Product"
//           style={{ maxWidth: "100px", maxHeight: "100px" }}
//         />
//       );
//     } else {
//       return (
//         <input
//           value={cell || ""}
//           onClick={() => {
//             setCellModalValue(cell || "");
//             setCellModalRowIndex(rowIndex);
//             setCellModalColKey(colKey);
//             setIsCellModalOpen(true);
//           }}
//           readOnly // 直接編集を防ぐ場合はreadOnlyを設定
//           style={cellStyle}
//         />
//       );
//     }
//   };

//   // 単一行を削除
//   const handleDeleteRow = (rowIndex) => {
//     if (rowIndex < 0) {
//       setError("ヘッダー行は削除できません。");
//       return;
//     }

//     try {
//       const newData = data.filter((_, index) => index !== rowIndex);
//       const newInitialData = initialData.filter(
//         (_, index) => index !== rowIndex
//       ); // initialDataも更新
//       setData(newData);
//       setInitialData(newInitialData); // initialDataを更新
//       setIsDataModified(true); // データが変更されたので状態を更新
//       setError(null);
//     } catch (err) {
//       console.error("Error deleting row:", err);
//       setError("行の削除に失敗しました");
//     }
//   };

//   // プレビューを表示する関数
//   const handlePreview = (row) => {
//     setPreviewItem(row);
//     setIsPreviewOpen(true);
//   };

//   // プレビューモーダルを閉じる関数
//   const closePreviewModal = () => {
//     setIsPreviewOpen(false);
//     setPreviewItem(null);
//   };

//   // モーダルのスタイル設定
//   const cellModalStyles = {
//     content: {
//       top: "50%",
//       left: "50%",
//       right: "auto",
//       bottom: "auto",
//       transform: "translate(-50%, -50%)",
//       width: "600px",
//       maxHeight: "80%",
//       overflow: "auto",
//       padding: "20px",
//       boxSizing: "border-box",
//     },
//     overlay: {
//       backgroundColor: "rgba(0, 0, 0, 0.5)",
//     },
//   };

//   const previewModalStyles = {
//     content: {
//       backgroundColor: "white", // モーダルの背景色を白に設定
//       top: "50%",
//       left: "50%",
//       right: "auto",
//       bottom: "auto",
//       marginRight: "-50%",
//       transform: "translate(-50%, -50%)",
//       width: "90%",
//       maxWidth: "1000px",
//       maxHeight: "90%",
//       overflow: "auto",
//     },
//     overlay: {
//       backgroundColor: "rgba(0, 0, 0, 0.8)", // 背景を濃いめの半透明黒に設定
//       zIndex: 1000,
//     },
//   };
  

//   // 警告モーダルのスタイル設定
//   const warningModalStyles = {
//     content: {
//       top: "50%",
//       left: "50%",
//       right: "auto",
//       bottom: "auto",
//       transform: "translate(-50%, -50%)",
//       width: "600px",
//       maxHeight: "80%",
//       overflow: "auto",
//       padding: "20px",
//       boxSizing: "border-box",
//     },
//     overlay: {
//       backgroundColor: "rgba(0, 0, 0, 0.5)",
//     },
//   };

//   // ページネーションのステート
//   const [itemsPerPage, setItemsPerPage] = useState(50); // 表示件数
//   const [currentPage, setCurrentPage] = useState(1); // 現在のページ
//   const [isLoading, setIsLoading] = useState(false); // ローディング状態

//   useEffect(() => {
//     // データが変更されたときにcurrentPageを1にリセット
//     setCurrentPage(1);
//   }, [data]);

//   const itemsPerPageValue = itemsPerPage === "All" ? data.length : itemsPerPage;
//   const totalPages =
//     itemsPerPage === "All" ? 1 : Math.ceil(data.length / itemsPerPageValue);

//   // ページまたは表示件数が変更されたときの処理
//   useEffect(() => {
//     setIsLoading(true);
//     const timer = setTimeout(() => {
//       setIsLoading(false);
//     }, 500); // ローディングインジケーターを表示する時間
//     return () => clearTimeout(timer);
//   }, [itemsPerPage, currentPage, data]);

//   // 表示件数の変更ハンドラー
//   const handleItemsPerPageChange = (e) => {
//     const value = e.target.value;
//     if (value === "All") {
//       setItemsPerPage("All");
//     } else {
//       setItemsPerPage(parseInt(value));
//     }
//     setCurrentPage(1); // ページをリセット
//   };

//   // ページ変更ハンドラー
//   const handlePageChange = (newPage) => {
//     if (newPage < 1 || newPage > totalPages) return;
//     setCurrentPage(newPage);
//   };

//   // 現在のページに表示するデータ
//   let currentData = [];
//   if (itemsPerPage === "All") {
//     currentData = data;
//   } else {
//     const startIndex = (currentPage - 1) * itemsPerPageValue;
//     const endIndex = startIndex + itemsPerPageValue;
//     currentData = data.slice(startIndex, endIndex);
//   }

//   // StartPriceの値をチェックし、該当商品のリストを返す関数
//   const getProductsWithLowerStartPrice = () => {
//     // "StartPrice"キーを大文字小文字を区別せずに検索
//     const startPriceKey = columns.find(
//       (key) => key.toLowerCase() === "startprice"
//     );

//     if (!startPriceKey) {
//       // StartPrice カラムがない場合は空のリストを返す
//       return [];
//     }

//     const warningProducts = [];

//     for (let i = 0; i < data.length; i++) {
//       const currentPriceStr = data[i][startPriceKey];
//       const initialPriceStr = initialData[i]?.[startPriceKey];

//       const currentPrice = parseFloat(currentPriceStr);
//       const initialPrice = parseFloat(initialPriceStr);

//       if (!isNaN(currentPrice) && !isNaN(initialPrice)) {
//         if (currentPrice <= initialPrice) {
//           // 該当商品の情報を追加（行番号も含める）
//           warningProducts.push({
//             ...data[i],
//             index: i + 1, // 行番号（1から始まる）
//           });
//         }
//       }
//     }
//     return warningProducts;
//   };

//   // CSVダウンロード処理
//   const handleDownloadCSV = () => {
//     const warningProductsList = getProductsWithLowerStartPrice();
//     if (warningProductsList.length > 0) {
//       // 該当商品がある場合は警告モーダルを表示
//       setWarningProducts(warningProductsList);
//       setIsWarningModalOpen(true);
//     } else {
//       // 該当商品がない場合は直接ダウンロード
//       proceedToDownloadCSV();
//     }
//   };

//   // CSVダウンロードを実行する関数
//   const proceedToDownloadCSV = () => {
//     const csvContent = generateCSVContent(data);
//     downloadCSV(csvContent, "data.csv");
//   };

//   // CSVコンテンツを生成する関数
//   const generateCSVContent = (data) => {
//     if (!data || data.length === 0) return "";

//     const headers = Object.keys(data[0]);
//     const csvRows = [];

//     // ヘッダー行を追加
//     csvRows.push(headers.join(","));

//     // データ行を追加
//     data.forEach((row) => {
//       const values = headers.map((header) => {
//         const value =
//           row[header] !== undefined && row[header] !== null ? row[header] : "";
//         const escaped = ("" + value).replace(/"/g, '""');
//         return `"${escaped}"`;
//       });
//       csvRows.push(values.join(","));
//     });

//     return csvRows.join("\n");
//   };

//   // CSVをダウンロードする関数
//   const downloadCSV = (csvContent, filename) => {
//     const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
//     if (navigator.msSaveBlob) {
//       // IE 10+
//       navigator.msSaveBlob(blob, filename);
//     } else {
//       const link = document.createElement("a");
//       if (link.download !== undefined) {
//         // ブラウザがHTML5のダウンロード属性をサポートしている場合
//         const url = URL.createObjectURL(blob);
//         link.setAttribute("href", url);
//         link.setAttribute("download", filename);
//         link.style.visibility = "hidden";
//         document.body.appendChild(link);
//         link.click();
//         document.body.removeChild(link);
//       }
//     }
//   };

//   // 警告モーダルの表示制御用のステートを追加
//   const [isWarningModalOpen, setIsWarningModalOpen] = useState(false);
//   const [warningProducts, setWarningProducts] = useState([]);

//   // レンダリング部分
//   return (
//     <div className="editable-spreadsheet-container">
//       {/* カラム一括編集と削除ワード管理機能を含むBulkColumnEditコンポーネント */}
//       <BulkColumnEdit
//         data={data}
//         setData={setData}
//         columns={columns}
//         setColumns={setColumns}
//         columnOrder={columnOrder} // 追加
//         setIsDataModified={setIsDataModified}
//         userId={userId}
//         columnOrderTemplates={columnOrderTemplates}
//         setColumnOrderTemplates={setColumnOrderTemplates}
//         applyColumnTemplate={applyColumnTemplate}
//         selectedTemplateName={selectedTemplateName}
//         setSelectedTemplateName={setSelectedTemplateName}
//         setColumnOrder={setColumnOrder}
//         columnWidth={columnWidth}
//         setColumnWidth={setColumnWidth}
//       />

//       {/* エラーメッセージ表示 */}
//       {error && <div className="error-message">{error}</div>}

//       {/* 表示件数の選択、ページネーション、CSVダウンロードボタンを横並びに配置 */}
//       <div className="top-controls">
//         {/* 表示件数の選択 */}
//         <div className="pagination-controls">
//           <label htmlFor="itemsPerPage">表示件数:</label>
//           <select
//             id="itemsPerPage"
//             value={itemsPerPage}
//             onChange={handleItemsPerPageChange}
//           >
//             <option value={50}>50</option>
//             <option value={100}>100</option>
//             <option value={200}>200</option>
//             <option value="All">すべて</option>
//           </select>
//         </div>

//         {/* ページネーションコントロール */}
//         {totalPages > 1 && (
//           <div className="pagination-controls">
//             <button
//               onClick={() => handlePageChange(currentPage - 1)}
//               disabled={currentPage === 1}
//             >
//               前へ
//             </button>
//             <span>
//               {currentPage} / {totalPages}
//             </span>
//             <button
//               onClick={() => handlePageChange(currentPage + 1)}
//               disabled={currentPage === totalPages}
//             >
//               次へ
//             </button>
//           </div>
//         )}

//         {/* CSVダウンロードボタン */}
//         <div className="csv-download-button-container">
//           <button
//             onClick={handleDownloadCSV}
//             className="csv-download-button"
//           >
//             CSVをダウンロード
//           </button>
//         </div>
//       </div>

//       {/* ローディングインジケーター */}
//       {isLoading && <div className="loading-indicator">読み込み中...</div>}

//       {/* プレビューモーダル */}
//       <Modal
//         isOpen={isPreviewOpen}
//         onRequestClose={closePreviewModal}
//         contentLabel="商品プレビュー"
//         style={previewModalStyles}
//       >
//         {previewItem && (
//           <div className="preview-modal-content">
//             <button className="close-modal-button" onClick={closePreviewModal}>
//               ×
//             </button>
//             <div className="preview-header">
//               <h2>{previewItem["Title"] || previewItem["title"]}</h2>
//             </div>
//             <div className="preview-body">
//               <div className="preview-images">
//                 {/* 画像を表示 */}
//                 {(() => {
//                   const picUrl =
//                     previewItem["PicURL"] || previewItem["picurl"] || "";
//                   const imageUrls = picUrl.split("|").filter(Boolean);
//                   if (imageUrls.length > 0) {
//                     return (
//                       <div className="image-gallery">
//                         {/* メイン画像 */}
//                         <div className="main-image">
//                           <img
//                             src={imageUrls[0]}
//                             alt="Product Main"
//                             style={{ maxWidth: "100%", maxHeight: "400px" }}
//                           />
//                         </div>
//                         {/* その他の画像 */}
//                         {imageUrls.length > 1 && (
//                           <div className="additional-images">
//                             {imageUrls.slice(1).map((url, index) => (
//                               <img
//                                 key={index}
//                                 src={url}
//                                 alt={`Product ${index + 2}`}
//                                 style={{
//                                   maxWidth: "100px",
//                                   maxHeight: "100px",
//                                   margin: "5px",
//                                 }}
//                               />
//                             ))}
//                           </div>
//                         )}
//                       </div>
//                     );
//                   } else {
//                     return <p>画像がありません。</p>;
//                   }
//                 })()}
//               </div>
//               <div className="preview-details">
//                 <p>
//                   <strong>価格：</strong>{" "}
//                   {previewItem["StartPrice"] ||
//                     previewItem["startprice"] ||
//                     "N/A"}
//                 </p>
//                 <p>
//                   <strong>説明：</strong>
//                 </p>
//                 <div
//                   className="item-description"
//                   dangerouslySetInnerHTML={{
//                     __html:
//                       previewItem["Description"] ||
//                       previewItem["description"] ||
//                       "",
//                   }}
//                 />
//               </div>
//             </div>
//           </div>
//         )}
//       </Modal>

//       {/* セル編集用のモーダル */}
//       <Modal
//         isOpen={isCellModalOpen}
//         onRequestClose={() => setIsCellModalOpen(false)}
//         contentLabel="セルの編集"
//         style={cellModalStyles}
//       >
//         <div>
//           <h2 className="cell-modal-title">セルの編集</h2>
//           <textarea
//             value={cellModalValue}
//             onChange={(e) => setCellModalValue(e.target.value)}
//             style={{
//               width: "100%",
//               height: "200px",
//               fontSize: "16px",
//               lineHeight: "1.5",
//               fontFamily: "Arial, sans-serif",
//               color: "#333",
//               padding: "10px",
//               boxSizing: "border-box",
//             }}
//             disabled={isUpdatingCell} // 更新中は入力を無効化
//           />
//           <div style={{ marginTop: "20px", textAlign: "right" }}>
//             <button
//               onClick={() => {
//                 updateCell(cellModalRowIndex, cellModalColKey, cellModalValue);
//                 setIsCellModalOpen(false);
//               }}
//               className="cell-modal-button"
//               style={{ marginRight: "10px" }}
//               disabled={isUpdatingCell} // 更新中はボタンを無効化
//             >
//               保存
//             </button>
//             <button
//               onClick={() => setIsCellModalOpen(false)}
//               className="cell-modal-button"
//               disabled={isUpdatingCell} // 更新中はボタンを無効化
//             >
//               キャンセル
//             </button>
//             {isUpdatingCell && (
//               <div
//                 className="loader"
//                 style={{ display: "inline-block", marginLeft: "10px" }}
//               ></div>
//             )}
//           </div>
//         </div>
//       </Modal>

//       {/* 警告モーダル */}
//       <Modal
//         isOpen={isWarningModalOpen}
//         onRequestClose={() => setIsWarningModalOpen(false)}
//         contentLabel="警告"
//         style={warningModalStyles}
//       >
//         <div>
//           <h2>警告</h2>
//           <p>
//             StartPriceの値が初期インポートされた値と同一またはそれ以下になっている商品があります。CSVをダウンロードしますか？
//           </p>
//           <p>該当の商品:</p>
//           <ul>
//             {warningProducts.map((product, index) => (
//               <li key={index}>
//                 {product["Title"] || product["title"] || "タイトルなし"}（行{" "}
//                 {product.index}）
//               </li>
//             ))}
//           </ul>
//           <div style={{ textAlign: "right" }}>
//             <button
//               onClick={() => {
//                 setIsWarningModalOpen(false);
//                 proceedToDownloadCSV();
//               }}
//               style={{ marginRight: "10px" }}
//             >
//               ダウンロードを続行
//             </button>
//             <button onClick={() => setIsWarningModalOpen(false)}>
//               キャンセル
//             </button>
//           </div>
//         </div>
//       </Modal>

//       {/* スプレッドシートの表示 */}
//       {currentData.length === 0 ? (
//         <div className="no-data-message">
//           データがありません。CSVファイルをインポートしてください。
//         </div>
//       ) : (
//         <div className="spreadsheet-container">
//           <div className="table-wrapper">
//             <table className="editable-spreadsheet">
//               <thead>
//                 <tr>
//                   <th
//                     className="row-number-header"
//                     style={{
//                       width: "50px",
//                       position: "relative",
//                       overflow: "hidden",
//                       whiteSpace: "nowrap",
//                       textOverflow: "ellipsis",
//                     }}
//                   >
//                     #
//                   </th>
//                   <th
//                     style={{
//                       width: "100px",
//                       position: "relative",
//                       overflow: "hidden",
//                       whiteSpace: "nowrap",
//                       textOverflow: "ellipsis",
//                     }}
//                   >
//                     操作
//                   </th>
//                   {columnOrder.map((header, colIndex) => (
//                     <th
//                       key={colIndex}
//                       onClick={() => handleColumnClick(colIndex)}
//                       className={
//                         selectedColumn === colIndex ? "selected-column" : ""
//                       }
//                       style={{
//                         minWidth: "50px",
//                         width: `${columnWidth}px`,
//                         maxWidth: `${columnWidth}px`,
//                         position: "relative",
//                         overflow: "hidden",
//                         whiteSpace: "nowrap",
//                         textOverflow: "ellipsis",
//                       }}
//                       title={header}
//                     >
//                       {header}
//                     </th>
//                   ))}
//                 </tr>
//               </thead>
//               <tbody>
//                 {currentData.map((row, rowIndex) => {
//                   const globalRowIndex =
//                     (currentPage - 1) * itemsPerPageValue + rowIndex;
//                   return (
//                     <tr key={globalRowIndex}>
//                       <td className="row-number" style={{ width: "50px" }}>
//                         {globalRowIndex + 1}
//                       </td>
//                       <td style={{ width: "100px" }}>
//                         <div className="action-buttons">
//                           <button
//                             className="preview-button"
//                             onClick={() => handlePreview(row)}
//                           >
//                             プレビュー
//                           </button>
//                           <button
//                             className="delete-button"
//                             onClick={() => handleDeleteRow(globalRowIndex)}
//                           >
//                             削除
//                           </button>
//                         </div>
//                       </td>
//                       {columnOrder.map((colKey, colIndex) => (
//                         <td
//                           key={colIndex}
//                           className={
//                             selectedColumn === colIndex ? "selected-column" : ""
//                           }
//                           style={{
//                             minWidth: "50px",
//                             width: `${columnWidth}px`,
//                             maxWidth: `${columnWidth}px`,
//                             overflow: "hidden",
//                             whiteSpace: "nowrap",
//                             textOverflow: "ellipsis",
//                             backgroundColor: (() => {
//                               const cellValue = row[colKey];
//                               // セルの背景色を動的に設定
//                               if (
//                                 requiredColumns.includes(colKey) &&
//                                 isCellValueEmptyOrNA(cellValue)
//                               ) {
//                                 return "#ffcccc"; // 薄い赤色
//                               } else if (
//                                 recommendedColumns.includes(colKey) &&
//                                 isCellValueEmptyOrNA(cellValue)
//                               ) {
//                                 return "#ffffcc"; // 薄い黄色
//                               } else if (
//                                 initialData[globalRowIndex] &&
//                                 initialData[globalRowIndex][colKey] !==
//                                   undefined &&
//                                 initialData[globalRowIndex][colKey] !==
//                                   cellValue &&
//                                 !isCellValueEmptyOrNA(cellValue)
//                               ) {
//                                 return "#ccffcc"; // 薄い緑色
//                               }
//                               return "transparent";
//                             })(),
//                           }}
//                           title={row[colKey]}
//                         >
//                           {renderCell(row[colKey], globalRowIndex, colKey)}
//                         </td>
//                       ))}
//                     </tr>
//                   );
//                 })}
//               </tbody>
//             </table>
//           </div>
//         </div>
//       )}

//       {/* ページネーションコントロール（下部）は削除します */}
//     </div>
//   );
// }

// // デフォルトプロップスを設定
// EditableSpreadsheet.defaultProps = {
//   setIsDataModified: () => {},
//   userId: null,
// };

// export default EditableSpreadsheet;








// // EditableSpreadsheet.js

// import React, { useState, useEffect } from "react";
// import Modal from "react-modal";
// import "./EditableSpreadsheet.css";
// import BulkColumnEdit from "./BulkColumnEdit";
// import ColumnSettingsManager from "./ColumnSettingsManager";

// // モーダルのアクセシビリティ設定
// Modal.setAppElement("#root");

// function EditableSpreadsheet({
//   data,
//   setData,
//   columns,
//   setColumns,
//   setIsDataModified,
//   userId, // 追加
// }) {
//   // ステート変数の定義
//   const [error, setError] = useState(null);
//   const [selectedColumn, setSelectedColumn] = useState(null);
//   // セル編集用のステートを追加
//   const [isCellModalOpen, setIsCellModalOpen] = useState(false);
//   const [cellModalValue, setCellModalValue] = useState("");
//   const [cellModalRowIndex, setCellModalRowIndex] = useState(null);
//   const [cellModalColKey, setCellModalColKey] = useState(null);
//   const [isUpdatingCell, setIsUpdatingCell] = useState(false);

//   // 初期データを保持
//   const [initialData, setInitialData] = useState([]);

//   useEffect(() => {
//     if (initialData.length === 0 && data.length > 0) {
//       // データが初期インポートされたときのみ初期データを設定
//       setInitialData(JSON.parse(JSON.stringify(data))); // ディープコピー
//     }
//   }, [data]);

//   // 必須変更カラム（ユーザーが必ず変更する必要があるカラム）
//   const requiredColumns = [
//     "Title",
//     "StartPrice",
//     "Description",
//     "Category",
//     "PaymentProfileName",
//     "ReturnProfileName",
//     "ShippingProfileName",
//     "MinimumBestOfferPrice",
//     "BestOfferAutoAcceptPrice",
//   ];

//   // 推奨変更カラム（'C:' で始まるカラム）
//   const recommendedColumns = columns.filter((col) => col.startsWith("C:"));

//   // セルの値が空または 'NA' かを判定する関数
//   const isCellValueEmptyOrNA = (value) => {
//     return (
//       value === undefined || value === null || value === "" || value === "NA"
//     );
//   };

//   // プレビュー用のステート
//   const [previewItem, setPreviewItem] = useState(null); // プレビューするアイテム
//   const [isPreviewOpen, setIsPreviewOpen] = useState(false); // プレビューモーダルの開閉

//   // カラム幅を一律に管理するステートを追加
//   const [columnWidth, setColumnWidth] = useState(150); // デフォルトの幅を150pxに設定

//   // カラム順序を管理するステートを追加
//   const [columnOrder, setColumnOrder] = useState(columns);

//   // カラム順序テンプレートを管理する状態
//   const [columnOrderTemplates, setColumnOrderTemplates] = useState({});

//   // 選択されたテンプレート名
//   const [selectedTemplateName, setSelectedTemplateName] = useState("");

//   // ローカルストレージからテンプレートを読み込む
//   useEffect(() => {
//     const savedTemplates = localStorage.getItem("columnOrderTemplates");
//     if (savedTemplates) {
//       setColumnOrderTemplates(JSON.parse(savedTemplates));
//     }
//   }, []);

//   // テンプレートをローカルストレージに保存
//   useEffect(() => {
//     localStorage.setItem(
//       "columnOrderTemplates",
//       JSON.stringify(columnOrderTemplates)
//     );
//   }, [columnOrderTemplates]);

//   // dataが更新されたときにcolumnsを更新
//   useEffect(() => {
//     if (data.length > 0) {
//       const dataColumns = Object.keys(data[0]);
//       setColumns(dataColumns);
//     }
//   }, [data, setColumns]);

//   // columnsまたはcolumnOrderが更新された場合、columnOrderを更新
//   useEffect(() => {
//     const newColumnOrder = columnOrder.filter((col) => columns.includes(col));
//     const addedColumns = columns.filter((col) => !newColumnOrder.includes(col));
//     setColumnOrder([...newColumnOrder, ...addedColumns]);
//   }, [columns]);

//   // カラム順序テンプレートを適用する関数
//   const applyColumnTemplate = (templateName) => {
//     if (!templateName) {
//       // テンプレート名が空の場合、デフォルトのカラム順序を適用
//       setColumnOrder(columns);
//       return;
//     }
//     const templateOrder = columnOrderTemplates[templateName];
//     if (templateOrder) {
//       const newColumnOrder = templateOrder.filter((col) =>
//         columns.includes(col)
//       );
//       const remainingColumns = columns.filter(
//         (col) => !newColumnOrder.includes(col)
//       );
//       // 残りのカラムを末尾に追加
//       setColumnOrder([...newColumnOrder, ...remainingColumns]);
//     }
//   };

//   // セルの更新
//   const updateCell = (rowIndex, colKey, newValue) => {
//     try {
//       setIsUpdatingCell(true); // 更新開始
//       const newData = [...data];
//       newData[rowIndex] = { ...newData[rowIndex], [colKey]: newValue };
//       setData(newData);
//       setIsDataModified(true);
//       console.log("Data modified in updateCell");
//       setIsUpdatingCell(false); // 更新終了
//     } catch (err) {
//       console.error("Error updating cell:", err);
//       setError("セルの更新に失敗しました");
//       setIsUpdatingCell(false);
//     }
//   };

//   // カラムのクリックハンドラー
//   const handleColumnClick = (colIndex) => {
//     setSelectedColumn(colIndex);
//   };

//   // セルのレンダリング
//   const renderCell = (cell, rowIndex, colKey) => {
//     const header = colKey.toLowerCase();

//     // セルのスタイルを動的に設定
//     let cellStyle = {
//       cursor: "pointer",
//       width: "100%",
//       boxSizing: "border-box",
//     };

//     // セルの値を取得
//     const cellValue = cell;

//     // 必須変更カラムの場合、値が 'NA' または空の場合に背景色を赤色に設定
//     if (requiredColumns.includes(colKey) && isCellValueEmptyOrNA(cellValue)) {
//       cellStyle.backgroundColor = "#ffcccc"; // 薄い赤色
//     }
//     // 推奨変更カラムの場合、値が 'NA' または空の場合に背景色を黄色に設定
//     else if (
//       recommendedColumns.includes(colKey) &&
//       isCellValueEmptyOrNA(cellValue)
//     ) {
//       cellStyle.backgroundColor = "#ffffcc"; // 薄い黄色
//     }
//     // セルが変更されていて、値が 'NA' でない場合、背景色を緑色に設定
//     if (
//       initialData[rowIndex] &&
//       initialData[rowIndex][colKey] !== undefined &&
//       initialData[rowIndex][colKey] !== cellValue &&
//       !isCellValueEmptyOrNA(cellValue)
//     ) {
//       cellStyle.backgroundColor = "#ccffcc"; // 薄い緑色
//     }

//     // PicURL や 画像 カラムの処理
//     if (header === "picurl" || header === "画像") {
//       const imageUrls = cell?.split("|") || [];
//       return (
//         <img
//           src={imageUrls[0]}
//           alt="Product"
//           style={{ maxWidth: "100px", maxHeight: "100px" }}
//         />
//       );
//     } else {
//       return (
//         <input
//           value={cell || ""}
//           onClick={() => {
//             setCellModalValue(cell || "");
//             setCellModalRowIndex(rowIndex);
//             setCellModalColKey(colKey);
//             setIsCellModalOpen(true);
//           }}
//           readOnly // 直接編集を防ぐ場合はreadOnlyを設定
//           style={cellStyle}
//         />
//       );
//     }
//   };

//   // 単一行を削除
//   const handleDeleteRow = (rowIndex) => {
//     if (rowIndex < 0) {
//       setError("ヘッダー行は削除できません。");
//       return;
//     }

//     try {
//       const newData = data.filter((_, index) => index !== rowIndex);
//       const newInitialData = initialData.filter(
//         (_, index) => index !== rowIndex
//       ); // initialDataも更新
//       setData(newData);
//       setInitialData(newInitialData); // initialDataを更新
//       setIsDataModified(true); // データが変更されたので状態を更新
//       setError(null);
//     } catch (err) {
//       console.error("Error deleting row:", err);
//       setError("行の削除に失敗しました");
//     }
//   };

//   // プレビューを表示する関数
//   const handlePreview = (row) => {
//     setPreviewItem(row);
//     setIsPreviewOpen(true);
//   };

//   // プレビューモーダルを閉じる関数
//   const closePreviewModal = () => {
//     setIsPreviewOpen(false);
//     setPreviewItem(null);
//   };

//   // モーダルのスタイル設定
//   const cellModalStyles = {
//     content: {
//       top: "50%",
//       left: "50%",
//       right: "auto",
//       bottom: "auto",
//       transform: "translate(-50%, -50%)",
//       width: "600px",
//       maxHeight: "80%",
//       overflow: "auto",
//       padding: "20px",
//       boxSizing: "border-box",
//     },
//     overlay: {
//       backgroundColor: "rgba(0, 0, 0, 0.5)",
//     },
//   };

//   const previewModalStyles = {
//     content: {
//       top: "50%",
//       left: "50%",
//       right: "auto",
//       bottom: "auto",
//       marginRight: "-50%",
//       transform: "translate(-50%, -50%)",
//       width: "80%",
//       maxWidth: "800px",
//       maxHeight: "90%",
//       overflow: "auto",
//     },
//     overlay: {
//       backgroundColor: "rgba(0, 0, 0, 0.5)",
//     },
//   };

//   // 警告モーダルのスタイル設定
//   const warningModalStyles = {
//     content: {
//       top: "50%",
//       left: "50%",
//       right: "auto",
//       bottom: "auto",
//       transform: "translate(-50%, -50%)",
//       width: "600px",
//       maxHeight: "80%",
//       overflow: "auto",
//       padding: "20px",
//       boxSizing: "border-box",
//     },
//     overlay: {
//       backgroundColor: "rgba(0, 0, 0, 0.5)",
//     },
//   };

//   // ページネーションのステート
//   const [itemsPerPage, setItemsPerPage] = useState(50); // 表示件数
//   const [currentPage, setCurrentPage] = useState(1); // 現在のページ
//   const [isLoading, setIsLoading] = useState(false); // ローディング状態

//   useEffect(() => {
//     // データが変更されたときにcurrentPageを1にリセット
//     setCurrentPage(1);
//   }, [data]);

//   const itemsPerPageValue = itemsPerPage === "All" ? data.length : itemsPerPage;
//   const totalPages =
//     itemsPerPage === "All" ? 1 : Math.ceil(data.length / itemsPerPage);

//   // ページまたは表示件数が変更されたときの処理
//   useEffect(() => {
//     setIsLoading(true);
//     const timer = setTimeout(() => {
//       setIsLoading(false);
//     }, 500); // ローディングインジケーターを表示する時間
//     return () => clearTimeout(timer);
//   }, [itemsPerPage, currentPage, data]);

//   // 表示件数の変更ハンドラー
//   const handleItemsPerPageChange = (e) => {
//     const value = e.target.value;
//     if (value === "All") {
//       setItemsPerPage("All");
//     } else {
//       setItemsPerPage(parseInt(value));
//     }
//     setCurrentPage(1); // ページをリセット
//   };

//   // ページ変更ハンドラー
//   const handlePageChange = (newPage) => {
//     if (newPage < 1 || newPage > totalPages) return;
//     setCurrentPage(newPage);
//   };

//   // 現在のページに表示するデータ
//   let currentData = [];
//   if (itemsPerPage === "All") {
//     currentData = data;
//   } else {
//     const startIndex = (currentPage - 1) * itemsPerPageValue;
//     const endIndex = startIndex + itemsPerPageValue;
//     currentData = data.slice(startIndex, endIndex);
//   }

//   // StartPriceの値をチェックし、該当商品のリストを返す関数
//   const getProductsWithLowerStartPrice = () => {
//     // "StartPrice"キーを大文字小文字を区別せずに検索
//     const startPriceKey = columns.find(
//       (key) => key.toLowerCase() === "startprice"
//     );

//     if (!startPriceKey) {
//       // StartPrice カラムがない場合は空のリストを返す
//       return [];
//     }

//     const warningProducts = [];

//     for (let i = 0; i < data.length; i++) {
//       const currentPriceStr = data[i][startPriceKey];
//       const initialPriceStr = initialData[i][startPriceKey];

//       const currentPrice = parseFloat(currentPriceStr);
//       const initialPrice = parseFloat(initialPriceStr);

//       if (!isNaN(currentPrice) && !isNaN(initialPrice)) {
//         if (currentPrice <= initialPrice) {
//           // 該当商品の情報を追加（行番号も含める）
//           warningProducts.push({
//             ...data[i],
//             index: i + 1, // 行番号（1から始まる）
//           });
//         }
//       }
//     }
//     return warningProducts;
//   };

//   // CSVダウンロード処理
//   const handleDownloadCSV = () => {
//     const warningProductsList = getProductsWithLowerStartPrice();
//     if (warningProductsList.length > 0) {
//       // 該当商品がある場合は警告モーダルを表示
//       setWarningProducts(warningProductsList);
//       setIsWarningModalOpen(true);
//     } else {
//       // 該当商品がない場合は直接ダウンロード
//       proceedToDownloadCSV();
//     }
//   };

//   // CSVダウンロードを実行する関数
//   const proceedToDownloadCSV = () => {
//     const csvContent = generateCSVContent(data);
//     downloadCSV(csvContent, "data.csv");
//   };

//   // CSVコンテンツを生成する関数
//   const generateCSVContent = (data) => {
//     if (!data || data.length === 0) return "";

//     const headers = Object.keys(data[0]);
//     const csvRows = [];

//     // ヘッダー行を追加
//     csvRows.push(headers.join(","));

//     // データ行を追加
//     data.forEach((row) => {
//       const values = headers.map((header) => {
//         const value =
//           row[header] !== undefined && row[header] !== null ? row[header] : "";
//         const escaped = ("" + value).replace(/"/g, '""');
//         return `"${escaped}"`;
//       });
//       csvRows.push(values.join(","));
//     });

//     return csvRows.join("\n");
//   };

//   // CSVをダウンロードする関数
//   const downloadCSV = (csvContent, filename) => {
//     const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
//     if (navigator.msSaveBlob) {
//       // IE 10+
//       navigator.msSaveBlob(blob, filename);
//     } else {
//       const link = document.createElement("a");
//       if (link.download !== undefined) {
//         // ブラウザがHTML5のダウンロード属性をサポートしている場合
//         const url = URL.createObjectURL(blob);
//         link.setAttribute("href", url);
//         link.setAttribute("download", filename);
//         link.style.visibility = "hidden";
//         document.body.appendChild(link);
//         link.click();
//         document.body.removeChild(link);
//       }
//     }
//   };

//   // 警告モーダルの表示制御用のステートを追加
//   const [isWarningModalOpen, setIsWarningModalOpen] = useState(false);
//   const [warningProducts, setWarningProducts] = useState([]);

//   // レンダリング部分
//   return (
//     <div className="editable-spreadsheet-container">
//       {/* カラム一括編集と削除ワード管理機能を含むBulkColumnEditコンポーネント */}
//       <BulkColumnEdit
//         data={data}
//         setData={setData}
//         columns={columns}
//         setColumns={setColumns}
//         setIsDataModified={setIsDataModified}
//         userId={userId} // 追加
//       />

//       {/* カラム設定マネージャー */}
//       <ColumnSettingsManager
//         columns={columns}
//         columnOrderTemplates={columnOrderTemplates}
//         setColumnOrderTemplates={setColumnOrderTemplates}
//         applyColumnTemplate={applyColumnTemplate}
//         selectedTemplateName={selectedTemplateName}
//         setSelectedTemplateName={setSelectedTemplateName}
//         setColumnOrder={setColumnOrder}
//         columnWidth={columnWidth}
//         setColumnWidth={setColumnWidth}
//       />

//       {/* CSVダウンロードボタン */}
//       <div className="csv-download-button-container">
//         <button onClick={handleDownloadCSV}>CSVをダウンロード</button>
//       </div>

//       {/* エラーメッセージ表示 */}
//       {error && <div className="error-message">{error}</div>}

//       {/* 表示件数の選択 */}
//       <div className="pagination-controls">
//         <label htmlFor="itemsPerPage">表示件数:</label>
//         <select
//           id="itemsPerPage"
//           value={itemsPerPage}
//           onChange={handleItemsPerPageChange}
//         >
//           <option value={50}>50</option>
//           <option value={100}>100</option>
//           <option value={200}>200</option>
//           <option value="All">すべて</option>
//         </select>
//       </div>

//       {/* ページネーションコントロール */}
//       {totalPages > 1 && (
//         <div className="pagination-controls">
//           <button
//             onClick={() => handlePageChange(currentPage - 1)}
//             disabled={currentPage === 1}
//           >
//             前へ
//           </button>
//           <span>
//             {currentPage} / {totalPages}
//           </span>
//           <button
//             onClick={() => handlePageChange(currentPage + 1)}
//             disabled={currentPage === totalPages}
//           >
//             次へ
//           </button>
//         </div>
//       )}

//       {/* ローディングインジケーター */}
//       {isLoading && <div className="loading-indicator">読み込み中...</div>}

//       {/* プレビューモーダル */}
//       <Modal
//         isOpen={isPreviewOpen}
//         onRequestClose={closePreviewModal}
//         contentLabel="商品プレビュー"
//         style={previewModalStyles}
//       >
//         {previewItem && (
//           <div className="preview-modal-content">
//             <button className="close-modal-button" onClick={closePreviewModal}>
//               ×
//             </button>
//             <div className="preview-header">
//               <h2>{previewItem["Title"] || previewItem["title"]}</h2>
//             </div>
//             <div className="preview-body">
//               <div className="preview-images">
//                 {/* 画像を表示 */}
//                 {(() => {
//                   const picUrl =
//                     previewItem["PicURL"] || previewItem["picurl"] || "";
//                   const imageUrls = picUrl.split("|").filter(Boolean);
//                   if (imageUrls.length > 0) {
//                     return (
//                       <div className="image-gallery">
//                         {/* メイン画像 */}
//                         <div className="main-image">
//                           <img
//                             src={imageUrls[0]}
//                             alt="Product Main"
//                             style={{ maxWidth: "100%", maxHeight: "400px" }}
//                           />
//                         </div>
//                         {/* その他の画像 */}
//                         {imageUrls.length > 1 && (
//                           <div className="additional-images">
//                             {imageUrls.slice(1).map((url, index) => (
//                               <img
//                                 key={index}
//                                 src={url}
//                                 alt={`Product ${index + 2}`}
//                                 style={{
//                                   maxWidth: "100px",
//                                   maxHeight: "100px",
//                                   margin: "5px",
//                                 }}
//                               />
//                             ))}
//                           </div>
//                         )}
//                       </div>
//                     );
//                   } else {
//                     return <p>画像がありません。</p>;
//                   }
//                 })()}
//               </div>
//               <div className="preview-details">
//                 <p>
//                   <strong>価格：</strong>{" "}
//                   {previewItem["StartPrice"] ||
//                     previewItem["startprice"] ||
//                     "N/A"}
//                 </p>
//                 <p>
//                   <strong>説明：</strong>
//                 </p>
//                 <div
//                   className="item-description"
//                   dangerouslySetInnerHTML={{
//                     __html:
//                       previewItem["Description"] ||
//                       previewItem["description"] ||
//                       "",
//                   }}
//                 />
//               </div>
//             </div>
//           </div>
//         )}
//       </Modal>

//       {/* セル編集用のモーダル */}
//       <Modal
//         isOpen={isCellModalOpen}
//         onRequestClose={() => setIsCellModalOpen(false)}
//         contentLabel="セルの編集"
//         style={cellModalStyles}
//       >
//         <div>
//           <h2 className="cell-modal-title">セルの編集</h2>
//           <textarea
//             value={cellModalValue}
//             onChange={(e) => setCellModalValue(e.target.value)}
//             style={{
//               width: "100%",
//               height: "200px",
//               fontSize: "16px",
//               lineHeight: "1.5",
//               fontFamily: "Arial, sans-serif",
//               color: "#333",
//               padding: "10px",
//               boxSizing: "border-box",
//             }}
//             disabled={isUpdatingCell} // 更新中は入力を無効化
//           />
//           <div style={{ marginTop: "20px", textAlign: "right" }}>
//             <button
//               onClick={() => {
//                 updateCell(cellModalRowIndex, cellModalColKey, cellModalValue);
//                 setIsCellModalOpen(false);
//               }}
//               className="cell-modal-button"
//               style={{ marginRight: "10px" }}
//               disabled={isUpdatingCell} // 更新中はボタンを無効化
//             >
//               保存
//             </button>
//             <button
//               onClick={() => setIsCellModalOpen(false)}
//               className="cell-modal-button"
//               disabled={isUpdatingCell} // 更新中はボタンを無効化
//             >
//               キャンセル
//             </button>
//             {isUpdatingCell && (
//               <div
//                 className="loader"
//                 style={{ display: "inline-block", marginLeft: "10px" }}
//               ></div>
//             )}
//           </div>
//         </div>
//       </Modal>

//       {/* 警告モーダル */}
//       <Modal
//         isOpen={isWarningModalOpen}
//         onRequestClose={() => setIsWarningModalOpen(false)}
//         contentLabel="警告"
//         style={warningModalStyles}
//       >
//         <div>
//           <h2>警告</h2>
//           <p>
//             StartPriceの値が初期インポートされた値と同一またはそれ以下になっている商品があります。CSVをダウンロードしますか？
//           </p>
//           <p>該当の商品:</p>
//           <ul>
//             {warningProducts.map((product, index) => (
//               <li key={index}>
//                 {product["Title"] || product["title"] || "タイトルなし"}（行{" "}
//                 {product.index}）
//               </li>
//             ))}
//           </ul>
//           <div style={{ textAlign: "right" }}>
//             <button
//               onClick={() => {
//                 setIsWarningModalOpen(false);
//                 proceedToDownloadCSV();
//               }}
//               style={{ marginRight: "10px" }}
//             >
//               ダウンロードを続行
//             </button>
//             <button onClick={() => setIsWarningModalOpen(false)}>
//               キャンセル
//             </button>
//           </div>
//         </div>
//       </Modal>

//       {/* スプレッドシートの表示 */}
//       {currentData.length === 0 ? (
//         <div className="no-data-message">
//           データがありません。CSVファイルをインポートしてください。
//         </div>
//       ) : (
//         <div className="spreadsheet-container">
//           <div className="table-wrapper">
//             <table className="editable-spreadsheet">
//               <thead>
//                 <tr>
//                   <th
//                     className="row-number-header"
//                     style={{
//                       width: "50px",
//                       position: "relative",
//                       overflow: "hidden",
//                       whiteSpace: "nowrap",
//                       textOverflow: "ellipsis",
//                     }}
//                   >
//                     #
//                   </th>
//                   <th
//                     style={{
//                       width: "100px",
//                       position: "relative",
//                       overflow: "hidden",
//                       whiteSpace: "nowrap",
//                       textOverflow: "ellipsis",
//                     }}
//                   >
//                     操作
//                   </th>
//                   {columnOrder.map((header, colIndex) => (
//                     <th
//                       key={colIndex}
//                       onClick={() => handleColumnClick(colIndex)}
//                       className={
//                         selectedColumn === colIndex ? "selected-column" : ""
//                       }
//                       style={{
//                         minWidth: "50px",
//                         width: `${columnWidth}px`,
//                         maxWidth: `${columnWidth}px`,
//                         position: "relative",
//                         overflow: "hidden",
//                         whiteSpace: "nowrap",
//                         textOverflow: "ellipsis",
//                       }}
//                       title={header}
//                     >
//                       {header}
//                     </th>
//                   ))}
//                 </tr>
//               </thead>
//               <tbody>
//                 {currentData.map((row, rowIndex) => {
//                   const globalRowIndex =
//                     (currentPage - 1) * itemsPerPageValue + rowIndex;
//                   return (
//                     <tr key={globalRowIndex}>
//                       <td className="row-number" style={{ width: "50px" }}>
//                         {globalRowIndex + 1}
//                       </td>
//                       <td style={{ width: "100px" }}>
//                         <div className="action-buttons">
//                           <button
//                             className="preview-button"
//                             onClick={() => handlePreview(row)}
//                           >
//                             プレビュー
//                           </button>
//                           <button
//                             className="delete-button"
//                             onClick={() => handleDeleteRow(globalRowIndex)}
//                           >
//                             削除
//                           </button>
//                         </div>
//                       </td>
//                       {columnOrder.map((colKey, colIndex) => (
//                         <td
//                           key={colIndex}
//                           className={
//                             selectedColumn === colIndex ? "selected-column" : ""
//                           }
//                           style={{
//                             minWidth: "50px",
//                             width: `${columnWidth}px`,
//                             maxWidth: `${columnWidth}px`,
//                             overflow: "hidden",
//                             whiteSpace: "nowrap",
//                             textOverflow: "ellipsis",
//                             backgroundColor: (() => {
//                               const cellValue = row[colKey];
//                               // セルの背景色を動的に設定
//                               if (
//                                 requiredColumns.includes(colKey) &&
//                                 isCellValueEmptyOrNA(cellValue)
//                               ) {
//                                 return "#ffcccc"; // 薄い赤色
//                               } else if (
//                                 recommendedColumns.includes(colKey) &&
//                                 isCellValueEmptyOrNA(cellValue)
//                               ) {
//                                 return "#ffffcc"; // 薄い黄色
//                               } else if (
//                                 initialData[globalRowIndex] &&
//                                 initialData[globalRowIndex][colKey] !==
//                                   undefined &&
//                                 initialData[globalRowIndex][colKey] !==
//                                   cellValue &&
//                                 !isCellValueEmptyOrNA(cellValue)
//                               ) {
//                                 return "#ccffcc"; // 薄い緑色
//                               }
//                               return "transparent";
//                             })(),
//                           }}
//                           title={row[colKey]}
//                         >
//                           {renderCell(row[colKey], globalRowIndex, colKey)}
//                         </td>
//                       ))}
//                     </tr>
//                   );
//                 })}
//               </tbody>
//             </table>
//           </div>
//         </div>
//       )}

//       {/* ページネーションコントロール（下部） */}
//       {totalPages > 1 && (
//         <div className="pagination-controls">
//           <button
//             onClick={() => handlePageChange(currentPage - 1)}
//             disabled={currentPage === 1}
//           >
//             前へ
//           </button>
//           <span>
//             {currentPage} / {totalPages}
//           </span>
//           <button
//             onClick={() => handlePageChange(currentPage + 1)}
//             disabled={currentPage === totalPages}
//           >
//             次へ
//           </button>
//         </div>
//       )}
//     </div>
//   );
// }

// // デフォルトプロップスを設定
// EditableSpreadsheet.defaultProps = {
//   setIsDataModified: () => {},
//   userId: null, // 追加
// };

// export default EditableSpreadsheet;