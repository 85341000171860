// PriceCalculationSettings.js

import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Typography,
  Button,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Alert,
  Snackbar,
  Grid,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontWeight: 'bold',
  backgroundColor: theme.palette.primary.light,
  color: theme.palette.common.white,
}));

const PriceCalculationSettings = ({
  customSettings = {},
  data,
  setData,
  setIsDataModified,
}) => {
  const [calculatedPrices, setCalculatedPrices] = useState([]);
  const [isCalculating, setIsCalculating] = useState(false);
  const [error, setError] = useState(null);
  const [showPriceSimulation, setShowPriceSimulation] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'info',
  });

  const [exchangeRate, setExchangeRate] = useState(140);
  const [bestOfferAutoAcceptPercentage, setBestOfferAutoAcceptPercentage] =
    useState(95);
  const [minimumBestOfferPercentage, setMinimumBestOfferPercentage] =
    useState(80);

  // 元のデータを保存する状態変数
  const [originalData, setOriginalData] = useState(null);

  // 価格が適用されたかどうかを追跡する状態変数
  const [isPriceApplied, setIsPriceApplied] = useState(false);

  // 処理済みアイテム数を追跡する状態変数
  const [processedCount, setProcessedCount] = useState(0);
  const [totalItems, setTotalItems] = useState(0);

  // 価格適用時のメッセージとその種類を保存する状態変数
  const [applyMessage, setApplyMessage] = useState('');
  const [applyMessageSeverity, setApplyMessageSeverity] = useState('success');

  // 価格適用に失敗したアイテムのインデックスを保存
  const [failedToApplyIndices, setFailedToApplyIndices] = useState([]);

  useEffect(() => {
    if (customSettings) {
      setBestOfferAutoAcceptPercentage(
        customSettings.bestOfferAutoAcceptPercentage || 95
      );
      setMinimumBestOfferPercentage(
        customSettings.minimumBestOfferPercentage || 80
      );
      setExchangeRate(customSettings.exchangeRate || 140);
    }
  }, [customSettings]);

  const defaultSettings = {
    fees: {
      ebayFinalValue: 10,
      payoneer: 2,
      promotedListing: 3,
      ebayTransactionFee: 0.3,
    },
    targetProfitMargin: 25,
    discountRate: 0,
    shippingRateTemplates: [],
    selectedShippingTemplateId: 'default',
    categories: {
      additionalCosts: [],
      expenses: [],
      fees: [],
      shipping: [],
    },
  };

  const settings = {
    ...defaultSettings,
    ...customSettings,
  };

  // 選択された送料テンプレートの情報を取得
  const selectedShippingTemplateId =
    settings.selectedShippingTemplateId || 'default';
  const shippingRateTemplates = settings.shippingRateTemplates || [];

  const selectedShippingTemplate =
    shippingRateTemplates.find(
      (template) => template.id === selectedShippingTemplateId
    ) || shippingRateTemplates[0];

  const selectedShippingTemplateName = selectedShippingTemplate
    ? selectedShippingTemplate.name
    : 'デフォルト送料設定';

  const adjustPriceTo99 = (price) => {
    return Math.floor(price) + 0.99;
  };

  const calculatePrices = useCallback(() => {
    setIsCalculating(true);
    setError(null);
    setProcessedCount(0);
    setTotalItems(data.length);
    setApplyMessage(''); // 価格適用メッセージをリセット

    try {
      if (!settings || !data || data.length === 0) {
        throw new Error('必要なデータが不足しています。');
      }

      if (!Array.isArray(data)) {
        throw new Error('データ形式が正しくありません。');
      }

      const columns = Object.keys(data[0]);
      if (!columns.includes('StartPrice')) {
        throw new Error('StartPrice列が見つかりません。');
      }

      const selectedShippingTemplateId =
        settings.selectedShippingTemplateId || 'default';
      const shippingRateTemplates = settings.shippingRateTemplates || [];

      const selectedShippingTemplate =
        shippingRateTemplates.find(
          (template) => template.id === selectedShippingTemplateId
        ) || shippingRateTemplates[0];

      if (!selectedShippingTemplate) {
        throw new Error('選択された送料テンプレートが見つかりません。');
      }

      const newPrices = [];
      let errorCount = 0;

      data.forEach((item, index) => {
        try {
          const startPrice = parseFloat(item.StartPrice);
          if (isNaN(startPrice)) {
            throw new Error('StartPriceが数値ではありません');
          }

          let totalCost = startPrice;
          let totalPercentage = 0;

          // カテゴリーの項目を適用
          if (settings.categories && typeof settings.categories === 'object') {
            Object.values(settings.categories)
              .flat()
              .forEach((categoryItem) => {
                if (categoryItem.type === 'fixed') {
                  totalCost += parseFloat(categoryItem.value) || 0;
                } else if (categoryItem.type === 'percentage') {
                  totalPercentage += parseFloat(categoryItem.value) || 0;
                }
              });
          }

          // 手数料を追加
          totalPercentage +=
            parseFloat(settings.fees.ebayFinalValue || 0) +
            parseFloat(settings.fees.payoneer || 0) +
            parseFloat(settings.fees.promotedListing || 0);
          totalCost += parseFloat(settings.fees.ebayTransactionFee || 0);

          // 送料を追加
          const shippingRate = selectedShippingTemplate?.shippingRates?.find(
            (rate) =>
              startPrice >= parseFloat(rate.minPriceUSD) &&
              (parseFloat(rate.maxPriceUSD) === Infinity ||
                startPrice < parseFloat(rate.maxPriceUSD))
          );
          if (shippingRate) {
            totalCost += parseFloat(shippingRate.costUSD) || 0;
          }

          // 目標利益率を追加
          const targetProfitMargin =
            parseFloat(settings.targetProfitMargin) || 0;
          totalPercentage += targetProfitMargin;

          // 販売価格を計算
          let calculatedPrice = totalCost / (1 - totalPercentage / 100);
          calculatedPrice = adjustPriceTo99(calculatedPrice);

          // 割引後価格を計算
          const discountRate = parseFloat(settings.discountRate) || 0;
          const discountedPrice = calculatedPrice * (1 - discountRate / 100);
          const finalPrice = adjustPriceTo99(discountedPrice);

          // ベストオファー価格を計算
          const bestOfferAutoAcceptPrice =
            finalPrice * (bestOfferAutoAcceptPercentage / 100);
          const minimumBestOfferPrice =
            finalPrice * (minimumBestOfferPercentage / 100);

          // 利益と利益率を計算
          const profit = finalPrice - totalCost;
          const profitMargin = (profit / finalPrice) * 100;

          newPrices.push({
            originalStartPrice: startPrice.toFixed(2),
            calculatedPrice: calculatedPrice.toFixed(2),
            discountedPrice: finalPrice.toFixed(2),
            bestOfferAutoAcceptPrice: bestOfferAutoAcceptPrice.toFixed(2),
            minimumBestOfferPrice: minimumBestOfferPrice.toFixed(2),
            profit: profit.toFixed(2),
            profitMargin: profitMargin.toFixed(2),
          });

          // 処理済みアイテム数を更新
          setProcessedCount((prevCount) => prevCount + 1);
        } catch (error) {
          console.error('アイテムの処理中にエラーが発生しました:', item, error);
          errorCount++;
          newPrices.push(null); // エラーが発生したアイテムはnullを設定
        }
      });

      setCalculatedPrices(newPrices);

      // エラーがあった場合にユーザーに通知
      if (errorCount > 0) {
        setSnackbar({
          open: true,
          message: `${errorCount} 件のアイテムでエラーが発生しました。詳細はコンソールログを確認してください。`,
          severity: 'warning',
        });
      } else {
        // 処理完了時のメッセージを表示
        setSnackbar({
          open: true,
          message: `価格計算が完了しました。(${processedCount}/${totalItems} 件)`,
          severity: 'success',
        });
      }
    } catch (err) {
      setError(err.message);
      setSnackbar({
        open: true,
        message: `価格計算中にエラーが発生しました: ${err.message}`,
        severity: 'error',
      });
    } finally {
      setIsCalculating(false);
    }
  }, [
    settings,
    data,
    bestOfferAutoAcceptPercentage,
    minimumBestOfferPercentage,
    processedCount,
    totalItems,
  ]);

  const handleApplyPrices = () => {
    try {
      // 価格を適用する前に元のデータを保存
      if (!originalData) {
        setOriginalData([...data]);
      }

      let appliedCount = 0; // 適用されたアイテム数をカウント
      const newFailedIndices = []; // 失敗したアイテムのインデックスを保存

      const updatedData = data.map((item, index) => {
        const calculatedPrice = calculatedPrices[index];
        if (!calculatedPrice) {
          newFailedIndices.push(index); // 価格計算に失敗したアイテムのインデックスを保存
          return item;
        }

        try {
          // 価格を適用
          appliedCount++; // 適用されたアイテム数を増やす
          return {
            ...item,
            StartPrice: calculatedPrice.discountedPrice,
            BestOfferAutoAcceptPrice: calculatedPrice.bestOfferAutoAcceptPrice,
            MinimumBestOfferPrice: calculatedPrice.minimumBestOfferPrice,
          };
        } catch (error) {
          // 適用中にエラーが発生した場合
          console.error(`アイテムの価格適用中にエラーが発生しました（インデックス: ${index}）:`, error);
          newFailedIndices.push(index);
          return item;
        }
      });

      setData(updatedData);
      setFailedToApplyIndices(newFailedIndices); // 失敗したアイテムのインデックスを更新
      setIsPriceApplied(true); // 価格が適用されたことを記録

      // 適用メッセージとアラートの種類を設定
      if (appliedCount === data.length) {
        setApplyMessage(
          `全てのアイテムの価格が適用されました。(${appliedCount}/${data.length} 件)`
        );
        setApplyMessageSeverity('success');
      } else {
        setApplyMessage(
          `一部のアイテムの価格が適用されました。(${appliedCount}/${data.length} 件)`
        );
        setApplyMessageSeverity('warning');
      }

      if (setIsDataModified) {
        setIsDataModified(true);
      }
    } catch (err) {
      setError('価格の更新に失敗しました: ' + err.message);
    }
  };

  const handleReapplyFailedPrices = () => {
    try {
      if (failedToApplyIndices.length === 0) {
        setSnackbar({
          open: true,
          message: '再適用するアイテムがありません。',
          severity: 'info',
        });
        return;
      }

      let reAppliedCount = 0;
      const newFailedIndices = [];

      const updatedData = data.map((item, index) => {
        if (!failedToApplyIndices.includes(index)) {
          return item; // 失敗したアイテム以外はそのまま
        }

        const calculatedPrice = calculatedPrices[index];
        if (!calculatedPrice) {
          newFailedIndices.push(index); // 価格計算に失敗したアイテム
          return item;
        }

        try {
          // 価格を適用
          reAppliedCount++;
          return {
            ...item,
            StartPrice: calculatedPrice.discountedPrice,
            BestOfferAutoAcceptPrice: calculatedPrice.bestOfferAutoAcceptPrice,
            MinimumBestOfferPrice: calculatedPrice.minimumBestOfferPrice,
          };
        } catch (error) {
          // 適用中にエラーが発生した場合
          console.error(`アイテムの再適用中にエラーが発生しました（インデックス: ${index}）:`, error);
          newFailedIndices.push(index);
          return item;
        }
      });

      setData(updatedData);
      setFailedToApplyIndices(newFailedIndices); // 再度失敗したアイテムのインデックスを更新

      // メッセージの更新
      if (newFailedIndices.length === 0) {
        setApplyMessage(
          `全てのアイテムの価格が適用されました。(${data.length} 件)`
        );
        setApplyMessageSeverity('success');
      } else {
        setApplyMessage(
          `一部のアイテムの価格が適用されました。(${data.length - newFailedIndices.length}/${data.length} 件)`
        );
        setApplyMessageSeverity('warning');
      }

      if (setIsDataModified) {
        setIsDataModified(true);
      }
    } catch (err) {
      setError('価格の再適用に失敗しました: ' + err.message);
    }
  };

  const handleRestorePrices = () => {
    if (originalData) {
      setData(originalData);
      setOriginalData(null); // 元のデータをクリア
      setSnackbar({
        open: true,
        message: '価格が元に戻されました',
        severity: 'success',
      });
      setIsPriceApplied(false); // 価格が適用されていない状態に戻す
      setApplyMessage(''); // 価格適用メッセージをリセット
      setFailedToApplyIndices([]); // 失敗したアイテムのインデックスをリセット

      if (setIsDataModified) {
        setIsDataModified(true);
      }
    } else {
      setSnackbar({
        open: true,
        message: '元の価格がありません',
        severity: 'warning',
      });
    }
  };

  // 価格適用状態の初期化
  useEffect(() => {
    if (data && calculatedPrices.length > 0) {
      const allPricesApplied = data.every((item, index) => {
        const calculatedPrice = calculatedPrices[index];
        if (!calculatedPrice) return false;
        return (
          item.StartPrice === calculatedPrice.discountedPrice &&
          item.BestOfferAutoAcceptPrice ===
            calculatedPrice.bestOfferAutoAcceptPrice &&
          item.MinimumBestOfferPrice === calculatedPrice.minimumBestOfferPrice
        );
      });
      setIsPriceApplied(allPricesApplied);
    } else {
      setIsPriceApplied(false);
    }
  }, [data, calculatedPrices]);

  const closePriceSimulation = () => setShowPriceSimulation(false);

  const formatCurrency = (value, currency = 'USD') => {
    if (value === undefined || value === null || isNaN(parseFloat(value))) {
      return currency === 'USD' ? '$0.00' : '¥0';
    }
    if (currency === 'USD') {
      return `$${parseFloat(value).toFixed(2)}`;
    } else if (currency === 'JPY') {
      return `¥${Math.round(parseFloat(value) * exchangeRate)}`;
    }
  };

  const SettingItem = ({ label, value }) => (
    <Typography variant="body2">
      <strong>{label}:</strong> {value}
    </Typography>
  );

  return (
    <Box sx={{ maxWidth: 800, margin: 'auto', p: 2 }}>
      <Typography variant="h6" gutterBottom>
        価格計算と収益シミュレーション
      </Typography>

      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}

      {/* 適用状態の表示 */}
      {isPriceApplied ? (
        <Alert
          severity="success"
          iconMapping={{
            success: <CheckCircleIcon fontSize="inherit" />,
          }}
          sx={{ mb: 2, fontWeight: 'bold', textAlign: 'center' }}
        >
          適用完了
        </Alert>
      ) : (
        <Alert
          severity="warning"
          iconMapping={{
            warning: <WarningAmberIcon fontSize="inherit" />,
          }}
          sx={{ mb: 2, fontWeight: 'bold', textAlign: 'center' }}
        >
          未適用
        </Alert>
      )}

      <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
        <Typography variant="h6" gutterBottom>
          現在の設定
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <SettingItem
              label="目標利益率"
              value={`${settings.targetProfitMargin}%`}
            />
            <SettingItem
              label="eBay最終価値手数料"
              value={`${settings.fees.ebayFinalValue}%`}
            />
            <SettingItem
              label="Payoneer手数料"
              value={`${settings.fees.payoneer}%`}
            />
            <SettingItem
              label="広告出品手数料"
              value={`${settings.fees.promotedListing}%`}
            />
            <SettingItem
              label="eBay取引手数料"
              value={`${formatCurrency(
                settings.fees.ebayTransactionFee
              )} (${formatCurrency(settings.fees.ebayTransactionFee, 'JPY')})`}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SettingItem
              label="為替レート"
              value={`1 USD = ${exchangeRate.toFixed(2)} JPY`}
            />

            <SettingItem
              label="Best Offer自動承認価格"
              value={`${bestOfferAutoAcceptPercentage}%`}
            />
            <SettingItem
              label="最小Best Offer価格"
              value={`${minimumBestOfferPercentage}%`}
            />
            <SettingItem
              label="適用されている送料テンプレート"
              value={`${selectedShippingTemplateName}`}
            />
          </Grid>
        </Grid>
      </Paper>

      <Grid container spacing={2} sx={{ mb: 2 }}>
        <Grid item xs={12} sm={6}>
          <Button
            variant="contained"
            color="primary"
            onClick={calculatePrices}
            disabled={isCalculating}
            fullWidth
          >
            {isCalculating ? '計算中...' : '価格を再計算'}
          </Button>
        </Grid>
      </Grid>

      {/* 処理済みアイテム数の表示 */}
      {isCalculating && (
        <Box sx={{ width: '100%', mb: 2 }}>
          <Typography variant="body2" color="textSecondary">
            処理中... ({processedCount}/{totalItems} 件)
          </Typography>
          <LinearProgress
            variant="determinate"
            value={(processedCount / totalItems) * 100}
          />
        </Box>
      )}

      {calculatedPrices.length > 0 && (
        <Box sx={{ mt: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={3}>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => setShowPriceSimulation(true)}
                fullWidth
              >
                価格シミュレーション結果
              </Button>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleApplyPrices}
                fullWidth
                disabled={isPriceApplied} // 価格適用済みの場合はボタンを無効化
              >
                計算した価格を適用
              </Button>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleReapplyFailedPrices}
                fullWidth
                disabled={failedToApplyIndices.length === 0}
              >
                失敗したアイテムに再適用
              </Button>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleRestorePrices}
                fullWidth
                disabled={!isPriceApplied} // 価格が適用されていない場合はボタンを無効化
              >
                価格を元に戻す
              </Button>
            </Grid>
          </Grid>

          {/* 価格適用時のメッセージを表示 */}
          {applyMessage && (
            <Alert severity={applyMessageSeverity} sx={{ mt: 2 }}>
              {applyMessage}
            </Alert>
          )}
        </Box>
      )}

      {/* 価格シミュレーション結果ダイアログ */}
      <Dialog
        open={showPriceSimulation}
        onClose={closePriceSimulation}
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle>価格シミュレーション結果</DialogTitle>
        <DialogContent sx={{ height: '90vh' }}>
          {/* 総合計金額の表示 */}
          {calculatedPrices.length > 0 && (
            <Typography variant="h6" sx={{ mb: 2 }}>
              計算後の価格の総合計金額:{' '}
              {formatCurrency(
                calculatedPrices.reduce(
                  (sum, price) =>
                    sum + (parseFloat(price?.discountedPrice) || 0),
                  0
                )
              )}{' '}
              (
              {formatCurrency(
                calculatedPrices.reduce(
                  (sum, price) =>
                    sum + (parseFloat(price?.discountedPrice) || 0),
                  0
                ),
                'JPY'
              )}
              )
            </Typography>
          )}

          <TableContainer
            component={Paper}
            sx={{ maxHeight: '100%', overflow: 'auto' }}
          >
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <StyledTableCell>元の価格 (USD)</StyledTableCell>
                  <StyledTableCell>計算後の価格 (USD)</StyledTableCell>
                  <StyledTableCell>
                    ベストオファー自動承認価格 (USD)
                  </StyledTableCell>
                  <StyledTableCell>最小ベストオファー価格 (USD)</StyledTableCell>
                  <StyledTableCell>利益 (USD)</StyledTableCell>
                  <StyledTableCell>利益率 (%)</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {calculatedPrices.map((price, index) => {
                  if (!price) return null;
                  return (
                    <TableRow key={index}>
                      <TableCell>
                        {formatCurrency(price.originalStartPrice)} (
                        {formatCurrency(price.originalStartPrice, 'JPY')})
                      </TableCell>
                      <TableCell>
                        {formatCurrency(price.calculatedPrice)} (
                        {formatCurrency(price.calculatedPrice, 'JPY')})
                      </TableCell>
                      <TableCell>
                        {formatCurrency(price.bestOfferAutoAcceptPrice)} (
                        {formatCurrency(
                          price.bestOfferAutoAcceptPrice,
                          'JPY'
                        )}
                        )
                      </TableCell>
                      <TableCell>
                        {formatCurrency(price.minimumBestOfferPrice)} (
                        {formatCurrency(price.minimumBestOfferPrice, 'JPY')})
                      </TableCell>
                      <TableCell>
                        {formatCurrency(price.profit)} (
                        {formatCurrency(price.profit, 'JPY')})
                      </TableCell>
                      <TableCell>{price.profitMargin}%</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={closePriceSimulation}>閉じる</Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar for notifications */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default PriceCalculationSettings;










// // PriceCalculationSettings.js

// import React, { useState, useEffect, useCallback } from 'react';
// import {
//   Box,
//   Typography,
//   Button,
//   Paper,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   CircularProgress,
//   Dialog,
//   DialogTitle,
//   DialogContent,
//   DialogActions,
//   Alert,
//   Snackbar,
//   Grid,
// } from '@mui/material';
// import { styled } from '@mui/material/styles';
// import CheckCircleIcon from '@mui/icons-material/CheckCircle';
// import WarningAmberIcon from '@mui/icons-material/WarningAmber';

// const StyledTableCell = styled(TableCell)(({ theme }) => ({
//   fontWeight: 'bold',
//   backgroundColor: theme.palette.primary.light,
//   color: theme.palette.common.white,
// }));

// const PriceCalculationSettings = ({ customSettings = {}, data, setData, setIsDataModified }) => {
//   const [calculatedPrices, setCalculatedPrices] = useState([]);
//   const [isCalculating, setIsCalculating] = useState(false);
//   const [error, setError] = useState(null);
//   const [showPriceSimulation, setShowPriceSimulation] = useState(false);
//   const [simulationResults, setSimulationResults] = useState(null);
//   const [snackbar, setSnackbar] = useState({
//     open: false,
//     message: '',
//     severity: 'info',
//   });

//   const [exchangeRate, setExchangeRate] = useState(140);
//   const [bestOfferAutoAcceptPercentage, setBestOfferAutoAcceptPercentage] =
//     useState(95);
//   const [minimumBestOfferPercentage, setMinimumBestOfferPercentage] =
//     useState(80);

//   // 元のデータを保存する状態変数
//   const [originalData, setOriginalData] = useState(null);

//   // 価格が適用されたかどうかを追跡する状態変数
//   const [isPriceApplied, setIsPriceApplied] = useState(false);

//   useEffect(() => {
//     if (customSettings) {
//       setBestOfferAutoAcceptPercentage(
//         customSettings.bestOfferAutoAcceptPercentage || 95
//       );
//       setMinimumBestOfferPercentage(
//         customSettings.minimumBestOfferPercentage || 80
//       );
//       setExchangeRate(customSettings.exchangeRate || 140);
//     }
//   }, [customSettings]);

//   const defaultSettings = {
//     fees: {
//       ebayFinalValue: 10,
//       payoneer: 2,
//       promotedListing: 3,
//       ebayTransactionFee: 0.3,
//     },
//     targetProfitMargin: 25,
//     discountRate: 0,
//     shippingRateTemplates: [],
//     selectedShippingTemplateId: 'default',
//     categories: {
//       additionalCosts: [],
//       expenses: [],
//       fees: [],
//       shipping: [],
//     },
//   };

//   const settings = {
//     ...defaultSettings,
//     ...customSettings,
//   };

//   // 選択された送料テンプレートの情報を取得
//   const selectedShippingTemplateId =
//     settings.selectedShippingTemplateId || 'default';
//   const shippingRateTemplates = settings.shippingRateTemplates || [];

//   const selectedShippingTemplate =
//     shippingRateTemplates.find(
//       (template) => template.id === selectedShippingTemplateId
//     ) || shippingRateTemplates[0];

//   const selectedShippingTemplateName = selectedShippingTemplate
//     ? selectedShippingTemplate.name
//     : 'デフォルト送料設定';

//   const adjustPriceTo99 = (price) => {
//     return Math.floor(price) + 0.99;
//   };

//   const calculatePrices = useCallback(() => {
//     setIsCalculating(true);
//     setError(null);

//     try {
//       if (!settings || !data || data.length === 0) {
//         throw new Error('必要なデータが不足しています。');
//       }

//       if (!Array.isArray(data)) {
//         throw new Error('データ形式が正しくありません。');
//       }

//       const columns = Object.keys(data[0]);
//       if (!columns.includes('StartPrice')) {
//         throw new Error('StartPrice列が見つかりません。');
//       }

//       const selectedShippingTemplateId =
//         settings.selectedShippingTemplateId || 'default';
//       const shippingRateTemplates = settings.shippingRateTemplates || [];

//       const selectedShippingTemplate =
//         shippingRateTemplates.find(
//           (template) => template.id === selectedShippingTemplateId
//         ) || shippingRateTemplates[0];

//       if (!selectedShippingTemplate) {
//         throw new Error('選択された送料テンプレートが見つかりません。');
//       }

//       const newPrices = data.map((item) => {
//         try {
//           const startPrice = parseFloat(item.StartPrice);
//           if (isNaN(startPrice)) {
//             throw new Error('StartPriceが数値ではありません');
//           }

//           let totalCost = startPrice;
//           let totalPercentage = 0;

//           // カテゴリーの項目を適用
//           if (settings.categories && typeof settings.categories === 'object') {
//             Object.values(settings.categories)
//               .flat()
//               .forEach((categoryItem) => {
//                 if (categoryItem.type === 'fixed') {
//                   totalCost += parseFloat(categoryItem.value) || 0;
//                 } else if (categoryItem.type === 'percentage') {
//                   totalPercentage += parseFloat(categoryItem.value) || 0;
//                 }
//               });
//           }

//           // 手数料を追加
//           totalPercentage +=
//             parseFloat(settings.fees.ebayFinalValue || 0) +
//             parseFloat(settings.fees.payoneer || 0) +
//             parseFloat(settings.fees.promotedListing || 0);
//           totalCost += parseFloat(settings.fees.ebayTransactionFee || 0);

//           // 送料を追加
//           const shippingRate = selectedShippingTemplate?.shippingRates?.find(
//             (rate) =>
//               startPrice >= parseFloat(rate.minPriceUSD) &&
//               (parseFloat(rate.maxPriceUSD) === Infinity ||
//                 startPrice < parseFloat(rate.maxPriceUSD))
//           );
//           if (shippingRate) {
//             totalCost += parseFloat(shippingRate.costUSD) || 0;
//           }

//           // 目標利益率を追加
//           const targetProfitMargin = parseFloat(settings.targetProfitMargin) || 0;
//           totalPercentage += targetProfitMargin;

//           // 販売価格を計算
//           let calculatedPrice = totalCost / (1 - totalPercentage / 100);
//           calculatedPrice = adjustPriceTo99(calculatedPrice);

//           // 割引後価格を計算
//           const discountRate = parseFloat(settings.discountRate) || 0;
//           const discountedPrice = calculatedPrice * (1 - discountRate / 100);
//           const finalPrice = adjustPriceTo99(discountedPrice);

//           // ベストオファー価格を計算
//           const bestOfferAutoAcceptPrice =
//             finalPrice * (bestOfferAutoAcceptPercentage / 100);
//           const minimumBestOfferPrice =
//             finalPrice * (minimumBestOfferPercentage / 100);

//           // 利益と利益率を計算
//           const profit = finalPrice - totalCost;
//           const profitMargin = (profit / finalPrice) * 100;

//           return {
//             originalStartPrice: startPrice.toFixed(2),
//             calculatedPrice: calculatedPrice.toFixed(2),
//             discountedPrice: finalPrice.toFixed(2),
//             bestOfferAutoAcceptPrice: bestOfferAutoAcceptPrice.toFixed(2),
//             minimumBestOfferPrice: minimumBestOfferPrice.toFixed(2),
//             profit: profit.toFixed(2),
//             profitMargin: profitMargin.toFixed(2),
//           };
//         } catch (error) {
//           console.error('アイテムの処理中にエラーが発生しました:', item, error);
//           return null; // エラーが発生したアイテムは無視
//         }
//       });

//       setCalculatedPrices(newPrices.filter((price) => price !== null));

//       // エラーがあった場合にユーザーに通知
//       const errorCount = newPrices.filter((price) => price === null).length;
//       if (errorCount > 0) {
//         setSnackbar({
//           open: true,
//           message: `${errorCount} 件のアイテムでエラーが発生しました。詳細はコンソールログを確認してください。`,
//           severity: 'warning',
//         });
//       }

//     } catch (err) {
//       setError(err.message);
//       setSnackbar({
//         open: true,
//         message: `価格計算中にエラーが発生しました: ${err.message}`,
//         severity: 'error',
//       });
//     } finally {
//       setIsCalculating(false);
//     }
//   }, [
//     settings,
//     data,
//     bestOfferAutoAcceptPercentage,
//     minimumBestOfferPercentage,
//   ]);

//   const handleApplyPrices = () => {
//     try {
//       // 価格を適用する前に元のデータを保存
//       if (!originalData) {
//         setOriginalData([...data]);
//       }

//       const updatedData = data.map((item, index) => {
//         const calculatedPrice = calculatedPrices[index];
//         if (!calculatedPrice) return item;

//         return {
//           ...item,
//           StartPrice: calculatedPrice.discountedPrice,
//           BestOfferAutoAcceptPrice: calculatedPrice.bestOfferAutoAcceptPrice,
//           MinimumBestOfferPrice: calculatedPrice.minimumBestOfferPrice,
//         };
//       });

//       setData(updatedData);
//       setSnackbar({
//         open: true,
//         message: '価格が更新されました',
//         severity: 'success',
//       });
//       setIsPriceApplied(true); // 価格が適用されたことを記録

//       if (setIsDataModified) {
//         setIsDataModified(true);
//       }
//     } catch (err) {
//       setError('価格の更新に失敗しました: ' + err.message);
//     }
//   };

//   const handleRestorePrices = () => {
//     if (originalData) {
//       setData(originalData);
//       setOriginalData(null); // 元のデータをクリア
//       setSnackbar({
//         open: true,
//         message: '価格が元に戻されました',
//         severity: 'success',
//       });
//       setIsPriceApplied(false); // 価格が適用されていない状態に戻す

//       if (setIsDataModified) {
//         setIsDataModified(true);
//       }
//     } else {
//       setSnackbar({
//         open: true,
//         message: '元の価格がありません',
//         severity: 'warning',
//       });
//     }
//   };

//   // 価格適用状態の初期化
//   useEffect(() => {
//     if (data && calculatedPrices.length > 0) {
//       const allPricesApplied = data.every((item, index) => {
//         const calculatedPrice = calculatedPrices[index];
//         if (!calculatedPrice) return false;
//         return (
//           item.StartPrice === calculatedPrice.discountedPrice &&
//           item.BestOfferAutoAcceptPrice ===
//             calculatedPrice.bestOfferAutoAcceptPrice &&
//           item.MinimumBestOfferPrice ===
//             calculatedPrice.minimumBestOfferPrice
//         );
//       });
//       setIsPriceApplied(allPricesApplied);
//     } else {
//       setIsPriceApplied(false);
//     }
//   }, [data, calculatedPrices]);

//   const closePriceSimulation = () => setShowPriceSimulation(false);

//   const formatCurrency = (value, currency = 'USD') => {
//     if (value === undefined || value === null || isNaN(parseFloat(value))) {
//       return currency === 'USD' ? '$0.00' : '¥0';
//     }
//     if (currency === 'USD') {
//       return `$${parseFloat(value).toFixed(2)}`;
//     } else if (currency === 'JPY') {
//       return `¥${Math.round(parseFloat(value) * exchangeRate)}`;
//     }
//   };

//   const SettingItem = ({ label, value }) => (
//     <Typography variant="body2">
//       <strong>{label}:</strong> {value}
//     </Typography>
//   );

//   return (
//     <Box sx={{ maxWidth: 800, margin: 'auto', p: 2 }}>
//       <Typography variant="h6" gutterBottom>
//         価格計算と収益シミュレーション
//       </Typography>

//       {error && (
//         <Alert severity="error" sx={{ mb: 2 }}>
//           {error}
//         </Alert>
//       )}

//       {/* 適用状態の表示 */}
//       {isPriceApplied ? (
//         <Alert
//           severity="success"
//           iconMapping={{
//             success: <CheckCircleIcon fontSize="inherit" />,
//           }}
//           sx={{ mb: 2, fontWeight: 'bold', textAlign: 'center' }}
//         >
//           適用完了
//         </Alert>
//       ) : (
//         <Alert
//           severity="warning"
//           iconMapping={{
//             warning: <WarningAmberIcon fontSize="inherit" />,
//           }}
//           sx={{ mb: 2, fontWeight: 'bold', textAlign: 'center' }}
//         >
//           未適用
//         </Alert>
//       )}

//       <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
//         <Typography variant="h6" gutterBottom>
//           現在の設定
//         </Typography>
//         <Grid container spacing={2}>
//           <Grid item xs={12} sm={6}>
//             <SettingItem
//               label="目標利益率"
//               value={`${settings.targetProfitMargin}%`}
//             />
//             <SettingItem
//               label="eBay最終価値手数料"
//               value={`${settings.fees.ebayFinalValue}%`}
//             />
//             <SettingItem
//               label="Payoneer手数料"
//               value={`${settings.fees.payoneer}%`}
//             />
//             <SettingItem
//               label="広告出品手数料"
//               value={`${settings.fees.promotedListing}%`}
//             />
//             <SettingItem
//               label="eBay取引手数料"
//               value={`${formatCurrency(
//                 settings.fees.ebayTransactionFee
//               )} (${formatCurrency(settings.fees.ebayTransactionFee, 'JPY')})`}
//             />
//           </Grid>
//           <Grid item xs={12} sm={6}>
//             <SettingItem
//               label="為替レート"
//               value={`1 USD = ${exchangeRate.toFixed(2)} JPY`}
//             />

//             <SettingItem
//               label="Best Offer自動承認価格"
//               value={`${bestOfferAutoAcceptPercentage}%`}
//             />
//             <SettingItem
//               label="最小Best Offer価格"
//               value={`${minimumBestOfferPercentage}%`}
//             />
//             <SettingItem
//               label="適用されている送料テンプレート"
//               value={`${selectedShippingTemplateName}`}
//             />
//           </Grid>
//         </Grid>
//       </Paper>

//       <Grid container spacing={2} sx={{ mb: 2 }}>
//         <Grid item xs={12} sm={6}>
//           <Button
//             variant="contained"
//             color="primary"
//             onClick={calculatePrices}
//             disabled={isCalculating}
//             fullWidth
//           >
//             {isCalculating ? <CircularProgress size={24} /> : '価格を再計算'}
//           </Button>
//         </Grid>
//       </Grid>

//       {calculatedPrices.length > 0 && (
//         <Grid container spacing={2} sx={{ mt: 2 }}>
//           <Grid item xs={12} sm={4}>
//             <Button
//               variant="contained"
//               color="secondary"
//               onClick={() => setShowPriceSimulation(true)}
//               fullWidth
//             >
//               価格シミュレーション結果
//             </Button>
//           </Grid>
//           <Grid item xs={12} sm={4}>
//             <Button
//               variant="contained"
//               color="secondary"
//               onClick={handleApplyPrices}
//               fullWidth
//               disabled={isPriceApplied} // 価格適用済みの場合はボタンを無効化
//             >
//               計算した価格を適用
//             </Button>
//           </Grid>
//           <Grid item xs={12} sm={4}>
//             <Button
//               variant="outlined"
//               color="primary"
//               onClick={handleRestorePrices}
//               fullWidth
//               disabled={!isPriceApplied} // 価格が適用されていない場合はボタンを無効化
//             >
//               価格を元に戻す
//             </Button>
//           </Grid>
//         </Grid>
//       )}

//       {/* 価格シミュレーション結果ダイアログ */}
//       <Dialog
//         open={showPriceSimulation}
//         onClose={closePriceSimulation}
//         maxWidth="lg"
//         fullWidth
//       >
//         <DialogTitle>価格シミュレーション結果</DialogTitle>
//         <DialogContent sx={{ height: '90vh' }}>
//           {/* 総合計金額の表示 */}
//           {calculatedPrices.length > 0 && (
//             <Typography variant="h6" sx={{ mb: 2 }}>
//               計算後の価格の総合計金額: {formatCurrency(
//                 calculatedPrices.reduce(
//                   (sum, price) => sum + parseFloat(price.discountedPrice),
//                   0
//                 )
//               )}{' '}
//               ({formatCurrency(
//                 calculatedPrices.reduce(
//                   (sum, price) => sum + parseFloat(price.discountedPrice),
//                   0
//                 ),
//                 'JPY'
//               )}
//               )
//             </Typography>
//           )}
//           <TableContainer
//             component={Paper}
//             sx={{ maxHeight: '100%', overflow: 'auto' }}
//           >
//             <Table stickyHeader>
//               <TableHead>
//                 <TableRow>
//                   <StyledTableCell>元の価格 (USD)</StyledTableCell>
//                   <StyledTableCell>計算後の価格 (USD)</StyledTableCell>
//                   <StyledTableCell>
//                     ベストオファー自動承認価格 (USD)
//                   </StyledTableCell>
//                   <StyledTableCell>最小ベストオファー価格 (USD)</StyledTableCell>
//                   <StyledTableCell>利益 (USD)</StyledTableCell>
//                   <StyledTableCell>利益率(手数料引き前) (%)</StyledTableCell>
//                 </TableRow>
//               </TableHead>
//               <TableBody>
//                 {calculatedPrices.map((price, index) => (
//                   <TableRow key={index}>
//                     <TableCell>
//                       {formatCurrency(price.originalStartPrice)} (
//                       {formatCurrency(price.originalStartPrice, 'JPY')})
//                     </TableCell>
//                     <TableCell>
//                       {formatCurrency(price.calculatedPrice)} (
//                       {formatCurrency(price.calculatedPrice, 'JPY')})
//                     </TableCell>
//                     <TableCell>
//                       {formatCurrency(price.bestOfferAutoAcceptPrice)} (
//                       {formatCurrency(price.bestOfferAutoAcceptPrice, 'JPY')})
//                     </TableCell>
//                     <TableCell>
//                       {formatCurrency(price.minimumBestOfferPrice)} (
//                       {formatCurrency(price.minimumBestOfferPrice, 'JPY')})
//                     </TableCell>
//                     <TableCell>
//                       {formatCurrency(price.profit)} (
//                       {formatCurrency(price.profit, 'JPY')})
//                     </TableCell>
//                     <TableCell>{price.profitMargin}%</TableCell>
//                   </TableRow>
//                 ))}
//               </TableBody>
//             </Table>
//           </TableContainer>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={closePriceSimulation}>閉じる</Button>
//         </DialogActions>
//       </Dialog>

//       {/* Snackbar for notifications */}
//       <Snackbar
//         open={snackbar.open}
//         autoHideDuration={6000}
//         onClose={() => setSnackbar({ ...snackbar, open: false })}
//       >
//         <Alert
//           onClose={() => setSnackbar({ ...snackbar, open: false })}
//           severity={snackbar.severity}
//           sx={{ width: '100%' }}
//         >
//           {snackbar.message}
//         </Alert>
//       </Snackbar>
//     </Box>
//   );
// };

// export default PriceCalculationSettings;








// // PriceCalculationSettings.js

// import React, { useState, useEffect, useCallback } from 'react';
// import {
//   Box,
//   Typography,
//   Button,
//   Paper,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   CircularProgress,
//   Dialog,
//   DialogTitle,
//   DialogContent,
//   DialogActions,
//   Alert,
//   Snackbar,
//   Grid,
// } from '@mui/material';
// import { styled } from '@mui/material/styles';
// import CheckCircleIcon from '@mui/icons-material/CheckCircle';
// import WarningAmberIcon from '@mui/icons-material/WarningAmber';

// const StyledTableCell = styled(TableCell)(({ theme }) => ({
//   fontWeight: 'bold',
//   backgroundColor: theme.palette.primary.light,
//   color: theme.palette.common.white,
// }));

// const PriceCalculationSettings = ({ customSettings = {}, data, setData }) => {
//   const [calculatedPrices, setCalculatedPrices] = useState([]);
//   const [isCalculating, setIsCalculating] = useState(false);
//   const [error, setError] = useState(null);
//   const [showPriceSimulation, setShowPriceSimulation] = useState(false);
//   const [showRevenueSimulation, setShowRevenueSimulation] = useState(false);
//   const [simulationResults, setSimulationResults] = useState(null);
//   const [snackbar, setSnackbar] = useState({
//     open: false,
//     message: '',
//     severity: 'info',
//   });

//   const [exchangeRate, setExchangeRate] = useState(140);
//   const [bestOfferAutoAcceptPercentage, setBestOfferAutoAcceptPercentage] =
//     useState(95);
//   const [minimumBestOfferPercentage, setMinimumBestOfferPercentage] =
//     useState(80);

//   // 追加: 元のデータを保存する状態変数
//   const [originalData, setOriginalData] = useState(null);

//   // 追加: 価格が適用されたかどうかを追跡する状態変数
//   const [isPriceApplied, setIsPriceApplied] = useState(false);

//   useEffect(() => {
//     if (customSettings) {
//       setBestOfferAutoAcceptPercentage(
//         customSettings.bestOfferAutoAcceptPercentage || 95
//       );
//       setMinimumBestOfferPercentage(
//         customSettings.minimumBestOfferPercentage || 80
//       );
//       setExchangeRate(customSettings.exchangeRate || 140);
//     }
//   }, [customSettings]);

//   const defaultSettings = {
//     fees: {
//       ebayFinalValue: 10,
//       payoneer: 2,
//       promotedListing: 3,
//       ebayTransactionFee: 0.3,
//     },
//     targetProfitMargin: 25,
//     discountRate: 0,
//     shippingRateTemplates: [],
//     selectedShippingTemplateId: 'default',
//     categories: {
//       additionalCosts: [],
//       expenses: [],
//       fees: [],
//       shipping: [],
//     },
//   };

//   const settings = {
//     ...defaultSettings,
//     ...customSettings,
//   };

//   // 選択された送料テンプレートの情報を取得
//   const selectedShippingTemplateId =
//     settings.selectedShippingTemplateId || 'default';
//   const shippingRateTemplates = settings.shippingRateTemplates || [];

//   const selectedShippingTemplate =
//     shippingRateTemplates.find(
//       (template) => template.id === selectedShippingTemplateId
//     ) || shippingRateTemplates[0];

//   const selectedShippingTemplateName = selectedShippingTemplate
//     ? selectedShippingTemplate.name
//     : 'デフォルト送料設定';

//   const adjustPriceTo99 = (price) => {
//     return Math.floor(price) + 0.99;
//   };

//   const calculatePrices = useCallback(() => {
//     setIsCalculating(true);
//     setError(null);

//     try {
//       if (!settings || !data || data.length === 0) {
//         throw new Error('必要なデータが不足しています。');
//       }

//       if (!Array.isArray(data)) {
//         throw new Error('データ形式が正しくありません。');
//       }

//       const columns = Object.keys(data[0]);
//       if (!columns.includes('StartPrice')) {
//         throw new Error('StartPrice列が見つかりません。');
//       }

//       const selectedShippingTemplateId =
//         settings.selectedShippingTemplateId || 'default';
//       const shippingRateTemplates = settings.shippingRateTemplates || [];

//       const selectedShippingTemplate =
//         shippingRateTemplates.find(
//           (template) => template.id === selectedShippingTemplateId
//         ) || shippingRateTemplates[0];

//       if (!selectedShippingTemplate) {
//         throw new Error('選択された送料テンプレートが見つかりません。');
//       }

//       const newPrices = data.map((item) => {
//         const startPrice = parseFloat(item.StartPrice);
//         if (isNaN(startPrice)) {
//           return null;
//         }

//         let totalCost = startPrice;
//         let totalPercentage = 0;

//         // カテゴリーの項目を適用
//         if (settings.categories && typeof settings.categories === 'object') {
//           Object.values(settings.categories)
//             .flat()
//             .forEach((categoryItem) => {
//               if (categoryItem.type === 'fixed') {
//                 totalCost += parseFloat(categoryItem.value) || 0;
//               } else if (categoryItem.type === 'percentage') {
//                 totalPercentage += parseFloat(categoryItem.value) || 0;
//               }
//             });
//         }

//         // 手数料を追加
//         totalPercentage +=
//           parseFloat(settings.fees.ebayFinalValue || 0) +
//           parseFloat(settings.fees.payoneer || 0) +
//           parseFloat(settings.fees.promotedListing || 0);
//         totalCost += parseFloat(settings.fees.ebayTransactionFee || 0);

//         // 送料を追加
//         const shippingRate = selectedShippingTemplate?.shippingRates?.find(
//           (rate) =>
//             startPrice >= parseFloat(rate.minPriceUSD) &&
//             (parseFloat(rate.maxPriceUSD) === Infinity ||
//               startPrice < parseFloat(rate.maxPriceUSD))
//         );
//         if (shippingRate) {
//           totalCost += parseFloat(shippingRate.costUSD) || 0;
//         }

//         // 目標利益率を追加
//         const targetProfitMargin = parseFloat(settings.targetProfitMargin) || 0;
//         totalPercentage += targetProfitMargin;

//         // 販売価格を計算
//         let calculatedPrice = totalCost / (1 - totalPercentage / 100);
//         calculatedPrice = adjustPriceTo99(calculatedPrice);

//         // 割引後価格を計算
//         const discountRate = parseFloat(settings.discountRate) || 0;
//         const discountedPrice = calculatedPrice * (1 - discountRate / 100);
//         const finalPrice = adjustPriceTo99(discountedPrice);

//         // ベストオファー価格を計算
//         const bestOfferAutoAcceptPrice =
//           finalPrice * (bestOfferAutoAcceptPercentage / 100);
//         const minimumBestOfferPrice =
//           finalPrice * (minimumBestOfferPercentage / 100);

//         // 利益と利益率を計算
//         const profit = finalPrice - totalCost;
//         const profitMargin = (profit / finalPrice) * 100;

//         return {
//           originalStartPrice: startPrice.toFixed(2),
//           calculatedPrice: calculatedPrice.toFixed(2),
//           discountedPrice: finalPrice.toFixed(2),
//           bestOfferAutoAcceptPrice: bestOfferAutoAcceptPrice.toFixed(2),
//           minimumBestOfferPrice: minimumBestOfferPrice.toFixed(2),
//           profit: profit.toFixed(2),
//           profitMargin: profitMargin.toFixed(2),
//         };
//       });

//       setCalculatedPrices(newPrices.filter((price) => price !== null));
//     } catch (err) {
//       setError(err.message);
//     } finally {
//       setIsCalculating(false);
//     }
//   }, [
//     settings,
//     data,
//     bestOfferAutoAcceptPercentage,
//     minimumBestOfferPercentage,
//   ]);

//   const handleApplyPrices = () => {
//     try {
//       // 価格を適用する前に元のデータを保存
//       if (!originalData) {
//         setOriginalData([...data]);
//       }

//       const updatedData = data.map((item, index) => {
//         const calculatedPrice = calculatedPrices[index];
//         if (!calculatedPrice) return item;

//         return {
//           ...item,
//           StartPrice: calculatedPrice.discountedPrice,
//           BestOfferAutoAcceptPrice: calculatedPrice.bestOfferAutoAcceptPrice,
//           MinimumBestOfferPrice: calculatedPrice.minimumBestOfferPrice,
//         };
//       });

//       setData(updatedData);
//       setSnackbar({
//         open: true,
//         message: '価格が更新されました',
//         severity: 'success',
//       });
//       setIsPriceApplied(true); // 価格が適用されたことを記録
//     } catch (err) {
//       setError('価格の更新に失敗しました: ' + err.message);
//     }
//   };

//   const handleRestorePrices = () => {
//     if (originalData) {
//       setData(originalData);
//       setOriginalData(null); // 元のデータをクリア
//       setSnackbar({
//         open: true,
//         message: '価格が元に戻されました',
//         severity: 'success',
//       });
//       setIsPriceApplied(false); // 価格が適用されていない状態に戻す
//     } else {
//       setSnackbar({
//         open: true,
//         message: '元の価格がありません',
//         severity: 'warning',
//       });
//     }
//   };

//   // 価格適用状態の初期化
//   useEffect(() => {
//     if (data && calculatedPrices.length > 0) {
//       const allPricesApplied = data.every((item, index) => {
//         const calculatedPrice = calculatedPrices[index];
//         if (!calculatedPrice) return false;
//         return (
//           item.StartPrice === calculatedPrice.discountedPrice &&
//           item.BestOfferAutoAcceptPrice ===
//             calculatedPrice.bestOfferAutoAcceptPrice &&
//           item.MinimumBestOfferPrice ===
//             calculatedPrice.minimumBestOfferPrice
//         );
//       });
//       setIsPriceApplied(allPricesApplied);
//     } else {
//       setIsPriceApplied(false);
//     }
//   }, [data, calculatedPrices]);

//   const runSimulation = () => {
//     const totalRevenue = calculatedPrices.reduce(
//       (sum, price) => sum + parseFloat(price.discountedPrice),
//       0
//     );
//     const totalProfit = calculatedPrices.reduce(
//       (sum, price) => sum + parseFloat(price.profit),
//       0
//     );
//     const averageProfitMargin =
//       calculatedPrices.reduce(
//         (sum, price) => sum + parseFloat(price.profitMargin),
//         0
//       ) / calculatedPrices.length;

//     const results = {
//       totalRevenue,
//       totalProfit,
//       averageProfitMargin,
//       itemCount: calculatedPrices.length,
//     };

//     setSimulationResults(results);
//     setShowRevenueSimulation(true);
//   };

//   const closePriceSimulation = () => setShowPriceSimulation(false);
//   const closeRevenueSimulation = () => setShowRevenueSimulation(false);

//   const formatCurrency = (value, currency = 'USD') => {
//     if (value === undefined || value === null || isNaN(parseFloat(value))) {
//       return currency === 'USD' ? '$0.00' : '¥0';
//     }
//     if (currency === 'USD') {
//       return `$${parseFloat(value).toFixed(2)}`;
//     } else if (currency === 'JPY') {
//       return `¥${Math.round(parseFloat(value) * exchangeRate)}`;
//     }
//   };

//   const SettingItem = ({ label, value }) => (
//     <Typography variant="body2">
//       <strong>{label}:</strong> {value}
//     </Typography>
//   );

//   return (
//     <Box sx={{ maxWidth: 800, margin: 'auto', p: 2 }}>
//       <Typography variant="h6" gutterBottom>
//         価格計算と収益シミュレーション
//       </Typography>

//       {error && (
//         <Alert severity="error" sx={{ mb: 2 }}>
//           {error}
//         </Alert>
//       )}

//       {/* 適用状態の表示 */}
//       {isPriceApplied ? (
//         <Alert
//           severity="success"
//           iconMapping={{
//             success: <CheckCircleIcon fontSize="inherit" />,
//           }}
//           sx={{ mb: 2, fontWeight: 'bold', textAlign: 'center' }}
//         >
//           適用完了
//         </Alert>
//       ) : (
//         <Alert
//           severity="warning"
//           iconMapping={{
//             warning: <WarningAmberIcon fontSize="inherit" />,
//           }}
//           sx={{ mb: 2, fontWeight: 'bold', textAlign: 'center' }}
//         >
//           未適用
//         </Alert>
//       )}

//       <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
//         <Typography variant="h6" gutterBottom>
//           現在の設定
//         </Typography>
//         <Grid container spacing={2}>
//           <Grid item xs={12} sm={6}>
//             <SettingItem
//               label="目標利益率"
//               value={`${settings.targetProfitMargin}%`}
//             />
//             <SettingItem
//               label="eBay最終価値手数料"
//               value={`${settings.fees.ebayFinalValue}%`}
//             />
//             <SettingItem
//               label="Payoneer手数料"
//               value={`${settings.fees.payoneer}%`}
//             />
//             <SettingItem
//               label="広告出品手数料"
//               value={`${settings.fees.promotedListing}%`}
//             />
//             <SettingItem
//               label="eBay取引手数料"
//               value={`${formatCurrency(
//                 settings.fees.ebayTransactionFee
//               )} (${formatCurrency(settings.fees.ebayTransactionFee, 'JPY')})`}
//             />
//           </Grid>
//           <Grid item xs={12} sm={6}>
//             <SettingItem
//               label="為替レート"
//               value={`1 USD = ${exchangeRate.toFixed(2)} JPY`}
//             />

//             <SettingItem
//               label="Best Offer自動承認価格"
//               value={`${bestOfferAutoAcceptPercentage}%`}
//             />
//             <SettingItem
//               label="最小Best Offer価格"
//               value={`${minimumBestOfferPercentage}%`}
//             />
//             <SettingItem
//               label="適用されている送料テンプレート"
//               value={`${selectedShippingTemplateName}`}
//             />
//           </Grid>
//         </Grid>
//       </Paper>

//       <Grid container spacing={2} sx={{ mb: 2 }}>
//         <Grid item xs={12} sm={6}>
//           <Button
//             variant="contained"
//             color="primary"
//             onClick={calculatePrices}
//             disabled={isCalculating}
//             fullWidth
//           >
//             {isCalculating ? <CircularProgress size={24} /> : '価格を再計算'}
//           </Button>
//         </Grid>
//       </Grid>

//       {calculatedPrices.length > 0 && (
//         <Grid container spacing={2} sx={{ mt: 2 }}>
//           <Grid item xs={12} sm={4}>
//             <Button
//               variant="contained"
//               color="secondary"
//               onClick={() => setShowPriceSimulation(true)}
//               fullWidth
//             >
//               価格シミュレーション結果
//             </Button>
//           </Grid>
//           <Grid item xs={12} sm={4}>
//             <Button
//               variant="contained"
//               color="secondary"
//               onClick={handleApplyPrices}
//               fullWidth
//               disabled={isPriceApplied} // 価格適用済みの場合はボタンを無効化
//             >
//               計算した価格を適用
//             </Button>
//           </Grid>
//           <Grid item xs={12} sm={4}>
//             <Button
//               variant="outlined"
//               color="primary"
//               onClick={handleRestorePrices}
//               fullWidth
//               disabled={!isPriceApplied} // 価格が適用されていない場合はボタンを無効化
//             >
//               価格を元に戻す
//             </Button>
//           </Grid>
//         </Grid>
//       )}

//       {/* 価格シミュレーション結果ダイアログ */}
//       <Dialog
//         open={showPriceSimulation}
//         onClose={closePriceSimulation}
//         maxWidth="lg"
//         fullWidth
//       >
//         <DialogTitle>価格シミュレーション結果</DialogTitle>
//         <DialogContent sx={{ height: '90vh' }}>
//           {/* 総合計金額の表示 */}
//           {calculatedPrices.length > 0 && (
//             <Typography variant="h6" sx={{ mb: 2 }}>
//               計算後の価格の総合計金額: {formatCurrency(
//                 calculatedPrices.reduce(
//                   (sum, price) => sum + parseFloat(price.discountedPrice),
//                   0
//                 )
//               )}{' '}
//               ({formatCurrency(
//                 calculatedPrices.reduce(
//                   (sum, price) => sum + parseFloat(price.discountedPrice),
//                   0
//                 ),
//                 'JPY'
//               )}
//               )
//             </Typography>
//           )}
//           <TableContainer
//             component={Paper}
//             sx={{ maxHeight: '100%', overflow: 'auto' }}
//           >
//             <Table stickyHeader>
//               <TableHead>
//                 <TableRow>
//                   <StyledTableCell>元の価格 (USD)</StyledTableCell>
//                   <StyledTableCell>計算後の価格 (USD)</StyledTableCell>
//                   <StyledTableCell>
//                     ベストオファー自動承認価格 (USD)
//                   </StyledTableCell>
//                   <StyledTableCell>最小ベストオファー価格 (USD)</StyledTableCell>
//                   <StyledTableCell>利益 (USD)</StyledTableCell>
//                   <StyledTableCell>利益率(手数料引き前) (%)</StyledTableCell>
//                 </TableRow>
//               </TableHead>
//               <TableBody>
//                 {calculatedPrices.map((price, index) => (
//                   <TableRow key={index}>
//                     <TableCell>
//                       {formatCurrency(price.originalStartPrice)} (
//                       {formatCurrency(price.originalStartPrice, 'JPY')})
//                     </TableCell>
//                     <TableCell>
//                       {formatCurrency(price.calculatedPrice)} (
//                       {formatCurrency(price.calculatedPrice, 'JPY')})
//                     </TableCell>
//                     <TableCell>
//                       {formatCurrency(price.bestOfferAutoAcceptPrice)} (
//                       {formatCurrency(price.bestOfferAutoAcceptPrice, 'JPY')})
//                     </TableCell>
//                     <TableCell>
//                       {formatCurrency(price.minimumBestOfferPrice)} (
//                       {formatCurrency(price.minimumBestOfferPrice, 'JPY')})
//                     </TableCell>
//                     <TableCell>
//                       {formatCurrency(price.profit)} (
//                       {formatCurrency(price.profit, 'JPY')})
//                     </TableCell>
//                     <TableCell>{price.profitMargin}%</TableCell>
//                   </TableRow>
//                 ))}
//               </TableBody>
//             </Table>
//           </TableContainer>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={closePriceSimulation}>閉じる</Button>
//         </DialogActions>
//       </Dialog>


//       {/* Snackbar for notifications */}
//       <Snackbar
//         open={snackbar.open}
//         autoHideDuration={6000}
//         onClose={() => setSnackbar({ ...snackbar, open: false })}
//       >
//         <Alert
//           onClose={() => setSnackbar({ ...snackbar, open: false })}
//           severity={snackbar.severity}
//           sx={{ width: '100%' }}
//         >
//           {snackbar.message}
//         </Alert>
//       </Snackbar>
//     </Box>
//   );
// };

// export default PriceCalculationSettings;