// ItemSpecificsManager.js

import React, { useState, useEffect } from 'react';
import { getFirestore, doc, getDoc, setDoc } from 'firebase/firestore';
import {
  Button,
  TextField,
  Checkbox,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  InputLabel,
  Select,
  MenuItem,
  Snackbar,
  Alert,
  FormControlLabel,
  RadioGroup,
  Radio,
  Autocomplete,
  Box,
  ListItemSecondaryAction,
  List,
  ListItem,
  ListItemText,
  FormControl,
} from '@mui/material';
import {
  Add as AddIcon,
  Save as SaveIcon,
  Delete as DeleteIcon,
  Settings as SettingsIcon,
  Refresh as RefreshIcon,
  ChevronRight as ChevronRightIcon,
  ContentCopy as CopyIcon,
} from '@mui/icons-material';
import { nanoid } from 'nanoid';
import SortableList from './SortableList';

function ItemSpecificsManager({ userId, headers, data, setData }) {
  const [categories, setCategories] = useState({});
  const [selectedCategory, setSelectedCategory] = useState('');
  const [newCategory, setNewCategory] = useState('');
  const [newColumn, setNewColumn] = useState('');
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [activeView, setActiveView] = useState('manage');
  const [duplicateCategory, setDuplicateCategory] = useState('');
  const [isConversionRuleDialogOpen, setIsConversionRuleDialogOpen] = useState(false);
  const [currentColumn, setCurrentColumn] = useState('');
  const [newRule, setNewRule] = useState({ keyword: '', value: '' });
  const [search, setSearch] = useState('');
  const [bulkInput, setBulkInput] = useState('');
  const [isBulkInputOpen, setIsBulkInputOpen] = useState(false);
  const [duplicateDialogOpen, setDuplicateDialogOpen] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [loading, setLoading] = useState(false);
  const [previewData, setPreviewData] = useState([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [updatedData, setUpdatedData] = useState([]);
  const [caseSensitive, setCaseSensitive] = useState(false);
  const [partialMatch, setPartialMatch] = useState(true);
  const [matchSource, setMatchSource] = useState('title');
  const [matchingOption, setMatchingOption] = useState('priority');

  const db = getFirestore();

  // Firestoreからデータを読み込む関数
  const loadSettings = async () => {
    if (!userId) return;
    try {
      const docRef = doc(db, 'itemSpecificsSettings', userId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const userSettings = docSnap.data();
        setCategories(userSettings.categories || {});
        setSnackbar({ open: true, message: '設定を読み込みました。', severity: 'success' });
      } else {
        setCategories({});
        setSnackbar({ open: true, message: '設定が見つかりません。', severity: 'info' });
      }
    } catch (error) {
      console.error('設定の読み込みに失敗しました:', error);
      setSnackbar({ open: true, message: '設定の読み込みに失敗しました。', severity: 'error' });
    }
  };

  useEffect(() => {
    loadSettings();
  }, [userId]);

  // 設定を保存する関数
  const handleSaveSettings = async () => {
    if (!userId) return;
    const settings = {
      categories,
    };
    try {
      const docRef = doc(db, 'itemSpecificsSettings', userId);
      await setDoc(docRef, settings);
      setSnackbar({ open: true, message: '設定を保存しました。', severity: 'success' });
    } catch (error) {
      console.error('設定の保存に失敗しました:', error);
      setSnackbar({ open: true, message: '設定の保存に失敗しました。', severity: 'error' });
    }
  };

  // カテゴリーを追加する関数
  const handleAddCategory = () => {
    if (newCategory && !categories[newCategory]) {
      setCategories({ ...categories, [newCategory]: { columns: {} } });
      setSelectedCategory(newCategory);
      setNewCategory('');
      setSnackbar({ open: true, message: `カテゴリー "${newCategory}" を追加しました`, severity: 'success' });
    } else if (categories[newCategory]) {
      setSnackbar({ open: true, message: `カテゴリー "${newCategory}" は既に存在します`, severity: 'warning' });
    } else {
      setSnackbar({ open: true, message: '有効なカテゴリー名を入力してください', severity: 'error' });
    }
  };

  // カテゴリーを選択する関数
  const handleSelectCategory = (category) => {
    setSelectedCategory(category);
  };

  // カラムを追加する関数
  const handleAddColumn = () => {
    const lowerCaseNewColumn = newColumn.toLowerCase();
    if (
      newColumn &&
      selectedCategory &&
      !Object.keys(categories[selectedCategory].columns || {}).some(
        (col) => col.toLowerCase() === lowerCaseNewColumn
      )
    ) {
      setCategories({
        ...categories,
        [selectedCategory]: {
          ...categories[selectedCategory],
          columns: {
            ...categories[selectedCategory].columns,
            [newColumn]: { values: [], conversionRules: [] },
          },
        },
      });
      setNewColumn('');
      setSnackbar({ open: true, message: `カラム "${newColumn}" を追加しました`, severity: 'success' });
    } else if (
      Object.keys(categories[selectedCategory].columns || {}).some(
        (col) => col.toLowerCase() === lowerCaseNewColumn
      )
    ) {
      setSnackbar({ open: true, message: `カラム "${newColumn}" は既に存在します`, severity: 'warning' });
    } else {
      setSnackbar({ open: true, message: '有効なカラム名を入力してください', severity: 'error' });
    }
  };

  // カテゴリーを削除する関数
  const handleDeleteCategory = () => {
    const updatedCategories = { ...categories };
    delete updatedCategories[selectedCategory];
    setCategories(updatedCategories);
    setSelectedCategory('');
    setSnackbar({ open: true, message: `カテゴリー "${selectedCategory}" を削除しました`, severity: 'info' });
  };

  // 設定を複製する関数
  const handleDuplicateSettings = () => {
    if (!selectedCategory || !categories[selectedCategory]) {
      setSnackbar({
        open: true,
        message: '複製元のカテゴリーを選択してください',
        severity: 'error',
      });
      setDuplicateDialogOpen(false);
      return;
    }

    if (!duplicateCategory) {
      setSnackbar({
        open: true,
        message: '有効な複製先のカテゴリー名を入力してください',
        severity: 'error',
      });
      return;
    }

    if (categories[duplicateCategory]) {
      setSnackbar({
        open: true,
        message: `カテゴリー "${duplicateCategory}" は既に存在します`,
        severity: 'warning',
      });
      return;
    }

    const newCategoryData = JSON.parse(JSON.stringify(categories[selectedCategory]));

    setCategories((prevCategories) => ({
      ...prevCategories,
      [duplicateCategory]: newCategoryData,
    }));
    setSnackbar({
      open: true,
      message: `設定が "${duplicateCategory}" に複製されました`,
      severity: 'success',
    });
    setDuplicateDialogOpen(false);
    setDuplicateCategory('');
  };

  // カラムを削除する関数
  const handleDeleteColumn = (column) => {
    const updatedColumns = { ...categories[selectedCategory].columns };
    delete updatedColumns[column];
    setCategories({
      ...categories,
      [selectedCategory]: {
        ...categories[selectedCategory],
        columns: updatedColumns,
      },
    });
    setSnackbar({ open: true, message: `カラム "${column}" を削除しました`, severity: 'info' });
  };

  // 値を追加する関数
  const handleAddValue = (column, value) => {
    if (value && selectedCategory) {
      setCategories((prevCategories) => {
        const existingValues =
          (prevCategories[selectedCategory] &&
            prevCategories[selectedCategory].columns[column] &&
            prevCategories[selectedCategory].columns[column].values) ||
          [];
        const lowerCaseValues = existingValues.map((v) => v.toLowerCase());
        if (!lowerCaseValues.includes(value.toLowerCase())) {
          return {
            ...prevCategories,
            [selectedCategory]: {
              ...prevCategories[selectedCategory],
              columns: {
                ...prevCategories[selectedCategory].columns,
                [column]: {
                  ...prevCategories[selectedCategory].columns[column],
                  values: [...existingValues, value],
                  conversionRules:
                    (prevCategories[selectedCategory].columns[column] &&
                      prevCategories[selectedCategory].columns[column].conversionRules) ||
                    [],
                },
              },
            },
          };
        } else {
          setSnackbar({ open: true, message: `値 "${value}" は既に存在します`, severity: 'warning' });
          return prevCategories;
        }
      });
    } else {
      setSnackbar({ open: true, message: '値を入力してください', severity: 'error' });
    }
  };

  // 値を削除する関数
  const handleDeleteValue = (column, index) => {
    setCategories((prevCategories) => {
      const existingValues =
        (prevCategories[selectedCategory] &&
          prevCategories[selectedCategory].columns[column] &&
          prevCategories[selectedCategory].columns[column].values) ||
        [];
      return {
        ...prevCategories,
        [selectedCategory]: {
          ...prevCategories[selectedCategory],
          columns: {
            ...prevCategories[selectedCategory].columns,
            [column]: {
              ...prevCategories[selectedCategory].columns[column],
              values: existingValues.filter((_, i) => i !== index),
            },
          },
        },
      };
    });
    setSnackbar({ open: true, message: `値を削除しました`, severity: 'info' });
  };

  // 値のリストを並べ替える関数
  const handleValuesDragEnd = (result, column) => {
    if (!result.destination) return;
    const sourceIndex = result.source.index;
    const destinationIndex = result.destination.index;

    setCategories((prevCategories) => {
      const columnValues = Array.from(prevCategories[selectedCategory].columns[column].values);
      const [removed] = columnValues.splice(sourceIndex, 1);
      columnValues.splice(destinationIndex, 0, removed);

      return {
        ...prevCategories,
        [selectedCategory]: {
          ...prevCategories[selectedCategory],
          columns: {
            ...prevCategories[selectedCategory].columns,
            [column]: {
              ...prevCategories[selectedCategory].columns[column],
              values: columnValues,
            },
          },
        },
      };
    });
  };

  // 一括入力を処理する関数
  const handleBulkInput = () => {
    const values = bulkInput.split('\n').map((v) => v.trim()).filter((v) => v);
    setCategories((prevCategories) => {
      const existingValues =
        (prevCategories[selectedCategory] &&
          prevCategories[selectedCategory].columns[currentColumn] &&
          prevCategories[selectedCategory].columns[currentColumn].values) ||
        [];
      return {
        ...prevCategories,
        [selectedCategory]: {
          ...prevCategories[selectedCategory],
          columns: {
            ...prevCategories[selectedCategory].columns,
            [currentColumn]: {
              ...prevCategories[selectedCategory].columns[currentColumn],
              values: [...existingValues, ...values],
            },
          },
        },
      };
    });
    setBulkInput('');
    setIsBulkInputOpen(false);
    setSnackbar({ open: true, message: `${values.length} 件の値を追加しました`, severity: 'success' });
  };

  // 変換ルールを追加する関数
  const handleAddConversionRule = (column, rule) => {
    const newId = nanoid();
    setCategories((prevCategories) => {
      const existingRules =
        (prevCategories[selectedCategory] &&
          prevCategories[selectedCategory].columns[column] &&
          prevCategories[selectedCategory].columns[column].conversionRules) ||
        [];
      return {
        ...prevCategories,
        [selectedCategory]: {
          ...prevCategories[selectedCategory],
          columns: {
            ...prevCategories[selectedCategory].columns,
            [column]: {
              ...prevCategories[selectedCategory].columns[column],
              conversionRules: [
                ...existingRules,
                { id: newId, ...rule },
              ],
            },
          },
        },
      };
    });
    setSnackbar({ open: true, message: '変換ルールを追加しました', severity: 'success' });
  };

  // 変換ルールを削除する関数
  const handleDeleteConversionRule = (column, ruleId) => {
    setCategories((prevCategories) => {
      const existingRules =
        (prevCategories[selectedCategory] &&
          prevCategories[selectedCategory].columns[column] &&
          prevCategories[selectedCategory].columns[column].conversionRules) ||
        [];
      return {
        ...prevCategories,
        [selectedCategory]: {
          ...prevCategories[selectedCategory],
          columns: {
            ...prevCategories[selectedCategory].columns,
            [column]: {
              ...prevCategories[selectedCategory].columns[column],
              conversionRules: existingRules.filter(
                (rule) => rule.id !== ruleId
              ),
            },
          },
        },
      };
    });
    setSnackbar({ open: true, message: '変換ルールを削除しました', severity: 'info' });
  };

  // 変換ルールを並べ替える関数
  const handleRulesDragEnd = (result) => {
    if (!result.destination) return;
    const sourceIndex = result.source.index;
    const destinationIndex = result.destination.index;

    setCategories((prevCategories) => {
      const conversionRules = Array.from(
        prevCategories[selectedCategory].columns[currentColumn].conversionRules
      );
      const [removed] = conversionRules.splice(sourceIndex, 1);
      conversionRules.splice(destinationIndex, 0, removed);

      return {
        ...prevCategories,
        [selectedCategory]: {
          ...prevCategories[selectedCategory],
          columns: {
            ...prevCategories[selectedCategory].columns,
            [currentColumn]: {
              ...prevCategories[selectedCategory].columns[currentColumn],
              conversionRules: conversionRules,
            },
          },
        },
      };
    });
  };

  // AI生成コンテンツを抽出する関数
  const extractAIGeneratedContent = (description) => {
    if (!description) return '';
    const regex = /<div id="ai-generated-content">(.*?)<\/div>/is;
    const match = description.match(regex);
    if (match && match[1]) {
      const tempDiv = document.createElement('div');
      tempDiv.innerHTML = match[1];
      return tempDiv.textContent || tempDiv.innerText || '';
    }
    return '';
  };

  // 商品説明からキーと値のペアを抽出する関数
  const extractKeyValuePairs = (htmlContent) => {
    const keyValuePairs = {};
    if (!htmlContent) return keyValuePairs;

    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, 'text/html');

    const liElements = doc.querySelectorAll('li');

    liElements.forEach((li) => {
      let text = li.textContent.trim();

      text = text
        .replace(/^\s*[-•✓]+[\s]*/, '')
        .replace(/\*\*/g, '')
        .replace(/\s+/g, ' ');

      const colonIndex = text.indexOf(':');
      if (colonIndex > -1) {
        let key = text.substring(0, colonIndex).trim();
        const value = text.substring(colonIndex + 1).trim();

        key = key.replace(/^\*+/, '').replace(/\*+$/, '');

        if (key && value) {
          keyValuePairs[key] = value;
        }
      }
    });

    return keyValuePairs;
  };

  // 値を64文字以内に調整し、単語が途中で切れないようにする関数
  const truncateValueByWords = (value, maxLength) => {
    if (value.length <= maxLength) return value;

    const words = value.split(' ');
    let truncatedValue = '';
    for (let i = 0; i < words.length; i++) {
      const word = words[i];
      const separator = truncatedValue ? ' ' : '';
      if ((truncatedValue + separator + word).length > maxLength) {
        break;
      }
      truncatedValue += separator + word;
    }

    return truncatedValue;
  };

  // データにItem Specificsを適用する関数
  const handleApplySpecifics = () => {
    if (!selectedCategory) {
      setSnackbar({ open: true, message: '適用するカテゴリーを選択してください。', severity: 'warning' });
      return;
    }

    if (selectedColumns.length === 0) {
      setSnackbar({ open: true, message: '適用するカラムを選択してください。', severity: 'warning' });
      return;
    }

    setLoading(true);

    try {
      const titleKey = headers.find((header) => header.toLowerCase() === 'title');
      const descriptionKey = headers.find((header) => header.toLowerCase() === 'description');

      if (!titleKey && (matchSource === 'title' || matchSource === 'both')) {
        throw new Error('「Title」カラムが見つかりません。');
      }

      if (!descriptionKey && (matchSource === 'description' || matchSource === 'both')) {
        throw new Error('「Description」カラムが見つかりません。');
      }

      const newData = data.map((item) => {
        const title = item[titleKey] || '';
        let description = item[descriptionKey] || '';
        let sourceText = '';

        // AI生成コンテンツを抽出
        const aiContent = extractAIGeneratedContent(description);
        const keyValuePairs = extractKeyValuePairs(aiContent);

        // マッチングソースに応じてテキストを設定
        if (matchSource === 'title') {
          sourceText = title;
        } else if (matchSource === 'description') {
          sourceText = aiContent;
        } else if (matchSource === 'both') {
          sourceText = `${title} ${aiContent}`;
        }

        const newItem = { ...item };

        // 更新されたカラムを追跡
        const updatedColumns = new Set();

        // 商品説明から抽出したキーと値を適用
        selectedColumns.forEach((column) => {
          const columnKey = column.startsWith('C:') ? column.slice(2) : column;
          const aiValue = keyValuePairs[columnKey];
          if (aiValue) {
            // 値を64文字以内に調整し、単語が途中で切れないようにする
            const truncatedValue = truncateValueByWords(aiValue, 64);
            newItem[column] = truncatedValue;
            updatedColumns.add(column);
          }
        });

        // まだ更新されていないカラムに対して既存のロジックを適用
        const remainingColumns = selectedColumns.filter((column) => !updatedColumns.has(column));

        remainingColumns.forEach((column) => {
          const columnData = categories[selectedCategory].columns[column];
          const conversionRules = (columnData && columnData.conversionRules) || [];
          const values = (columnData && columnData.values) || [];

          // 優先順位に基づいてマッチングするために、変換ルールと値を一つの配列に統合
          const matchingItems = [
            ...conversionRules.map((rule) => ({ type: 'rule', keyword: rule.keyword, value: rule.value })),
            ...values.map((value) => ({ type: 'value', keyword: value, value })),
          ];

          const matchedValues = new Set();

          // 正規表現の特殊文字をエスケープする関数
          const escapeRegExp = (string) => {
            return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
          };

          // マッチング処理
          for (const item of matchingItems) {
            const { keyword, value } = item;
            if (!keyword || !value) continue;

            const flags = caseSensitive ? 'g' : 'gi';
            const escapedKeyword = escapeRegExp(keyword);
            const regex = new RegExp(`\\b${escapedKeyword}\\b`, flags);

            const matchCondition = partialMatch
              ? regex.test(sourceText)
              : caseSensitive
              ? sourceText === keyword
              : sourceText.toLowerCase() === keyword.toLowerCase();

            if (matchCondition) {
              matchedValues.add(value);
              if (matchingOption === 'first') break;
              if (matchingOption === 'priority') {
                newItem[column] = value;
                break;
              }
            }
          }

          // マッチした値を適用
          if (matchedValues.size > 0) {
            if (matchingOption === 'first' || matchingOption === 'priority') {
              newItem[column] = matchedValues.values().next().value;
            } else if (matchingOption === 'all') {
              newItem[column] = Array.from(matchedValues).join(', ');
            }
          }
          // マッチングが成功しなかった場合は、既存の値を保持する
        });

        return newItem;
      });

      setUpdatedData(newData);

      // プレビュー用データを作成（上位10件のみ）
      const previewItems = newData.slice(0, 10).map((item, index) => {
        const originalItem = data[index];
        const previewItem = { Title: item[titleKey] || '' };

        if (matchSource !== 'title') {
          previewItem.Description = item[descriptionKey] || '';
          // AI生成部分のみを抽出してプレビューに表示
          previewItem['AI Generated Content'] = extractAIGeneratedContent(item[descriptionKey]);
        }

        selectedColumns.forEach((column) => {
          previewItem[`${column} (Before)`] = originalItem[column] || '';
          previewItem[`${column} (After)`] = item[column] || '';
        });

        return previewItem;
      });

      setPreviewData(previewItems);
      setPreviewOpen(true);
    } catch (error) {
      console.error('データの更新中にエラーが発生しました:', error);
      setSnackbar({ open: true, message: `データの更新中にエラーが発生しました: ${error.message}`, severity: 'error' });
    } finally {
      setLoading(false);
    }
  };

  // 更新を確定する関数
  const confirmUpdate = () => {
    if (updatedData.length === 0) {
      setSnackbar({ open: true, message: '更新データが存在しません。', severity: 'warning' });
      return;
    }

    setData(updatedData);
    setSnackbar({ open: true, message: 'データが正常に更新されました。', severity: 'success' });
    setPreviewOpen(false);
  };

  // 大文字小文字区別の切り替え
  const handleCaseSensitiveToggle = (event) => {
    setCaseSensitive(event.target.checked);
  };

  // 部分一致の切り替え
  const handlePartialMatchToggle = (event) => {
    setPartialMatch(event.target.checked);
  };

  // マッチングソースの変更
  const handleMatchSourceChange = (event) => {
    setMatchSource(event.target.value);
  };

  // マッチングの動作を選択する関数
  const handleMatchingOptionChange = (event) => {
    setMatchingOption(event.target.value);
  };

  // ヘッダーからC:で始まるカラム名を抽出
  const cColumns = Array.isArray(headers)
    ? headers.filter((header) => header.startsWith('C:'))
    : [];

  return (
    <div style={{ display: 'flex' }}>
      {/* サイドバー */}
      <div style={{ width: '250px', padding: '16px', backgroundColor: '#f5f5f5' }}>
        <Typography variant="h5" gutterBottom>
          Item Specifics
        </Typography>
        <Button
          variant={activeView === 'manage' ? 'contained' : 'text'}
          startIcon={<SettingsIcon />}
          onClick={() => setActiveView('manage')}
          fullWidth
        >
          設定を管理
        </Button>
        <Button
          variant={activeView === 'apply' ? 'contained' : 'text'}
          startIcon={<ChevronRightIcon />}
          onClick={() => setActiveView('apply')}
          fullWidth
        >
          項目を適用
        </Button>
        <Typography variant="subtitle1" style={{ marginTop: '16px' }}>
          カテゴリー
        </Typography>
        <List>
          {Object.keys(categories).map((category) => (
            <ListItem
              button
              selected={selectedCategory === category}
              onClick={() => handleSelectCategory(category)}
              key={category}
            >
              <ListItemText primary={category} />
            </ListItem>
          ))}
        </List>
      </div>

      {/* メインコンテンツ */}
      <div style={{ flex: 1, padding: '16px', overflowY: 'auto' }}>
        {activeView === 'manage' ? (
          <Card>
            <CardHeader
              title="Item Specifics を管理"
              subheader="カテゴリー、カラム、値を追加・管理します。"
            />
            <CardContent>
              {/* カテゴリー追加 */}
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '16px' }}>
                <TextField
                  label="新しいカテゴリー"
                  value={newCategory}
                  onChange={(e) => setNewCategory(e.target.value)}
                />
                <Button
                  variant="contained"
                  startIcon={<AddIcon />}
                  onClick={handleAddCategory}
                  style={{ marginLeft: '8px' }}
                >
                  カテゴリーを追加
                </Button>
                {selectedCategory && (
                  <>
                    <Button
                      variant="outlined"
                      startIcon={<DeleteIcon />}
                      onClick={handleDeleteCategory}
                      color="error"
                      style={{ marginLeft: '8px' }}
                    >
                      カテゴリーを削除
                    </Button>
                    <Button
                      variant="outlined"
                      startIcon={<CopyIcon />}
                      onClick={() => setDuplicateDialogOpen(true)}
                      style={{ marginLeft: '8px' }}
                    >
                      設定を複製
                    </Button>
                  </>
                )}
              </div>
              {/* カラムや値の管理 */}
              {selectedCategory && (
                <div>
                  {/* カラム追加 */}
                  <div style={{ display: 'flex', alignItems: 'center', marginBottom: '16px' }}>
                    <Autocomplete
                      freeSolo
                      options={cColumns}
                      inputValue={newColumn}
                      onInputChange={(event, newInputValue) => {
                        setNewColumn(newInputValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="新しいカラム (例: C:Brand)"
                        />
                      )}
                      style={{ minWidth: '200px' }}
                    />
                    <Button
                      variant="contained"
                      startIcon={<AddIcon />}
                      onClick={handleAddColumn}
                      style={{ marginLeft: '8px' }}
                    >
                      カラムを追加
                    </Button>
                    {/* 検索フィールド */}
                    <TextField
                      label="検索"
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                      style={{ marginLeft: 'auto' }}
                    />
                  </div>
                  {/* カラムリスト */}
                  {Object.entries(categories[selectedCategory].columns)
                    .filter(([column]) =>
                      column.toLowerCase().includes(search.toLowerCase())
                    )
                    .map(([column, columnData]) => (
                      <Card key={column} style={{ marginBottom: '16px' }}>
                        <CardContent>
                          {/* ヘッダーエリア */}
                          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            {/* 左側：カラム名 */}
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <Typography variant="subtitle1">{column}</Typography>
                            </div>
                            {/* 右側：設定と削除ボタン */}
                            <div>
                              <IconButton
                                onClick={() => {
                                  setCurrentColumn(column);
                                  setIsConversionRuleDialogOpen(true);
                                }}
                              >
                                <SettingsIcon />
                              </IconButton>
                              <IconButton
                                onClick={() => handleDeleteColumn(column)}
                              >
                                <DeleteIcon color="error" />
                              </IconButton>
                            </div>
                          </div>
                          {/* 値のリストをSortableListで表示 */}
                          <SortableList
                            items={columnData.values.map((value, index) => ({
                              id: value,
                              value: value,
                            }))}
                            onDragEnd={(result) => handleValuesDragEnd(result, column)}
                            onDelete={(index) => handleDeleteValue(column, index)}
                            onAddItem={(value) => handleAddValue(column, value)}
                            isChip={true}
                          />
                        </CardContent>
                      </Card>
                    ))}
                </div>
              )}
            </CardContent>
            <CardActions>
              <Button
                variant="contained"
                startIcon={<SaveIcon />}
                onClick={handleSaveSettings}
              >
                設定を保存
              </Button>
            </CardActions>
          </Card>
        ) : (
          <Card>
            <CardHeader
              title="Item Specifics を適用"
              subheader="定義した項目を商品に適用します。"
            />
            <CardContent>
              {/* 適用するカラムを選択 */}
              {selectedCategory && (
                <div>
                  <FormControl fullWidth>
                    <InputLabel>適用するカラムを選択</InputLabel>
                    <Select
                      multiple
                      value={selectedColumns}
                      onChange={(e) => setSelectedColumns(e.target.value)}
                      label="適用するカラムを選択"
                      renderValue={(selected) => selected.join(', ')}
                    >
                      {Object.keys(categories[selectedCategory].columns).map((column) => (
                        <MenuItem key={column} value={column}>
                          <Checkbox checked={selectedColumns.indexOf(column) > -1} />
                          <ListItemText primary={column} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {/* マッチングソースの選択 */}
                  <div style={{ marginTop: '16px' }}>
                    <Typography variant="subtitle1" gutterBottom>
                      マッチングの基準を選択してください。
                    </Typography>
                    <FormControl component="fieldset">
                      <RadioGroup value={matchSource} onChange={handleMatchSourceChange}>
                        <FormControlLabel value="title" control={<Radio />} label="タイトルのみ" />
                        <FormControlLabel value="description" control={<Radio />} label="商品説明のみ (AI生成部分のみ)" />
                        <FormControlLabel value="both" control={<Radio />} label="タイトルと商品説明の両方 (AI生成部分のみ)" />
                      </RadioGroup>
                    </FormControl>
                  </div>
                  {/* オプション */}
                  <div style={{ marginTop: '16px' }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={caseSensitive}
                          onChange={handleCaseSensitiveToggle}
                          name="caseSensitive"
                        />
                      }
                      label="大文字小文字を区別する"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={partialMatch}
                          onChange={handlePartialMatchToggle}
                          name="partialMatch"
                        />
                      }
                      label="部分一致を許可する"
                    />
                  </div>
                  {/* マッチングの動作を選択 */}
                  <div style={{ marginTop: '16px' }}>
                    <Typography variant="subtitle1" gutterBottom>
                      マッチングの動作を選択してください。
                    </Typography>
                    <Typography variant="subtitle1" gutterBottom>
                      (上記でAI商品説明からマッチングを選択している場合は、自動処理でAI生成して文章からカラムの適所に適用されます。自動処理によって特に値の変更がなかった場合以下の設定が適用されます。)
                    </Typography>
                    <FormControl component="fieldset">
                      <RadioGroup value={matchingOption} onChange={handleMatchingOptionChange}>
                        <FormControlLabel value="priority" control={<Radio />} label="優先順位に基づいて適用" />
                        <FormControlLabel value="all" control={<Radio />} label="マッチしたすべての値を適用" />
                      </RadioGroup>
                    </FormControl>
                  </div>
                </div>
              )}
            </CardContent>
            <CardActions>
              <Button
                variant="outlined"
                startIcon={<RefreshIcon />}
                onClick={loadSettings}
              >
                データを再読み込み
              </Button>
              <Button
                variant="contained"
                startIcon={<SettingsIcon />}
                onClick={handleApplySpecifics}
              >
                項目を適用
              </Button>
            </CardActions>
          </Card>
        )}
      </div>
      {/* Snackbar */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>

      {/* 一括入力ダイアログ */}
      <Dialog
        open={isBulkInputOpen}
        onClose={() => setIsBulkInputOpen(false)}
        aria-labelledby="bulk-input-dialog-title"
        fullWidth
        maxWidth="md"
      >
        <DialogTitle id="bulk-input-dialog-title">{currentColumn} への一括入力</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            multiline
            rows={10}
            fullWidth
            variant="outlined"
            value={bulkInput}
            onChange={(e) => setBulkInput(e.target.value)}
            placeholder="値を入力してください（1行に1つ）"
            sx={{ mt: 2 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsBulkInputOpen(false)} color="primary">
            キャンセル
          </Button>
          <Button onClick={handleBulkInput} color="primary" variant="contained">
            追加する
          </Button>
        </DialogActions>
      </Dialog>

      {/* 変換ルール管理ダイアログ */}
      <Dialog
        open={isConversionRuleDialogOpen}
        onClose={() => setIsConversionRuleDialogOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>{currentColumn} の変換ルールの管理</DialogTitle>
        <DialogContent>
          <SortableList
            items={categories[selectedCategory]?.columns[currentColumn]?.conversionRules || []}
            onDragEnd={handleRulesDragEnd}
            onDelete={(id) => handleDeleteConversionRule(currentColumn, id)}
            onAddItem={(value) => handleAddConversionRule(currentColumn, value)}
            renderItem={(item, index) => (
              <ListItemText
                primary={`キーワード: ${item.keyword} (優先度: ${index + 1})`}
                secondary={`値: ${item.value}`}
              />
            )}
          />
          {/* 新しいルールの追加 */}
          <Box sx={{ mt: 2 }}>
            <TextField
              fullWidth
              label="キーワード"
              value={newRule.keyword}
              onChange={(e) => setNewRule({ ...newRule, keyword: e.target.value })}
              sx={{ mb: 2 }}
            />
            <TextField
              fullWidth
              label="値"
              value={newRule.value}
              onChange={(e) => setNewRule({ ...newRule, value: e.target.value })}
              sx={{ mb: 2 }}
            />
            <Button
              variant="contained"
              onClick={() => handleAddConversionRule(currentColumn, newRule)}
              disabled={!newRule.keyword || !newRule.value}
            >
              ルールを追加
            </Button>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsConversionRuleDialogOpen(false)}>閉じる</Button>
        </DialogActions>
      </Dialog>

      {/* 設定の複製ダイアログ */}
      <Dialog
        open={duplicateDialogOpen}
        onClose={() => setDuplicateDialogOpen(false)}
        aria-labelledby="duplicate-dialog-title"
      >
        <DialogTitle id="duplicate-dialog-title">他のカテゴリーに設定を複製する</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            label="複製先のカテゴリー名を入力"
            value={duplicateCategory}
            onChange={(e) => setDuplicateCategory(e.target.value)}
            variant="outlined"
            size="small"
            sx={{ mt: 2 }}
            placeholder="新しいカテゴリー名を入力"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDuplicateDialogOpen(false)} color="primary">
            キャンセル
          </Button>
          <Button onClick={handleDuplicateSettings} color="primary" variant="contained">
            複製する
          </Button>
        </DialogActions>
      </Dialog>

      {/* プレビューダイアログ */}
      <Dialog open={previewOpen} onClose={() => setPreviewOpen(false)} maxWidth="lg" fullWidth>
        <DialogTitle>更新プレビュー</DialogTitle>
        <DialogContent>
          <Typography variant="body2" paragraph>
            以下の内容で更新されます。確認してください。
          </Typography>
          <Box sx={{ maxHeight: 400, overflow: 'auto' }}>
            <table style={{ width: '100%', borderCollapse: 'collapse' }}>
              <thead>
                <tr>
                  {previewData[0] &&
                    Object.keys(previewData[0]).map((header, index) => (
                      <th
                        key={index}
                        style={{
                          border: '1px solid #ccc',
                          padding: '8px',
                          backgroundColor: '#f5f5f5',
                          position: 'sticky',
                          top: 0,
                        }}
                      >
                        {header}
                      </th>
                    ))}
                </tr>
              </thead>
              <tbody>
                {previewData.map((row, rowIndex) => (
                  <tr key={rowIndex}>
                    {Object.values(row).map((cell, cellIndex) => (
                      <td key={cellIndex} style={{ border: '1px solid #ccc', padding: '8px' }}>
                        {cell}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </Box>
          {updatedData.length > 10 && (
            <Typography variant="body2" sx={{ mt: 2 }}>
              (表示は上位10件のみです。実際の更新はすべてのデータに適用されます。)
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setPreviewOpen(false)} color="secondary">
            キャンセル
          </Button>
          <Button onClick={confirmUpdate} color="primary" variant="contained">
            更新を確定
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ItemSpecificsManager;






// // ItemSpecificsManager.js

// import React, { useState, useEffect } from 'react';
// import { getFirestore, doc, getDoc, setDoc } from 'firebase/firestore';
// import {
//   Button,
//   TextField,
//   Checkbox,
//   Card,
//   CardHeader,
//   CardContent,
//   CardActions,
//   Typography,
//   Dialog,
//   DialogTitle,
//   DialogContent,
//   DialogActions,
//   IconButton,
//   InputLabel,
//   Select,
//   MenuItem,
//   FormControl,
//   Snackbar,
//   Alert,
//   FormControlLabel,
//   RadioGroup,
//   Radio,
//   Autocomplete,
//   Box,
//   ListItemSecondaryAction,
//   List,             // 追加
//   ListItem,         // 追加
//   ListItemText,     // 追加
// } from '@mui/material';
// import {
//   Add as AddIcon,
//   Save as SaveIcon,
//   Delete as DeleteIcon,
//   Settings as SettingsIcon,
//   Refresh as RefreshIcon,
//   ChevronRight as ChevronRightIcon,
//   ContentCopy as CopyIcon,
// } from '@mui/icons-material';
// import { nanoid } from 'nanoid';
// import SortableList from './SortableList';

// function ItemSpecificsManager({ userId, headers, data, setData }) {
//   const [categories, setCategories] = useState({});
//   const [selectedCategory, setSelectedCategory] = useState('');
//   const [newCategory, setNewCategory] = useState('');
//   const [newColumn, setNewColumn] = useState('');
//   const [selectedColumns, setSelectedColumns] = useState([]);
//   const [activeView, setActiveView] = useState('manage');
//   const [duplicateCategory, setDuplicateCategory] = useState('');
//   const [isConversionRuleDialogOpen, setIsConversionRuleDialogOpen] = useState(false);
//   const [currentColumn, setCurrentColumn] = useState('');
//   const [newRule, setNewRule] = useState({ keyword: '', value: '' });
//   const [search, setSearch] = useState('');
//   const [bulkInput, setBulkInput] = useState('');
//   const [isBulkInputOpen, setIsBulkInputOpen] = useState(false);
//   const [duplicateDialogOpen, setDuplicateDialogOpen] = useState(false);
//   const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
//   const [loading, setLoading] = useState(false);
//   const [previewData, setPreviewData] = useState([]);
//   const [previewOpen, setPreviewOpen] = useState(false);
//   const [updatedData, setUpdatedData] = useState([]);
//   const [caseSensitive, setCaseSensitive] = useState(false);
//   const [partialMatch, setPartialMatch] = useState(true);
//   const [matchSource, setMatchSource] = useState('title');
//   const [matchingOption, setMatchingOption] = useState('first');

//   const db = getFirestore();

//   // Firestoreからデータを読み込む関数
//   const loadSettings = async () => {
//     if (!userId) return;
//     try {
//       const docRef = doc(db, 'itemSpecificsSettings', userId);
//       const docSnap = await getDoc(docRef);

//       if (docSnap.exists()) {
//         const userSettings = docSnap.data();
//         setCategories(userSettings.categories || {});
//         setSnackbar({ open: true, message: '設定を読み込みました。', severity: 'success' });
//       } else {
//         setCategories({});
//         setSnackbar({ open: true, message: '設定が見つかりません。', severity: 'info' });
//       }
//     } catch (error) {
//       console.error('設定の読み込みに失敗しました:', error);
//       setSnackbar({ open: true, message: '設定の読み込みに失敗しました。', severity: 'error' });
//     }
//   };

//   useEffect(() => {
//     loadSettings();
//   }, [userId]);

//   // 設定を保存する関数
//   const handleSaveSettings = async () => {
//     if (!userId) return;
//     const settings = {
//       categories,
//     };
//     try {
//       const docRef = doc(db, 'itemSpecificsSettings', userId);
//       await setDoc(docRef, settings);
//       setSnackbar({ open: true, message: '設定を保存しました。', severity: 'success' });
//     } catch (error) {
//       console.error('設定の保存に失敗しました:', error);
//       setSnackbar({ open: true, message: '設定の保存に失敗しました。', severity: 'error' });
//     }
//   };

//   // カテゴリーを追加する関数
//   const handleAddCategory = () => {
//     if (newCategory && !categories[newCategory]) {
//       setCategories({ ...categories, [newCategory]: { columns: {} } });
//       setSelectedCategory(newCategory);
//       setNewCategory('');
//       setSnackbar({ open: true, message: `カテゴリー "${newCategory}" を追加しました`, severity: 'success' });
//     } else if (categories[newCategory]) {
//       setSnackbar({ open: true, message: `カテゴリー "${newCategory}" は既に存在します`, severity: 'warning' });
//     } else {
//       setSnackbar({ open: true, message: '有効なカテゴリー名を入力してください', severity: 'error' });
//     }
//   };

//   // カテゴリーを選択する関数
//   const handleSelectCategory = (category) => {
//     setSelectedCategory(category);
//   };

//   // カラムを追加する関数
//   const handleAddColumn = () => {
//     const lowerCaseNewColumn = newColumn.toLowerCase();
//     if (
//       newColumn &&
//       selectedCategory &&
//       !Object.keys(categories[selectedCategory].columns || {}).some(
//         (col) => col.toLowerCase() === lowerCaseNewColumn
//       )
//     ) {
//       setCategories({
//         ...categories,
//         [selectedCategory]: {
//           ...categories[selectedCategory],
//           columns: {
//             ...categories[selectedCategory].columns,
//             [newColumn]: { values: [], conversionRules: [] },
//           },
//         },
//       });
//       setNewColumn('');
//       setSnackbar({ open: true, message: `カラム "${newColumn}" を追加しました`, severity: 'success' });
//     } else if (
//       Object.keys(categories[selectedCategory].columns || {}).some(
//         (col) => col.toLowerCase() === lowerCaseNewColumn
//       )
//     ) {
//       setSnackbar({ open: true, message: `カラム "${newColumn}" は既に存在します`, severity: 'warning' });
//     } else {
//       setSnackbar({ open: true, message: '有効なカラム名を入力してください', severity: 'error' });
//     }
//   };

//   // カテゴリーを削除する関数
//   const handleDeleteCategory = () => {
//     const updatedCategories = { ...categories };
//     delete updatedCategories[selectedCategory];
//     setCategories(updatedCategories);
//     setSelectedCategory('');
//     setSnackbar({ open: true, message: `カテゴリー "${selectedCategory}" を削除しました`, severity: 'info' });
//   };

//   // 設定を複製する関数
//   const handleDuplicateSettings = () => {
//     if (!selectedCategory || !categories[selectedCategory]) {
//       setSnackbar({
//         open: true,
//         message: '複製元のカテゴリーを選択してください',
//         severity: 'error',
//       });
//       setDuplicateDialogOpen(false);
//       return;
//     }

//     if (!duplicateCategory) {
//       setSnackbar({
//         open: true,
//         message: '有効な複製先のカテゴリー名を入力してください',
//         severity: 'error',
//       });
//       return;
//     }

//     if (categories[duplicateCategory]) {
//       setSnackbar({
//         open: true,
//         message: `カテゴリー "${duplicateCategory}" は既に存在します`,
//         severity: 'warning',
//       });
//       return;
//     }

//     const newCategoryData = JSON.parse(JSON.stringify(categories[selectedCategory]));

//     setCategories((prevCategories) => ({
//       ...prevCategories,
//       [duplicateCategory]: newCategoryData,
//     }));
//     setSnackbar({
//       open: true,
//       message: `設定が "${duplicateCategory}" に複製されました`,
//       severity: 'success',
//     });
//     setDuplicateDialogOpen(false);
//     setDuplicateCategory('');
//   };

//   // カラムを削除する関数
//   const handleDeleteColumn = (column) => {
//     const updatedColumns = { ...categories[selectedCategory].columns };
//     delete updatedColumns[column];
//     setCategories({
//       ...categories,
//       [selectedCategory]: {
//         ...categories[selectedCategory],
//         columns: updatedColumns,
//       },
//     });
//     setSnackbar({ open: true, message: `カラム "${column}" を削除しました`, severity: 'info' });
//   };

//   // 値を追加する関数
//   const handleAddValue = (column, value) => {
//     if (value && selectedCategory) {
//       setCategories((prevCategories) => {
//         const existingValues =
//           (prevCategories[selectedCategory] &&
//             prevCategories[selectedCategory].columns[column] &&
//             prevCategories[selectedCategory].columns[column].values) ||
//           [];
//         const lowerCaseValues = existingValues.map((v) => v.toLowerCase());
//         if (!lowerCaseValues.includes(value.toLowerCase())) {
//           return {
//             ...prevCategories,
//             [selectedCategory]: {
//               ...prevCategories[selectedCategory],
//               columns: {
//                 ...prevCategories[selectedCategory].columns,
//                 [column]: {
//                   ...prevCategories[selectedCategory].columns[column],
//                   values: [...existingValues, value],
//                   conversionRules:
//                     (prevCategories[selectedCategory].columns[column] &&
//                       prevCategories[selectedCategory].columns[column].conversionRules) ||
//                     [],
//                 },
//               },
//             },
//           };
//         } else {
//           setSnackbar({ open: true, message: `値 "${value}" は既に存在します`, severity: 'warning' });
//           return prevCategories;
//         }
//       });
//     } else {
//       setSnackbar({ open: true, message: '値を入力してください', severity: 'error' });
//     }
//   };

//   // 値を削除する関数
//   const handleDeleteValue = (column, index) => {
//     setCategories((prevCategories) => {
//       const existingValues =
//         (prevCategories[selectedCategory] &&
//           prevCategories[selectedCategory].columns[column] &&
//           prevCategories[selectedCategory].columns[column].values) ||
//         [];
//       return {
//         ...prevCategories,
//         [selectedCategory]: {
//           ...prevCategories[selectedCategory],
//           columns: {
//             ...prevCategories[selectedCategory].columns,
//             [column]: {
//               ...prevCategories[selectedCategory].columns[column],
//               values: existingValues.filter((_, i) => i !== index),
//             },
//           },
//         },
//       };
//     });
//     setSnackbar({ open: true, message: `値を削除しました`, severity: 'info' });
//   };

//   // 値のリストを並べ替える関数
//   const handleValuesDragEnd = (result, column) => {
//     if (!result.destination) return;
//     const sourceIndex = result.source.index;
//     const destinationIndex = result.destination.index;

//     setCategories((prevCategories) => {
//       const columnValues = Array.from(prevCategories[selectedCategory].columns[column].values);
//       const [removed] = columnValues.splice(sourceIndex, 1);
//       columnValues.splice(destinationIndex, 0, removed);

//       return {
//         ...prevCategories,
//         [selectedCategory]: {
//           ...prevCategories[selectedCategory],
//           columns: {
//             ...prevCategories[selectedCategory].columns,
//             [column]: {
//               ...prevCategories[selectedCategory].columns[column],
//               values: columnValues,
//             },
//           },
//         },
//       };
//     });
//   };

//   // 一括入力を処理する関数
//   const handleBulkInput = () => {
//     const values = bulkInput.split('\n').map((v) => v.trim()).filter((v) => v);
//     setCategories((prevCategories) => {
//       const existingValues =
//         (prevCategories[selectedCategory] &&
//           prevCategories[selectedCategory].columns[currentColumn] &&
//           prevCategories[selectedCategory].columns[currentColumn].values) ||
//         [];
//       return {
//         ...prevCategories,
//         [selectedCategory]: {
//           ...prevCategories[selectedCategory],
//           columns: {
//             ...prevCategories[selectedCategory].columns,
//             [currentColumn]: {
//               ...prevCategories[selectedCategory].columns[currentColumn],
//               values: [...existingValues, ...values],
//             },
//           },
//         },
//       };
//     });
//     setBulkInput('');
//     setIsBulkInputOpen(false);
//     setSnackbar({ open: true, message: `${values.length} 件の値を追加しました`, severity: 'success' });
//   };

//   // 変換ルールを追加する関数
//   const handleAddConversionRule = (column, rule) => {
//     const newId = nanoid();
//     setCategories((prevCategories) => {
//       const existingRules =
//         (prevCategories[selectedCategory] &&
//           prevCategories[selectedCategory].columns[column] &&
//           prevCategories[selectedCategory].columns[column].conversionRules) ||
//         [];
//       return {
//         ...prevCategories,
//         [selectedCategory]: {
//           ...prevCategories[selectedCategory],
//           columns: {
//             ...prevCategories[selectedCategory].columns,
//             [column]: {
//               ...prevCategories[selectedCategory].columns[column],
//               conversionRules: [
//                 ...existingRules,
//                 { id: newId, ...rule },
//               ],
//             },
//           },
//         },
//       };
//     });
//     setSnackbar({ open: true, message: '変換ルールを追加しました', severity: 'success' });
//   };

//   // 変換ルールを削除する関数
//   const handleDeleteConversionRule = (column, ruleId) => {
//     setCategories((prevCategories) => {
//       const existingRules =
//         (prevCategories[selectedCategory] &&
//           prevCategories[selectedCategory].columns[column] &&
//           prevCategories[selectedCategory].columns[column].conversionRules) ||
//         [];
//       return {
//         ...prevCategories,
//         [selectedCategory]: {
//           ...prevCategories[selectedCategory],
//           columns: {
//             ...prevCategories[selectedCategory].columns,
//             [column]: {
//               ...prevCategories[selectedCategory].columns[column],
//               conversionRules: existingRules.filter(
//                 (rule) => rule.id !== ruleId
//               ),
//             },
//           },
//         },
//       };
//     });
//     setSnackbar({ open: true, message: '変換ルールを削除しました', severity: 'info' });
//   };

//   // 変換ルールを並べ替える関数
//   const handleRulesDragEnd = (result) => {
//     if (!result.destination) return;
//     const sourceIndex = result.source.index;
//     const destinationIndex = result.destination.index;

//     setCategories((prevCategories) => {
//       const conversionRules = Array.from(
//         prevCategories[selectedCategory].columns[currentColumn].conversionRules
//       );
//       const [removed] = conversionRules.splice(sourceIndex, 1);
//       conversionRules.splice(destinationIndex, 0, removed);

//       return {
//         ...prevCategories,
//         [selectedCategory]: {
//           ...prevCategories[selectedCategory],
//           columns: {
//             ...prevCategories[selectedCategory].columns,
//             [currentColumn]: {
//               ...prevCategories[selectedCategory].columns[currentColumn],
//               conversionRules: conversionRules,
//             },
//           },
//         },
//       };
//     });
//   };

//   // DescriptionからAI生成部分を抽出する関数
//   const extractAIGeneratedContent = (description) => {
//     if (!description) return '';
//     const regex = /<div id="ai-generated-content">(.*?)<\/div>/is;
//     const match = description.match(regex);
//     if (match && match[1]) {
//       // マッチした部分のHTMLタグを除去してテキストのみを取得
//       const tempDiv = document.createElement('div');
//       tempDiv.innerHTML = match[1];
//       return tempDiv.textContent || tempDiv.innerText || '';
//     }
//     return '';
//   };

//   // データにItem Specificsを適用する関数
//   const handleApplySpecifics = () => {
//     if (!selectedCategory) {
//       setSnackbar({ open: true, message: '適用するカテゴリーを選択してください。', severity: 'warning' });
//       return;
//     }

//     if (selectedColumns.length === 0) {
//       setSnackbar({ open: true, message: '適用するカラムを選択してください。', severity: 'warning' });
//       return;
//     }

//     setLoading(true);

//     try {
//       const titleKey = headers.find((header) => header.toLowerCase() === 'title');
//       const descriptionKey = headers.find((header) => header.toLowerCase() === 'description');

//       if (!titleKey && (matchSource === 'title' || matchSource === 'both')) {
//         throw new Error('「Title」カラムが見つかりません。');
//       }

//       if (!descriptionKey && (matchSource === 'description' || matchSource === 'both')) {
//         throw new Error('「Description」カラムが見つかりません。');
//       }

//       const newData = data.map((item) => {
//         const title = item[titleKey] || '';
//         let description = item[descriptionKey] || '';
//         let sourceText = '';

//         // マッチングソースに応じてテキストを設定
//         if (matchSource === 'title') {
//           sourceText = title;
//         } else if (matchSource === 'description') {
//           // DescriptionからAI生成部分を抽出
//           const aiContent = extractAIGeneratedContent(description);
//           sourceText = aiContent;
//         } else if (matchSource === 'both') {
//           // DescriptionからAI生成部分を抽出
//           const aiContent = extractAIGeneratedContent(description);
//           sourceText = `${title} ${aiContent}`;
//         }

//         const newItem = { ...item };

//         selectedColumns.forEach((column) => {
//           const columnData = categories[selectedCategory].columns[column];
//           const conversionRules = (columnData && columnData.conversionRules) || [];
//           const values = (columnData && columnData.values) || [];

//           // 優先順位に基づいてマッチングするために、変換ルールと値を一つの配列に統合
//           const matchingItems = [
//             ...conversionRules.map((rule) => ({ type: 'rule', keyword: rule.keyword, value: rule.value })),
//             ...values.map((value) => ({ type: 'value', keyword: value, value })),
//           ];

//           // 順序はドラッグ＆ドロップで並べ替えた順序に基づく

//           const matchedValues = new Set();

//           // 正規表現の特殊文字をエスケープする関数
//           const escapeRegExp = (string) => {
//             return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
//           };

//           // マッチング処理
//           for (const item of matchingItems) {
//             const { keyword, value } = item;
//             if (!keyword || !value) continue;

//             const flags = caseSensitive ? 'g' : 'gi';
//             const escapedKeyword = escapeRegExp(keyword);
//             const regex = new RegExp(`\\b${escapedKeyword}\\b`, flags);

//             const matchCondition = partialMatch
//               ? regex.test(sourceText)
//               : caseSensitive
//               ? sourceText === keyword
//               : sourceText.toLowerCase() === keyword.toLowerCase();

//             if (matchCondition) {
//               matchedValues.add(value);
//               if (matchingOption === 'first') break;
//               if (matchingOption === 'priority') {
//                 newItem[column] = value;
//                 break;
//               }
//             }
//           }

//           // マッチした値を適用
//           if (matchedValues.size > 0) {
//             if (matchingOption === 'first' || matchingOption === 'priority') {
//               newItem[column] = matchedValues.values().next().value;
//             } else if (matchingOption === 'all') {
//               newItem[column] = Array.from(matchedValues).join(', ');
//             }
//           }
//         });

//         return newItem;
//       });

//       setUpdatedData(newData);

//       // プレビュー用データを作成（上位10件のみ）
//       const previewItems = newData.slice(0, 10).map((item, index) => {
//         const originalItem = data[index];
//         const previewItem = { Title: item[titleKey] || '' };

//         if (matchSource !== 'title') {
//           previewItem.Description = item[descriptionKey] || '';
//           // AI生成部分のみを抽出してプレビューに表示
//           previewItem['AI Generated Content'] = extractAIGeneratedContent(item[descriptionKey]);
//         }

//         selectedColumns.forEach((column) => {
//           previewItem[`${column} (Before)`] = originalItem[column] || '';
//           previewItem[`${column} (After)`] = item[column] || '';
//         });

//         return previewItem;
//       });

//       setPreviewData(previewItems);
//       setPreviewOpen(true);
//     } catch (error) {
//       console.error('データの更新中にエラーが発生しました:', error);
//       setSnackbar({ open: true, message: `データの更新中にエラーが発生しました: ${error.message}`, severity: 'error' });
//     } finally {
//       setLoading(false);
//     }
//   };

//   // 更新を確定する関数
//   const confirmUpdate = () => {
//     if (updatedData.length === 0) {
//       setSnackbar({ open: true, message: '更新データが存在しません。', severity: 'warning' });
//       return;
//     }

//     setData(updatedData);
//     setSnackbar({ open: true, message: 'データが正常に更新されました。', severity: 'success' });
//     setPreviewOpen(false);
//   };

//   // 大文字小文字区別の切り替え
//   const handleCaseSensitiveToggle = (event) => {
//     setCaseSensitive(event.target.checked);
//   };

//   // 部分一致の切り替え
//   const handlePartialMatchToggle = (event) => {
//     setPartialMatch(event.target.checked);
//   };

//   // マッチングソースの変更
//   const handleMatchSourceChange = (event) => {
//     setMatchSource(event.target.value);
//   };

//   // マッチングの動作を選択する関数
//   const handleMatchingOptionChange = (event) => {
//     setMatchingOption(event.target.value);
//   };

//   // ヘッダーからC:で始まるカラム名を抽出
//   const cColumns = Array.isArray(headers)
//     ? headers.filter((header) => header.startsWith('C:'))
//     : [];

//   return (
//     <div style={{ display: 'flex' }}>
//       {/* サイドバー */}
//       <div style={{ width: '250px', padding: '16px', backgroundColor: '#f5f5f5' }}>
//         <Typography variant="h5" gutterBottom>
//           Item Specifics
//         </Typography>
//         <Button
//           variant={activeView === 'manage' ? 'contained' : 'text'}
//           startIcon={<SettingsIcon />}
//           onClick={() => setActiveView('manage')}
//           fullWidth
//         >
//           設定を管理
//         </Button>
//         <Button
//           variant={activeView === 'apply' ? 'contained' : 'text'}
//           startIcon={<ChevronRightIcon />}
//           onClick={() => setActiveView('apply')}
//           fullWidth
//         >
//           項目を適用
//         </Button>
//         <Typography variant="subtitle1" style={{ marginTop: '16px' }}>
//           カテゴリー
//         </Typography>
//         <List>
//           {Object.keys(categories).map((category) => (
//             <ListItem
//               button
//               selected={selectedCategory === category}
//               onClick={() => handleSelectCategory(category)}
//               key={category}
//             >
//               <ListItemText primary={category} />
//             </ListItem>
//           ))}
//         </List>
//       </div>

//       {/* メインコンテンツ */}
//       <div style={{ flex: 1, padding: '16px', overflowY: 'auto' }}>
//         {activeView === 'manage' ? (
//           <Card>
//             <CardHeader
//               title="Item Specifics を管理"
//               subheader="カテゴリー、カラム、値を追加・管理します。"
//             />
//             <CardContent>
//               {/* カテゴリー追加 */}
//               <div style={{ display: 'flex', alignItems: 'center', marginBottom: '16px' }}>
//                 <TextField
//                   label="新しいカテゴリー"
//                   value={newCategory}
//                   onChange={(e) => setNewCategory(e.target.value)}
//                 />
//                 <Button
//                   variant="contained"
//                   startIcon={<AddIcon />}
//                   onClick={handleAddCategory}
//                   style={{ marginLeft: '8px' }}
//                 >
//                   カテゴリーを追加
//                 </Button>
//                 {selectedCategory && (
//                   <>
//                     <Button
//                       variant="outlined"
//                       startIcon={<DeleteIcon />}
//                       onClick={handleDeleteCategory}
//                       color="error"
//                       style={{ marginLeft: '8px' }}
//                     >
//                       カテゴリーを削除
//                     </Button>
//                     <Button
//                       variant="outlined"
//                       startIcon={<CopyIcon />}
//                       onClick={() => setDuplicateDialogOpen(true)}
//                       style={{ marginLeft: '8px' }}
//                     >
//                       設定を複製
//                     </Button>
//                   </>
//                 )}
//               </div>
//               {/* カラムや値の管理 */}
//               {selectedCategory && (
//                 <div>
//                   {/* カラム追加 */}
//                   <div style={{ display: 'flex', alignItems: 'center', marginBottom: '16px' }}>
//                     <Autocomplete
//                       freeSolo
//                       options={cColumns}
//                       inputValue={newColumn}
//                       onInputChange={(event, newInputValue) => {
//                         setNewColumn(newInputValue);
//                       }}
//                       renderInput={(params) => (
//                         <TextField
//                           {...params}
//                           label="新しいカラム (例: C:Brand)"
//                         />
//                       )}
//                       style={{ minWidth: '200px' }}
//                     />
//                     <Button
//                       variant="contained"
//                       startIcon={<AddIcon />}
//                       onClick={handleAddColumn}
//                       style={{ marginLeft: '8px' }}
//                     >
//                       カラムを追加
//                     </Button>
//                     {/* 検索フィールド */}
//                     <TextField
//                       label="検索"
//                       value={search}
//                       onChange={(e) => setSearch(e.target.value)}
//                       style={{ marginLeft: 'auto' }}
//                     />
//                   </div>
//                   {/* カラムリスト */}
//                   {Object.entries(categories[selectedCategory].columns)
//                     .filter(([column]) =>
//                       column.toLowerCase().includes(search.toLowerCase())
//                     )
//                     .map(([column, columnData]) => (
//                       <Card key={column} style={{ marginBottom: '16px' }}>
//                         <CardContent>
//                           {/* ヘッダーエリア */}
//                           <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
//                             {/* 左側：カラム名 */}
//                             <div style={{ display: 'flex', alignItems: 'center' }}>
//                               <Typography variant="subtitle1">{column}</Typography>
//                             </div>
//                             {/* 右側：設定と削除ボタン */}
//                             <div>
//                               <IconButton
//                                 onClick={() => {
//                                   setCurrentColumn(column);
//                                   setIsConversionRuleDialogOpen(true);
//                                 }}
//                               >
//                                 <SettingsIcon />
//                               </IconButton>
//                               <IconButton
//                                 onClick={() => handleDeleteColumn(column)}
//                               >
//                                 <DeleteIcon color="error" />
//                               </IconButton>
//                             </div>
//                           </div>
//                           {/* 値のリストをSortableListで表示 */}
//                           <SortableList
//                             items={columnData.values.map((value, index) => ({
//                               id: value,
//                               value: value,
//                             }))}
//                             onDragEnd={(result) => handleValuesDragEnd(result, column)}
//                             onDelete={(index) => handleDeleteValue(column, index)}
//                             onAddItem={(value) => handleAddValue(column, value)}
//                             isChip={true}
//                           />
//                         </CardContent>
//                       </Card>
//                     ))}
//                 </div>
//               )}
//             </CardContent>
//             <CardActions>
//               <Button
//                 variant="contained"
//                 startIcon={<SaveIcon />}
//                 onClick={handleSaveSettings}
//               >
//                 設定を保存
//               </Button>
//             </CardActions>
//           </Card>
//         ) : (
//           <Card>
//             <CardHeader
//               title="Item Specifics を適用"
//               subheader="定義した項目を商品に適用します。"
//             />
//             <CardContent>
//               {/* 適用するカラムを選択 */}
//               {selectedCategory && (
//                 <div>
//                   <FormControl fullWidth>
//                     <InputLabel>適用するカラムを選択</InputLabel>
//                     <Select
//                       multiple
//                       value={selectedColumns}
//                       onChange={(e) => setSelectedColumns(e.target.value)}
//                       label="適用するカラムを選択"
//                       renderValue={(selected) => selected.join(', ')}
//                     >
//                       {Object.keys(categories[selectedCategory].columns).map((column) => (
//                         <MenuItem key={column} value={column}>
//                           <Checkbox checked={selectedColumns.indexOf(column) > -1} />
//                           <ListItemText primary={column} />
//                         </MenuItem>
//                       ))}
//                     </Select>
//                   </FormControl>
//                   {/* マッチングソースの選択 */}
//                   <div style={{ marginTop: '16px' }}>
//                     <Typography variant="subtitle1" gutterBottom>
//                       マッチングの基準を選択してください。
//                     </Typography>
//                     <FormControl component="fieldset">
//                       <RadioGroup value={matchSource} onChange={handleMatchSourceChange}>
//                         <FormControlLabel value="title" control={<Radio />} label="タイトルのみ" />
//                         <FormControlLabel value="description" control={<Radio />} label="商品説明のみ (AI生成部分のみ)" />
//                         <FormControlLabel value="both" control={<Radio />} label="タイトルと商品説明の両方 (AI生成部分のみ)" />
//                       </RadioGroup>
//                     </FormControl>
//                   </div>
//                   {/* オプション */}
//                   <div style={{ marginTop: '16px' }}>
//                     <FormControlLabel
//                       control={
//                         <Checkbox
//                           checked={caseSensitive}
//                           onChange={handleCaseSensitiveToggle}
//                           name="caseSensitive"
//                         />
//                       }
//                       label="大文字小文字を区別する"
//                     />
//                     <FormControlLabel
//                       control={
//                         <Checkbox
//                           checked={partialMatch}
//                           onChange={handlePartialMatchToggle}
//                           name="partialMatch"
//                         />
//                       }
//                       label="部分一致を許可する"
//                     />
//                   </div>
//                   {/* マッチングの動作を選択 */}
//                   <div style={{ marginTop: '16px' }}>
//                     <Typography variant="subtitle1" gutterBottom>
//                       マッチングの動作を選択してください。
//                     </Typography>
//                     <FormControl component="fieldset">
//                       <RadioGroup value={matchingOption} onChange={handleMatchingOptionChange}>
//                         <FormControlLabel value="first" control={<Radio />} label="マッチした最初の値のみを適用" />
//                         <FormControlLabel value="priority" control={<Radio />} label="優先順位に基づいて適用" />
//                         <FormControlLabel value="all" control={<Radio />} label="マッチしたすべての値を適用" />
//                       </RadioGroup>
//                     </FormControl>
//                   </div>
//                 </div>
//               )}
//             </CardContent>
//             <CardActions>
//               <Button
//                 variant="outlined"
//                 startIcon={<RefreshIcon />}
//                 onClick={loadSettings}
//               >
//                 データを再読み込み
//               </Button>
//               <Button
//                 variant="contained"
//                 startIcon={<SettingsIcon />}
//                 onClick={handleApplySpecifics}
//               >
//                 項目を適用
//               </Button>
//             </CardActions>
//           </Card>
//         )}
//       </div>
//       {/* Snackbar */}
//       <Snackbar
//         open={snackbar.open}
//         autoHideDuration={6000}
//         onClose={() => setSnackbar({ ...snackbar, open: false })}
//         anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
//       >
//         <Alert
//           onClose={() => setSnackbar({ ...snackbar, open: false })}
//           severity={snackbar.severity}
//           variant="filled"
//           sx={{ width: '100%' }}
//         >
//           {snackbar.message}
//         </Alert>
//       </Snackbar>

//       {/* 一括入力ダイアログ */}
//       <Dialog
//         open={isBulkInputOpen}
//         onClose={() => setIsBulkInputOpen(false)}
//         aria-labelledby="bulk-input-dialog-title"
//         fullWidth
//         maxWidth="md"
//       >
//         <DialogTitle id="bulk-input-dialog-title">{currentColumn} への一括入力</DialogTitle>
//         <DialogContent>
//           <TextField
//             autoFocus
//             multiline
//             rows={10}
//             fullWidth
//             variant="outlined"
//             value={bulkInput}
//             onChange={(e) => setBulkInput(e.target.value)}
//             placeholder="値を入力してください（1行に1つ）"
//             sx={{ mt: 2 }}
//           />
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => setIsBulkInputOpen(false)} color="primary">
//             キャンセル
//           </Button>
//           <Button onClick={handleBulkInput} color="primary" variant="contained">
//             追加する
//           </Button>
//         </DialogActions>
//       </Dialog>

//       {/* 変換ルール管理ダイアログ */}
//       <Dialog
//         open={isConversionRuleDialogOpen}
//         onClose={() => setIsConversionRuleDialogOpen(false)}
//         maxWidth="sm"
//         fullWidth
//       >
//         <DialogTitle>{currentColumn} の変換ルールの管理</DialogTitle>
//         <DialogContent>
//           <SortableList
//             items={categories[selectedCategory]?.columns[currentColumn]?.conversionRules || []}
//             onDragEnd={handleRulesDragEnd}
//             onDelete={(id) => handleDeleteConversionRule(currentColumn, id)}
//             onAddItem={(value) => handleAddConversionRule(currentColumn, value)}
//             renderItem={(item, index) => (
//               <ListItemText
//                 primary={`キーワード: ${item.keyword} (優先度: ${index + 1})`}
//                 secondary={`値: ${item.value}`}
//               />
//             )}
//           />
//           {/* 新しいルールの追加 */}
//           <Box sx={{ mt: 2 }}>
//             <TextField
//               fullWidth
//               label="キーワード"
//               value={newRule.keyword}
//               onChange={(e) => setNewRule({ ...newRule, keyword: e.target.value })}
//               sx={{ mb: 2 }}
//             />
//             <TextField
//               fullWidth
//               label="値"
//               value={newRule.value}
//               onChange={(e) => setNewRule({ ...newRule, value: e.target.value })}
//               sx={{ mb: 2 }}
//             />
//             <Button
//               variant="contained"
//               onClick={() => handleAddConversionRule(currentColumn, newRule)}
//               disabled={!newRule.keyword || !newRule.value}
//             >
//               ルールを追加
//             </Button>
//           </Box>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => setIsConversionRuleDialogOpen(false)}>閉じる</Button>
//         </DialogActions>
//       </Dialog>

//       {/* 設定の複製ダイアログ */}
//       <Dialog
//         open={duplicateDialogOpen}
//         onClose={() => setDuplicateDialogOpen(false)}
//         aria-labelledby="duplicate-dialog-title"
//       >
//         <DialogTitle id="duplicate-dialog-title">他のカテゴリーに設定を複製する</DialogTitle>
//         <DialogContent>
//           <TextField
//             fullWidth
//             label="複製先のカテゴリー名を入力"
//             value={duplicateCategory}
//             onChange={(e) => setDuplicateCategory(e.target.value)}
//             variant="outlined"
//             size="small"
//             sx={{ mt: 2 }}
//             placeholder="新しいカテゴリー名を入力"
//           />
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => setDuplicateDialogOpen(false)} color="primary">
//             キャンセル
//           </Button>
//           <Button onClick={handleDuplicateSettings} color="primary" variant="contained">
//             複製する
//           </Button>
//         </DialogActions>
//       </Dialog>

//       {/* プレビューダイアログ */}
//       <Dialog open={previewOpen} onClose={() => setPreviewOpen(false)} maxWidth="lg" fullWidth>
//         <DialogTitle>更新プレビュー</DialogTitle>
//         <DialogContent>
//           <Typography variant="body2" paragraph>
//             以下の内容で更新されます。確認してください。
//           </Typography>
//           <Box sx={{ maxHeight: 400, overflow: 'auto' }}>
//             <table style={{ width: '100%', borderCollapse: 'collapse' }}>
//               <thead>
//                 <tr>
//                   {previewData[0] &&
//                     Object.keys(previewData[0]).map((header, index) => (
//                       <th
//                         key={index}
//                         style={{
//                           border: '1px solid #ccc',
//                           padding: '8px',
//                           backgroundColor: '#f5f5f5',
//                           position: 'sticky',
//                           top: 0,
//                         }}
//                       >
//                         {header}
//                       </th>
//                     ))}
//                 </tr>
//               </thead>
//               <tbody>
//                 {previewData.map((row, rowIndex) => (
//                   <tr key={rowIndex}>
//                     {Object.values(row).map((cell, cellIndex) => (
//                       <td key={cellIndex} style={{ border: '1px solid #ccc', padding: '8px' }}>
//                         {cell}
//                       </td>
//                     ))}
//                   </tr>
//                 ))}
//               </tbody>
//             </table>
//           </Box>
//           {updatedData.length > 10 && (
//             <Typography variant="body2" sx={{ mt: 2 }}>
//               (表示は上位10件のみです。実際の更新はすべてのデータに適用されます。)
//             </Typography>
//           )}
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => setPreviewOpen(false)} color="secondary">
//             キャンセル
//           </Button>
//           <Button onClick={confirmUpdate} color="primary" variant="contained">
//             更新を確定
//           </Button>
//         </DialogActions>
//       </Dialog>
//     </div>
//   );
// }

// export default ItemSpecificsManager;