// ColumnSettingsManager.js

import React, { useState } from 'react';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import {
  Box,
  Button,
  TextField,
  Select,
  MenuItem,
  Typography,
  IconButton,
  Paper,
  Grid,
  InputLabel,
  FormControl,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  Save as SaveIcon,
  Check as CheckIcon,
  Close as CloseIcon,
} from '@mui/icons-material';

function ColumnSettingsManager({
  data,
  setData,
  columns,
  setColumns,
  columnOrderTemplates,
  setColumnOrderTemplates,
  applyColumnTemplate,
  selectedTemplateName,
  setSelectedTemplateName,
  setColumnOrder,
  columnWidth,
  setColumnWidth,
}) {
  // カラム順序テンプレート管理のための状態と関数
  const [newTemplateName, setNewTemplateName] = useState('');
  const [editTemplateName, setEditTemplateName] = useState('');
  const [editColumnOrder, setEditColumnOrder] = useState([]);

  // カラム名編集のための状態
  const [isEditingColumn, setIsEditingColumn] = useState(false);
  const [editingColumnIndex, setEditingColumnIndex] = useState(null);
  const [editingColumnName, setEditingColumnName] = useState('');

  // カラム名編集用のモーダル表示状態
  const [isEditColumnModalOpen, setIsEditColumnModalOpen] = useState(false);

  // テンプレートの追加
  const addTemplate = () => {
    if (newTemplateName && !columnOrderTemplates[newTemplateName]) {
      setColumnOrderTemplates({
        ...columnOrderTemplates,
        [newTemplateName]: columns,
      });
      setNewTemplateName('');
    }
  };

  // テンプレートの削除
  const deleteTemplate = (templateName) => {
    const updatedTemplates = { ...columnOrderTemplates };
    delete updatedTemplates[templateName];
    setColumnOrderTemplates(updatedTemplates);
    if (selectedTemplateName === templateName) {
      setSelectedTemplateName('');
      applyColumnTemplate(''); // デフォルトのカラム順序に戻す
    }
  };

  // テンプレートの編集開始
  const startEditTemplate = (templateName) => {
    setEditTemplateName(templateName);
    setEditColumnOrder(columnOrderTemplates[templateName]);
  };

  // テンプレートの編集適用
  const applyEditTemplateChanges = () => {
    // テンプレートを更新
    setColumnOrderTemplates({
      ...columnOrderTemplates,
      [editTemplateName]: editColumnOrder,
    });
    // 編集モードを終了
    setEditTemplateName('');
    setEditColumnOrder([]);

    // 直接カラム順序を更新
    const newColumnOrder = editColumnOrder.filter((col) => columns.includes(col));
    const remainingColumns = columns.filter((col) => !newColumnOrder.includes(col));
    setColumnOrder([...newColumnOrder, ...remainingColumns]);
  };

  // カラム順序の編集（ドラッグ＆ドロップを使用）
  const onDragEnd = (result) => {
    if (!result.destination) return;
    const newOrder = Array.from(editColumnOrder);
    const [movedItem] = newOrder.splice(result.source.index, 1);
    newOrder.splice(result.destination.index, 0, movedItem);
    setEditColumnOrder(newOrder);
  };

  // カラム幅の変更
  const handleColumnWidthChange = (e) => {
    setColumnWidth(Number(e.target.value));
  };

  // カラム名の編集開始
  const startEditColumnName = (index, currentName) => {
    setIsEditingColumn(true);
    setEditingColumnIndex(index);
    setEditingColumnName(currentName);
  };

  // カラム名の編集キャンセル
  const cancelEditColumnName = () => {
    setIsEditingColumn(false);
    setEditingColumnIndex(null);
    setEditingColumnName('');
  };

  // カラム名の編集適用
  const applyEditColumnName = () => {
    if (editingColumnName.trim() === '') {
      // 空の名前は許可しない
      return;
    }

    const newColumns = [...columns];
    const oldName = newColumns[editingColumnIndex];
    newColumns[editingColumnIndex] = editingColumnName;

    // カラム名を更新
    setColumns(newColumns);

    // データ内のキーを更新
    const newData = data.map((row) => {
      const newRow = { ...row };
      if (oldName in newRow) {
        newRow[editingColumnName] = newRow[oldName];
        delete newRow[oldName];
      }
      return newRow;
    });
    setData(newData);

    // カラム順序も更新
    setColumnOrder((prevOrder) =>
      prevOrder.map((col) => (col === oldName ? editingColumnName : col))
    );

    // テンプレート内のカラム名も更新
    const updatedTemplates = { ...columnOrderTemplates };
    Object.keys(updatedTemplates).forEach((templateName) => {
      updatedTemplates[templateName] = updatedTemplates[templateName].map((col) =>
        col === oldName ? editingColumnName : col
      );
    });
    setColumnOrderTemplates(updatedTemplates);

    // 編集状態をリセット
    setIsEditingColumn(false);
    setEditingColumnIndex(null);
    setEditingColumnName('');
  };

  // カラム名編集モーダルを開く
  const openEditColumnModal = () => {
    setIsEditColumnModalOpen(true);
  };

  // カラム名編集モーダルを閉じる
  const closeEditColumnModal = () => {
    setIsEditColumnModalOpen(false);
    // 編集状態をリセット
    setIsEditingColumn(false);
    setEditingColumnIndex(null);
    setEditingColumnName('');
  };

  return (
    <Paper elevation={3} sx={{ padding: 1}}>

      {/* Gridコンテナで2つの機能を横並びに配置 */}
      <Grid container spacing={2} alignItems="flex-start">
        {/* カラム幅の設定（全12のうち2を使用） */}
        <Grid item xs={12} md={3}>
          <FormControl variant="outlined" fullWidth size="small">
            <TextField
              label="カラム幅 (px)"
              type="number"
              value={columnWidth}
              onChange={handleColumnWidthChange}
              InputProps={{ inputProps: { min: 50 } }}
              size="small"
            />
          </FormControl>
        </Grid>

        {/* カラム順序テンプレートの管理（残りを使用） */}
        <Grid item xs={12} md={9}>
          <Box>
            {/* 新しいテンプレートの追加 */}
            <Grid container spacing={1} alignItems="center">
              <Grid item xs={12} sm={6}>
                <TextField
                  label="新しいテンプレート名"
                  value={newTemplateName}
                  onChange={(e) => setNewTemplateName(e.target.value)}
                  fullWidth
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={addTemplate}
                  fullWidth
                  size="small"
                >
                  追加
                </Button>
              </Grid>
            </Grid>

            {/* テンプレートの選択 */}
            <Box sx={{ marginTop: 2 }}>
              <FormControl variant="outlined" fullWidth size="small">
                <InputLabel id="template-select-label">テンプレートを選択</InputLabel>
                <Select
                  labelId="template-select-label"
                  value={selectedTemplateName}
                  onChange={(e) => {
                    const templateName = e.target.value;
                    setSelectedTemplateName(templateName);
                    applyColumnTemplate(templateName);
                  }}
                  label="テンプレートを選択"
                  size="small"
                >
                  <MenuItem value="">
                    <em>なし</em>
                  </MenuItem>
                  {Object.keys(columnOrderTemplates).map((templateName) => (
                    <MenuItem key={templateName} value={templateName}>
                      {templateName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>

            {/* 編集・削除ボタン */}
            {selectedTemplateName && (
              <Box sx={{ marginTop: 1, textAlign: 'right' }}>
                <Button
                  variant="outlined"
                  color="primary"
                  startIcon={<EditIcon />}
                  onClick={() => startEditTemplate(selectedTemplateName)}
                  size="small"
                  sx={{ marginRight: 1 }}
                >
                  編集
                </Button>
                <Button
                  variant="outlined"
                  color="secondary"
                  startIcon={<DeleteIcon />}
                  onClick={() => deleteTemplate(selectedTemplateName)}
                  size="small"
                >
                  削除
                </Button>
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>

      {/* カラム名編集用のボタンを追加 */}
      <Box sx={{ marginTop: 2, textAlign: 'right' }}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<EditIcon />}
          onClick={openEditColumnModal}
          size="small"
        >
          カラム名を編集
        </Button>
      </Box>

      {/* カラム名編集用のモーダル */}
      <Dialog open={isEditColumnModalOpen} onClose={closeEditColumnModal} maxWidth="sm" fullWidth>
        <DialogTitle>カラム名の編集</DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            {columns.map((colName, index) => (
              <Grid item xs={12} key={index}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  {isEditingColumn && editingColumnIndex === index ? (
                    <>
                      <TextField
                        value={editingColumnName}
                        onChange={(e) => setEditingColumnName(e.target.value)}
                        size="small"
                        fullWidth
                      />
                      <IconButton
                        color="primary"
                        onClick={applyEditColumnName}
                        size="small"
                      >
                        <CheckIcon />
                      </IconButton>
                      <IconButton
                        color="secondary"
                        onClick={cancelEditColumnName}
                        size="small"
                      >
                        <CloseIcon />
                      </IconButton>
                    </>
                  ) : (
                    <>
                      <Typography variant="body1" sx={{ flexGrow: 1 }}>
                        {colName}
                      </Typography>
                      <IconButton
                        color="primary"
                        onClick={() => startEditColumnName(index, colName)}
                        size="small"
                      >
                        <EditIcon />
                      </IconButton>
                    </>
                  )}
                </Box>
              </Grid>
            ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeEditColumnModal} color="primary">
            閉じる
          </Button>
        </DialogActions>
      </Dialog>

      {/* テンプレートの編集 */}
      {editTemplateName && (
        <Box sx={{ marginTop: 2 }}>
          <Typography variant="subtitle1">
            テンプレートの編集: {editTemplateName}
          </Typography>
          <Paper
            variant="outlined"
            sx={{
              padding: 1,
              marginTop: 1,
              overflowX: 'auto',
            }}
          >
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="editColumns" direction="horizontal">
                {(provided) => (
                  <Box
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    sx={{
                      display: 'flex',
                      padding: 1,
                      minHeight: '50px',
                    }}
                  >
                    {editColumnOrder.map((colKey, index) => (
                      <Draggable key={colKey} draggableId={colKey} index={index}>
                        {(provided) => (
                          <Paper
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            sx={{
                              padding: 1,
                              marginRight: 1,
                              minWidth: '60px',
                              textAlign: 'center',
                              cursor: 'move',
                            }}
                          >
                            {colKey}
                          </Paper>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </Box>
                )}
              </Droppable>
            </DragDropContext>
          </Paper>
          <Box sx={{ marginTop: 1, textAlign: 'right' }}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<SaveIcon />}
              onClick={applyEditTemplateChanges}
              size="small"
              sx={{ marginRight: 1 }}
            >
              保存
            </Button>
            <Button variant="outlined" onClick={() => setEditTemplateName('')} size="small">
              キャンセル
            </Button>
          </Box>
        </Box>
      )}
    </Paper>
  );
}

export default ColumnSettingsManager;







// // ColumnSettingsManager.js

// import React, { useState } from 'react';
// import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
// import {
//   Box,
//   Button,
//   TextField,
//   Select,
//   MenuItem,
//   Typography,
//   IconButton,
//   Paper,
//   Grid,
//   InputLabel,
//   FormControl,
// } from '@mui/material';
// import { Delete as DeleteIcon, Edit as EditIcon, Save as SaveIcon } from '@mui/icons-material';

// function ColumnSettingsManager({
//   columns,
//   columnOrderTemplates,
//   setColumnOrderTemplates,
//   applyColumnTemplate,
//   selectedTemplateName,
//   setSelectedTemplateName,
//   setColumnOrder,
//   columnWidth,
//   setColumnWidth,
// }) {
//   // カラム順序テンプレート管理のための状態と関数
//   const [newTemplateName, setNewTemplateName] = useState('');
//   const [editTemplateName, setEditTemplateName] = useState('');
//   const [editColumnOrder, setEditColumnOrder] = useState([]);

//   // テンプレートの追加
//   const addTemplate = () => {
//     if (newTemplateName && !columnOrderTemplates[newTemplateName]) {
//       setColumnOrderTemplates({
//         ...columnOrderTemplates,
//         [newTemplateName]: columns,
//       });
//       setNewTemplateName('');
//     }
//   };

//   // テンプレートの削除
//   const deleteTemplate = (templateName) => {
//     const updatedTemplates = { ...columnOrderTemplates };
//     delete updatedTemplates[templateName];
//     setColumnOrderTemplates(updatedTemplates);
//     if (selectedTemplateName === templateName) {
//       setSelectedTemplateName('');
//       applyColumnTemplate(''); // デフォルトのカラム順序に戻す
//     }
//   };

//   // テンプレートの編集開始
//   const startEditTemplate = (templateName) => {
//     setEditTemplateName(templateName);
//     setEditColumnOrder(columnOrderTemplates[templateName]);
//   };

//   // テンプレートの編集適用
//   const applyEditTemplateChanges = () => {
//     // テンプレートを更新
//     setColumnOrderTemplates({
//       ...columnOrderTemplates,
//       [editTemplateName]: editColumnOrder,
//     });
//     // 編集モードを終了
//     setEditTemplateName('');
//     setEditColumnOrder([]);

//     // 直接カラム順序を更新
//     const newColumnOrder = editColumnOrder.filter((col) => columns.includes(col));
//     const remainingColumns = columns.filter((col) => !newColumnOrder.includes(col));
//     setColumnOrder([...newColumnOrder, ...remainingColumns]);
//   };

//   // カラム順序の編集（ドラッグ＆ドロップを使用）
//   const onDragEnd = (result) => {
//     if (!result.destination) return;
//     const newOrder = Array.from(editColumnOrder);
//     const [movedItem] = newOrder.splice(result.source.index, 1);
//     newOrder.splice(result.destination.index, 0, movedItem);
//     setEditColumnOrder(newOrder);
//   };

//   // カラム幅の変更
//   const handleColumnWidthChange = (e) => {
//     setColumnWidth(Number(e.target.value));
//   };

//   return (
//     <Paper elevation={3} sx={{ padding: 1, marginBottom: 2 }}>
//       <Typography variant="h7" gutterBottom>
//         カラム設定マネージャー
//       </Typography>

//       {/* Gridコンテナで2つの機能を横並びに配置 */}
//       <Grid container spacing={2} alignItems="flex-start">
//         {/* カラム幅の設定（全12のうち2を使用） */}
//         <Grid item xs={12} md={2}>
//           <FormControl variant="outlined" fullWidth size="small">
//             <TextField
//               label="カラム幅 (px)"
//               type="number"
//               value={columnWidth}
//               onChange={handleColumnWidthChange}
//               InputProps={{ inputProps: { min: 50 } }}
//               size="small"
//             />
//           </FormControl>
//         </Grid>

//         {/* カラム順序テンプレートの管理（残りを使用） */}
//         <Grid item xs={12} md={10}>
//           <Box>
//             {/* 新しいテンプレートの追加 */}
//             <Grid container spacing={1} alignItems="center">
//               <Grid item xs={12} sm={6}>
//                 <TextField
//                   label="新しいテンプレート名"
//                   value={newTemplateName}
//                   onChange={(e) => setNewTemplateName(e.target.value)}
//                   fullWidth
//                   size="small"
//                 />
//               </Grid>
//               <Grid item xs={12} sm={6}>
//                 <Button
//                   variant="contained"
//                   color="primary"
//                   onClick={addTemplate}
//                   fullWidth
//                   size="small"
//                 >
//                   追加
//                 </Button>
//               </Grid>
//             </Grid>

//             {/* テンプレートの選択 */}
//             <Box sx={{ marginTop: 2 }}>
//               <FormControl variant="outlined" fullWidth size="small">
//                 <InputLabel id="template-select-label">テンプレートを選択</InputLabel>
//                 <Select
//                   labelId="template-select-label"
//                   value={selectedTemplateName}
//                   onChange={(e) => {
//                     const templateName = e.target.value;
//                     setSelectedTemplateName(templateName);
//                     applyColumnTemplate(templateName);
//                   }}
//                   label="テンプレートを選択"
//                   size="small"
//                 >
//                   <MenuItem value="">
//                     <em>なし</em>
//                   </MenuItem>
//                   {Object.keys(columnOrderTemplates).map((templateName) => (
//                     <MenuItem key={templateName} value={templateName}>
//                       {templateName}
//                     </MenuItem>
//                   ))}
//                 </Select>
//               </FormControl>
//             </Box>

//             {/* 編集・削除ボタン */}
//             {selectedTemplateName && (
//               <Box sx={{ marginTop: 1, textAlign: 'right' }}>
//                 <Button
//                   variant="outlined"
//                   color="primary"
//                   startIcon={<EditIcon />}
//                   onClick={() => startEditTemplate(selectedTemplateName)}
//                   size="small"
//                   sx={{ marginRight: 1 }}
//                 >
//                   編集
//                 </Button>
//                 <Button
//                   variant="outlined"
//                   color="secondary"
//                   startIcon={<DeleteIcon />}
//                   onClick={() => deleteTemplate(selectedTemplateName)}
//                   size="small"
//                 >
//                   削除
//                 </Button>
//               </Box>
//             )}
//           </Box>
//         </Grid>
//       </Grid>

//       {/* テンプレートの編集 */}
//       {editTemplateName && (
//         <Box sx={{ marginTop: 2 }}>
//           <Typography variant="subtitle1">テンプレートの編集: {editTemplateName}</Typography>
//           <Paper
//             variant="outlined"
//             sx={{
//               padding: 1,
//               marginTop: 1,
//               overflowX: 'auto',
//             }}
//           >
//             <DragDropContext onDragEnd={onDragEnd}>
//               <Droppable droppableId="editColumns" direction="horizontal">
//                 {(provided) => (
//                   <Box
//                     ref={provided.innerRef}
//                     {...provided.droppableProps}
//                     sx={{
//                       display: 'flex',
//                       padding: 1,
//                       minHeight: '50px',
//                     }}
//                   >
//                     {editColumnOrder.map((colKey, index) => (
//                       <Draggable key={colKey} draggableId={colKey} index={index}>
//                         {(provided) => (
//                           <Paper
//                             ref={provided.innerRef}
//                             {...provided.draggableProps}
//                             {...provided.dragHandleProps}
//                             sx={{
//                               padding: 1,
//                               marginRight: 1,
//                               minWidth: '60px',
//                               textAlign: 'center',
//                               cursor: 'move',
//                             }}
//                           >
//                             {colKey}
//                           </Paper>
//                         )}
//                       </Draggable>
//                     ))}
//                     {provided.placeholder}
//                   </Box>
//                 )}
//               </Droppable>
//             </DragDropContext>
//           </Paper>
//           <Box sx={{ marginTop: 1, textAlign: 'right' }}>
//             <Button
//               variant="contained"
//               color="primary"
//               startIcon={<SaveIcon />}
//               onClick={applyEditTemplateChanges}
//               size="small"
//               sx={{ marginRight: 1 }}
//             >
//               保存
//             </Button>
//             <Button
//               variant="outlined"
//               onClick={() => setEditTemplateName('')}
//               size="small"
//             >
//               キャンセル
//             </Button>
//           </Box>
//         </Box>
//       )}
//     </Paper>
//   );
// }

// export default ColumnSettingsManager;