import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Button,
  Typography,
  TextField,
  Paper,
  Snackbar,
  Alert,
  List,
  ListItem,
  Checkbox,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Tabs,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  LinearProgress,
  Grid,
  Switch,
  ListItemText,
  RadioGroup,
  Radio,
  FormControlLabel,
  Chip,
  Drawer,
  Tooltip,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import ReplayIcon from '@mui/icons-material/Replay';
import DeleteIcon from '@mui/icons-material/Delete';

// Firebase のインポート
import { auth, db } from './firebaseConfig';
import {
  getFirestore,
  getDoc,
  doc,
  collection,
  getDocs,
  addDoc,
  setDoc,
  deleteDoc,
} from 'firebase/firestore';

// 機能関数のインポート
import { applyTemplate, applyItemSpecifics } from './functions';
import Papa from 'papaparse';
import { calculatePrices } from './calculatePrices';
import { applyAITitles } from './applyAITitles';
import { applyAIProductDescriptions } from './applyAIProductDescriptions';
import CryptoJS from 'crypto-js';
import { applyItemSpecificsProcessor } from './ItemSpecificsProcessor';
import designTemplates from './designTemplates';

import OptionsPanel from './OptionsPanel';

function CsvSchedulerUploader({ user }) {
  const [csvFiles, setCsvFiles] = useState([]);
  const [fileContents, setFileContents] = useState([]);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'info',
  });

  const [selectedTemplate, setSelectedTemplate] = useState('');
  const [selectedItemSpecificsCategory, setSelectedItemSpecificsCategory] = useState('');
  const [selectedItemSpecificsColumns, setSelectedItemSpecificsColumns] = useState([]);
  const [itemSpecificsMatchingOptions, setItemSpecificsMatchingOptions] = useState({
    caseSensitive: false,
    partialMatch: true,
    matchSource: 'title',
    matchingOption: 'priority',
  });
  const [itemSpecificsCategories, setItemSpecificsCategories] = useState({});

  const [selectedFunctions, setSelectedFunctions] = useState([]);
  const [isImmediate, setIsImmediate] = useState(true);
  const [scheduledTime, setScheduledTime] = useState('');
  const [functionDialogOpen, setFunctionDialogOpen] = useState(false);
  const [isScheduling, setIsScheduling] = useState(false);
  const [processedData, setProcessedData] = useState([]);
  const [activeTab, setActiveTab] = useState(0);

  const [templateList, setTemplateList] = useState([]);
  const [selectedTemplateName, setSelectedTemplateName] = useState('');

  const [selectedPriceSettings, setSelectedPriceSettings] = useState(null);
  const [shippingRateTemplates, setShippingRateTemplates] = useState([]);
  const [selectedShippingTemplateId, setSelectedShippingTemplateId] = useState('');

  const [exchangeRate, setExchangeRate] = useState(0);
  const [targetProfitMargin, setTargetProfitMargin] = useState(0);
  const [fees, setFees] = useState({});
  const [bestOfferSettings, setBestOfferSettings] = useState({});

  const [userApiKey, setUserApiKey] = useState('');
  const [userSettings, setUserSettings] = useState({});

  const [designTemplateNames, setDesignTemplateNames] = useState([]);
  const [selectedDesignTemplate, setSelectedDesignTemplate] = useState('');

  const [templates, setTemplates] = useState([]);
  const [templateName, setTemplateName] = useState('');
  const [isSaveTemplateDialogOpen, setIsSaveTemplateDialogOpen] = useState(false);
  const [isManageTemplatesDialogOpen, setIsManageTemplatesDialogOpen] = useState(false);
  const [selectedTemplateId, setSelectedTemplateId] = useState('');
  const [currentTemplateName, setCurrentTemplateName] = useState('');
  const [saveOption, setSaveOption] = useState('new');

  const [customCategories, setCustomCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');

  const [useImageDescription, setUseImageDescription] = useState(false);
  const [mandatoryKeywords, setMandatoryKeywords] = useState([]);
  const [mandatoryKeywordInput, setMandatoryKeywordInput] = useState('');

  const [sentenceToDeleteInput, setSentenceToDeleteInput] = useState('');
  const [wordToReplaceFrom, setWordToReplaceFrom] = useState('');
  const [wordToReplaceTo, setWordToReplaceTo] = useState('');

  const navigate = useNavigate();

  const [patterns, setPatterns] = useState([]);
  const [selectedPatternName, setSelectedPatternName] = useState('');

  const generateEncryptionKey = (uid) => {
    const salt = 'your-fixed-salt-value';
    return CryptoJS.PBKDF2(uid, salt, { keySize: 256 / 32 }).toString();
  };

  const [progress, setProgress] = useState({ totalItems: 0, completedItems: 0 });
  const [errorItems, setErrorItems] = useState([]);

  const [availableFunctions, setAvailableFunctions] = useState([
    {
      name: 'applyTemplate',
      displayName: 'テンプレートを実行する',
      func: applyTemplate,
    },
    {
      name: 'applyPriceChanges',
      displayName: '価格の変更を適用する',
      func: 'applyPriceChanges',
    },
    {
      name: 'applyAITitles',
      displayName: 'AIタイトルを適用する',
      func: 'applyAITitles',
    },
    {
      name: 'applyAIProductDescriptions',
      displayName: 'AI商品説明を適用する',
      func: 'applyAIProductDescriptions',
    },
    {
      name: 'applyItemSpecifics',
      displayName: 'Item Specificsの適用をする',
      func: applyItemSpecifics,
    },
  ]);

  // AI商品説明用テンプレートを管理するステート
  const [aiDescriptionTemplateNames, setAIDescriptionTemplateNames] = useState([]);
  const [selectedAIDescriptionTemplate, setSelectedAIDescriptionTemplate] = useState('');
  const [aiDescriptionTemplates, setAiDescriptionTemplates] = useState({});

  useEffect(() => {
    const fetchItemSpecificsSettings = async () => {
      if (!user) return;
      try {
        const db = getFirestore();
        const docRef = doc(db, 'itemSpecificsSettings', user.uid);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const settingsData = docSnap.data();
          setItemSpecificsCategories(settingsData.categories || {});
        } else {
          console.warn('Item Specificsの設定が見つかりませんでした。');
        }
      } catch (error) {
        console.error('Item Specificsの設定取得中にエラー:', error);
      }
    };

    fetchItemSpecificsSettings();
  }, [user]);

  const getColumnsForSelectedCategory = () => {
    if (
      selectedItemSpecificsCategory &&
      itemSpecificsCategories[selectedItemSpecificsCategory]
    ) {
      return Object.keys(
        itemSpecificsCategories[selectedItemSpecificsCategory].columns || {}
      );
    }
    return [];
  };

  useEffect(() => {
    if (!user) {
      navigate('/login');
    } else {
      const fetchTemplates = async () => {
        try {
          const docRef = doc(db, 'userTemplates', user.uid);
          const docSnap = await getDoc(docRef);

          if (docSnap.exists()) {
            const userData = docSnap.data();
            const userCategories = userData.categories || {};
            const templates = Object.values(userCategories)
              .flat()
              .map((template) => ({
                name: template.name,
                operations: template.operations,
              }));
            setTemplateList(templates);
            if (templates.length > 0) {
              setSelectedTemplateName(templates[0].name);
            }
          }
        } catch (error) {
          console.error('テンプレート取得エラー:', error);
          setSnackbar({
            open: true,
            message: 'テンプレートの取得に失敗しました。',
            severity: 'error',
          });
        }
      };
      fetchTemplates();

      const fetchUserSettings = async () => {
        try {
          const docRef = doc(db, 'userSettings', user.uid);
          const docSnap = await getDoc(docRef);

          if (docSnap.exists()) {
            const userSettingsData = docSnap.data();
            setSelectedPriceSettings(userSettingsData);
            setUserSettings(userSettingsData);

            if (userSettingsData.shippingRateTemplates) {
              setShippingRateTemplates(userSettingsData.shippingRateTemplates);
              setSelectedShippingTemplateId(
                userSettingsData.selectedShippingTemplateId ||
                  userSettingsData.shippingRateTemplates[0].id
              );
            }

            setExchangeRate(userSettingsData.exchangeRate || 0);
            setTargetProfitMargin(userSettingsData.targetProfitMargin || 0);
            setFees(userSettingsData.fees || {});
            setBestOfferSettings({
              bestOfferAutoAcceptPercentage:
                userSettingsData.bestOfferAutoAcceptPercentage || 0,
              minimumBestOfferPercentage:
                userSettingsData.minimumBestOfferPercentage || 0,
            });

            // デザインテンプレートはFirebaseではなくローカルから取得
            const templateNames = Object.keys(designTemplates);
            setDesignTemplateNames(templateNames);
            if (templateNames.length > 0) {
              setSelectedDesignTemplate(templateNames[0]);
            } else {
              setSelectedDesignTemplate('defaultTemplate');
            }

            setCustomFilters(userSettingsData.customFilters || []);
            setSentencesToDelete(userSettingsData.sentencesToDelete || []);
            setWordsToReplace(userSettingsData.wordsToReplace || []);
            setMandatoryKeywords(userSettingsData.mandatoryKeywords || []);

            setCustomFilters(userSettingsData.customFilters || []);
            setSentencesToDelete(userSettingsData.sentencesToDelete || []);
            setWordsToReplace(userSettingsData.wordsToReplace || []);
            setMandatoryKeywords(userSettingsData.mandatoryKeywords || []);

            if (userSettingsData.customCategories) {
              setCustomCategories(userSettingsData.customCategories);
            } else {
              await loadTitleCustomizationTemplates();
            }

            if (userSettingsData.patterns) {
              setPatterns(userSettingsData.patterns);
            } else {
              setPatterns([]);
            }

            // ここでAI商品説明テンプレートを取得してstateに反映
            if (userSettingsData.aiDescriptionTemplates) {
              const aiDescTemplateNames = Object.keys(userSettingsData.aiDescriptionTemplates);
              setAIDescriptionTemplateNames(aiDescTemplateNames);
              setAiDescriptionTemplates(userSettingsData.aiDescriptionTemplates);
              if (aiDescTemplateNames.length > 0) {
                setSelectedAIDescriptionTemplate(aiDescTemplateNames[0]);
              } else {
                setSelectedAIDescriptionTemplate('');
              }
            } else {
              setAIDescriptionTemplateNames([]);
              setSelectedAIDescriptionTemplate('');
              setAiDescriptionTemplates({});
            }

          } else {
            await loadTitleCustomizationTemplates();
          }

          const apiKeyDocRef = doc(db, 'userApiKeys', user.uid);
          const apiKeyDocSnap = await getDoc(apiKeyDocRef);

          if (apiKeyDocSnap.exists()) {
            const encryptedKey = apiKeyDocSnap.data().apiKey;
            const encryptionKey = generateEncryptionKey(user.uid);
            const bytes = CryptoJS.AES.decrypt(encryptedKey, encryptionKey);
            const decryptedKey = bytes.toString(CryptoJS.enc.Utf8);
            if (decryptedKey) {
              setUserApiKey(decryptedKey);
            } else {
              console.error('APIキーの復号に失敗');
            }
          } else {
            console.warn('APIキーが見つかりません');
          }

          loadTemplates();
        } catch (error) {
          console.error('ユーザー設定取得エラー:', error);
          setSnackbar({
            open: true,
            message: 'ユーザー設定の取得に失敗しました。',
            severity: 'error',
          });
        }
      };
      fetchUserSettings();

      const loadTitleCustomizationTemplates = async () => {
        if (!user) return;
        try {
          const docRef = doc(db, 'userTitleSettings', user.uid);
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            const data = docSnap.data();
            setCustomCategories(data.customCategories || []);
          }
        } catch (error) {
          console.error('タイトルカスタマイズテンプレート取得エラー:', error);
        }
      };
    }
  }, [user, navigate]);

  const [customFilters, setCustomFilters] = useState([]);
  const [sentencesToDelete, setSentencesToDelete] = useState([]);
  const [wordsToReplace, setWordsToReplace] = useState([]);

  const [selectedFilters, setSelectedFilters] = useState([]);
  const [customFilterInput, setCustomFilterInput] = useState('');

  const saveTemplate = async () => {
    if (!templateName.trim()) {
      setSnackbar({
        open: true,
        message: 'テンプレート名を入力してください',
        severity: 'warning',
      });
      return;
    }

    const templateData = {
      name: templateName.trim(),
      customFilters,
      sentencesToDelete,
      wordsToReplace,
      mandatoryKeywords,
      selectedFilters,
    };

    try {
      const userTemplatesRef = collection(db, 'users', user.uid, 'templates');

      if (saveOption === 'overwrite' && selectedTemplateId) {
        const templateDocRef = doc(userTemplatesRef, selectedTemplateId);
        await setDoc(templateDocRef, templateData);
        setSnackbar({
          open: true,
          message: 'テンプレートが更新されました',
          severity: 'success',
        });
      } else {
        await addDoc(userTemplatesRef, templateData);
        setSnackbar({
          open: true,
          message: '新しいテンプレートが保存されました',
          severity: 'success',
        });
      }

      setIsSaveTemplateDialogOpen(false);
      setTemplateName('');
      loadTemplates();
    } catch (error) {
      console.error('テンプレート保存中エラー:', error);
      setSnackbar({
        open: true,
        message: 'テンプレートの保存中にエラーが発生しました',
        severity: 'error',
      });
    }
  };

  const loadTemplates = async () => {
    try {
      const userTemplatesRef = collection(db, 'users', user.uid, 'templates');
      const templatesSnapshot = await getDocs(userTemplatesRef);
      const templatesData = templatesSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setTemplates(templatesData);
    } catch (error) {
      console.error('テンプレート読み込みエラー:', error);
    }
  };

  const handleLoadTemplate = async (templateId) => {
    if (!templateId) {
      setSelectedTemplateId('');
      setTemplateName('');
      setCurrentTemplateName('');
      return;
    }
    setSelectedTemplateId(templateId);
    try {
      const templateDocRef = doc(db, 'users', user.uid, 'templates', templateId);
      const templateDoc = await getDoc(templateDocRef);
      if (templateDoc.exists()) {
        const templateData = templateDoc.data();
        setTemplateName(templateData.name || '');
        setCurrentTemplateName(templateData.name || '');
        setCustomFilters(templateData.customFilters || []);
        setSentencesToDelete(templateData.sentencesToDelete || []);
        setWordsToReplace(templateData.wordsToReplace || []);
        setMandatoryKeywords(templateData.mandatoryKeywords || []);
        setSelectedFilters(templateData.selectedFilters || []);
        setSnackbar({
          open: true,
          message: 'テンプレートが適用されました',
          severity: 'success',
        });
      } else {
        setSnackbar({
          open: true,
          message: 'テンプレートが見つかりません',
          severity: 'error',
        });
      }
    } catch (error) {
      console.error('テンプレート読み込みエラー:', error);
      setSnackbar({
        open: true,
        message: 'テンプレートの読み込み中にエラーが発生しました',
        severity: 'error',
      });
    }
  };

  const handleDeleteTemplate = async (templateId) => {
    try {
      const templateDocRef = doc(db, 'users', user.uid, 'templates', templateId);
      await deleteDoc(templateDocRef);
      setSnackbar({
        open: true,
        message: 'テンプレートが削除されました',
        severity: 'success',
      });
      loadTemplates();
    } catch (error) {
      console.error('テンプレート削除エラー:', error);
      setSnackbar({
        open: true,
        message: 'テンプレートの削除中にエラーが発生しました',
        severity: 'error',
      });
    }
  };

  const handleCreateNewTemplate = () => {
    setSelectedTemplateId('');
    setTemplateName('');
    setCurrentTemplateName('');
    setCustomFilters([]);
    setSelectedFilters([]);
    setSentencesToDelete([]);
    setWordsToReplace([]);
    setMandatoryKeywords([]);
    setSnackbar({
      open: true,
      message: '新しいテンプレートを作成します',
      severity: 'info',
    });
  };

  const handleAddMandatoryKeyword = () => {
    if (mandatoryKeywordInput.trim() !== '') {
      setMandatoryKeywords([...mandatoryKeywords, mandatoryKeywordInput.trim()]);
      setMandatoryKeywordInput('');
    }
  };

  const handleDeleteMandatoryKeyword = (index) => {
    const updatedKeywords = [...mandatoryKeywords];
    updatedKeywords.splice(index, 1);
    setMandatoryKeywords(updatedKeywords);
  };

  const handleAddSentenceToDelete = () => {
    if (sentenceToDeleteInput.trim() !== '') {
      setSentencesToDelete([...sentencesToDelete, sentenceToDeleteInput.trim()]);
      setSentenceToDeleteInput('');
    }
  };

  const handleDeleteSentenceToDelete = (index) => {
    const updatedSentences = [...sentencesToDelete];
    updatedSentences.splice(index, 1);
    setSentencesToDelete(updatedSentences);
  };

  const handleAddWordToReplace = () => {
    if (wordToReplaceFrom.trim() !== '' && wordToReplaceTo.trim() !== '') {
      setWordsToReplace([
        ...wordsToReplace,
        { from: wordToReplaceFrom.trim(), to: wordToReplaceTo.trim() },
      ]);
      setWordToReplaceFrom('');
      setWordToReplaceTo('');
    }
  };

  const handleDeleteWordToReplace = (index) => {
    const updatedWords = [...wordsToReplace];
    updatedWords.splice(index, 1);
    setWordsToReplace(updatedWords);
  };

  const [deleteStrings, setDeleteStrings] = useState([]);
  const [replacePairs, setReplacePairs] = useState([]);
  const [prependText, setPrependText] = useState('');
  const [appendText, setAppendText] = useState('');
  const [limitTitleLength, setLimitTitleLength] = useState(false);

  const handleAddReplacePair = () => {
    setReplacePairs([...replacePairs, { from: '', to: '' }]);
  };

  const handleReplacePairChange = (index, field, value) => {
    const newPairs = [...replacePairs];
    newPairs[index][field] = value;
    setReplacePairs(newPairs);
  };

  const handleRemoveReplacePair = (index) => {
    const newPairs = [...replacePairs];
    newPairs.splice(index, 1);
    setReplacePairs(newPairs);
  };

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files).slice(0, 5);
    setCsvFiles(files);
    Promise.all(files.map((file) => file.text())).then((contents) => {
      setFileContents(contents);
    });
  };

  const handleFunctionDialogOpen = () => {
    setFunctionDialogOpen(true);
  };

  const handleFunctionDialogClose = () => {
    setFunctionDialogOpen(false);
  };

  const handleFunctionToggle = (func) => () => {
    const currentIndex = selectedFunctions.findIndex((f) => f.name === func.name);
    const newSelected = [...selectedFunctions];

    if (currentIndex === -1) {
      newSelected.push(func);
    } else {
      newSelected.splice(currentIndex, 1);
    }

    setSelectedFunctions(newSelected);
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const newFunctions = Array.from(selectedFunctions);
    const [removed] = newFunctions.splice(result.source.index, 1);
    newFunctions.splice(result.destination.index, 0, removed);

    setSelectedFunctions(newFunctions);
  };

  const applyPriceChanges = useCallback(
    async (currentData) => {
      try {
        if (!selectedPriceSettings) {
          throw new Error('価格設定が取得されていません。');
        }

        const updatedPriceSettings = {
          ...selectedPriceSettings,
          selectedShippingTemplateId,
        };

        const updatedData = await Promise.all(
          currentData.map(async (file) => {
            const parsedResult = Papa.parse(file.content, { header: true });
            const data = parsedResult.data;
            const newPrices = calculatePrices(
              data,
              updatedPriceSettings,
              selectedShippingTemplateId
            );
            const updatedData = data.map((item, index) => {
              const calculatedPrice = newPrices[index];
              if (calculatedPrice) {
                return {
                  ...item,
                  StartPrice: calculatedPrice.discountedPrice,
                  BestOfferAutoAcceptPrice: calculatedPrice.bestOfferAutoAcceptPrice,
                  MinimumBestOfferPrice: calculatedPrice.minimumBestOfferPrice,
                };
              } else {
                return item;
              }
            });

            const csvContent = Papa.unparse(updatedData);
            return {
              ...file,
              content: csvContent,
            };
          })
        );

        return updatedData;
      } catch (error) {
        console.error('価格適用中エラー:', error);
        setSnackbar({
          open: true,
          message: '価格適用中にエラーが発生しました。',
          severity: 'error',
        });
        throw error;
      }
    },
    [selectedPriceSettings, selectedShippingTemplateId, setSnackbar]
  );

  const applyAITitlesWrapper = useCallback(
    async (currentData) => {
      try {
        if (!userApiKey) {
          setSnackbar({
            open: true,
            message:
              'OpenAI APIキーが設定されていません。ユーザー設定でAPIキーを入力してください。',
            severity: 'error',
          });
          throw new Error('OpenAI APIキーが設定されていません。');
        }

        setProgress({ totalItems: 0, completedItems: 0 });
        setErrorItems([]);

        const customizationOptions = {
          deleteStrings,
          replacePairs,
          prependText,
          appendText,
          limitTitleLength,
        };

        const { updatedData, errorItems } = await applyAITitles(
          currentData,
          userApiKey,
          (progressData) => {
            setProgress(progressData);
          },
          customizationOptions
        );

        setErrorItems(errorItems);
        return updatedData;
      } catch (error) {
        console.error('AIタイトル適用中エラー:', error);
        setSnackbar({
          open: true,
          message: 'AIタイトル適用中にエラーが発生しました。',
          severity: 'error',
        });
        throw error;
      }
    },
    [
      userApiKey,
      setSnackbar,
      setProgress,
      setErrorItems,
      deleteStrings,
      replacePairs,
      prependText,
      appendText,
      limitTitleLength,
    ]
  );

  const applyAIProductDescriptionsWrapper = useCallback(
    async (currentData) => {
      try {
        if (!userApiKey) {
          setSnackbar({
            open: true,
            message:
              'OpenAI APIキーが設定されていません。ユーザー設定でAPIキーを入力してください。',
            severity: 'error',
          });
          throw new Error('OpenAI APIキーが設定されていません。');
        }

        setProgress({ totalItems: 0, completedItems: 0 });
        setErrorItems([]);

        const selectedTemplateData =
          selectedAIDescriptionTemplate && aiDescriptionTemplates[selectedAIDescriptionTemplate]
            ? aiDescriptionTemplates[selectedAIDescriptionTemplate]
            : null;

        const { updatedData, errorItems } = await applyAIProductDescriptions(
          currentData,
          userApiKey,
          (progressData) => {
            setProgress(progressData);
          },
          selectedDesignTemplate,
          {
            customFilters,
            selectedFilters,
            sentencesToDelete,
            wordsToReplace,
            mandatoryKeywords,
            selectedAIDescriptionTemplateData: selectedTemplateData,
          }
        );

        setErrorItems(errorItems);
        return updatedData;
      } catch (error) {
        console.error('AI商品説明適用中エラー:', error);
        setSnackbar({
          open: true,
          message: 'AI商品説明適用中にエラーが発生しました。',
          severity: 'error',
        });
        throw error;
      }
    },
    [
      userApiKey,
      setSnackbar,
      setProgress,
      setErrorItems,
      selectedDesignTemplate,
      customFilters,
      selectedFilters,
      sentencesToDelete,
      wordsToReplace,
      mandatoryKeywords,
      selectedAIDescriptionTemplate,
      aiDescriptionTemplates,
    ]
  );

  const applyItemSpecificsWrapper = useCallback(
    async (currentData, options) => {
      const {
        selectedItemSpecificsCategory,
        selectedItemSpecificsColumns,
        itemSpecificsMatchingOptions,
      } = options;
      try {
        if (!user) {
          setSnackbar({
            open: true,
            message: 'ユーザー情報が取得できません。',
            severity: 'error',
          });
          throw new Error('ユーザー情報が取得できません。');
        }

        if (!selectedItemSpecificsCategory) {
          setSnackbar({
            open: true,
            message: '適用するカテゴリーを選択してください。',
            severity: 'warning',
          });
          throw new Error('適用するカテゴリーを選択してください。');
        }

        setProgress({ totalItems: 0, completedItems: 0 });

        const updatedData = await applyItemSpecificsProcessor(
          currentData,
          user.uid,
          {
            selectedCategory: selectedItemSpecificsCategory,
            selectedColumns: selectedItemSpecificsColumns,
            matchingOptions: itemSpecificsMatchingOptions,
          },
          (progressData) => {
            setProgress(progressData);
          }
        );

        return updatedData;
      } catch (error) {
        console.error('Item Specifics適用中エラー:', error);
        setSnackbar({
          open: true,
          message: error.message || 'Item Specifics適用中にエラーが発生しました。',
          severity: 'error',
        });
        throw error;
      }
    },
    [user, setSnackbar, setProgress]
  );

  useEffect(() => {
    setAvailableFunctions((prevFunctions) =>
      prevFunctions.map((func) => {
        if (func.name === 'applyPriceChanges') {
          return { ...func, func: applyPriceChanges };
        }
        if (func.name === 'applyAITitles') {
          return { ...func, func: applyAITitlesWrapper };
        }
        if (func.name === 'applyAIProductDescriptions') {
          return { ...func, func: applyAIProductDescriptionsWrapper };
        }
        return func;
      })
    );
  }, [applyPriceChanges, applyAITitlesWrapper, applyAIProductDescriptionsWrapper]);

  useEffect(() => {
    setAvailableFunctions((prevFunctions) =>
      prevFunctions.map((func) =>
        func.name === 'applyItemSpecifics'
          ? { ...func, func: applyItemSpecificsWrapper }
          : func
      )
    );
  }, [applyItemSpecificsWrapper]);

  const applyPatternSettings = (pattern) => {
    if (!pattern || !pattern.settings) {
      setSnackbar({
        open: true,
        message: '選択されたパターンが正しくありません。',
        severity: 'error',
      });
      return;
    }

    const settings = pattern.settings;

    if (settings.applyTemplate && settings.applyTemplate.selectedTemplateName) {
      setSelectedTemplateName(settings.applyTemplate.selectedTemplateName);
    }

    if (settings.applyPriceChanges && settings.applyPriceChanges.selectedShippingTemplateId) {
      setSelectedShippingTemplateId(settings.applyPriceChanges.selectedShippingTemplateId);
    }

    if (settings.applyAITitles) {
      setDeleteStrings(settings.applyAITitles.deleteStrings || []);
      setReplacePairs(settings.applyAITitles.replacePairs || []);
      setPrependText(settings.applyAITitles.prependText || '');
      setAppendText(settings.applyAITitles.appendText || '');
      setLimitTitleLength(settings.applyAITitles.limitTitleLength || false);
    }

    if (settings.applyAIProductDescriptions && settings.applyAIProductDescriptions.selectedDesignTemplate) {
      setSelectedDesignTemplate(settings.applyAIProductDescriptions.selectedDesignTemplate);
      setCustomFilters(settings.applyAIProductDescriptions.customFilters || []);
      setSentencesToDelete(settings.applyAIProductDescriptions.sentencesToDelete || []);
      setWordsToReplace(settings.applyAIProductDescriptions.wordsToReplace || []);
      setMandatoryKeywords(settings.applyAIProductDescriptions.mandatoryKeywords || []);
      if (settings.applyAIProductDescriptions.selectedAIDescriptionTemplate) {
        setSelectedAIDescriptionTemplate(settings.applyAIProductDescriptions.selectedAIDescriptionTemplate);
      }
    }

    if (settings.applyItemSpecifics && settings.applyItemSpecifics.selectedItemSpecificsCategory) {
      setSelectedItemSpecificsCategory(settings.applyItemSpecifics.selectedItemSpecificsCategory);
      setSelectedItemSpecificsColumns(settings.applyItemSpecifics.selectedItemSpecificsColumns || []);
      setItemSpecificsMatchingOptions(settings.applyItemSpecifics.itemSpecificsMatchingOptions || itemSpecificsMatchingOptions);
    }

    const newSelectedFunctions = availableFunctions.filter((func) =>
      Object.keys(settings).includes(func.name)
    );
    setSelectedFunctions(newSelectedFunctions);

    setSnackbar({
      open: true,
      message: `パターン "${pattern.name}" が適用されました。`,
      severity: 'success',
    });
  };

  const [isSavePatternDialogOpen, setIsSavePatternDialogOpen] = useState(false);
  const [newPatternName, setNewPatternName] = useState('');
  const [isManagePatternsDialogOpen, setIsManagePatternsDialogOpen] = useState(false);

  const saveCurrentSettingsAsPattern = async () => {
    if (!newPatternName.trim()) {
      setSnackbar({
        open: true,
        message: 'パターン名を入力してください',
        severity: 'warning',
      });
      return;
    }

    const newPattern = {
      name: newPatternName.trim(),
      settings: {},
    };

    selectedFunctions.forEach((func) => {
      if (func.name === 'applyTemplate') {
        newPattern.settings.applyTemplate = { selectedTemplateName };
      }
      if (func.name === 'applyPriceChanges') {
        newPattern.settings.applyPriceChanges = { selectedShippingTemplateId };
      }
      if (func.name === 'applyAITitles') {
        newPattern.settings.applyAITitles = {
          deleteStrings,
          replacePairs,
          prependText,
          appendText,
          limitTitleLength,
        };
      }
      if (func.name === 'applyAIProductDescriptions') {
        newPattern.settings.applyAIProductDescriptions = {
          selectedDesignTemplate,
          customFilters,
          sentencesToDelete,
          wordsToReplace,
          mandatoryKeywords,
          selectedAIDescriptionTemplate,
        };
      }
      if (func.name === 'applyItemSpecifics') {
        newPattern.settings.applyItemSpecifics = {
          selectedItemSpecificsCategory,
          selectedItemSpecificsColumns,
          itemSpecificsMatchingOptions,
        };
      }
    });

    try {
      const userSettingsRef = doc(db, 'userSettings', user.uid);
      const docSnap = await getDoc(userSettingsRef);
      let existingPatterns = [];
      if (docSnap.exists()) {
        existingPatterns = docSnap.data().patterns || [];
      }

      const patternExists = existingPatterns.some(
        (pattern) => pattern.name === newPattern.name
      );

      if (patternExists) {
        setSnackbar({
          open: true,
          message: '同名のパターンが既に存在します。',
          severity: 'error',
        });
        return;
      }

      existingPatterns.push(newPattern);
      await setDoc(userSettingsRef, { patterns: existingPatterns }, { merge: true });
      setPatterns(existingPatterns);
      setIsSavePatternDialogOpen(false);
      setNewPatternName('');
      setSnackbar({
        open: true,
        message: 'パターンが保存されました。',
        severity: 'success',
      });
    } catch (error) {
      console.error('パターン保存中エラー:', error);
      setSnackbar({
        open: true,
        message: 'パターンの保存中にエラーが発生しました。',
        severity: 'error',
      });
    }
  };

  const handleDeletePattern = async (patternName) => {
    try {
      const userSettingsRef = doc(db, 'userSettings', user.uid);
      const docSnap = await getDoc(userSettingsRef);
      if (docSnap.exists()) {
        let existingPatterns = docSnap.data().patterns || [];
        existingPatterns = existingPatterns.filter((p) => p.name !== patternName);
        await setDoc(userSettingsRef, { patterns: existingPatterns }, { merge: true });
        setPatterns(existingPatterns);
        setSnackbar({
          open: true,
          message: 'パターンが削除されました。',
          severity: 'success',
        });
      }
    } catch (error) {
      console.error('パターン削除エラー:', error);
      setSnackbar({
        open: true,
        message: 'パターンの削除中にエラーが発生しました。',
        severity: 'error',
      });
    }
  };

  const handleUploadAndExecute = async () => {
    if (!user) {
      setSnackbar({
        open: true,
        message: 'ユーザーが認証されていません。',
        severity: 'error',
      });
      return;
    }

    if (csvFiles.length === 0) {
      setSnackbar({
        open: true,
        message: 'アップロードするファイルがありません。',
        severity: 'warning',
      });
      return;
    }

    if (selectedFunctions.length === 0) {
      setSnackbar({
        open: true,
        message: '実行する機能を選択してください。',
        severity: 'warning',
      });
      return;
    }

    setIsScheduling(true);

    try {
      const filesData = csvFiles.map((file, index) => ({
        name: file.name,
        content: fileContents[index],
      }));

      const executeFunctions = async () => {
        let currentData = filesData;

        for (const func of selectedFunctions) {
          try {
            if (func.func.constructor.name === 'AsyncFunction') {
              if (func.name === 'applyTemplate') {
                currentData = await func.func(currentData, user, selectedTemplateName);
              } else if (func.name === 'applyItemSpecifics') {
                currentData = await func.func(currentData, {
                  selectedItemSpecificsCategory,
                  selectedItemSpecificsColumns,
                  itemSpecificsMatchingOptions,
                });
              } else {
                currentData = await func.func(currentData);
              }
            } else {
              if (func.name === 'applyTemplate') {
                currentData = func.func(currentData, user, selectedTemplateName);
              } else if (func.name === 'applyItemSpecifics') {
                currentData = func.func(currentData, {
                  selectedItemSpecificsCategory,
                  selectedItemSpecificsColumns,
                  itemSpecificsMatchingOptions,
                });
              } else {
                currentData = func.func(currentData);
              }
            }
          } catch (error) {
            console.error(`機能 "${func.displayName}" 実行中エラー:`, error);
            setSnackbar({
              open: true,
              message: `機能 "${func.displayName}" 実行中にエラーが発生しました。`,
              severity: 'error',
            });
            setIsScheduling(false);
            return;
          }
        }

        setProcessedData(currentData);

        setSnackbar({
          open: true,
          message: '選択された機能を実行しました。',
          severity: 'success',
        });
        setIsScheduling(false);
      };

      if (isImmediate) {
        await executeFunctions();
      } else {
        const now = new Date();
        const scheduledDateTime = new Date(scheduledTime);
        const delay = scheduledDateTime - now;

        if (delay > 0) {
          setSnackbar({
            open: true,
            message: 'タスクがスケジュールされました。',
            severity: 'success',
          });
          setTimeout(async () => {
            await executeFunctions();
          }, delay);
        } else {
          setSnackbar({
            open: true,
            message: '指定された時間は過去です。正しい時間を選択してください。',
            severity: 'error',
          });
          setIsScheduling(false);
        }
      }
    } catch (error) {
      console.error('処理中エラー:', error);
      setSnackbar({
        open: true,
        message: '処理中にエラーが発生しました。',
        severity: 'error',
      });
      setIsScheduling(false);
    }
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const [parsedData, setParsedData] = useState([]);

  useEffect(() => {
    const parseCSVFiles = () => {
      const data = fileContents.map((content) => {
        const parsedResult = Papa.parse(content, { header: true });
        return {
          data: parsedResult.data,
          columns: parsedResult.meta.fields,
        };
      });
      setParsedData(data);
    };

    if (fileContents.length > 0) {
      parseCSVFiles();
    } else {
      setParsedData([]);
    }
  }, [fileContents]);

  const [processedParsedData, setProcessedParsedData] = useState([]);

  useEffect(() => {
    if (processedData.length > 0) {
      const data = processedData.map((file) => {
        const parsedResult = Papa.parse(file.content, { header: true });
        return {
          name: file.name,
          data: parsedResult.data,
          columns: parsedResult.meta.fields,
        };
      });
      setProcessedParsedData(data);
    } else {
      setProcessedParsedData([]);
    }
  }, [processedData]);

  const handleFilterChange = (label) => {
    setSelectedFilters((prev) =>
      prev.includes(label) ? prev.filter((item) => item !== label) : [...prev, label]
    );
  };

  const handleAddCustomFilter = () => {
    if (customFilterInput.trim() !== '') {
      setCustomFilters([...customFilters, customFilterInput.trim()]);
      setCustomFilterInput('');
    }
  };

  const handleDeleteCustomFilter = (index) => {
    const updatedFilters = [...customFilters];
    updatedFilters.splice(index, 1);
    setCustomFilters(updatedFilters);
  };

  const getFirstImageUrl = (picUrlString) => {
    if (!picUrlString) return '';
    const urls = picUrlString.split('|');
    return urls[0] || '';
  };

  const [selectedProduct, setSelectedProduct] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [currentImageUrl, setCurrentImageUrl] = useState('');

  const handleOpenImageModal = (url) => {
    setCurrentImageUrl(url);
    setIsImageModalOpen(true);
  };

  const handleCloseImageModal = () => {
    setIsImageModalOpen(false);
    setCurrentImageUrl('');
  };

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const ImageGallery = ({ picUrlString }) => {
    if (!picUrlString) return null;
    const urls = picUrlString.split('|');

    return (
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mt: 2 }}>
        {urls.map((url, index) => (
          <Box key={index} sx={{ position: 'relative' }}>
            <img
              src={url}
              alt={`商品画像 ${index + 1}`}
              style={{
                width: '100px',
                height: '100px',
                objectFit: 'cover',
                cursor: 'pointer',
              }}
              onClick={() => {
                handleOpenImageModal(url);
              }}
            />
          </Box>
        ))}
      </Box>
    );
  };

  return (
    <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
      <Typography variant="h6" gutterBottom>
        CSVファイルのアップロードとタスクのスケジュール
      </Typography>

      <Button variant="contained" component="label">
        ファイルを選択（最大5つまで）
        <input
          type="file"
          accept=".csv"
          multiple
          hidden
          onChange={handleFileChange}
        />
      </Button>

      {csvFiles.length > 0 && (
        <Box sx={{ mt: 2 }}>
          <Typography variant="subtitle1">選択されたファイル:</Typography>
          <List>
            {csvFiles.map((file, index) => (
              <ListItem key={index}>
                <Typography>{file.name}</Typography>
              </ListItem>
            ))}
          </List>
        </Box>
      )}

      <Button
        variant="contained"
        color="primary"
        sx={{ mt: 2 }}
        onClick={handleFunctionDialogOpen}
        startIcon={<MenuIcon />}
      >
        実行する機能を選択
      </Button>

      <Box sx={{ mt: 2 }}>
        <FormControl fullWidth>
          <InputLabel>パターンを選択</InputLabel>
          <Select
            value={selectedPatternName}
            onChange={(e) => {
              const patternName = e.target.value;
              setSelectedPatternName(patternName);
              const pattern = patterns.find((p) => p.name === patternName);
              applyPatternSettings(pattern);
            }}
          >
            <MenuItem value="">
              <em>パターンを選択</em>
            </MenuItem>
            {patterns.map((pattern) => (
              <MenuItem key={pattern.name} value={pattern.name}>
                {pattern.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => setIsSavePatternDialogOpen(true)}
          sx={{ mt: 1 }}
        >
          現在の設定をパターンとして保存
        </Button>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => setIsManagePatternsDialogOpen(true)}
          sx={{ mt: 1, ml: 1 }}
        >
          パターンを管理
        </Button>
      </Box>

      {selectedFunctions.some((func) => func.name === 'applyTemplate') && (
        <Paper sx={{ p: 2, mt: 2 }}>
          <Typography variant="h6">1. テンプレート適用</Typography>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel>テンプレートを選択</InputLabel>
                <Select
                  value={selectedTemplateName}
                  onChange={(e) => setSelectedTemplateName(e.target.value)}
                >
                  {templateList.map((template) => (
                    <MenuItem key={template.name} value={template.name}>
                      {template.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Paper>
      )}

      {selectedFunctions.some((func) => func.name === 'applyPriceChanges') && (
        <Paper sx={{ p: 2, mt: 2 }}>
          <Typography variant="h6">2. 価格機能</Typography>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            {shippingRateTemplates.length > 0 && (
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel>送料設定を選択</InputLabel>
                  <Select
                    value={selectedShippingTemplateId}
                    onChange={(e) => setSelectedShippingTemplateId(e.target.value)}
                  >
                    {shippingRateTemplates.map((template) => (
                      <MenuItem key={template.id} value={template.id}>
                        {template.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}
          </Grid>
          <Box sx={{ mt: 2 }}>
            <Typography variant="subtitle1">現在の価格設定</Typography>
            <Typography>為替レート: 1 USD = {exchangeRate} JPY</Typography>
            <Typography>目標利益率: {targetProfitMargin}%</Typography>
            <Typography>eBay最終価値手数料: {fees.ebayFinalValue || 0}%</Typography>
            <Typography>Payoneer手数料: {fees.payoneer || 0}%</Typography>
            <Typography>広告出品手数料: {fees.promotedListing || 0}%</Typography>
            <Typography>eBay取引手数料: {fees.ebayTransactionFee || 0} USD</Typography>
            <Typography>
              ベストオファー自動承認価格:{' '}
              {bestOfferSettings.bestOfferAutoAcceptPercentage || 0}%
            </Typography>
            <Typography>
              最小ベストオファー価格:{' '}
              {bestOfferSettings.minimumBestOfferPercentage || 0}%
            </Typography>
          </Box>
        </Paper>
      )}

      {selectedFunctions.some((func) => func.name === 'applyAITitles') && (
        <Paper sx={{ p: 2, mt: 2 }}>
          <Typography variant="h6">3. AIタイトル</Typography>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel>タイトルカスタマイズテンプレートを選択</InputLabel>
                <Select
                  value={selectedCategory}
                  onChange={(e) => {
                    const selectedName = e.target.value;
                    setSelectedCategory(selectedName);

                    if (selectedName) {
                      const category = customCategories.find((cat) => cat.name === selectedName);
                      if (category) {
                        setDeleteStrings(category.deleteStrings || []);
                        setReplacePairs(category.replacePairs || []);
                        setPrependText(category.prependText || '');
                        setAppendText(category.appendText || '');
                        setLimitTitleLength(category.limitTitleLength || false);
                      }
                    } else {
                      setDeleteStrings([]);
                      setReplacePairs([]);
                      setPrependText('');
                      setAppendText('');
                      setLimitTitleLength(false);
                    }
                  }}
                >
                  {customCategories.map((cat) => (
                    <MenuItem key={cat.name} value={cat.name}>
                      {cat.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <Box sx={{ mt: 2 }}>
            <Typography variant="subtitle1">タイトルカスタマイズ設定</Typography>
            <Grid container spacing={2} sx={{ mt: 1 }}>
              <Grid item xs={12}>
                <TextField
                  label="削除したい単語 (カンマ区切り)"
                  value={deleteStrings.join(', ')}
                  onChange={(e) =>
                    setDeleteStrings(e.target.value.split(',').map((s) => s.trim()))
                  }
                  fullWidth
                />
              </Grid>

              <Grid item xs={12}>
                <Typography variant="subtitle1">置換ペア</Typography>
                {replacePairs.map((pair, index) => (
                  <Box key={index} sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                    <TextField
                      label="置換前"
                      value={pair.from}
                      onChange={(e) => handleReplacePairChange(index, 'from', e.target.value)}
                    />
                    <TextField
                      label="置換後"
                      value={pair.to}
                      onChange={(e) => handleReplacePairChange(index, 'to', e.target.value)}
                      sx={{ ml: 1 }}
                    />
                    <IconButton onClick={() => handleRemoveReplacePair(index)}>
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                ))}
                <Button variant="outlined" onClick={handleAddReplacePair} sx={{ mt: 1 }}>
                  置換ペアを追加
                </Button>
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  label="先頭に追加する文字列"
                  value={prependText}
                  onChange={(e) => setPrependText(e.target.value)}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  label="末尾に追加する文字列"
                  value={appendText}
                  onChange={(e) => setAppendText(e.target.value)}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={limitTitleLength}
                      onChange={(e) => setLimitTitleLength(e.target.checked)}
                    />
                  }
                  label="80文字に制限する"
                />
              </Grid>
            </Grid>
          </Box>
        </Paper>
      )}

      {selectedFunctions.some((func) => func.name === 'applyAIProductDescriptions') && (
        <Paper sx={{ p: 2, mt: 2 }}>
          <Typography variant="h6">4. AI商品説明</Typography>
          <Grid container spacing={2} sx={{ mt: 1 }}>
          {designTemplateNames.length > 0 && (
            // 既存のデザインテンプレート選択UIここから下は変更なし
            <Grid item xs={12} sm={4}>
              <FormControl fullWidth>
                <InputLabel>デザインテンプレートを選択</InputLabel>
                <Select
                  value={selectedDesignTemplate}
                  onChange={(e) => setSelectedDesignTemplate(e.target.value)}
                >
                  {designTemplateNames.map((templateName) => (
                    <MenuItem key={templateName} value={templateName}>
                      {templateName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {selectedDesignTemplate && (
                <Typography variant="body2" sx={{ mt: 1 }}>
                  選択中のテンプレート: {selectedDesignTemplate}
                </Typography>
              )}
            </Grid>
          )}

            <Grid item xs={12} sm={4}>

            {templates && templates.length > 0 && (
              <Box sx={{ mt: 2 }}>
                <FormControl fullWidth>
                  <InputLabel>テキスト変換テンプレートを選択</InputLabel>
                  <Select
                    value={selectedTemplateId}
                    onChange={(e) => {
                      const templateId = e.target.value;
                      handleLoadTemplate(templateId); // テンプレートを読み込む
                    }}
                  >
                    <MenuItem value="">
                      <em>選択なし</em>
                    </MenuItem>
                    {templates.map((tpl) => (
                      <MenuItem key={tpl.id} value={tpl.id}>
                        {tpl.name || 'Unnamed Template'}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            )}

              <FormControlLabel
                control={
                  <Switch
                    checked={useImageDescription}
                    onChange={(e) => setUseImageDescription(e.target.checked)}
                    name="useImageDescription"
                    color="primary"
                  />
                }
                label="画像解析の説明を使用"
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <Typography variant="subtitle1">必ず含めたいキーワード</Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                <TextField
                  label="キーワードを入力"
                  value={mandatoryKeywordInput}
                  onChange={(e) => setMandatoryKeywordInput(e.target.value)}
                  fullWidth
                />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleAddMandatoryKeyword}
                  sx={{ ml: 1, height: '56px' }}
                >
                  追加
                </Button>
              </Box>
              <List>
                {mandatoryKeywords.map((keyword, index) => (
                  <ListItem
                    key={index}
                    secondaryAction={
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={() => handleDeleteMandatoryKeyword(index)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    }
                  >
                    <ListItemText primary={keyword} />
                  </ListItem>
                ))}
              </List>
            </Grid>
          </Grid>

          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={12} sm={4}>
              <OptionsPanel
                selectedFilters={selectedFilters}
                handleFilterChange={handleFilterChange}
                customFilterInput={customFilterInput}
                setCustomFilterInput={setCustomFilterInput}
                customFilters={customFilters}
                handleAddCustomFilter={handleAddCustomFilter}
                handleDeleteCustomFilter={handleDeleteCustomFilter}
                sentencesToDelete={sentencesToDelete}
                setSentencesToDelete={setSentencesToDelete}
                wordsToReplace={wordsToReplace}
                setWordsToReplace={setWordsToReplace}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <Typography variant="subtitle1">削除したい文章</Typography>
              <TextField
                label="文章を入力"
                value={sentenceToDeleteInput}
                onChange={(e) => setSentenceToDeleteInput(e.target.value)}
                fullWidth
              />
              <Button
                variant="contained"
                color="primary"
                onClick={handleAddSentenceToDelete}
                sx={{ mt: 1 }}
              >
                追加
              </Button>
              <List>
                {sentencesToDelete.map((sentence, index) => (
                  <ListItem
                    key={index}
                    secondaryAction={
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={() => handleDeleteSentenceToDelete(index)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    }
                  >
                    <ListItemText primary={sentence} />
                  </ListItem>
                ))}
              </List>
            </Grid>

            <Grid item xs={12} sm={4}>
              <Typography variant="subtitle1">置換単語</Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                <TextField
                  label="置換前"
                  value={wordToReplaceFrom}
                  onChange={(e) => setWordToReplaceFrom(e.target.value)}
                  fullWidth
                />
                <Typography sx={{ mx: 1 }}>➔</Typography>
                <TextField
                  label="置換後"
                  value={wordToReplaceTo}
                  onChange={(e) => setWordToReplaceTo(e.target.value)}
                  fullWidth
                />
              </Box>
              <Button
                variant="contained"
                color="primary"
                onClick={handleAddWordToReplace}
                sx={{ mt: 1 }}
              >
                追加
              </Button>
              <List>
                {wordsToReplace.map((wordPair, index) => (
                  <ListItem
                    key={index}
                    secondaryAction={
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={() => handleDeleteWordToReplace(index)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    }
                  >
                    <ListItemText primary={`${wordPair.from} ➔ ${wordPair.to}`} />
                  </ListItem>
                ))}
              </List>
            </Grid>
          </Grid>
        </Paper>
      )}

      {selectedFunctions.some((func) => func.name === 'applyItemSpecifics') && (
        <Paper sx={{ p: 2, mt: 2 }}>
          <Typography variant="h6">5. Item Specifics適用</Typography>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel>適用するカテゴリー</InputLabel>
                <Select
                  value={selectedItemSpecificsCategory}
                  onChange={(e) => {
                    setSelectedItemSpecificsCategory(e.target.value);
                    setSelectedItemSpecificsColumns([]);
                  }}
                  label="適用するカテゴリー"
                >
                  {Object.keys(itemSpecificsCategories).map((category) => (
                    <MenuItem key={category} value={category}>
                      {category}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          {selectedItemSpecificsCategory && (
            <>
              <Grid container spacing={2} sx={{ mt: 2 }}>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel>適用するカラムを選択</InputLabel>
                    <Select
                      multiple
                      value={selectedItemSpecificsColumns}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (value.includes('all')) {
                          const allColumns = getColumnsForSelectedCategory();
                          if (
                            selectedItemSpecificsColumns.length === allColumns.length
                          ) {
                            setSelectedItemSpecificsColumns([]);
                          } else {
                            setSelectedItemSpecificsColumns(allColumns);
                          }
                        } else {
                          setSelectedItemSpecificsColumns(value);
                        }
                      }}
                      renderValue={(selected) => {
                        if (selected.length === 0) {
                          return '適用するカラムを選択';
                        }

                        const displayedValues = selected.slice(0, 5);
                        const hiddenCount = selected.length - displayedValues.length;

                        return (
                          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                            {displayedValues.map((value) => (
                              <Chip key={value} label={value} size="small" />
                            ))}
                            {hiddenCount > 0 && (
                              <Chip label={`+${hiddenCount}個`} size="small" />
                            )}
                          </Box>
                        );
                      }}
                    >
                      <MenuItem
                        value="all"
                        onClick={() => {
                          const allColumns = getColumnsForSelectedCategory();
                          if (selectedItemSpecificsColumns.length === allColumns.length) {
                            setSelectedItemSpecificsColumns([]);
                          } else {
                            setSelectedItemSpecificsColumns(allColumns);
                          }
                        }}
                      >
                        <Checkbox
                          checked={
                            selectedItemSpecificsColumns.length ===
                              getColumnsForSelectedCategory().length &&
                            selectedItemSpecificsColumns.length > 0
                          }
                          indeterminate={
                            selectedItemSpecificsColumns.length > 0 &&
                            selectedItemSpecificsColumns.length <
                              getColumnsForSelectedCategory().length
                          }
                        />
                        <Typography variant="body1">全選択</Typography>
                      </MenuItem>
                      {getColumnsForSelectedCategory().map((column) => (
                        <MenuItem key={column} value={column}>
                          <Checkbox
                            checked={selectedItemSpecificsColumns.indexOf(column) > -1}
                          />
                          <Typography variant="body1">{column}</Typography>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>

              <Box sx={{ mt: 2 }}>
                <Typography variant="subtitle1" gutterBottom>
                  マッチングの基準を選択してください。
                </Typography>
                <FormControl component="fieldset">
                  <RadioGroup
                    value={itemSpecificsMatchingOptions.matchSource}
                    onChange={(e) =>
                      setItemSpecificsMatchingOptions((prev) => ({
                        ...prev,
                        matchSource: e.target.value,
                      }))
                    }
                  >
                    <FormControlLabel
                      value="title"
                      control={<Radio />}
                      label="タイトルのみ"
                    />
                    <FormControlLabel
                      value="description"
                      control={<Radio />}
                      label="商品説明のみ (AI生成部分のみ)"
                    />
                    <FormControlLabel
                      value="both"
                      control={<Radio />}
                      label="タイトルと商品説明の両方 (AI生成部分のみ)"
                    />
                  </RadioGroup>
                </FormControl>
              </Box>

              <Box sx={{ mt: 2 }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={itemSpecificsMatchingOptions.caseSensitive}
                      onChange={(e) =>
                        setItemSpecificsMatchingOptions((prev) => ({
                          ...prev,
                          caseSensitive: e.target.checked,
                        }))
                      }
                      name="caseSensitive"
                    />
                  }
                  label="大文字小文字を区別する"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={itemSpecificsMatchingOptions.partialMatch}
                      onChange={(e) =>
                        setItemSpecificsMatchingOptions((prev) => ({
                          ...prev,
                          partialMatch: e.target.checked,
                        }))
                      }
                      name="partialMatch"
                    />
                  }
                  label="部分一致を許可する"
                />
              </Box>

              <Box sx={{ mt: 2 }}>
                <Typography variant="subtitle1" gutterBottom>
                  マッチングの動作を選択してください。
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                  (上記でAI商品説明からマッチングを選択している場合は、自動処理でAI生成した文章からカラムの適所に適用されます。自動処理によって特に値の変更がなかった場合以下の設定が適用されます。)
                </Typography>
                <FormControl component="fieldset">
                  <RadioGroup
                    value={itemSpecificsMatchingOptions.matchingOption}
                    onChange={(e) =>
                      setItemSpecificsMatchingOptions((prev) => ({
                        ...prev,
                        matchingOption: e.target.value,
                      }))
                    }
                  >
                    <FormControlLabel
                      value="priority"
                      control={<Radio />}
                      label="優先順位に基づいて適用"
                    />
                    <FormControlLabel
                      value="all"
                      control={<Radio />}
                      label="マッチしたすべての値を適用"
                    />
                  </RadioGroup>
                </FormControl>
              </Box>
            </>
          )}
        </Paper>
      )}

      <Box sx={{ mt: 2 }}>
        <Typography variant="subtitle1">実行モード:</Typography>
        <Button
          variant={isImmediate ? 'contained' : 'outlined'}
          onClick={() => setIsImmediate(true)}
          sx={{ mr: 1 }}
        >
          即時実行
        </Button>
        <Button
          variant={!isImmediate ? 'contained' : 'outlined'}
          onClick={() => setIsImmediate(false)}
        >
          スケジュール実行
        </Button>
      </Box>

      {!isImmediate && (
        <Box sx={{ mt: 2 }}>
          <TextField
            label="実行開始時間"
            type="datetime-local"
            value={scheduledTime}
            onChange={(e) => setScheduledTime(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
          />
        </Box>
      )}

      <Button
        variant="contained"
        color="secondary"
        sx={{ mt: 2 }}
        onClick={handleUploadAndExecute}
        disabled={isScheduling}
      >
        {isScheduling ? '処理中...' : 'アップロードして実行'}
      </Button>

      {isScheduling && (
        <Box sx={{ mt: 2 }}>
          <Typography variant="h6">処理進捗:</Typography>
          <Typography>
            {progress.completedItems} / {progress.totalItems} 件が完了しました
          </Typography>
          <LinearProgress
            variant="determinate"
            value={(progress.completedItems / progress.totalItems) * 100}
          />
        </Box>
      )}

      {processedParsedData.length > 0 && (
        <Box sx={{ mt: 4 }}>
          <Typography variant="h6">処理結果のプレビュー:</Typography>
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons="auto"
          >
            {processedParsedData.map((file, index) => (
              <Tab key={index} label={file.name} />
            ))}
          </Tabs>
          {processedParsedData.map((file, index) => (
            <div
              key={index}
              role="tabpanel"
              hidden={activeTab !== index}
              id={`tabpanel-${index}`}
            >
              {activeTab === index && (
                <Box sx={{ p: 2 }}>
                  <Paper elevation={2} sx={{ overflowX: 'auto' }}>
                    <TableContainer>
                      <Table
                        size="small"
                        sx={{
                          tableLayout: 'auto',
                          width: 'auto',
                        }}
                      >
                        <TableHead>
                          <TableRow>
                            {file.columns.map((column, idx) => (
                              <TableCell
                                key={idx}
                                sx={{
                                  maxWidth: '150px',
                                  whiteSpace: 'nowrap',
                                  padding: '8px',
                                  border: '1px solid rgba(224, 224, 224, 1)',
                                  fontWeight: 'bold',
                                }}
                              >
                                {column}
                              </TableCell>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {file.data.map((row, rowIndex) => (
                            <TableRow
                              key={rowIndex}
                              onClick={() => {
                                setSelectedProduct(row);
                                setIsDrawerOpen(true);
                              }}
                              style={{ cursor: 'pointer' }}
                            >
                              {file.columns.map((column, colIndex) => (
                                <TableCell
                                  key={colIndex}
                                  sx={{
                                    maxWidth: '150px',
                                    whiteSpace: 'nowrap',
                                    padding: '8px',
                                    border: '1px solid rgba(224, 224, 224, 1)',
                                  }}
                                >
                                  {column === 'PicURL' ? (
                                    row[column] ? (
                                      <img
                                        src={getFirstImageUrl(row[column])}
                                        alt="商品画像"
                                        style={{
                                          width: '50px',
                                          height: '50px',
                                          objectFit: 'cover',
                                        }}
                                      />
                                    ) : (
                                      'なし'
                                    )
                                  ) : (
                                    row[column]
                                  )}
                                </TableCell>
                              ))}
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Paper>

                  <Drawer
                    anchor="right"
                    open={isDrawerOpen}
                    onClose={() => setIsDrawerOpen(false)}
                  >
                    <Box sx={{ width: 700, p: 2 }}>
                      {selectedProduct ? (
                        <>
                          <Typography variant="h6">{selectedProduct['Title']}</Typography>
                          <ImageGallery picUrlString={selectedProduct['PicURL']} />
                          <Typography variant="body1" sx={{ mt: 2 }}>
                            価格: {selectedProduct['StartPrice']}
                          </Typography>
                          <Typography variant="body1" sx={{ mt: 2 }}>説明:</Typography>
                          <Box
                            sx={{ mt: 2 }}
                            dangerouslySetInnerHTML={{ __html: selectedProduct['Description'] }}
                          />
                        </>
                      ) : (
                        <Typography variant="body1">商品が選択されていません</Typography>
                      )}
                    </Box>
                  </Drawer>

                  <Dialog
                    open={isImageModalOpen}
                    onClose={handleCloseImageModal}
                    maxWidth="lg"
                  >
                    <DialogContent>
                      <img
                        src={currentImageUrl}
                        alt="拡大画像"
                        style={{ width: '100%', height: 'auto', objectFit: 'contain' }}
                      />
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleCloseImageModal}>閉じる</Button>
                    </DialogActions>
                  </Dialog>
                </Box>
              )}
            </div>
          ))}
        </Box>
      )}

      {errorItems.length > 0 && (
        <Box sx={{ mt: 2 }}>
          <Typography variant="h6" color="error">
            エラーが発生したアイテム:
          </Typography>
          <List>
            {errorItems.map((item, index) => (
              <ListItem key={index}>
                <Typography>
                  ファイル名: {item.fileName}, 行番号: {item.rowIndex}, エラー: {item.error}
                </Typography>
              </ListItem>
            ))}
          </List>
        </Box>
      )}

      {processedData.length > 0 && (
        <Box sx={{ mt: 2 }}>
          <Typography variant="h6">処理結果のダウンロード:</Typography>
          {processedData.map((file, index) => (
            <Button
              key={index}
              variant="contained"
              sx={{ mt: 1, mr: 1 }}
              onClick={() => {
                const blob = new Blob([file.content], {
                  type: 'text/csv;charset=utf-8;',
                });
                const link = document.createElement('a');
                link.href = URL.createObjectURL(blob);
                link.setAttribute('download', file.name);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
              }}
            >
              {file.name}
            </Button>
          ))}
        </Box>
      )}

      <Dialog open={functionDialogOpen} onClose={handleFunctionDialogClose}>
        <DialogTitle>実行する機能を選択</DialogTitle>
        <DialogContent>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="functions">
              {(provided) => (
                <List {...provided.droppableProps} ref={provided.innerRef}>
                  {availableFunctions.map((func, index) => (
                    <Draggable key={func.name} draggableId={func.name} index={index}>
                      {(provided) => (
                        <ListItem
                          button
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                        >
                          <Checkbox
                            checked={
                              selectedFunctions.findIndex((f) => f.name === func.name) !== -1
                            }
                            onClick={handleFunctionToggle(func)}
                          />
                          <Typography>{func.displayName}</Typography>
                          <IconButton {...provided.dragHandleProps}>
                            <DragHandleIcon />
                          </IconButton>
                        </ListItem>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </List>
              )}
            </Droppable>
          </DragDropContext>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleFunctionDialogClose}>閉じる</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={isSaveTemplateDialogOpen}
        onClose={() => setIsSaveTemplateDialogOpen(false)}
      >
        <DialogTitle>テンプレートの保存</DialogTitle>
        <DialogContent>
          <FormControl component="fieldset">
            <RadioGroup
              value={saveOption}
              onChange={(e) => setSaveOption(e.target.value)}
            >
              <FormControlLabel
                value="new"
                control={<Radio />}
                label="新しいテンプレートとして保存"
              />
              {selectedTemplateId && (
                <FormControlLabel
                  value="overwrite"
                  control={<Radio />}
                  label="既存のテンプレートを上書き保存"
                />
              )}
            </RadioGroup>
          </FormControl>
          <TextField
            label="テンプレート名"
            value={templateName}
            onChange={(e) => setTemplateName(e.target.value)}
            fullWidth
            sx={{ mt: 2 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsSaveTemplateDialogOpen(false)}>キャンセル</Button>
          <Button onClick={saveTemplate} color="primary">
            保存
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={isManageTemplatesDialogOpen}
        onClose={() => setIsManageTemplatesDialogOpen(false)}
      >
        <DialogTitle>テンプレートの管理</DialogTitle>
        <DialogContent>
          <List>
            {templates.map((template) => (
              <ListItem key={template.id}>
                <ListItemText primary={template.name} />
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() => handleDeleteTemplate(template.id)}
                >
                  <DeleteIcon />
                </IconButton>
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsManageTemplatesDialogOpen(false)}>閉じる</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={isSavePatternDialogOpen}
        onClose={() => setIsSavePatternDialogOpen(false)}
      >
        <DialogTitle>現在の設定をパターンとして保存</DialogTitle>
        <DialogContent>
          <TextField
            label="パターン名"
            value={newPatternName}
            onChange={(e) => setNewPatternName(e.target.value)}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsSavePatternDialogOpen(false)}>キャンセル</Button>
          <Button onClick={saveCurrentSettingsAsPattern} color="primary">
            保存
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={isManagePatternsDialogOpen}
        onClose={() => setIsManagePatternsDialogOpen(false)}
      >
        <DialogTitle>パターンの管理</DialogTitle>
        <DialogContent>
          <List>
            {patterns.map((pattern) => (
              <ListItem key={pattern.name}>
                <ListItemText primary={pattern.name} />
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() => handleDeletePattern(pattern.name)}
                >
                  <DeleteIcon />
                </IconButton>
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsManagePatternsDialogOpen(false)}>閉じる</Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Paper>
  );
}

export default CsvSchedulerUploader;








// import React, { useState, useEffect, useCallback } from 'react';
// import {
//   Box,
//   Button,
//   Typography,
//   TextField,
//   Paper,
//   Snackbar,
//   Alert,
//   List,
//   ListItem,
//   Checkbox,
//   IconButton,
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogTitle,
//   FormControl,
//   InputLabel,
//   Select,
//   MenuItem,
//   Tabs,
//   Tab,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   LinearProgress,
//   Grid,
//   Switch,
//   ListItemText,
//   RadioGroup,
//   Radio,
//   FormControlLabel,
//   Chip,
//   Drawer,
//   Tooltip,
// } from '@mui/material';
// import { useNavigate } from 'react-router-dom';
// import MenuIcon from '@mui/icons-material/Menu';
// import DragHandleIcon from '@mui/icons-material/DragHandle';
// import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
// import VisibilityIcon from '@mui/icons-material/Visibility';
// import EditIcon from '@mui/icons-material/Edit';
// import MoneyOffIcon from '@mui/icons-material/MoneyOff';
// import ReplayIcon from '@mui/icons-material/Replay';
// import DeleteIcon from '@mui/icons-material/Delete';

// // Firebase のインポート
// import { auth, db } from './firebaseConfig';
// import {
//   getFirestore,
//   getDoc,
//   doc,
//   collection,
//   getDocs,
//   addDoc,
//   setDoc,
//   deleteDoc,
// } from 'firebase/firestore';

// // 機能関数のインポート
// import { applyTemplate, applyItemSpecifics } from './functions';
// // パーサーのインポート
// import Papa from 'papaparse';
// // 価格計算関数のインポート
// import { calculatePrices } from './calculatePrices';
// // AIタイトル生成関数のインポート
// import { applyAITitles } from './applyAITitles';
// // AI商品説明生成関数のインポート
// import { applyAIProductDescriptions } from './applyAIProductDescriptions';
// // CryptoJSのインポート
// import CryptoJS from 'crypto-js';
// // デザインテンプレートのインポート
// import { applyItemSpecificsProcessor } from './ItemSpecificsProcessor';
// import designTemplates from './designTemplates';

// // その他のコンポーネントのインポート
// import OptionsPanel from './OptionsPanel';

// function CsvSchedulerUploader({ user }) {
//   const [csvFiles, setCsvFiles] = useState([]);
//   const [fileContents, setFileContents] = useState([]);
//   const [snackbar, setSnackbar] = useState({
//     open: false,
//     message: '',
//     severity: 'info',
//   });

//   // 状態変数を定義
//   const [selectedTemplate, setSelectedTemplate] = useState('');
//   const [selectedItemSpecificsCategory, setSelectedItemSpecificsCategory] = useState('');
//   const [selectedItemSpecificsColumns, setSelectedItemSpecificsColumns] = useState([]);
//   const [itemSpecificsMatchingOptions, setItemSpecificsMatchingOptions] = useState({
//     caseSensitive: false,
//     partialMatch: true,
//     matchSource: 'title',
//     matchingOption: 'priority',
//   });
//   const [itemSpecificsCategories, setItemSpecificsCategories] = useState({});

//   const [selectedFunctions, setSelectedFunctions] = useState([]);
//   const [isImmediate, setIsImmediate] = useState(true);
//   const [scheduledTime, setScheduledTime] = useState('');
//   const [functionDialogOpen, setFunctionDialogOpen] = useState(false);
//   const [isScheduling, setIsScheduling] = useState(false);
//   const [processedData, setProcessedData] = useState([]);
//   const [activeTab, setActiveTab] = useState(0);

//   const [templateList, setTemplateList] = useState([]);
//   const [selectedTemplateName, setSelectedTemplateName] = useState('');

//   // 価格設定状態管理
//   const [selectedPriceSettings, setSelectedPriceSettings] = useState(null);
//   const [shippingRateTemplates, setShippingRateTemplates] = useState([]);
//   const [selectedShippingTemplateId, setSelectedShippingTemplateId] = useState('');

//   // その他の設定
//   const [exchangeRate, setExchangeRate] = useState(0);
//   const [targetProfitMargin, setTargetProfitMargin] = useState(0);
//   const [fees, setFees] = useState({});
//   const [bestOfferSettings, setBestOfferSettings] = useState({});

//   const [userApiKey, setUserApiKey] = useState('');
//   const [userSettings, setUserSettings] = useState({});

//   const [designTemplateNames, setDesignTemplateNames] = useState([]);
//   const [selectedDesignTemplate, setSelectedDesignTemplate] = useState('');

//   const [templates, setTemplates] = useState([]);
//   const [templateName, setTemplateName] = useState('');
//   const [isSaveTemplateDialogOpen, setIsSaveTemplateDialogOpen] = useState(false);
//   const [isManageTemplatesDialogOpen, setIsManageTemplatesDialogOpen] = useState(false);
//   const [selectedTemplateId, setSelectedTemplateId] = useState('');
//   const [currentTemplateName, setCurrentTemplateName] = useState('');
//   const [saveOption, setSaveOption] = useState('new');

//   const [customCategories, setCustomCategories] = useState([]);
//   const [selectedCategory, setSelectedCategory] = useState('');

//   const [useImageDescription, setUseImageDescription] = useState(false);
//   const [mandatoryKeywords, setMandatoryKeywords] = useState([]);
//   const [mandatoryKeywordInput, setMandatoryKeywordInput] = useState('');

//   const [sentenceToDeleteInput, setSentenceToDeleteInput] = useState('');
//   const [wordToReplaceFrom, setWordToReplaceFrom] = useState('');
//   const [wordToReplaceTo, setWordToReplaceTo] = useState('');

//   const navigate = useNavigate();

//   const [patterns, setPatterns] = useState([]);
//   const [selectedPatternName, setSelectedPatternName] = useState('');

//   const generateEncryptionKey = (uid) => {
//     const salt = 'your-fixed-salt-value';
//     return CryptoJS.PBKDF2(uid, salt, { keySize: 256 / 32 }).toString();
//   };

//   const [progress, setProgress] = useState({ totalItems: 0, completedItems: 0 });
//   const [errorItems, setErrorItems] = useState([]);

//   const [availableFunctions, setAvailableFunctions] = useState([
//     {
//       name: 'applyTemplate',
//       displayName: 'テンプレートを実行する',
//       func: applyTemplate,
//     },
//     {
//       name: 'applyPriceChanges',
//       displayName: '価格の変更を適用する',
//       func: 'applyPriceChanges',
//     },
//     {
//       name: 'applyAITitles',
//       displayName: 'AIタイトルを適用する',
//       func: 'applyAITitles',
//     },
//     {
//       name: 'applyAIProductDescriptions',
//       displayName: 'AI商品説明を適用する',
//       func: 'applyAIProductDescriptions',
//     },
//     {
//       name: 'applyItemSpecifics',
//       displayName: 'Item Specificsの適用をする',
//       func: applyItemSpecifics,
//     },
//   ]);

//   useEffect(() => {
//     const fetchItemSpecificsSettings = async () => {
//       if (!user) return;
//       try {
//         const db = getFirestore();
//         const docRef = doc(db, 'itemSpecificsSettings', user.uid);
//         const docSnap = await getDoc(docRef);

//         if (docSnap.exists()) {
//           const settingsData = docSnap.data();
//           setItemSpecificsCategories(settingsData.categories || {});
//         } else {
//           console.warn('Item Specificsの設定が見つかりませんでした。');
//         }
//       } catch (error) {
//         console.error('Item Specificsの設定取得中にエラー:', error);
//       }
//     };

//     fetchItemSpecificsSettings();
//   }, [user]);

//   const getColumnsForSelectedCategory = () => {
//     if (
//       selectedItemSpecificsCategory &&
//       itemSpecificsCategories[selectedItemSpecificsCategory]
//     ) {
//       return Object.keys(
//         itemSpecificsCategories[selectedItemSpecificsCategory].columns || {}
//       );
//     }
//     return [];
//   };

//   useEffect(() => {
//     if (!user) {
//       navigate('/login');
//     } else {
//       const fetchTemplates = async () => {
//         try {
//           const docRef = doc(db, 'userTemplates', user.uid);
//           const docSnap = await getDoc(docRef);

//           if (docSnap.exists()) {
//             const userData = docSnap.data();
//             const userCategories = userData.categories || {};
//             const templates = Object.values(userCategories)
//               .flat()
//               .map((template) => ({
//                 name: template.name,
//                 operations: template.operations,
//               }));
//             setTemplateList(templates);
//             if (templates.length > 0) {
//               setSelectedTemplateName(templates[0].name);
//             }
//           }
//         } catch (error) {
//           console.error('テンプレート取得エラー:', error);
//           setSnackbar({
//             open: true,
//             message: 'テンプレートの取得に失敗しました。',
//             severity: 'error',
//           });
//         }
//       };
//       fetchTemplates();

//       const fetchUserSettings = async () => {
//         try {
//           const docRef = doc(db, 'userSettings', user.uid);
//           const docSnap = await getDoc(docRef);

//           if (docSnap.exists()) {
//             const userSettingsData = docSnap.data();
//             setSelectedPriceSettings(userSettingsData);
//             setUserSettings(userSettingsData);

//             if (userSettingsData.shippingRateTemplates) {
//               setShippingRateTemplates(userSettingsData.shippingRateTemplates);
//               setSelectedShippingTemplateId(
//                 userSettingsData.selectedShippingTemplateId ||
//                   userSettingsData.shippingRateTemplates[0].id
//               );
//             }

//             setExchangeRate(userSettingsData.exchangeRate || 0);
//             setTargetProfitMargin(userSettingsData.targetProfitMargin || 0);
//             setFees(userSettingsData.fees || {});
//             setBestOfferSettings({
//               bestOfferAutoAcceptPercentage:
//                 userSettingsData.bestOfferAutoAcceptPercentage || 0,
//               minimumBestOfferPercentage:
//                 userSettingsData.minimumBestOfferPercentage || 0,
//             });

//             if (userSettingsData.designTemplates) {
//               const templateNames = Object.keys(userSettingsData.designTemplates);
//               setDesignTemplateNames(templateNames);
//               if (templateNames.length > 0) {
//                 setSelectedDesignTemplate(templateNames[0]);
//               } else {
//                 setSelectedDesignTemplate('defaultTemplate');
//               }
//             } else {
//               setSelectedDesignTemplate('simple');
//             }

//             setCustomFilters(userSettingsData.customFilters || []);
//             setSentencesToDelete(userSettingsData.sentencesToDelete || []);
//             setWordsToReplace(userSettingsData.wordsToReplace || []);
//             setMandatoryKeywords(userSettingsData.mandatoryKeywords || []);

//             if (userSettingsData.customCategories) {
//               setCustomCategories(userSettingsData.customCategories);
//             } else {
//               await loadTitleCustomizationTemplates();
//             }

//             if (userSettingsData.patterns) {
//               setPatterns(userSettingsData.patterns);
//             } else {
//               setPatterns([]);
//             }
//           } else {
//             await loadTitleCustomizationTemplates();
//           }

//           const apiKeyDocRef = doc(db, 'userApiKeys', user.uid);
//           const apiKeyDocSnap = await getDoc(apiKeyDocRef);

//           if (apiKeyDocSnap.exists()) {
//             const encryptedKey = apiKeyDocSnap.data().apiKey;
//             const encryptionKey = generateEncryptionKey(user.uid);
//             const bytes = CryptoJS.AES.decrypt(encryptedKey, encryptionKey);
//             const decryptedKey = bytes.toString(CryptoJS.enc.Utf8);
//             if (decryptedKey) {
//               setUserApiKey(decryptedKey);
//             } else {
//               console.error('APIキーの復号に失敗');
//             }
//           } else {
//             console.warn('APIキーが見つかりません');
//           }

//           loadTemplates();
//         } catch (error) {
//           console.error('ユーザー設定取得エラー:', error);
//           setSnackbar({
//             open: true,
//             message: 'ユーザー設定の取得に失敗しました。',
//             severity: 'error',
//           });
//         }
//       };
//       fetchUserSettings();

//       const loadTitleCustomizationTemplates = async () => {
//         if (!user) return;
//         try {
//           const docRef = doc(db, 'userTitleSettings', user.uid);
//           const docSnap = await getDoc(docRef);
//           if (docSnap.exists()) {
//             const data = docSnap.data();
//             setCustomCategories(data.customCategories || []);
//           }
//         } catch (error) {
//           console.error('タイトルカスタマイズテンプレート取得エラー:', error);
//         }
//       };
//     }
//   }, [user, navigate]);

//   const [customFilters, setCustomFilters] = useState([]);
//   const [sentencesToDelete, setSentencesToDelete] = useState([]);
//   const [wordsToReplace, setWordsToReplace] = useState([]);

//   const [selectedFilters, setSelectedFilters] = useState([]);
//   const [customFilterInput, setCustomFilterInput] = useState('');

//   const saveTemplate = async () => {
//     if (!templateName.trim()) {
//       setSnackbar({
//         open: true,
//         message: 'テンプレート名を入力してください',
//         severity: 'warning',
//       });
//       return;
//     }

//     const templateData = {
//       name: templateName.trim(),
//       customFilters,
//       sentencesToDelete,
//       wordsToReplace,
//       mandatoryKeywords,
//       selectedFilters,
//     };

//     try {
//       const userTemplatesRef = collection(db, 'users', user.uid, 'templates');

//       if (saveOption === 'overwrite' && selectedTemplateId) {
//         const templateDocRef = doc(userTemplatesRef, selectedTemplateId);
//         await setDoc(templateDocRef, templateData);
//         setSnackbar({
//           open: true,
//           message: 'テンプレートが更新されました',
//           severity: 'success',
//         });
//       } else {
//         await addDoc(userTemplatesRef, templateData);
//         setSnackbar({
//           open: true,
//           message: '新しいテンプレートが保存されました',
//           severity: 'success',
//         });
//       }

//       setIsSaveTemplateDialogOpen(false);
//       setTemplateName('');
//       loadTemplates();
//     } catch (error) {
//       console.error('テンプレート保存中エラー:', error);
//       setSnackbar({
//         open: true,
//         message: 'テンプレートの保存中にエラーが発生しました',
//         severity: 'error',
//       });
//     }
//   };

//   const loadTemplates = async () => {
//     try {
//       const userTemplatesRef = collection(db, 'users', user.uid, 'templates');
//       const templatesSnapshot = await getDocs(userTemplatesRef);
//       const templatesData = templatesSnapshot.docs.map((doc) => ({
//         id: doc.id,
//         ...doc.data(),
//       }));
//       setTemplates(templatesData);
//     } catch (error) {
//       console.error('テンプレート読み込みエラー:', error);
//     }
//   };

//   const handleLoadTemplate = async (templateId) => {
//     if (!templateId) {
//       setSelectedTemplateId('');
//       setTemplateName('');
//       setCurrentTemplateName('');
//       return;
//     }
//     setSelectedTemplateId(templateId);
//     try {
//       const templateDocRef = doc(db, 'users', user.uid, 'templates', templateId);
//       const templateDoc = await getDoc(templateDocRef);
//       if (templateDoc.exists()) {
//         const templateData = templateDoc.data();
//         setTemplateName(templateData.name || '');
//         setCurrentTemplateName(templateData.name || '');
//         setCustomFilters(templateData.customFilters || []);
//         setSentencesToDelete(templateData.sentencesToDelete || []);
//         setWordsToReplace(templateData.wordsToReplace || []);
//         setMandatoryKeywords(templateData.mandatoryKeywords || []);
//         setSelectedFilters(templateData.selectedFilters || []);
//         setSnackbar({
//           open: true,
//           message: 'テンプレートが適用されました',
//           severity: 'success',
//         });
//       } else {
//         setSnackbar({
//           open: true,
//           message: 'テンプレートが見つかりません',
//           severity: 'error',
//         });
//       }
//     } catch (error) {
//       console.error('テンプレート読み込みエラー:', error);
//       setSnackbar({
//         open: true,
//         message: 'テンプレートの読み込み中にエラーが発生しました',
//         severity: 'error',
//       });
//     }
//   };

//   const handleDeleteTemplate = async (templateId) => {
//     try {
//       const templateDocRef = doc(db, 'users', user.uid, 'templates', templateId);
//       await deleteDoc(templateDocRef);
//       setSnackbar({
//         open: true,
//         message: 'テンプレートが削除されました',
//         severity: 'success',
//       });
//       loadTemplates();
//     } catch (error) {
//       console.error('テンプレート削除エラー:', error);
//       setSnackbar({
//         open: true,
//         message: 'テンプレートの削除中にエラーが発生しました',
//         severity: 'error',
//       });
//     }
//   };

//   const handleCreateNewTemplate = () => {
//     setSelectedTemplateId('');
//     setTemplateName('');
//     setCurrentTemplateName('');
//     setCustomFilters([]);
//     setSelectedFilters([]);
//     setSentencesToDelete([]);
//     setWordsToReplace([]);
//     setMandatoryKeywords([]);
//     setSnackbar({
//       open: true,
//       message: '新しいテンプレートを作成します',
//       severity: 'info',
//     });
//   };

//   const handleAddMandatoryKeyword = () => {
//     if (mandatoryKeywordInput.trim() !== '') {
//       setMandatoryKeywords([...mandatoryKeywords, mandatoryKeywordInput.trim()]);
//       setMandatoryKeywordInput('');
//     }
//   };

//   const handleDeleteMandatoryKeyword = (index) => {
//     const updatedKeywords = [...mandatoryKeywords];
//     updatedKeywords.splice(index, 1);
//     setMandatoryKeywords(updatedKeywords);
//   };

//   const handleAddSentenceToDelete = () => {
//     if (sentenceToDeleteInput.trim() !== '') {
//       setSentencesToDelete([...sentencesToDelete, sentenceToDeleteInput.trim()]);
//       setSentenceToDeleteInput('');
//     }
//   };

//   const handleDeleteSentenceToDelete = (index) => {
//     const updatedSentences = [...sentencesToDelete];
//     updatedSentences.splice(index, 1);
//     setSentencesToDelete(updatedSentences);
//   };

//   const handleAddWordToReplace = () => {
//     if (wordToReplaceFrom.trim() !== '' && wordToReplaceTo.trim() !== '') {
//       setWordsToReplace([
//         ...wordsToReplace,
//         { from: wordToReplaceFrom.trim(), to: wordToReplaceTo.trim() },
//       ]);
//       setWordToReplaceFrom('');
//       setWordToReplaceTo('');
//     }
//   };

//   const handleDeleteWordToReplace = (index) => {
//     const updatedWords = [...wordsToReplace];
//     updatedWords.splice(index, 1);
//     setWordsToReplace(updatedWords);
//   };

//   const [deleteStrings, setDeleteStrings] = useState([]);
//   const [replacePairs, setReplacePairs] = useState([]);
//   const [prependText, setPrependText] = useState('');
//   const [appendText, setAppendText] = useState('');
//   const [limitTitleLength, setLimitTitleLength] = useState(false);

//   const handleAddReplacePair = () => {
//     setReplacePairs([...replacePairs, { from: '', to: '' }]);
//   };

//   const handleReplacePairChange = (index, field, value) => {
//     const newPairs = [...replacePairs];
//     newPairs[index][field] = value;
//     setReplacePairs(newPairs);
//   };

//   const handleRemoveReplacePair = (index) => {
//     const newPairs = [...replacePairs];
//     newPairs.splice(index, 1);
//     setReplacePairs(newPairs);
//   };

//   const handleFileChange = (event) => {
//     const files = Array.from(event.target.files).slice(0, 5);
//     setCsvFiles(files);
//     Promise.all(files.map((file) => file.text())).then((contents) => {
//       setFileContents(contents);
//     });
//   };

//   const handleFunctionDialogOpen = () => {
//     setFunctionDialogOpen(true);
//   };

//   const handleFunctionDialogClose = () => {
//     setFunctionDialogOpen(false);
//   };

//   const handleFunctionToggle = (func) => () => {
//     const currentIndex = selectedFunctions.findIndex((f) => f.name === func.name);
//     const newSelected = [...selectedFunctions];

//     if (currentIndex === -1) {
//       newSelected.push(func);
//     } else {
//       newSelected.splice(currentIndex, 1);
//     }

//     setSelectedFunctions(newSelected);
//   };

//   const onDragEnd = (result) => {
//     if (!result.destination) return;
//     const newFunctions = Array.from(selectedFunctions);
//     const [removed] = newFunctions.splice(result.source.index, 1);
//     newFunctions.splice(result.destination.index, 0, removed);
//     setSelectedFunctions(newFunctions);
//   };

//   const applyPriceChanges = useCallback(
//     async (currentData) => {
//       try {
//         if (!selectedPriceSettings) {
//           throw new Error('価格設定が取得されていません。');
//         }

//         const updatedPriceSettings = {
//           ...selectedPriceSettings,
//           selectedShippingTemplateId,
//         };

//         const updatedData = await Promise.all(
//           currentData.map(async (file) => {
//             const parsedResult = Papa.parse(file.content, { header: true });
//             const data = parsedResult.data;
//             const newPrices = calculatePrices(
//               data,
//               updatedPriceSettings,
//               selectedShippingTemplateId
//             );
//             const updatedData = data.map((item, index) => {
//               const calculatedPrice = newPrices[index];
//               if (calculatedPrice) {
//                 return {
//                   ...item,
//                   StartPrice: calculatedPrice.discountedPrice,
//                   BestOfferAutoAcceptPrice: calculatedPrice.bestOfferAutoAcceptPrice,
//                   MinimumBestOfferPrice: calculatedPrice.minimumBestOfferPrice,
//                 };
//               } else {
//                 return item;
//               }
//             });

//             const csvContent = Papa.unparse(updatedData);
//             return {
//               ...file,
//               content: csvContent,
//             };
//           })
//         );

//         return updatedData;
//       } catch (error) {
//         console.error('価格適用中エラー:', error);
//         setSnackbar({
//           open: true,
//           message: '価格適用中にエラーが発生しました。',
//           severity: 'error',
//         });
//         throw error;
//       }
//     },
//     [selectedPriceSettings, selectedShippingTemplateId, setSnackbar]
//   );

//   const applyAITitlesWrapper = useCallback(
//     async (currentData) => {
//       try {
//         if (!userApiKey) {
//           setSnackbar({
//             open: true,
//             message:
//               'OpenAI APIキーが設定されていません。ユーザー設定でAPIキーを入力してください。',
//             severity: 'error',
//           });
//           throw new Error('OpenAI APIキーが設定されていません。');
//         }

//         setProgress({ totalItems: 0, completedItems: 0 });
//         setErrorItems([]);

//         const customizationOptions = {
//           deleteStrings,
//           replacePairs,
//           prependText,
//           appendText,
//           limitTitleLength,
//         };

//         const { updatedData, errorItems } = await applyAITitles(
//           currentData,
//           userApiKey,
//           (progressData) => {
//             setProgress(progressData);
//           },
//           customizationOptions
//         );

//         setErrorItems(errorItems);
//         return updatedData;
//       } catch (error) {
//         console.error('AIタイトル適用中エラー:', error);
//         setSnackbar({
//           open: true,
//           message: 'AIタイトル適用中にエラーが発生しました。',
//           severity: 'error',
//         });
//         throw error;
//       }
//     },
//     [
//       userApiKey,
//       setSnackbar,
//       setProgress,
//       setErrorItems,
//       deleteStrings,
//       replacePairs,
//       prependText,
//       appendText,
//       limitTitleLength,
//     ]
//   );

//   const applyAIProductDescriptionsWrapper = useCallback(
//     async (currentData) => {
//       try {
//         if (!userApiKey) {
//           setSnackbar({
//             open: true,
//             message:
//               'OpenAI APIキーが設定されていません。ユーザー設定でAPIキーを入力してください。',
//             severity: 'error',
//           });
//           throw new Error('OpenAI APIキーが設定されていません。');
//         }

//         setProgress({ totalItems: 0, completedItems: 0 });
//         setErrorItems([]);

//         const { updatedData, errorItems } = await applyAIProductDescriptions(
//           currentData,
//           userApiKey,
//           (progressData) => {
//             setProgress(progressData);
//           },
//           selectedDesignTemplate,
//           {
//             customFilters,
//             selectedFilters,
//             sentencesToDelete,
//             wordsToReplace,
//             mandatoryKeywords,
//           }
//         );

//         setErrorItems(errorItems);
//         return updatedData;
//       } catch (error) {
//         console.error('AI商品説明適用中エラー:', error);
//         setSnackbar({
//           open: true,
//           message: 'AI商品説明適用中にエラーが発生しました。',
//           severity: 'error',
//         });
//         throw error;
//       }
//     },
//     [
//       userApiKey,
//       setSnackbar,
//       setProgress,
//       setErrorItems,
//       selectedDesignTemplate,
//       customFilters,
//       selectedFilters,
//       sentencesToDelete,
//       wordsToReplace,
//       mandatoryKeywords,
//     ]
//   );

//   const applyItemSpecificsWrapper = useCallback(
//     async (currentData, options) => {
//       const {
//         selectedItemSpecificsCategory,
//         selectedItemSpecificsColumns,
//         itemSpecificsMatchingOptions,
//       } = options;
//       try {
//         if (!user) {
//           setSnackbar({
//             open: true,
//             message: 'ユーザー情報が取得できません。',
//             severity: 'error',
//           });
//           throw new Error('ユーザー情報が取得できません。');
//         }

//         if (!selectedItemSpecificsCategory) {
//           setSnackbar({
//             open: true,
//             message: '適用するカテゴリーを選択してください。',
//             severity: 'warning',
//           });
//           throw new Error('適用するカテゴリーを選択してください。');
//         }

//         setProgress({ totalItems: 0, completedItems: 0 });

//         const updatedData = await applyItemSpecificsProcessor(
//           currentData,
//           user.uid,
//           {
//             selectedCategory: selectedItemSpecificsCategory,
//             selectedColumns: selectedItemSpecificsColumns,
//             matchingOptions: itemSpecificsMatchingOptions,
//           },
//           (progressData) => {
//             setProgress(progressData);
//           }
//         );

//         return updatedData;
//       } catch (error) {
//         console.error('Item Specifics適用中エラー:', error);
//         setSnackbar({
//           open: true,
//           message: error.message || 'Item Specifics適用中にエラーが発生しました。',
//           severity: 'error',
//         });
//         throw error;
//       }
//     },
//     [user, setSnackbar, setProgress]
//   );

//   useEffect(() => {
//     setAvailableFunctions((prevFunctions) =>
//       prevFunctions.map((func) => {
//         if (func.name === 'applyPriceChanges') {
//           return { ...func, func: applyPriceChanges };
//         }
//         if (func.name === 'applyAITitles') {
//           return { ...func, func: applyAITitlesWrapper };
//         }
//         if (func.name === 'applyAIProductDescriptions') {
//           return { ...func, func: applyAIProductDescriptionsWrapper };
//         }
//         return func;
//       })
//     );
//   }, [applyPriceChanges, applyAITitlesWrapper, applyAIProductDescriptionsWrapper]);

//   useEffect(() => {
//     setAvailableFunctions((prevFunctions) =>
//       prevFunctions.map((func) =>
//         func.name === 'applyItemSpecifics'
//           ? { ...func, func: applyItemSpecificsWrapper }
//           : func
//       )
//     );
//   }, [applyItemSpecificsWrapper]);

//   const applyPatternSettings = (pattern) => {
//     if (!pattern || !pattern.settings) {
//       setSnackbar({
//         open: true,
//         message: '選択されたパターンが正しくありません。',
//         severity: 'error',
//       });
//       return;
//     }

//     const settings = pattern.settings;

//     if (settings.applyTemplate && settings.applyTemplate.selectedTemplateName) {
//       setSelectedTemplateName(settings.applyTemplate.selectedTemplateName);
//     }

//     if (settings.applyPriceChanges && settings.applyPriceChanges.selectedShippingTemplateId) {
//       setSelectedShippingTemplateId(settings.applyPriceChanges.selectedShippingTemplateId);
//     }

//     if (settings.applyAITitles) {
//       setDeleteStrings(settings.applyAITitles.deleteStrings || []);
//       setReplacePairs(settings.applyAITitles.replacePairs || []);
//       setPrependText(settings.applyAITitles.prependText || '');
//       setAppendText(settings.applyAITitles.appendText || '');
//       setLimitTitleLength(settings.applyAITitles.limitTitleLength || false);
//     }

//     if (settings.applyAIProductDescriptions && settings.applyAIProductDescriptions.selectedDesignTemplate) {
//       setSelectedDesignTemplate(settings.applyAIProductDescriptions.selectedDesignTemplate);
//       setCustomFilters(settings.applyAIProductDescriptions.customFilters || []);
//       setSentencesToDelete(settings.applyAIProductDescriptions.sentencesToDelete || []);
//       setWordsToReplace(settings.applyAIProductDescriptions.wordsToReplace || []);
//       setMandatoryKeywords(settings.applyAIProductDescriptions.mandatoryKeywords || []);
//     }

//     if (settings.applyItemSpecifics && settings.applyItemSpecifics.selectedItemSpecificsCategory) {
//       setSelectedItemSpecificsCategory(settings.applyItemSpecifics.selectedItemSpecificsCategory);
//       setSelectedItemSpecificsColumns(settings.applyItemSpecifics.selectedItemSpecificsColumns || []);
//       setItemSpecificsMatchingOptions(settings.applyItemSpecifics.itemSpecificsMatchingOptions || itemSpecificsMatchingOptions);
//     }

//     const newSelectedFunctions = availableFunctions.filter((func) =>
//       Object.keys(settings).includes(func.name)
//     );
//     setSelectedFunctions(newSelectedFunctions);

//     setSnackbar({
//       open: true,
//       message: `パターン "${pattern.name}" が適用されました。`,
//       severity: 'success',
//     });
//   };

//   const saveCurrentSettingsAsPattern = async () => {
//     if (!newPatternName.trim()) {
//       setSnackbar({
//         open: true,
//         message: 'パターン名を入力してください',
//         severity: 'warning',
//       });
//       return;
//     }

//     const newPattern = {
//       name: newPatternName.trim(),
//       settings: {},
//     };

//     selectedFunctions.forEach((func) => {
//       if (func.name === 'applyTemplate') {
//         newPattern.settings.applyTemplate = { selectedTemplateName };
//       }
//       if (func.name === 'applyPriceChanges') {
//         newPattern.settings.applyPriceChanges = { selectedShippingTemplateId };
//       }
//       if (func.name === 'applyAITitles') {
//         newPattern.settings.applyAITitles = {
//           deleteStrings,
//           replacePairs,
//           prependText,
//           appendText,
//           limitTitleLength,
//         };
//       }
//       if (func.name === 'applyAIProductDescriptions') {
//         newPattern.settings.applyAIProductDescriptions = {
//           selectedDesignTemplate,
//           customFilters,
//           sentencesToDelete,
//           wordsToReplace,
//           mandatoryKeywords,
//         };
//       }
//       if (func.name === 'applyItemSpecifics') {
//         newPattern.settings.applyItemSpecifics = {
//           selectedItemSpecificsCategory,
//           selectedItemSpecificsColumns,
//           itemSpecificsMatchingOptions,
//         };
//       }
//     });

//     try {
//       const userSettingsRef = doc(db, 'userSettings', user.uid);
//       const docSnap = await getDoc(userSettingsRef);
//       let existingPatterns = [];
//       if (docSnap.exists()) {
//         existingPatterns = docSnap.data().patterns || [];
//       }

//       const patternExists = existingPatterns.some(
//         (pattern) => pattern.name === newPattern.name
//       );

//       if (patternExists) {
//         setSnackbar({
//           open: true,
//           message: '同名のパターンが既に存在します。',
//           severity: 'error',
//         });
//         return;
//       }

//       existingPatterns.push(newPattern);
//       await setDoc(userSettingsRef, { patterns: existingPatterns }, { merge: true });
//       setPatterns(existingPatterns);
//       setIsSavePatternDialogOpen(false);
//       setNewPatternName('');
//       setSnackbar({
//         open: true,
//         message: 'パターンが保存されました。',
//         severity: 'success',
//       });
//     } catch (error) {
//       console.error('パターン保存中エラー:', error);
//       setSnackbar({
//         open: true,
//         message: 'パターンの保存中にエラーが発生しました。',
//         severity: 'error',
//       });
//     }
//   };

//   const handleDeletePattern = async (patternName) => {
//     try {
//       const userSettingsRef = doc(db, 'userSettings', user.uid);
//       const docSnap = await getDoc(userSettingsRef);
//       if (docSnap.exists()) {
//         let existingPatterns = docSnap.data().patterns || [];
//         existingPatterns = existingPatterns.filter((p) => p.name !== patternName);
//         await setDoc(userSettingsRef, { patterns: existingPatterns }, { merge: true });
//         setPatterns(existingPatterns);
//         setSnackbar({
//           open: true,
//           message: 'パターンが削除されました。',
//           severity: 'success',
//         });
//       }
//     } catch (error) {
//       console.error('パターン削除エラー:', error);
//       setSnackbar({
//         open: true,
//         message: 'パターンの削除中にエラーが発生しました。',
//         severity: 'error',
//       });
//     }
//   };

//   const [isSavePatternDialogOpen, setIsSavePatternDialogOpen] = useState(false);
//   const [newPatternName, setNewPatternName] = useState('');
//   const [isManagePatternsDialogOpen, setIsManagePatternsDialogOpen] = useState(false);

//   const handleUploadAndExecute = async () => {
//     if (!user) {
//       setSnackbar({
//         open: true,
//         message: 'ユーザーが認証されていません。',
//         severity: 'error',
//       });
//       return;
//     }

//     if (csvFiles.length === 0) {
//       setSnackbar({
//         open: true,
//         message: 'アップロードするファイルがありません。',
//         severity: 'warning',
//       });
//       return;
//     }

//     if (selectedFunctions.length === 0) {
//       setSnackbar({
//         open: true,
//         message: '実行する機能を選択してください。',
//         severity: 'warning',
//       });
//       return;
//     }

//     setIsScheduling(true);

//     try {
//       const filesData = csvFiles.map((file, index) => ({
//         name: file.name,
//         content: fileContents[index],
//       }));

//       const executeFunctions = async () => {
//         let currentData = filesData;

//         for (const func of selectedFunctions) {
//           try {
//             if (func.func.constructor.name === 'AsyncFunction') {
//               if (func.name === 'applyTemplate') {
//                 currentData = await func.func(currentData, user, selectedTemplateName);
//               } else if (func.name === 'applyItemSpecifics') {
//                 currentData = await func.func(currentData, {
//                   selectedItemSpecificsCategory,
//                   selectedItemSpecificsColumns,
//                   itemSpecificsMatchingOptions,
//                 });
//               } else {
//                 currentData = await func.func(currentData);
//               }
//             } else {
//               if (func.name === 'applyTemplate') {
//                 currentData = func.func(currentData, user, selectedTemplateName);
//               } else if (func.name === 'applyItemSpecifics') {
//                 currentData = func.func(currentData, {
//                   selectedItemSpecificsCategory,
//                   selectedItemSpecificsColumns,
//                   itemSpecificsMatchingOptions,
//                 });
//               } else {
//                 currentData = func.func(currentData);
//               }
//             }
//           } catch (error) {
//             console.error(`機能 "${func.displayName}" 実行中エラー:`, error);
//             setSnackbar({
//               open: true,
//               message: `機能 "${func.displayName}" 実行中にエラーが発生しました。`,
//               severity: 'error',
//             });
//             setIsScheduling(false);
//             return;
//           }
//         }

//         setProcessedData(currentData);

//         setSnackbar({
//           open: true,
//           message: '選択された機能を実行しました。',
//           severity: 'success',
//         });
//         setIsScheduling(false);
//       };

//       if (isImmediate) {
//         await executeFunctions();
//       } else {
//         const now = new Date();
//         const scheduledDateTime = new Date(scheduledTime);
//         const delay = scheduledDateTime - now;

//         if (delay > 0) {
//           setSnackbar({
//             open: true,
//             message: 'タスクがスケジュールされました。',
//             severity: 'success',
//           });
//           setTimeout(async () => {
//             await executeFunctions();
//           }, delay);
//         } else {
//           setSnackbar({
//             open: true,
//             message: '指定された時間は過去です。正しい時間を選択してください。',
//             severity: 'error',
//           });
//           setIsScheduling(false);
//         }
//       }
//     } catch (error) {
//       console.error('処理中エラー:', error);
//       setSnackbar({
//         open: true,
//         message: '処理中にエラーが発生しました。',
//         severity: 'error',
//       });
//       setIsScheduling(false);
//     }
//   };

//   const handleTabChange = (event, newValue) => {
//     setActiveTab(newValue);
//   };

//   const [parsedData, setParsedData] = useState([]);

//   useEffect(() => {
//     const parseCSVFiles = () => {
//       const data = fileContents.map((content) => {
//         const parsedResult = Papa.parse(content, { header: true });
//         return {
//           data: parsedResult.data,
//           columns: parsedResult.meta.fields,
//         };
//       });
//       setParsedData(data);
//     };

//     if (fileContents.length > 0) {
//       parseCSVFiles();
//     } else {
//       setParsedData([]);
//     }
//   }, [fileContents]);

//   const [processedParsedData, setProcessedParsedData] = useState([]);

//   useEffect(() => {
//     if (processedData.length > 0) {
//       const data = processedData.map((file) => {
//         const parsedResult = Papa.parse(file.content, { header: true });
//         return {
//           name: file.name,
//           data: parsedResult.data,
//           columns: parsedResult.meta.fields,
//         };
//       });
//       setProcessedParsedData(data);
//     } else {
//       setProcessedParsedData([]);
//     }
//   }, [processedData]);

//   const handleFilterChange = (label) => {
//     setSelectedFilters((prev) =>
//       prev.includes(label) ? prev.filter((item) => item !== label) : [...prev, label]
//     );
//   };

//   const handleAddCustomFilter = () => {
//     if (customFilterInput.trim() !== '') {
//       setCustomFilters([...customFilters, customFilterInput.trim()]);
//       setCustomFilterInput('');
//     }
//   };

//   const handleDeleteCustomFilter = (index) => {
//     const updatedFilters = [...customFilters];
//     updatedFilters.splice(index, 1);
//     setCustomFilters(updatedFilters);
//   };

//   const getFirstImageUrl = (picUrlString) => {
//     if (!picUrlString) return '';
//     const urls = picUrlString.split('|');
//     return urls[0] || '';
//   };

//   const [selectedProduct, setSelectedProduct] = useState(null);
//   const [isModalOpen, setIsModalOpen] = useState(false);

//   const [isImageModalOpen, setIsImageModalOpen] = useState(false);
//   const [currentImageUrl, setCurrentImageUrl] = useState('');

//   const handleOpenImageModal = (url) => {
//     setCurrentImageUrl(url);
//     setIsImageModalOpen(true);
//   };

//   const handleCloseImageModal = () => {
//     setIsImageModalOpen(false);
//     setCurrentImageUrl('');
//   };

//   const [isDrawerOpen, setIsDrawerOpen] = useState(false);

//   const ImageGallery = ({ picUrlString }) => {
//     if (!picUrlString) return null;
//     const urls = picUrlString.split('|');

//     return (
//       <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mt: 2 }}>
//         {urls.map((url, index) => (
//           <Box key={index} sx={{ position: 'relative' }}>
//             <img
//               src={url}
//               alt={`商品画像 ${index + 1}`}
//               style={{
//                 width: '100px',
//                 height: '100px',
//                 objectFit: 'cover',
//                 cursor: 'pointer',
//               }}
//               onClick={() => {
//                 handleOpenImageModal(url);
//               }}
//             />
//           </Box>
//         ))}
//       </Box>
//     );
//   };

//   return (
//     <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
//       <Typography variant="h6" gutterBottom>
//         CSVファイルのアップロードとタスクのスケジュール
//       </Typography>

//       <Button variant="contained" component="label">
//         ファイルを選択（最大5つまで）
//         <input
//           type="file"
//           accept=".csv"
//           multiple
//           hidden
//           onChange={handleFileChange}
//         />
//       </Button>

//       {csvFiles.length > 0 && (
//         <Box sx={{ mt: 2 }}>
//           <Typography variant="subtitle1">選択されたファイル:</Typography>
//           <List>
//             {csvFiles.map((file, index) => (
//               <ListItem key={index}>
//                 <Typography>{file.name}</Typography>
//               </ListItem>
//             ))}
//           </List>
//         </Box>
//       )}

//       <Button
//         variant="contained"
//         color="primary"
//         sx={{ mt: 2 }}
//         onClick={handleFunctionDialogOpen}
//         startIcon={<MenuIcon />}
//       >
//         実行する機能を選択
//       </Button>

//       <Box sx={{ mt: 2 }}>
//         <FormControl fullWidth>
//           <InputLabel>パターンを選択</InputLabel>
//           <Select
//             value={selectedPatternName}
//             onChange={(e) => {
//               const patternName = e.target.value;
//               setSelectedPatternName(patternName);
//               const pattern = patterns.find((p) => p.name === patternName);
//               applyPatternSettings(pattern);
//             }}
//           >
//             <MenuItem value="">
//               <em>パターンを選択</em>
//             </MenuItem>
//             {patterns.map((pattern) => (
//               <MenuItem key={pattern.name} value={pattern.name}>
//                 {pattern.name}
//               </MenuItem>
//             ))}
//           </Select>
//         </FormControl>
//         <Button
//           variant="outlined"
//           color="primary"
//           onClick={() => setIsSavePatternDialogOpen(true)}
//           sx={{ mt: 1 }}
//         >
//           現在の設定をパターンとして保存
//         </Button>
//         <Button
//           variant="outlined"
//           color="primary"
//           onClick={() => setIsManagePatternsDialogOpen(true)}
//           sx={{ mt: 1, ml: 1 }}
//         >
//           パターンを管理
//         </Button>
//       </Box>

//       {/* 以下、UI改善済みのコード。前回と同様のレイアウト変更を含む */}
//       {/* テンプレート適用 */}
//       {selectedFunctions.some((func) => func.name === 'applyTemplate') && (
//         <Paper sx={{ p: 2, mt: 2 }}>
//           <Typography variant="h6">1. テンプレート適用</Typography>
//           <Grid container spacing={2} sx={{ mt: 1 }}>
//             <Grid item xs={12} sm={6}>
//               <FormControl fullWidth>
//                 <InputLabel>テンプレートを選択</InputLabel>
//                 <Select
//                   value={selectedTemplateName}
//                   onChange={(e) => setSelectedTemplateName(e.target.value)}
//                 >
//                   {templateList.map((template) => (
//                     <MenuItem key={template.name} value={template.name}>
//                       {template.name}
//                     </MenuItem>
//                   ))}
//                 </Select>
//               </FormControl>
//             </Grid>
//           </Grid>
//         </Paper>
//       )}

//       {/* 価格設定 */}
//       {selectedFunctions.some((func) => func.name === 'applyPriceChanges') && (
//         <Paper sx={{ p: 2, mt: 2 }}>
//           <Typography variant="h6">2. 価格機能</Typography>
//           <Grid container spacing={2} sx={{ mt: 1 }}>
//             {shippingRateTemplates.length > 0 && (
//               <Grid item xs={12} sm={6}>
//                 <FormControl fullWidth>
//                   <InputLabel>送料設定を選択</InputLabel>
//                   <Select
//                     value={selectedShippingTemplateId}
//                     onChange={(e) => setSelectedShippingTemplateId(e.target.value)}
//                   >
//                     {shippingRateTemplates.map((template) => (
//                       <MenuItem key={template.id} value={template.id}>
//                         {template.name}
//                       </MenuItem>
//                     ))}
//                   </Select>
//                 </FormControl>
//               </Grid>
//             )}
//           </Grid>
//           <Box sx={{ mt: 2 }}>
//             <Typography variant="subtitle1">現在の価格設定</Typography>
//             <Typography>為替レート: 1 USD = {exchangeRate} JPY</Typography>
//             <Typography>目標利益率: {targetProfitMargin}%</Typography>
//             <Typography>eBay最終価値手数料: {fees.ebayFinalValue || 0}%</Typography>
//             <Typography>Payoneer手数料: {fees.payoneer || 0}%</Typography>
//             <Typography>広告出品手数料: {fees.promotedListing || 0}%</Typography>
//             <Typography>eBay取引手数料: {fees.ebayTransactionFee || 0} USD</Typography>
//             <Typography>
//               ベストオファー自動承認価格:{' '}
//               {bestOfferSettings.bestOfferAutoAcceptPercentage || 0}%
//             </Typography>
//             <Typography>
//               最小ベストオファー価格:{' '}
//               {bestOfferSettings.minimumBestOfferPercentage || 0}%
//             </Typography>
//           </Box>
//         </Paper>
//       )}

//       {/* AIタイトル */}
//       {selectedFunctions.some((func) => func.name === 'applyAITitles') && (
//         <Paper sx={{ p: 2, mt: 2 }}>
//           <Typography variant="h6">3. AIタイトル</Typography>
//           <Grid container spacing={2} sx={{ mt: 1 }}>
//             {/* タイトルカスタマイズテンプレート選択 */}
//             <Grid item xs={12} sm={6}>
//               <FormControl fullWidth>
//                 <InputLabel>タイトルカスタマイズテンプレートを選択</InputLabel>
//                 <Select
//                   value={selectedCategory}
//                   onChange={(e) => {
//                     const selectedName = e.target.value;
//                     setSelectedCategory(selectedName);

//                     if (selectedName) {
//                       const category = customCategories.find((cat) => cat.name === selectedName);
//                       if (category) {
//                         setDeleteStrings(category.deleteStrings || []);
//                         setReplacePairs(category.replacePairs || []);
//                         setPrependText(category.prependText || '');
//                         setAppendText(category.appendText || '');
//                         setLimitTitleLength(category.limitTitleLength || false);
//                       }
//                     } else {
//                       setDeleteStrings([]);
//                       setReplacePairs([]);
//                       setPrependText('');
//                       setAppendText('');
//                       setLimitTitleLength(false);
//                     }
//                   }}
//                 >
//                   {customCategories.map((cat) => (
//                     <MenuItem key={cat.name} value={cat.name}>
//                       {cat.name}
//                     </MenuItem>
//                   ))}
//                 </Select>
//               </FormControl>
//             </Grid>
//           </Grid>

//           {/* タイトルカスタマイズ設定 */}
//           <Box sx={{ mt: 2 }}>
//             <Typography variant="subtitle1">タイトルカスタマイズ設定</Typography>
//             <Grid container spacing={2} sx={{ mt: 1 }}>
//               <Grid item xs={12}>
//                 <TextField
//                   label="削除したい単語 (カンマ区切り)"
//                   value={deleteStrings.join(', ')}
//                   onChange={(e) =>
//                     setDeleteStrings(e.target.value.split(',').map((s) => s.trim()))
//                   }
//                   fullWidth
//                 />
//               </Grid>

//               <Grid item xs={12}>
//                 <Typography variant="subtitle1">置換ペア</Typography>
//                 {replacePairs.map((pair, index) => (
//                   <Box key={index} sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
//                     <TextField
//                       label="置換前"
//                       value={pair.from}
//                       onChange={(e) => handleReplacePairChange(index, 'from', e.target.value)}
//                     />
//                     <TextField
//                       label="置換後"
//                       value={pair.to}
//                       onChange={(e) => handleReplacePairChange(index, 'to', e.target.value)}
//                       sx={{ ml: 1 }}
//                     />
//                     <IconButton onClick={() => handleRemoveReplacePair(index)}>
//                       <DeleteIcon />
//                     </IconButton>
//                   </Box>
//                 ))}
//                 <Button variant="outlined" onClick={handleAddReplacePair} sx={{ mt: 1 }}>
//                   置換ペアを追加
//                 </Button>
//               </Grid>

//               <Grid item xs={12} sm={6}>
//                 <TextField
//                   label="先頭に追加する文字列"
//                   value={prependText}
//                   onChange={(e) => setPrependText(e.target.value)}
//                   fullWidth
//                 />
//               </Grid>

//               <Grid item xs={12} sm={6}>
//                 <TextField
//                   label="末尾に追加する文字列"
//                   value={appendText}
//                   onChange={(e) => setAppendText(e.target.value)}
//                   fullWidth
//                 />
//               </Grid>

//               <Grid item xs={12}>
//                 <FormControlLabel
//                   control={
//                     <Switch
//                       checked={limitTitleLength}
//                       onChange={(e) => setLimitTitleLength(e.target.checked)}
//                     />
//                   }
//                   label="80文字に制限する"
//                 />
//               </Grid>
//             </Grid>
//           </Box>
//         </Paper>
//       )}

//       {/* AI商品説明 */}
//       {selectedFunctions.some((func) => func.name === 'applyAIProductDescriptions') && (
//         <Paper sx={{ p: 2, mt: 2 }}>
//           <Typography variant="h6">4. AI商品説明</Typography>
//           <Grid container spacing={2} sx={{ mt: 1 }}>
//             {designTemplateNames.length > 0 && (
//               <Grid item xs={12} sm={4}>
//                 <FormControl fullWidth>
//                   <InputLabel>デザインテンプレートを選択</InputLabel>
//                   <Select
//                     value={selectedDesignTemplate}
//                     onChange={(e) => setSelectedDesignTemplate(e.target.value)}
//                   >
//                     {designTemplateNames.map((templateName) => (
//                       <MenuItem key={templateName} value={templateName}>
//                         {templateName}
//                       </MenuItem>
//                     ))}
//                   </Select>
//                 </FormControl>
//                 {selectedDesignTemplate && (
//                   <Typography variant="body2" sx={{ mt: 1 }}>
//                     選択中のテンプレート: {selectedDesignTemplate}
//                   </Typography>
//                 )}
//               </Grid>
//             )}

//             <Grid item xs={12} sm={4}>
//               <FormControlLabel
//                 control={
//                   <Switch
//                     checked={useImageDescription}
//                     onChange={(e) => setUseImageDescription(e.target.checked)}
//                     name="useImageDescription"
//                     color="primary"
//                   />
//                 }
//                 label="画像解析の説明を使用"
//               />
//             </Grid>

//             <Grid item xs={12} sm={4}>
//               <Typography variant="subtitle1">必ず含めたいキーワード</Typography>
//               <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
//                 <TextField
//                   label="キーワードを入力"
//                   value={mandatoryKeywordInput}
//                   onChange={(e) => setMandatoryKeywordInput(e.target.value)}
//                   fullWidth
//                 />
//                 <Button
//                   variant="contained"
//                   color="primary"
//                   onClick={handleAddMandatoryKeyword}
//                   sx={{ ml: 1, height: '56px' }}
//                 >
//                   追加
//                 </Button>
//               </Box>
//               <List>
//                 {mandatoryKeywords.map((keyword, index) => (
//                   <ListItem
//                     key={index}
//                     secondaryAction={
//                       <IconButton
//                         edge="end"
//                         aria-label="delete"
//                         onClick={() => handleDeleteMandatoryKeyword(index)}
//                       >
//                         <DeleteIcon />
//                       </IconButton>
//                     }
//                   >
//                     <ListItemText primary={keyword} />
//                   </ListItem>
//                 ))}
//               </List>
//             </Grid>
//           </Grid>

//           <Grid container spacing={2} sx={{ mt: 2 }}>
//             {/* カスタムフィルター等 */}
//             <Grid item xs={12} sm={4}>
//               <OptionsPanel
//                 selectedFilters={selectedFilters}
//                 handleFilterChange={handleFilterChange}
//                 customFilterInput={customFilterInput}
//                 setCustomFilterInput={setCustomFilterInput}
//                 customFilters={customFilters}
//                 handleAddCustomFilter={handleAddCustomFilter}
//                 handleDeleteCustomFilter={handleDeleteCustomFilter}
//                 sentencesToDelete={sentencesToDelete}
//                 setSentencesToDelete={setSentencesToDelete}
//                 wordsToReplace={wordsToReplace}
//                 setWordsToReplace={setWordsToReplace}
//               />
//             </Grid>

//             <Grid item xs={12} sm={4}>
//               <Typography variant="subtitle1">削除したい文章</Typography>
//               <TextField
//                 label="文章を入力"
//                 value={sentenceToDeleteInput}
//                 onChange={(e) => setSentenceToDeleteInput(e.target.value)}
//                 fullWidth
//               />
//               <Button
//                 variant="contained"
//                 color="primary"
//                 onClick={handleAddSentenceToDelete}
//                 sx={{ mt: 1 }}
//               >
//                 追加
//               </Button>
//               <List>
//                 {sentencesToDelete.map((sentence, index) => (
//                   <ListItem
//                     key={index}
//                     secondaryAction={
//                       <IconButton
//                         edge="end"
//                         aria-label="delete"
//                         onClick={() => handleDeleteSentenceToDelete(index)}
//                       >
//                         <DeleteIcon />
//                       </IconButton>
//                     }
//                   >
//                     <ListItemText primary={sentence} />
//                   </ListItem>
//                 ))}
//               </List>
//             </Grid>

//             <Grid item xs={12} sm={4}>
//               <Typography variant="subtitle1">置換単語</Typography>
//               <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
//                 <TextField
//                   label="置換前"
//                   value={wordToReplaceFrom}
//                   onChange={(e) => setWordToReplaceFrom(e.target.value)}
//                   fullWidth
//                 />
//                 <Typography sx={{ mx: 1 }}>➔</Typography>
//                 <TextField
//                   label="置換後"
//                   value={wordToReplaceTo}
//                   onChange={(e) => setWordToReplaceTo(e.target.value)}
//                   fullWidth
//                 />
//               </Box>
//               <Button
//                 variant="contained"
//                 color="primary"
//                 onClick={handleAddWordToReplace}
//                 sx={{ mt: 1 }}
//               >
//                 追加
//               </Button>
//               <List>
//                 {wordsToReplace.map((wordPair, index) => (
//                   <ListItem
//                     key={index}
//                     secondaryAction={
//                       <IconButton
//                         edge="end"
//                         aria-label="delete"
//                         onClick={() => handleDeleteWordToReplace(index)}
//                       >
//                         <DeleteIcon />
//                       </IconButton>
//                     }
//                   >
//                     <ListItemText primary={`${wordPair.from} ➔ ${wordPair.to}`} />
//                   </ListItem>
//                 ))}
//               </List>
//             </Grid>
//           </Grid>
//         </Paper>
//       )}

//       {/* Item Specifics適用 */}
//       {selectedFunctions.some((func) => func.name === 'applyItemSpecifics') && (
//         <Paper sx={{ p: 2, mt: 2 }}>
//           <Typography variant="h6">5. Item Specifics適用</Typography>
//           <Grid container spacing={2} sx={{ mt: 1 }}>
//             <Grid item xs={12} sm={6}>
//               <FormControl fullWidth>
//                 <InputLabel>適用するカテゴリー</InputLabel>
//                 <Select
//                   value={selectedItemSpecificsCategory}
//                   onChange={(e) => {
//                     setSelectedItemSpecificsCategory(e.target.value);
//                     setSelectedItemSpecificsColumns([]);
//                   }}
//                   label="適用するカテゴリー"
//                 >
//                   {Object.keys(itemSpecificsCategories).map((category) => (
//                     <MenuItem key={category} value={category}>
//                       {category}
//                     </MenuItem>
//                   ))}
//                 </Select>
//               </FormControl>
//             </Grid>
//           </Grid>

//           {selectedItemSpecificsCategory && (
//             <>
//               <Grid container spacing={2} sx={{ mt: 2 }}>
//                 <Grid item xs={12}>
//                   <FormControl fullWidth>
//                     <InputLabel>適用するカラムを選択</InputLabel>
//                     <Select
//                       multiple
//                       value={selectedItemSpecificsColumns}
//                       onChange={(e) => {
//                         const value = e.target.value;
//                         if (value.includes('all')) {
//                           const allColumns = getColumnsForSelectedCategory();
//                           if (
//                             selectedItemSpecificsColumns.length === allColumns.length
//                           ) {
//                             setSelectedItemSpecificsColumns([]);
//                           } else {
//                             setSelectedItemSpecificsColumns(allColumns);
//                           }
//                         } else {
//                           setSelectedItemSpecificsColumns(value);
//                         }
//                       }}
//                       renderValue={(selected) => {
//                         if (selected.length === 0) {
//                           return '適用するカラムを選択';
//                         }

//                         const displayedValues = selected.slice(0, 5);
//                         const hiddenCount = selected.length - displayedValues.length;

//                         return (
//                           <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
//                             {displayedValues.map((value) => (
//                               <Chip key={value} label={value} size="small" />
//                             ))}
//                             {hiddenCount > 0 && (
//                               <Chip label={`+${hiddenCount}個`} size="small" />
//                             )}
//                           </Box>
//                         );
//                       }}
//                     >
//                       <MenuItem
//                         value="all"
//                         onClick={() => {
//                           const allColumns = getColumnsForSelectedCategory();
//                           if (selectedItemSpecificsColumns.length === allColumns.length) {
//                             setSelectedItemSpecificsColumns([]);
//                           } else {
//                             setSelectedItemSpecificsColumns(allColumns);
//                           }
//                         }}
//                       >
//                         <Checkbox
//                           checked={
//                             selectedItemSpecificsColumns.length ===
//                               getColumnsForSelectedCategory().length &&
//                             selectedItemSpecificsColumns.length > 0
//                           }
//                           indeterminate={
//                             selectedItemSpecificsColumns.length > 0 &&
//                             selectedItemSpecificsColumns.length <
//                               getColumnsForSelectedCategory().length
//                           }
//                         />
//                         <Typography variant="body1">全選択</Typography>
//                       </MenuItem>
//                       {getColumnsForSelectedCategory().map((column) => (
//                         <MenuItem key={column} value={column}>
//                           <Checkbox
//                             checked={selectedItemSpecificsColumns.indexOf(column) > -1}
//                           />
//                           <Typography variant="body1">{column}</Typography>
//                         </MenuItem>
//                       ))}
//                     </Select>
//                   </FormControl>
//                 </Grid>
//               </Grid>

//               <Box sx={{ mt: 2 }}>
//                 <Typography variant="subtitle1" gutterBottom>
//                   マッチングの基準を選択してください。
//                 </Typography>
//                 <FormControl component="fieldset">
//                   <RadioGroup
//                     value={itemSpecificsMatchingOptions.matchSource}
//                     onChange={(e) =>
//                       setItemSpecificsMatchingOptions((prev) => ({
//                         ...prev,
//                         matchSource: e.target.value,
//                       }))
//                     }
//                   >
//                     <FormControlLabel
//                       value="title"
//                       control={<Radio />}
//                       label="タイトルのみ"
//                     />
//                     <FormControlLabel
//                       value="description"
//                       control={<Radio />}
//                       label="商品説明のみ (AI生成部分のみ)"
//                     />
//                     <FormControlLabel
//                       value="both"
//                       control={<Radio />}
//                       label="タイトルと商品説明の両方 (AI生成部分のみ)"
//                     />
//                   </RadioGroup>
//                 </FormControl>
//               </Box>

//               <Box sx={{ mt: 2 }}>
//                 <FormControlLabel
//                   control={
//                     <Checkbox
//                       checked={itemSpecificsMatchingOptions.caseSensitive}
//                       onChange={(e) =>
//                         setItemSpecificsMatchingOptions((prev) => ({
//                           ...prev,
//                           caseSensitive: e.target.checked,
//                         }))
//                       }
//                       name="caseSensitive"
//                     />
//                   }
//                   label="大文字小文字を区別する"
//                 />
//                 <FormControlLabel
//                   control={
//                     <Checkbox
//                       checked={itemSpecificsMatchingOptions.partialMatch}
//                       onChange={(e) =>
//                         setItemSpecificsMatchingOptions((prev) => ({
//                           ...prev,
//                           partialMatch: e.target.checked,
//                         }))
//                       }
//                       name="partialMatch"
//                     />
//                   }
//                   label="部分一致を許可する"
//                 />
//               </Box>

//               <Box sx={{ mt: 2 }}>
//                 <Typography variant="subtitle1" gutterBottom>
//                   マッチングの動作を選択してください。
//                 </Typography>
//                 <Typography variant="subtitle1" gutterBottom>
//                   (上記でAI商品説明からマッチングを選択している場合は、自動処理でAI生成した文章からカラムの適所に適用されます。自動処理によって特に値の変更がなかった場合以下の設定が適用されます。)
//                 </Typography>
//                 <FormControl component="fieldset">
//                   <RadioGroup
//                     value={itemSpecificsMatchingOptions.matchingOption}
//                     onChange={(e) =>
//                       setItemSpecificsMatchingOptions((prev) => ({
//                         ...prev,
//                         matchingOption: e.target.value,
//                       }))
//                     }
//                   >
//                     <FormControlLabel
//                       value="priority"
//                       control={<Radio />}
//                       label="優先順位に基づいて適用"
//                     />
//                     <FormControlLabel
//                       value="all"
//                       control={<Radio />}
//                       label="マッチしたすべての値を適用"
//                     />
//                   </RadioGroup>
//                 </FormControl>
//               </Box>
//             </>
//           )}
//         </Paper>
//       )}

//       {/* 実行モード */}
//       <Box sx={{ mt: 2 }}>
//         <Typography variant="subtitle1">実行モード:</Typography>
//         <Button
//           variant={isImmediate ? 'contained' : 'outlined'}
//           onClick={() => setIsImmediate(true)}
//           sx={{ mr: 1 }}
//         >
//           即時実行
//         </Button>
//         <Button
//           variant={!isImmediate ? 'contained' : 'outlined'}
//           onClick={() => setIsImmediate(false)}
//         >
//           スケジュール実行
//         </Button>
//       </Box>

//       {!isImmediate && (
//         <Box sx={{ mt: 2 }}>
//           <TextField
//             label="実行開始時間"
//             type="datetime-local"
//             value={scheduledTime}
//             onChange={(e) => setScheduledTime(e.target.value)}
//             InputLabelProps={{
//               shrink: true,
//             }}
//             fullWidth
//           />
//         </Box>
//       )}

//       <Button
//         variant="contained"
//         color="secondary"
//         sx={{ mt: 2 }}
//         onClick={handleUploadAndExecute}
//         disabled={isScheduling}
//       >
//         {isScheduling ? '処理中...' : 'アップロードして実行'}
//       </Button>

//       {isScheduling && (
//         <Box sx={{ mt: 2 }}>
//           <Typography variant="h6">処理進捗:</Typography>
//           <Typography>
//             {progress.completedItems} / {progress.totalItems} 件が完了しました
//           </Typography>
//           <LinearProgress
//             variant="determinate"
//             value={(progress.completedItems / progress.totalItems) * 100}
//           />
//         </Box>
//       )}

//       {processedParsedData.length > 0 && (
//         <Box sx={{ mt: 4 }}>
//           <Typography variant="h6">処理結果のプレビュー:</Typography>
//           <Tabs
//             value={activeTab}
//             onChange={handleTabChange}
//             variant="scrollable"
//             scrollButtons="auto"
//           >
//             {processedParsedData.map((file, index) => (
//               <Tab key={index} label={file.name} />
//             ))}
//           </Tabs>
//           {processedParsedData.map((file, index) => (
//             <div
//               key={index}
//               role="tabpanel"
//               hidden={activeTab !== index}
//               id={`tabpanel-${index}`}
//             >
//               {activeTab === index && (
//                 <Box sx={{ p: 2 }}>
//                   <Paper elevation={2} sx={{ overflowX: 'auto' }}>
//                     <TableContainer>
//                       <Table
//                         size="small"
//                         sx={{
//                           tableLayout: 'auto',
//                           width: 'auto',
//                         }}
//                       >
//                         <TableHead>
//                           <TableRow>
//                             {file.columns.map((column, idx) => (
//                               <TableCell
//                                 key={idx}
//                                 sx={{
//                                   maxWidth: '150px',
//                                   whiteSpace: 'nowrap',
//                                   padding: '8px',
//                                   border: '1px solid rgba(224, 224, 224, 1)',
//                                   fontWeight: 'bold',
//                                 }}
//                               >
//                                 {column}
//                               </TableCell>
//                             ))}
//                           </TableRow>
//                         </TableHead>
//                         <TableBody>
//                           {file.data.map((row, rowIndex) => (
//                             <TableRow
//                               key={rowIndex}
//                               onClick={() => {
//                                 setSelectedProduct(row);
//                                 setIsDrawerOpen(true);
//                               }}
//                               style={{ cursor: 'pointer' }}
//                             >
//                               {file.columns.map((column, colIndex) => (
//                                 <TableCell
//                                   key={colIndex}
//                                   sx={{
//                                     maxWidth: '150px',
//                                     whiteSpace: 'nowrap',
//                                     padding: '8px',
//                                     border: '1px solid rgba(224, 224, 224, 1)',
//                                   }}
//                                 >
//                                   {column === 'PicURL' ? (
//                                     row[column] ? (
//                                       <img
//                                         src={getFirstImageUrl(row[column])}
//                                         alt="商品画像"
//                                         style={{
//                                           width: '50px',
//                                           height: '50px',
//                                           objectFit: 'cover',
//                                         }}
//                                       />
//                                     ) : (
//                                       'なし'
//                                     )
//                                   ) : (
//                                     row[column]
//                                   )}
//                                 </TableCell>
//                               ))}
//                             </TableRow>
//                           ))}
//                         </TableBody>
//                       </Table>
//                     </TableContainer>
//                   </Paper>

//                   <Drawer
//                     anchor="right"
//                     open={isDrawerOpen}
//                     onClose={() => setIsDrawerOpen(false)}
//                   >
//                     <Box sx={{ width: 700, p: 2 }}>
//                       {selectedProduct ? (
//                         <>
//                           <Typography variant="h6">{selectedProduct['Title']}</Typography>
//                           <ImageGallery picUrlString={selectedProduct['PicURL']} />
//                           <Typography variant="body1" sx={{ mt: 2 }}>
//                             価格: {selectedProduct['StartPrice']}
//                           </Typography>
//                           <Typography variant="body1" sx={{ mt: 2 }}>説明:</Typography>
//                           <Box
//                             sx={{ mt: 2 }}
//                             dangerouslySetInnerHTML={{ __html: selectedProduct['Description'] }}
//                           />
//                         </>
//                       ) : (
//                         <Typography variant="body1">商品が選択されていません</Typography>
//                       )}
//                     </Box>
//                   </Drawer>

//                   <Dialog
//                     open={isImageModalOpen}
//                     onClose={handleCloseImageModal}
//                     maxWidth="lg"
//                   >
//                     <DialogContent>
//                       <img
//                         src={currentImageUrl}
//                         alt="拡大画像"
//                         style={{ width: '100%', height: 'auto', objectFit: 'contain' }}
//                       />
//                     </DialogContent>
//                     <DialogActions>
//                       <Button onClick={handleCloseImageModal}>閉じる</Button>
//                     </DialogActions>
//                   </Dialog>
//                 </Box>
//               )}
//             </div>
//           ))}
//         </Box>
//       )}

//       {errorItems.length > 0 && (
//         <Box sx={{ mt: 2 }}>
//           <Typography variant="h6" color="error">
//             エラーが発生したアイテム:
//           </Typography>
//           <List>
//             {errorItems.map((item, index) => (
//               <ListItem key={index}>
//                 <Typography>
//                   ファイル名: {item.fileName}, 行番号: {item.rowIndex}, エラー: {item.error}
//                 </Typography>
//               </ListItem>
//             ))}
//           </List>
//         </Box>
//       )}

//       {processedData.length > 0 && (
//         <Box sx={{ mt: 2 }}>
//           <Typography variant="h6">処理結果のダウンロード:</Typography>
//           {processedData.map((file, index) => (
//             <Button
//               key={index}
//               variant="contained"
//               sx={{ mt: 1, mr: 1 }}
//               onClick={() => {
//                 const blob = new Blob([file.content], {
//                   type: 'text/csv;charset=utf-8;',
//                 });
//                 const link = document.createElement('a');
//                 link.href = URL.createObjectURL(blob);
//                 link.setAttribute('download', file.name);
//                 document.body.appendChild(link);
//                 link.click();
//                 document.body.removeChild(link);
//               }}
//             >
//               {file.name}
//             </Button>
//           ))}
//         </Box>
//       )}

//       <Dialog open={functionDialogOpen} onClose={handleFunctionDialogClose}>
//         <DialogTitle>実行する機能を選択</DialogTitle>
//         <DialogContent>
//           <DragDropContext onDragEnd={onDragEnd}>
//             <Droppable droppableId="functions">
//               {(provided) => (
//                 <List {...provided.droppableProps} ref={provided.innerRef}>
//                   {availableFunctions.map((func, index) => (
//                     <Draggable key={func.name} draggableId={func.name} index={index}>
//                       {(provided) => (
//                         <ListItem
//                           button
//                           ref={provided.innerRef}
//                           {...provided.draggableProps}
//                         >
//                           <Checkbox
//                             checked={
//                               selectedFunctions.findIndex((f) => f.name === func.name) !== -1
//                             }
//                             onClick={handleFunctionToggle(func)}
//                           />
//                           <Typography>{func.displayName}</Typography>
//                           <IconButton {...provided.dragHandleProps}>
//                             <DragHandleIcon />
//                           </IconButton>
//                         </ListItem>
//                       )}
//                     </Draggable>
//                   ))}
//                   {provided.placeholder}
//                 </List>
//               )}
//             </Droppable>
//           </DragDropContext>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={handleFunctionDialogClose}>閉じる</Button>
//         </DialogActions>
//       </Dialog>

//       <Dialog
//         open={isSaveTemplateDialogOpen}
//         onClose={() => setIsSaveTemplateDialogOpen(false)}
//       >
//         <DialogTitle>テンプレートの保存</DialogTitle>
//         <DialogContent>
//           <FormControl component="fieldset">
//             <RadioGroup
//               value={saveOption}
//               onChange={(e) => setSaveOption(e.target.value)}
//             >
//               <FormControlLabel
//                 value="new"
//                 control={<Radio />}
//                 label="新しいテンプレートとして保存"
//               />
//               {selectedTemplateId && (
//                 <FormControlLabel
//                   value="overwrite"
//                   control={<Radio />}
//                   label="既存のテンプレートを上書き保存"
//                 />
//               )}
//             </RadioGroup>
//           </FormControl>
//           <TextField
//             label="テンプレート名"
//             value={templateName}
//             onChange={(e) => setTemplateName(e.target.value)}
//             fullWidth
//             sx={{ mt: 2 }}
//           />
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => setIsSaveTemplateDialogOpen(false)}>キャンセル</Button>
//           <Button onClick={saveTemplate} color="primary">
//             保存
//           </Button>
//         </DialogActions>
//       </Dialog>

//       <Dialog
//         open={isManageTemplatesDialogOpen}
//         onClose={() => setIsManageTemplatesDialogOpen(false)}
//       >
//         <DialogTitle>テンプレートの管理</DialogTitle>
//         <DialogContent>
//           <List>
//             {templates.map((template) => (
//               <ListItem key={template.id}>
//                 <ListItemText primary={template.name} />
//                 <IconButton
//                   edge="end"
//                   aria-label="delete"
//                   onClick={() => handleDeleteTemplate(template.id)}
//                 >
//                   <DeleteIcon />
//                 </IconButton>
//               </ListItem>
//             ))}
//           </List>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => setIsManageTemplatesDialogOpen(false)}>閉じる</Button>
//         </DialogActions>
//       </Dialog>

//       <Dialog
//         open={isSavePatternDialogOpen}
//         onClose={() => setIsSavePatternDialogOpen(false)}
//       >
//         <DialogTitle>現在の設定をパターンとして保存</DialogTitle>
//         <DialogContent>
//           <TextField
//             label="パターン名"
//             value={newPatternName}
//             onChange={(e) => setNewPatternName(e.target.value)}
//             fullWidth
//           />
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => setIsSavePatternDialogOpen(false)}>キャンセル</Button>
//           <Button onClick={saveCurrentSettingsAsPattern} color="primary">
//             保存
//           </Button>
//         </DialogActions>
//       </Dialog>

//       <Dialog
//         open={isManagePatternsDialogOpen}
//         onClose={() => setIsManagePatternsDialogOpen(false)}
//       >
//         <DialogTitle>パターンの管理</DialogTitle>
//         <DialogContent>
//           <List>
//             {patterns.map((pattern) => (
//               <ListItem key={pattern.name}>
//                 <ListItemText primary={pattern.name} />
//                 <IconButton
//                   edge="end"
//                   aria-label="delete"
//                   onClick={() => handleDeletePattern(pattern.name)}
//                 >
//                   <DeleteIcon />
//                 </IconButton>
//               </ListItem>
//             ))}
//           </List>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => setIsManagePatternsDialogOpen(false)}>閉じる</Button>
//         </DialogActions>
//       </Dialog>

//       <Snackbar
//         open={snackbar.open}
//         autoHideDuration={6000}
//         onClose={() => setSnackbar({ ...snackbar, open: false })}
//       >
//         <Alert
//           onClose={() => setSnackbar({ ...snackbar, open: false })}
//           severity={snackbar.severity}
//           sx={{ width: '100%' }}
//         >
//           {snackbar.message}
//         </Alert>
//       </Snackbar>
//     </Paper>
//   );
// }

// export default CsvSchedulerUploader;





// // CsvSchedulerUploader.js

// import React, { useState, useEffect, useCallback } from 'react';
// import {
//   Box,
//   Button,
//   Typography,
//   TextField,
//   Paper,
//   Snackbar,
//   Alert,
//   List,
//   ListItem,
//   Checkbox,
//   IconButton,
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogTitle,
//   FormControl,
//   InputLabel,
//   Select,
//   MenuItem,
//   Tabs,
//   Tab,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   LinearProgress,
//   Grid,
//   Switch,
//   ListItemText,
//   RadioGroup,
//   Radio,
//   FormControlLabel,
//   Chip,
//   Drawer,
//   Tooltip,
// } from '@mui/material';
// import { useNavigate } from 'react-router-dom';
// import MenuIcon from '@mui/icons-material/Menu';
// import DragHandleIcon from '@mui/icons-material/DragHandle';
// import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
// import VisibilityIcon from '@mui/icons-material/Visibility';
// import EditIcon from '@mui/icons-material/Edit';
// import MoneyOffIcon from '@mui/icons-material/MoneyOff';
// import ReplayIcon from '@mui/icons-material/Replay';
// import DeleteIcon from '@mui/icons-material/Delete';

// // Firebase のインポート
// import { auth, db } from './firebaseConfig';
// import {
//   getFirestore,
//   getDoc,
//   doc,
//   collection,
//   getDocs,
//   addDoc,
//   setDoc,
//   deleteDoc,
// } from 'firebase/firestore';

// // 機能関数のインポート
// import { applyTemplate, applyItemSpecifics } from './functions';
// // パーサーのインポート
// import Papa from 'papaparse';
// // 価格計算関数のインポート
// import { calculatePrices } from './calculatePrices';
// // AIタイトル生成関数のインポート
// import { applyAITitles } from './applyAITitles';
// // AI商品説明生成関数のインポート
// import { applyAIProductDescriptions } from './applyAIProductDescriptions';
// // CryptoJSのインポート
// import CryptoJS from 'crypto-js';
// // デザインテンプレートのインポート
// import { applyItemSpecificsProcessor } from './ItemSpecificsProcessor';
// import designTemplates from './designTemplates';

// // その他のコンポーネントのインポート
// import OptionsPanel from './OptionsPanel';

// function CsvSchedulerUploader({ user }) {
//   const [csvFiles, setCsvFiles] = useState([]);
//   const [fileContents, setFileContents] = useState([]);
//   const [snackbar, setSnackbar] = useState({
//     open: false,
//     message: '',
//     severity: 'info',
//   });

//   // 状態変数を定義
//   const [selectedTemplate, setSelectedTemplate] = useState('');
//   const [selectedItemSpecificsCategory, setSelectedItemSpecificsCategory] = useState('');
//   const [selectedItemSpecificsColumns, setSelectedItemSpecificsColumns] = useState([]);
//   const [itemSpecificsMatchingOptions, setItemSpecificsMatchingOptions] = useState({
//     caseSensitive: false,
//     partialMatch: true,
//     matchSource: 'title',
//     matchingOption: 'priority',
//   });
//   const [itemSpecificsCategories, setItemSpecificsCategories] = useState({});

//   const [selectedFunctions, setSelectedFunctions] = useState([]);
//   const [isImmediate, setIsImmediate] = useState(true);
//   const [scheduledTime, setScheduledTime] = useState('');
//   const [functionDialogOpen, setFunctionDialogOpen] = useState(false);
//   const [isScheduling, setIsScheduling] = useState(false);
//   const [processedData, setProcessedData] = useState([]);
//   const [activeTab, setActiveTab] = useState(0);

//   const [templateList, setTemplateList] = useState([]);
//   const [selectedTemplateName, setSelectedTemplateName] = useState('');

//   // 価格設定状態管理
//   const [selectedPriceSettings, setSelectedPriceSettings] = useState(null);
//   const [shippingRateTemplates, setShippingRateTemplates] = useState([]);
//   const [selectedShippingTemplateId, setSelectedShippingTemplateId] = useState('');

//   // その他の設定
//   const [exchangeRate, setExchangeRate] = useState(0);
//   const [targetProfitMargin, setTargetProfitMargin] = useState(0);
//   const [fees, setFees] = useState({});
//   const [bestOfferSettings, setBestOfferSettings] = useState({});

//   const [userApiKey, setUserApiKey] = useState('');
//   const [userSettings, setUserSettings] = useState({});

//   const [designTemplateNames, setDesignTemplateNames] = useState([]);
//   const [selectedDesignTemplate, setSelectedDesignTemplate] = useState('');

//   const [templates, setTemplates] = useState([]);
//   const [templateName, setTemplateName] = useState('');
//   const [isSaveTemplateDialogOpen, setIsSaveTemplateDialogOpen] = useState(false);
//   const [isManageTemplatesDialogOpen, setIsManageTemplatesDialogOpen] = useState(false);
//   const [selectedTemplateId, setSelectedTemplateId] = useState('');
//   const [currentTemplateName, setCurrentTemplateName] = useState('');
//   const [saveOption, setSaveOption] = useState('new');

//   const [customCategories, setCustomCategories] = useState([]);
//   const [selectedCategory, setSelectedCategory] = useState('');

//   const [useImageDescription, setUseImageDescription] = useState(false);
//   const [mandatoryKeywords, setMandatoryKeywords] = useState([]);
//   const [mandatoryKeywordInput, setMandatoryKeywordInput] = useState('');

//   const [sentenceToDeleteInput, setSentenceToDeleteInput] = useState('');
//   const [wordToReplaceFrom, setWordToReplaceFrom] = useState('');
//   const [wordToReplaceTo, setWordToReplaceTo] = useState('');

//   const navigate = useNavigate();

//   const [patterns, setPatterns] = useState([]);
//   const [selectedPatternName, setSelectedPatternName] = useState('');

//   const generateEncryptionKey = (uid) => {
//     const salt = 'your-fixed-salt-value';
//     return CryptoJS.PBKDF2(uid, salt, { keySize: 256 / 32 }).toString();
//   };

//   const [progress, setProgress] = useState({ totalItems: 0, completedItems: 0 });
//   const [errorItems, setErrorItems] = useState([]);

//   const [availableFunctions, setAvailableFunctions] = useState([
//     {
//       name: 'applyTemplate',
//       displayName: 'テンプレートを実行する',
//       func: applyTemplate,
//     },
//     {
//       name: 'applyPriceChanges',
//       displayName: '価格の変更を適用する',
//       func: 'applyPriceChanges',
//     },
//     {
//       name: 'applyAITitles',
//       displayName: 'AIタイトルを適用する',
//       func: 'applyAITitles',
//     },
//     {
//       name: 'applyAIProductDescriptions',
//       displayName: 'AI商品説明を適用する',
//       func: 'applyAIProductDescriptions',
//     },
//     {
//       name: 'applyItemSpecifics',
//       displayName: 'Item Specificsの適用をする',
//       func: applyItemSpecifics,
//     },
//   ]);

//   useEffect(() => {
//     const fetchItemSpecificsSettings = async () => {
//       if (!user) return;
//       try {
//         const db = getFirestore();
//         const docRef = doc(db, 'itemSpecificsSettings', user.uid);
//         const docSnap = await getDoc(docRef);

//         if (docSnap.exists()) {
//           const settingsData = docSnap.data();
//           setItemSpecificsCategories(settingsData.categories || {});
//         } else {
//           console.warn('Item Specificsの設定が見つかりませんでした。');
//         }
//       } catch (error) {
//         console.error('Item Specificsの設定取得中にエラー:', error);
//       }
//     };

//     fetchItemSpecificsSettings();
//   }, [user]);

//   const getColumnsForSelectedCategory = () => {
//     if (
//       selectedItemSpecificsCategory &&
//       itemSpecificsCategories[selectedItemSpecificsCategory]
//     ) {
//       return Object.keys(
//         itemSpecificsCategories[selectedItemSpecificsCategory].columns || {}
//       );
//     }
//     return [];
//   };

//   useEffect(() => {
//     if (!user) {
//       navigate('/login');
//     } else {
//       const fetchTemplates = async () => {
//         try {
//           const docRef = doc(db, 'userTemplates', user.uid);
//           const docSnap = await getDoc(docRef);

//           if (docSnap.exists()) {
//             const userData = docSnap.data();
//             const userCategories = userData.categories || {};
//             const templates = Object.values(userCategories)
//               .flat()
//               .map((template) => ({
//                 name: template.name,
//                 operations: template.operations,
//               }));
//             setTemplateList(templates);
//             if (templates.length > 0) {
//               setSelectedTemplateName(templates[0].name);
//             }
//           }
//         } catch (error) {
//           console.error('テンプレート取得エラー:', error);
//           setSnackbar({
//             open: true,
//             message: 'テンプレートの取得に失敗しました。',
//             severity: 'error',
//           });
//         }
//       };
//       fetchTemplates();

//       const fetchUserSettings = async () => {
//         try {
//           const docRef = doc(db, 'userSettings', user.uid);
//           const docSnap = await getDoc(docRef);

//           if (docSnap.exists()) {
//             const userSettingsData = docSnap.data();
//             setSelectedPriceSettings(userSettingsData);
//             setUserSettings(userSettingsData);

//             if (userSettingsData.shippingRateTemplates) {
//               setShippingRateTemplates(userSettingsData.shippingRateTemplates);
//               setSelectedShippingTemplateId(
//                 userSettingsData.selectedShippingTemplateId ||
//                   userSettingsData.shippingRateTemplates[0].id
//               );
//             }

//             setExchangeRate(userSettingsData.exchangeRate || 0);
//             setTargetProfitMargin(userSettingsData.targetProfitMargin || 0);
//             setFees(userSettingsData.fees || {});
//             setBestOfferSettings({
//               bestOfferAutoAcceptPercentage:
//                 userSettingsData.bestOfferAutoAcceptPercentage || 0,
//               minimumBestOfferPercentage:
//                 userSettingsData.minimumBestOfferPercentage || 0,
//             });

//             if (userSettingsData.designTemplates) {
//               const templateNames = Object.keys(userSettingsData.designTemplates);
//               setDesignTemplateNames(templateNames);
//               if (templateNames.length > 0) {
//                 setSelectedDesignTemplate(templateNames[0]);
//               } else {
//                 setSelectedDesignTemplate('defaultTemplate');
//               }
//             } else {
//               setSelectedDesignTemplate('simple');
//             }

//             setCustomFilters(userSettingsData.customFilters || []);
//             setSentencesToDelete(userSettingsData.sentencesToDelete || []);
//             setWordsToReplace(userSettingsData.wordsToReplace || []);
//             setMandatoryKeywords(userSettingsData.mandatoryKeywords || []);

//             if (userSettingsData.customCategories) {
//               setCustomCategories(userSettingsData.customCategories);
//             } else {
//               await loadTitleCustomizationTemplates();
//             }

//             if (userSettingsData.patterns) {
//               setPatterns(userSettingsData.patterns);
//             } else {
//               setPatterns([]);
//             }
//           } else {
//             await loadTitleCustomizationTemplates();
//           }

//           const apiKeyDocRef = doc(db, 'userApiKeys', user.uid);
//           const apiKeyDocSnap = await getDoc(apiKeyDocRef);

//           if (apiKeyDocSnap.exists()) {
//             const encryptedKey = apiKeyDocSnap.data().apiKey;
//             const encryptionKey = generateEncryptionKey(user.uid);
//             const bytes = CryptoJS.AES.decrypt(encryptedKey, encryptionKey);
//             const decryptedKey = bytes.toString(CryptoJS.enc.Utf8);
//             if (decryptedKey) {
//               setUserApiKey(decryptedKey);
//             } else {
//               console.error('APIキーの復号に失敗');
//             }
//           } else {
//             console.warn('APIキーが見つかりません');
//           }

//           loadTemplates();
//         } catch (error) {
//           console.error('ユーザー設定取得エラー:', error);
//           setSnackbar({
//             open: true,
//             message: 'ユーザー設定の取得に失敗しました。',
//             severity: 'error',
//           });
//         }
//       };
//       fetchUserSettings();

//       const loadTitleCustomizationTemplates = async () => {
//         if (!user) return;
//         try {
//           const docRef = doc(db, 'userTitleSettings', user.uid);
//           const docSnap = await getDoc(docRef);
//           if (docSnap.exists()) {
//             const data = docSnap.data();
//             setCustomCategories(data.customCategories || []);
//           }
//         } catch (error) {
//           console.error('タイトルカスタマイズテンプレート取得エラー:', error);
//         }
//       };
//     }
//   }, [user, navigate]);

//   const [customFilters, setCustomFilters] = useState([]);
//   const [sentencesToDelete, setSentencesToDelete] = useState([]);
//   const [wordsToReplace, setWordsToReplace] = useState([]);

//   const [selectedFilters, setSelectedFilters] = useState([]);
//   const [customFilterInput, setCustomFilterInput] = useState('');

//   const saveTemplate = async () => {
//     if (!templateName.trim()) {
//       setSnackbar({
//         open: true,
//         message: 'テンプレート名を入力してください',
//         severity: 'warning',
//       });
//       return;
//     }

//     const templateData = {
//       name: templateName.trim(),
//       customFilters,
//       sentencesToDelete,
//       wordsToReplace,
//       mandatoryKeywords,
//       selectedFilters,
//     };

//     try {
//       const userTemplatesRef = collection(db, 'users', user.uid, 'templates');

//       if (saveOption === 'overwrite' && selectedTemplateId) {
//         const templateDocRef = doc(userTemplatesRef, selectedTemplateId);
//         await setDoc(templateDocRef, templateData);
//         setSnackbar({
//           open: true,
//           message: 'テンプレートが更新されました',
//           severity: 'success',
//         });
//       } else {
//         await addDoc(userTemplatesRef, templateData);
//         setSnackbar({
//           open: true,
//           message: '新しいテンプレートが保存されました',
//           severity: 'success',
//         });
//       }

//       setIsSaveTemplateDialogOpen(false);
//       setTemplateName('');
//       loadTemplates();
//     } catch (error) {
//       console.error('テンプレート保存中エラー:', error);
//       setSnackbar({
//         open: true,
//         message: 'テンプレートの保存中にエラーが発生しました',
//         severity: 'error',
//       });
//     }
//   };

//   const loadTemplates = async () => {
//     try {
//       const userTemplatesRef = collection(db, 'users', user.uid, 'templates');
//       const templatesSnapshot = await getDocs(userTemplatesRef);
//       const templatesData = templatesSnapshot.docs.map((doc) => ({
//         id: doc.id,
//         ...doc.data(),
//       }));
//       setTemplates(templatesData);
//     } catch (error) {
//       console.error('テンプレート読み込みエラー:', error);
//     }
//   };

//   const handleLoadTemplate = async (templateId) => {
//     if (!templateId) {
//       setSelectedTemplateId('');
//       setTemplateName('');
//       setCurrentTemplateName('');
//       return;
//     }
//     setSelectedTemplateId(templateId);
//     try {
//       const templateDocRef = doc(db, 'users', user.uid, 'templates', templateId);
//       const templateDoc = await getDoc(templateDocRef);
//       if (templateDoc.exists()) {
//         const templateData = templateDoc.data();
//         setTemplateName(templateData.name || '');
//         setCurrentTemplateName(templateData.name || '');
//         setCustomFilters(templateData.customFilters || []);
//         setSentencesToDelete(templateData.sentencesToDelete || []);
//         setWordsToReplace(templateData.wordsToReplace || []);
//         setMandatoryKeywords(templateData.mandatoryKeywords || []);
//         setSelectedFilters(templateData.selectedFilters || []);
//         setSnackbar({
//           open: true,
//           message: 'テンプレートが適用されました',
//           severity: 'success',
//         });
//       } else {
//         setSnackbar({
//           open: true,
//           message: 'テンプレートが見つかりません',
//           severity: 'error',
//         });
//       }
//     } catch (error) {
//       console.error('テンプレート読み込みエラー:', error);
//       setSnackbar({
//         open: true,
//         message: 'テンプレートの読み込み中にエラーが発生しました',
//         severity: 'error',
//       });
//     }
//   };

//   const handleDeleteTemplate = async (templateId) => {
//     try {
//       const templateDocRef = doc(db, 'users', user.uid, 'templates', templateId);
//       await deleteDoc(templateDocRef);
//       setSnackbar({
//         open: true,
//         message: 'テンプレートが削除されました',
//         severity: 'success',
//       });
//       loadTemplates();
//     } catch (error) {
//       console.error('テンプレート削除エラー:', error);
//       setSnackbar({
//         open: true,
//         message: 'テンプレートの削除中にエラーが発生しました',
//         severity: 'error',
//       });
//     }
//   };

//   const handleCreateNewTemplate = () => {
//     setSelectedTemplateId('');
//     setTemplateName('');
//     setCurrentTemplateName('');
//     setCustomFilters([]);
//     setSelectedFilters([]);
//     setSentencesToDelete([]);
//     setWordsToReplace([]);
//     setMandatoryKeywords([]);
//     setSnackbar({
//       open: true,
//       message: '新しいテンプレートを作成します',
//       severity: 'info',
//     });
//   };

//   const handleAddMandatoryKeyword = () => {
//     if (mandatoryKeywordInput.trim() !== '') {
//       setMandatoryKeywords([...mandatoryKeywords, mandatoryKeywordInput.trim()]);
//       setMandatoryKeywordInput('');
//     }
//   };

//   const handleDeleteMandatoryKeyword = (index) => {
//     const updatedKeywords = [...mandatoryKeywords];
//     updatedKeywords.splice(index, 1);
//     setMandatoryKeywords(updatedKeywords);
//   };

//   const handleAddSentenceToDelete = () => {
//     if (sentenceToDeleteInput.trim() !== '') {
//       setSentencesToDelete([...sentencesToDelete, sentenceToDeleteInput.trim()]);
//       setSentenceToDeleteInput('');
//     }
//   };

//   const handleDeleteSentenceToDelete = (index) => {
//     const updatedSentences = [...sentencesToDelete];
//     updatedSentences.splice(index, 1);
//     setSentencesToDelete(updatedSentences);
//   };

//   const handleAddWordToReplace = () => {
//     if (wordToReplaceFrom.trim() !== '' && wordToReplaceTo.trim() !== '') {
//       setWordsToReplace([
//         ...wordsToReplace,
//         { from: wordToReplaceFrom.trim(), to: wordToReplaceTo.trim() },
//       ]);
//       setWordToReplaceFrom('');
//       setWordToReplaceTo('');
//     }
//   };

//   const handleDeleteWordToReplace = (index) => {
//     const updatedWords = [...wordsToReplace];
//     updatedWords.splice(index, 1);
//     setWordsToReplace(updatedWords);
//   };

//   const [deleteStrings, setDeleteStrings] = useState([]);
//   const [replacePairs, setReplacePairs] = useState([]);
//   const [prependText, setPrependText] = useState('');
//   const [appendText, setAppendText] = useState('');
//   const [limitTitleLength, setLimitTitleLength] = useState(false);

//   const handleAddReplacePair = () => {
//     setReplacePairs([...replacePairs, { from: '', to: '' }]);
//   };

//   const handleReplacePairChange = (index, field, value) => {
//     const newPairs = [...replacePairs];
//     newPairs[index][field] = value;
//     setReplacePairs(newPairs);
//   };

//   const handleRemoveReplacePair = (index) => {
//     const newPairs = [...replacePairs];
//     newPairs.splice(index, 1);
//     setReplacePairs(newPairs);
//   };

//   const handleFileChange = (event) => {
//     const files = Array.from(event.target.files).slice(0, 5);
//     setCsvFiles(files);
//     Promise.all(files.map((file) => file.text())).then((contents) => {
//       setFileContents(contents);
//     });
//   };

//   const handleFunctionDialogOpen = () => {
//     setFunctionDialogOpen(true);
//   };

//   const handleFunctionDialogClose = () => {
//     setFunctionDialogOpen(false);
//   };

//   const handleFunctionToggle = (func) => () => {
//     const currentIndex = selectedFunctions.findIndex((f) => f.name === func.name);
//     const newSelected = [...selectedFunctions];

//     if (currentIndex === -1) {
//       newSelected.push(func);
//     } else {
//       newSelected.splice(currentIndex, 1);
//     }

//     setSelectedFunctions(newSelected);
//   };

//   const onDragEnd = (result) => {
//     if (!result.destination) return;
//     const newFunctions = Array.from(selectedFunctions);
//     const [removed] = newFunctions.splice(result.source.index, 1);
//     newFunctions.splice(result.destination.index, 0, removed);
//     setSelectedFunctions(newFunctions);
//   };

//   const applyPriceChanges = useCallback(
//     async (currentData) => {
//       try {
//         if (!selectedPriceSettings) {
//           throw new Error('価格設定が取得されていません。');
//         }

//         const updatedPriceSettings = {
//           ...selectedPriceSettings,
//           selectedShippingTemplateId,
//         };

//         const updatedData = await Promise.all(
//           currentData.map(async (file) => {
//             const parsedResult = Papa.parse(file.content, { header: true });
//             const data = parsedResult.data;
//             const newPrices = calculatePrices(
//               data,
//               updatedPriceSettings,
//               selectedShippingTemplateId
//             );
//             const updatedData = data.map((item, index) => {
//               const calculatedPrice = newPrices[index];
//               if (calculatedPrice) {
//                 return {
//                   ...item,
//                   StartPrice: calculatedPrice.discountedPrice,
//                   BestOfferAutoAcceptPrice: calculatedPrice.bestOfferAutoAcceptPrice,
//                   MinimumBestOfferPrice: calculatedPrice.minimumBestOfferPrice,
//                 };
//               } else {
//                 return item;
//               }
//             });

//             const csvContent = Papa.unparse(updatedData);
//             return {
//               ...file,
//               content: csvContent,
//             };
//           })
//         );

//         return updatedData;
//       } catch (error) {
//         console.error('価格適用中エラー:', error);
//         setSnackbar({
//           open: true,
//           message: '価格適用中にエラーが発生しました。',
//           severity: 'error',
//         });
//         throw error;
//       }
//     },
//     [selectedPriceSettings, selectedShippingTemplateId, setSnackbar]
//   );

//   const applyAITitlesWrapper = useCallback(
//     async (currentData) => {
//       try {
//         if (!userApiKey) {
//           setSnackbar({
//             open: true,
//             message:
//               'OpenAI APIキーが設定されていません。ユーザー設定でAPIキーを入力してください。',
//             severity: 'error',
//           });
//           throw new Error('OpenAI APIキーが設定されていません。');
//         }

//         setProgress({ totalItems: 0, completedItems: 0 });
//         setErrorItems([]);

//         const customizationOptions = {
//           deleteStrings,
//           replacePairs,
//           prependText,
//           appendText,
//           limitTitleLength,
//         };

//         const { updatedData, errorItems } = await applyAITitles(
//           currentData,
//           userApiKey,
//           (progressData) => {
//             setProgress(progressData);
//           },
//           customizationOptions
//         );

//         setErrorItems(errorItems);
//         return updatedData;
//       } catch (error) {
//         console.error('AIタイトル適用中エラー:', error);
//         setSnackbar({
//           open: true,
//           message: 'AIタイトル適用中にエラーが発生しました。',
//           severity: 'error',
//         });
//         throw error;
//       }
//     },
//     [
//       userApiKey,
//       setSnackbar,
//       setProgress,
//       setErrorItems,
//       deleteStrings,
//       replacePairs,
//       prependText,
//       appendText,
//       limitTitleLength,
//     ]
//   );

//   const applyAIProductDescriptionsWrapper = useCallback(
//     async (currentData) => {
//       try {
//         if (!userApiKey) {
//           setSnackbar({
//             open: true,
//             message:
//               'OpenAI APIキーが設定されていません。ユーザー設定でAPIキーを入力してください。',
//             severity: 'error',
//           });
//           throw new Error('OpenAI APIキーが設定されていません。');
//         }

//         setProgress({ totalItems: 0, completedItems: 0 });
//         setErrorItems([]);

//         const { updatedData, errorItems } = await applyAIProductDescriptions(
//           currentData,
//           userApiKey,
//           (progressData) => {
//             setProgress(progressData);
//           },
//           selectedDesignTemplate,
//           {
//             customFilters,
//             selectedFilters,
//             sentencesToDelete,
//             wordsToReplace,
//             mandatoryKeywords,
//           }
//         );

//         setErrorItems(errorItems);
//         return updatedData;
//       } catch (error) {
//         console.error('AI商品説明適用中エラー:', error);
//         setSnackbar({
//           open: true,
//           message: 'AI商品説明適用中にエラーが発生しました。',
//           severity: 'error',
//         });
//         throw error;
//       }
//     },
//     [
//       userApiKey,
//       setSnackbar,
//       setProgress,
//       setErrorItems,
//       selectedDesignTemplate,
//       customFilters,
//       selectedFilters,
//       sentencesToDelete,
//       wordsToReplace,
//       mandatoryKeywords,
//     ]
//   );

//   const applyItemSpecificsWrapper = useCallback(
//     async (currentData, options) => {
//       const {
//         selectedItemSpecificsCategory,
//         selectedItemSpecificsColumns,
//         itemSpecificsMatchingOptions,
//       } = options;
//       try {
//         if (!user) {
//           setSnackbar({
//             open: true,
//             message: 'ユーザー情報が取得できません。',
//             severity: 'error',
//           });
//           throw new Error('ユーザー情報が取得できません。');
//         }

//         if (!selectedItemSpecificsCategory) {
//           setSnackbar({
//             open: true,
//             message: '適用するカテゴリーを選択してください。',
//             severity: 'warning',
//           });
//           throw new Error('適用するカテゴリーを選択してください。');
//         }

//         setProgress({ totalItems: 0, completedItems: 0 });

//         const updatedData = await applyItemSpecificsProcessor(
//           currentData,
//           user.uid,
//           {
//             selectedCategory: selectedItemSpecificsCategory,
//             selectedColumns: selectedItemSpecificsColumns,
//             matchingOptions: itemSpecificsMatchingOptions,
//           },
//           (progressData) => {
//             setProgress(progressData);
//           }
//         );

//         return updatedData;
//       } catch (error) {
//         console.error('Item Specifics適用中エラー:', error);
//         setSnackbar({
//           open: true,
//           message: error.message || 'Item Specifics適用中にエラーが発生しました。',
//           severity: 'error',
//         });
//         throw error;
//       }
//     },
//     [user, setSnackbar, setProgress]
//   );

//   useEffect(() => {
//     setAvailableFunctions((prevFunctions) =>
//       prevFunctions.map((func) => {
//         if (func.name === 'applyPriceChanges') {
//           return { ...func, func: applyPriceChanges };
//         }
//         if (func.name === 'applyAITitles') {
//           return { ...func, func: applyAITitlesWrapper };
//         }
//         if (func.name === 'applyAIProductDescriptions') {
//           return { ...func, func: applyAIProductDescriptionsWrapper };
//         }
//         return func;
//       })
//     );
//   }, [applyPriceChanges, applyAITitlesWrapper, applyAIProductDescriptionsWrapper]);

//   useEffect(() => {
//     setAvailableFunctions((prevFunctions) =>
//       prevFunctions.map((func) =>
//         func.name === 'applyItemSpecifics'
//           ? { ...func, func: applyItemSpecificsWrapper }
//           : func
//       )
//     );
//   }, [applyItemSpecificsWrapper]);

//   const applyPatternSettings = (pattern) => {
//     if (!pattern || !pattern.settings) {
//       setSnackbar({
//         open: true,
//         message: '選択されたパターンが正しくありません。',
//         severity: 'error',
//       });
//       return;
//     }

//     const settings = pattern.settings;

//     if (settings.applyTemplate && settings.applyTemplate.selectedTemplateName) {
//       setSelectedTemplateName(settings.applyTemplate.selectedTemplateName);
//     }

//     if (settings.applyPriceChanges && settings.applyPriceChanges.selectedShippingTemplateId) {
//       setSelectedShippingTemplateId(settings.applyPriceChanges.selectedShippingTemplateId);
//     }

//     if (settings.applyAITitles) {
//       setDeleteStrings(settings.applyAITitles.deleteStrings || []);
//       setReplacePairs(settings.applyAITitles.replacePairs || []);
//       setPrependText(settings.applyAITitles.prependText || '');
//       setAppendText(settings.applyAITitles.appendText || '');
//       setLimitTitleLength(settings.applyAITitles.limitTitleLength || false);
//     }

//     if (settings.applyAIProductDescriptions && settings.applyAIProductDescriptions.selectedDesignTemplate) {
//       setSelectedDesignTemplate(settings.applyAIProductDescriptions.selectedDesignTemplate);
//       setCustomFilters(settings.applyAIProductDescriptions.customFilters || []);
//       setSentencesToDelete(settings.applyAIProductDescriptions.sentencesToDelete || []);
//       setWordsToReplace(settings.applyAIProductDescriptions.wordsToReplace || []);
//       setMandatoryKeywords(settings.applyAIProductDescriptions.mandatoryKeywords || []);
//     }

//     if (settings.applyItemSpecifics && settings.applyItemSpecifics.selectedItemSpecificsCategory) {
//       setSelectedItemSpecificsCategory(settings.applyItemSpecifics.selectedItemSpecificsCategory);
//       setSelectedItemSpecificsColumns(settings.applyItemSpecifics.selectedItemSpecificsColumns || []);
//       setItemSpecificsMatchingOptions(settings.applyItemSpecifics.itemSpecificsMatchingOptions || itemSpecificsMatchingOptions);
//     }

//     const newSelectedFunctions = availableFunctions.filter((func) =>
//       Object.keys(settings).includes(func.name)
//     );
//     setSelectedFunctions(newSelectedFunctions);

//     setSnackbar({
//       open: true,
//       message: `パターン "${pattern.name}" が適用されました。`,
//       severity: 'success',
//     });
//   };

//   const saveCurrentSettingsAsPattern = async () => {
//     if (!newPatternName.trim()) {
//       setSnackbar({
//         open: true,
//         message: 'パターン名を入力してください',
//         severity: 'warning',
//       });
//       return;
//     }

//     const newPattern = {
//       name: newPatternName.trim(),
//       settings: {},
//     };

//     selectedFunctions.forEach((func) => {
//       if (func.name === 'applyTemplate') {
//         newPattern.settings.applyTemplate = { selectedTemplateName };
//       }
//       if (func.name === 'applyPriceChanges') {
//         newPattern.settings.applyPriceChanges = { selectedShippingTemplateId };
//       }
//       if (func.name === 'applyAITitles') {
//         newPattern.settings.applyAITitles = {
//           deleteStrings,
//           replacePairs,
//           prependText,
//           appendText,
//           limitTitleLength,
//         };
//       }
//       if (func.name === 'applyAIProductDescriptions') {
//         newPattern.settings.applyAIProductDescriptions = {
//           selectedDesignTemplate,
//           customFilters,
//           sentencesToDelete,
//           wordsToReplace,
//           mandatoryKeywords,
//         };
//       }
//       if (func.name === 'applyItemSpecifics') {
//         newPattern.settings.applyItemSpecifics = {
//           selectedItemSpecificsCategory,
//           selectedItemSpecificsColumns,
//           itemSpecificsMatchingOptions,
//         };
//       }
//     });

//     try {
//       const userSettingsRef = doc(db, 'userSettings', user.uid);
//       const docSnap = await getDoc(userSettingsRef);
//       let existingPatterns = [];
//       if (docSnap.exists()) {
//         existingPatterns = docSnap.data().patterns || [];
//       }

//       const patternExists = existingPatterns.some(
//         (pattern) => pattern.name === newPattern.name
//       );

//       if (patternExists) {
//         setSnackbar({
//           open: true,
//           message: '同名のパターンが既に存在します。',
//           severity: 'error',
//         });
//         return;
//       }

//       existingPatterns.push(newPattern);
//       await setDoc(userSettingsRef, { patterns: existingPatterns }, { merge: true });
//       setPatterns(existingPatterns);
//       setIsSavePatternDialogOpen(false);
//       setNewPatternName('');
//       setSnackbar({
//         open: true,
//         message: 'パターンが保存されました。',
//         severity: 'success',
//       });
//     } catch (error) {
//       console.error('パターン保存中エラー:', error);
//       setSnackbar({
//         open: true,
//         message: 'パターンの保存中にエラーが発生しました。',
//         severity: 'error',
//       });
//     }
//   };

//   const handleDeletePattern = async (patternName) => {
//     try {
//       const userSettingsRef = doc(db, 'userSettings', user.uid);
//       const docSnap = await getDoc(userSettingsRef);
//       if (docSnap.exists()) {
//         let existingPatterns = docSnap.data().patterns || [];
//         existingPatterns = existingPatterns.filter((p) => p.name !== patternName);
//         await setDoc(userSettingsRef, { patterns: existingPatterns }, { merge: true });
//         setPatterns(existingPatterns);
//         setSnackbar({
//           open: true,
//           message: 'パターンが削除されました。',
//           severity: 'success',
//         });
//       }
//     } catch (error) {
//       console.error('パターン削除エラー:', error);
//       setSnackbar({
//         open: true,
//         message: 'パターンの削除中にエラーが発生しました。',
//         severity: 'error',
//       });
//     }
//   };

//   const [isSavePatternDialogOpen, setIsSavePatternDialogOpen] = useState(false);
//   const [newPatternName, setNewPatternName] = useState('');
//   const [isManagePatternsDialogOpen, setIsManagePatternsDialogOpen] = useState(false);

//   const handleUploadAndExecute = async () => {
//     if (!user) {
//       setSnackbar({
//         open: true,
//         message: 'ユーザーが認証されていません。',
//         severity: 'error',
//       });
//       return;
//     }

//     if (csvFiles.length === 0) {
//       setSnackbar({
//         open: true,
//         message: 'アップロードするファイルがありません。',
//         severity: 'warning',
//       });
//       return;
//     }

//     if (selectedFunctions.length === 0) {
//       setSnackbar({
//         open: true,
//         message: '実行する機能を選択してください。',
//         severity: 'warning',
//       });
//       return;
//     }

//     setIsScheduling(true);

//     try {
//       const filesData = csvFiles.map((file, index) => ({
//         name: file.name,
//         content: fileContents[index],
//       }));

//       const executeFunctions = async () => {
//         let currentData = filesData;

//         for (const func of selectedFunctions) {
//           try {
//             if (func.func.constructor.name === 'AsyncFunction') {
//               if (func.name === 'applyTemplate') {
//                 currentData = await func.func(currentData, user, selectedTemplateName);
//               } else if (func.name === 'applyItemSpecifics') {
//                 currentData = await func.func(currentData, {
//                   selectedItemSpecificsCategory,
//                   selectedItemSpecificsColumns,
//                   itemSpecificsMatchingOptions,
//                 });
//               } else {
//                 currentData = await func.func(currentData);
//               }
//             } else {
//               if (func.name === 'applyTemplate') {
//                 currentData = func.func(currentData, user, selectedTemplateName);
//               } else if (func.name === 'applyItemSpecifics') {
//                 currentData = func.func(currentData, {
//                   selectedItemSpecificsCategory,
//                   selectedItemSpecificsColumns,
//                   itemSpecificsMatchingOptions,
//                 });
//               } else {
//                 currentData = func.func(currentData);
//               }
//             }
//           } catch (error) {
//             console.error(`機能 "${func.displayName}" 実行中エラー:`, error);
//             setSnackbar({
//               open: true,
//               message: `機能 "${func.displayName}" 実行中にエラーが発生しました。`,
//               severity: 'error',
//             });
//             setIsScheduling(false);
//             return;
//           }
//         }

//         setProcessedData(currentData);

//         setSnackbar({
//           open: true,
//           message: '選択された機能を実行しました。',
//           severity: 'success',
//         });
//         setIsScheduling(false);
//       };

//       if (isImmediate) {
//         await executeFunctions();
//       } else {
//         const now = new Date();
//         const scheduledDateTime = new Date(scheduledTime);
//         const delay = scheduledDateTime - now;

//         if (delay > 0) {
//           setSnackbar({
//             open: true,
//             message: 'タスクがスケジュールされました。',
//             severity: 'success',
//           });
//           setTimeout(async () => {
//             await executeFunctions();
//           }, delay);
//         } else {
//           setSnackbar({
//             open: true,
//             message: '指定された時間は過去です。正しい時間を選択してください。',
//             severity: 'error',
//           });
//           setIsScheduling(false);
//         }
//       }
//     } catch (error) {
//       console.error('処理中エラー:', error);
//       setSnackbar({
//         open: true,
//         message: '処理中にエラーが発生しました。',
//         severity: 'error',
//       });
//       setIsScheduling(false);
//     }
//   };

//   const handleTabChange = (event, newValue) => {
//     setActiveTab(newValue);
//   };

//   const [parsedData, setParsedData] = useState([]);

//   useEffect(() => {
//     const parseCSVFiles = () => {
//       const data = fileContents.map((content) => {
//         const parsedResult = Papa.parse(content, { header: true });
//         return {
//           data: parsedResult.data,
//           columns: parsedResult.meta.fields,
//         };
//       });
//       setParsedData(data);
//     };

//     if (fileContents.length > 0) {
//       parseCSVFiles();
//     } else {
//       setParsedData([]);
//     }
//   }, [fileContents]);

//   const [processedParsedData, setProcessedParsedData] = useState([]);

//   useEffect(() => {
//     if (processedData.length > 0) {
//       const data = processedData.map((file) => {
//         const parsedResult = Papa.parse(file.content, { header: true });
//         return {
//           name: file.name,
//           data: parsedResult.data,
//           columns: parsedResult.meta.fields,
//         };
//       });
//       setProcessedParsedData(data);
//     } else {
//       setProcessedParsedData([]);
//     }
//   }, [processedData]);

//   const handleFilterChange = (label) => {
//     setSelectedFilters((prev) =>
//       prev.includes(label) ? prev.filter((item) => item !== label) : [...prev, label]
//     );
//   };

//   const handleAddCustomFilter = () => {
//     if (customFilterInput.trim() !== '') {
//       setCustomFilters([...customFilters, customFilterInput.trim()]);
//       setCustomFilterInput('');
//     }
//   };

//   const handleDeleteCustomFilter = (index) => {
//     const updatedFilters = [...customFilters];
//     updatedFilters.splice(index, 1);
//     setCustomFilters(updatedFilters);
//   };

//   const getFirstImageUrl = (picUrlString) => {
//     if (!picUrlString) return '';
//     const urls = picUrlString.split('|');
//     return urls[0] || '';
//   };

//   const [selectedProduct, setSelectedProduct] = useState(null);
//   const [isModalOpen, setIsModalOpen] = useState(false);

//   const [isImageModalOpen, setIsImageModalOpen] = useState(false);
//   const [currentImageUrl, setCurrentImageUrl] = useState('');

//   const handleOpenImageModal = (url) => {
//     setCurrentImageUrl(url);
//     setIsImageModalOpen(true);
//   };

//   const handleCloseImageModal = () => {
//     setIsImageModalOpen(false);
//     setCurrentImageUrl('');
//   };

//   const [isDrawerOpen, setIsDrawerOpen] = useState(false);

//   const ImageGallery = ({ picUrlString }) => {
//     if (!picUrlString) return null;
//     const urls = picUrlString.split('|');

//     return (
//       <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mt: 2 }}>
//         {urls.map((url, index) => (
//           <Box key={index} sx={{ position: 'relative' }}>
//             <img
//               src={url}
//               alt={`商品画像 ${index + 1}`}
//               style={{
//                 width: '100px',
//                 height: '100px',
//                 objectFit: 'cover',
//                 cursor: 'pointer',
//               }}
//               onClick={() => {
//                 handleOpenImageModal(url);
//               }}
//             />
//           </Box>
//         ))}
//       </Box>
//     );
//   };

//   return (
//     <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
//       <Typography variant="h6" gutterBottom>
//         CSVファイルのアップロードとタスクのスケジュール
//       </Typography>

//       <Button variant="contained" component="label">
//         ファイルを選択（最大5つまで）
//         <input
//           type="file"
//           accept=".csv"
//           multiple
//           hidden
//           onChange={handleFileChange}
//         />
//       </Button>

//       {csvFiles.length > 0 && (
//         <Box sx={{ mt: 2 }}>
//           <Typography variant="subtitle1">選択されたファイル:</Typography>
//           <List>
//             {csvFiles.map((file, index) => (
//               <ListItem key={index}>
//                 <Typography>{file.name}</Typography>
//               </ListItem>
//             ))}
//           </List>
//         </Box>
//       )}

//       <Button
//         variant="contained"
//         color="primary"
//         sx={{ mt: 2 }}
//         onClick={handleFunctionDialogOpen}
//         startIcon={<MenuIcon />}
//       >
//         実行する機能を選択
//       </Button>

//       <Box sx={{ mt: 2 }}>
//         <FormControl fullWidth>
//           <InputLabel>パターンを選択</InputLabel>
//           <Select
//             value={selectedPatternName}
//             onChange={(e) => {
//               const patternName = e.target.value;
//               setSelectedPatternName(patternName);
//               const pattern = patterns.find((p) => p.name === patternName);
//               applyPatternSettings(pattern);
//             }}
//           >
//             <MenuItem value="">
//               <em>パターンを選択</em>
//             </MenuItem>
//             {patterns.map((pattern) => (
//               <MenuItem key={pattern.name} value={pattern.name}>
//                 {pattern.name}
//               </MenuItem>
//             ))}
//           </Select>
//         </FormControl>
//         <Button
//           variant="outlined"
//           color="primary"
//           onClick={() => setIsSavePatternDialogOpen(true)}
//           sx={{ mt: 1 }}
//         >
//           現在の設定をパターンとして保存
//         </Button>
//         <Button
//           variant="outlined"
//           color="primary"
//           onClick={() => setIsManagePatternsDialogOpen(true)}
//           sx={{ mt: 1, ml: 1 }}
//         >
//           パターンを管理
//         </Button>
//       </Box>

//       {/* テンプレート適用 */}
//       {selectedFunctions.some((func) => func.name === 'applyTemplate') && (
//         <Paper sx={{ p: 2, mt: 2 }}>
//           <Typography variant="h6">1. テンプレート適用</Typography>
//           <Grid container spacing={2} sx={{ mt: 1 }}>
//             <Grid item xs={12} sm={6}>
//               <FormControl fullWidth>
//                 <InputLabel>テンプレートを選択</InputLabel>
//                 <Select
//                   value={selectedTemplateName}
//                   onChange={(e) => setSelectedTemplateName(e.target.value)}
//                 >
//                   {templateList.map((template) => (
//                     <MenuItem key={template.name} value={template.name}>
//                       {template.name}
//                     </MenuItem>
//                   ))}
//                 </Select>
//               </FormControl>
//             </Grid>
//           </Grid>
//         </Paper>
//       )}

//       {/* 価格設定 */}
//       {selectedFunctions.some((func) => func.name === 'applyPriceChanges') && (
//         <Paper sx={{ p: 2, mt: 2 }}>
//           <Typography variant="h6">2. 価格機能</Typography>
//           <Grid container spacing={2} sx={{ mt: 1 }}>
//             {shippingRateTemplates.length > 0 && (
//               <Grid item xs={12} sm={6}>
//                 <FormControl fullWidth>
//                   <InputLabel>送料設定を選択</InputLabel>
//                   <Select
//                     value={selectedShippingTemplateId}
//                     onChange={(e) => setSelectedShippingTemplateId(e.target.value)}
//                   >
//                     {shippingRateTemplates.map((template) => (
//                       <MenuItem key={template.id} value={template.id}>
//                         {template.name}
//                       </MenuItem>
//                     ))}
//                   </Select>
//                 </FormControl>
//               </Grid>
//             )}
//           </Grid>
//           <Box sx={{ mt: 2 }}>
//             <Typography variant="subtitle1">現在の価格設定</Typography>
//             <Typography>為替レート: 1 USD = {exchangeRate} JPY</Typography>
//             <Typography>目標利益率: {targetProfitMargin}%</Typography>
//             <Typography>eBay最終価値手数料: {fees.ebayFinalValue || 0}%</Typography>
//             <Typography>Payoneer手数料: {fees.payoneer || 0}%</Typography>
//             <Typography>広告出品手数料: {fees.promotedListing || 0}%</Typography>
//             <Typography>eBay取引手数料: {fees.ebayTransactionFee || 0} USD</Typography>
//             <Typography>
//               ベストオファー自動承認価格:{' '}
//               {bestOfferSettings.bestOfferAutoAcceptPercentage || 0}%
//             </Typography>
//             <Typography>
//               最小ベストオファー価格:{' '}
//               {bestOfferSettings.minimumBestOfferPercentage || 0}%
//             </Typography>
//           </Box>
//         </Paper>
//       )}

//       {/* AIタイトル */}
//       {selectedFunctions.some((func) => func.name === 'applyAITitles') && (
//         <Paper sx={{ p: 2, mt: 2 }}>
//           <Typography variant="h6">3. AIタイトル</Typography>
//           <Grid container spacing={2} sx={{ mt: 1 }}>
//             {/* タイトルカスタマイズテンプレート選択 */}
//             <Grid item xs={12} sm={6}>
//               <FormControl fullWidth>
//                 <InputLabel>タイトルカスタマイズテンプレートを選択</InputLabel>
//                 <Select
//                   value={selectedCategory}
//                   onChange={(e) => {
//                     const selectedName = e.target.value;
//                     setSelectedCategory(selectedName);

//                     if (selectedName) {
//                       const category = customCategories.find((cat) => cat.name === selectedName);
//                       if (category) {
//                         setDeleteStrings(category.deleteStrings || []);
//                         setReplacePairs(category.replacePairs || []);
//                         setPrependText(category.prependText || '');
//                         setAppendText(category.appendText || '');
//                         setLimitTitleLength(category.limitTitleLength || false);
//                       }
//                     } else {
//                       setDeleteStrings([]);
//                       setReplacePairs([]);
//                       setPrependText('');
//                       setAppendText('');
//                       setLimitTitleLength(false);
//                     }
//                   }}
//                 >
//                   {customCategories.map((cat) => (
//                     <MenuItem key={cat.name} value={cat.name}>
//                       {cat.name}
//                     </MenuItem>
//                   ))}
//                 </Select>
//               </FormControl>
//             </Grid>
//           </Grid>

//           {/* タイトルカスタマイズ設定 */}
//           <Box sx={{ mt: 2 }}>
//             <Typography variant="subtitle1">タイトルカスタマイズ設定</Typography>
//             <Grid container spacing={2} sx={{ mt: 1 }}>
//               <Grid item xs={12}>
//                 <TextField
//                   label="削除したい単語 (カンマ区切り)"
//                   value={deleteStrings.join(', ')}
//                   onChange={(e) =>
//                     setDeleteStrings(e.target.value.split(',').map((s) => s.trim()))
//                   }
//                   fullWidth
//                 />
//               </Grid>

//               <Grid item xs={12}>
//                 <Typography variant="subtitle1">置換ペア</Typography>
//                 {replacePairs.map((pair, index) => (
//                   <Box key={index} sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
//                     <TextField
//                       label="置換前"
//                       value={pair.from}
//                       onChange={(e) => handleReplacePairChange(index, 'from', e.target.value)}
//                     />
//                     <TextField
//                       label="置換後"
//                       value={pair.to}
//                       onChange={(e) => handleReplacePairChange(index, 'to', e.target.value)}
//                       sx={{ ml: 1 }}
//                     />
//                     <IconButton onClick={() => handleRemoveReplacePair(index)}>
//                       <DeleteIcon />
//                     </IconButton>
//                   </Box>
//                 ))}
//                 <Button variant="outlined" onClick={handleAddReplacePair} sx={{ mt: 1 }}>
//                   置換ペアを追加
//                 </Button>
//               </Grid>

//               <Grid item xs={12} sm={6}>
//                 <TextField
//                   label="先頭に追加する文字列"
//                   value={prependText}
//                   onChange={(e) => setPrependText(e.target.value)}
//                   fullWidth
//                 />
//               </Grid>

//               <Grid item xs={12} sm={6}>
//                 <TextField
//                   label="末尾に追加する文字列"
//                   value={appendText}
//                   onChange={(e) => setAppendText(e.target.value)}
//                   fullWidth
//                 />
//               </Grid>

//               <Grid item xs={12}>
//                 <FormControlLabel
//                   control={
//                     <Switch
//                       checked={limitTitleLength}
//                       onChange={(e) => setLimitTitleLength(e.target.checked)}
//                     />
//                   }
//                   label="80文字に制限する"
//                 />
//               </Grid>
//             </Grid>
//           </Box>
//         </Paper>
//       )}

//       {/* AI商品説明 */}
//       {selectedFunctions.some((func) => func.name === 'applyAIProductDescriptions') && (
//         <Paper sx={{ p: 2, mt: 2 }}>
//           <Typography variant="h6">4. AI商品説明</Typography>
//           <Grid container spacing={2} sx={{ mt: 1 }}>
//             {designTemplateNames.length > 0 && (
//               <Grid item xs={12} sm={4}>
//                 <FormControl fullWidth>
//                   <InputLabel>デザインテンプレートを選択</InputLabel>
//                   <Select
//                     value={selectedDesignTemplate}
//                     onChange={(e) => setSelectedDesignTemplate(e.target.value)}
//                   >
//                     {designTemplateNames.map((templateName) => (
//                       <MenuItem key={templateName} value={templateName}>
//                         {templateName}
//                       </MenuItem>
//                     ))}
//                   </Select>
//                 </FormControl>
//                 {selectedDesignTemplate && (
//                   <Typography variant="body2" sx={{ mt: 1 }}>
//                     選択中のテンプレート: {selectedDesignTemplate}
//                   </Typography>
//                 )}
//               </Grid>
//             )}

//             <Grid item xs={12} sm={4}>
//               <FormControlLabel
//                 control={
//                   <Switch
//                     checked={useImageDescription}
//                     onChange={(e) => setUseImageDescription(e.target.checked)}
//                     name="useImageDescription"
//                     color="primary"
//                   />
//                 }
//                 label="画像解析の説明を使用"
//               />
//             </Grid>

//             <Grid item xs={12} sm={4}>
//               <Typography variant="subtitle1">必ず含めたいキーワード</Typography>
//               <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
//                 <TextField
//                   label="キーワードを入力"
//                   value={mandatoryKeywordInput}
//                   onChange={(e) => setMandatoryKeywordInput(e.target.value)}
//                   fullWidth
//                 />
//                 <Button
//                   variant="contained"
//                   color="primary"
//                   onClick={handleAddMandatoryKeyword}
//                   sx={{ ml: 1, height: '56px' }}
//                 >
//                   追加
//                 </Button>
//               </Box>
//               <List>
//                 {mandatoryKeywords.map((keyword, index) => (
//                   <ListItem
//                     key={index}
//                     secondaryAction={
//                       <IconButton
//                         edge="end"
//                         aria-label="delete"
//                         onClick={() => handleDeleteMandatoryKeyword(index)}
//                       >
//                         <DeleteIcon />
//                       </IconButton>
//                     }
//                   >
//                     <ListItemText primary={keyword} />
//                   </ListItem>
//                 ))}
//               </List>
//             </Grid>
//           </Grid>

//           <Grid container spacing={2} sx={{ mt: 2 }}>
//             {/* カスタムフィルター等 */}
//             <Grid item xs={12} sm={4}>
//               <OptionsPanel
//                 selectedFilters={selectedFilters}
//                 handleFilterChange={handleFilterChange}
//                 customFilterInput={customFilterInput}
//                 setCustomFilterInput={setCustomFilterInput}
//                 customFilters={customFilters}
//                 handleAddCustomFilter={handleAddCustomFilter}
//                 handleDeleteCustomFilter={handleDeleteCustomFilter}
//                 sentencesToDelete={sentencesToDelete}
//                 setSentencesToDelete={setSentencesToDelete}
//                 wordsToReplace={wordsToReplace}
//                 setWordsToReplace={setWordsToReplace}
//               />
//             </Grid>

//             <Grid item xs={12} sm={4}>
//               <Typography variant="subtitle1">削除したい文章</Typography>
//               <TextField
//                 label="文章を入力"
//                 value={sentenceToDeleteInput}
//                 onChange={(e) => setSentenceToDeleteInput(e.target.value)}
//                 fullWidth
//               />
//               <Button
//                 variant="contained"
//                 color="primary"
//                 onClick={handleAddSentenceToDelete}
//                 sx={{ mt: 1 }}
//               >
//                 追加
//               </Button>
//               <List>
//                 {sentencesToDelete.map((sentence, index) => (
//                   <ListItem
//                     key={index}
//                     secondaryAction={
//                       <IconButton
//                         edge="end"
//                         aria-label="delete"
//                         onClick={() => handleDeleteSentenceToDelete(index)}
//                       >
//                         <DeleteIcon />
//                       </IconButton>
//                     }
//                   >
//                     <ListItemText primary={sentence} />
//                   </ListItem>
//                 ))}
//               </List>
//             </Grid>

//             <Grid item xs={12} sm={4}>
//               <Typography variant="subtitle1">置換単語</Typography>
//               <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
//                 <TextField
//                   label="置換前"
//                   value={wordToReplaceFrom}
//                   onChange={(e) => setWordToReplaceFrom(e.target.value)}
//                   fullWidth
//                 />
//                 <Typography sx={{ mx: 1 }}>➔</Typography>
//                 <TextField
//                   label="置換後"
//                   value={wordToReplaceTo}
//                   onChange={(e) => setWordToReplaceTo(e.target.value)}
//                   fullWidth
//                 />
//               </Box>
//               <Button
//                 variant="contained"
//                 color="primary"
//                 onClick={handleAddWordToReplace}
//                 sx={{ mt: 1 }}
//               >
//                 追加
//               </Button>
//               <List>
//                 {wordsToReplace.map((wordPair, index) => (
//                   <ListItem
//                     key={index}
//                     secondaryAction={
//                       <IconButton
//                         edge="end"
//                         aria-label="delete"
//                         onClick={() => handleDeleteWordToReplace(index)}
//                       >
//                         <DeleteIcon />
//                       </IconButton>
//                     }
//                   >
//                     <ListItemText primary={`${wordPair.from} ➔ ${wordPair.to}`} />
//                   </ListItem>
//                 ))}
//               </List>
//             </Grid>
//           </Grid>
//         </Paper>
//       )}

//       {/* Item Specifics適用 */}
//       {selectedFunctions.some((func) => func.name === 'applyItemSpecifics') && (
//         <Paper sx={{ p: 2, mt: 2 }}>
//           <Typography variant="h6">5. Item Specifics適用</Typography>
//           <Grid container spacing={2} sx={{ mt: 1 }}>
//             <Grid item xs={12} sm={6}>
//               <FormControl fullWidth>
//                 <InputLabel>適用するカテゴリー</InputLabel>
//                 <Select
//                   value={selectedItemSpecificsCategory}
//                   onChange={(e) => {
//                     setSelectedItemSpecificsCategory(e.target.value);
//                     setSelectedItemSpecificsColumns([]);
//                   }}
//                   label="適用するカテゴリー"
//                 >
//                   {Object.keys(itemSpecificsCategories).map((category) => (
//                     <MenuItem key={category} value={category}>
//                       {category}
//                     </MenuItem>
//                   ))}
//                 </Select>
//               </FormControl>
//             </Grid>
//           </Grid>

//           {selectedItemSpecificsCategory && (
//             <>
//               <Grid container spacing={2} sx={{ mt: 2 }}>
//                 <Grid item xs={12}>
//                   <FormControl fullWidth>
//                     <InputLabel>適用するカラムを選択</InputLabel>
//                     <Select
//                       multiple
//                       value={selectedItemSpecificsColumns}
//                       onChange={(e) => {
//                         const value = e.target.value;
//                         if (value.includes('all')) {
//                           const allColumns = getColumnsForSelectedCategory();
//                           if (
//                             selectedItemSpecificsColumns.length === allColumns.length
//                           ) {
//                             setSelectedItemSpecificsColumns([]);
//                           } else {
//                             setSelectedItemSpecificsColumns(allColumns);
//                           }
//                         } else {
//                           setSelectedItemSpecificsColumns(value);
//                         }
//                       }}
//                       renderValue={(selected) => {
//                         if (selected.length === 0) {
//                           return '適用するカラムを選択';
//                         }

//                         const displayedValues = selected.slice(0, 5);
//                         const hiddenCount = selected.length - displayedValues.length;

//                         return (
//                           <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
//                             {displayedValues.map((value) => (
//                               <Chip key={value} label={value} size="small" />
//                             ))}
//                             {hiddenCount > 0 && (
//                               <Chip label={`+${hiddenCount}個`} size="small" />
//                             )}
//                           </Box>
//                         );
//                       }}
//                     >
//                       <MenuItem
//                         value="all"
//                         onClick={() => {
//                           const allColumns = getColumnsForSelectedCategory();
//                           if (selectedItemSpecificsColumns.length === allColumns.length) {
//                             setSelectedItemSpecificsColumns([]);
//                           } else {
//                             setSelectedItemSpecificsColumns(allColumns);
//                           }
//                         }}
//                       >
//                         <Checkbox
//                           checked={
//                             selectedItemSpecificsColumns.length ===
//                               getColumnsForSelectedCategory().length &&
//                             selectedItemSpecificsColumns.length > 0
//                           }
//                           indeterminate={
//                             selectedItemSpecificsColumns.length > 0 &&
//                             selectedItemSpecificsColumns.length <
//                               getColumnsForSelectedCategory().length
//                           }
//                         />
//                         <Typography variant="body1">全選択</Typography>
//                       </MenuItem>
//                       {getColumnsForSelectedCategory().map((column) => (
//                         <MenuItem key={column} value={column}>
//                           <Checkbox
//                             checked={selectedItemSpecificsColumns.indexOf(column) > -1}
//                           />
//                           <Typography variant="body1">{column}</Typography>
//                         </MenuItem>
//                       ))}
//                     </Select>
//                   </FormControl>
//                 </Grid>
//               </Grid>

//               <Box sx={{ mt: 2 }}>
//                 <Typography variant="subtitle1" gutterBottom>
//                   マッチングの基準を選択してください。
//                 </Typography>
//                 <FormControl component="fieldset">
//                   <RadioGroup
//                     value={itemSpecificsMatchingOptions.matchSource}
//                     onChange={(e) =>
//                       setItemSpecificsMatchingOptions((prev) => ({
//                         ...prev,
//                         matchSource: e.target.value,
//                       }))
//                     }
//                   >
//                     <FormControlLabel
//                       value="title"
//                       control={<Radio />}
//                       label="タイトルのみ"
//                     />
//                     <FormControlLabel
//                       value="description"
//                       control={<Radio />}
//                       label="商品説明のみ (AI生成部分のみ)"
//                     />
//                     <FormControlLabel
//                       value="both"
//                       control={<Radio />}
//                       label="タイトルと商品説明の両方 (AI生成部分のみ)"
//                     />
//                   </RadioGroup>
//                 </FormControl>
//               </Box>

//               <Box sx={{ mt: 2 }}>
//                 <FormControlLabel
//                   control={
//                     <Checkbox
//                       checked={itemSpecificsMatchingOptions.caseSensitive}
//                       onChange={(e) =>
//                         setItemSpecificsMatchingOptions((prev) => ({
//                           ...prev,
//                           caseSensitive: e.target.checked,
//                         }))
//                       }
//                       name="caseSensitive"
//                     />
//                   }
//                   label="大文字小文字を区別する"
//                 />
//                 <FormControlLabel
//                   control={
//                     <Checkbox
//                       checked={itemSpecificsMatchingOptions.partialMatch}
//                       onChange={(e) =>
//                         setItemSpecificsMatchingOptions((prev) => ({
//                           ...prev,
//                           partialMatch: e.target.checked,
//                         }))
//                       }
//                       name="partialMatch"
//                     />
//                   }
//                   label="部分一致を許可する"
//                 />
//               </Box>

//               <Box sx={{ mt: 2 }}>
//                 <Typography variant="subtitle1" gutterBottom>
//                   マッチングの動作を選択してください。
//                 </Typography>
//                 <Typography variant="subtitle1" gutterBottom>
//                   (上記でAI商品説明からマッチングを選択している場合は、自動処理でAI生成した文章からカラムの適所に適用されます。自動処理によって特に値の変更がなかった場合以下の設定が適用されます。)
//                 </Typography>
//                 <FormControl component="fieldset">
//                   <RadioGroup
//                     value={itemSpecificsMatchingOptions.matchingOption}
//                     onChange={(e) =>
//                       setItemSpecificsMatchingOptions((prev) => ({
//                         ...prev,
//                         matchingOption: e.target.value,
//                       }))
//                     }
//                   >
//                     <FormControlLabel
//                       value="priority"
//                       control={<Radio />}
//                       label="優先順位に基づいて適用"
//                     />
//                     <FormControlLabel
//                       value="all"
//                       control={<Radio />}
//                       label="マッチしたすべての値を適用"
//                     />
//                   </RadioGroup>
//                 </FormControl>
//               </Box>
//             </>
//           )}
//         </Paper>
//       )}

//       {/* 実行モード */}
//       <Box sx={{ mt: 2 }}>
//         <Typography variant="subtitle1">実行モード:</Typography>
//         <Button
//           variant={isImmediate ? 'contained' : 'outlined'}
//           onClick={() => setIsImmediate(true)}
//           sx={{ mr: 1 }}
//         >
//           即時実行
//         </Button>
//         <Button
//           variant={!isImmediate ? 'contained' : 'outlined'}
//           onClick={() => setIsImmediate(false)}
//         >
//           スケジュール実行
//         </Button>
//       </Box>

//       {!isImmediate && (
//         <Box sx={{ mt: 2 }}>
//           <TextField
//             label="実行開始時間"
//             type="datetime-local"
//             value={scheduledTime}
//             onChange={(e) => setScheduledTime(e.target.value)}
//             InputLabelProps={{
//               shrink: true,
//             }}
//             fullWidth
//           />
//         </Box>
//       )}

//       <Button
//         variant="contained"
//         color="secondary"
//         sx={{ mt: 2 }}
//         onClick={handleUploadAndExecute}
//         disabled={isScheduling}
//       >
//         {isScheduling ? '処理中...' : 'アップロードして実行'}
//       </Button>

//       {isScheduling && (
//         <Box sx={{ mt: 2 }}>
//           <Typography variant="h6">処理進捗:</Typography>
//           <Typography>
//             {progress.completedItems} / {progress.totalItems} 件が完了しました
//           </Typography>
//           <LinearProgress
//             variant="determinate"
//             value={(progress.completedItems / progress.totalItems) * 100}
//           />
//         </Box>
//       )}

//       {processedParsedData.length > 0 && (
//         <Box sx={{ mt: 4 }}>
//           <Typography variant="h6">処理結果のプレビュー:</Typography>
//           <Tabs
//             value={activeTab}
//             onChange={handleTabChange}
//             variant="scrollable"
//             scrollButtons="auto"
//           >
//             {processedParsedData.map((file, index) => (
//               <Tab key={index} label={file.name} />
//             ))}
//           </Tabs>
//           {processedParsedData.map((file, index) => (
//             <div
//               key={index}
//               role="tabpanel"
//               hidden={activeTab !== index}
//               id={`tabpanel-${index}`}
//             >
//               {activeTab === index && (
//                 <Box sx={{ p: 2 }}>
//                   <Paper elevation={2} sx={{ overflowX: 'auto' }}>
//                     <TableContainer>
//                       <Table
//                         size="small"
//                         sx={{
//                           tableLayout: 'auto',
//                           width: 'auto',
//                         }}
//                       >
//                         <TableHead>
//                           <TableRow>
//                             {file.columns.map((column, idx) => (
//                               <TableCell
//                                 key={idx}
//                                 sx={{
//                                   maxWidth: '150px',
//                                   whiteSpace: 'nowrap',
//                                   padding: '8px',
//                                   border: '1px solid rgba(224, 224, 224, 1)',
//                                   fontWeight: 'bold',
//                                 }}
//                               >
//                                 {column}
//                               </TableCell>
//                             ))}
//                           </TableRow>
//                         </TableHead>
//                         <TableBody>
//                           {file.data.map((row, rowIndex) => (
//                             <TableRow
//                               key={rowIndex}
//                               onClick={() => {
//                                 setSelectedProduct(row);
//                                 setIsDrawerOpen(true);
//                               }}
//                               style={{ cursor: 'pointer' }}
//                             >
//                               {file.columns.map((column, colIndex) => (
//                                 <TableCell
//                                   key={colIndex}
//                                   sx={{
//                                     maxWidth: '150px',
//                                     whiteSpace: 'nowrap',
//                                     padding: '8px',
//                                     border: '1px solid rgba(224, 224, 224, 1)',
//                                   }}
//                                 >
//                                   {column === 'PicURL' ? (
//                                     row[column] ? (
//                                       <img
//                                         src={getFirstImageUrl(row[column])}
//                                         alt="商品画像"
//                                         style={{
//                                           width: '50px',
//                                           height: '50px',
//                                           objectFit: 'cover',
//                                         }}
//                                       />
//                                     ) : (
//                                       'なし'
//                                     )
//                                   ) : (
//                                     row[column]
//                                   )}
//                                 </TableCell>
//                               ))}
//                             </TableRow>
//                           ))}
//                         </TableBody>
//                       </Table>
//                     </TableContainer>
//                   </Paper>

//                   <Drawer
//                     anchor="right"
//                     open={isDrawerOpen}
//                     onClose={() => setIsDrawerOpen(false)}
//                   >
//                     <Box sx={{ width: 700, p: 2 }}>
//                       {selectedProduct ? (
//                         <>
//                           <Typography variant="h6">{selectedProduct['Title']}</Typography>
//                           <ImageGallery picUrlString={selectedProduct['PicURL']} />
//                           <Typography variant="body1" sx={{ mt: 2 }}>
//                             価格: {selectedProduct['StartPrice']}
//                           </Typography>
//                           <Typography variant="body1" sx={{ mt: 2 }}>説明:</Typography>
//                           <Box
//                             sx={{ mt: 2 }}
//                             dangerouslySetInnerHTML={{ __html: selectedProduct['Description'] }}
//                           />
//                         </>
//                       ) : (
//                         <Typography variant="body1">商品が選択されていません</Typography>
//                       )}
//                     </Box>
//                   </Drawer>

//                   <Dialog
//                     open={isImageModalOpen}
//                     onClose={handleCloseImageModal}
//                     maxWidth="lg"
//                   >
//                     <DialogContent>
//                       <img
//                         src={currentImageUrl}
//                         alt="拡大画像"
//                         style={{ width: '100%', height: 'auto', objectFit: 'contain' }}
//                       />
//                     </DialogContent>
//                     <DialogActions>
//                       <Button onClick={handleCloseImageModal}>閉じる</Button>
//                     </DialogActions>
//                   </Dialog>
//                 </Box>
//               )}
//             </div>
//           ))}
//         </Box>
//       )}

//       {errorItems.length > 0 && (
//         <Box sx={{ mt: 2 }}>
//           <Typography variant="h6" color="error">
//             エラーが発生したアイテム:
//           </Typography>
//           <List>
//             {errorItems.map((item, index) => (
//               <ListItem key={index}>
//                 <Typography>
//                   ファイル名: {item.fileName}, 行番号: {item.rowIndex}, エラー: {item.error}
//                 </Typography>
//               </ListItem>
//             ))}
//           </List>
//         </Box>
//       )}

//       {processedData.length > 0 && (
//         <Box sx={{ mt: 2 }}>
//           <Typography variant="h6">処理結果のダウンロード:</Typography>
//           {processedData.map((file, index) => (
//             <Button
//               key={index}
//               variant="contained"
//               sx={{ mt: 1, mr: 1 }}
//               onClick={() => {
//                 const blob = new Blob([file.content], {
//                   type: 'text/csv;charset=utf-8;',
//                 });
//                 const link = document.createElement('a');
//                 link.href = URL.createObjectURL(blob);
//                 link.setAttribute('download', file.name);
//                 document.body.appendChild(link);
//                 link.click();
//                 document.body.removeChild(link);
//               }}
//             >
//               {file.name}
//             </Button>
//           ))}
//         </Box>
//       )}

//       <Dialog open={functionDialogOpen} onClose={handleFunctionDialogClose}>
//         <DialogTitle>実行する機能を選択</DialogTitle>
//         <DialogContent>
//           <DragDropContext onDragEnd={onDragEnd}>
//             <Droppable droppableId="functions">
//               {(provided) => (
//                 <List {...provided.droppableProps} ref={provided.innerRef}>
//                   {availableFunctions.map((func, index) => (
//                     <Draggable key={func.name} draggableId={func.name} index={index}>
//                       {(provided) => (
//                         <ListItem
//                           button
//                           ref={provided.innerRef}
//                           {...provided.draggableProps}
//                         >
//                           <Checkbox
//                             checked={
//                               selectedFunctions.findIndex((f) => f.name === func.name) !== -1
//                             }
//                             onClick={handleFunctionToggle(func)}
//                           />
//                           <Typography>{func.displayName}</Typography>
//                           <IconButton {...provided.dragHandleProps}>
//                             <DragHandleIcon />
//                           </IconButton>
//                         </ListItem>
//                       )}
//                     </Draggable>
//                   ))}
//                   {provided.placeholder}
//                 </List>
//               )}
//             </Droppable>
//           </DragDropContext>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={handleFunctionDialogClose}>閉じる</Button>
//         </DialogActions>
//       </Dialog>

//       <Dialog
//         open={isSaveTemplateDialogOpen}
//         onClose={() => setIsSaveTemplateDialogOpen(false)}
//       >
//         <DialogTitle>テンプレートの保存</DialogTitle>
//         <DialogContent>
//           <FormControl component="fieldset">
//             <RadioGroup
//               value={saveOption}
//               onChange={(e) => setSaveOption(e.target.value)}
//             >
//               <FormControlLabel
//                 value="new"
//                 control={<Radio />}
//                 label="新しいテンプレートとして保存"
//               />
//               {selectedTemplateId && (
//                 <FormControlLabel
//                   value="overwrite"
//                   control={<Radio />}
//                   label="既存のテンプレートを上書き保存"
//                 />
//               )}
//             </RadioGroup>
//           </FormControl>
//           <TextField
//             label="テンプレート名"
//             value={templateName}
//             onChange={(e) => setTemplateName(e.target.value)}
//             fullWidth
//             sx={{ mt: 2 }}
//           />
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => setIsSaveTemplateDialogOpen(false)}>キャンセル</Button>
//           <Button onClick={saveTemplate} color="primary">
//             保存
//           </Button>
//         </DialogActions>
//       </Dialog>

//       <Dialog
//         open={isManageTemplatesDialogOpen}
//         onClose={() => setIsManageTemplatesDialogOpen(false)}
//       >
//         <DialogTitle>テンプレートの管理</DialogTitle>
//         <DialogContent>
//           <List>
//             {templates.map((template) => (
//               <ListItem key={template.id}>
//                 <ListItemText primary={template.name} />
//                 <IconButton
//                   edge="end"
//                   aria-label="delete"
//                   onClick={() => handleDeleteTemplate(template.id)}
//                 >
//                   <DeleteIcon />
//                 </IconButton>
//               </ListItem>
//             ))}
//           </List>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => setIsManageTemplatesDialogOpen(false)}>閉じる</Button>
//         </DialogActions>
//       </Dialog>

//       <Dialog
//         open={isSavePatternDialogOpen}
//         onClose={() => setIsSavePatternDialogOpen(false)}
//       >
//         <DialogTitle>現在の設定をパターンとして保存</DialogTitle>
//         <DialogContent>
//           <TextField
//             label="パターン名"
//             value={newPatternName}
//             onChange={(e) => setNewPatternName(e.target.value)}
//             fullWidth
//           />
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => setIsSavePatternDialogOpen(false)}>キャンセル</Button>
//           <Button onClick={saveCurrentSettingsAsPattern} color="primary">
//             保存
//           </Button>
//         </DialogActions>
//       </Dialog>

//       <Dialog
//         open={isManagePatternsDialogOpen}
//         onClose={() => setIsManagePatternsDialogOpen(false)}
//       >
//         <DialogTitle>パターンの管理</DialogTitle>
//         <DialogContent>
//           <List>
//             {patterns.map((pattern) => (
//               <ListItem key={pattern.name}>
//                 <ListItemText primary={pattern.name} />
//                 <IconButton
//                   edge="end"
//                   aria-label="delete"
//                   onClick={() => handleDeletePattern(pattern.name)}
//                 >
//                   <DeleteIcon />
//                 </IconButton>
//               </ListItem>
//             ))}
//           </List>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => setIsManagePatternsDialogOpen(false)}>閉じる</Button>
//         </DialogActions>
//       </Dialog>

//       <Snackbar
//         open={snackbar.open}
//         autoHideDuration={6000}
//         onClose={() => setSnackbar({ ...snackbar, open: false })}
//       >
//         <Alert
//           onClose={() => setSnackbar({ ...snackbar, open: false })}
//           severity={snackbar.severity}
//           sx={{ width: '100%' }}
//         >
//           {snackbar.message}
//         </Alert>
//       </Snackbar>
//     </Paper>
//   );
// }

// export default CsvSchedulerUploader;





// // CsvSchedulerUploader.js

// import React, { useState, useEffect, useCallback } from 'react';
// import {
//   Box,
//   Button,
//   Typography,
//   TextField,
//   Paper,
//   Snackbar,
//   Alert,
//   List,
//   ListItem,
//   Checkbox,
//   IconButton,
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogTitle,
//   FormControl,
//   InputLabel,
//   Select,
//   MenuItem,
//   Tabs,
//   Tab,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   LinearProgress,
//   Grid,
//   Switch,
//   ListItemText,
//   RadioGroup,
//   Radio,
//   FormControlLabel,
//   Chip,
//   Drawer,
// } from '@mui/material';
// import { useNavigate } from 'react-router-dom';
// import MenuIcon from '@mui/icons-material/Menu';
// import DragHandleIcon from '@mui/icons-material/DragHandle';
// import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
// import VisibilityIcon from '@mui/icons-material/Visibility';
// import EditIcon from '@mui/icons-material/Edit';
// import MoneyOffIcon from '@mui/icons-material/MoneyOff';
// import ReplayIcon from '@mui/icons-material/Replay';
// import DeleteIcon from '@mui/icons-material/Delete';

// // Firebase のインポート
// import { auth, db } from './firebaseConfig';
// import {
//   getFirestore,
//   getDoc,
//   doc,
//   collection,
//   getDocs,
//   addDoc,
//   setDoc,
//   deleteDoc,
// } from 'firebase/firestore';

// // 機能関数のインポート
// import { applyTemplate, applyItemSpecifics } from './functions';
// // パーサーのインポート
// import Papa from 'papaparse';
// // 価格計算関数のインポート
// import { calculatePrices } from './calculatePrices';
// // AIタイトル生成関数のインポート
// import { applyAITitles } from './applyAITitles';
// // AI商品説明生成関数のインポート
// import { applyAIProductDescriptions } from './applyAIProductDescriptions';
// // CryptoJSのインポート
// import CryptoJS from 'crypto-js';
// // デザインテンプレートのインポート
// import { applyItemSpecificsProcessor } from './ItemSpecificsProcessor';
// import designTemplates from './designTemplates';

// // その他のコンポーネントのインポート
// import OptionsPanel from './OptionsPanel';

// function CsvSchedulerUploader({ user }) {
//   const [csvFiles, setCsvFiles] = useState([]);
//   const [fileContents, setFileContents] = useState([]);
//   const [snackbar, setSnackbar] = useState({
//     open: false,
//     message: '',
//     severity: 'info',
//   });

//   // 状態変数を定義
//   const [selectedTemplate, setSelectedTemplate] = useState('');
//   const [selectedItemSpecificsCategory, setSelectedItemSpecificsCategory] = useState('');
//   const [selectedItemSpecificsColumns, setSelectedItemSpecificsColumns] = useState([]);
//   const [itemSpecificsMatchingOptions, setItemSpecificsMatchingOptions] = useState({
//     caseSensitive: false,
//     partialMatch: true,
//     matchSource: 'title',
//     matchingOption: 'priority',
//   });
//   const [itemSpecificsCategories, setItemSpecificsCategories] = useState({});

//   // デフォルトで全ての機能を選択状態にする
//   const [selectedFunctions, setSelectedFunctions] = useState([]);
//   const [isImmediate, setIsImmediate] = useState(true); // 即時実行かどうか
//   const [scheduledTime, setScheduledTime] = useState(''); // スケジュール時間（文字列）
//   const [functionDialogOpen, setFunctionDialogOpen] = useState(false);
//   const [isScheduling, setIsScheduling] = useState(false); // スケジュール処理中かどうか
//   const [processedData, setProcessedData] = useState([]); // 処理後のデータ
//   const [activeTab, setActiveTab] = useState(0); // アクティブなタブのインデックス

//   const [templateList, setTemplateList] = useState([]);
//   const [selectedTemplateName, setSelectedTemplateName] = useState('');

//   // 価格設定の状態管理
//   const [selectedPriceSettings, setSelectedPriceSettings] = useState(null);

//   // 送料テンプレートの状態管理
//   const [shippingRateTemplates, setShippingRateTemplates] = useState([]);
//   const [selectedShippingTemplateId, setSelectedShippingTemplateId] = useState('');

//   // その他の設定
//   const [exchangeRate, setExchangeRate] = useState(0);
//   const [targetProfitMargin, setTargetProfitMargin] = useState(0);
//   const [fees, setFees] = useState({});
//   const [bestOfferSettings, setBestOfferSettings] = useState({});

//   // ユーザーのAPIキーの状態管理
//   const [userApiKey, setUserApiKey] = useState('');

//   // ユーザー設定の状態管理
//   const [userSettings, setUserSettings] = useState({});

//   // デザインテンプレートの選択状態
//   const [designTemplateNames, setDesignTemplateNames] = useState([]);
//   const [selectedDesignTemplate, setSelectedDesignTemplate] = useState('');

//   // テンプレート管理の状態
//   const [templates, setTemplates] = useState([]);
//   const [templateName, setTemplateName] = useState('');
//   const [isSaveTemplateDialogOpen, setIsSaveTemplateDialogOpen] = useState(false);
//   const [isManageTemplatesDialogOpen, setIsManageTemplatesDialogOpen] = useState(false);
//   const [selectedTemplateId, setSelectedTemplateId] = useState('');
//   const [currentTemplateName, setCurrentTemplateName] = useState('');
//   const [saveOption, setSaveOption] = useState('new');

//   const [customCategories, setCustomCategories] = useState([]);
//   const [selectedCategory, setSelectedCategory] = useState('');

//   // その他の状態
//   const [useImageDescription, setUseImageDescription] = useState(false);
//   const [mandatoryKeywords, setMandatoryKeywords] = useState([]);
//   const [mandatoryKeywordInput, setMandatoryKeywordInput] = useState('');

//   // 新しく追加する状態変数
//   const [sentenceToDeleteInput, setSentenceToDeleteInput] = useState('');
//   const [wordToReplaceFrom, setWordToReplaceFrom] = useState('');
//   const [wordToReplaceTo, setWordToReplaceTo] = useState('');

//   const navigate = useNavigate();

//   // パターン機能の状態管理
//   const [patterns, setPatterns] = useState([]);
//   const [selectedPatternName, setSelectedPatternName] = useState('');

//   // ユーザー固有の秘密鍵を生成
//   const generateEncryptionKey = (uid) => {
//     const salt = 'your-fixed-salt-value'; // 固定の塩値（セキュリティ向上のため、環境変数で管理することを推奨）
//     return CryptoJS.PBKDF2(uid, salt, { keySize: 256 / 32 }).toString();
//   };

//   // 進捗状況の状態
//   const [progress, setProgress] = useState({ totalItems: 0, completedItems: 0 });
//   const [errorItems, setErrorItems] = useState([]);

//   // availableFunctionsの定義をここに移動
//   const [availableFunctions, setAvailableFunctions] = useState([
//     {
//       name: 'applyTemplate',
//       displayName: 'テンプレートを実行する',
//       func: applyTemplate,
//     },
//     {
//       name: 'applyPriceChanges',
//       displayName: '価格の変更を適用する',
//       func: 'applyPriceChanges', // 後で設定
//     },
//     {
//       name: 'applyAITitles',
//       displayName: 'AIタイトルを適用する',
//       func: 'applyAITitles', // 後で設定
//     },
//     {
//       name: 'applyAIProductDescriptions',
//       displayName: 'AI商品説明を適用する',
//       func: 'applyAIProductDescriptions', // 後で設定
//     },
//     {
//       name: 'applyItemSpecifics',
//       displayName: 'Item Specificsの適用をする',
//       func: applyItemSpecifics,
//     },
//   ]);

//   useEffect(() => {
//     const fetchItemSpecificsSettings = async () => {
//       if (!user) return;

//       try {
//         const db = getFirestore();
//         const docRef = doc(db, 'itemSpecificsSettings', user.uid);
//         const docSnap = await getDoc(docRef);

//         if (docSnap.exists()) {
//           const settingsData = docSnap.data();
//           setItemSpecificsCategories(settingsData.categories || {});
//         } else {
//           console.warn('Item Specificsの設定が見つかりませんでした。');
//         }
//       } catch (error) {
//         console.error('Item Specificsの設定取得中にエラーが発生しました:', error);
//       }
//     };

//     fetchItemSpecificsSettings();
//   }, [user]);

//   // Function to get columns for the selected category
//   const getColumnsForSelectedCategory = () => {
//     if (
//       selectedItemSpecificsCategory &&
//       itemSpecificsCategories[selectedItemSpecificsCategory]
//     ) {
//       return Object.keys(
//         itemSpecificsCategories[selectedItemSpecificsCategory].columns || {}
//       );
//     }
//     return [];
//   };

//   // ユーザーが認証されていない場合はログインページへリダイレクト
//   useEffect(() => {
//     if (!user) {
//       navigate('/login');
//     } else {
//       // テンプレート一覧を取得
//       const fetchTemplates = async () => {
//         try {
//           const docRef = doc(db, 'userTemplates', user.uid);
//           const docSnap = await getDoc(docRef);

//           if (docSnap.exists()) {
//             const userData = docSnap.data();
//             const userCategories = userData.categories || {};
//             const templates = Object.values(userCategories)
//               .flat()
//               .map((template) => ({
//                 name: template.name,
//                 operations: template.operations,
//               }));
//             setTemplateList(templates);
//             if (templates.length > 0) {
//               setSelectedTemplateName(templates[0].name);
//             }
//           }
//         } catch (error) {
//           console.error('テンプレートの取得中にエラーが発生しました:', error);
//           setSnackbar({
//             open: true,
//             message: 'テンプレートの取得に失敗しました。',
//             severity: 'error',
//           });
//         }
//       };
//       fetchTemplates();

//       // ユーザー設定とAPIキーを取得
//       const fetchUserSettings = async () => {
//         try {
//           // ユーザー設定を取得
//           const docRef = doc(db, 'userSettings', user.uid);
//           const docSnap = await getDoc(docRef);

//           if (docSnap.exists()) {
//             const userSettingsData = docSnap.data();
//             setSelectedPriceSettings(userSettingsData);
//             setUserSettings(userSettingsData); // ユーザー設定を保存

//             // 送料テンプレートを設定
//             if (userSettingsData.shippingRateTemplates) {
//               setShippingRateTemplates(userSettingsData.shippingRateTemplates);
//               setSelectedShippingTemplateId(
//                 userSettingsData.selectedShippingTemplateId ||
//                   userSettingsData.shippingRateTemplates[0].id
//               );
//             }

//             // その他の設定を取得
//             setExchangeRate(userSettingsData.exchangeRate || 0);
//             setTargetProfitMargin(userSettingsData.targetProfitMargin || 0);
//             setFees(userSettingsData.fees || {});
//             setBestOfferSettings({
//               bestOfferAutoAcceptPercentage:
//                 userSettingsData.bestOfferAutoAcceptPercentage || 0,
//               minimumBestOfferPercentage:
//                 userSettingsData.minimumBestOfferPercentage || 0,
//             });

//             // デザインテンプレート名の取得と初期設定
//             if (userSettingsData.designTemplates) {
//               const templateNames = Object.keys(userSettingsData.designTemplates);
//               setDesignTemplateNames(templateNames);
//               if (templateNames.length > 0) {
//                 setSelectedDesignTemplate(templateNames[0]);
//               } else {
//                 console.warn('デザインテンプレートが設定されていません。');
//                 // デフォルトのテンプレート名を設定
//                 setSelectedDesignTemplate('defaultTemplate');
//               }
//             } else {
//               console.warn('デザインテンプレートが設定されていません。');
//               // デフォルトのテンプレート名を設定
//               setSelectedDesignTemplate('simple');
//             }

//             // カスタムフィルターや削除したい文章などの取得
//             setCustomFilters(userSettingsData.customFilters || []);
//             setSentencesToDelete(userSettingsData.sentencesToDelete || []);
//             setWordsToReplace(userSettingsData.wordsToReplace || []);
//             setMandatoryKeywords(userSettingsData.mandatoryKeywords || []);

//             // タイトルカスタマイズテンプレートを取得して状態に保存
//             if (userSettingsData.customCategories) {
//               setCustomCategories(userSettingsData.customCategories);
//             } else {
//               // ユーザー設定に存在しない場合、Firestoreから別途取得
//               await loadTitleCustomizationTemplates();
//             }

//             // パターンの取得
//             if (userSettingsData.patterns) {
//               setPatterns(userSettingsData.patterns);
//             } else {
//               setPatterns([]);
//             }
//           } else {
//             console.warn('ユーザー設定が見つかりませんでした');
//             // ユーザー設定がない場合も、タイトルカスタマイズテンプレートを取得
//             await loadTitleCustomizationTemplates();
//           }

//           // APIキーを取得
//           const apiKeyDocRef = doc(db, 'userApiKeys', user.uid);
//           const apiKeyDocSnap = await getDoc(apiKeyDocRef);

//           if (apiKeyDocSnap.exists()) {
//             const encryptedKey = apiKeyDocSnap.data().apiKey;
//             const encryptionKey = generateEncryptionKey(user.uid);
//             const bytes = CryptoJS.AES.decrypt(encryptedKey, encryptionKey);
//             const decryptedKey = bytes.toString(CryptoJS.enc.Utf8);
//             if (decryptedKey) {
//               setUserApiKey(decryptedKey);
//             } else {
//               console.error('APIキーの復号に失敗しました。');
//             }
//           } else {
//             console.warn('ユーザーのAPIキーが見つかりませんでした');
//           }

//           // ユーザーのテンプレートをロード
//           loadTemplates();
//         } catch (error) {
//           console.error('ユーザー設定の取得中にエラーが発生しました:', error);
//           setSnackbar({
//             open: true,
//             message: 'ユーザー設定の取得に失敗しました。',
//             severity: 'error',
//           });
//         }
//       };
//       fetchUserSettings();

//       // タイトルカスタマイズテンプレートをロード
//       const loadTitleCustomizationTemplates = async () => {
//         if (!user) return;
//         try {
//           const docRef = doc(db, 'userTitleSettings', user.uid);
//           const docSnap = await getDoc(docRef);
//           if (docSnap.exists()) {
//             const data = docSnap.data();
//             setCustomCategories(data.customCategories || []);
//           } else {
//             console.log('タイトルカスタマイズテンプレートが見つかりませんでした。');
//           }
//         } catch (error) {
//           console.error('タイトルカスタマイズテンプレートのロード中にエラーが発生しました:', error);
//         }
//       };
//     }
//   }, [user, navigate]);

//   // カスタムフィルターやテキスト変換の状態管理
//   const [customFilters, setCustomFilters] = useState([]);
//   const [sentencesToDelete, setSentencesToDelete] = useState([]);
//   const [wordsToReplace, setWordsToReplace] = useState([]);

//   // フィルタリングオプションのステート
//   const [selectedFilters, setSelectedFilters] = useState([]);
//   const [customFilterInput, setCustomFilterInput] = useState('');

//   // テンプレートの保存
//   const saveTemplate = async () => {
//     if (!templateName.trim()) {
//       setSnackbar({
//         open: true,
//         message: 'テンプレート名を入力してください',
//         severity: 'warning',
//       });
//       return;
//     }

//     const templateData = {
//       name: templateName.trim(),
//       customFilters,
//       sentencesToDelete,
//       wordsToReplace,
//       mandatoryKeywords,
//       selectedFilters,
//     };

//     try {
//       const userTemplatesRef = collection(db, 'users', user.uid, 'templates');

//       if (saveOption === 'overwrite' && selectedTemplateId) {
//         const templateDocRef = doc(userTemplatesRef, selectedTemplateId);
//         await setDoc(templateDocRef, templateData);
//         setSnackbar({
//           open: true,
//           message: 'テンプレートが更新されました',
//           severity: 'success',
//         });
//       } else {
//         await addDoc(userTemplatesRef, templateData);
//         setSnackbar({
//           open: true,
//           message: '新しいテンプレートが保存されました',
//           severity: 'success',
//         });
//       }

//       setIsSaveTemplateDialogOpen(false);
//       setTemplateName('');
//       loadTemplates();
//     } catch (error) {
//       console.error('テンプレートの保存中にエラーが発生しました:', error);
//       setSnackbar({
//         open: true,
//         message: 'テンプレートの保存中にエラーが発生しました',
//         severity: 'error',
//       });
//     }
//   };

//   // テンプレートの読み込み
//   const loadTemplates = async () => {
//     try {
//       const userTemplatesRef = collection(db, 'users', user.uid, 'templates');
//       const templatesSnapshot = await getDocs(userTemplatesRef);
//       const templatesData = templatesSnapshot.docs.map((doc) => ({
//         id: doc.id,
//         ...doc.data(),
//       }));
//       setTemplates(templatesData);
//     } catch (error) {
//       console.error('テンプレートの読み込み中にエラーが発生しました:', error);
//     }
//   };

//   // テンプレートの適用
//   const handleLoadTemplate = async (templateId) => {
//     if (!templateId) {
//       setSelectedTemplateId('');
//       setTemplateName('');
//       setCurrentTemplateName('');
//       return;
//     }
//     setSelectedTemplateId(templateId);
//     try {
//       const templateDocRef = doc(db, 'users', user.uid, 'templates', templateId);
//       const templateDoc = await getDoc(templateDocRef);
//       if (templateDoc.exists()) {
//         const templateData = templateDoc.data();
//         setTemplateName(templateData.name || '');
//         setCurrentTemplateName(templateData.name || '');
//         setCustomFilters(templateData.customFilters || []);
//         setSentencesToDelete(templateData.sentencesToDelete || []);
//         setWordsToReplace(templateData.wordsToReplace || []);
//         setMandatoryKeywords(templateData.mandatoryKeywords || []);
//         setSelectedFilters(templateData.selectedFilters || []);
//         setSnackbar({
//           open: true,
//           message: 'テンプレートが適用されました',
//           severity: 'success',
//         });
//       } else {
//         setSnackbar({
//           open: true,
//           message: 'テンプレートが見つかりません',
//           severity: 'error',
//         });
//       }
//     } catch (error) {
//       console.error('テンプレートの読み込み中にエラーが発生しました:', error);
//       setSnackbar({
//         open: true,
//         message: 'テンプレートの読み込み中にエラーが発生しました',
//         severity: 'error',
//       });
//     }
//   };

//   // テンプレートの削除
//   const handleDeleteTemplate = async (templateId) => {
//     try {
//       const templateDocRef = doc(db, 'users', user.uid, 'templates', templateId);
//       await deleteDoc(templateDocRef);
//       setSnackbar({
//         open: true,
//         message: 'テンプレートが削除されました',
//         severity: 'success',
//       });
//       loadTemplates();
//     } catch (error) {
//       console.error('テンプレートの削除中にエラーが発生しました:', error);
//       setSnackbar({
//         open: true,
//         message: 'テンプレートの削除中にエラーが発生しました',
//         severity: 'error',
//       });
//     }
//   };

//   // 新しいテンプレートを作成
//   const handleCreateNewTemplate = () => {
//     setSelectedTemplateId('');
//     setTemplateName('');
//     setCurrentTemplateName('');
//     setCustomFilters([]);
//     setSelectedFilters([]);
//     setSentencesToDelete([]);
//     setWordsToReplace([]);
//     setMandatoryKeywords([]);
//     setSnackbar({
//       open: true,
//       message: '新しいテンプレートを作成します',
//       severity: 'info',
//     });
//   };

//   // 必須キーワードの追加・削除
//   const handleAddMandatoryKeyword = () => {
//     if (mandatoryKeywordInput.trim() !== '') {
//       setMandatoryKeywords([...mandatoryKeywords, mandatoryKeywordInput.trim()]);
//       setMandatoryKeywordInput('');
//     }
//   };

//   const handleDeleteMandatoryKeyword = (index) => {
//     const updatedKeywords = [...mandatoryKeywords];
//     updatedKeywords.splice(index, 1);
//     setMandatoryKeywords(updatedKeywords);
//   };

//   // 削除したい文章の追加・削除
//   const handleAddSentenceToDelete = () => {
//     if (sentenceToDeleteInput.trim() !== '') {
//       setSentencesToDelete([...sentencesToDelete, sentenceToDeleteInput.trim()]);
//       setSentenceToDeleteInput('');
//     }
//   };

//   const handleDeleteSentenceToDelete = (index) => {
//     const updatedSentences = [...sentencesToDelete];
//     updatedSentences.splice(index, 1);
//     setSentencesToDelete(updatedSentences);
//   };

//   // 置換単語の追加・削除
//   const handleAddWordToReplace = () => {
//     if (wordToReplaceFrom.trim() !== '' && wordToReplaceTo.trim() !== '') {
//       setWordsToReplace([
//         ...wordsToReplace,
//         { from: wordToReplaceFrom.trim(), to: wordToReplaceTo.trim() },
//       ]);
//       setWordToReplaceFrom('');
//       setWordToReplaceTo('');
//     }
//   };

//   const handleDeleteWordToReplace = (index) => {
//     const updatedWords = [...wordsToReplace];
//     updatedWords.splice(index, 1);
//     setWordsToReplace(updatedWords);
//   };

//   // タイトルカスタマイズの状態管理
//   const [deleteStrings, setDeleteStrings] = useState([]);
//   const [replacePairs, setReplacePairs] = useState([]);
//   const [prependText, setPrependText] = useState('');
//   const [appendText, setAppendText] = useState('');
//   const [limitTitleLength, setLimitTitleLength] = useState(false);

//   // 置換ペアの管理関数
//   const handleAddReplacePair = () => {
//     setReplacePairs([...replacePairs, { from: '', to: '' }]);
//   };

//   const handleReplacePairChange = (index, field, value) => {
//     const newPairs = [...replacePairs];
//     newPairs[index][field] = value;
//     setReplacePairs(newPairs);
//   };

//   const handleRemoveReplacePair = (index) => {
//     const newPairs = [...replacePairs];
//     newPairs.splice(index, 1);
//     setReplacePairs(newPairs);
//   };

//   // ファイル選択時の処理
//   const handleFileChange = (event) => {
//     const files = Array.from(event.target.files).slice(0, 5); // 最大5つまで選択
//     setCsvFiles(files);
//     // ファイルの内容を読み込む
//     Promise.all(files.map((file) => file.text())).then((contents) => {
//       setFileContents(contents);
//     });
//   };

//   // 機能選択ダイアログの開閉
//   const handleFunctionDialogOpen = () => {
//     setFunctionDialogOpen(true);
//   };

//   const handleFunctionDialogClose = () => {
//     setFunctionDialogOpen(false);
//   };

//   // 機能の選択・解除
//   const handleFunctionToggle = (func) => () => {
//     const currentIndex = selectedFunctions.findIndex((f) => f.name === func.name);
//     const newSelected = [...selectedFunctions];

//     if (currentIndex === -1) {
//       newSelected.push(func);
//     } else {
//       newSelected.splice(currentIndex, 1);
//     }

//     setSelectedFunctions(newSelected);
//   };

//   // 機能の順序変更（ドラッグ＆ドロップ）
//   const onDragEnd = (result) => {
//     if (!result.destination) return;

//     const newFunctions = Array.from(selectedFunctions);
//     const [removed] = newFunctions.splice(result.source.index, 1);
//     newFunctions.splice(result.destination.index, 0, removed);

//     setSelectedFunctions(newFunctions);
//   };

//   // 価格適用関数をメモ化
//   const applyPriceChanges = useCallback(
//     async (currentData) => {
//       try {
//         if (!selectedPriceSettings) {
//           throw new Error('価格設定が取得されていません。');
//         }

//         // 選択された送料テンプレートを設定に反映
//         const updatedPriceSettings = {
//           ...selectedPriceSettings,
//           selectedShippingTemplateId,
//         };

//         const updatedData = await Promise.all(
//           currentData.map(async (file) => {
//             // CSVデータをパース
//             const parsedResult = Papa.parse(file.content, { header: true });
//             const data = parsedResult.data;

//             // 価格計算を実行
//             const newPrices = calculatePrices(
//               data,
//               updatedPriceSettings,
//               selectedShippingTemplateId
//             );

//             // 計算結果を元にデータを更新
//             const updatedData = data.map((item, index) => {
//               const calculatedPrice = newPrices[index];
//               if (calculatedPrice) {
//                 return {
//                   ...item,
//                   StartPrice: calculatedPrice.discountedPrice,
//                   BestOfferAutoAcceptPrice: calculatedPrice.bestOfferAutoAcceptPrice,
//                   MinimumBestOfferPrice: calculatedPrice.minimumBestOfferPrice,
//                 };
//               } else {
//                 return item;
//               }
//             });

//             // CSVに変換
//             const csvContent = Papa.unparse(updatedData);

//             return {
//               ...file,
//               content: csvContent,
//             };
//           })
//         );

//         return updatedData;
//       } catch (error) {
//         console.error('価格適用中にエラーが発生しました:', error);
//         setSnackbar({
//           open: true,
//           message: '価格適用中にエラーが発生しました。',
//           severity: 'error',
//         });
//         throw error;
//       }
//     },
//     [selectedPriceSettings, selectedShippingTemplateId, setSnackbar]
//   );

//   // applyAITitlesをラップしてAPIキーを渡す（メモ化）
//   const applyAITitlesWrapper = useCallback(
//     async (currentData) => {
//       try {
//         if (!userApiKey) {
//           setSnackbar({
//             open: true,
//             message:
//               'OpenAI APIキーが設定されていません。ユーザー設定でAPIキーを入力してください。',
//             severity: 'error',
//           });
//           throw new Error('OpenAI APIキーが設定されていません。');
//         }

//         // 進捗状況の初期化
//         setProgress({ totalItems: 0, completedItems: 0 });
//         setErrorItems([]);

//         // カスタマイズ設定を取得
//         const customizationOptions = {
//           deleteStrings,
//           replacePairs,
//           prependText,
//           appendText,
//           limitTitleLength,
//         };

//         const { updatedData, errorItems } = await applyAITitles(
//           currentData,
//           userApiKey,
//           (progressData) => {
//             setProgress(progressData);
//           },
//           customizationOptions // カスタマイズ設定を渡す
//         );

//         // エラー情報を保存
//         setErrorItems(errorItems);

//         return updatedData;
//       } catch (error) {
//         console.error('AIタイトル適用中にエラーが発生しました:', error);
//         setSnackbar({
//           open: true,
//           message: 'AIタイトル適用中にエラーが発生しました。',
//           severity: 'error',
//         });
//         throw error;
//       }
//     },
//     [
//       userApiKey,
//       setSnackbar,
//       setProgress,
//       setErrorItems,
//       deleteStrings,
//       replacePairs,
//       prependText,
//       appendText,
//       limitTitleLength,
//     ]
//   );

//   // applyAIProductDescriptionsをラップしてAPIキーとユーザー設定を渡す（メモ化）
//   const applyAIProductDescriptionsWrapper = useCallback(
//     async (currentData) => {
//       try {
//         if (!userApiKey) {
//           setSnackbar({
//             open: true,
//             message:
//               'OpenAI APIキーが設定されていません。ユーザー設定でAPIキーを入力してください。',
//             severity: 'error',
//           });
//           throw new Error('OpenAI APIキーが設定されていません。');
//         }

//         // 進捗状況の初期化
//         setProgress({ totalItems: 0, completedItems: 0 });
//         setErrorItems([]);

//         const { updatedData, errorItems } = await applyAIProductDescriptions(
//           currentData,
//           userApiKey,
//           (progressData) => {
//             setProgress(progressData);
//           },
//           selectedDesignTemplate, // デザインテンプレートを渡す
//           {
//             customFilters,
//             selectedFilters,
//             sentencesToDelete,
//             wordsToReplace,
//             mandatoryKeywords,
//           } // ユーザー設定を渡す
//         );

//         // エラー情報を保存
//         setErrorItems(errorItems);

//         return updatedData;
//       } catch (error) {
//         console.error('AI商品説明適用中にエラーが発生しました:', error);
//         setSnackbar({
//           open: true,
//           message: 'AI商品説明適用中にエラーが発生しました。',
//           severity: 'error',
//         });
//         throw error;
//       }
//     },
//     [
//       userApiKey,
//       setSnackbar,
//       setProgress,
//       setErrorItems,
//       selectedDesignTemplate,
//       customFilters,
//       selectedFilters,
//       sentencesToDelete,
//       wordsToReplace,
//       mandatoryKeywords,
//     ]
//   );

//   // applyItemSpecificsWrapperをメモ化
//   const applyItemSpecificsWrapper = useCallback(
//     async (currentData, options) => {
//       const {
//         selectedItemSpecificsCategory,
//         selectedItemSpecificsColumns,
//         itemSpecificsMatchingOptions,
//       } = options;
//       try {
//         if (!user) {
//           setSnackbar({
//             open: true,
//             message: 'ユーザー情報が取得できません。',
//             severity: 'error',
//           });
//           throw new Error('ユーザー情報が取得できません。');
//         }

//         if (!selectedItemSpecificsCategory) {
//           setSnackbar({
//             open: true,
//             message: '適用するカテゴリーを選択してください。',
//             severity: 'warning',
//           });
//           throw new Error('適用するカテゴリーを選択してください。');
//         }

//         // 進捗状況の初期化
//         setProgress({ totalItems: 0, completedItems: 0 });

//         console.log(
//           'applyItemSpecificsWrapper内のselectedItemSpecificsCategory:',
//           selectedItemSpecificsCategory
//         );

//         const updatedData = await applyItemSpecificsProcessor(
//           currentData,
//           user.uid,
//           {
//             selectedCategory: selectedItemSpecificsCategory,
//             selectedColumns: selectedItemSpecificsColumns,
//             matchingOptions: itemSpecificsMatchingOptions,
//           },
//           (progressData) => {
//             setProgress(progressData);
//           }
//         );

//         return updatedData;
//       } catch (error) {
//         console.error('Item Specifics適用中にエラーが発生しました:', error);
//         setSnackbar({
//           open: true,
//           message: error.message || 'Item Specifics適用中にエラーが発生しました。',
//           severity: 'error',
//         });
//         throw error;
//       }
//     },
//     [user, setSnackbar, setProgress]
//   );

//   // availableFunctions の 'applyPriceChanges' と 'applyAITitles' と 'applyAIProductDescriptions' の func を更新
//   useEffect(() => {
//     setAvailableFunctions((prevFunctions) =>
//       prevFunctions.map((func) => {
//         if (func.name === 'applyPriceChanges') {
//           return { ...func, func: applyPriceChanges };
//         }
//         if (func.name === 'applyAITitles') {
//           return { ...func, func: applyAITitlesWrapper };
//         }
//         if (func.name === 'applyAIProductDescriptions') {
//           return { ...func, func: applyAIProductDescriptionsWrapper };
//         }
//         return func;
//       })
//     );
//   }, [applyPriceChanges, applyAITitlesWrapper, applyAIProductDescriptionsWrapper]);

//   // applyItemSpecificsの関数も設定
//   useEffect(() => {
//     setAvailableFunctions((prevFunctions) =>
//       prevFunctions.map((func) =>
//         func.name === 'applyItemSpecifics'
//           ? { ...func, func: applyItemSpecificsWrapper }
//           : func
//       )
//     );
//   }, [applyItemSpecificsWrapper]);

//   // パターンを適用する関数
//   const applyPatternSettings = (pattern) => {
//     if (!pattern || !pattern.settings) {
//       setSnackbar({
//         open: true,
//         message: '選択されたパターンが正しくありません。',
//         severity: 'error',
//       });
//       return;
//     }

//     const settings = pattern.settings;

//     // applyTemplateの設定を適用
//     if (settings.applyTemplate && settings.applyTemplate.selectedTemplateName) {
//       setSelectedTemplateName(settings.applyTemplate.selectedTemplateName);
//     }

//     // applyPriceChangesの設定を適用
//     if (settings.applyPriceChanges && settings.applyPriceChanges.selectedShippingTemplateId) {
//       setSelectedShippingTemplateId(settings.applyPriceChanges.selectedShippingTemplateId);
//       // 他の価格設定も適用
//     }

//     // applyAITitlesの設定を適用
//     if (settings.applyAITitles) {
//       setDeleteStrings(settings.applyAITitles.deleteStrings || []);
//       setReplacePairs(settings.applyAITitles.replacePairs || []);
//       setPrependText(settings.applyAITitles.prependText || '');
//       setAppendText(settings.applyAITitles.appendText || '');
//       setLimitTitleLength(settings.applyAITitles.limitTitleLength || false);
//     }

//     // applyAIProductDescriptionsの設定を適用
//     if (settings.applyAIProductDescriptions && settings.applyAIProductDescriptions.selectedDesignTemplate) {
//       setSelectedDesignTemplate(settings.applyAIProductDescriptions.selectedDesignTemplate);
//       // 他の説明文設定も適用
//       setCustomFilters(settings.applyAIProductDescriptions.customFilters || []);
//       setSentencesToDelete(settings.applyAIProductDescriptions.sentencesToDelete || []);
//       setWordsToReplace(settings.applyAIProductDescriptions.wordsToReplace || []);
//       setMandatoryKeywords(settings.applyAIProductDescriptions.mandatoryKeywords || []);
//     }

//     // applyItemSpecificsの設定を適用
//     if (settings.applyItemSpecifics && settings.applyItemSpecifics.selectedItemSpecificsCategory) {
//       setSelectedItemSpecificsCategory(settings.applyItemSpecifics.selectedItemSpecificsCategory);
//       setSelectedItemSpecificsColumns(settings.applyItemSpecifics.selectedItemSpecificsColumns || []);
//       setItemSpecificsMatchingOptions(settings.applyItemSpecifics.itemSpecificsMatchingOptions || itemSpecificsMatchingOptions);
//     }

//     // 各機能が選択されているか確認し、選択状態にする
//     const newSelectedFunctions = availableFunctions.filter((func) =>
//       Object.keys(settings).includes(func.name)
//     );
//     setSelectedFunctions(newSelectedFunctions);

//     setSnackbar({
//       open: true,
//       message: `パターン "${pattern.name}" が適用されました。`,
//       severity: 'success',
//     });
//   };

//   // パターンの保存
//   const saveCurrentSettingsAsPattern = async () => {
//     if (!newPatternName.trim()) {
//       setSnackbar({
//         open: true,
//         message: 'パターン名を入力してください',
//         severity: 'warning',
//       });
//       return;
//     }

//     const newPattern = {
//       name: newPatternName.trim(),
//       settings: {},
//     };

//     // 選択された機能の設定を保存
//     selectedFunctions.forEach((func) => {
//       if (func.name === 'applyTemplate') {
//         newPattern.settings.applyTemplate = { selectedTemplateName };
//       }
//       if (func.name === 'applyPriceChanges') {
//         newPattern.settings.applyPriceChanges = { selectedShippingTemplateId };
//       }
//       if (func.name === 'applyAITitles') {
//         newPattern.settings.applyAITitles = {
//           deleteStrings,
//           replacePairs,
//           prependText,
//           appendText,
//           limitTitleLength,
//         };
//       }
//       if (func.name === 'applyAIProductDescriptions') {
//         newPattern.settings.applyAIProductDescriptions = {
//           selectedDesignTemplate,
//           customFilters,
//           sentencesToDelete,
//           wordsToReplace,
//           mandatoryKeywords,
//         };
//       }
//       if (func.name === 'applyItemSpecifics') {
//         newPattern.settings.applyItemSpecifics = {
//           selectedItemSpecificsCategory,
//           selectedItemSpecificsColumns,
//           itemSpecificsMatchingOptions,
//         };
//       }
//     });

//     try {
//       // パターンをFirebaseに保存
//       const userSettingsRef = doc(db, 'userSettings', user.uid);
//       const docSnap = await getDoc(userSettingsRef);
//       let existingPatterns = [];
//       if (docSnap.exists()) {
//         existingPatterns = docSnap.data().patterns || [];
//       }

//       // 同名のパターンが存在するか確認
//       const patternExists = existingPatterns.some(
//         (pattern) => pattern.name === newPattern.name
//       );

//       if (patternExists) {
//         setSnackbar({
//           open: true,
//           message: '同名のパターンが既に存在します。',
//           severity: 'error',
//         });
//         return;
//       }

//       existingPatterns.push(newPattern);
//       await setDoc(userSettingsRef, { patterns: existingPatterns }, { merge: true });
//       setPatterns(existingPatterns);
//       setIsSavePatternDialogOpen(false);
//       setNewPatternName('');
//       setSnackbar({
//         open: true,
//         message: 'パターンが保存されました。',
//         severity: 'success',
//       });
//     } catch (error) {
//       console.error('パターンの保存中にエラーが発生しました:', error);
//       setSnackbar({
//         open: true,
//         message: 'パターンの保存中にエラーが発生しました。',
//         severity: 'error',
//       });
//     }
//   };

//   // パターンの削除
//   const handleDeletePattern = async (patternName) => {
//     try {
//       const userSettingsRef = doc(db, 'userSettings', user.uid);
//       const docSnap = await getDoc(userSettingsRef);
//       if (docSnap.exists()) {
//         let existingPatterns = docSnap.data().patterns || [];
//         existingPatterns = existingPatterns.filter((p) => p.name !== patternName);
//         await setDoc(userSettingsRef, { patterns: existingPatterns }, { merge: true });
//         setPatterns(existingPatterns);
//         setSnackbar({
//           open: true,
//           message: 'パターンが削除されました。',
//           severity: 'success',
//         });
//       }
//     } catch (error) {
//       console.error('パターンの削除中にエラーが発生しました:', error);
//       setSnackbar({
//         open: true,
//         message: 'パターンの削除中にエラーが発生しました。',
//         severity: 'error',
//       });
//     }
//   };

//   // パターン保存用の状態と関数
//   const [isSavePatternDialogOpen, setIsSavePatternDialogOpen] = useState(false);
//   const [newPatternName, setNewPatternName] = useState('');
//   const [isManagePatternsDialogOpen, setIsManagePatternsDialogOpen] = useState(false);

//   // アップロードおよび実行処理
//   const handleUploadAndExecute = async () => {
//     if (!user) {
//       setSnackbar({
//         open: true,
//         message: 'ユーザーが認証されていません。',
//         severity: 'error',
//       });
//       return;
//     }

//     if (csvFiles.length === 0) {
//       setSnackbar({
//         open: true,
//         message: 'アップロードするファイルがありません。',
//         severity: 'warning',
//       });
//       return;
//     }

//     if (selectedFunctions.length === 0) {
//       setSnackbar({
//         open: true,
//         message: '実行する機能を選択してください。',
//         severity: 'warning',
//       });
//       return;
//     }

//     setIsScheduling(true);

//     try {
//       // ファイル名と内容を組み合わせたデータ
//       const filesData = csvFiles.map((file, index) => ({
//         name: file.name,
//         content: fileContents[index],
//       }));

//       const executeFunctions = async () => {
//         let currentData = filesData; // 初期データ

//         for (const func of selectedFunctions) {
//           try {
//             if (func.func.constructor.name === 'AsyncFunction') {
//               if (func.name === 'applyTemplate') {
//                 currentData = await func.func(currentData, user, selectedTemplateName); // user とテンプレート名を渡す
//               } else if (func.name === 'applyItemSpecifics') {
//                 // ここで最新の状態を渡す
//                 currentData = await func.func(currentData, {
//                   selectedItemSpecificsCategory,
//                   selectedItemSpecificsColumns,
//                   itemSpecificsMatchingOptions,
//                 });
//               } else {
//                 currentData = await func.func(currentData);
//               }
//             } else {
//               if (func.name === 'applyTemplate') {
//                 currentData = func.func(currentData, user, selectedTemplateName); // user とテンプレート名を渡す
//               } else if (func.name === 'applyItemSpecifics') {
//                 // ここで最新の状態を渡す
//                 currentData = func.func(currentData, {
//                   selectedItemSpecificsCategory,
//                   selectedItemSpecificsColumns,
//                   itemSpecificsMatchingOptions,
//                 });
//               } else {
//                 currentData = func.func(currentData);
//               }
//             }
//           } catch (error) {
//             console.error(`機能 "${func.displayName}" の実行中にエラーが発生しました:`, error);
//             setSnackbar({
//               open: true,
//               message: `機能 "${func.displayName}" の実行中にエラーが発生しました。`,
//               severity: 'error',
//             });
//             setIsScheduling(false);
//             return;
//           }
//         }

//         // 処理結果を状態に保存
//         setProcessedData(currentData);

//         setSnackbar({
//           open: true,
//           message: '選択された機能を実行しました。',
//           severity: 'success',
//         });
//         setIsScheduling(false);
//       };

//       if (isImmediate) {
//         // 即時実行の場合
//         await executeFunctions();
//       } else {
//         // スケジュール実行の場合
//         const now = new Date();
//         const scheduledDateTime = new Date(scheduledTime);
//         const delay = scheduledDateTime - now;

//         if (delay > 0) {
//           setSnackbar({
//             open: true,
//             message: 'タスクがスケジュールされました。',
//             severity: 'success',
//           });
//           setTimeout(async () => {
//             await executeFunctions();
//           }, delay);
//         } else {
//           setSnackbar({
//             open: true,
//             message: '指定された時間は過去です。正しい時間を選択してください。',
//             severity: 'error',
//           });
//           setIsScheduling(false);
//         }
//       }
//     } catch (error) {
//       console.error('処理中にエラーが発生しました:', error);
//       setSnackbar({
//         open: true,
//         message: '処理中にエラーが発生しました。',
//         severity: 'error',
//       });
//       setIsScheduling(false);
//     }
//   };

//   // タブの変更
//   const handleTabChange = (event, newValue) => {
//     setActiveTab(newValue);
//   };

//   // CSVデータをパースして状態に保存
//   const [parsedData, setParsedData] = useState([]);

//   useEffect(() => {
//     const parseCSVFiles = () => {
//       const data = fileContents.map((content) => {
//         const parsedResult = Papa.parse(content, { header: true });
//         return {
//           data: parsedResult.data,
//           columns: parsedResult.meta.fields,
//         };
//       });
//       setParsedData(data);
//     };

//     if (fileContents.length > 0) {
//       parseCSVFiles();
//     } else {
//       setParsedData([]);
//     }
//   }, [fileContents]);

//   // 処理後のデータをプレビュー
//   const [processedParsedData, setProcessedParsedData] = useState([]);

//   useEffect(() => {
//     if (processedData.length > 0) {
//       const data = processedData.map((file) => {
//         const parsedResult = Papa.parse(file.content, { header: true });
//         return {
//           name: file.name,
//           data: parsedResult.data,
//           columns: parsedResult.meta.fields,
//         };
//       });
//       setProcessedParsedData(data);
//     } else {
//       setProcessedParsedData([]);
//     }
//   }, [processedData]);

//   // フィルターの変更ハンドラー
//   const handleFilterChange = (label) => {
//     setSelectedFilters((prev) =>
//       prev.includes(label) ? prev.filter((item) => item !== label) : [...prev, label]
//     );
//   };

//   // カスタムフィルターの追加・削除
//   const handleAddCustomFilter = () => {
//     if (customFilterInput.trim() !== '') {
//       setCustomFilters([...customFilters, customFilterInput.trim()]);
//       setCustomFilterInput('');
//     }
//   };

//   const handleDeleteCustomFilter = (index) => {
//     const updatedFilters = [...customFilters];
//     updatedFilters.splice(index, 1);
//     setCustomFilters(updatedFilters);
//   };

//   // 画像サムネイルの表示用関数
//   const getFirstImageUrl = (picUrlString) => {
//     if (!picUrlString) return '';
//     const urls = picUrlString.split('|');
//     return urls[0] || '';
//   };

//   // 選択された商品の状態管理
//   const [selectedProduct, setSelectedProduct] = useState(null);
//   const [isModalOpen, setIsModalOpen] = useState(false);

//   // 画像モーダルの状態管理
//   const [isImageModalOpen, setIsImageModalOpen] = useState(false);
//   const [currentImageUrl, setCurrentImageUrl] = useState('');

//   const handleOpenImageModal = (url) => {
//     setCurrentImageUrl(url);
//     setIsImageModalOpen(true);
//   };

//   const handleCloseImageModal = () => {
//     setIsImageModalOpen(false);
//     setCurrentImageUrl('');
//   };

//   // サイドパネルの状態管理
//   const [isDrawerOpen, setIsDrawerOpen] = useState(false);

//   // 画像ギャラリーコンポーネント
//   const ImageGallery = ({ picUrlString }) => {
//     if (!picUrlString) return null;
//     const urls = picUrlString.split('|');

//     return (
//       <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
//         {urls.map((url, index) => (
//           <Box key={index} sx={{ position: 'relative' }}>
//             <img
//               src={url}
//               alt={`商品画像 ${index + 1}`}
//               style={{
//                 width: '100px',
//                 height: '100px',
//                 objectFit: 'cover',
//                 cursor: 'pointer',
//               }}
//               onClick={() => {
//                 handleOpenImageModal(url);
//               }}
//             />
//           </Box>
//         ))}
//       </Box>
//     );
//   };

//   return (
//     <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
//       <Typography variant="h6" gutterBottom>
//         CSVファイルのアップロードとタスクのスケジュール
//       </Typography>

//       {/* ファイル選択 */}
//       <Button variant="contained" component="label">
//         ファイルを選択（最大5つまで）
//         <input
//           type="file"
//           accept=".csv"
//           multiple
//           hidden
//           onChange={handleFileChange}
//         />
//       </Button>

//       {csvFiles.length > 0 && (
//         <Box sx={{ mt: 2 }}>
//           <Typography variant="subtitle1">選択されたファイル:</Typography>
//           <List>
//             {csvFiles.map((file, index) => (
//               <ListItem key={index}>
//                 <Typography>{file.name}</Typography>
//               </ListItem>
//             ))}
//           </List>
//         </Box>
//       )}

//       {/* 機能選択 */}
//       <Button
//         variant="contained"
//         color="primary"
//         sx={{ mt: 2 }}
//         onClick={handleFunctionDialogOpen}
//         startIcon={<MenuIcon />}
//       >
//         実行する機能を選択
//       </Button>

//       {/* パターン選択 */}
//       <Box sx={{ mt: 2 }}>
//         <FormControl fullWidth>
//           <InputLabel>パターンを選択</InputLabel>
//           <Select
//             value={selectedPatternName}
//             onChange={(e) => {
//               const patternName = e.target.value;
//               setSelectedPatternName(patternName);
//               const pattern = patterns.find((p) => p.name === patternName);
//               applyPatternSettings(pattern);
//             }}
//           >
//             <MenuItem value="">
//               <em>パターンを選択</em>
//             </MenuItem>
//             {patterns.map((pattern) => (
//               <MenuItem key={pattern.name} value={pattern.name}>
//                 {pattern.name}
//               </MenuItem>
//             ))}
//           </Select>
//         </FormControl>
//         <Button
//           variant="outlined"
//           color="primary"
//           onClick={() => setIsSavePatternDialogOpen(true)}
//           sx={{ mt: 1 }}
//         >
//           現在の設定をパターンとして保存
//         </Button>
//         <Button
//           variant="outlined"
//           color="primary"
//           onClick={() => setIsManagePatternsDialogOpen(true)}
//           sx={{ mt: 1, ml: 1 }}
//         >
//           パターンを管理
//         </Button>
//       </Box>

//       {/* --- ここから設定表示を指定された順序で配置します --- */}

//       {/* 1. テンプレート適用 */}
//       {selectedFunctions.some((func) => func.name === 'applyTemplate') && (
//         <Box sx={{ mt: 2 }}>
//           <Typography variant="h6">1. テンプレート適用</Typography>
//           <FormControl fullWidth sx={{ mt: 1 }}>
//             <InputLabel>テンプレートを選択</InputLabel>
//             <Select
//               value={selectedTemplateName}
//               onChange={(e) => setSelectedTemplateName(e.target.value)}
//             >
//               {templateList.map((template) => (
//                 <MenuItem key={template.name} value={template.name}>
//                   {template.name}
//                 </MenuItem>
//               ))}
//             </Select>
//           </FormControl>
//         </Box>
//       )}

//       {/* 2. 価格機能 */}
//       {selectedFunctions.some((func) => func.name === 'applyPriceChanges') && (
//         <Box sx={{ mt: 2 }}>
//           <Typography variant="h6">2. 価格機能</Typography>
//           {shippingRateTemplates.length > 0 && (
//             <FormControl fullWidth sx={{ mt: 1 }}>
//               <InputLabel>送料設定を選択</InputLabel>
//               <Select
//                 value={selectedShippingTemplateId}
//                 onChange={(e) => setSelectedShippingTemplateId(e.target.value)}
//               >
//                 {shippingRateTemplates.map((template) => (
//                   <MenuItem key={template.id} value={template.id}>
//                     {template.name}
//                   </MenuItem>
//                 ))}
//               </Select>
//             </FormControl>
//           )}
//           <Paper elevation={2} sx={{ p: 2, mt: 2 }}>
//             <Typography variant="subtitle1">現在の価格設定</Typography>
//             <Typography>為替レート: 1 USD = {exchangeRate} JPY</Typography>
//             <Typography>目標利益率: {targetProfitMargin}%</Typography>
//             <Typography>eBay最終価値手数料: {fees.ebayFinalValue || 0}%</Typography>
//             <Typography>Payoneer手数料: {fees.payoneer || 0}%</Typography>
//             <Typography>広告出品手数料: {fees.promotedListing || 0}%</Typography>
//             <Typography>eBay取引手数料: {fees.ebayTransactionFee || 0} USD</Typography>
//             <Typography>
//               ベストオファー自動承認価格:{' '}
//               {bestOfferSettings.bestOfferAutoAcceptPercentage || 0}%
//             </Typography>
//             <Typography>
//               最小ベストオファー価格:{' '}
//               {bestOfferSettings.minimumBestOfferPercentage || 0}%
//             </Typography>
//           </Paper>
//         </Box>
//       )}

//       {/* 3. AIタイトル */}
//       {selectedFunctions.some((func) => func.name === 'applyAITitles') && (
//         <Box sx={{ mt: 2 }}>
//           <Typography variant="h6">3. AIタイトル</Typography>

//           {/* タイトルカスタマイズテンプレートの選択 */}
//           <FormControl fullWidth sx={{ mt: 1 }}>
//             <InputLabel>タイトルカスタマイズテンプレートを選択</InputLabel>
//             <Select
//               value={selectedCategory}
//               onChange={(e) => {
//                 const selectedName = e.target.value;
//                 setSelectedCategory(selectedName);

//                 if (selectedName) {
//                   const category = customCategories.find((cat) => cat.name === selectedName);
//                   if (category) {
//                     setDeleteStrings(category.deleteStrings || []);
//                     setReplacePairs(category.replacePairs || []);
//                     setPrependText(category.prependText || '');
//                     setAppendText(category.appendText || '');
//                     setLimitTitleLength(category.limitTitleLength || false);
//                   }
//                 } else {
//                   // 新しいテンプレートを選択した場合、初期状態にリセット
//                   setDeleteStrings([]);
//                   setReplacePairs([]);
//                   setPrependText('');
//                   setAppendText('');
//                   setLimitTitleLength(false);
//                 }
//               }}
//             >
//               {customCategories.map((cat) => (
//                 <MenuItem key={cat.name} value={cat.name}>
//                   {cat.name}
//                 </MenuItem>
//               ))}
//             </Select>
//           </FormControl>

//           {/* 以下にカスタマイズ設定のUIを追加 */}
//           <Box sx={{ mt: 2 }}>
//             <Typography variant="subtitle1">タイトルカスタマイズ設定</Typography>

//             {/* 削除したい単語 */}
//             <TextField
//               label="削除したい単語 (カンマ区切り)"
//               value={deleteStrings.join(', ')}
//               onChange={(e) =>
//                 setDeleteStrings(e.target.value.split(',').map((s) => s.trim()))
//               }
//               fullWidth
//               sx={{ mt: 1 }}
//             />

//             {/* 置換ペア */}
//             <Box sx={{ mt: 2 }}>
//               <Typography variant="subtitle1">置換ペア</Typography>
//               {replacePairs.map((pair, index) => (
//                 <Box key={index} sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
//                   <TextField
//                     label="置換前"
//                     value={pair.from}
//                     onChange={(e) => handleReplacePairChange(index, 'from', e.target.value)}
//                   />
//                   <TextField
//                     label="置換後"
//                     value={pair.to}
//                     onChange={(e) => handleReplacePairChange(index, 'to', e.target.value)}
//                     sx={{ ml: 1 }}
//                   />
//                   <IconButton onClick={() => handleRemoveReplacePair(index)}>
//                     <DeleteIcon />
//                   </IconButton>
//                 </Box>
//               ))}
//               <Button variant="outlined" onClick={handleAddReplacePair} sx={{ mt: 1 }}>
//                 置換ペアを追加
//               </Button>
//             </Box>

//             {/* 先頭に追加する文字列 */}
//             <TextField
//               label="先頭に追加する文字列"
//               value={prependText}
//               onChange={(e) => setPrependText(e.target.value)}
//               fullWidth
//               sx={{ mt: 1 }}
//             />

//             {/* 末尾に追加する文字列 */}
//             <TextField
//               label="末尾に追加する文字列"
//               value={appendText}
//               onChange={(e) => setAppendText(e.target.value)}
//               fullWidth
//               sx={{ mt: 1 }}
//             />

//             {/* 80文字に制限する */}
//             <FormControlLabel
//               control={
//                 <Switch
//                   checked={limitTitleLength}
//                   onChange={(e) => setLimitTitleLength(e.target.checked)}
//                 />
//               }
//               label="80文字に制限する"
//               sx={{ mt: 1 }}
//             />
//           </Box>
//         </Box>
//       )}

//       {/* 4. AI商品説明 */}
//       {selectedFunctions.some((func) => func.name === 'applyAIProductDescriptions') && (
//         <Box sx={{ mt: 2 }}>
//           <Typography variant="h6">4. AI商品説明</Typography>
//           <Grid container spacing={2} sx={{ mt: 1 }}>
//             {/* デザインテンプレート選択 */}
//             {designTemplateNames.length > 0 && (
//               <Grid item xs={12} sm={6} md={4}>
//                 <FormControl fullWidth>
//                   <InputLabel>デザインテンプレートを選択</InputLabel>
//                   <Select
//                     value={selectedDesignTemplate}
//                     onChange={(e) => setSelectedDesignTemplate(e.target.value)}
//                   >
//                     {designTemplateNames.map((templateName) => (
//                       <MenuItem key={templateName} value={templateName}>
//                         {templateName}
//                       </MenuItem>
//                     ))}
//                   </Select>
//                 </FormControl>
//                 {/* 現在選択されているデザインテンプレートの表示 */}
//                 {selectedDesignTemplate && (
//                   <Typography variant="body2" sx={{ mt: 1 }}>
//                     選択中のテンプレート: {selectedDesignTemplate}
//                   </Typography>
//                 )}
//               </Grid>
//             )}

//             {/* 画像解析の説明を使用 */}
//             <Grid item xs={12} sm={6} md={4}>
//               <FormControlLabel
//                 control={
//                   <Switch
//                     checked={useImageDescription}
//                     onChange={(e) => setUseImageDescription(e.target.checked)}
//                     name="useImageDescription"
//                     color="primary"
//                   />
//                 }
//                 label="画像解析の説明を使用"
//               />
//             </Grid>

//             {/* 必須キーワード */}
//             <Grid item xs={12} sm={6} md={4}>
//               <Typography variant="subtitle1">必ず含めたいキーワード</Typography>
//               <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
//                 <TextField
//                   label="キーワードを入力"
//                   value={mandatoryKeywordInput}
//                   onChange={(e) => setMandatoryKeywordInput(e.target.value)}
//                   fullWidth
//                 />
//                 <Button
//                   variant="contained"
//                   color="primary"
//                   onClick={handleAddMandatoryKeyword}
//                   sx={{ ml: 1, height: '56px' }}
//                 >
//                   追加
//                 </Button>
//               </Box>
//               <List>
//                 {mandatoryKeywords.map((keyword, index) => (
//                   <ListItem
//                     key={index}
//                     secondaryAction={
//                       <IconButton
//                         edge="end"
//                         aria-label="delete"
//                         onClick={() => handleDeleteMandatoryKeyword(index)}
//                       >
//                         <DeleteIcon />
//                       </IconButton>
//                     }
//                   >
//                     <ListItemText primary={keyword} />
//                   </ListItem>
//                 ))}
//               </List>
//             </Grid>

//             {/* カスタムフィルター、削除したい文章、置換単語を横並びに配置 */}
//             <Grid item xs={12}>
//               <Grid container spacing={2}>
//                 {/* カスタムフィルター */}
//                 <Grid item xs={12} sm={4}>
//                   <OptionsPanel
//                     selectedFilters={selectedFilters}
//                     handleFilterChange={handleFilterChange}
//                     customFilterInput={customFilterInput}
//                     setCustomFilterInput={setCustomFilterInput}
//                     customFilters={customFilters}
//                     handleAddCustomFilter={handleAddCustomFilter}
//                     handleDeleteCustomFilter={handleDeleteCustomFilter}
//                     sentencesToDelete={sentencesToDelete}
//                     setSentencesToDelete={setSentencesToDelete}
//                     wordsToReplace={wordsToReplace}
//                     setWordsToReplace={setWordsToReplace}
//                   />
//                 </Grid>

//                 {/* 削除したい文章 */}
//                 <Grid item xs={12} sm={4}>
//                   <Typography variant="subtitle1">削除したい文章</Typography>
//                   <TextField
//                     label="文章を入力"
//                     value={sentenceToDeleteInput}
//                     onChange={(e) => setSentenceToDeleteInput(e.target.value)}
//                     fullWidth
//                   />
//                   <Button
//                     variant="contained"
//                     color="primary"
//                     onClick={handleAddSentenceToDelete}
//                     sx={{ mt: 1 }}
//                   >
//                     追加
//                   </Button>
//                   <List>
//                     {sentencesToDelete.map((sentence, index) => (
//                       <ListItem
//                         key={index}
//                         secondaryAction={
//                           <IconButton
//                             edge="end"
//                             aria-label="delete"
//                             onClick={() => handleDeleteSentenceToDelete(index)}
//                           >
//                             <DeleteIcon />
//                           </IconButton>
//                         }
//                       >
//                         <ListItemText primary={sentence} />
//                       </ListItem>
//                     ))}
//                   </List>
//                 </Grid>

//                 {/* 置換単語 */}
//                 <Grid item xs={12} sm={4}>
//                   <Typography variant="subtitle1">置換単語</Typography>
//                   <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
//                     <TextField
//                       label="置換前"
//                       value={wordToReplaceFrom}
//                       onChange={(e) => setWordToReplaceFrom(e.target.value)}
//                       fullWidth
//                     />
//                     <Typography sx={{ mx: 1 }}>➔</Typography>
//                     <TextField
//                       label="置換後"
//                       value={wordToReplaceTo}
//                       onChange={(e) => setWordToReplaceTo(e.target.value)}
//                       fullWidth
//                     />
//                   </Box>
//                   <Button
//                     variant="contained"
//                     color="primary"
//                     onClick={handleAddWordToReplace}
//                     sx={{ mt: 1 }}
//                   >
//                     追加
//                   </Button>
//                   <List>
//                     {wordsToReplace.map((wordPair, index) => (
//                       <ListItem
//                         key={index}
//                         secondaryAction={
//                           <IconButton
//                             edge="end"
//                             aria-label="delete"
//                             onClick={() => handleDeleteWordToReplace(index)}
//                           >
//                             <DeleteIcon />
//                           </IconButton>
//                         }
//                       >
//                         <ListItemText primary={`${wordPair.from} ➔ ${wordPair.to}`} />
//                       </ListItem>
//                     ))}
//                   </List>
//                 </Grid>
//               </Grid>
//             </Grid>
//           </Grid>
//         </Box>
//       )}

//       {/* Item Specifics適用 */}
//       {selectedFunctions.some((func) => func.name === 'applyItemSpecifics') && (
//         <Box sx={{ mt: 2 }}>
//           <Typography variant="h6">5. Item Specifics適用</Typography>
//           {/* カテゴリー選択 */}
//           <FormControl fullWidth sx={{ mt: 1 }}>
//             <InputLabel>適用するカテゴリー</InputLabel>
//             <Select
//               value={selectedItemSpecificsCategory}
//               onChange={(e) => {
//                 console.log('選択されたカテゴリー:', e.target.value);
//                 setSelectedItemSpecificsCategory(e.target.value);
//                 setSelectedItemSpecificsColumns([]); // カラム選択をリセット
//               }}
//               label="適用するカテゴリー"
//             >
//               {Object.keys(itemSpecificsCategories).map((category) => (
//                 <MenuItem key={category} value={category}>
//                   {category}
//                 </MenuItem>
//               ))}
//             </Select>
//           </FormControl>

//           {/* カラム選択 */}
//           {selectedItemSpecificsCategory && (
//             <FormControl fullWidth sx={{ mt: 2 }}>
//               <InputLabel>適用するカラムを選択</InputLabel>
//               <Select
//                 multiple
//                 value={selectedItemSpecificsColumns}
//                 onChange={(e) => {
//                   const value = e.target.value;
//                   if (value.includes('all')) {
//                     // Handle "select all"
//                     const allColumns = getColumnsForSelectedCategory();
//                     if (selectedItemSpecificsColumns.length === allColumns.length) {
//                       // Deselect all if all are already selected
//                       setSelectedItemSpecificsColumns([]);
//                     } else {
//                       // Select all columns
//                       setSelectedItemSpecificsColumns(allColumns);
//                     }
//                   } else {
//                     setSelectedItemSpecificsColumns(value);
//                   }
//                 }}
//                 renderValue={(selected) => {
//                   if (selected.length === 0) {
//                     return '適用するカラムを選択';
//                   }

//                   const displayedValues = selected.slice(0, 5); // Show up to 5 selected columns
//                   const hiddenCount = selected.length - displayedValues.length;

//                   return (
//                     <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
//                       {displayedValues.map((value) => (
//                         <Chip key={value} label={value} size="small" />
//                       ))}
//                       {hiddenCount > 0 && (
//                         <Chip label={`+${hiddenCount}個`} size="small" />
//                       )}
//                     </Box>
//                   );
//                 }}
//               >
//                 <MenuItem
//                   value="all"
//                   onClick={() => {
//                     const allColumns = getColumnsForSelectedCategory();
//                     if (selectedItemSpecificsColumns.length === allColumns.length) {
//                       // Deselect all if all are already selected
//                       setSelectedItemSpecificsColumns([]);
//                     } else {
//                       // Select all columns
//                       setSelectedItemSpecificsColumns(allColumns);
//                     }
//                   }}
//                 >
//                   <Checkbox
//                     checked={
//                       selectedItemSpecificsColumns.length ===
//                         getColumnsForSelectedCategory().length &&
//                       selectedItemSpecificsColumns.length > 0
//                     }
//                     indeterminate={
//                       selectedItemSpecificsColumns.length > 0 &&
//                       selectedItemSpecificsColumns.length <
//                         getColumnsForSelectedCategory().length
//                     }
//                   />
//                   <Typography variant="body1">全選択</Typography>
//                 </MenuItem>
//                 {getColumnsForSelectedCategory().map((column) => (
//                   <MenuItem key={column} value={column}>
//                     <Checkbox
//                       checked={selectedItemSpecificsColumns.indexOf(column) > -1}
//                     />
//                     <Typography variant="body1">{column}</Typography>
//                   </MenuItem>
//                 ))}
//               </Select>
//             </FormControl>
//           )}

//           {/* マッチングオプション */}
//           {selectedItemSpecificsCategory && (
//             <>
//               <Box sx={{ mt: 2 }}>
//                 <Typography variant="subtitle1" gutterBottom>
//                   マッチングの基準を選択してください。
//                 </Typography>
//                 <FormControl component="fieldset">
//                   <RadioGroup
//                     value={itemSpecificsMatchingOptions.matchSource}
//                     onChange={(e) =>
//                       setItemSpecificsMatchingOptions((prev) => ({
//                         ...prev,
//                         matchSource: e.target.value,
//                       }))
//                     }
//                   >
//                     <FormControlLabel
//                       value="title"
//                       control={<Radio />}
//                       label="タイトルのみ"
//                     />
//                     <FormControlLabel
//                       value="description"
//                       control={<Radio />}
//                       label="商品説明のみ (AI生成部分のみ)"
//                     />
//                     <FormControlLabel
//                       value="both"
//                       control={<Radio />}
//                       label="タイトルと商品説明の両方 (AI生成部分のみ)"
//                     />
//                   </RadioGroup>
//                 </FormControl>
//               </Box>

//               {/* オプション */}
//               <Box sx={{ mt: 2 }}>
//                 <FormControlLabel
//                   control={
//                     <Checkbox
//                       checked={itemSpecificsMatchingOptions.caseSensitive}
//                       onChange={(e) =>
//                         setItemSpecificsMatchingOptions((prev) => ({
//                           ...prev,
//                           caseSensitive: e.target.checked,
//                         }))
//                       }
//                       name="caseSensitive"
//                     />
//                   }
//                   label="大文字小文字を区別する"
//                 />
//                 <FormControlLabel
//                   control={
//                     <Checkbox
//                       checked={itemSpecificsMatchingOptions.partialMatch}
//                       onChange={(e) =>
//                         setItemSpecificsMatchingOptions((prev) => ({
//                           ...prev,
//                           partialMatch: e.target.checked,
//                         }))
//                       }
//                       name="partialMatch"
//                     />
//                   }
//                   label="部分一致を許可する"
//                 />
//               </Box>

//               {/* マッチングの動作を選択 */}
//               <Box sx={{ mt: 2 }}>
//                 <Typography variant="subtitle1" gutterBottom>
//                   マッチングの動作を選択してください。
//                 </Typography>
//                 <Typography variant="subtitle1" gutterBottom>
//                   (上記でAI商品説明からマッチングを選択している場合は、自動処理でAI生成した文章からカラムの適所に適用されます。自動処理によって特に値の変更がなかった場合以下の設定が適用されます。)
//                 </Typography>
//                 <FormControl component="fieldset">
//                   <RadioGroup
//                     value={itemSpecificsMatchingOptions.matchingOption}
//                     onChange={(e) =>
//                       setItemSpecificsMatchingOptions((prev) => ({
//                         ...prev,
//                         matchingOption: e.target.value,
//                       }))
//                     }
//                   >
//                     <FormControlLabel
//                       value="priority"
//                       control={<Radio />}
//                       label="優先順位に基づいて適用"
//                     />
//                     <FormControlLabel
//                       value="all"
//                       control={<Radio />}
//                       label="マッチしたすべての値を適用"
//                     />
//                   </RadioGroup>
//                 </FormControl>
//               </Box>
//             </>
//           )}
//         </Box>
//       )}

//       {/* --- ここまで設定表示 --- */}

//       {/* 実行モードの選択 */}
//       <Box sx={{ mt: 2 }}>
//         <Typography variant="subtitle1">実行モード:</Typography>
//         <Button
//           variant={isImmediate ? 'contained' : 'outlined'}
//           onClick={() => setIsImmediate(true)}
//           sx={{ mr: 1 }}
//         >
//           即時実行
//         </Button>
//         <Button
//           variant={!isImmediate ? 'contained' : 'outlined'}
//           onClick={() => setIsImmediate(false)}
//         >
//           スケジュール実行
//         </Button>
//       </Box>

//       {/* スケジュール時間の選択 */}
//       {!isImmediate && (
//         <Box sx={{ mt: 2 }}>
//           <TextField
//             label="実行開始時間"
//             type="datetime-local"
//             value={scheduledTime}
//             onChange={(e) => setScheduledTime(e.target.value)}
//             InputLabelProps={{
//               shrink: true,
//             }}
//             fullWidth
//           />
//         </Box>
//       )}

//       {/* アップロード＆実行ボタン */}
//       <Button
//         variant="contained"
//         color="secondary"
//         sx={{ mt: 2 }}
//         onClick={handleUploadAndExecute}
//         disabled={isScheduling}
//       >
//         {isScheduling ? '処理中...' : 'アップロードして実行'}
//       </Button>

//       {/* 進捗状況の表示 */}
//       {isScheduling && (
//         <Box sx={{ mt: 2 }}>
//           <Typography variant="h6">処理進捗:</Typography>
//           <Typography>
//             {progress.completedItems} / {progress.totalItems} 件が完了しました
//           </Typography>
//           <LinearProgress
//             variant="determinate"
//             value={(progress.completedItems / progress.totalItems) * 100}
//           />
//         </Box>
//       )}

//       {/* 処理結果のプレビュー */}
//       {processedParsedData.length > 0 && (
//         <Box sx={{ mt: 4 }}>
//           <Typography variant="h6">処理結果のプレビュー:</Typography>
//           <Tabs
//             value={activeTab}
//             onChange={handleTabChange}
//             variant="scrollable"
//             scrollButtons="auto"
//           >
//             {processedParsedData.map((file, index) => (
//               <Tab key={index} label={file.name} />
//             ))}
//           </Tabs>
//           {processedParsedData.map((file, index) => (
//             <div
//               key={index}
//               role="tabpanel"
//               hidden={activeTab !== index}
//               id={`tabpanel-${index}`}
//             >
//               {activeTab === index && (
//                 <Box sx={{ p: 2 }}>
//                   {/* テーブル表示 */}
//                   <Paper elevation={2} sx={{ overflowX: 'auto' }}>
//                     <TableContainer>
//                       <Table
//                         size="small"
//                         sx={{
//                           tableLayout: 'auto',
//                           width: 'auto',
//                         }}
//                       >
//                         <TableHead>
//                           <TableRow>
//                             {file.columns.map((column, idx) => (
//                               <TableCell
//                                 key={idx}
//                                 sx={{
//                                   maxWidth: '150px',
//                                   whiteSpace: 'nowrap',
//                                   padding: '8px',
//                                   border: '1px solid rgba(224, 224, 224, 1)',
//                                   fontWeight: 'bold',
//                                 }}
//                               >
//                                 {column}
//                               </TableCell>
//                             ))}
//                           </TableRow>
//                         </TableHead>
//                         <TableBody>
//                           {file.data.map((row, rowIndex) => (
//                             <TableRow
//                               key={rowIndex}
//                               onClick={() => {
//                                 setSelectedProduct(row);
//                                 setIsDrawerOpen(true);
//                               }}
//                               style={{ cursor: 'pointer' }}
//                             >
//                               {file.columns.map((column, colIndex) => (
//                                 <TableCell
//                                   key={colIndex}
//                                   sx={{
//                                     maxWidth: '150px',
//                                     whiteSpace: 'nowrap',
//                                     padding: '8px',
//                                     border: '1px solid rgba(224, 224, 224, 1)',
//                                   }}
//                                 >
//                                   {column === 'PicURL' ? (
//                                     row[column] ? (
//                                       <img
//                                         src={getFirstImageUrl(row[column])}
//                                         alt="商品画像"
//                                         style={{
//                                           width: '50px',
//                                           height: '50px',
//                                           objectFit: 'cover',
//                                         }}
//                                       />
//                                     ) : (
//                                       'なし'
//                                     )
//                                   ) : (
//                                     row[column]
//                                   )}
//                                 </TableCell>
//                               ))}
//                             </TableRow>
//                           ))}
//                         </TableBody>
//                       </Table>
//                     </TableContainer>
//                   </Paper>

//                   {/* サイドパネル（Drawer） */}
//                   <Drawer
//                     anchor="right"
//                     open={isDrawerOpen}
//                     onClose={() => setIsDrawerOpen(false)}
//                   >
//                     <Box sx={{ width: 700, p: 2 }}>
//                       {selectedProduct ? (
//                         <>
//                           <Typography variant="h6">{selectedProduct['Title']}</Typography>
//                           <ImageGallery picUrlString={selectedProduct['PicURL']} />
//                           <Typography variant="body1">
//                             価格: {selectedProduct['StartPrice']}
//                           </Typography>
//                           <Typography variant="body1">説明:</Typography>
//                           <Box
//                             sx={{ mt: 2 }}
//                             dangerouslySetInnerHTML={{ __html: selectedProduct['Description'] }}
//                           />
//                           {/* 必要に応じて他の詳細情報を追加 */}
//                         </>
//                       ) : (
//                         <Typography variant="body1">商品が選択されていません</Typography>
//                       )}
//                     </Box>
//                   </Drawer>

//                   {/* 画像拡大表示のモーダル */}
//                   <Dialog
//                     open={isImageModalOpen}
//                     onClose={handleCloseImageModal}
//                     maxWidth="lg"
//                   >
//                     <DialogContent>
//                       <img
//                         src={currentImageUrl}
//                         alt="拡大画像"
//                         style={{ width: '100%', height: 'auto', objectFit: 'contain' }}
//                       />
//                     </DialogContent>
//                     <DialogActions>
//                       <Button onClick={handleCloseImageModal}>閉じる</Button>
//                     </DialogActions>
//                   </Dialog>
//                 </Box>
//               )}
//             </div>
//           ))}
//         </Box>
//       )}

//       {/* エラー情報の表示 */}
//       {errorItems.length > 0 && (
//         <Box sx={{ mt: 2 }}>
//           <Typography variant="h6" color="error">
//             エラーが発生したアイテム:
//           </Typography>
//           <List>
//             {errorItems.map((item, index) => (
//               <ListItem key={index}>
//                 <Typography>
//                   ファイル名: {item.fileName}, 行番号: {item.rowIndex}, エラー: {item.error}
//                 </Typography>
//               </ListItem>
//             ))}
//           </List>
//         </Box>
//       )}

//       {/* 処理結果のダウンロード */}
//       {processedData.length > 0 && (
//         <Box sx={{ mt: 2 }}>
//           <Typography variant="h6">処理結果のダウンロード:</Typography>
//           {processedData.map((file, index) => (
//             <Button
//               key={index}
//               variant="contained"
//               sx={{ mt: 1, mr: 1 }}
//               onClick={() => {
//                 const blob = new Blob([file.content], {
//                   type: 'text/csv;charset=utf-8;',
//                 });
//                 const link = document.createElement('a');
//                 link.href = URL.createObjectURL(blob);
//                 link.setAttribute('download', file.name);
//                 document.body.appendChild(link);
//                 link.click();
//                 document.body.removeChild(link);
//               }}
//             >
//               {file.name}
//             </Button>
//           ))}
//         </Box>
//       )}

//       {/* 機能選択ダイアログ */}
//       <Dialog open={functionDialogOpen} onClose={handleFunctionDialogClose}>
//         <DialogTitle>実行する機能を選択</DialogTitle>
//         <DialogContent>
//           <DragDropContext onDragEnd={onDragEnd}>
//             <Droppable droppableId="functions">
//               {(provided) => (
//                 <List {...provided.droppableProps} ref={provided.innerRef}>
//                   {availableFunctions.map((func, index) => (
//                     <Draggable key={func.name} draggableId={func.name} index={index}>
//                       {(provided) => (
//                         <ListItem
//                           button
//                           ref={provided.innerRef}
//                           {...provided.draggableProps}
//                         >
//                           <Checkbox
//                             checked={
//                               selectedFunctions.findIndex((f) => f.name === func.name) !== -1
//                             }
//                             onClick={handleFunctionToggle(func)}
//                           />
//                           <Typography>{func.displayName}</Typography>
//                           <IconButton {...provided.dragHandleProps}>
//                             <DragHandleIcon />
//                           </IconButton>
//                         </ListItem>
//                       )}
//                     </Draggable>
//                   ))}
//                   {provided.placeholder}
//                 </List>
//               )}
//             </Droppable>
//           </DragDropContext>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={handleFunctionDialogClose}>閉じる</Button>
//         </DialogActions>
//       </Dialog>

//       {/* テンプレート保存ダイアログ */}
//       <Dialog
//         open={isSaveTemplateDialogOpen}
//         onClose={() => setIsSaveTemplateDialogOpen(false)}
//       >
//         <DialogTitle>テンプレートの保存</DialogTitle>
//         <DialogContent>
//           <FormControl component="fieldset">
//             <RadioGroup
//               value={saveOption}
//               onChange={(e) => setSaveOption(e.target.value)}
//             >
//               <FormControlLabel
//                 value="new"
//                 control={<Radio />}
//                 label="新しいテンプレートとして保存"
//               />
//               {selectedTemplateId && (
//                 <FormControlLabel
//                   value="overwrite"
//                   control={<Radio />}
//                   label="既存のテンプレートを上書き保存"
//                 />
//               )}
//             </RadioGroup>
//           </FormControl>
//           <TextField
//             label="テンプレート名"
//             value={templateName}
//             onChange={(e) => setTemplateName(e.target.value)}
//             fullWidth
//             sx={{ mt: 2 }}
//           />
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => setIsSaveTemplateDialogOpen(false)}>キャンセル</Button>
//           <Button onClick={saveTemplate} color="primary">
//             保存
//           </Button>
//         </DialogActions>
//       </Dialog>

//       {/* テンプレート管理ダイアログ */}
//       <Dialog
//         open={isManageTemplatesDialogOpen}
//         onClose={() => setIsManageTemplatesDialogOpen(false)}
//       >
//         <DialogTitle>テンプレートの管理</DialogTitle>
//         <DialogContent>
//           <List>
//             {templates.map((template) => (
//               <ListItem key={template.id}>
//                 <ListItemText primary={template.name} />
//                 <IconButton
//                   edge="end"
//                   aria-label="delete"
//                   onClick={() => handleDeleteTemplate(template.id)}
//                 >
//                   <DeleteIcon />
//                 </IconButton>
//               </ListItem>
//             ))}
//           </List>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => setIsManageTemplatesDialogOpen(false)}>閉じる</Button>
//         </DialogActions>
//       </Dialog>

//       {/* パターン保存ダイアログ */}
//       <Dialog
//         open={isSavePatternDialogOpen}
//         onClose={() => setIsSavePatternDialogOpen(false)}
//       >
//         <DialogTitle>現在の設定をパターンとして保存</DialogTitle>
//         <DialogContent>
//           <TextField
//             label="パターン名"
//             value={newPatternName}
//             onChange={(e) => setNewPatternName(e.target.value)}
//             fullWidth
//           />
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => setIsSavePatternDialogOpen(false)}>キャンセル</Button>
//           <Button onClick={saveCurrentSettingsAsPattern} color="primary">
//             保存
//           </Button>
//         </DialogActions>
//       </Dialog>

//       {/* パターン管理ダイアログ */}
//       <Dialog
//         open={isManagePatternsDialogOpen}
//         onClose={() => setIsManagePatternsDialogOpen(false)}
//       >
//         <DialogTitle>パターンの管理</DialogTitle>
//         <DialogContent>
//           <List>
//             {patterns.map((pattern) => (
//               <ListItem key={pattern.name}>
//                 <ListItemText primary={pattern.name} />
//                 <IconButton
//                   edge="end"
//                   aria-label="delete"
//                   onClick={() => handleDeletePattern(pattern.name)}
//                 >
//                   <DeleteIcon />
//                 </IconButton>
//               </ListItem>
//             ))}
//           </List>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => setIsManagePatternsDialogOpen(false)}>閉じる</Button>
//         </DialogActions>
//       </Dialog>

//       {/* Snackbarによる通知 */}
//       <Snackbar
//         open={snackbar.open}
//         autoHideDuration={6000}
//         onClose={() => setSnackbar({ ...snackbar, open: false })}
//       >
//         <Alert
//           onClose={() => setSnackbar({ ...snackbar, open: false })}
//           severity={snackbar.severity}
//           sx={{ width: '100%' }}
//         >
//           {snackbar.message}
//         </Alert>
//       </Snackbar>
//     </Paper>
//   );
// }

// export default CsvSchedulerUploader;







// // CsvSchedulerUploader.js

// import React, { useState, useEffect, useCallback } from 'react';
// import {
//   Box,
//   Button,
//   Typography,
//   TextField,
//   Paper,
//   Snackbar,
//   Alert,
//   List,
//   ListItem,
//   Checkbox,
//   IconButton,
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogTitle,
//   FormControl,
//   InputLabel,
//   Select,
//   MenuItem,
//   Tabs,
//   Tab,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   LinearProgress,
//   Grid,
//   Switch,
//   ListItemText,
//   RadioGroup,
//   Radio,
//   FormControlLabel,
//   Chip,
//   Drawer,
// } from '@mui/material';
// import { useNavigate } from 'react-router-dom';
// import MenuIcon from '@mui/icons-material/Menu';
// import DragHandleIcon from '@mui/icons-material/DragHandle';
// import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
// import VisibilityIcon from '@mui/icons-material/Visibility';
// import EditIcon from '@mui/icons-material/Edit';
// import MoneyOffIcon from '@mui/icons-material/MoneyOff';
// import ReplayIcon from '@mui/icons-material/Replay';
// import DeleteIcon from '@mui/icons-material/Delete';

// // Firebase のインポート
// import { auth, db } from './firebaseConfig';
// import {
//   getFirestore,
//   getDoc,
//   doc,
//   collection,
//   getDocs,
//   addDoc,
//   setDoc,
//   deleteDoc,
// } from 'firebase/firestore';

// // 機能関数のインポート
// import { applyTemplate, applyItemSpecifics } from './functions';
// // パーサーのインポート
// import Papa from 'papaparse';
// // 価格計算関数のインポート
// import { calculatePrices } from './calculatePrices';
// // AIタイトル生成関数のインポート
// import { applyAITitles } from './applyAITitles';
// // AI商品説明生成関数のインポート
// import { applyAIProductDescriptions } from './applyAIProductDescriptions';
// // CryptoJSのインポート
// import CryptoJS from 'crypto-js';
// // デザインテンプレートのインポート
// import { applyItemSpecificsProcessor } from './ItemSpecificsProcessor';
// import designTemplates from './designTemplates';

// // その他のコンポーネントのインポート
// import OptionsPanel from './OptionsPanel';

// function CsvSchedulerUploader({ user }) {
//   const [csvFiles, setCsvFiles] = useState([]);
//   const [fileContents, setFileContents] = useState([]);
//   const [snackbar, setSnackbar] = useState({
//     open: false,
//     message: '',
//     severity: 'info',
//   });

//   // 状態変数を定義
//   const [selectedTemplate, setSelectedTemplate] = useState('');
//   const [selectedItemSpecificsCategory, setSelectedItemSpecificsCategory] = useState('');
//   const [selectedItemSpecificsColumns, setSelectedItemSpecificsColumns] = useState([]);
//   const [itemSpecificsMatchingOptions, setItemSpecificsMatchingOptions] = useState({
//     caseSensitive: false,
//     partialMatch: true,
//     matchSource: 'title',
//     matchingOption: 'priority',
//   });
//   const [itemSpecificsCategories, setItemSpecificsCategories] = useState({});

//   // デフォルトで全ての機能を選択状態にする
//   const [selectedFunctions, setSelectedFunctions] = useState([]);
//   const [isImmediate, setIsImmediate] = useState(true); // 即時実行かどうか
//   const [scheduledTime, setScheduledTime] = useState(''); // スケジュール時間（文字列）
//   const [functionDialogOpen, setFunctionDialogOpen] = useState(false);
//   const [isScheduling, setIsScheduling] = useState(false); // スケジュール処理中かどうか
//   const [processedData, setProcessedData] = useState([]); // 処理後のデータ
//   const [activeTab, setActiveTab] = useState(0); // アクティブなタブのインデックス

//   const [templateList, setTemplateList] = useState([]);
//   const [selectedTemplateName, setSelectedTemplateName] = useState('');

//   // 価格設定の状態管理
//   const [selectedPriceSettings, setSelectedPriceSettings] = useState(null);

//   // 送料テンプレートの状態管理
//   const [shippingRateTemplates, setShippingRateTemplates] = useState([]);
//   const [selectedShippingTemplateId, setSelectedShippingTemplateId] = useState('');

//   // その他の設定
//   const [exchangeRate, setExchangeRate] = useState(0);
//   const [targetProfitMargin, setTargetProfitMargin] = useState(0);
//   const [fees, setFees] = useState({});
//   const [bestOfferSettings, setBestOfferSettings] = useState({});

//   // ユーザーのAPIキーの状態管理
//   const [userApiKey, setUserApiKey] = useState('');

//   // ユーザー設定の状態管理
//   const [userSettings, setUserSettings] = useState({});

//   // デザインテンプレートの選択状態
//   const [designTemplateNames, setDesignTemplateNames] = useState([]);
//   const [selectedDesignTemplate, setSelectedDesignTemplate] = useState('');

//   // テンプレート管理の状態
//   const [templates, setTemplates] = useState([]);
//   const [templateName, setTemplateName] = useState('');
//   const [isSaveTemplateDialogOpen, setIsSaveTemplateDialogOpen] = useState(false);
//   const [isManageTemplatesDialogOpen, setIsManageTemplatesDialogOpen] = useState(false);
//   const [selectedTemplateId, setSelectedTemplateId] = useState('');
//   const [currentTemplateName, setCurrentTemplateName] = useState('');
//   const [saveOption, setSaveOption] = useState('new');

//   const [customCategories, setCustomCategories] = useState([]);
//   const [selectedCategory, setSelectedCategory] = useState('');



//   // その他の状態
//   const [useImageDescription, setUseImageDescription] = useState(false);
//   const [mandatoryKeywords, setMandatoryKeywords] = useState([]);
//   const [mandatoryKeywordInput, setMandatoryKeywordInput] = useState('');

//   // 新しく追加する状態変数
//   const [sentenceToDeleteInput, setSentenceToDeleteInput] = useState('');
//   const [wordToReplaceFrom, setWordToReplaceFrom] = useState('');
//   const [wordToReplaceTo, setWordToReplaceTo] = useState('');

//   const navigate = useNavigate();

//   // ユーザー固有の秘密鍵を生成
//   const generateEncryptionKey = (uid) => {
//     const salt = 'your-fixed-salt-value'; // 固定の塩値（セキュリティ向上のため、環境変数で管理することを推奨）
//     return CryptoJS.PBKDF2(uid, salt, { keySize: 256 / 32 }).toString();
//   };

//   // 進捗状況の状態
//   const [progress, setProgress] = useState({ totalItems: 0, completedItems: 0 });
//   const [errorItems, setErrorItems] = useState([]);

//   // availableFunctionsの定義をここに移動
//   const [availableFunctions, setAvailableFunctions] = useState([
//     {
//       name: 'applyTemplate',
//       displayName: 'テンプレートを実行する',
//       func: applyTemplate,
//     },
//     {
//       name: 'applyPriceChanges',
//       displayName: '価格の変更を適用する',
//       func: 'applyPriceChanges', // 後で設定
//     },
//     {
//       name: 'applyAITitles',
//       displayName: 'AIタイトルを適用する',
//       func: 'applyAITitles', // 後で設定
//     },
//     {
//       name: 'applyAIProductDescriptions',
//       displayName: 'AI商品説明を適用する',
//       func: 'applyAIProductDescriptions', // 後で設定
//     },
//     {
//       name: 'applyItemSpecifics',
//       displayName: 'Item Specificsの適用をする',
//       func: applyItemSpecifics,
//     },
//   ]);

//   useEffect(() => {
//     const fetchItemSpecificsSettings = async () => {
//       if (!user) return;

//       try {
//         const db = getFirestore();
//         const docRef = doc(db, 'itemSpecificsSettings', user.uid);
//         const docSnap = await getDoc(docRef);

//         if (docSnap.exists()) {
//           const settingsData = docSnap.data();
//           setItemSpecificsCategories(settingsData.categories || {});
//         } else {
//           console.warn('Item Specificsの設定が見つかりませんでした。');
//         }
//       } catch (error) {
//         console.error('Item Specificsの設定取得中にエラーが発生しました:', error);
//       }
//     };

//     fetchItemSpecificsSettings();
//   }, [user]);

//   // Function to get columns for the selected category
//   const getColumnsForSelectedCategory = () => {
//     if (
//       selectedItemSpecificsCategory &&
//       itemSpecificsCategories[selectedItemSpecificsCategory]
//     ) {
//       return Object.keys(
//         itemSpecificsCategories[selectedItemSpecificsCategory].columns || {}
//       );
//     }
//     return [];
//   };

//   // ユーザーが認証されていない場合はログインページへリダイレクト
//   // ユーザーが認証されていない場合はログインページへリダイレクト
// useEffect(() => {
//   if (!user) {
//     navigate('/login');
//   } else {
//     // テンプレート一覧を取得
//     const fetchTemplates = async () => {
//       try {
//         const docRef = doc(db, 'userTemplates', user.uid);
//         const docSnap = await getDoc(docRef);

//         if (docSnap.exists()) {
//           const userData = docSnap.data();
//           const userCategories = userData.categories || {};
//           const templates = Object.values(userCategories)
//             .flat()
//             .map((template) => ({
//               name: template.name,
//               operations: template.operations,
//             }));
//           setTemplateList(templates);
//           if (templates.length > 0) {
//             setSelectedTemplateName(templates[0].name);
//           }
//         }
//       } catch (error) {
//         console.error('テンプレートの取得中にエラーが発生しました:', error);
//         setSnackbar({
//           open: true,
//           message: 'テンプレートの取得に失敗しました。',
//           severity: 'error',
//         });
//       }
//     };
//     fetchTemplates();

//     // ユーザー設定とAPIキーを取得
//     const fetchUserSettings = async () => {
//       try {
//         // ユーザー設定を取得
//         const docRef = doc(db, 'userSettings', user.uid);
//         const docSnap = await getDoc(docRef);

//         if (docSnap.exists()) {
//           const userSettingsData = docSnap.data();
//           setSelectedPriceSettings(userSettingsData);
//           setUserSettings(userSettingsData); // ユーザー設定を保存

//           // 送料テンプレートを設定
//           if (userSettingsData.shippingRateTemplates) {
//             setShippingRateTemplates(userSettingsData.shippingRateTemplates);
//             setSelectedShippingTemplateId(
//               userSettingsData.selectedShippingTemplateId ||
//                 userSettingsData.shippingRateTemplates[0].id
//             );
//           }

//           // その他の設定を取得
//           setExchangeRate(userSettingsData.exchangeRate || 0);
//           setTargetProfitMargin(userSettingsData.targetProfitMargin || 0);
//           setFees(userSettingsData.fees || {});
//           setBestOfferSettings({
//             bestOfferAutoAcceptPercentage:
//               userSettingsData.bestOfferAutoAcceptPercentage || 0,
//             minimumBestOfferPercentage:
//               userSettingsData.minimumBestOfferPercentage || 0,
//           });

//           // デザインテンプレート名の取得と初期設定
//           if (userSettingsData.designTemplates) {
//             const templateNames = Object.keys(userSettingsData.designTemplates);
//             setDesignTemplateNames(templateNames);
//             if (templateNames.length > 0) {
//               setSelectedDesignTemplate(templateNames[0]);
//             } else {
//               console.warn('デザインテンプレートが設定されていません。');
//               // デフォルトのテンプレート名を設定
//               setSelectedDesignTemplate('defaultTemplate');
//             }
//           } else {
//             console.warn('デザインテンプレートが設定されていません。');
//             // デフォルトのテンプレート名を設定
//             setSelectedDesignTemplate('simple');
//           }

//           // カスタムフィルターや削除したい文章などの取得
//           setCustomFilters(userSettingsData.customFilters || []);
//           setSentencesToDelete(userSettingsData.sentencesToDelete || []);
//           setWordsToReplace(userSettingsData.wordsToReplace || []);
//           setMandatoryKeywords(userSettingsData.mandatoryKeywords || []);

//           // **タイトルカスタマイズテンプレートを取得して状態に保存**
//           if (userSettingsData.customCategories) {
//             setCustomCategories(userSettingsData.customCategories);
//           } else {
//             // **ユーザー設定に存在しない場合、Firestoreから別途取得**
//             await loadTitleCustomizationTemplates();
//           }
//         } else {
//           console.warn('ユーザー設定が見つかりませんでした');
//           // **ユーザー設定がない場合も、タイトルカスタマイズテンプレートを取得**
//           await loadTitleCustomizationTemplates();
//         }

//         // APIキーを取得
//         const apiKeyDocRef = doc(db, 'userApiKeys', user.uid);
//         const apiKeyDocSnap = await getDoc(apiKeyDocRef);

//         if (apiKeyDocSnap.exists()) {
//           const encryptedKey = apiKeyDocSnap.data().apiKey;
//           const encryptionKey = generateEncryptionKey(user.uid);
//           const bytes = CryptoJS.AES.decrypt(encryptedKey, encryptionKey);
//           const decryptedKey = bytes.toString(CryptoJS.enc.Utf8);
//           if (decryptedKey) {
//             setUserApiKey(decryptedKey);
//           } else {
//             console.error('APIキーの復号に失敗しました。');
//           }
//         } else {
//           console.warn('ユーザーのAPIキーが見つかりませんでした');
//         }

//         // ユーザーのテンプレートをロード
//         loadTemplates();

//       } catch (error) {
//         console.error('ユーザー設定の取得中にエラーが発生しました:', error);
//         setSnackbar({
//           open: true,
//           message: 'ユーザー設定の取得に失敗しました。',
//           severity: 'error',
//         });
//       }
//     };
//     fetchUserSettings();

//     // **loadTitleCustomizationTemplates関数を定義**
//     const loadTitleCustomizationTemplates = async () => {
//       if (!user) return;
//       try {
//         const docRef = doc(db, 'userTitleSettings', user.uid);
//         const docSnap = await getDoc(docRef);
//         if (docSnap.exists()) {
//           const data = docSnap.data();
//           setCustomCategories(data.customCategories || []);
//         } else {
//           console.log('タイトルカスタマイズテンプレートが見つかりませんでした。');
//         }
//       } catch (error) {
//         console.error('タイトルカスタマイズテンプレートのロード中にエラーが発生しました:', error);
//       }
//     };

//   }
// }, [user, navigate]);


//   // カスタムフィルターやテキスト変換の状態管理
//   const [customFilters, setCustomFilters] = useState([]);
//   const [sentencesToDelete, setSentencesToDelete] = useState([]);
//   const [wordsToReplace, setWordsToReplace] = useState([]);

//   // フィルタリングオプションのステート
//   const [selectedFilters, setSelectedFilters] = useState([]);
//   const [customFilterInput, setCustomFilterInput] = useState('');

//   // テンプレートの保存
//   const saveTemplate = async () => {
//     if (!templateName.trim()) {
//       setSnackbar({
//         open: true,
//         message: 'テンプレート名を入力してください',
//         severity: 'warning',
//       });
//       return;
//     }

//     const templateData = {
//       name: templateName.trim(),
//       customFilters,
//       sentencesToDelete,
//       wordsToReplace,
//       mandatoryKeywords,
//       selectedFilters,
//     };

//     try {
//       const userTemplatesRef = collection(db, 'users', user.uid, 'templates');

//       if (saveOption === 'overwrite' && selectedTemplateId) {
//         const templateDocRef = doc(userTemplatesRef, selectedTemplateId);
//         await setDoc(templateDocRef, templateData);
//         setSnackbar({
//           open: true,
//           message: 'テンプレートが更新されました',
//           severity: 'success',
//         });
//       } else {
//         await addDoc(userTemplatesRef, templateData);
//         setSnackbar({
//           open: true,
//           message: '新しいテンプレートが保存されました',
//           severity: 'success',
//         });
//       }

//       setIsSaveTemplateDialogOpen(false);
//       setTemplateName('');
//       loadTemplates();
//     } catch (error) {
//       console.error('テンプレートの保存中にエラーが発生しました:', error);
//       setSnackbar({
//         open: true,
//         message: 'テンプレートの保存中にエラーが発生しました',
//         severity: 'error',
//       });
//     }
//   };

//   // テンプレートの読み込み
//   const loadTemplates = async () => {
//     try {
//       const userTemplatesRef = collection(db, 'users', user.uid, 'templates');
//       const templatesSnapshot = await getDocs(userTemplatesRef);
//       const templatesData = templatesSnapshot.docs.map((doc) => ({
//         id: doc.id,
//         ...doc.data(),
//       }));
//       setTemplates(templatesData);
//     } catch (error) {
//       console.error('テンプレートの読み込み中にエラーが発生しました:', error);
//     }
//   };

//   // テンプレートの適用
//   const handleLoadTemplate = async (templateId) => {
//     if (!templateId) {
//       setSelectedTemplateId('');
//       setTemplateName('');
//       setCurrentTemplateName('');
//       return;
//     }
//     setSelectedTemplateId(templateId);
//     try {
//       const templateDocRef = doc(db, 'users', user.uid, 'templates', templateId);
//       const templateDoc = await getDoc(templateDocRef);
//       if (templateDoc.exists()) {
//         const templateData = templateDoc.data();
//         setTemplateName(templateData.name || '');
//         setCurrentTemplateName(templateData.name || '');
//         setCustomFilters(templateData.customFilters || []);
//         setSentencesToDelete(templateData.sentencesToDelete || []);
//         setWordsToReplace(templateData.wordsToReplace || []);
//         setMandatoryKeywords(templateData.mandatoryKeywords || []);
//         setSelectedFilters(templateData.selectedFilters || []);
//         setSnackbar({
//           open: true,
//           message: 'テンプレートが適用されました',
//           severity: 'success',
//         });
//       } else {
//         setSnackbar({
//           open: true,
//           message: 'テンプレートが見つかりません',
//           severity: 'error',
//         });
//       }
//     } catch (error) {
//       console.error('テンプレートの読み込み中にエラーが発生しました:', error);
//       setSnackbar({
//         open: true,
//         message: 'テンプレートの読み込み中にエラーが発生しました',
//         severity: 'error',
//       });
//     }
//   };

//   // テンプレートの削除
//   const handleDeleteTemplate = async (templateId) => {
//     try {
//       const templateDocRef = doc(db, 'users', user.uid, 'templates', templateId);
//       await deleteDoc(templateDocRef);
//       setSnackbar({
//         open: true,
//         message: 'テンプレートが削除されました',
//         severity: 'success',
//       });
//       loadTemplates();
//     } catch (error) {
//       console.error('テンプレートの削除中にエラーが発生しました:', error);
//       setSnackbar({
//         open: true,
//         message: 'テンプレートの削除中にエラーが発生しました',
//         severity: 'error',
//       });
//     }
//   };

//   // 新しいテンプレートを作成
//   const handleCreateNewTemplate = () => {
//     setSelectedTemplateId('');
//     setTemplateName('');
//     setCurrentTemplateName('');
//     setCustomFilters([]);
//     setSelectedFilters([]);
//     setSentencesToDelete([]);
//     setWordsToReplace([]);
//     setMandatoryKeywords([]);
//     setSnackbar({
//       open: true,
//       message: '新しいテンプレートを作成します',
//       severity: 'info',
//     });
//   };

//   // 必須キーワードの追加・削除
//   const handleAddMandatoryKeyword = () => {
//     if (mandatoryKeywordInput.trim() !== '') {
//       setMandatoryKeywords([...mandatoryKeywords, mandatoryKeywordInput.trim()]);
//       setMandatoryKeywordInput('');
//     }
//   };

//   const handleDeleteMandatoryKeyword = (index) => {
//     const updatedKeywords = [...mandatoryKeywords];
//     updatedKeywords.splice(index, 1);
//     setMandatoryKeywords(updatedKeywords);
//   };

//   // 削除したい文章の追加・削除
//   const handleAddSentenceToDelete = () => {
//     if (sentenceToDeleteInput.trim() !== '') {
//       setSentencesToDelete([...sentencesToDelete, sentenceToDeleteInput.trim()]);
//       setSentenceToDeleteInput('');
//     }
//   };

//   const handleDeleteSentenceToDelete = (index) => {
//     const updatedSentences = [...sentencesToDelete];
//     updatedSentences.splice(index, 1);
//     setSentencesToDelete(updatedSentences);
//   };

//   // 置換単語の追加・削除
//   const handleAddWordToReplace = () => {
//     if (wordToReplaceFrom.trim() !== '' && wordToReplaceTo.trim() !== '') {
//       setWordsToReplace([
//         ...wordsToReplace,
//         { from: wordToReplaceFrom.trim(), to: wordToReplaceTo.trim() },
//       ]);
//       setWordToReplaceFrom('');
//       setWordToReplaceTo('');
//     }
//   };

//   const handleDeleteWordToReplace = (index) => {
//     const updatedWords = [...wordsToReplace];
//     updatedWords.splice(index, 1);
//     setWordsToReplace(updatedWords);
//   };

//   // タイトルカスタマイズの状態管理
//   const [deleteStrings, setDeleteStrings] = useState([]);
//   const [replacePairs, setReplacePairs] = useState([]);
//   const [prependText, setPrependText] = useState('');
//   const [appendText, setAppendText] = useState('');
//   const [limitTitleLength, setLimitTitleLength] = useState(false);

//   // 置換ペアの管理関数
//   const handleAddReplacePair = () => {
//     setReplacePairs([...replacePairs, { from: '', to: '' }]);
//   };

//   const handleReplacePairChange = (index, field, value) => {
//     const newPairs = [...replacePairs];
//     newPairs[index][field] = value;
//     setReplacePairs(newPairs);
//   };

//   const handleRemoveReplacePair = (index) => {
//     const newPairs = [...replacePairs];
//     newPairs.splice(index, 1);
//     setReplacePairs(newPairs);
//   };

//   // ファイル選択時の処理
//   const handleFileChange = (event) => {
//     const files = Array.from(event.target.files).slice(0, 5); // 最大5つまで選択
//     setCsvFiles(files);
//     // ファイルの内容を読み込む
//     Promise.all(files.map((file) => file.text())).then((contents) => {
//       setFileContents(contents);
//     });
//   };

//   // 機能選択ダイアログの開閉
//   const handleFunctionDialogOpen = () => {
//     setFunctionDialogOpen(true);
//   };

//   const handleFunctionDialogClose = () => {
//     setFunctionDialogOpen(false);
//   };

//   // 機能の選択・解除
//   const handleFunctionToggle = (func) => () => {
//     const currentIndex = selectedFunctions.findIndex((f) => f.name === func.name);
//     const newSelected = [...selectedFunctions];

//     if (currentIndex === -1) {
//       newSelected.push(func);
//     } else {
//       newSelected.splice(currentIndex, 1);
//     }

//     setSelectedFunctions(newSelected);
//   };

//   // 機能の順序変更（ドラッグ＆ドロップ）
//   const onDragEnd = (result) => {
//     if (!result.destination) return;

//     const newFunctions = Array.from(selectedFunctions);
//     const [removed] = newFunctions.splice(result.source.index, 1);
//     newFunctions.splice(result.destination.index, 0, removed);

//     setSelectedFunctions(newFunctions);
//   };

//   // 価格適用関数をメモ化
//   const applyPriceChanges = useCallback(
//     async (currentData) => {
//       try {
//         if (!selectedPriceSettings) {
//           throw new Error('価格設定が取得されていません。');
//         }

//         // 選択された送料テンプレートを設定に反映
//         const updatedPriceSettings = {
//           ...selectedPriceSettings,
//           selectedShippingTemplateId,
//         };

//         const updatedData = await Promise.all(
//           currentData.map(async (file) => {
//             // CSVデータをパース
//             const parsedResult = Papa.parse(file.content, { header: true });
//             const data = parsedResult.data;

//             // 価格計算を実行
//             const newPrices = calculatePrices(
//               data,
//               updatedPriceSettings,
//               selectedShippingTemplateId
//             );

//             // 計算結果を元にデータを更新
//             const updatedData = data.map((item, index) => {
//               const calculatedPrice = newPrices[index];
//               if (calculatedPrice) {
//                 return {
//                   ...item,
//                   StartPrice: calculatedPrice.discountedPrice,
//                   BestOfferAutoAcceptPrice: calculatedPrice.bestOfferAutoAcceptPrice,
//                   MinimumBestOfferPrice: calculatedPrice.minimumBestOfferPrice,
//                 };
//               } else {
//                 return item;
//               }
//             });

//             // CSVに変換
//             const csvContent = Papa.unparse(updatedData);

//             return {
//               ...file,
//               content: csvContent,
//             };
//           })
//         );

//         return updatedData;
//       } catch (error) {
//         console.error('価格適用中にエラーが発生しました:', error);
//         setSnackbar({
//           open: true,
//           message: '価格適用中にエラーが発生しました。',
//           severity: 'error',
//         });
//         throw error;
//       }
//     },
//     [selectedPriceSettings, selectedShippingTemplateId, setSnackbar]
//   );

//   // applyAITitlesをラップしてAPIキーを渡す（メモ化）
//   const applyAITitlesWrapper = useCallback(
//     async (currentData) => {
//       try {
//         if (!userApiKey) {
//           setSnackbar({
//             open: true,
//             message:
//               'OpenAI APIキーが設定されていません。ユーザー設定でAPIキーを入力してください。',
//             severity: 'error',
//           });
//           throw new Error('OpenAI APIキーが設定されていません。');
//         }

//         // 進捗状況の初期化
//         setProgress({ totalItems: 0, completedItems: 0 });
//         setErrorItems([]);

//         // カスタマイズ設定を取得
//         const customizationOptions = {
//           deleteStrings,
//           replacePairs,
//           prependText,
//           appendText,
//           limitTitleLength,
//         };

//         const { updatedData, errorItems } = await applyAITitles(
//           currentData,
//           userApiKey,
//           (progressData) => {
//             setProgress(progressData);
//           },
//           customizationOptions // カスタマイズ設定を渡す
//         );

//         // エラー情報を保存
//         setErrorItems(errorItems);

//         return updatedData;
//       } catch (error) {
//         console.error('AIタイトル適用中にエラーが発生しました:', error);
//         setSnackbar({
//           open: true,
//           message: 'AIタイトル適用中にエラーが発生しました。',
//           severity: 'error',
//         });
//         throw error;
//       }
//     },
//     [
//       userApiKey,
//       setSnackbar,
//       setProgress,
//       setErrorItems,
//       deleteStrings,
//       replacePairs,
//       prependText,
//       appendText,
//       limitTitleLength,
//     ]
//   );

//   // applyAIProductDescriptionsをラップしてAPIキーとユーザー設定を渡す（メモ化）
//   const applyAIProductDescriptionsWrapper = useCallback(
//     async (currentData) => {
//       try {
//         if (!userApiKey) {
//           setSnackbar({
//             open: true,
//             message:
//               'OpenAI APIキーが設定されていません。ユーザー設定でAPIキーを入力してください。',
//             severity: 'error',
//           });
//           throw new Error('OpenAI APIキーが設定されていません。');
//         }

//         // 進捗状況の初期化
//         setProgress({ totalItems: 0, completedItems: 0 });
//         setErrorItems([]);

//         const { updatedData, errorItems } = await applyAIProductDescriptions(
//           currentData,
//           userApiKey,
//           (progressData) => {
//             setProgress(progressData);
//           },
//           selectedDesignTemplate, // デザインテンプレートを渡す
//           {
//             customFilters,
//             selectedFilters,
//             sentencesToDelete,
//             wordsToReplace,
//             mandatoryKeywords,
//           } // ユーザー設定を渡す
//         );

//         // エラー情報を保存
//         setErrorItems(errorItems);

//         return updatedData;
//       } catch (error) {
//         console.error('AI商品説明適用中にエラーが発生しました:', error);
//         setSnackbar({
//           open: true,
//           message: 'AI商品説明適用中にエラーが発生しました。',
//           severity: 'error',
//         });
//         throw error;
//       }
//     },
//     [
//       userApiKey,
//       setSnackbar,
//       setProgress,
//       setErrorItems,
//       selectedDesignTemplate,
//       customFilters,
//       selectedFilters,
//       sentencesToDelete,
//       wordsToReplace,
//       mandatoryKeywords,
//     ]
//   );

//   // applyItemSpecificsWrapperをメモ化
//   const applyItemSpecificsWrapper = useCallback(
//     async (currentData, options) => {
//       const {
//         selectedItemSpecificsCategory,
//         selectedItemSpecificsColumns,
//         itemSpecificsMatchingOptions,
//       } = options;
//       try {
//         if (!user) {
//           setSnackbar({
//             open: true,
//             message: 'ユーザー情報が取得できません。',
//             severity: 'error',
//           });
//           throw new Error('ユーザー情報が取得できません。');
//         }

//         if (!selectedItemSpecificsCategory) {
//           setSnackbar({
//             open: true,
//             message: '適用するカテゴリーを選択してください。',
//             severity: 'warning',
//           });
//           throw new Error('適用するカテゴリーを選択してください。');
//         }

//         // 進捗状況の初期化
//         setProgress({ totalItems: 0, completedItems: 0 });

//         console.log(
//           'applyItemSpecificsWrapper内のselectedItemSpecificsCategory:',
//           selectedItemSpecificsCategory
//         );

//         const updatedData = await applyItemSpecificsProcessor(
//           currentData,
//           user.uid,
//           {
//             selectedCategory: selectedItemSpecificsCategory,
//             selectedColumns: selectedItemSpecificsColumns,
//             matchingOptions: itemSpecificsMatchingOptions,
//           },
//           (progressData) => {
//             setProgress(progressData);
//           }
//         );

//         return updatedData;
//       } catch (error) {
//         console.error('Item Specifics適用中にエラーが発生しました:', error);
//         setSnackbar({
//           open: true,
//           message: error.message || 'Item Specifics適用中にエラーが発生しました。',
//           severity: 'error',
//         });
//         throw error;
//       }
//     },
//     [user, setSnackbar, setProgress]
//   );

//   // availableFunctions の 'applyPriceChanges' と 'applyAITitles' と 'applyAIProductDescriptions' の func を更新
//   useEffect(() => {
//     setAvailableFunctions((prevFunctions) =>
//       prevFunctions.map((func) => {
//         if (func.name === 'applyPriceChanges') {
//           return { ...func, func: applyPriceChanges };
//         }
//         if (func.name === 'applyAITitles') {
//           return { ...func, func: applyAITitlesWrapper };
//         }
//         if (func.name === 'applyAIProductDescriptions') {
//           return { ...func, func: applyAIProductDescriptionsWrapper };
//         }
//         return func;
//       })
//     );
//   }, [applyPriceChanges, applyAITitlesWrapper, applyAIProductDescriptionsWrapper]);

//   // applyItemSpecificsの関数も設定
//   useEffect(() => {
//     setAvailableFunctions((prevFunctions) =>
//       prevFunctions.map((func) =>
//         func.name === 'applyItemSpecifics'
//           ? { ...func, func: applyItemSpecificsWrapper }
//           : func
//       )
//     );
//   }, [applyItemSpecificsWrapper]);

//   // アップロードおよび実行処理
//   const handleUploadAndExecute = async () => {
//     if (!user) {
//       setSnackbar({
//         open: true,
//         message: 'ユーザーが認証されていません。',
//         severity: 'error',
//       });
//       return;
//     }

//     if (csvFiles.length === 0) {
//       setSnackbar({
//         open: true,
//         message: 'アップロードするファイルがありません。',
//         severity: 'warning',
//       });
//       return;
//     }

//     if (selectedFunctions.length === 0) {
//       setSnackbar({
//         open: true,
//         message: '実行する機能を選択してください。',
//         severity: 'warning',
//       });
//       return;
//     }

//     setIsScheduling(true);

//     try {
//       // ファイル名と内容を組み合わせたデータ
//       const filesData = csvFiles.map((file, index) => ({
//         name: file.name,
//         content: fileContents[index],
//       }));

//       const executeFunctions = async () => {
//         let currentData = filesData; // 初期データ

//         for (const func of selectedFunctions) {
//           try {
//             if (func.func.constructor.name === 'AsyncFunction') {
//               if (func.name === 'applyTemplate') {
//                 currentData = await func.func(currentData, user, selectedTemplateName); // user とテンプレート名を渡す
//               } else if (func.name === 'applyItemSpecifics') {
//                 // ここで最新の状態を渡す
//                 currentData = await func.func(currentData, {
//                   selectedItemSpecificsCategory,
//                   selectedItemSpecificsColumns,
//                   itemSpecificsMatchingOptions,
//                 });
//               } else {
//                 currentData = await func.func(currentData);
//               }
//             } else {
//               if (func.name === 'applyTemplate') {
//                 currentData = func.func(currentData, user, selectedTemplateName); // user とテンプレート名を渡す
//               } else if (func.name === 'applyItemSpecifics') {
//                 // ここで最新の状態を渡す
//                 currentData = func.func(currentData, {
//                   selectedItemSpecificsCategory,
//                   selectedItemSpecificsColumns,
//                   itemSpecificsMatchingOptions,
//                 });
//               } else {
//                 currentData = func.func(currentData);
//               }
//             }
//           } catch (error) {
//             console.error(`機能 "${func.displayName}" の実行中にエラーが発生しました:`, error);
//             setSnackbar({
//               open: true,
//               message: `機能 "${func.displayName}" の実行中にエラーが発生しました。`,
//               severity: 'error',
//             });
//             setIsScheduling(false);
//             return;
//           }
//         }

//         // 処理結果を状態に保存
//         setProcessedData(currentData);

//         setSnackbar({
//           open: true,
//           message: '選択された機能を実行しました。',
//           severity: 'success',
//         });
//         setIsScheduling(false);
//       };

//       if (isImmediate) {
//         // 即時実行の場合
//         await executeFunctions();
//       } else {
//         // スケジュール実行の場合
//         const now = new Date();
//         const scheduledDateTime = new Date(scheduledTime);
//         const delay = scheduledDateTime - now;

//         if (delay > 0) {
//           setSnackbar({
//             open: true,
//             message: 'タスクがスケジュールされました。',
//             severity: 'success',
//           });
//           setTimeout(async () => {
//             await executeFunctions();
//           }, delay);
//         } else {
//           setSnackbar({
//             open: true,
//             message: '指定された時間は過去です。正しい時間を選択してください。',
//             severity: 'error',
//           });
//           setIsScheduling(false);
//         }
//       }
//     } catch (error) {
//       console.error('処理中にエラーが発生しました:', error);
//       setSnackbar({
//         open: true,
//         message: '処理中にエラーが発生しました。',
//         severity: 'error',
//       });
//       setIsScheduling(false);
//     }
//   };

//   // タブの変更
//   const handleTabChange = (event, newValue) => {
//     setActiveTab(newValue);
//   };

//   // CSVデータをパースして状態に保存
//   const [parsedData, setParsedData] = useState([]);

//   useEffect(() => {
//     const parseCSVFiles = () => {
//       const data = fileContents.map((content) => {
//         const parsedResult = Papa.parse(content, { header: true });
//         return {
//           data: parsedResult.data,
//           columns: parsedResult.meta.fields,
//         };
//       });
//       setParsedData(data);
//     };

//     if (fileContents.length > 0) {
//       parseCSVFiles();
//     } else {
//       setParsedData([]);
//     }
//   }, [fileContents]);

//   // 処理後のデータをプレビュー
//   const [processedParsedData, setProcessedParsedData] = useState([]);

//   useEffect(() => {
//     if (processedData.length > 0) {
//       const data = processedData.map((file) => {
//         const parsedResult = Papa.parse(file.content, { header: true });
//         return {
//           name: file.name,
//           data: parsedResult.data,
//           columns: parsedResult.meta.fields,
//         };
//       });
//       setProcessedParsedData(data);
//     } else {
//       setProcessedParsedData([]);
//     }
//   }, [processedData]);

//   // フィルターの変更ハンドラー
//   const handleFilterChange = (label) => {
//     setSelectedFilters((prev) =>
//       prev.includes(label) ? prev.filter((item) => item !== label) : [...prev, label]
//     );
//   };

//   // カスタムフィルターの追加・削除
//   const handleAddCustomFilter = () => {
//     if (customFilterInput.trim() !== '') {
//       setCustomFilters([...customFilters, customFilterInput.trim()]);
//       setCustomFilterInput('');
//     }
//   };

//   const handleDeleteCustomFilter = (index) => {
//     const updatedFilters = [...customFilters];
//     updatedFilters.splice(index, 1);
//     setCustomFilters(updatedFilters);
//   };

//   // 画像サムネイルの表示用関数
//   const getFirstImageUrl = (picUrlString) => {
//     if (!picUrlString) return '';
//     const urls = picUrlString.split('|');
//     return urls[0] || '';
//   };

//   // 選択された商品の状態管理
//   const [selectedProduct, setSelectedProduct] = useState(null);
//   const [isModalOpen, setIsModalOpen] = useState(false);

//   // 画像モーダルの状態管理
//   const [isImageModalOpen, setIsImageModalOpen] = useState(false);
//   const [currentImageUrl, setCurrentImageUrl] = useState('');

//   const handleOpenImageModal = (url) => {
//     setCurrentImageUrl(url);
//     setIsImageModalOpen(true);
//   };

//   const handleCloseImageModal = () => {
//     setIsImageModalOpen(false);
//     setCurrentImageUrl('');
//   };

//   // サイドパネルの状態管理
//   const [isDrawerOpen, setIsDrawerOpen] = useState(false);

//   // 画像ギャラリーコンポーネント
//   const ImageGallery = ({ picUrlString }) => {
//     if (!picUrlString) return null;
//     const urls = picUrlString.split('|');

//     return (
//       <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
//         {urls.map((url, index) => (
//           <Box key={index} sx={{ position: 'relative' }}>
//             <img
//               src={url}
//               alt={`商品画像 ${index + 1}`}
//               style={{
//                 width: '100px',
//                 height: '100px',
//                 objectFit: 'cover',
//                 cursor: 'pointer',
//               }}
//               onClick={() => {
//                 handleOpenImageModal(url);
//               }}
//             />
//           </Box>
//         ))}
//       </Box>
//     );
//   };

//   return (
//     <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
//       <Typography variant="h6" gutterBottom>
//         CSVファイルのアップロードとタスクのスケジュール
//       </Typography>

//       {/* ファイル選択 */}
//       <Button variant="contained" component="label">
//         ファイルを選択（最大5つまで）
//         <input
//           type="file"
//           accept=".csv"
//           multiple
//           hidden
//           onChange={handleFileChange}
//         />
//       </Button>

//       {csvFiles.length > 0 && (
//         <Box sx={{ mt: 2 }}>
//           <Typography variant="subtitle1">選択されたファイル:</Typography>
//           <List>
//             {csvFiles.map((file, index) => (
//               <ListItem key={index}>
//                 <Typography>{file.name}</Typography>
//               </ListItem>
//             ))}
//           </List>
//         </Box>
//       )}

//       {/* 機能選択 */}
//       <Button
//         variant="contained"
//         color="primary"
//         sx={{ mt: 2 }}
//         onClick={handleFunctionDialogOpen}
//         startIcon={<MenuIcon />}
//       >
//         実行する機能を選択
//       </Button>

//       {/* --- ここから設定表示を指定された順序で配置します --- */}

//       {/* 1. テンプレート適用 */}
//       {selectedFunctions.some((func) => func.name === 'applyTemplate') && (
//         <Box sx={{ mt: 2 }}>
//           <Typography variant="h6">1. テンプレート適用</Typography>
//           <FormControl fullWidth sx={{ mt: 1 }}>
//             <InputLabel>テンプレートを選択</InputLabel>
//             <Select
//               value={selectedTemplateName}
//               onChange={(e) => setSelectedTemplateName(e.target.value)}
//             >
//               {templateList.map((template) => (
//                 <MenuItem key={template.name} value={template.name}>
//                   {template.name}
//                 </MenuItem>
//               ))}
//             </Select>
//           </FormControl>
//         </Box>
//       )}

//       {/* 2. 価格機能 */}
//       {selectedFunctions.some((func) => func.name === 'applyPriceChanges') && (
//         <Box sx={{ mt: 2 }}>
//           <Typography variant="h6">2. 価格機能</Typography>
//           {shippingRateTemplates.length > 0 && (
//             <FormControl fullWidth sx={{ mt: 1 }}>
//               <InputLabel>送料設定を選択</InputLabel>
//               <Select
//                 value={selectedShippingTemplateId}
//                 onChange={(e) => setSelectedShippingTemplateId(e.target.value)}
//               >
//                 {shippingRateTemplates.map((template) => (
//                   <MenuItem key={template.id} value={template.id}>
//                     {template.name}
//                   </MenuItem>
//                 ))}
//               </Select>
//             </FormControl>
//           )}
//           <Paper elevation={2} sx={{ p: 2, mt: 2 }}>
//             <Typography variant="subtitle1">現在の価格設定</Typography>
//             <Typography>為替レート: 1 USD = {exchangeRate} JPY</Typography>
//             <Typography>目標利益率: {targetProfitMargin}%</Typography>
//             <Typography>eBay最終価値手数料: {fees.ebayFinalValue || 0}%</Typography>
//             <Typography>Payoneer手数料: {fees.payoneer || 0}%</Typography>
//             <Typography>広告出品手数料: {fees.promotedListing || 0}%</Typography>
//             <Typography>eBay取引手数料: {fees.ebayTransactionFee || 0} USD</Typography>
//             <Typography>
//               ベストオファー自動承認価格:{' '}
//               {bestOfferSettings.bestOfferAutoAcceptPercentage || 0}%
//             </Typography>
//             <Typography>
//               最小ベストオファー価格:{' '}
//               {bestOfferSettings.minimumBestOfferPercentage || 0}%
//             </Typography>
//           </Paper>
//         </Box>
//       )}

//       {/* 3. AIタイトル */}
//         {selectedFunctions.some((func) => func.name === 'applyAITitles') && (
//           <Box sx={{ mt: 2 }}>
//             <Typography variant="h6">3. AIタイトル</Typography>

//             {/* タイトルカスタマイズテンプレートの選択 */}
//             <FormControl fullWidth sx={{ mt: 1 }}>
//               <InputLabel>タイトルカスタマイズテンプレートを選択</InputLabel>
//               <Select
//                 value={selectedCategory}
//                 onChange={(e) => {
//                   const selectedName = e.target.value;
//                   setSelectedCategory(selectedName);

//                   if (selectedName) {
//                     const category = customCategories.find((cat) => cat.name === selectedName);
//                     if (category) {
//                       setDeleteStrings(category.deleteStrings || []);
//                       setReplacePairs(category.replacePairs || []);
//                       setPrependText(category.prependText || '');
//                       setAppendText(category.appendText || '');
//                       setLimitTitleLength(category.limitTitleLength || false);
//                     }
//                   } else {
//                     // 新しいテンプレートを選択した場合、初期状態にリセット
//                     setDeleteStrings([]);
//                     setReplacePairs([]);
//                     setPrependText('');
//                     setAppendText('');
//                     setLimitTitleLength(false);
//                   }
//                 }}
//               >
//                 {customCategories.map((cat) => (
//                   <MenuItem key={cat.name} value={cat.name}>
//                     {cat.name}
//                   </MenuItem>
//                 ))}
//               </Select>
//             </FormControl>

//             {/* 以下にカスタマイズ設定のUIを追加 */}
//             <Box sx={{ mt: 2 }}>
//               <Typography variant="subtitle1">タイトルカスタマイズ設定</Typography>

//               {/* 削除したい単語 */}
//               <TextField
//                 label="削除したい単語 (カンマ区切り)"
//                 value={deleteStrings.join(', ')}
//                 onChange={(e) =>
//                   setDeleteStrings(e.target.value.split(',').map((s) => s.trim()))
//                 }
//                 fullWidth
//                 sx={{ mt: 1 }}
//               />

//               {/* 置換ペア */}
//               <Box sx={{ mt: 2 }}>
//                 <Typography variant="subtitle1">置換ペア</Typography>
//                 {replacePairs.map((pair, index) => (
//                   <Box key={index} sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
//                     <TextField
//                       label="置換前"
//                       value={pair.from}
//                       onChange={(e) => handleReplacePairChange(index, 'from', e.target.value)}
//                     />
//                     <TextField
//                       label="置換後"
//                       value={pair.to}
//                       onChange={(e) => handleReplacePairChange(index, 'to', e.target.value)}
//                       sx={{ ml: 1 }}
//                     />
//                     <IconButton onClick={() => handleRemoveReplacePair(index)}>
//                       <DeleteIcon />
//                     </IconButton>
//                   </Box>
//                 ))}
//                 <Button variant="outlined" onClick={handleAddReplacePair} sx={{ mt: 1 }}>
//                   置換ペアを追加
//                 </Button>
//               </Box>

//               {/* 先頭に追加する文字列 */}
//               <TextField
//                 label="先頭に追加する文字列"
//                 value={prependText}
//                 onChange={(e) => setPrependText(e.target.value)}
//                 fullWidth
//                 sx={{ mt: 1 }}
//               />

//               {/* 末尾に追加する文字列 */}
//               <TextField
//                 label="末尾に追加する文字列"
//                 value={appendText}
//                 onChange={(e) => setAppendText(e.target.value)}
//                 fullWidth
//                 sx={{ mt: 1 }}
//               />

//               {/* 80文字に制限する */}
//               <FormControlLabel
//                 control={
//                   <Switch
//                     checked={limitTitleLength}
//                     onChange={(e) => setLimitTitleLength(e.target.checked)}
//                   />
//                 }
//                 label="80文字に制限する"
//                 sx={{ mt: 1 }}
//               />
//             </Box>
//           </Box>
//         )}



//       {/* 4. AI商品説明 */}
//       {selectedFunctions.some((func) => func.name === 'applyAIProductDescriptions') && (
//         <Box sx={{ mt: 2 }}>
//           <Typography variant="h6">4. AI商品説明</Typography>
//           <Grid container spacing={2} sx={{ mt: 1 }}>
//             {/* デザインテンプレート選択 */}
//             {designTemplateNames.length > 0 && (
//               <Grid item xs={12} sm={6} md={4}>
//                 <FormControl fullWidth>
//                   <InputLabel>デザインテンプレートを選択</InputLabel>
//                   <Select
//                     value={selectedDesignTemplate}
//                     onChange={(e) => setSelectedDesignTemplate(e.target.value)}
//                   >
//                     {designTemplateNames.map((templateName) => (
//                       <MenuItem key={templateName} value={templateName}>
//                         {templateName}
//                       </MenuItem>
//                     ))}
//                   </Select>
//                 </FormControl>
//                 {/* 現在選択されているデザインテンプレートの表示 */}
//                 {selectedDesignTemplate && (
//                   <Typography variant="body2" sx={{ mt: 1 }}>
//                     選択中のテンプレート: {selectedDesignTemplate}
//                   </Typography>
//                 )}
//               </Grid>
//             )}

//             {/* 画像解析の説明を使用 */}
//             <Grid item xs={12} sm={6} md={4}>
//               <FormControlLabel
//                 control={
//                   <Switch
//                     checked={useImageDescription}
//                     onChange={(e) => setUseImageDescription(e.target.checked)}
//                     name="useImageDescription"
//                     color="primary"
//                   />
//                 }
//                 label="画像解析の説明を使用"
//               />
//             </Grid>

//             {/* 必須キーワード */}
//             <Grid item xs={12} sm={6} md={4}>
//               <Typography variant="subtitle1">必ず含めたいキーワード</Typography>
//               <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
//                 <TextField
//                   label="キーワードを入力"
//                   value={mandatoryKeywordInput}
//                   onChange={(e) => setMandatoryKeywordInput(e.target.value)}
//                   fullWidth
//                 />
//                 <Button
//                   variant="contained"
//                   color="primary"
//                   onClick={handleAddMandatoryKeyword}
//                   sx={{ ml: 1, height: '56px' }}
//                 >
//                   追加
//                 </Button>
//               </Box>
//               <List>
//                 {mandatoryKeywords.map((keyword, index) => (
//                   <ListItem
//                     key={index}
//                     secondaryAction={
//                       <IconButton
//                         edge="end"
//                         aria-label="delete"
//                         onClick={() => handleDeleteMandatoryKeyword(index)}
//                       >
//                         <DeleteIcon />
//                       </IconButton>
//                     }
//                   >
//                     <ListItemText primary={keyword} />
//                   </ListItem>
//                 ))}
//               </List>
//             </Grid>

//             {/* カスタムフィルター、削除したい文章、置換単語を横並びに配置 */}
//             <Grid item xs={12}>
//               <Grid container spacing={2}>
//                 {/* カスタムフィルター */}
//                 <Grid item xs={12} sm={4}>
//                   <OptionsPanel
//                     selectedFilters={selectedFilters}
//                     handleFilterChange={handleFilterChange}
//                     customFilterInput={customFilterInput}
//                     setCustomFilterInput={setCustomFilterInput}
//                     customFilters={customFilters}
//                     handleAddCustomFilter={handleAddCustomFilter}
//                     handleDeleteCustomFilter={handleDeleteCustomFilter}
//                     sentencesToDelete={sentencesToDelete}
//                     setSentencesToDelete={setSentencesToDelete}
//                     wordsToReplace={wordsToReplace}
//                     setWordsToReplace={setWordsToReplace}
//                   />
//                 </Grid>

//                 {/* 削除したい文章 */}
//                 <Grid item xs={12} sm={4}>
//                   <Typography variant="subtitle1">削除したい文章</Typography>
//                   <TextField
//                     label="文章を入力"
//                     value={sentenceToDeleteInput}
//                     onChange={(e) => setSentenceToDeleteInput(e.target.value)}
//                     fullWidth
//                   />
//                   <Button
//                     variant="contained"
//                     color="primary"
//                     onClick={handleAddSentenceToDelete}
//                     sx={{ mt: 1 }}
//                   >
//                     追加
//                   </Button>
//                   <List>
//                     {sentencesToDelete.map((sentence, index) => (
//                       <ListItem
//                         key={index}
//                         secondaryAction={
//                           <IconButton
//                             edge="end"
//                             aria-label="delete"
//                             onClick={() => handleDeleteSentenceToDelete(index)}
//                           >
//                             <DeleteIcon />
//                           </IconButton>
//                         }
//                       >
//                         <ListItemText primary={sentence} />
//                       </ListItem>
//                     ))}
//                   </List>
//                 </Grid>

//                 {/* 置換単語 */}
//                 <Grid item xs={12} sm={4}>
//                   <Typography variant="subtitle1">置換単語</Typography>
//                   <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
//                     <TextField
//                       label="置換前"
//                       value={wordToReplaceFrom}
//                       onChange={(e) => setWordToReplaceFrom(e.target.value)}
//                       fullWidth
//                     />
//                     <Typography sx={{ mx: 1 }}>➔</Typography>
//                     <TextField
//                       label="置換後"
//                       value={wordToReplaceTo}
//                       onChange={(e) => setWordToReplaceTo(e.target.value)}
//                       fullWidth
//                     />
//                   </Box>
//                   <Button
//                     variant="contained"
//                     color="primary"
//                     onClick={handleAddWordToReplace}
//                     sx={{ mt: 1 }}
//                   >
//                     追加
//                   </Button>
//                   <List>
//                     {wordsToReplace.map((wordPair, index) => (
//                       <ListItem
//                         key={index}
//                         secondaryAction={
//                           <IconButton
//                             edge="end"
//                             aria-label="delete"
//                             onClick={() => handleDeleteWordToReplace(index)}
//                           >
//                             <DeleteIcon />
//                           </IconButton>
//                         }
//                       >
//                         <ListItemText primary={`${wordPair.from} ➔ ${wordPair.to}`} />
//                       </ListItem>
//                     ))}
//                   </List>
//                 </Grid>
//               </Grid>
//             </Grid>

//             {/* テンプレート管理のUI */}
//             <Grid item xs={12}>
//               <Box sx={{ mt: 2 }}>
//                 <Typography variant="subtitle1">テンプレートの管理</Typography>
//                 <Button
//                   variant="contained"
//                   color="primary"
//                   onClick={() => {
//                     setIsSaveTemplateDialogOpen(true);
//                     setSaveOption(selectedTemplateId ? 'overwrite' : 'new');
//                   }}
//                 >
//                   テンプレートとして保存
//                 </Button>
//                 <Button
//                   variant="outlined"
//                   color="primary"
//                   onClick={() => setIsManageTemplatesDialogOpen(true)}
//                   sx={{ ml: 1 }}
//                 >
//                   テンプレートを管理
//                 </Button>
//                 <Button
//                   variant="outlined"
//                   color="secondary"
//                   onClick={handleCreateNewTemplate}
//                   sx={{ ml: 1, mt: 1 }}
//                 >
//                   新しいテンプレートを作成
//                 </Button>

//                 {/* テンプレート選択 */}
//                 <FormControl fullWidth sx={{ mt: 2 }}>
//                   <InputLabel>テンプレートを選択</InputLabel>
//                   <Select
//                     value={selectedTemplateId}
//                     onChange={(e) => handleLoadTemplate(e.target.value)}
//                   >
//                     <MenuItem value="">
//                       <em>テンプレートを選択</em>
//                     </MenuItem>
//                     {templates.map((template) => (
//                       <MenuItem key={template.id} value={template.id}>
//                         {template.name}
//                       </MenuItem>
//                     ))}
//                   </Select>
//                 </FormControl>
//               </Box>
//             </Grid>
//           </Grid>

//           {/* ユーザー設定の表示 */}
//           <Grid item xs={12}>
//             <Paper elevation={2} sx={{ p: 2, mt: 2 }}>
//               <Typography variant="h6">現在のユーザー設定</Typography>
//               <Grid container spacing={2}>
//                 {/* カスタムフィルター */}
//                 {customFilters && customFilters.length > 0 && (
//                   <Grid item xs={12} sm={6} md={3}>
//                     <Typography variant="subtitle1">カスタムフィルター:</Typography>
//                     <List>
//                       {customFilters.map((filter, index) => (
//                         <ListItem key={index}>
//                           <Typography>{filter}</Typography>
//                         </ListItem>
//                       ))}
//                     </List>
//                   </Grid>
//                 )}

//                 {/* 削除したい文章 */}
//                 {sentencesToDelete && sentencesToDelete.length > 0 && (
//                   <Grid item xs={12} sm={6} md={3}>
//                     <Typography variant="subtitle1">削除したい文章:</Typography>
//                     <List>
//                       {sentencesToDelete.map((sentence, index) => (
//                         <ListItem key={index}>
//                           <Typography>{sentence}</Typography>
//                         </ListItem>
//                       ))}
//                     </List>
//                   </Grid>
//                 )}

//                 {/* 置換単語 */}
//                 {wordsToReplace && wordsToReplace.length > 0 && (
//                   <Grid item xs={12} sm={6} md={3}>
//                     <Typography variant="subtitle1">置換単語:</Typography>
//                     <List>
//                       {wordsToReplace.map((wordPair, index) => (
//                         <ListItem key={index}>
//                           <Typography>
//                             {wordPair.from} ➔ {wordPair.to}
//                           </Typography>
//                         </ListItem>
//                       ))}
//                     </List>
//                   </Grid>
//                 )}

//                 {/* 必須キーワード */}
//                 {mandatoryKeywords && mandatoryKeywords.length > 0 && (
//                   <Grid item xs={12} sm={6} md={3}>
//                     <Typography variant="subtitle1">必須キーワード:</Typography>
//                     <List>
//                       {mandatoryKeywords.map((keyword, index) => (
//                         <ListItem key={index}>
//                           <Typography>{keyword}</Typography>
//                         </ListItem>
//                       ))}
//                     </List>
//                   </Grid>
//                 )}
//               </Grid>
//             </Paper>
//           </Grid>
//         </Box>
//       )}

//       {/* Item Specifics適用 */}
//       {selectedFunctions.some((func) => func.name === 'applyItemSpecifics') && (
//         <Box sx={{ mt: 2 }}>
//           <Typography variant="h6">5. Item Specifics適用</Typography>
//           {/* カテゴリー選択 */}
//           <FormControl fullWidth sx={{ mt: 1 }}>
//             <InputLabel>適用するカテゴリー</InputLabel>
//             <Select
//               value={selectedItemSpecificsCategory}
//               onChange={(e) => {
//                 console.log('選択されたカテゴリー:', e.target.value);
//                 setSelectedItemSpecificsCategory(e.target.value);
//                 setSelectedItemSpecificsColumns([]); // カラム選択をリセット
//               }}
//               label="適用するカテゴリー"
//             >
//               {Object.keys(itemSpecificsCategories).map((category) => (
//                 <MenuItem key={category} value={category}>
//                   {category}
//                 </MenuItem>
//               ))}
//             </Select>
//           </FormControl>

//           {/* カラム選択 */}
//           {selectedItemSpecificsCategory && (
//             <FormControl fullWidth sx={{ mt: 2 }}>
//               <InputLabel>適用するカラムを選択</InputLabel>
//               <Select
//                 multiple
//                 value={selectedItemSpecificsColumns}
//                 onChange={(e) => {
//                   const value = e.target.value;
//                   if (value.includes('all')) {
//                     // Handle "select all"
//                     const allColumns = getColumnsForSelectedCategory();
//                     if (selectedItemSpecificsColumns.length === allColumns.length) {
//                       // Deselect all if all are already selected
//                       setSelectedItemSpecificsColumns([]);
//                     } else {
//                       // Select all columns
//                       setSelectedItemSpecificsColumns(allColumns);
//                     }
//                   } else {
//                     setSelectedItemSpecificsColumns(value);
//                   }
//                 }}
//                 renderValue={(selected) => {
//                   if (selected.length === 0) {
//                     return '適用するカラムを選択';
//                   }

//                   const displayedValues = selected.slice(0, 5); // Show up to 5 selected columns
//                   const hiddenCount = selected.length - displayedValues.length;

//                   return (
//                     <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
//                       {displayedValues.map((value) => (
//                         <Chip key={value} label={value} size="small" />
//                       ))}
//                       {hiddenCount > 0 && (
//                         <Chip label={`+${hiddenCount}個`} size="small" />
//                       )}
//                     </Box>
//                   );
//                 }}
//               >
//                 <MenuItem
//                   value="all"
//                   onClick={() => {
//                     const allColumns = getColumnsForSelectedCategory();
//                     if (selectedItemSpecificsColumns.length === allColumns.length) {
//                       // Deselect all if all are already selected
//                       setSelectedItemSpecificsColumns([]);
//                     } else {
//                       // Select all columns
//                       setSelectedItemSpecificsColumns(allColumns);
//                     }
//                   }}
//                 >
//                   <Checkbox
//                     checked={
//                       selectedItemSpecificsColumns.length ===
//                         getColumnsForSelectedCategory().length &&
//                       selectedItemSpecificsColumns.length > 0
//                     }
//                     indeterminate={
//                       selectedItemSpecificsColumns.length > 0 &&
//                       selectedItemSpecificsColumns.length <
//                         getColumnsForSelectedCategory().length
//                     }
//                   />
//                   <Typography variant="body1">全選択</Typography>
//                 </MenuItem>
//                 {getColumnsForSelectedCategory().map((column) => (
//                   <MenuItem key={column} value={column}>
//                     <Checkbox
//                       checked={selectedItemSpecificsColumns.indexOf(column) > -1}
//                     />
//                     <Typography variant="body1">{column}</Typography>
//                   </MenuItem>
//                 ))}
//               </Select>
//             </FormControl>
//           )}

//           {/* マッチングオプション */}
//           {selectedItemSpecificsCategory && (
//             <>
//               <Box sx={{ mt: 2 }}>
//                 <Typography variant="subtitle1" gutterBottom>
//                   マッチングの基準を選択してください。
//                 </Typography>
//                 <FormControl component="fieldset">
//                   <RadioGroup
//                     value={itemSpecificsMatchingOptions.matchSource}
//                     onChange={(e) =>
//                       setItemSpecificsMatchingOptions((prev) => ({
//                         ...prev,
//                         matchSource: e.target.value,
//                       }))
//                     }
//                   >
//                     <FormControlLabel
//                       value="title"
//                       control={<Radio />}
//                       label="タイトルのみ"
//                     />
//                     <FormControlLabel
//                       value="description"
//                       control={<Radio />}
//                       label="商品説明のみ (AI生成部分のみ)"
//                     />
//                     <FormControlLabel
//                       value="both"
//                       control={<Radio />}
//                       label="タイトルと商品説明の両方 (AI生成部分のみ)"
//                     />
//                   </RadioGroup>
//                 </FormControl>
//               </Box>

//               {/* オプション */}
//               <Box sx={{ mt: 2 }}>
//                 <FormControlLabel
//                   control={
//                     <Checkbox
//                       checked={itemSpecificsMatchingOptions.caseSensitive}
//                       onChange={(e) =>
//                         setItemSpecificsMatchingOptions((prev) => ({
//                           ...prev,
//                           caseSensitive: e.target.checked,
//                         }))
//                       }
//                       name="caseSensitive"
//                     />
//                   }
//                   label="大文字小文字を区別する"
//                 />
//                 <FormControlLabel
//                   control={
//                     <Checkbox
//                       checked={itemSpecificsMatchingOptions.partialMatch}
//                       onChange={(e) =>
//                         setItemSpecificsMatchingOptions((prev) => ({
//                           ...prev,
//                           partialMatch: e.target.checked,
//                         }))
//                       }
//                       name="partialMatch"
//                     />
//                   }
//                   label="部分一致を許可する"
//                 />
//               </Box>

//               {/* マッチングの動作を選択 */}
//               <Box sx={{ mt: 2 }}>
//                 <Typography variant="subtitle1" gutterBottom>
//                   マッチングの動作を選択してください。
//                 </Typography>
//                 <Typography variant="subtitle1" gutterBottom>
//                   (上記でAI商品説明からマッチングを選択している場合は、自動処理でAI生成した文章からカラムの適所に適用されます。自動処理によって特に値の変更がなかった場合以下の設定が適用されます。)
//                 </Typography>
//                 <FormControl component="fieldset">
//                   <RadioGroup
//                     value={itemSpecificsMatchingOptions.matchingOption}
//                     onChange={(e) =>
//                       setItemSpecificsMatchingOptions((prev) => ({
//                         ...prev,
//                         matchingOption: e.target.value,
//                       }))
//                     }
//                   >
//                     <FormControlLabel
//                       value="priority"
//                       control={<Radio />}
//                       label="優先順位に基づいて適用"
//                     />
//                     <FormControlLabel
//                       value="all"
//                       control={<Radio />}
//                       label="マッチしたすべての値を適用"
//                     />
//                   </RadioGroup>
//                 </FormControl>
//               </Box>
//             </>
//           )}
//         </Box>
//       )}

//       {/* --- ここまで設定表示 --- */}

//       {/* 実行モードの選択 */}
//       <Box sx={{ mt: 2 }}>
//         <Typography variant="subtitle1">実行モード:</Typography>
//         <Button
//           variant={isImmediate ? 'contained' : 'outlined'}
//           onClick={() => setIsImmediate(true)}
//           sx={{ mr: 1 }}
//         >
//           即時実行
//         </Button>
//         <Button
//           variant={!isImmediate ? 'contained' : 'outlined'}
//           onClick={() => setIsImmediate(false)}
//         >
//           スケジュール実行
//         </Button>
//       </Box>

//       {/* スケジュール時間の選択 */}
//       {!isImmediate && (
//         <Box sx={{ mt: 2 }}>
//           <TextField
//             label="実行開始時間"
//             type="datetime-local"
//             value={scheduledTime}
//             onChange={(e) => setScheduledTime(e.target.value)}
//             InputLabelProps={{
//               shrink: true,
//             }}
//             fullWidth
//           />
//         </Box>
//       )}

//       {/* アップロード＆実行ボタン */}
//       <Button
//         variant="contained"
//         color="secondary"
//         sx={{ mt: 2 }}
//         onClick={handleUploadAndExecute}
//         disabled={isScheduling}
//       >
//         {isScheduling ? '処理中...' : 'アップロードして実行'}
//       </Button>

//       {/* 進捗状況の表示 */}
//       {isScheduling && (
//         <Box sx={{ mt: 2 }}>
//           <Typography variant="h6">処理進捗:</Typography>
//           <Typography>
//             {progress.completedItems} / {progress.totalItems} 件が完了しました
//           </Typography>
//           <LinearProgress
//             variant="determinate"
//             value={(progress.completedItems / progress.totalItems) * 100}
//           />
//         </Box>
//       )}

//       {/* 処理結果のプレビュー */}
//       {processedParsedData.length > 0 && (
//         <Box sx={{ mt: 4 }}>
//           <Typography variant="h6">処理結果のプレビュー:</Typography>
//           <Tabs
//             value={activeTab}
//             onChange={handleTabChange}
//             variant="scrollable"
//             scrollButtons="auto"
//           >
//             {processedParsedData.map((file, index) => (
//               <Tab key={index} label={file.name} />
//             ))}
//           </Tabs>
//           {processedParsedData.map((file, index) => (
//             <div
//               key={index}
//               role="tabpanel"
//               hidden={activeTab !== index}
//               id={`tabpanel-${index}`}
//             >
//               {activeTab === index && (
//                 <Box sx={{ p: 2 }}>
//                   {/* テーブル表示 */}
//                   <Paper elevation={2} sx={{ overflowX: 'auto' }}>
//                     <TableContainer>
//                       <Table
//                         size="small"
//                         sx={{
//                           tableLayout: 'auto',
//                           width: 'auto',
//                         }}
//                       >
//                         <TableHead>
//                           <TableRow>
//                             {file.columns.map((column, idx) => (
//                               <TableCell
//                                 key={idx}
//                                 sx={{
//                                   maxWidth: '150px',
//                                   whiteSpace: 'nowrap',
//                                   padding: '8px',
//                                   border: '1px solid rgba(224, 224, 224, 1)',
//                                   fontWeight: 'bold',
//                                 }}
//                               >
//                                 {column}
//                               </TableCell>
//                             ))}
//                           </TableRow>
//                         </TableHead>
//                         <TableBody>
//                           {file.data.map((row, rowIndex) => (
//                             <TableRow
//                               key={rowIndex}
//                               onClick={() => {
//                                 setSelectedProduct(row);
//                                 setIsDrawerOpen(true);
//                               }}
//                               style={{ cursor: 'pointer' }}
//                             >
//                               {file.columns.map((column, colIndex) => (
//                                 <TableCell
//                                   key={colIndex}
//                                   sx={{
//                                     maxWidth: '150px',
//                                     whiteSpace: 'nowrap',
//                                     padding: '8px',
//                                     border: '1px solid rgba(224, 224, 224, 1)',
//                                   }}
//                                 >
//                                   {column === 'PicURL' ? (
//                                     row[column] ? (
//                                       <img
//                                         src={getFirstImageUrl(row[column])}
//                                         alt="商品画像"
//                                         style={{
//                                           width: '50px',
//                                           height: '50px',
//                                           objectFit: 'cover',
//                                         }}
//                                       />
//                                     ) : (
//                                       'なし'
//                                     )
//                                   ) : (
//                                     row[column]
//                                   )}
//                                 </TableCell>
//                               ))}
//                             </TableRow>
//                           ))}
//                         </TableBody>
//                       </Table>
//                     </TableContainer>
//                   </Paper>

//                   {/* サイドパネル（Drawer） */}
//                   <Drawer
//                     anchor="right"
//                     open={isDrawerOpen}
//                     onClose={() => setIsDrawerOpen(false)}
//                   >
//                     <Box sx={{ width: 700, p: 2 }}>
//                       {selectedProduct ? (
//                         <>
//                           <Typography variant="h6">{selectedProduct['Title']}</Typography>
//                           <ImageGallery picUrlString={selectedProduct['PicURL']} />
//                           <Typography variant="body1">
//                             価格: {selectedProduct['StartPrice']}
//                           </Typography>
//                           <Typography variant="body1">説明:</Typography>
//                           <Box
//                             sx={{ mt: 2 }}
//                             dangerouslySetInnerHTML={{ __html: selectedProduct['Description'] }}
//                           />
//                           {/* 必要に応じて他の詳細情報を追加 */}
//                         </>
//                       ) : (
//                         <Typography variant="body1">商品が選択されていません</Typography>
//                       )}
//                     </Box>

//                   </Drawer>

//                   {/* 画像拡大表示のモーダル */}
//                   <Dialog
//                     open={isImageModalOpen}
//                     onClose={handleCloseImageModal}
//                     maxWidth="lg"
//                   >
//                     <DialogContent>
//                       <img
//                         src={currentImageUrl}
//                         alt="拡大画像"
//                         style={{ width: '100%', height: 'auto', objectFit: 'contain' }}
//                       />
//                     </DialogContent>
//                     <DialogActions>
//                       <Button onClick={handleCloseImageModal}>閉じる</Button>
//                     </DialogActions>
//                   </Dialog>
//                 </Box>
//               )}
//             </div>
//           ))}
//         </Box>
//       )}

//       {/* エラー情報の表示 */}
//       {errorItems.length > 0 && (
//         <Box sx={{ mt: 2 }}>
//           <Typography variant="h6" color="error">
//             エラーが発生したアイテム:
//           </Typography>
//           <List>
//             {errorItems.map((item, index) => (
//               <ListItem key={index}>
//                 <Typography>
//                   ファイル名: {item.fileName}, 行番号: {item.rowIndex}, エラー: {item.error}
//                 </Typography>
//               </ListItem>
//             ))}
//           </List>
//         </Box>
//       )}

//       {/* 処理結果のダウンロード */}
//       {processedData.length > 0 && (
//         <Box sx={{ mt: 2 }}>
//           <Typography variant="h6">処理結果のダウンロード:</Typography>
//           {processedData.map((file, index) => (
//             <Button
//               key={index}
//               variant="contained"
//               sx={{ mt: 1, mr: 1 }}
//               onClick={() => {
//                 const blob = new Blob([file.content], {
//                   type: 'text/csv;charset=utf-8;',
//                 });
//                 const link = document.createElement('a');
//                 link.href = URL.createObjectURL(blob);
//                 link.setAttribute('download', file.name);
//                 document.body.appendChild(link);
//                 link.click();
//                 document.body.removeChild(link);
//               }}
//             >
//               {file.name}
//             </Button>
//           ))}
//         </Box>
//       )}

//       {/* 機能選択ダイアログ */}
//       <Dialog open={functionDialogOpen} onClose={handleFunctionDialogClose}>
//         <DialogTitle>実行する機能を選択</DialogTitle>
//         <DialogContent>
//           <DragDropContext onDragEnd={onDragEnd}>
//             <Droppable droppableId="functions">
//               {(provided) => (
//                 <List {...provided.droppableProps} ref={provided.innerRef}>
//                   {availableFunctions.map((func, index) => (
//                     <Draggable key={func.name} draggableId={func.name} index={index}>
//                       {(provided) => (
//                         <ListItem
//                           button
//                           ref={provided.innerRef}
//                           {...provided.draggableProps}
//                         >
//                           <Checkbox
//                             checked={
//                               selectedFunctions.findIndex((f) => f.name === func.name) !== -1
//                             }
//                             onClick={handleFunctionToggle(func)}
//                           />
//                           <Typography>{func.displayName}</Typography>
//                           <IconButton {...provided.dragHandleProps}>
//                             <DragHandleIcon />
//                           </IconButton>
//                         </ListItem>
//                       )}
//                     </Draggable>
//                   ))}
//                   {provided.placeholder}
//                 </List>
//               )}
//             </Droppable>
//           </DragDropContext>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={handleFunctionDialogClose}>閉じる</Button>
//         </DialogActions>
//       </Dialog>

//       {/* テンプレート保存ダイアログ */}
//       <Dialog
//         open={isSaveTemplateDialogOpen}
//         onClose={() => setIsSaveTemplateDialogOpen(false)}
//       >
//         <DialogTitle>テンプレートの保存</DialogTitle>
//         <DialogContent>
//           <FormControl component="fieldset">
//             <RadioGroup
//               value={saveOption}
//               onChange={(e) => setSaveOption(e.target.value)}
//             >
//               <FormControlLabel
//                 value="new"
//                 control={<Radio />}
//                 label="新しいテンプレートとして保存"
//               />
//               {selectedTemplateId && (
//                 <FormControlLabel
//                   value="overwrite"
//                   control={<Radio />}
//                   label="既存のテンプレートを上書き保存"
//                 />
//               )}
//             </RadioGroup>
//           </FormControl>
//           <TextField
//             label="テンプレート名"
//             value={templateName}
//             onChange={(e) => setTemplateName(e.target.value)}
//             fullWidth
//             sx={{ mt: 2 }}
//           />
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => setIsSaveTemplateDialogOpen(false)}>キャンセル</Button>
//           <Button onClick={saveTemplate} color="primary">
//             保存
//           </Button>
//         </DialogActions>
//       </Dialog>

//       {/* テンプレート管理ダイアログ */}
//       <Dialog
//         open={isManageTemplatesDialogOpen}
//         onClose={() => setIsManageTemplatesDialogOpen(false)}
//       >
//         <DialogTitle>テンプレートの管理</DialogTitle>
//         <DialogContent>
//           <List>
//             {templates.map((template) => (
//               <ListItem key={template.id}>
//                 <ListItemText primary={template.name} />
//                 <IconButton
//                   edge="end"
//                   aria-label="delete"
//                   onClick={() => handleDeleteTemplate(template.id)}
//                 >
//                   <DeleteIcon />
//                 </IconButton>
//               </ListItem>
//             ))}
//           </List>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => setIsManageTemplatesDialogOpen(false)}>閉じる</Button>
//         </DialogActions>
//       </Dialog>

//       {/* Snackbarによる通知 */}
//       <Snackbar
//         open={snackbar.open}
//         autoHideDuration={6000}
//         onClose={() => setSnackbar({ ...snackbar, open: false })}
//       >
//         <Alert
//           onClose={() => setSnackbar({ ...snackbar, open: false })}
//           severity={snackbar.severity}
//           sx={{ width: '100%' }}
//         >
//           {snackbar.message}
//         </Alert>
//       </Snackbar>
//     </Paper>
//   );
// }

// export default CsvSchedulerUploader;







// // CsvSchedulerUploader.js

// import React, { useState, useEffect, useCallback } from 'react';
// import {
//   Box,
//   Button,
//   Typography,
//   TextField,
//   Paper,
//   Snackbar,
//   Alert,
//   List,
//   ListItem,
//   Checkbox,
//   IconButton,
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogTitle,
//   FormControl,
//   InputLabel,
//   Select,
//   MenuItem,
//   Tabs,
//   Tab,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   LinearProgress,
//   Grid,
//   Switch,
//   ListItemText,
//   RadioGroup,
//   Radio,
//   FormControlLabel,
//   Chip,
// } from '@mui/material';
// import { useNavigate } from 'react-router-dom';
// import MenuIcon from '@mui/icons-material/Menu';
// import DragHandleIcon from '@mui/icons-material/DragHandle';
// import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
// import VisibilityIcon from '@mui/icons-material/Visibility';
// import EditIcon from '@mui/icons-material/Edit';
// import MoneyOffIcon from '@mui/icons-material/MoneyOff';
// import ReplayIcon from '@mui/icons-material/Replay';
// import DeleteIcon from '@mui/icons-material/Delete';

// // Firebase のインポート
// import { auth, db } from './firebaseConfig';
// import {
//   getFirestore,
//   getDoc,
//   doc,
//   collection,
//   getDocs,
//   addDoc,
//   setDoc,
//   deleteDoc,
// } from 'firebase/firestore';

// // 機能関数のインポート
// import {
//   applyTemplate,
//   applyItemSpecifics,
// } from './functions';
// // パーサーのインポート
// import Papa from 'papaparse';
// // 価格計算関数のインポート
// import { calculatePrices } from './calculatePrices';
// // AIタイトル生成関数のインポート
// import { applyAITitles } from './applyAITitles';
// // AI商品説明生成関数のインポート
// import { applyAIProductDescriptions } from './applyAIProductDescriptions';
// // CryptoJSのインポート
// import CryptoJS from 'crypto-js';
// // デザインテンプレートのインポート
// import { applyItemSpecificsProcessor } from './ItemSpecificsProcessor';
// import designTemplates from './designTemplates';

// // その他のコンポーネントのインポート
// import OptionsPanel from './OptionsPanel';

// function CsvSchedulerUploader({ user }) {
//   const [csvFiles, setCsvFiles] = useState([]);
//   const [fileContents, setFileContents] = useState([]);
//   const [snackbar, setSnackbar] = useState({
//     open: false,
//     message: '',
//     severity: 'info',
//   });

//   // 状態変数を定義
//   const [selectedTemplate, setSelectedTemplate] = useState('');
//   const [selectedItemSpecificsCategory, setSelectedItemSpecificsCategory] = useState('');
//   const [selectedItemSpecificsColumns, setSelectedItemSpecificsColumns] = useState([]);
//   const [itemSpecificsMatchingOptions, setItemSpecificsMatchingOptions] = useState({
//     caseSensitive: false,
//     partialMatch: true,
//     matchSource: 'title',
//     matchingOption: 'priority',
//   });
//   const [itemSpecificsCategories, setItemSpecificsCategories] = useState({});

//   // デフォルトで全ての機能を選択状態にする
//   const [selectedFunctions, setSelectedFunctions] = useState([]);
//   const [isImmediate, setIsImmediate] = useState(true); // 即時実行かどうか
//   const [scheduledTime, setScheduledTime] = useState(''); // スケジュール時間（文字列）
//   const [functionDialogOpen, setFunctionDialogOpen] = useState(false);
//   const [isScheduling, setIsScheduling] = useState(false); // スケジュール処理中かどうか
//   const [processedData, setProcessedData] = useState([]); // 処理後のデータ
//   const [activeTab, setActiveTab] = useState(0); // アクティブなタブのインデックス

//   const [templateList, setTemplateList] = useState([]);
//   const [selectedTemplateName, setSelectedTemplateName] = useState('');

//   // 価格設定の状態管理
//   const [selectedPriceSettings, setSelectedPriceSettings] = useState(null);

//   // 送料テンプレートの状態管理
//   const [shippingRateTemplates, setShippingRateTemplates] = useState([]);
//   const [selectedShippingTemplateId, setSelectedShippingTemplateId] = useState('');

//   // その他の設定
//   const [exchangeRate, setExchangeRate] = useState(0);
//   const [targetProfitMargin, setTargetProfitMargin] = useState(0);
//   const [fees, setFees] = useState({});
//   const [bestOfferSettings, setBestOfferSettings] = useState({});

//   // ユーザーのAPIキーの状態管理
//   const [userApiKey, setUserApiKey] = useState('');

//   // ユーザー設定の状態管理
//   const [userSettings, setUserSettings] = useState({});

//   // デザインテンプレートの選択状態
//   const [designTemplateNames, setDesignTemplateNames] = useState([]);
//   const [selectedDesignTemplate, setSelectedDesignTemplate] = useState('');

//   // テンプレート管理の状態
//   const [templates, setTemplates] = useState([]);
//   const [templateName, setTemplateName] = useState('');
//   const [isSaveTemplateDialogOpen, setIsSaveTemplateDialogOpen] = useState(false);
//   const [isManageTemplatesDialogOpen, setIsManageTemplatesDialogOpen] = useState(false);
//   const [selectedTemplateId, setSelectedTemplateId] = useState('');
//   const [currentTemplateName, setCurrentTemplateName] = useState('');
//   const [saveOption, setSaveOption] = useState('new');

//   // その他の状態
//   const [useImageDescription, setUseImageDescription] = useState(false);
//   const [mandatoryKeywords, setMandatoryKeywords] = useState([]);
//   const [mandatoryKeywordInput, setMandatoryKeywordInput] = useState('');

//   // 新しく追加する状態変数
//   const [sentenceToDeleteInput, setSentenceToDeleteInput] = useState('');
//   const [wordToReplaceFrom, setWordToReplaceFrom] = useState('');
//   const [wordToReplaceTo, setWordToReplaceTo] = useState('');

//   const navigate = useNavigate();

//   // ユーザー固有の秘密鍵を生成
//   const generateEncryptionKey = (uid) => {
//     const salt = 'your-fixed-salt-value'; // 固定の塩値（セキュリティ向上のため、環境変数で管理することを推奨）
//     return CryptoJS.PBKDF2(uid, salt, { keySize: 256 / 32 }).toString();
//   };

//   // 進捗状況の状態
//   const [progress, setProgress] = useState({ totalItems: 0, completedItems: 0 });
//   const [errorItems, setErrorItems] = useState([]);

//   // availableFunctionsの定義をここに移動
//   const [availableFunctions, setAvailableFunctions] = useState([
//     {
//       name: 'applyTemplate',
//       displayName: 'テンプレートを実行する',
//       func: applyTemplate,
//     },
//     {
//       name: 'applyPriceChanges',
//       displayName: '価格の変更を適用する',
//       func: 'applyPriceChanges', // 後で設定
//     },
//     {
//       name: 'applyAITitles',
//       displayName: 'AIタイトルを適用する',
//       func: 'applyAITitles', // 後で設定
//     },
//     {
//       name: 'applyAIProductDescriptions',
//       displayName: 'AI商品説明を適用する',
//       func: 'applyAIProductDescriptions', // 後で設定
//     },
//     {
//       name: 'applyItemSpecifics',
//       displayName: 'Item Specificsの適用をする',
//       func: applyItemSpecifics,
//     },
//   ]);

//   useEffect(() => {
//     const fetchItemSpecificsSettings = async () => {
//       if (!user) return;

//       try {
//         const db = getFirestore();
//         const docRef = doc(db, 'itemSpecificsSettings', user.uid);
//         const docSnap = await getDoc(docRef);

//         if (docSnap.exists()) {
//           const settingsData = docSnap.data();
//           setItemSpecificsCategories(settingsData.categories || {});
//         } else {
//           console.warn('Item Specificsの設定が見つかりませんでした。');
//         }
//       } catch (error) {
//         console.error('Item Specificsの設定取得中にエラーが発生しました:', error);
//       }
//     };

//     fetchItemSpecificsSettings();
//   }, [user]);

//   // Function to get columns for the selected category
//   const getColumnsForSelectedCategory = () => {
//     if (
//       selectedItemSpecificsCategory &&
//       itemSpecificsCategories[selectedItemSpecificsCategory]
//     ) {
//       return Object.keys(
//         itemSpecificsCategories[selectedItemSpecificsCategory].columns || {}
//       );
//     }
//     return [];
//   };

//   // ユーザーが認証されていない場合はログインページへリダイレクト
//   useEffect(() => {
//     if (!user) {
//       navigate('/login');
//     } else {
//       // テンプレート一覧を取得
//       const fetchTemplates = async () => {
//         try {
//           const docRef = doc(db, 'userTemplates', user.uid);
//           const docSnap = await getDoc(docRef);

//           if (docSnap.exists()) {
//             const userData = docSnap.data();
//             const userCategories = userData.categories || {};
//             const templates = Object.values(userCategories)
//               .flat()
//               .map((template) => ({
//                 name: template.name,
//                 operations: template.operations,
//               }));
//             setTemplateList(templates);
//             if (templates.length > 0) {
//               setSelectedTemplateName(templates[0].name);
//             }
//           }
//         } catch (error) {
//           console.error('テンプレートの取得中にエラーが発生しました:', error);
//           setSnackbar({
//             open: true,
//             message: 'テンプレートの取得に失敗しました。',
//             severity: 'error',
//           });
//         }
//       };
//       fetchTemplates();

//       // ユーザー設定とAPIキーを取得
//       const fetchUserSettings = async () => {
//         try {
//           // ユーザー設定を取得
//           const docRef = doc(db, 'userSettings', user.uid);
//           const docSnap = await getDoc(docRef);

//           if (docSnap.exists()) {
//             const userSettingsData = docSnap.data();
//             setSelectedPriceSettings(userSettingsData);
//             setUserSettings(userSettingsData); // ユーザー設定を保存

//             // 送料テンプレートを設定
//             if (userSettingsData.shippingRateTemplates) {
//               setShippingRateTemplates(userSettingsData.shippingRateTemplates);
//               setSelectedShippingTemplateId(
//                 userSettingsData.selectedShippingTemplateId ||
//                   userSettingsData.shippingRateTemplates[0].id
//               );
//             }

//             // その他の設定を取得
//             setExchangeRate(userSettingsData.exchangeRate || 0);
//             setTargetProfitMargin(userSettingsData.targetProfitMargin || 0);
//             setFees(userSettingsData.fees || {});
//             setBestOfferSettings({
//               bestOfferAutoAcceptPercentage:
//                 userSettingsData.bestOfferAutoAcceptPercentage || 0,
//               minimumBestOfferPercentage:
//                 userSettingsData.minimumBestOfferPercentage || 0,
//             });

//             // デザインテンプレート名の取得と初期設定
//             if (userSettingsData.designTemplates) {
//               const templateNames = Object.keys(userSettingsData.designTemplates);
//               setDesignTemplateNames(templateNames);
//               if (templateNames.length > 0) {
//                 setSelectedDesignTemplate(templateNames[0]);
//               } else {
//                 console.warn('デザインテンプレートが設定されていません。');
//                 // デフォルトのテンプレート名を設定
//                 setSelectedDesignTemplate('defaultTemplate');
//               }
//             } else {
//               console.warn('デザインテンプレートが設定されていません。');
//               // デフォルトのテンプレート名を設定
//               setSelectedDesignTemplate('simple');
//             }

//             // カスタムフィルターや削除したい文章などの取得
//             setCustomFilters(userSettingsData.customFilters || []);
//             setSentencesToDelete(userSettingsData.sentencesToDelete || []);
//             setWordsToReplace(userSettingsData.wordsToReplace || []);
//             setMandatoryKeywords(userSettingsData.mandatoryKeywords || []);
//           } else {
//             console.warn('ユーザー設定が見つかりませんでした');
//           }

//           // APIキーを取得
//           const apiKeyDocRef = doc(db, 'userApiKeys', user.uid);
//           const apiKeyDocSnap = await getDoc(apiKeyDocRef);

//           if (apiKeyDocSnap.exists()) {
//             const encryptedKey = apiKeyDocSnap.data().apiKey;
//             const encryptionKey = generateEncryptionKey(user.uid);
//             const bytes = CryptoJS.AES.decrypt(encryptedKey, encryptionKey);
//             const decryptedKey = bytes.toString(CryptoJS.enc.Utf8);
//             if (decryptedKey) {
//               setUserApiKey(decryptedKey);
//             } else {
//               console.error('APIキーの復号に失敗しました。');
//             }
//           } else {
//             console.warn('ユーザーのAPIキーが見つかりませんでした');
//           }

//           // ユーザーのテンプレートをロード
//           loadTemplates();
//         } catch (error) {
//           console.error('ユーザー設定の取得中にエラーが発生しました:', error);
//           setSnackbar({
//             open: true,
//             message: 'ユーザー設定の取得に失敗しました。',
//             severity: 'error',
//           });
//         }
//       };
//       fetchUserSettings();
//     }
//   }, [user, navigate]);

//   // カスタムフィルターやテキスト変換の状態管理
//   const [customFilters, setCustomFilters] = useState([]);
//   const [sentencesToDelete, setSentencesToDelete] = useState([]);
//   const [wordsToReplace, setWordsToReplace] = useState([]);

//   // フィルタリングオプションのステート
//   const [selectedFilters, setSelectedFilters] = useState([]);
//   const [customFilterInput, setCustomFilterInput] = useState('');

//   // テンプレートの保存
//   const saveTemplate = async () => {
//     if (!templateName.trim()) {
//       setSnackbar({
//         open: true,
//         message: 'テンプレート名を入力してください',
//         severity: 'warning',
//       });
//       return;
//     }

//     const templateData = {
//       name: templateName.trim(),
//       customFilters,
//       sentencesToDelete,
//       wordsToReplace,
//       mandatoryKeywords,
//       selectedFilters,
//     };

//     try {
//       const userTemplatesRef = collection(db, 'users', user.uid, 'templates');

//       if (saveOption === 'overwrite' && selectedTemplateId) {
//         const templateDocRef = doc(userTemplatesRef, selectedTemplateId);
//         await setDoc(templateDocRef, templateData);
//         setSnackbar({
//           open: true,
//           message: 'テンプレートが更新されました',
//           severity: 'success',
//         });
//       } else {
//         await addDoc(userTemplatesRef, templateData);
//         setSnackbar({
//           open: true,
//           message: '新しいテンプレートが保存されました',
//           severity: 'success',
//         });
//       }

//       setIsSaveTemplateDialogOpen(false);
//       setTemplateName('');
//       loadTemplates();
//     } catch (error) {
//       console.error('テンプレートの保存中にエラーが発生しました:', error);
//       setSnackbar({
//         open: true,
//         message: 'テンプレートの保存中にエラーが発生しました',
//         severity: 'error',
//       });
//     }
//   };

//   // テンプレートの読み込み
//   const loadTemplates = async () => {
//     try {
//       const userTemplatesRef = collection(db, 'users', user.uid, 'templates');
//       const templatesSnapshot = await getDocs(userTemplatesRef);
//       const templatesData = templatesSnapshot.docs.map((doc) => ({
//         id: doc.id,
//         ...doc.data(),
//       }));
//       setTemplates(templatesData);
//     } catch (error) {
//       console.error('テンプレートの読み込み中にエラーが発生しました:', error);
//     }
//   };

//   // テンプレートの適用
//   const handleLoadTemplate = async (templateId) => {
//     if (!templateId) {
//       setSelectedTemplateId('');
//       setTemplateName('');
//       setCurrentTemplateName('');
//       return;
//     }
//     setSelectedTemplateId(templateId);
//     try {
//       const templateDocRef = doc(db, 'users', user.uid, 'templates', templateId);
//       const templateDoc = await getDoc(templateDocRef);
//       if (templateDoc.exists()) {
//         const templateData = templateDoc.data();
//         setTemplateName(templateData.name || '');
//         setCurrentTemplateName(templateData.name || '');
//         setCustomFilters(templateData.customFilters || []);
//         setSentencesToDelete(templateData.sentencesToDelete || []);
//         setWordsToReplace(templateData.wordsToReplace || []);
//         setMandatoryKeywords(templateData.mandatoryKeywords || []);
//         setSelectedFilters(templateData.selectedFilters || []);
//         setSnackbar({
//           open: true,
//           message: 'テンプレートが適用されました',
//           severity: 'success',
//         });
//       } else {
//         setSnackbar({
//           open: true,
//           message: 'テンプレートが見つかりません',
//           severity: 'error',
//         });
//       }
//     } catch (error) {
//       console.error('テンプレートの読み込み中にエラーが発生しました:', error);
//       setSnackbar({
//         open: true,
//         message: 'テンプレートの読み込み中にエラーが発生しました',
//         severity: 'error',
//       });
//     }
//   };

//   // テンプレートの削除
//   const handleDeleteTemplate = async (templateId) => {
//     try {
//       const templateDocRef = doc(db, 'users', user.uid, 'templates', templateId);
//       await deleteDoc(templateDocRef);
//       setSnackbar({
//         open: true,
//         message: 'テンプレートが削除されました',
//         severity: 'success',
//       });
//       loadTemplates();
//     } catch (error) {
//       console.error('テンプレートの削除中にエラーが発生しました:', error);
//       setSnackbar({
//         open: true,
//         message: 'テンプレートの削除中にエラーが発生しました',
//         severity: 'error',
//       });
//     }
//   };

//   // 新しいテンプレートを作成
//   const handleCreateNewTemplate = () => {
//     setSelectedTemplateId('');
//     setTemplateName('');
//     setCurrentTemplateName('');
//     setCustomFilters([]);
//     setSelectedFilters([]);
//     setSentencesToDelete([]);
//     setWordsToReplace([]);
//     setMandatoryKeywords([]);
//     setSnackbar({
//       open: true,
//       message: '新しいテンプレートを作成します',
//       severity: 'info',
//     });
//   };

//   // 必須キーワードの追加・削除
//   const handleAddMandatoryKeyword = () => {
//     if (mandatoryKeywordInput.trim() !== '') {
//       setMandatoryKeywords([...mandatoryKeywords, mandatoryKeywordInput.trim()]);
//       setMandatoryKeywordInput('');
//     }
//   };

//   const handleDeleteMandatoryKeyword = (index) => {
//     const updatedKeywords = [...mandatoryKeywords];
//     updatedKeywords.splice(index, 1);
//     setMandatoryKeywords(updatedKeywords);
//   };

//   // 削除したい文章の追加・削除
//   const handleAddSentenceToDelete = () => {
//     if (sentenceToDeleteInput.trim() !== '') {
//       setSentencesToDelete([...sentencesToDelete, sentenceToDeleteInput.trim()]);
//       setSentenceToDeleteInput('');
//     }
//   };

//   const handleDeleteSentenceToDelete = (index) => {
//     const updatedSentences = [...sentencesToDelete];
//     updatedSentences.splice(index, 1);
//     setSentencesToDelete(updatedSentences);
//   };

//   // 置換単語の追加・削除
//   const handleAddWordToReplace = () => {
//     if (wordToReplaceFrom.trim() !== '' && wordToReplaceTo.trim() !== '') {
//       setWordsToReplace([
//         ...wordsToReplace,
//         { from: wordToReplaceFrom.trim(), to: wordToReplaceTo.trim() },
//       ]);
//       setWordToReplaceFrom('');
//       setWordToReplaceTo('');
//     }
//   };

//   const handleDeleteWordToReplace = (index) => {
//     const updatedWords = [...wordsToReplace];
//     updatedWords.splice(index, 1);
//     setWordsToReplace(updatedWords);
//   };

//   // タイトルカスタマイズの状態管理
//   const [deleteStrings, setDeleteStrings] = useState([]);
//   const [replacePairs, setReplacePairs] = useState([]);
//   const [prependText, setPrependText] = useState('');
//   const [appendText, setAppendText] = useState('');
//   const [limitTitleLength, setLimitTitleLength] = useState(false);

//   // 置換ペアの管理関数
//   const handleAddReplacePair = () => {
//     setReplacePairs([...replacePairs, { from: '', to: '' }]);
//   };

//   const handleReplacePairChange = (index, field, value) => {
//     const newPairs = [...replacePairs];
//     newPairs[index][field] = value;
//     setReplacePairs(newPairs);
//   };

//   const handleRemoveReplacePair = (index) => {
//     const newPairs = [...replacePairs];
//     newPairs.splice(index, 1);
//     setReplacePairs(newPairs);
//   };

//   // ファイル選択時の処理
//   const handleFileChange = (event) => {
//     const files = Array.from(event.target.files).slice(0, 5); // 最大5つまで選択
//     setCsvFiles(files);
//     // ファイルの内容を読み込む
//     Promise.all(files.map((file) => file.text())).then((contents) => {
//       setFileContents(contents);
//     });
//   };

//   // 機能選択ダイアログの開閉
//   const handleFunctionDialogOpen = () => {
//     setFunctionDialogOpen(true);
//   };

//   const handleFunctionDialogClose = () => {
//     setFunctionDialogOpen(false);
//   };

//   // 機能の選択・解除
//   const handleFunctionToggle = (func) => () => {
//     const currentIndex = selectedFunctions.findIndex((f) => f.name === func.name);
//     const newSelected = [...selectedFunctions];

//     if (currentIndex === -1) {
//       newSelected.push(func);
//     } else {
//       newSelected.splice(currentIndex, 1);
//     }

//     setSelectedFunctions(newSelected);
//   };

//   // 機能の順序変更（ドラッグ＆ドロップ）
//   const onDragEnd = (result) => {
//     if (!result.destination) return;

//     const newFunctions = Array.from(selectedFunctions);
//     const [removed] = newFunctions.splice(result.source.index, 1);
//     newFunctions.splice(result.destination.index, 0, removed);

//     setSelectedFunctions(newFunctions);
//   };

//   // 価格適用関数をメモ化
//   const applyPriceChanges = useCallback(
//     async (currentData) => {
//       try {
//         if (!selectedPriceSettings) {
//           throw new Error('価格設定が取得されていません。');
//         }

//         // 選択された送料テンプレートを設定に反映
//         const updatedPriceSettings = {
//           ...selectedPriceSettings,
//           selectedShippingTemplateId,
//         };

//         const updatedData = await Promise.all(
//           currentData.map(async (file) => {
//             // CSVデータをパース
//             const parsedResult = Papa.parse(file.content, { header: true });
//             const data = parsedResult.data;

//             // 価格計算を実行
//             const newPrices = calculatePrices(
//               data,
//               updatedPriceSettings,
//               selectedShippingTemplateId
//             );

//             // 計算結果を元にデータを更新
//             const updatedData = data.map((item, index) => {
//               const calculatedPrice = newPrices[index];
//               if (calculatedPrice) {
//                 return {
//                   ...item,
//                   StartPrice: calculatedPrice.discountedPrice,
//                   BestOfferAutoAcceptPrice: calculatedPrice.bestOfferAutoAcceptPrice,
//                   MinimumBestOfferPrice: calculatedPrice.minimumBestOfferPrice,
//                 };
//               } else {
//                 return item;
//               }
//             });

//             // CSVに変換
//             const csvContent = Papa.unparse(updatedData);

//             return {
//               ...file,
//               content: csvContent,
//             };
//           })
//         );

//         return updatedData;
//       } catch (error) {
//         console.error('価格適用中にエラーが発生しました:', error);
//         setSnackbar({
//           open: true,
//           message: '価格適用中にエラーが発生しました。',
//           severity: 'error',
//         });
//         throw error;
//       }
//     },
//     [selectedPriceSettings, selectedShippingTemplateId, setSnackbar]
//   );

//   // applyAITitlesをラップしてAPIキーを渡す（メモ化）
//   const applyAITitlesWrapper = useCallback(
//     async (currentData) => {
//       try {
//         if (!userApiKey) {
//           setSnackbar({
//             open: true,
//             message:
//               'OpenAI APIキーが設定されていません。ユーザー設定でAPIキーを入力してください。',
//             severity: 'error',
//           });
//           throw new Error('OpenAI APIキーが設定されていません。');
//         }

//         // 進捗状況の初期化
//         setProgress({ totalItems: 0, completedItems: 0 });
//         setErrorItems([]);

//         // カスタマイズ設定を取得
//         const customizationOptions = {
//           deleteStrings,
//           replacePairs,
//           prependText,
//           appendText,
//           limitTitleLength,
//         };

//         const { updatedData, errorItems } = await applyAITitles(
//           currentData,
//           userApiKey,
//           (progressData) => {
//             setProgress(progressData);
//           },
//           customizationOptions // カスタマイズ設定を渡す
//         );

//         // エラー情報を保存
//         setErrorItems(errorItems);

//         return updatedData;
//       } catch (error) {
//         console.error('AIタイトル適用中にエラーが発生しました:', error);
//         setSnackbar({
//           open: true,
//           message: 'AIタイトル適用中にエラーが発生しました。',
//           severity: 'error',
//         });
//         throw error;
//       }
//     },
//     [
//       userApiKey,
//       setSnackbar,
//       setProgress,
//       setErrorItems,
//       deleteStrings,
//       replacePairs,
//       prependText,
//       appendText,
//       limitTitleLength,
//     ]
//   );

//   // applyAIProductDescriptionsをラップしてAPIキーとユーザー設定を渡す（メモ化）
//   const applyAIProductDescriptionsWrapper = useCallback(
//     async (currentData) => {
//       try {
//         if (!userApiKey) {
//           setSnackbar({
//             open: true,
//             message:
//               'OpenAI APIキーが設定されていません。ユーザー設定でAPIキーを入力してください。',
//             severity: 'error',
//           });
//           throw new Error('OpenAI APIキーが設定されていません。');
//         }

//         // 進捗状況の初期化
//         setProgress({ totalItems: 0, completedItems: 0 });
//         setErrorItems([]);

//         const { updatedData, errorItems } = await applyAIProductDescriptions(
//           currentData,
//           userApiKey,
//           (progressData) => {
//             setProgress(progressData);
//           },
//           selectedDesignTemplate, // デザインテンプレートを渡す
//           {
//             customFilters,
//             selectedFilters,
//             sentencesToDelete,
//             wordsToReplace,
//             mandatoryKeywords,
//           } // ユーザー設定を渡す
//         );

//         // エラー情報を保存
//         setErrorItems(errorItems);

//         return updatedData;
//       } catch (error) {
//         console.error('AI商品説明適用中にエラーが発生しました:', error);
//         setSnackbar({
//           open: true,
//           message: 'AI商品説明適用中にエラーが発生しました。',
//           severity: 'error',
//         });
//         throw error;
//       }
//     },
//     [
//       userApiKey,
//       setSnackbar,
//       setProgress,
//       setErrorItems,
//       selectedDesignTemplate,
//       customFilters,
//       selectedFilters,
//       sentencesToDelete,
//       wordsToReplace,
//       mandatoryKeywords,
//     ]
//   );

//   // applyItemSpecificsWrapperをメモ化
//   const applyItemSpecificsWrapper = useCallback(
//     async (currentData, options) => {
//       const {
//         selectedItemSpecificsCategory,
//         selectedItemSpecificsColumns,
//         itemSpecificsMatchingOptions,
//       } = options;
//       try {
//         if (!user) {
//           setSnackbar({
//             open: true,
//             message: 'ユーザー情報が取得できません。',
//             severity: 'error',
//           });
//           throw new Error('ユーザー情報が取得できません。');
//         }

//         if (!selectedItemSpecificsCategory) {
//           setSnackbar({
//             open: true,
//             message: '適用するカテゴリーを選択してください。',
//             severity: 'warning',
//           });
//           throw new Error('適用するカテゴリーを選択してください。');
//         }

//         // 進捗状況の初期化
//         setProgress({ totalItems: 0, completedItems: 0 });

//         console.log(
//           'applyItemSpecificsWrapper内のselectedItemSpecificsCategory:',
//           selectedItemSpecificsCategory
//         );

//         const updatedData = await applyItemSpecificsProcessor(
//           currentData,
//           user.uid,
//           {
//             selectedCategory: selectedItemSpecificsCategory,
//             selectedColumns: selectedItemSpecificsColumns,
//             matchingOptions: itemSpecificsMatchingOptions,
//           },
//           (progressData) => {
//             setProgress(progressData);
//           }
//         );

//         return updatedData;
//       } catch (error) {
//         console.error('Item Specifics適用中にエラーが発生しました:', error);
//         setSnackbar({
//           open: true,
//           message: error.message || 'Item Specifics適用中にエラーが発生しました。',
//           severity: 'error',
//         });
//         throw error;
//       }
//     },
//     [user, setSnackbar, setProgress]
//   );

//   // availableFunctions の 'applyPriceChanges' と 'applyAITitles' と 'applyAIProductDescriptions' の func を更新
//   useEffect(() => {
//     setAvailableFunctions((prevFunctions) =>
//       prevFunctions.map((func) => {
//         if (func.name === 'applyPriceChanges') {
//           return { ...func, func: applyPriceChanges };
//         }
//         if (func.name === 'applyAITitles') {
//           return { ...func, func: applyAITitlesWrapper };
//         }
//         if (func.name === 'applyAIProductDescriptions') {
//           return { ...func, func: applyAIProductDescriptionsWrapper };
//         }
//         return func;
//       })
//     );
//   }, [applyPriceChanges, applyAITitlesWrapper, applyAIProductDescriptionsWrapper]);

//   // applyItemSpecificsの関数も設定
//   useEffect(() => {
//     setAvailableFunctions((prevFunctions) =>
//       prevFunctions.map((func) =>
//         func.name === 'applyItemSpecifics'
//           ? { ...func, func: applyItemSpecificsWrapper }
//           : func
//       )
//     );
//   }, [applyItemSpecificsWrapper]);

//   // アップロードおよび実行処理
//   const handleUploadAndExecute = async () => {
//     if (!user) {
//       setSnackbar({
//         open: true,
//         message: 'ユーザーが認証されていません。',
//         severity: 'error',
//       });
//       return;
//     }

//     if (csvFiles.length === 0) {
//       setSnackbar({
//         open: true,
//         message: 'アップロードするファイルがありません。',
//         severity: 'warning',
//       });
//       return;
//     }

//     if (selectedFunctions.length === 0) {
//       setSnackbar({
//         open: true,
//         message: '実行する機能を選択してください。',
//         severity: 'warning',
//       });
//       return;
//     }

//     setIsScheduling(true);

//     try {
//       // ファイル名と内容を組み合わせたデータ
//       const filesData = csvFiles.map((file, index) => ({
//         name: file.name,
//         content: fileContents[index],
//       }));

//       const executeFunctions = async () => {
//         let currentData = filesData; // 初期データ

//         for (const func of selectedFunctions) {
//           try {
//             if (func.func.constructor.name === 'AsyncFunction') {
//               if (func.name === 'applyTemplate') {
//                 currentData = await func.func(currentData, user, selectedTemplateName); // user とテンプレート名を渡す
//               } else if (func.name === 'applyItemSpecifics') {
//                 // ここで最新の状態を渡す
//                 currentData = await func.func(currentData, {
//                   selectedItemSpecificsCategory,
//                   selectedItemSpecificsColumns,
//                   itemSpecificsMatchingOptions,
//                 });
//               } else {
//                 currentData = await func.func(currentData);
//               }
//             } else {
//               if (func.name === 'applyTemplate') {
//                 currentData = func.func(currentData, user, selectedTemplateName); // user とテンプレート名を渡す
//               } else if (func.name === 'applyItemSpecifics') {
//                 // ここで最新の状態を渡す
//                 currentData = func.func(currentData, {
//                   selectedItemSpecificsCategory,
//                   selectedItemSpecificsColumns,
//                   itemSpecificsMatchingOptions,
//                 });
//               } else {
//                 currentData = func.func(currentData);
//               }
//             }
//           } catch (error) {
//             console.error(`機能 "${func.displayName}" の実行中にエラーが発生しました:`, error);
//             setSnackbar({
//               open: true,
//               message: `機能 "${func.displayName}" の実行中にエラーが発生しました。`,
//               severity: 'error',
//             });
//             setIsScheduling(false);
//             return;
//           }
//         }

//         // 処理結果を状態に保存
//         setProcessedData(currentData);

//         setSnackbar({
//           open: true,
//           message: '選択された機能を実行しました。',
//           severity: 'success',
//         });
//         setIsScheduling(false);
//       };

//       if (isImmediate) {
//         // 即時実行の場合
//         await executeFunctions();
//       } else {
//         // スケジュール実行の場合
//         const now = new Date();
//         const scheduledDateTime = new Date(scheduledTime);
//         const delay = scheduledDateTime - now;

//         if (delay > 0) {
//           setSnackbar({
//             open: true,
//             message: 'タスクがスケジュールされました。',
//             severity: 'success',
//           });
//           setTimeout(async () => {
//             await executeFunctions();
//           }, delay);
//         } else {
//           setSnackbar({
//             open: true,
//             message: '指定された時間は過去です。正しい時間を選択してください。',
//             severity: 'error',
//           });
//           setIsScheduling(false);
//         }
//       }
//     } catch (error) {
//       console.error('処理中にエラーが発生しました:', error);
//       setSnackbar({
//         open: true,
//         message: '処理中にエラーが発生しました。',
//         severity: 'error',
//       });
//       setIsScheduling(false);
//     }
//   };

//   // タブの変更
//   const handleTabChange = (event, newValue) => {
//     setActiveTab(newValue);
//   };

//   // CSVデータをパースして状態に保存
//   const [parsedData, setParsedData] = useState([]);

//   useEffect(() => {
//     const parseCSVFiles = () => {
//       const data = fileContents.map((content) => {
//         const parsedResult = Papa.parse(content, { header: true });
//         return {
//           data: parsedResult.data,
//           columns: parsedResult.meta.fields,
//         };
//       });
//       setParsedData(data);
//     };

//     if (fileContents.length > 0) {
//       parseCSVFiles();
//     } else {
//       setParsedData([]);
//     }
//   }, [fileContents]);

//   // 処理後のデータをプレビュー
//   const [processedParsedData, setProcessedParsedData] = useState([]);

//   useEffect(() => {
//     if (processedData.length > 0) {
//       const data = processedData.map((file) => {
//         const parsedResult = Papa.parse(file.content, { header: true });
//         return {
//           name: file.name,
//           data: parsedResult.data,
//           columns: parsedResult.meta.fields,
//         };
//       });
//       setProcessedParsedData(data);
//     } else {
//       setProcessedParsedData([]);
//     }
//   }, [processedData]);

//   // フィルターの変更ハンドラー
//   const handleFilterChange = (label) => {
//     setSelectedFilters((prev) =>
//       prev.includes(label) ? prev.filter((item) => item !== label) : [...prev, label]
//     );
//   };

//   // カスタムフィルターの追加・削除
//   const handleAddCustomFilter = () => {
//     if (customFilterInput.trim() !== '') {
//       setCustomFilters([...customFilters, customFilterInput.trim()]);
//       setCustomFilterInput('');
//     }
//   };

//   const handleDeleteCustomFilter = (index) => {
//     const updatedFilters = [...customFilters];
//     updatedFilters.splice(index, 1);
//     setCustomFilters(updatedFilters);
//   };


//   return (
//     <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
//       <Typography variant="h6" gutterBottom>
//         CSVファイルのアップロードとタスクのスケジュール
//       </Typography>

//       {/* ファイル選択 */}
//       <Button variant="contained" component="label">
//         ファイルを選択（最大5つまで）
//         <input
//           type="file"
//           accept=".csv"
//           multiple
//           hidden
//           onChange={handleFileChange}
//         />
//       </Button>

//       {csvFiles.length > 0 && (
//         <Box sx={{ mt: 2 }}>
//           <Typography variant="subtitle1">選択されたファイル:</Typography>
//           <List>
//             {csvFiles.map((file, index) => (
//               <ListItem key={index}>
//                 <Typography>{file.name}</Typography>
//               </ListItem>
//             ))}
//           </List>
//         </Box>
//       )}

//       {/* 機能選択 */}
//       <Button
//         variant="contained"
//         color="primary"
//         sx={{ mt: 2 }}
//         onClick={handleFunctionDialogOpen}
//         startIcon={<MenuIcon />}
//       >
//         実行する機能を選択
//       </Button>

//       {/* --- ここから設定表示を指定された順序で配置します --- */}

//       {/* 1. テンプレート適用 */}
//       {selectedFunctions.some((func) => func.name === 'applyTemplate') && (
//         <Box sx={{ mt: 2 }}>
//           <Typography variant="h6">1. テンプレート適用</Typography>
//           <FormControl fullWidth sx={{ mt: 1 }}>
//             <InputLabel>テンプレートを選択</InputLabel>
//             <Select
//               value={selectedTemplateName}
//               onChange={(e) => setSelectedTemplateName(e.target.value)}
//             >
//               {templateList.map((template) => (
//                 <MenuItem key={template.name} value={template.name}>
//                   {template.name}
//                 </MenuItem>
//               ))}
//             </Select>
//           </FormControl>
//         </Box>
//       )}

//       {/* 2. 価格機能 */}
//       {selectedFunctions.some((func) => func.name === 'applyPriceChanges') && (
//         <Box sx={{ mt: 2 }}>
//           <Typography variant="h6">2. 価格機能</Typography>
//           {shippingRateTemplates.length > 0 && (
//             <FormControl fullWidth sx={{ mt: 1 }}>
//               <InputLabel>送料設定を選択</InputLabel>
//               <Select
//                 value={selectedShippingTemplateId}
//                 onChange={(e) => setSelectedShippingTemplateId(e.target.value)}
//               >
//                 {shippingRateTemplates.map((template) => (
//                   <MenuItem key={template.id} value={template.id}>
//                     {template.name}
//                   </MenuItem>
//                 ))}
//               </Select>
//             </FormControl>
//           )}
//           <Paper elevation={2} sx={{ p: 2, mt: 2 }}>
//             <Typography variant="subtitle1">現在の価格設定</Typography>
//             <Typography>為替レート: 1 USD = {exchangeRate} JPY</Typography>
//             <Typography>目標利益率: {targetProfitMargin}%</Typography>
//             <Typography>
//               eBay最終価値手数料: {fees.ebayFinalValue || 0}%
//             </Typography>
//             <Typography>Payoneer手数料: {fees.payoneer || 0}%</Typography>
//             <Typography>
//               広告出品手数料: {fees.promotedListing || 0}%
//             </Typography>
//             <Typography>
//               eBay取引手数料: {fees.ebayTransactionFee || 0} USD
//             </Typography>
//             <Typography>
//               ベストオファー自動承認価格:{' '}
//               {bestOfferSettings.bestOfferAutoAcceptPercentage || 0}%
//             </Typography>
//             <Typography>
//               最小ベストオファー価格:{' '}
//               {bestOfferSettings.minimumBestOfferPercentage || 0}%
//             </Typography>
//           </Paper>
//         </Box>
//       )}

//       {/* 3. AIタイトル */}
//       {selectedFunctions.some((func) => func.name === 'applyAITitles') && (
//         <Box sx={{ mt: 2 }}>
//           <Typography variant="h6">3. AIタイトル</Typography>
//           {/* カスタマイズ設定のUIを追加 */}
//           <Box sx={{ mt: 2 }}>
//             <Typography variant="subtitle1">タイトルカスタマイズ設定</Typography>

//             {/* 削除したい単語 */}
//             <TextField
//               label="削除したい単語 (カンマ区切り)"
//               value={deleteStrings.join(', ')}
//               onChange={(e) => setDeleteStrings(e.target.value.split(',').map(s => s.trim()))}
//               fullWidth
//               sx={{ mt: 1 }}
//             />

//             {/* 置換ペア */}
//             <Box sx={{ mt: 2 }}>
//               <Typography variant="subtitle1">置換ペア</Typography>
//               {replacePairs.map((pair, index) => (
//                 <Box key={index} sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
//                   <TextField
//                     label="置換前"
//                     value={pair.from}
//                     onChange={(e) => handleReplacePairChange(index, 'from', e.target.value)}
//                   />
//                   <TextField
//                     label="置換後"
//                     value={pair.to}
//                     onChange={(e) => handleReplacePairChange(index, 'to', e.target.value)}
//                     sx={{ ml: 1 }}
//                   />
//                   <IconButton onClick={() => handleRemoveReplacePair(index)}>
//                     <DeleteIcon />
//                   </IconButton>
//                 </Box>
//               ))}
//               <Button variant="outlined" onClick={handleAddReplacePair} sx={{ mt: 1 }}>
//                 置換ペアを追加
//               </Button>
//             </Box>

//             {/* 先頭に追加する文字列 */}
//             <TextField
//               label="先頭に追加する文字列"
//               value={prependText}
//               onChange={(e) => setPrependText(e.target.value)}
//               fullWidth
//               sx={{ mt: 1 }}
//             />

//             {/* 末尾に追加する文字列 */}
//             <TextField
//               label="末尾に追加する文字列"
//               value={appendText}
//               onChange={(e) => setAppendText(e.target.value)}
//               fullWidth
//               sx={{ mt: 1 }}
//             />

//             {/* 80文字に制限する */}
//             <FormControlLabel
//               control={
//                 <Switch
//                   checked={limitTitleLength}
//                   onChange={(e) => setLimitTitleLength(e.target.checked)}
//                 />
//               }
//               label="80文字に制限する"
//               sx={{ mt: 1 }}
//             />
//           </Box>
//         </Box>
//       )}

//       {/* 4. AI商品説明 */}
//       {selectedFunctions.some((func) => func.name === 'applyAIProductDescriptions') && (
//         <Box sx={{ mt: 2 }}>
//           <Typography variant="h6">4. AI商品説明</Typography>
//           <Grid container spacing={2} sx={{ mt: 1 }}>
//             {/* デザインテンプレート選択 */}
//             {designTemplateNames.length > 0 && (
//               <Grid item xs={12} sm={6} md={4}>
//                 <FormControl fullWidth>
//                   <InputLabel>デザインテンプレートを選択</InputLabel>
//                   <Select
//                     value={selectedDesignTemplate}
//                     onChange={(e) => setSelectedDesignTemplate(e.target.value)}
//                   >
//                     {designTemplateNames.map((templateName) => (
//                       <MenuItem key={templateName} value={templateName}>
//                         {templateName}
//                       </MenuItem>
//                     ))}
//                   </Select>
//                 </FormControl>
//                 {/* 現在選択されているデザインテンプレートの表示 */}
//                 {selectedDesignTemplate && (
//                   <Typography variant="body2" sx={{ mt: 1 }}>
//                     選択中のテンプレート: {selectedDesignTemplate}
//                   </Typography>
//                 )}
//               </Grid>
//             )}

//             {/* 画像解析の説明を使用 */}
//             <Grid item xs={12} sm={6} md={4}>
//               <FormControlLabel
//                 control={
//                   <Switch
//                     checked={useImageDescription}
//                     onChange={(e) => setUseImageDescription(e.target.checked)}
//                     name="useImageDescription"
//                     color="primary"
//                   />
//                 }
//                 label="画像解析の説明を使用"
//               />
//             </Grid>

//             {/* 必須キーワード */}
//             <Grid item xs={12} sm={6} md={4}>
//               <Typography variant="subtitle1">必ず含めたいキーワード</Typography>
//               <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
//                 <TextField
//                   label="キーワードを入力"
//                   value={mandatoryKeywordInput}
//                   onChange={(e) => setMandatoryKeywordInput(e.target.value)}
//                   fullWidth
//                 />
//                 <Button
//                   variant="contained"
//                   color="primary"
//                   onClick={handleAddMandatoryKeyword}
//                   sx={{ ml: 1, height: '56px' }}
//                 >
//                   追加
//                 </Button>
//               </Box>
//               <List>
//                 {mandatoryKeywords.map((keyword, index) => (
//                   <ListItem
//                     key={index}
//                     secondaryAction={
//                       <IconButton
//                         edge="end"
//                         aria-label="delete"
//                         onClick={() => handleDeleteMandatoryKeyword(index)}
//                       >
//                         <DeleteIcon />
//                       </IconButton>
//                     }
//                   >
//                     <ListItemText primary={keyword} />
//                   </ListItem>
//                 ))}
//               </List>
//             </Grid>

//             {/* カスタムフィルター、削除したい文章、置換単語を横並びに配置 */}
//             <Grid item xs={12}>
//               <Grid container spacing={2}>
//                 {/* カスタムフィルター */}
//                 <Grid item xs={12} sm={4}>
//                   <OptionsPanel
//                     selectedFilters={selectedFilters}
//                     handleFilterChange={handleFilterChange}
//                     customFilterInput={customFilterInput}
//                     setCustomFilterInput={setCustomFilterInput}
//                     customFilters={customFilters}
//                     handleAddCustomFilter={handleAddCustomFilter}
//                     handleDeleteCustomFilter={handleDeleteCustomFilter}
//                     sentencesToDelete={sentencesToDelete}
//                     setSentencesToDelete={setSentencesToDelete}
//                     wordsToReplace={wordsToReplace}
//                     setWordsToReplace={setWordsToReplace}
//                   />
//                 </Grid>

//                 {/* 削除したい文章 */}
//                 <Grid item xs={12} sm={4}>
//                   <Typography variant="subtitle1">削除したい文章</Typography>
//                   <TextField
//                     label="文章を入力"
//                     value={sentenceToDeleteInput}
//                     onChange={(e) => setSentenceToDeleteInput(e.target.value)}
//                     fullWidth
//                   />
//                   <Button
//                     variant="contained"
//                     color="primary"
//                     onClick={handleAddSentenceToDelete}
//                     sx={{ mt: 1 }}
//                   >
//                     追加
//                   </Button>
//                   <List>
//                     {sentencesToDelete.map((sentence, index) => (
//                       <ListItem
//                         key={index}
//                         secondaryAction={
//                           <IconButton
//                             edge="end"
//                             aria-label="delete"
//                             onClick={() => handleDeleteSentenceToDelete(index)}
//                           >
//                             <DeleteIcon />
//                           </IconButton>
//                         }
//                       >
//                         <ListItemText primary={sentence} />
//                       </ListItem>
//                     ))}
//                   </List>
//                 </Grid>

//                 {/* 置換単語 */}
//                 <Grid item xs={12} sm={4}>
//                   <Typography variant="subtitle1">置換単語</Typography>
//                   <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
//                     <TextField
//                       label="置換前"
//                       value={wordToReplaceFrom}
//                       onChange={(e) => setWordToReplaceFrom(e.target.value)}
//                       fullWidth
//                     />
//                     <Typography sx={{ mx: 1 }}>➔</Typography>
//                     <TextField
//                       label="置換後"
//                       value={wordToReplaceTo}
//                       onChange={(e) => setWordToReplaceTo(e.target.value)}
//                       fullWidth
//                     />
//                   </Box>
//                   <Button
//                     variant="contained"
//                     color="primary"
//                     onClick={handleAddWordToReplace}
//                     sx={{ mt: 1 }}
//                   >
//                     追加
//                   </Button>
//                   <List>
//                     {wordsToReplace.map((wordPair, index) => (
//                       <ListItem
//                         key={index}
//                         secondaryAction={
//                           <IconButton
//                             edge="end"
//                             aria-label="delete"
//                             onClick={() => handleDeleteWordToReplace(index)}
//                           >
//                             <DeleteIcon />
//                           </IconButton>
//                         }
//                       >
//                         <ListItemText primary={`${wordPair.from} ➔ ${wordPair.to}`} />
//                       </ListItem>
//                     ))}
//                   </List>
//                 </Grid>
//               </Grid>
//             </Grid>

//             {/* テンプレート管理のUI */}
//             <Grid item xs={12}>
//               <Box sx={{ mt: 2 }}>
//                 <Typography variant="subtitle1">テンプレートの管理</Typography>
//                 <Button
//                   variant="contained"
//                   color="primary"
//                   onClick={() => {
//                     setIsSaveTemplateDialogOpen(true);
//                     setSaveOption(selectedTemplateId ? 'overwrite' : 'new');
//                   }}
//                 >
//                   テンプレートとして保存
//                 </Button>
//                 <Button
//                   variant="outlined"
//                   color="primary"
//                   onClick={() => setIsManageTemplatesDialogOpen(true)}
//                   sx={{ ml: 1 }}
//                 >
//                   テンプレートを管理
//                 </Button>
//                 <Button
//                   variant="outlined"
//                   color="secondary"
//                   onClick={handleCreateNewTemplate}
//                   sx={{ ml: 1, mt: 1 }}
//                 >
//                   新しいテンプレートを作成
//                 </Button>

//                 {/* テンプレート選択 */}
//                 <FormControl fullWidth sx={{ mt: 2 }}>
//                   <InputLabel>テンプレートを選択</InputLabel>
//                   <Select
//                     value={selectedTemplateId}
//                     onChange={(e) => handleLoadTemplate(e.target.value)}
//                   >
//                     <MenuItem value="">
//                       <em>テンプレートを選択</em>
//                     </MenuItem>
//                     {templates.map((template) => (
//                       <MenuItem key={template.id} value={template.id}>
//                         {template.name}
//                       </MenuItem>
//                     ))}
//                   </Select>
//                 </FormControl>
//               </Box>
//             </Grid>
//           </Grid>

//           {/* ユーザー設定の表示 */}
//           <Grid item xs={12}>
//             <Paper elevation={2} sx={{ p: 2, mt: 2 }}>
//               <Typography variant="h6">現在のユーザー設定</Typography>
//               <Grid container spacing={2}>
//                 {/* カスタムフィルター */}
//                 {customFilters && customFilters.length > 0 && (
//                   <Grid item xs={12} sm={6} md={3}>
//                     <Typography variant="subtitle1">カスタムフィルター:</Typography>
//                     <List>
//                       {customFilters.map((filter, index) => (
//                         <ListItem key={index}>
//                           <Typography>{filter}</Typography>
//                         </ListItem>
//                       ))}
//                     </List>
//                   </Grid>
//                 )}

//                 {/* 削除したい文章 */}
//                 {sentencesToDelete && sentencesToDelete.length > 0 && (
//                   <Grid item xs={12} sm={6} md={3}>
//                     <Typography variant="subtitle1">削除したい文章:</Typography>
//                     <List>
//                       {sentencesToDelete.map((sentence, index) => (
//                         <ListItem key={index}>
//                           <Typography>{sentence}</Typography>
//                         </ListItem>
//                       ))}
//                     </List>
//                   </Grid>
//                 )}

//                 {/* 置換単語 */}
//                 {wordsToReplace && wordsToReplace.length > 0 && (
//                   <Grid item xs={12} sm={6} md={3}>
//                     <Typography variant="subtitle1">置換単語:</Typography>
//                     <List>
//                       {wordsToReplace.map((wordPair, index) => (
//                         <ListItem key={index}>
//                           <Typography>
//                             {wordPair.from} ➔ {wordPair.to}
//                           </Typography>
//                         </ListItem>
//                       ))}
//                     </List>
//                   </Grid>
//                 )}

//                 {/* 必須キーワード */}
//                 {mandatoryKeywords && mandatoryKeywords.length > 0 && (
//                   <Grid item xs={12} sm={6} md={3}>
//                     <Typography variant="subtitle1">必須キーワード:</Typography>
//                     <List>
//                       {mandatoryKeywords.map((keyword, index) => (
//                         <ListItem key={index}>
//                           <Typography>{keyword}</Typography>
//                         </ListItem>
//                       ))}
//                     </List>
//                   </Grid>
//                 )}
//               </Grid>
//             </Paper>
//           </Grid>
//         </Box>
//       )}

//       {/* Item Specifics適用 */}
//       {selectedFunctions.some((func) => func.name === 'applyItemSpecifics') && (
//         <Box sx={{ mt: 2 }}>
//           <Typography variant="h6">5. Item Specifics適用</Typography>
//           {/* カテゴリー選択 */}
//           <FormControl fullWidth sx={{ mt: 1 }}>
//             <InputLabel>適用するカテゴリー</InputLabel>
//             <Select
//               value={selectedItemSpecificsCategory}
//               onChange={(e) => {
//                 console.log('選択されたカテゴリー:', e.target.value);
//                 setSelectedItemSpecificsCategory(e.target.value);
//                 setSelectedItemSpecificsColumns([]); // カラム選択をリセット
//               }}
//               label="適用するカテゴリー"
//             >
//               {Object.keys(itemSpecificsCategories).map((category) => (
//                 <MenuItem key={category} value={category}>
//                   {category}
//                 </MenuItem>
//               ))}
//             </Select>
//           </FormControl>

//           {/* カラム選択 */}
//           {selectedItemSpecificsCategory && (
//             <FormControl fullWidth sx={{ mt: 2 }}>
//               <InputLabel>適用するカラムを選択</InputLabel>
//               <Select
//                 multiple
//                 value={selectedItemSpecificsColumns}
//                 onChange={(e) => {
//                   const value = e.target.value;
//                   if (value.includes('all')) {
//                     // Handle "select all"
//                     const allColumns = getColumnsForSelectedCategory();
//                     if (selectedItemSpecificsColumns.length === allColumns.length) {
//                       // Deselect all if all are already selected
//                       setSelectedItemSpecificsColumns([]);
//                     } else {
//                       // Select all columns
//                       setSelectedItemSpecificsColumns(allColumns);
//                     }
//                   } else {
//                     setSelectedItemSpecificsColumns(value);
//                   }
//                 }}
//                 renderValue={(selected) => {
//                   if (selected.length === 0) {
//                     return '適用するカラムを選択';
//                   }

//                   const displayedValues = selected.slice(0, 5); // Show up to 5 selected columns
//                   const hiddenCount = selected.length - displayedValues.length;

//                   return (
//                     <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
//                       {displayedValues.map((value) => (
//                         <Chip key={value} label={value} size="small" />
//                       ))}
//                       {hiddenCount > 0 && (
//                         <Chip label={`+${hiddenCount}個`} size="small" />
//                       )}
//                     </Box>
//                   );
//                 }}
//               >
//                 <MenuItem
//                   value="all"
//                   onClick={() => {
//                     const allColumns = getColumnsForSelectedCategory();
//                     if (selectedItemSpecificsColumns.length === allColumns.length) {
//                       // Deselect all if all are already selected
//                       setSelectedItemSpecificsColumns([]);
//                     } else {
//                       // Select all columns
//                       setSelectedItemSpecificsColumns(allColumns);
//                     }
//                   }}
//                 >
//                   <Checkbox
//                     checked={
//                       selectedItemSpecificsColumns.length === getColumnsForSelectedCategory().length &&
//                       selectedItemSpecificsColumns.length > 0
//                     }
//                     indeterminate={
//                       selectedItemSpecificsColumns.length > 0 &&
//                       selectedItemSpecificsColumns.length < getColumnsForSelectedCategory().length
//                     }
//                   />
//                   <Typography variant="body1">全選択</Typography>
//                 </MenuItem>
//                 {getColumnsForSelectedCategory().map((column) => (
//                   <MenuItem key={column} value={column}>
//                     <Checkbox
//                       checked={selectedItemSpecificsColumns.indexOf(column) > -1}
//                     />
//                     <Typography variant="body1">{column}</Typography>
//                   </MenuItem>
//                 ))}
//               </Select>
//             </FormControl>
//           )}

//           {/* マッチングオプション */}
//           {selectedItemSpecificsCategory && (
//             <>
//               <Box sx={{ mt: 2 }}>
//                 <Typography variant="subtitle1" gutterBottom>
//                   マッチングの基準を選択してください。
//                 </Typography>
//                 <FormControl component="fieldset">
//                   <RadioGroup
//                     value={itemSpecificsMatchingOptions.matchSource}
//                     onChange={(e) =>
//                       setItemSpecificsMatchingOptions((prev) => ({
//                         ...prev,
//                         matchSource: e.target.value,
//                       }))
//                     }
//                   >
//                     <FormControlLabel
//                       value="title"
//                       control={<Radio />}
//                       label="タイトルのみ"
//                     />
//                     <FormControlLabel
//                       value="description"
//                       control={<Radio />}
//                       label="商品説明のみ (AI生成部分のみ)"
//                     />
//                     <FormControlLabel
//                       value="both"
//                       control={<Radio />}
//                       label="タイトルと商品説明の両方 (AI生成部分のみ)"
//                     />
//                   </RadioGroup>
//                 </FormControl>
//               </Box>

//               {/* オプション */}
//               <Box sx={{ mt: 2 }}>
//                 <FormControlLabel
//                   control={
//                     <Checkbox
//                       checked={itemSpecificsMatchingOptions.caseSensitive}
//                       onChange={(e) =>
//                         setItemSpecificsMatchingOptions((prev) => ({
//                           ...prev,
//                           caseSensitive: e.target.checked,
//                         }))
//                       }
//                       name="caseSensitive"
//                     />
//                   }
//                   label="大文字小文字を区別する"
//                 />
//                 <FormControlLabel
//                   control={
//                     <Checkbox
//                       checked={itemSpecificsMatchingOptions.partialMatch}
//                       onChange={(e) =>
//                         setItemSpecificsMatchingOptions((prev) => ({
//                           ...prev,
//                           partialMatch: e.target.checked,
//                         }))
//                       }
//                       name="partialMatch"
//                     />
//                   }
//                   label="部分一致を許可する"
//                 />
//               </Box>

//               {/* マッチングの動作を選択 */}
//               <Box sx={{ mt: 2 }}>
//                 <Typography variant="subtitle1" gutterBottom>
//                   マッチングの動作を選択してください。
//                 </Typography>
//                 <Typography variant="subtitle1" gutterBottom>
//                   (上記でAI商品説明からマッチングを選択している場合は、自動処理でAI生成した文章からカラムの適所に適用されます。自動処理によって特に値の変更がなかった場合以下の設定が適用されます。)
//                 </Typography>
//                 <FormControl component="fieldset">
//                   <RadioGroup
//                     value={itemSpecificsMatchingOptions.matchingOption}
//                     onChange={(e) =>
//                       setItemSpecificsMatchingOptions((prev) => ({
//                         ...prev,
//                         matchingOption: e.target.value,
//                       }))
//                     }
//                   >
//                     <FormControlLabel
//                       value="priority"
//                       control={<Radio />}
//                       label="優先順位に基づいて適用"
//                     />
//                     <FormControlLabel
//                       value="all"
//                       control={<Radio />}
//                       label="マッチしたすべての値を適用"
//                     />
//                   </RadioGroup>
//                 </FormControl>
//               </Box>
//             </>
//           )}
//         </Box>
//       )}

//       {/* --- ここまで設定表示 --- */}

//       {/* 実行モードの選択 */}
//       <Box sx={{ mt: 2 }}>
//         <Typography variant="subtitle1">実行モード:</Typography>
//         <Button
//           variant={isImmediate ? 'contained' : 'outlined'}
//           onClick={() => setIsImmediate(true)}
//           sx={{ mr: 1 }}
//         >
//           即時実行
//         </Button>
//         <Button
//           variant={!isImmediate ? 'contained' : 'outlined'}
//           onClick={() => setIsImmediate(false)}
//         >
//           スケジュール実行
//         </Button>
//       </Box>

//       {/* スケジュール時間の選択 */}
//       {!isImmediate && (
//         <Box sx={{ mt: 2 }}>
//           <TextField
//             label="実行開始時間"
//             type="datetime-local"
//             value={scheduledTime}
//             onChange={(e) => setScheduledTime(e.target.value)}
//             InputLabelProps={{
//               shrink: true,
//             }}
//             fullWidth
//           />
//         </Box>
//       )}

//       {/* アップロード＆実行ボタン */}
//       <Button
//         variant="contained"
//         color="secondary"
//         sx={{ mt: 2 }}
//         onClick={handleUploadAndExecute}
//         disabled={isScheduling}
//       >
//         {isScheduling ? '処理中...' : 'アップロードして実行'}
//       </Button>

//       {/* 進捗状況の表示 */}
//       {isScheduling && (
//         <Box sx={{ mt: 2 }}>
//           <Typography variant="h6">処理進捗:</Typography>
//           <Typography>
//             {progress.completedItems} / {progress.totalItems} 件が完了しました
//           </Typography>
//           <LinearProgress
//             variant="determinate"
//             value={(progress.completedItems / progress.totalItems) * 100}
//           />
//         </Box>
//       )}

//       {/* 処理結果のプレビュー */}
//       {processedParsedData.length > 0 && (
//         <Box sx={{ mt: 4 }}>
//           <Typography variant="h6">処理結果のプレビュー:</Typography>
//           <Tabs
//             value={activeTab}
//             onChange={handleTabChange}
//             variant="scrollable"
//             scrollButtons="auto"
//           >
//             {processedParsedData.map((file, index) => (
//               <Tab key={index} label={file.name} />
//             ))}
//           </Tabs>
//           {processedParsedData.map((file, index) => (
//             <div
//               key={index}
//               role="tabpanel"
//               hidden={activeTab !== index}
//               id={`tabpanel-${index}`}
//             >
//               {activeTab === index && (
//                 <Box sx={{ p: 2 }}>
//                   <Paper elevation={2} sx={{ overflowX: 'auto' }}>
//                     <TableContainer>
//                       <Table
//                         size="small"
//                         sx={{
//                           tableLayout: 'auto',
//                           width: 'auto',
//                         }}
//                       >
//                         <TableHead>
//                           <TableRow>
//                             {file.columns.map((column, idx) => (
//                               <TableCell
//                                 key={idx}
//                                 sx={{
//                                   maxWidth: '150px',
//                                   whiteSpace: 'nowrap',
//                                   padding: '8px',
//                                   border: '1px solid rgba(224, 224, 224, 1)',
//                                   fontWeight: 'bold',
//                                 }}
//                               >
//                                 {column}
//                               </TableCell>
//                             ))}
//                           </TableRow>
//                         </TableHead>
//                         <TableBody>
//                           {file.data.map((row, rowIndex) => (
//                             <TableRow key={rowIndex}>
//                               {file.columns.map((column, colIndex) => (
//                                 <TableCell
//                                   key={colIndex}
//                                   sx={{
//                                     maxWidth: '150px',
//                                     whiteSpace: 'nowrap',
//                                     padding: '8px',
//                                     border: '1px solid rgba(224, 224, 224, 1)',
//                                   }}
//                                 >
//                                   {row[column]}
//                                 </TableCell>
//                               ))}
//                             </TableRow>
//                           ))}
//                         </TableBody>
//                       </Table>
//                     </TableContainer>
//                   </Paper>
//                 </Box>
//               )}
//             </div>
//           ))}
//         </Box>
//       )}

//       {/* エラー情報の表示 */}
//       {errorItems.length > 0 && (
//         <Box sx={{ mt: 2 }}>
//           <Typography variant="h6" color="error">
//             エラーが発生したアイテム:
//           </Typography>
//           <List>
//             {errorItems.map((item, index) => (
//               <ListItem key={index}>
//                 <Typography>
//                   ファイル名: {item.fileName}, 行番号: {item.rowIndex}, エラー: {item.error}
//                 </Typography>
//               </ListItem>
//             ))}
//           </List>
//         </Box>
//       )}

//       {/* 処理結果のダウンロード */}
//       {processedData.length > 0 && (
//         <Box sx={{ mt: 2 }}>
//           <Typography variant="h6">処理結果のダウンロード:</Typography>
//           {processedData.map((file, index) => (
//             <Button
//               key={index}
//               variant="contained"
//               sx={{ mt: 1, mr: 1 }}
//               onClick={() => {
//                 const blob = new Blob([file.content], {
//                   type: 'text/csv;charset=utf-8;',
//                 });
//                 const link = document.createElement('a');
//                 link.href = URL.createObjectURL(blob);
//                 link.setAttribute('download', file.name);
//                 document.body.appendChild(link);
//                 link.click();
//                 document.body.removeChild(link);
//               }}
//             >
//               {file.name}
//             </Button>
//           ))}
//         </Box>
//       )}

//       {/* 機能選択ダイアログ */}
//       <Dialog open={functionDialogOpen} onClose={handleFunctionDialogClose}>
//         <DialogTitle>実行する機能を選択</DialogTitle>
//         <DialogContent>
//           <DragDropContext onDragEnd={onDragEnd}>
//             <Droppable droppableId="functions">
//               {(provided) => (
//                 <List {...provided.droppableProps} ref={provided.innerRef}>
//                   {availableFunctions.map((func, index) => (
//                     <Draggable key={func.name} draggableId={func.name} index={index}>
//                       {(provided) => (
//                         <ListItem
//                           button
//                           ref={provided.innerRef}
//                           {...provided.draggableProps}
//                         >
//                           <Checkbox
//                             checked={
//                               selectedFunctions.findIndex((f) => f.name === func.name) !== -1
//                             }
//                             onClick={handleFunctionToggle(func)}
//                           />
//                           <Typography>{func.displayName}</Typography>
//                           <IconButton {...provided.dragHandleProps}>
//                             <DragHandleIcon />
//                           </IconButton>
//                         </ListItem>
//                       )}
//                     </Draggable>
//                   ))}
//                   {provided.placeholder}
//                 </List>
//               )}
//             </Droppable>
//           </DragDropContext>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={handleFunctionDialogClose}>閉じる</Button>
//         </DialogActions>
//       </Dialog>

//       {/* テンプレート保存ダイアログ */}
//       <Dialog
//         open={isSaveTemplateDialogOpen}
//         onClose={() => setIsSaveTemplateDialogOpen(false)}
//       >
//         <DialogTitle>テンプレートの保存</DialogTitle>
//         <DialogContent>
//           <FormControl component="fieldset">
//             <RadioGroup
//               value={saveOption}
//               onChange={(e) => setSaveOption(e.target.value)}
//             >
//               <FormControlLabel
//                 value="new"
//                 control={<Radio />}
//                 label="新しいテンプレートとして保存"
//               />
//               {selectedTemplateId && (
//                 <FormControlLabel
//                   value="overwrite"
//                   control={<Radio />}
//                   label="既存のテンプレートを上書き保存"
//                 />
//               )}
//             </RadioGroup>
//           </FormControl>
//           <TextField
//             label="テンプレート名"
//             value={templateName}
//             onChange={(e) => setTemplateName(e.target.value)}
//             fullWidth
//             sx={{ mt: 2 }}
//           />
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => setIsSaveTemplateDialogOpen(false)}>
//             キャンセル
//           </Button>
//           <Button onClick={saveTemplate} color="primary">
//             保存
//           </Button>
//         </DialogActions>
//       </Dialog>

//       {/* テンプレート管理ダイアログ */}
//       <Dialog
//         open={isManageTemplatesDialogOpen}
//         onClose={() => setIsManageTemplatesDialogOpen(false)}
//       >
//         <DialogTitle>テンプレートの管理</DialogTitle>
//         <DialogContent>
//           <List>
//             {templates.map((template) => (
//               <ListItem key={template.id}>
//                 <ListItemText primary={template.name} />
//                 <IconButton
//                   edge="end"
//                   aria-label="delete"
//                   onClick={() => handleDeleteTemplate(template.id)}
//                 >
//                   <DeleteIcon />
//                 </IconButton>
//               </ListItem>
//             ))}
//           </List>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => setIsManageTemplatesDialogOpen(false)}>
//             閉じる
//           </Button>
//         </DialogActions>
//       </Dialog>

//       {/* Snackbarによる通知 */}
//       <Snackbar
//         open={snackbar.open}
//         autoHideDuration={6000}
//         onClose={() => setSnackbar({ ...snackbar, open: false })}
//       >
//         <Alert
//           onClose={() => setSnackbar({ ...snackbar, open: false })}
//           severity={snackbar.severity}
//           sx={{ width: '100%' }}
//         >
//           {snackbar.message}
//         </Alert>
//       </Snackbar>
//     </Paper>
//   );
// }

// export default CsvSchedulerUploader;






// // CsvSchedulerUploader.js

// import React, { useState, useEffect ,useCallback } from 'react';
// import {
//   Box,
//   Button,
//   Typography,
//   TextField,
//   Paper,
//   Snackbar,
//   Alert,
//   List,
//   ListItem,
//   Checkbox,
//   IconButton,
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogTitle,
//   FormControl,
//   InputLabel,
//   Select,
//   MenuItem,
//   Tabs,
//   Tab,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   LinearProgress,
//   Grid,
//   Switch,
//   ListItemText,
//   RadioGroup,
//   Radio,
//   FormControlLabel,
//   Chip,
// } from '@mui/material';
// import { useNavigate } from 'react-router-dom';
// import MenuIcon from '@mui/icons-material/Menu';
// import DragHandleIcon from '@mui/icons-material/DragHandle';
// import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
// import VisibilityIcon from '@mui/icons-material/Visibility';
// import EditIcon from '@mui/icons-material/Edit';
// import MoneyOffIcon from '@mui/icons-material/MoneyOff';
// import ReplayIcon from '@mui/icons-material/Replay';
// import DeleteIcon from '@mui/icons-material/Delete';

// // Firebase のインポート
// import { auth, db } from './firebaseConfig';
// import { getFirestore,getDoc, doc, collection, getDocs, addDoc, setDoc, deleteDoc } from 'firebase/firestore';

// // 機能関数のインポート
// import {
//   applyTemplate,
//   applyItemSpecifics,
// } from './functions';
// // パーサーのインポート
// import Papa from 'papaparse';
// // 価格計算関数のインポート
// import { calculatePrices } from './calculatePrices';
// // AIタイトル生成関数のインポート
// import { applyAITitles } from './applyAITitles';
// // AI商品説明生成関数のインポート
// import { applyAIProductDescriptions } from './applyAIProductDescriptions';
// // CryptoJSのインポート
// import CryptoJS from 'crypto-js';
// // デザインテンプレートのインポート
// import { applyItemSpecificsProcessor } from './ItemSpecificsProcessor';
// import designTemplates from './designTemplates';



// // その他のコンポーネントのインポート
// import OptionsPanel from './OptionsPanel';

// function CsvSchedulerUploader({ user }) {
//   const [csvFiles, setCsvFiles] = useState([]);
//   const [fileContents, setFileContents] = useState([]);
//   const [snackbar, setSnackbar] = useState({
//     open: false,
//     message: '',
//     severity: 'info',
//   });

//   // CsvSchedulerUploader.js

//   // 状態変数を定
//   const [selectedTemplate, setSelectedTemplate] = useState('');


//   const [selectedItemSpecificsCategory, setSelectedItemSpecificsCategory] = useState('');
//   const [selectedItemSpecificsColumns, setSelectedItemSpecificsColumns] = useState([]);
//   const [itemSpecificsMatchingOptions, setItemSpecificsMatchingOptions] = useState({
//     caseSensitive: false,
//     partialMatch: true,
//     matchSource: 'title',
//     matchingOption: 'priority',
//   });
//   const [itemSpecificsCategories, setItemSpecificsCategories] = useState({});

//   // デフォルトで全ての機能を選択状態にする
//   const [selectedFunctions, setSelectedFunctions] = useState([]);
//   const [isImmediate, setIsImmediate] = useState(true); // 即時実行かどうか
//   const [scheduledTime, setScheduledTime] = useState(''); // スケジュール時間（文字列）
//   const [functionDialogOpen, setFunctionDialogOpen] = useState(false);
//   const [isScheduling, setIsScheduling] = useState(false); // スケジュール処理中かどうか
//   const [processedData, setProcessedData] = useState([]); // 処理後のデータ
//   const [activeTab, setActiveTab] = useState(0); // アクティブなタブのインデックス

//   const [templateList, setTemplateList] = useState([]);
//   const [selectedTemplateName, setSelectedTemplateName] = useState('');

//   // 価格設定の状態管理
//   const [selectedPriceSettings, setSelectedPriceSettings] = useState(null);

//   // 送料テンプレートの状態管理
//   const [shippingRateTemplates, setShippingRateTemplates] = useState([]);
//   const [selectedShippingTemplateId, setSelectedShippingTemplateId] = useState('');

//   // その他の設定
//   const [exchangeRate, setExchangeRate] = useState(0);
//   const [targetProfitMargin, setTargetProfitMargin] = useState(0);
//   const [fees, setFees] = useState({});
//   const [bestOfferSettings, setBestOfferSettings] = useState({});

//   // ユーザーのAPIキーの状態管理
//   const [userApiKey, setUserApiKey] = useState('');

//   // ユーザー設定の状態管理
//   const [userSettings, setUserSettings] = useState({});

//   // デザインテンプレートの選択状態
//   const [designTemplateNames, setDesignTemplateNames] = useState([]);
//   const [selectedDesignTemplate, setSelectedDesignTemplate] = useState('');

//   // テンプレート管理の状態
//   const [templates, setTemplates] = useState([]);
//   const [templateName, setTemplateName] = useState('');
//   const [isSaveTemplateDialogOpen, setIsSaveTemplateDialogOpen] = useState(false);
//   const [isManageTemplatesDialogOpen, setIsManageTemplatesDialogOpen] = useState(false);
//   const [selectedTemplateId, setSelectedTemplateId] = useState('');
//   const [currentTemplateName, setCurrentTemplateName] = useState('');
//   const [saveOption, setSaveOption] = useState('new');

//   // その他の状態
//   const [useImageDescription, setUseImageDescription] = useState(false);
//   const [mandatoryKeywords, setMandatoryKeywords] = useState([]);
//   const [mandatoryKeywordInput, setMandatoryKeywordInput] = useState('');

//   // 新しく追加する状態変数
//   const [sentenceToDeleteInput, setSentenceToDeleteInput] = useState('');
//   const [wordToReplaceFrom, setWordToReplaceFrom] = useState('');
//   const [wordToReplaceTo, setWordToReplaceTo] = useState('');

//   const navigate = useNavigate();

//   // ユーザー固有の秘密鍵を生成
//   const generateEncryptionKey = (uid) => {
//     const salt = 'your-fixed-salt-value'; // 固定の塩値（セキュリティ向上のため、環境変数で管理することを推奨）
//     return CryptoJS.PBKDF2(uid, salt, { keySize: 256 / 32 }).toString();
//   };

  

//   // 進捗状況の状態
//   const [progress, setProgress] = useState({ totalItems: 0, completedItems: 0 });
//   const [errorItems, setErrorItems] = useState([]);


//   // availableFunctionsの定義をここに移動
//   const [availableFunctions, setAvailableFunctions] = useState([
//     {
//       name: 'applyTemplate',
//       displayName: 'テンプレートを実行する',
//       func: applyTemplate,
//     },
//     {
//       name: 'applyPriceChanges',
//       displayName: '価格の変更を適用する',
//       func: 'applyPriceChanges', // 後で設定
//     },
//     {
//       name: 'applyAITitles',
//       displayName: 'AIタイトルを適用する',
//       func: 'applyAITitles', // 後で設定
//     },
//     {
//       name: 'applyAIProductDescriptions',
//       displayName: 'AI商品説明を適用する',
//       func: 'applyAIProductDescriptions', // 後で設定
//     },
//     {
//       name: 'applyItemSpecifics',
//       displayName: 'Item Specificsの適用をする',
//       func: applyItemSpecifics,
//     },
//   ]);

//   useEffect(() => {
//     const fetchItemSpecificsSettings = async () => {
//       if (!user) return;
  
//       try {
//         const db = getFirestore();
//         const docRef = doc(db, 'itemSpecificsSettings', user.uid);
//         const docSnap = await getDoc(docRef);
  
//         if (docSnap.exists()) {
//           const settingsData = docSnap.data();
//           setItemSpecificsCategories(settingsData.categories || {});
//         } else {
//           console.warn('Item Specificsの設定が見つかりませんでした。');
//         }
//       } catch (error) {
//         console.error('Item Specificsの設定取得中にエラーが発生しました:', error);
//       }
//     };
  
//     fetchItemSpecificsSettings();
//   }, [user]);


//   // 1. applyItemSpecificsWrapperからuseCallbackを削除
// const applyItemSpecificsWrapper = async (currentData, options) => {
//   const {
//     selectedItemSpecificsCategory,
//     selectedItemSpecificsColumns,
//     itemSpecificsMatchingOptions,
//   } = options;
//   try {
//     if (!user) {
//       setSnackbar({
//         open: true,
//         message: 'ユーザー情報が取得できません。',
//         severity: 'error',
//       });
//       throw new Error('ユーザー情報が取得できません。');
//     }

//     if (!selectedItemSpecificsCategory) {
//       setSnackbar({
//         open: true,
//         message: '適用するカテゴリーを選択してください。',
//         severity: 'warning',
//       });
//       throw new Error('適用するカテゴリーを選択してください。');
//     }

//     // 進捗状況の初期化
//     setProgress({ totalItems: 0, completedItems: 0 });

//     console.log(
//       'applyItemSpecificsWrapper内のselectedItemSpecificsCategory:',
//       selectedItemSpecificsCategory
//     );

//     const updatedData = await applyItemSpecificsProcessor(
//       currentData,
//       user.uid,
//       {
//         selectedCategory: selectedItemSpecificsCategory,
//         selectedColumns: selectedItemSpecificsColumns,
//         matchingOptions: itemSpecificsMatchingOptions,
//       },
//       (progressData) => {
//         setProgress(progressData);
//       }
//     );

//     return updatedData;
//   } catch (error) {
//     console.error('Item Specifics適用中にエラーが発生しました:', error);
//     setSnackbar({
//       open: true,
//       message: error.message || 'Item Specifics適用中にエラーが発生しました。',
//       severity: 'error',
//     });
//     throw error;
//   }
// };

  
  

// // 2. useEffectの依存配列からapplyItemSpecificsWrapperを除外
// useEffect(() => {
//   setAvailableFunctions((prevFunctions) =>
//     prevFunctions.map((func) =>
//       func.name === 'applyItemSpecifics'
//         ? { ...func, func: applyItemSpecificsWrapper }
//         : func
//     )
//   );
// }, [
//   user,
//   // 依存配列から状態変数を削除
// ]);


//   // Function to get columns for the selected category
//   const getColumnsForSelectedCategory = () => {
//     if (
//       selectedItemSpecificsCategory &&
//       itemSpecificsCategories[selectedItemSpecificsCategory]
//     ) {
//       return Object.keys(
//         itemSpecificsCategories[selectedItemSpecificsCategory].columns || {}
//       );
//     }
//     return [];
//   };
  

//   // ユーザーが認証されていない場合はログインページへリダイレクト
//   useEffect(() => {
//     if (!user) {
//       navigate('/login');
//     } else {
//       // テンプレート一覧を取得
//       const fetchTemplates = async () => {
//         try {
//           const docRef = doc(db, 'userTemplates', user.uid);
//           const docSnap = await getDoc(docRef);

//           if (docSnap.exists()) {
//             const userData = docSnap.data();
//             const userCategories = userData.categories || {};
//             const templates = Object.values(userCategories)
//               .flat()
//               .map((template) => ({
//                 name: template.name,
//                 operations: template.operations,
//               }));
//             setTemplateList(templates);
//             if (templates.length > 0) {
//               setSelectedTemplateName(templates[0].name);
//             }
//           }
//         } catch (error) {
//           console.error('テンプレートの取得中にエラーが発生しました:', error);
//           setSnackbar({
//             open: true,
//             message: 'テンプレートの取得に失敗しました。',
//             severity: 'error',
//           });
//         }
//       };
//       fetchTemplates();

//       // ユーザー設定とAPIキーを取得
//       const fetchUserSettings = async () => {
//         try {
//           // ユーザー設定を取得
//           const docRef = doc(db, 'userSettings', user.uid);
//           const docSnap = await getDoc(docRef);

//           if (docSnap.exists()) {
//             const userSettingsData = docSnap.data();
//             setSelectedPriceSettings(userSettingsData);
//             setUserSettings(userSettingsData); // ユーザー設定を保存

//             // 送料テンプレートを設定
//             if (userSettingsData.shippingRateTemplates) {
//               setShippingRateTemplates(userSettingsData.shippingRateTemplates);
//               setSelectedShippingTemplateId(
//                 userSettingsData.selectedShippingTemplateId ||
//                   userSettingsData.shippingRateTemplates[0].id
//               );
//             }

//             // その他の設定を取得
//             setExchangeRate(userSettingsData.exchangeRate || 0);
//             setTargetProfitMargin(userSettingsData.targetProfitMargin || 0);
//             setFees(userSettingsData.fees || {});
//             setBestOfferSettings({
//               bestOfferAutoAcceptPercentage:
//                 userSettingsData.bestOfferAutoAcceptPercentage || 0,
//               minimumBestOfferPercentage:
//                 userSettingsData.minimumBestOfferPercentage || 0,
//             });

//             // デザインテンプレート名の取得と初期設定
//             if (userSettingsData.designTemplates) {
//               const templateNames = Object.keys(userSettingsData.designTemplates);
//               setDesignTemplateNames(templateNames);
//               if (templateNames.length > 0) {
//                 setSelectedDesignTemplate(templateNames[0]);
//               } else {
//                 console.warn('デザインテンプレートが設定されていません。');
//                 // デフォルトのテンプレート名を設定
//                 setSelectedDesignTemplate('defaultTemplate');
//               }
//             } else {
//               console.warn('デザインテンプレートが設定されていません。');
//               // デフォルトのテンプレート名を設定
//               setSelectedDesignTemplate('simple');
//             }

//             // カスタムフィルターや削除したい文章などの取得
//             setCustomFilters(userSettingsData.customFilters || []);
//             setSentencesToDelete(userSettingsData.sentencesToDelete || []);
//             setWordsToReplace(userSettingsData.wordsToReplace || []);
//             setMandatoryKeywords(userSettingsData.mandatoryKeywords || []);
//           } else {
//             console.warn('ユーザー設定が見つかりませんでした');
//           }

//           // APIキーを取得
//           const apiKeyDocRef = doc(db, 'userApiKeys', user.uid);
//           const apiKeyDocSnap = await getDoc(apiKeyDocRef);

//           if (apiKeyDocSnap.exists()) {
//             const encryptedKey = apiKeyDocSnap.data().apiKey;
//             const encryptionKey = generateEncryptionKey(user.uid);
//             const bytes = CryptoJS.AES.decrypt(encryptedKey, encryptionKey);
//             const decryptedKey = bytes.toString(CryptoJS.enc.Utf8);
//             if (decryptedKey) {
//               setUserApiKey(decryptedKey);
//             } else {
//               console.error('APIキーの復号に失敗しました。');
//             }
//           } else {
//             console.warn('ユーザーのAPIキーが見つかりませんでした');
//           }

//           // ユーザーのテンプレートをロード
//           loadTemplates();
//         } catch (error) {
//           console.error('ユーザー設定の取得中にエラーが発生しました:', error);
//           setSnackbar({
//             open: true,
//             message: 'ユーザー設定の取得に失敗しました。',
//             severity: 'error',
//           });
//         }
//       };
//       fetchUserSettings();
//     }
//   }, [user, navigate]);

//   // カスタムフィルターやテキスト変換の状態管理
//   const [customFilters, setCustomFilters] = useState([]);
//   const [sentencesToDelete, setSentencesToDelete] = useState([]);
//   const [wordsToReplace, setWordsToReplace] = useState([]);

//   // フィルタリングオプションのステート
//   const [selectedFilters, setSelectedFilters] = useState([]);
//   const [customFilterInput, setCustomFilterInput] = useState('');

//   // テンプレートの保存
//   const saveTemplate = async () => {
//     if (!templateName.trim()) {
//       setSnackbar({
//         open: true,
//         message: 'テンプレート名を入力してください',
//         severity: 'warning',
//       });
//       return;
//     }

//     const templateData = {
//       name: templateName.trim(),
//       customFilters,
//       sentencesToDelete,
//       wordsToReplace,
//       mandatoryKeywords,
//       selectedFilters,
//     };

//     try {
//       const userTemplatesRef = collection(db, 'users', user.uid, 'templates');

//       if (saveOption === 'overwrite' && selectedTemplateId) {
//         const templateDocRef = doc(userTemplatesRef, selectedTemplateId);
//         await setDoc(templateDocRef, templateData);
//         setSnackbar({
//           open: true,
//           message: 'テンプレートが更新されました',
//           severity: 'success',
//         });
//       } else {
//         await addDoc(userTemplatesRef, templateData);
//         setSnackbar({
//           open: true,
//           message: '新しいテンプレートが保存されました',
//           severity: 'success',
//         });
//       }

//       setIsSaveTemplateDialogOpen(false);
//       setTemplateName('');
//       loadTemplates();
//     } catch (error) {
//       console.error('テンプレートの保存中にエラーが発生しました:', error);
//       setSnackbar({
//         open: true,
//         message: 'テンプレートの保存中にエラーが発生しました',
//         severity: 'error',
//       });
//     }
//   };

//   // テンプレートの読み込み
//   const loadTemplates = async () => {
//     try {
//       const userTemplatesRef = collection(db, 'users', user.uid, 'templates');
//       const templatesSnapshot = await getDocs(userTemplatesRef);
//       const templatesData = templatesSnapshot.docs.map((doc) => ({
//         id: doc.id,
//         ...doc.data(),
//       }));
//       setTemplates(templatesData);
//     } catch (error) {
//       console.error('テンプレートの読み込み中にエラーが発生しました:', error);
//     }
//   };

//   // テンプレートの適用
//   const handleLoadTemplate = async (templateId) => {
//     if (!templateId) {
//       setSelectedTemplateId('');
//       setTemplateName('');
//       setCurrentTemplateName('');
//       return;
//     }
//     setSelectedTemplateId(templateId);
//     try {
//       const templateDocRef = doc(db, 'users', user.uid, 'templates', templateId);
//       const templateDoc = await getDoc(templateDocRef);
//       if (templateDoc.exists()) {
//         const templateData = templateDoc.data();
//         setTemplateName(templateData.name || '');
//         setCurrentTemplateName(templateData.name || '');
//         setCustomFilters(templateData.customFilters || []);
//         setSentencesToDelete(templateData.sentencesToDelete || []);
//         setWordsToReplace(templateData.wordsToReplace || []);
//         setMandatoryKeywords(templateData.mandatoryKeywords || []);
//         setSelectedFilters(templateData.selectedFilters || []);
//         setSnackbar({
//           open: true,
//           message: 'テンプレートが適用されました',
//           severity: 'success',
//         });
//       } else {
//         setSnackbar({
//           open: true,
//           message: 'テンプレートが見つかりません',
//           severity: 'error',
//         });
//       }
//     } catch (error) {
//       console.error('テンプレートの読み込み中にエラーが発生しました:', error);
//       setSnackbar({
//         open: true,
//         message: 'テンプレートの読み込み中にエラーが発生しました',
//         severity: 'error',
//       });
//     }
//   };

//   // テンプレートの削除
//   const handleDeleteTemplate = async (templateId) => {
//     try {
//       const templateDocRef = doc(db, 'users', user.uid, 'templates', templateId);
//       await deleteDoc(templateDocRef);
//       setSnackbar({
//         open: true,
//         message: 'テンプレートが削除されました',
//         severity: 'success',
//       });
//       loadTemplates();
//     } catch (error) {
//       console.error('テンプレートの削除中にエラーが発生しました:', error);
//       setSnackbar({
//         open: true,
//         message: 'テンプレートの削除中にエラーが発生しました',
//         severity: 'error',
//       });
//     }
//   };

//   // 新しいテンプレートを作成
//   const handleCreateNewTemplate = () => {
//     setSelectedTemplateId('');
//     setTemplateName('');
//     setCurrentTemplateName('');
//     setCustomFilters([]);
//     setSelectedFilters([]);
//     setSentencesToDelete([]);
//     setWordsToReplace([]);
//     setMandatoryKeywords([]);
//     setSnackbar({
//       open: true,
//       message: '新しいテンプレートを作成します',
//       severity: 'info',
//     });
//   };

//   // 必須キーワードの追加・削除
//   const handleAddMandatoryKeyword = () => {
//     if (mandatoryKeywordInput.trim() !== '') {
//       setMandatoryKeywords([...mandatoryKeywords, mandatoryKeywordInput.trim()]);
//       setMandatoryKeywordInput('');
//     }
//   };

//   const handleDeleteMandatoryKeyword = (index) => {
//     const updatedKeywords = [...mandatoryKeywords];
//     updatedKeywords.splice(index, 1);
//     setMandatoryKeywords(updatedKeywords);
//   };

//   // 削除したい文章の追加・削除
//   const handleAddSentenceToDelete = () => {
//     if (sentenceToDeleteInput.trim() !== '') {
//       setSentencesToDelete([...sentencesToDelete, sentenceToDeleteInput.trim()]);
//       setSentenceToDeleteInput('');
//     }
//   };

//   const handleDeleteSentenceToDelete = (index) => {
//     const updatedSentences = [...sentencesToDelete];
//     updatedSentences.splice(index, 1);
//     setSentencesToDelete(updatedSentences);
//   };

//   // 置換単語の追加・削除
//   const handleAddWordToReplace = () => {
//     if (wordToReplaceFrom.trim() !== '' && wordToReplaceTo.trim() !== '') {
//       setWordsToReplace([
//         ...wordsToReplace,
//         { from: wordToReplaceFrom.trim(), to: wordToReplaceTo.trim() },
//       ]);
//       setWordToReplaceFrom('');
//       setWordToReplaceTo('');
//     }
//   };

//   const handleDeleteWordToReplace = (index) => {
//     const updatedWords = [...wordsToReplace];
//     updatedWords.splice(index, 1);
//     setWordsToReplace(updatedWords);
//   };

//   // ファイル選択時の処理
//   const handleFileChange = (event) => {
//     const files = Array.from(event.target.files).slice(0, 5); // 最大5つまで選択
//     setCsvFiles(files);
//     // ファイルの内容を読み込む
//     Promise.all(files.map((file) => file.text())).then((contents) => {
//       setFileContents(contents);
//     });
//   };

//   // 機能選択ダイアログの開閉
//   const handleFunctionDialogOpen = () => {
//     setFunctionDialogOpen(true);
//   };

//   const handleFunctionDialogClose = () => {
//     setFunctionDialogOpen(false);
//   };

//   // 機能の選択・解除
//   const handleFunctionToggle = (func) => () => {
//     const currentIndex = selectedFunctions.findIndex((f) => f.name === func.name);
//     const newSelected = [...selectedFunctions];

//     if (currentIndex === -1) {
//       newSelected.push(func);
//     } else {
//       newSelected.splice(currentIndex, 1);
//     }

//     setSelectedFunctions(newSelected);
//   };

//   // 機能の順序変更（ドラッグ＆ドロップ）
//   const onDragEnd = (result) => {
//     if (!result.destination) return;

//     const newFunctions = Array.from(selectedFunctions);
//     const [removed] = newFunctions.splice(result.source.index, 1);
//     newFunctions.splice(result.destination.index, 0, removed);

//     setSelectedFunctions(newFunctions);
//   };

//   // 価格適用関数をコンポーネント内部に定義
//   const applyPriceChanges = async (currentData) => {
//     try {
//       if (!selectedPriceSettings) {
//         throw new Error('価格設定が取得されていません。');
//       }

//       // 選択された送料テンプレートを設定に反映
//       const updatedPriceSettings = {
//         ...selectedPriceSettings,
//         selectedShippingTemplateId,
//       };

//       const updatedData = await Promise.all(
//         currentData.map(async (file) => {
//           // CSVデータをパース
//           const parsedResult = Papa.parse(file.content, { header: true });
//           const data = parsedResult.data;

//           // 価格計算を実行
//           const newPrices = calculatePrices(
//             data,
//             updatedPriceSettings,
//             selectedShippingTemplateId
//           );

//           // 計算結果を元にデータを更新
//           const updatedData = data.map((item, index) => {
//             const calculatedPrice = newPrices[index];
//             if (calculatedPrice) {
//               return {
//                 ...item,
//                 StartPrice: calculatedPrice.discountedPrice,
//                 BestOfferAutoAcceptPrice: calculatedPrice.bestOfferAutoAcceptPrice,
//                 MinimumBestOfferPrice: calculatedPrice.minimumBestOfferPrice,
//               };
//             } else {
//               return item;
//             }
//           });

//           // CSVに変換
//           const csvContent = Papa.unparse(updatedData);

//           return {
//             ...file,
//             content: csvContent,
//           };
//         })
//       );

//       return updatedData;
//     } catch (error) {
//       console.error('価格適用中にエラーが発生しました:', error);
//       setSnackbar({
//         open: true,
//         message: '価格適用中にエラーが発生しました。',
//         severity: 'error',
//       });
//       throw error;
//     }
//   };

//   // applyAITitlesをラップしてAPIキーを渡す
//   const applyAITitlesWrapper = async (currentData) => {
//     try {
//       if (!userApiKey) {
//         setSnackbar({
//           open: true,
//           message:
//             'OpenAI APIキーが設定されていません。ユーザー設定でAPIキーを入力してください。',
//           severity: 'error',
//         });
//         throw new Error('OpenAI APIキーが設定されていません。');
//       }

//       // 進捗状況の初期化
//       setProgress({ totalItems: 0, completedItems: 0 });
//       setErrorItems([]);

//       const { updatedData, errorItems } = await applyAITitles(
//         currentData,
//         userApiKey,
//         (progressData) => {
//           setProgress(progressData);
//         }
//       );

//       // エラー情報を保存
//       setErrorItems(errorItems);

//       return updatedData;
//     } catch (error) {
//       console.error('AIタイトル適用中にエラーが発生しました:', error);
//       setSnackbar({
//         open: true,
//         message: 'AIタイトル適用中にエラーが発生しました。',
//         severity: 'error',
//       });
//       throw error;
//     }
//   };

//   // フィルター適用関数を定義
//   const applyFilters = (text, selectedFilters, customFilters) => {
//     let filteredText = text;

//     // カスタムフィルターを適用
//     customFilters.forEach((filter) => {
//       const regex = new RegExp(filter, 'g');
//       filteredText = filteredText.replace(regex, '');
//     });

//     // その他のフィルターを適用（必要に応じて実装）

//     return filteredText;
//   };

//   // テキスト変換関数を定義
//   const applyCustomTransformations = (text, sentencesToDelete, wordsToReplace) => {
//     let transformedText = text;

//     // 削除したい文章を適用
//     sentencesToDelete.forEach((sentence) => {
//       if (sentence.trim() !== '') {
//         const escapedSentence = sentence.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
//         const regex = new RegExp(escapedSentence, 'g');
//         transformedText = transformedText.replace(regex, '');
//       }
//     });

//     // 置換単語を適用
//     wordsToReplace.forEach(({ from, to }) => {
//       if (from.trim() !== '') {
//         const escapedFrom = from.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
//         const regex = new RegExp(escapedFrom, 'g');
//         transformedText = transformedText.replace(regex, to);
//       }
//     });

//     return transformedText;
//   };

//   // applyAIProductDescriptionsをラップしてAPIキーとユーザー設定を渡す
//   const applyAIProductDescriptionsWrapper = async (currentData) => {
//     try {
//       if (!userApiKey) {
//         setSnackbar({
//           open: true,
//           message:
//             'OpenAI APIキーが設定されていません。ユーザー設定でAPIキーを入力してください。',
//           severity: 'error',
//         });
//         throw new Error('OpenAI APIキーが設定されていません。');
//       }

//       // 進捗状況の初期化
//       setProgress({ totalItems: 0, completedItems: 0 });
//       setErrorItems([]);

//       const { updatedData, errorItems } = await applyAIProductDescriptions(
//         currentData,
//         userApiKey,
//         (progressData) => {
//           setProgress(progressData);
//         },
//         selectedDesignTemplate, // デザインテンプレートを渡す
//         {
//           customFilters,
//           selectedFilters,
//           sentencesToDelete,
//           wordsToReplace,
//           mandatoryKeywords,
//         } // ユーザー設定を渡す
//       );

//       // エラー情報を保存
//       setErrorItems(errorItems);

//       return updatedData;
//     } catch (error) {
//       console.error('AI商品説明適用中にエラーが発生しました:', error);
//       setSnackbar({
//         open: true,
//         message: 'AI商品説明適用中にエラーが発生しました。',
//         severity: 'error',
//       });
//       throw error;
//     }
//   };

//   // availableFunctions の 'applyPriceChanges' と 'applyAITitles' と 'applyAIProductDescriptions' の func を更新
//   useEffect(() => {
//     availableFunctions.forEach((func) => {
//       if (func.name === 'applyPriceChanges') {
//         func.func = applyPriceChanges;
//       }
//       if (func.name === 'applyAITitles') {
//         func.func = applyAITitlesWrapper;
//       }
//       if (func.name === 'applyAIProductDescriptions') {
//         func.func = applyAIProductDescriptionsWrapper;
//       }
//     });
//   }, [
//     applyPriceChanges,
//     applyAITitlesWrapper,
//     applyAIProductDescriptionsWrapper,
//     customFilters,
//     selectedFilters,
//     sentencesToDelete,
//     wordsToReplace,
//     mandatoryKeywords,
//   ]);

  

  

//   // アップロードおよび実行処理
//   const handleUploadAndExecute = async () => {
//     if (!user) {
//       setSnackbar({
//         open: true,
//         message: 'ユーザーが認証されていません。',
//         severity: 'error',
//       });
//       return;
//     }

//     if (csvFiles.length === 0) {
//       setSnackbar({
//         open: true,
//         message: 'アップロードするファイルがありません。',
//         severity: 'warning',
//       });
//       return;
//     }

//     if (selectedFunctions.length === 0) {
//       setSnackbar({
//         open: true,
//         message: '実行する機能を選択してください。',
//         severity: 'warning',
//       });
//       return;
//     }
    

//     setIsScheduling(true);

//     try {
//       // ファイル名と内容を組み合わせたデータ
//       const filesData = csvFiles.map((file, index) => ({
//         name: file.name,
//         content: fileContents[index],
//       }));

//       const executeFunctions = async () => {
//         let currentData = filesData; // 初期データ

//         for (const func of selectedFunctions) {
//           try {
//             if (func.func.constructor.name === 'AsyncFunction') {
//               if (func.name === 'applyTemplate') {
//                 currentData = await func.func(currentData, user, selectedTemplateName); // user とテンプレート名を渡す
//               } else if (func.name === 'applyItemSpecifics') {
//                 // ここで最新の状態を渡す
//                 currentData = await func.func(currentData, {
//                   selectedItemSpecificsCategory,
//                   selectedItemSpecificsColumns,
//                   itemSpecificsMatchingOptions,
//                 });
//               }else {
//                 currentData = await func.func(currentData);
//               }
//             } else {
//               if (func.name === 'applyTemplate') {
//                 currentData = func.func(currentData, user, selectedTemplateName); // user とテンプレート名を渡す
//               } else if (func.name === 'applyItemSpecifics') {
//                 // ここで最新の状態を渡す
//                 currentData = func.func(currentData, {
//                   selectedItemSpecificsCategory,
//                   selectedItemSpecificsColumns,
//                   itemSpecificsMatchingOptions,
//                 });
//               }               
//               else {
//                 currentData = func.func(currentData);
//               }
//             }
//           } catch (error) {
//             console.error(`機能 "${func.displayName}" の実行中にエラーが発生しました:`, error);
//             setSnackbar({
//               open: true,
//               message: `機能 "${func.displayName}" の実行中にエラーが発生しました。`,
//               severity: 'error',
//             });
//             setIsScheduling(false);
//             return;
//           }
//         }

//         // 処理結果を状態に保存
//         setProcessedData(currentData);

//         setSnackbar({
//           open: true,
//           message: '選択された機能を実行しました。',
//           severity: 'success',
//         });
//         setIsScheduling(false);
//       };

//       if (isImmediate) {
//         // 即時実行の場合
//         await executeFunctions();
//       } else {
//         // スケジュール実行の場合
//         const now = new Date();
//         const scheduledDateTime = new Date(scheduledTime);
//         const delay = scheduledDateTime - now;

//         if (delay > 0) {
//           setSnackbar({
//             open: true,
//             message: 'タスクがスケジュールされました。',
//             severity: 'success',
//           });
//           setTimeout(async () => {
//             await executeFunctions();
//           }, delay);
//         } else {
//           setSnackbar({
//             open: true,
//             message: '指定された時間は過去です。正しい時間を選択してください。',
//             severity: 'error',
//           });
//           setIsScheduling(false);
//         }
//       }
//     } catch (error) {
//       console.error('処理中にエラーが発生しました:', error);
//       setSnackbar({
//         open: true,
//         message: '処理中にエラーが発生しました。',
//         severity: 'error',
//       });
//       setIsScheduling(false);
//     }
//   };

//   // タブの変更
//   const handleTabChange = (event, newValue) => {
//     setActiveTab(newValue);
//   };

//   // CSVデータをパースして状態に保存
//   const [parsedData, setParsedData] = useState([]);

//   useEffect(() => {
//     const parseCSVFiles = () => {
//       const data = fileContents.map((content) => {
//         const parsedResult = Papa.parse(content, { header: true });
//         return {
//           data: parsedResult.data,
//           columns: parsedResult.meta.fields,
//         };
//       });
//       setParsedData(data);
//     };

//     if (fileContents.length > 0) {
//       parseCSVFiles();
//     } else {
//       setParsedData([]);
//     }
//   }, [fileContents]);

//   // 処理後のデータをプレビュー
//   const [processedParsedData, setProcessedParsedData] = useState([]);

//   useEffect(() => {
//     if (processedData.length > 0) {
//       const data = processedData.map((file) => {
//         const parsedResult = Papa.parse(file.content, { header: true });
//         return {
//           name: file.name,
//           data: parsedResult.data,
//           columns: parsedResult.meta.fields,
//         };
//       });
//       setProcessedParsedData(data);
//     } else {
//       setProcessedParsedData([]);
//     }
//   }, [processedData]);

//   // フィルターの変更ハンドラー
//   const handleFilterChange = (label) => {
//     setSelectedFilters((prev) =>
//       prev.includes(label) ? prev.filter((item) => item !== label) : [...prev, label]
//     );
//   };

//   // カスタムフィルターの追加・削除
//   const handleAddCustomFilter = () => {
//     if (customFilterInput.trim() !== '') {
//       setCustomFilters([...customFilters, customFilterInput.trim()]);
//       setCustomFilterInput('');
//     }
//   };

//   const handleDeleteCustomFilter = (index) => {
//     const updatedFilters = [...customFilters];
//     updatedFilters.splice(index, 1);
//     setCustomFilters(updatedFilters);
//   };

  

//   return (
//     <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
//       <Typography variant="h6" gutterBottom>
//         CSVファイルのアップロードとタスクのスケジュール
//       </Typography>

//       {/* ファイル選択 */}
//       <Button variant="contained" component="label">
//         ファイルを選択（最大5つまで）
//         <input
//           type="file"
//           accept=".csv"
//           multiple
//           hidden
//           onChange={handleFileChange}
//         />
//       </Button>

//       {csvFiles.length > 0 && (
//         <Box sx={{ mt: 2 }}>
//           <Typography variant="subtitle1">選択されたファイル:</Typography>
//           <List>
//             {csvFiles.map((file, index) => (
//               <ListItem key={index}>
//                 <Typography>{file.name}</Typography>
//               </ListItem>
//             ))}
//           </List>
//         </Box>
//       )}

//       {/* 機能選択 */}
//       <Button
//         variant="contained"
//         color="primary"
//         sx={{ mt: 2 }}
//         onClick={handleFunctionDialogOpen}
//         startIcon={<MenuIcon />}
//       >
//         実行する機能を選択
//       </Button>

//       {/* --- ここから設定表示を指定された順序で配置します --- */}

//       {/* 1. テンプレート適用 */}
//       {selectedFunctions.some((func) => func.name === 'applyTemplate') && (
//         <Box sx={{ mt: 2 }}>
//           <Typography variant="h6">1. テンプレート適用</Typography>
//           <FormControl fullWidth sx={{ mt: 1 }}>
//             <InputLabel>テンプレートを選択</InputLabel>
//             <Select
//               value={selectedTemplateName}
//               onChange={(e) => setSelectedTemplateName(e.target.value)}
//             >
//               {templateList.map((template) => (
//                 <MenuItem key={template.name} value={template.name}>
//                   {template.name}
//                 </MenuItem>
//               ))}
//             </Select>
//           </FormControl>
//         </Box>
//       )}

//       {/* 2. 価格機能 */}
//       {selectedFunctions.some((func) => func.name === 'applyPriceChanges') && (
//         <Box sx={{ mt: 2 }}>
//           <Typography variant="h6">2. 価格機能</Typography>
//           {shippingRateTemplates.length > 0 && (
//             <FormControl fullWidth sx={{ mt: 1 }}>
//               <InputLabel>送料設定を選択</InputLabel>
//               <Select
//                 value={selectedShippingTemplateId}
//                 onChange={(e) => setSelectedShippingTemplateId(e.target.value)}
//               >
//                 {shippingRateTemplates.map((template) => (
//                   <MenuItem key={template.id} value={template.id}>
//                     {template.name}
//                   </MenuItem>
//                 ))}
//               </Select>
//             </FormControl>
//           )}
//           <Paper elevation={2} sx={{ p: 2, mt: 2 }}>
//             <Typography variant="subtitle1">現在の価格設定</Typography>
//             <Typography>為替レート: 1 USD = {exchangeRate} JPY</Typography>
//             <Typography>目標利益率: {targetProfitMargin}%</Typography>
//             <Typography>
//               eBay最終価値手数料: {fees.ebayFinalValue || 0}%
//             </Typography>
//             <Typography>Payoneer手数料: {fees.payoneer || 0}%</Typography>
//             <Typography>
//               広告出品手数料: {fees.promotedListing || 0}%
//             </Typography>
//             <Typography>
//               eBay取引手数料: {fees.ebayTransactionFee || 0} USD
//             </Typography>
//             <Typography>
//               ベストオファー自動承認価格:{' '}
//               {bestOfferSettings.bestOfferAutoAcceptPercentage || 0}%
//             </Typography>
//             <Typography>
//               最小ベストオファー価格:{' '}
//               {bestOfferSettings.minimumBestOfferPercentage || 0}%
//             </Typography>
//           </Paper>
//         </Box>
//       )}

//       {/* 3. AIタイトル */}
//       {selectedFunctions.some((func) => func.name === 'applyAITitles') && (
//         <Box sx={{ mt: 2 }}>
//           <Typography variant="h6">3. AIタイトル</Typography>
//           {/* 現在、AIタイトルに関する設定は特にないため、このセクションは空です */}
//           <Typography>AIタイトル生成の設定はありません。</Typography>
//         </Box>
//       )}

//       {/* 4. AI商品説明 */}
//       {selectedFunctions.some((func) => func.name === 'applyAIProductDescriptions') && (
//         <Box sx={{ mt: 2 }}>
//           <Typography variant="h6">4. AI商品説明</Typography>
//           <Grid container spacing={2} sx={{ mt: 1 }}>
//             {/* デザインテンプレート選択 */}
//             {designTemplateNames.length > 0 && (
//               <Grid item xs={12} sm={6} md={4}>
//                 <FormControl fullWidth>
//                   <InputLabel>デザインテンプレートを選択</InputLabel>
//                   <Select
//                     value={selectedDesignTemplate}
//                     onChange={(e) => setSelectedDesignTemplate(e.target.value)}
//                   >
//                     {designTemplateNames.map((templateName) => (
//                       <MenuItem key={templateName} value={templateName}>
//                         {templateName}
//                       </MenuItem>
//                     ))}
//                   </Select>
//                 </FormControl>
//                 {/* 現在選択されているデザインテンプレートの表示 */}
//                 {selectedDesignTemplate && (
//                   <Typography variant="body2" sx={{ mt: 1 }}>
//                     選択中のテンプレート: {selectedDesignTemplate}
//                   </Typography>
//                 )}
//               </Grid>
//             )}

//             {/* 画像解析の説明を使用 */}
//             <Grid item xs={12} sm={6} md={4}>
//               <FormControlLabel
//                 control={
//                   <Switch
//                     checked={useImageDescription}
//                     onChange={(e) => setUseImageDescription(e.target.checked)}
//                     name="useImageDescription"
//                     color="primary"
//                   />
//                 }
//                 label="画像解析の説明を使用"
//               />
//             </Grid>

//             {/* 必須キーワード */}
//             <Grid item xs={12} sm={6} md={4}>
//               <Typography variant="subtitle1">必ず含めたいキーワード</Typography>
//               <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
//                 <TextField
//                   label="キーワードを入力"
//                   value={mandatoryKeywordInput}
//                   onChange={(e) => setMandatoryKeywordInput(e.target.value)}
//                   fullWidth
//                 />
//                 <Button
//                   variant="contained"
//                   color="primary"
//                   onClick={handleAddMandatoryKeyword}
//                   sx={{ ml: 1, height: '56px' }}
//                 >
//                   追加
//                 </Button>
//               </Box>
//               <List>
//                 {mandatoryKeywords.map((keyword, index) => (
//                   <ListItem
//                     key={index}
//                     secondaryAction={
//                       <IconButton
//                         edge="end"
//                         aria-label="delete"
//                         onClick={() => handleDeleteMandatoryKeyword(index)}
//                       >
//                         <DeleteIcon />
//                       </IconButton>
//                     }
//                   >
//                     <ListItemText primary={keyword} />
//                   </ListItem>
//                 ))}
//               </List>
//             </Grid>

//             {/* カスタムフィルター、削除したい文章、置換単語を横並びに配置 */}
//             <Grid item xs={12}>
//               <Grid container spacing={2}>
//                 {/* カスタムフィルター */}
//                 <Grid item xs={12} sm={4}>
//                   <OptionsPanel
//                     selectedFilters={selectedFilters}
//                     handleFilterChange={handleFilterChange}
//                     customFilterInput={customFilterInput}
//                     setCustomFilterInput={setCustomFilterInput}
//                     customFilters={customFilters}
//                     handleAddCustomFilter={handleAddCustomFilter}
//                     handleDeleteCustomFilter={handleDeleteCustomFilter}
//                     sentencesToDelete={sentencesToDelete}
//                     setSentencesToDelete={setSentencesToDelete}
//                     wordsToReplace={wordsToReplace}
//                     setWordsToReplace={setWordsToReplace}
//                   />
//                 </Grid>

//                 {/* 削除したい文章 */}
//                 <Grid item xs={12} sm={4}>
//                   <Typography variant="subtitle1">削除したい文章</Typography>
//                   <TextField
//                     label="文章を入力"
//                     value={sentenceToDeleteInput}
//                     onChange={(e) => setSentenceToDeleteInput(e.target.value)}
//                     fullWidth
//                   />
//                   <Button
//                     variant="contained"
//                     color="primary"
//                     onClick={handleAddSentenceToDelete}
//                     sx={{ mt: 1 }}
//                   >
//                     追加
//                   </Button>
//                   <List>
//                     {sentencesToDelete.map((sentence, index) => (
//                       <ListItem
//                         key={index}
//                         secondaryAction={
//                           <IconButton
//                             edge="end"
//                             aria-label="delete"
//                             onClick={() => handleDeleteSentenceToDelete(index)}
//                           >
//                             <DeleteIcon />
//                           </IconButton>
//                         }
//                       >
//                         <ListItemText primary={sentence} />
//                       </ListItem>
//                     ))}
//                   </List>
//                 </Grid>

//                 {/* 置換単語 */}
//                 <Grid item xs={12} sm={4}>
//                   <Typography variant="subtitle1">置換単語</Typography>
//                   <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
//                     <TextField
//                       label="置換前"
//                       value={wordToReplaceFrom}
//                       onChange={(e) => setWordToReplaceFrom(e.target.value)}
//                       fullWidth
//                     />
//                     <Typography sx={{ mx: 1 }}>➔</Typography>
//                     <TextField
//                       label="置換後"
//                       value={wordToReplaceTo}
//                       onChange={(e) => setWordToReplaceTo(e.target.value)}
//                       fullWidth
//                     />
//                   </Box>
//                   <Button
//                     variant="contained"
//                     color="primary"
//                     onClick={handleAddWordToReplace}
//                     sx={{ mt: 1 }}
//                   >
//                     追加
//                   </Button>
//                   <List>
//                     {wordsToReplace.map((wordPair, index) => (
//                       <ListItem
//                         key={index}
//                         secondaryAction={
//                           <IconButton
//                             edge="end"
//                             aria-label="delete"
//                             onClick={() => handleDeleteWordToReplace(index)}
//                           >
//                             <DeleteIcon />
//                           </IconButton>
//                         }
//                       >
//                         <ListItemText primary={`${wordPair.from} ➔ ${wordPair.to}`} />
//                       </ListItem>
//                     ))}
//                   </List>
//                 </Grid>
//               </Grid>
//             </Grid>

//             {/* テンプレート管理のUI */}
//             <Grid item xs={12}>
//               <Box sx={{ mt: 2 }}>
//                 <Typography variant="subtitle1">テンプレートの管理</Typography>
//                 <Button
//                   variant="contained"
//                   color="primary"
//                   onClick={() => {
//                     setIsSaveTemplateDialogOpen(true);
//                     setSaveOption(selectedTemplateId ? 'overwrite' : 'new');
//                   }}
//                 >
//                   テンプレートとして保存
//                 </Button>
//                 <Button
//                   variant="outlined"
//                   color="primary"
//                   onClick={() => setIsManageTemplatesDialogOpen(true)}
//                   sx={{ ml: 1 }}
//                 >
//                   テンプレートを管理
//                 </Button>
//                 <Button
//                   variant="outlined"
//                   color="secondary"
//                   onClick={handleCreateNewTemplate}
//                   sx={{ ml: 1, mt: 1 }}
//                 >
//                   新しいテンプレートを作成
//                 </Button>

//                 {/* テンプレート選択 */}
//                 <FormControl fullWidth sx={{ mt: 2 }}>
//                   <InputLabel>テンプレートを選択</InputLabel>
//                   <Select
//                     value={selectedTemplateId}
//                     onChange={(e) => handleLoadTemplate(e.target.value)}
//                   >
//                     <MenuItem value="">
//                       <em>テンプレートを選択</em>
//                     </MenuItem>
//                     {templates.map((template) => (
//                       <MenuItem key={template.id} value={template.id}>
//                         {template.name}
//                       </MenuItem>
//                     ))}
//                   </Select>
//                 </FormControl>
//               </Box>
//             </Grid>
//           </Grid>

//           {/* ユーザー設定の表示 */}
//           <Grid item xs={12}>
//             <Paper elevation={2} sx={{ p: 2, mt: 2 }}>
//               <Typography variant="h6">現在のユーザー設定</Typography>
//               <Grid container spacing={2}>
//                 {/* カスタムフィルター */}
//                 {customFilters && customFilters.length > 0 && (
//                   <Grid item xs={12} sm={6} md={3}>
//                     <Typography variant="subtitle1">カスタムフィルター:</Typography>
//                     <List>
//                       {customFilters.map((filter, index) => (
//                         <ListItem key={index}>
//                           <Typography>{filter}</Typography>
//                         </ListItem>
//                       ))}
//                     </List>
//                   </Grid>
//                 )}

//                 {/* 削除したい文章 */}
//                 {sentencesToDelete && sentencesToDelete.length > 0 && (
//                   <Grid item xs={12} sm={6} md={3}>
//                     <Typography variant="subtitle1">削除したい文章:</Typography>
//                     <List>
//                       {sentencesToDelete.map((sentence, index) => (
//                         <ListItem key={index}>
//                           <Typography>{sentence}</Typography>
//                         </ListItem>
//                       ))}
//                     </List>
//                   </Grid>
//                 )}

//                 {/* 置換単語 */}
//                 {wordsToReplace && wordsToReplace.length > 0 && (
//                   <Grid item xs={12} sm={6} md={3}>
//                     <Typography variant="subtitle1">置換単語:</Typography>
//                     <List>
//                       {wordsToReplace.map((wordPair, index) => (
//                         <ListItem key={index}>
//                           <Typography>
//                             {wordPair.from} ➔ {wordPair.to}
//                           </Typography>
//                         </ListItem>
//                       ))}
//                     </List>
//                   </Grid>
//                 )}

//                 {/* 必須キーワード */}
//                 {mandatoryKeywords && mandatoryKeywords.length > 0 && (
//                   <Grid item xs={12} sm={6} md={3}>
//                     <Typography variant="subtitle1">必須キーワード:</Typography>
//                     <List>
//                       {mandatoryKeywords.map((keyword, index) => (
//                         <ListItem key={index}>
//                           <Typography>{keyword}</Typography>
//                         </ListItem>
//                       ))}
//                     </List>
//                   </Grid>
//                 )}
//               </Grid>
//             </Paper>
//           </Grid>
//         </Box>
//       )}

//       {/* デザインテンプレート選択 */}
//           <FormControl fullWidth sx={{ mt: 2 }}>
//             <InputLabel>デザインテンプレートを選択</InputLabel>
//       <Select
//         value={selectedTemplate}
//         onChange={(e) => setSelectedTemplate(e.target.value)}
//         label="デザインテンプレートを選択"
//       >
//         {Object.entries(designTemplates).map(([key, template]) => (
//           <MenuItem key={key} value={key}>
//             {template.name}
//           </MenuItem>
//         ))}
//       </Select>
//     </FormControl>

//       {/* Item Specifics適用 */}
//       {selectedFunctions.some((func) => func.name === 'applyItemSpecifics') && (
//         <Box sx={{ mt: 2 }}>
//           <Typography variant="h6">5. Item Specifics適用</Typography>
//           {/* カテゴリー選択 */}
//           <FormControl fullWidth sx={{ mt: 1 }}>
//             <InputLabel>適用するカテゴリー</InputLabel>
//             <Select
//               value={selectedItemSpecificsCategory}
//               onChange={(e) => {
//                 console.log('選択されたカテゴリー:', e.target.value);
//                 setSelectedItemSpecificsCategory(e.target.value);
//                 setSelectedItemSpecificsColumns([]); // カラム選択をリセット
//               }}
//               label="適用するカテゴリー"
//             >
//               {Object.keys(itemSpecificsCategories).map((category) => (
//                 <MenuItem key={category} value={category}>
//                   {category}
//                 </MenuItem>
//               ))}
//             </Select>
//           </FormControl>

//           {/* カラム選択 */}
//           {selectedItemSpecificsCategory && (
//             <FormControl fullWidth sx={{ mt: 2 }}>
//               <InputLabel>適用するカラムを選択</InputLabel>
//               <Select
//                 multiple
//                 value={selectedItemSpecificsColumns}
//                 onChange={(e) => {
//                   const value = e.target.value;
//                   if (value.includes('all')) {
//                     // Handle "select all"
//                     const allColumns = getColumnsForSelectedCategory();
//                     if (selectedItemSpecificsColumns.length === allColumns.length) {
//                       // Deselect all if all are already selected
//                       setSelectedItemSpecificsColumns([]);
//                     } else {
//                       // Select all columns
//                       setSelectedItemSpecificsColumns(allColumns);
//                     }
//                   } else {
//                     setSelectedItemSpecificsColumns(value);
//                   }
//                 }}
//                 renderValue={(selected) => {
//                   if (selected.length === 0) {
//                     return '適用するカラムを選択';
//                   }

//                   const displayedValues = selected.slice(0, 5); // Show up to 5 selected columns
//                   const hiddenCount = selected.length - displayedValues.length;

//                   return (
//                     <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
//                       {displayedValues.map((value) => (
//                         <Chip key={value} label={value} size="small" />
//                       ))}
//                       {hiddenCount > 0 && (
//                         <Chip label={`+${hiddenCount}個`} size="small" />
//                       )}
//                     </Box>
//                   );
//                 }}
//               >
//                 <MenuItem
//                   value="all"
//                   onClick={() => {
//                     const allColumns = getColumnsForSelectedCategory();
//                     if (selectedItemSpecificsColumns.length === allColumns.length) {
//                       // Deselect all if all are already selected
//                       setSelectedItemSpecificsColumns([]);
//                     } else {
//                       // Select all columns
//                       setSelectedItemSpecificsColumns(allColumns);
//                     }
//                   }}
//                 >
//                   <Checkbox
//                     checked={
//                       selectedItemSpecificsColumns.length === getColumnsForSelectedCategory().length &&
//                       selectedItemSpecificsColumns.length > 0
//                     }
//                     indeterminate={
//                       selectedItemSpecificsColumns.length > 0 &&
//                       selectedItemSpecificsColumns.length < getColumnsForSelectedCategory().length
//                     }
//                   />
//                   <Typography variant="body1">全選択</Typography>
//                 </MenuItem>
//                 {getColumnsForSelectedCategory().map((column) => (
//                   <MenuItem key={column} value={column}>
//                     <Checkbox
//                       checked={selectedItemSpecificsColumns.indexOf(column) > -1}
//                     />
//                     <Typography variant="body1">{column}</Typography>
//                   </MenuItem>
//                 ))}
//               </Select>
//             </FormControl>
//           )}

//           {/* マッチングオプション */}
//           {selectedItemSpecificsCategory && (
//             <>
//               <Box sx={{ mt: 2 }}>
//                 <Typography variant="subtitle1" gutterBottom>
//                   マッチングの基準を選択してください。
//                 </Typography>
//                 <FormControl component="fieldset">
//                   <RadioGroup
//                     value={itemSpecificsMatchingOptions.matchSource}
//                     onChange={(e) =>
//                       setItemSpecificsMatchingOptions((prev) => ({
//                         ...prev,
//                         matchSource: e.target.value,
//                       }))
//                     }
//                   >
//                     <FormControlLabel
//                       value="title"
//                       control={<Radio />}
//                       label="タイトルのみ"
//                     />
//                     <FormControlLabel
//                       value="description"
//                       control={<Radio />}
//                       label="商品説明のみ (AI生成部分のみ)"
//                     />
//                     <FormControlLabel
//                       value="both"
//                       control={<Radio />}
//                       label="タイトルと商品説明の両方 (AI生成部分のみ)"
//                     />
//                   </RadioGroup>
//                 </FormControl>
//               </Box>

//               {/* オプション */}
//               <Box sx={{ mt: 2 }}>
//                 <FormControlLabel
//                   control={
//                     <Checkbox
//                       checked={itemSpecificsMatchingOptions.caseSensitive}
//                       onChange={(e) =>
//                         setItemSpecificsMatchingOptions((prev) => ({
//                           ...prev,
//                           caseSensitive: e.target.checked,
//                         }))
//                       }
//                       name="caseSensitive"
//                     />
//                   }
//                   label="大文字小文字を区別する"
//                 />
//                 <FormControlLabel
//                   control={
//                     <Checkbox
//                       checked={itemSpecificsMatchingOptions.partialMatch}
//                       onChange={(e) =>
//                         setItemSpecificsMatchingOptions((prev) => ({
//                           ...prev,
//                           partialMatch: e.target.checked,
//                         }))
//                       }
//                       name="partialMatch"
//                     />
//                   }
//                   label="部分一致を許可する"
//                 />
//               </Box>

//               {/* マッチングの動作を選択 */}
//               <Box sx={{ mt: 2 }}>
//                 <Typography variant="subtitle1" gutterBottom>
//                   マッチングの動作を選択してください。
//                 </Typography>
//                 <Typography variant="subtitle1" gutterBottom>
//                   (上記でAI商品説明からマッチングを選択している場合は、自動処理でAI生成した文章からカラムの適所に適用されます。自動処理によって特に値の変更がなかった場合以下の設定が適用されます。)
//                 </Typography>
//                 <FormControl component="fieldset">
//                   <RadioGroup
//                     value={itemSpecificsMatchingOptions.matchingOption}
//                     onChange={(e) =>
//                       setItemSpecificsMatchingOptions((prev) => ({
//                         ...prev,
//                         matchingOption: e.target.value,
//                       }))
//                     }
//                   >
//                     {/* <FormControlLabel
//                       value="first"
//                       control={<Radio />}
//                       label="マッチした最初の値のみを適用"
//                     /> */}
//                     <FormControlLabel
//                       value="priority"
//                       control={<Radio />}
//                       label="優先順位に基づいて適用"
//                     />
//                     <FormControlLabel
//                       value="all"
//                       control={<Radio />}
//                       label="マッチしたすべての値を適用"
//                     />
//                   </RadioGroup>
//                 </FormControl>
//               </Box>
//             </>
//           )}
//         </Box>
//       )}

//       {/* --- ここまで設定表示 --- */}

//       {/* 実行モードの選択 */}
//       <Box sx={{ mt: 2 }}>
//         <Typography variant="subtitle1">実行モード:</Typography>
//         <Button
//           variant={isImmediate ? 'contained' : 'outlined'}
//           onClick={() => setIsImmediate(true)}
//           sx={{ mr: 1 }}
//         >
//           即時実行
//         </Button>
//         <Button
//           variant={!isImmediate ? 'contained' : 'outlined'}
//           onClick={() => setIsImmediate(false)}
//         >
//           スケジュール実行
//         </Button>
//       </Box>

//       {/* スケジュール時間の選択 */}
//       {!isImmediate && (
//         <Box sx={{ mt: 2 }}>
//           <TextField
//             label="実行開始時間"
//             type="datetime-local"
//             value={scheduledTime}
//             onChange={(e) => setScheduledTime(e.target.value)}
//             InputLabelProps={{
//               shrink: true,
//             }}
//             fullWidth
//           />
//         </Box>
//       )}

//       {/* アップロード＆実行ボタン */}
//       <Button
//         variant="contained"
//         color="secondary"
//         sx={{ mt: 2 }}
//         onClick={handleUploadAndExecute}
//         disabled={isScheduling}
//       >
//         {isScheduling ? '処理中...' : 'アップロードして実行'}
//       </Button>

//       {/* 進捗状況の表示 */}
//       {isScheduling && (
//         <Box sx={{ mt: 2 }}>
//           <Typography variant="h6">処理進捗:</Typography>
//           <Typography>
//             {progress.completedItems} / {progress.totalItems} 件が完了しました
//           </Typography>
//           <LinearProgress
//             variant="determinate"
//             value={(progress.completedItems / progress.totalItems) * 100}
//           />
//         </Box>
//       )}

//       {/* 処理結果のプレビュー */}
//       {processedParsedData.length > 0 && (
//         <Box sx={{ mt: 4 }}>
//           <Typography variant="h6">処理結果のプレビュー:</Typography>
//           <Tabs
//             value={activeTab}
//             onChange={handleTabChange}
//             variant="scrollable"
//             scrollButtons="auto"
//           >
//             {processedParsedData.map((file, index) => (
//               <Tab key={index} label={file.name} />
//             ))}
//           </Tabs>
//           {processedParsedData.map((file, index) => (
//             <div
//               key={index}
//               role="tabpanel"
//               hidden={activeTab !== index}
//               id={`tabpanel-${index}`}
//             >
//               {activeTab === index && (
//                 <Box sx={{ p: 2 }}>
//                   <Paper elevation={2} sx={{ overflowX: 'auto' }}>
//                     <TableContainer>
//                       <Table
//                         size="small"
//                         sx={{
//                           tableLayout: 'auto',
//                           width: 'auto',
//                         }}
//                       >
//                         <TableHead>
//                           <TableRow>
//                             {file.columns.map((column, idx) => (
//                               <TableCell
//                                 key={idx}
//                                 sx={{
//                                   maxWidth: '150px',
//                                   whiteSpace: 'nowrap',
//                                   padding: '8px',
//                                   border: '1px solid rgba(224, 224, 224, 1)',
//                                   fontWeight: 'bold',
//                                 }}
//                               >
//                                 {column}
//                               </TableCell>
//                             ))}
//                           </TableRow>
//                         </TableHead>
//                         <TableBody>
//                           {file.data.map((row, rowIndex) => (
//                             <TableRow key={rowIndex}>
//                               {file.columns.map((column, colIndex) => (
//                                 <TableCell
//                                   key={colIndex}
//                                   sx={{
//                                     maxWidth: '150px',
//                                     whiteSpace: 'nowrap',
//                                     padding: '8px',
//                                     border: '1px solid rgba(224, 224, 224, 1)',
//                                   }}
//                                 >
//                                   {row[column]}
//                                 </TableCell>
//                               ))}
//                             </TableRow>
//                           ))}
//                         </TableBody>
//                       </Table>
//                     </TableContainer>
//                   </Paper>
//                 </Box>
//               )}
//             </div>
//           ))}
//         </Box>
//       )}

//       {/* エラー情報の表示 */}
//       {errorItems.length > 0 && (
//         <Box sx={{ mt: 2 }}>
//           <Typography variant="h6" color="error">
//             エラーが発生したアイテム:
//           </Typography>
//           <List>
//             {errorItems.map((item, index) => (
//               <ListItem key={index}>
//                 <Typography>
//                   ファイル名: {item.fileName}, 行番号: {item.rowIndex}, エラー: {item.error}
//                 </Typography>
//               </ListItem>
//             ))}
//           </List>
//         </Box>
//       )}

//       {/* 処理結果のダウンロード */}
//       {processedData.length > 0 && (
//         <Box sx={{ mt: 2 }}>
//           <Typography variant="h6">処理結果のダウンロード:</Typography>
//           {processedData.map((file, index) => (
//             <Button
//               key={index}
//               variant="contained"
//               sx={{ mt: 1, mr: 1 }}
//               onClick={() => {
//                 const blob = new Blob([file.content], {
//                   type: 'text/csv;charset=utf-8;',
//                 });
//                 const link = document.createElement('a');
//                 link.href = URL.createObjectURL(blob);
//                 link.setAttribute('download', file.name);
//                 document.body.appendChild(link);
//                 link.click();
//                 document.body.removeChild(link);
//               }}
//             >
//               {file.name}
//             </Button>
//           ))}
//         </Box>
//       )}

//       {/* 機能選択ダイアログ */}
//       <Dialog open={functionDialogOpen} onClose={handleFunctionDialogClose}>
//         <DialogTitle>実行する機能を選択</DialogTitle>
//         <DialogContent>
//           <DragDropContext onDragEnd={onDragEnd}>
//             <Droppable droppableId="functions">
//               {(provided) => (
//                 <List {...provided.droppableProps} ref={provided.innerRef}>
//                   {availableFunctions.map((func, index) => (
//                     <Draggable key={func.name} draggableId={func.name} index={index}>
//                       {(provided) => (
//                         <ListItem
//                           button
//                           ref={provided.innerRef}
//                           {...provided.draggableProps}
//                         >
//                           <Checkbox
//                             checked={
//                               selectedFunctions.findIndex((f) => f.name === func.name) !== -1
//                             }
//                             onClick={handleFunctionToggle(func)}
//                           />
//                           <Typography>{func.displayName}</Typography>
//                           <IconButton {...provided.dragHandleProps}>
//                             <DragHandleIcon />
//                           </IconButton>
//                         </ListItem>
//                       )}
//                     </Draggable>
//                   ))}
//                   {provided.placeholder}
//                 </List>
//               )}
//             </Droppable>
//           </DragDropContext>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={handleFunctionDialogClose}>閉じる</Button>
//         </DialogActions>
//       </Dialog>

//       {/* テンプレート保存ダイアログ */}
//       <Dialog
//         open={isSaveTemplateDialogOpen}
//         onClose={() => setIsSaveTemplateDialogOpen(false)}
//       >
//         <DialogTitle>テンプレートの保存</DialogTitle>
//         <DialogContent>
//           <FormControl component="fieldset">
//             <RadioGroup
//               value={saveOption}
//               onChange={(e) => setSaveOption(e.target.value)}
//             >
//               <FormControlLabel
//                 value="new"
//                 control={<Radio />}
//                 label="新しいテンプレートとして保存"
//               />
//               {selectedTemplateId && (
//                 <FormControlLabel
//                   value="overwrite"
//                   control={<Radio />}
//                   label="既存のテンプレートを上書き保存"
//                 />
//               )}
//             </RadioGroup>
//           </FormControl>
//           <TextField
//             label="テンプレート名"
//             value={templateName}
//             onChange={(e) => setTemplateName(e.target.value)}
//             fullWidth
//             sx={{ mt: 2 }}
//           />
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => setIsSaveTemplateDialogOpen(false)}>
//             キャンセル
//           </Button>
//           <Button onClick={saveTemplate} color="primary">
//             保存
//           </Button>
//         </DialogActions>
//       </Dialog>

//       {/* テンプレート管理ダイアログ */}
//       <Dialog
//         open={isManageTemplatesDialogOpen}
//         onClose={() => setIsManageTemplatesDialogOpen(false)}
//       >
//         <DialogTitle>テンプレートの管理</DialogTitle>
//         <DialogContent>
//           <List>
//             {templates.map((template) => (
//               <ListItem key={template.id}>
//                 <ListItemText primary={template.name} />
//                 <IconButton
//                   edge="end"
//                   aria-label="delete"
//                   onClick={() => handleDeleteTemplate(template.id)}
//                 >
//                   <DeleteIcon />
//                 </IconButton>
//               </ListItem>
//             ))}
//           </List>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => setIsManageTemplatesDialogOpen(false)}>
//             閉じる
//           </Button>
//         </DialogActions>
//       </Dialog>

//       {/* Snackbarによる通知 */}
//       <Snackbar
//         open={snackbar.open}
//         autoHideDuration={6000}
//         onClose={() => setSnackbar({ ...snackbar, open: false })}
//       >
//         <Alert
//           onClose={() => setSnackbar({ ...snackbar, open: false })}
//           severity={snackbar.severity}
//           sx={{ width: '100%' }}
//         >
//           {snackbar.message}
//         </Alert>
//       </Snackbar>
//     </Paper>
//   );
// }

// export default CsvSchedulerUploader;