// ImageOperations.js

import React, { useState, useCallback, useEffect, useRef, useMemo } from "react";
import {
  Button,
  Typography,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Grid,
  IconButton,
  TextField,
  MenuItem,
  Select,
  FormControl,
  Pagination,
  Snackbar,
  DialogContentText,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import DescriptionIcon from "@mui/icons-material/Description";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import {
  getStorage,
  ref as storageRef,
  uploadBytes,
  getDownloadURL,
} from "firebase/storage";
import axios from "axios";
import performImageAnalysis from "./ImageAnalysis"; // 画像解析関数をインポート
import ImageAnalysis from "./ImageAnalysis";
import { v4 as uuidv4 } from 'uuid';


function ImageOperations({
  data,
  setData,
  startRow,
  endRow,
  calculateMaxEndRow,
  isProcessing,
  setIsProcessing,
  setError,
  showSnackbar, // プロパティとして渡される
  openPreviewDialog,
  setOpenPreviewDialog,
  apiKey, // OpenAIのAPIキー
}) {
  const [previewImages, setPreviewImages] = useState([]);
  const [displayedPreviewImages, setDisplayedPreviewImages] = useState([]);
  const [selectedImages, setSelectedImages] = useState(new Set());
  const [selectedProductForEdit, setSelectedProductForEdit] = useState(null);
  const [isAddImageDialogOpen, setIsAddImageDialogOpen] = useState(false);
  const [newImageFile, setNewImageFile] = useState(null);
  const [newImageURL, setNewImageURL] = useState("");
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  const [itemsPerPage, setItemsPerPage] = useState(50); // 1ページあたりの表示件数
  const [currentPage, setCurrentPage] = useState(1); // 現在のページ番号

  const [previewDialogMode, setPreviewDialogMode] = useState("edit"); // 'edit' または 'analyze'
  const [isAnalyzing, setIsAnalyzing] = useState(false);

  const theme = useTheme();

  // スクロール可能なコンテナのrefを追加
  const scrollableContainerRef = useRef(null);
  const [showScrollTopButton, setShowScrollTopButton] = useState(false); // スクロールトップボタンの表示制御

  // 追加: 削除された画像の情報を保存する状態
  const [lastDeletedImage, setLastDeletedImage] = useState(null);
  const [snackbarState, setSnackbarState] = useState({
    open: false,
    message: "",
  });

  // 追加: 削除された商品の情報を保存する状態
  const [deletedProducts, setDeletedProducts] = useState([]);
  const [lastDeletedProduct, setLastDeletedProduct] = useState(null);
  const [openDeletedProductsDialog, setOpenDeletedProductsDialog] = useState(false);

  // 商品IDを管理するための状態（uniqueIdを使用しないので削除）

  // 追加: 削除中の商品のインデックスを管理するステート
  const [deletingProductIndices, setDeletingProductIndices] = useState(new Set());

  // titleKey と picUrlKey を定義
  const titleKey = useMemo(() => {
    if (!data || data.length === 0) return null;
    return Object.keys(data[0]).find((key) => key.toLowerCase() === "title");
  }, [data]);

  const picUrlKey = useMemo(() => {
    if (!data || data.length === 0) return null;
    return Object.keys(data[0]).find((key) => key.toLowerCase() === "picurl");
  }, [data]);

  const handleOpenPreviewDialog = useCallback(() => {
    try {
      if (!startRow || !endRow || isNaN(startRow) || isNaN(endRow)) {
        throw new Error("Start RowとEnd Rowは有効な数字である必要があります。");
      }

      if (!picUrlKey) {
        throw new Error("PicURLカラムが見つかりません。");
      }

      const actualStartRow = parseInt(startRow) - 1; // インデックスは0から開始
      const maxEndRow = calculateMaxEndRow();
      const actualEndRow = Math.min(parseInt(endRow) - 1, maxEndRow - 1);

      if (actualStartRow < 0 || actualEndRow < actualStartRow) {
        throw new Error("Start RowとEnd Rowが有効ではありません。");
      }

      let previewItems = [];

      // すべての画像を取得
      for (let i = actualStartRow; i <= actualEndRow; i++) {
        const item = data[i];
        const picUrl = item[picUrlKey];
        const imageUrls = picUrl ? picUrl.split("|") : [];
        const title = item[titleKey] || `商品 ${i + 1}`;
        const images = imageUrls.map((url, idx) => ({
          url,
          imageIndex: idx + 1,
        }));
        if (images.length > 0) {
          previewItems.push({
            title,
            rowIndex: i,
            images,
          });
        }
      }

      setPreviewImages(previewItems);
      setSelectedImages(new Set()); // すべて未選択
      // setCurrentPage(1); // ページをリセット
    } catch (err) {
      const errorMessage = `プレビューの準備中にエラーが発生しました: ${err.message}`;
      setError(errorMessage);
      console.error(errorMessage, err);
    }
  }, [data, startRow, endRow, calculateMaxEndRow, setError, titleKey, picUrlKey]);

  // openPreviewDialogがtrueになったときにプレビューを準備
  useEffect(() => {
    if (openPreviewDialog) {
      handleOpenPreviewDialog();
    } else {
      // ダイアログが閉じられたときにプレビュー画像をクリア
      setPreviewImages([]);
      setSelectedImages(new Set());
    }
  }, [openPreviewDialog, handleOpenPreviewDialog]);

  // ページネーションの処理
  useEffect(() => {
    if (itemsPerPage === "ALL") {
      setDisplayedPreviewImages(previewImages);
    } else {
      const startIndex = (currentPage - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      setDisplayedPreviewImages(previewImages.slice(startIndex, endIndex));
    }
  }, [previewImages, currentPage, itemsPerPage]);

  // currentPageが変更されたときにスクロール位置をトップに戻す
  useEffect(() => {
    if (scrollableContainerRef.current) {
      scrollableContainerRef.current.scrollTop = 0;
    }
  }, [currentPage]);

  // スクロール位置に応じてスクロールトップボタンの表示を制御
  useEffect(() => {
    const handleScroll = () => {
      if (scrollableContainerRef.current) {
        const scrollTop = scrollableContainerRef.current.scrollTop;
        setShowScrollTopButton(scrollTop > 200);
      }
    };

    const container = scrollableContainerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
      return () => container.removeEventListener("scroll", handleScroll);
    }
  }, [scrollableContainerRef]);

  // Snackbarの自動非表示に合わせてlastDeletedImageとlastDeletedProductをクリア
  useEffect(() => {
    if (snackbarState.open && (lastDeletedImage || lastDeletedProduct)) {
      const timer = setTimeout(() => {
        setLastDeletedImage(null);
        setLastDeletedProduct(null);
      }, 6000); // SnackbarのautoHideDurationと同じ

      return () => clearTimeout(timer);
    }
  }, [snackbarState.open, lastDeletedImage, lastDeletedProduct]);

  const totalPages =
    itemsPerPage === "ALL" ? 1 : Math.ceil(previewImages.length / itemsPerPage);

  const handleItemsPerPageChange = (event) => {
    const value =
      event.target.value === "ALL" ? "ALL" : parseInt(event.target.value);
    setItemsPerPage(value);
    setCurrentPage(1); // ページをリセット
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const toggleImageSelection = (url) => {
    setSelectedImages((prevSelected) => {
      const newSelected = new Set(prevSelected);
      if (newSelected.has(url)) {
        newSelected.delete(url);
      } else {
        newSelected.add(url);
      }
      return newSelected;
    });
  };

  const handleDelete = useCallback(async () => {
    setIsProcessing(true);
    setError("");

    try {
      if (!startRow || !endRow || isNaN(startRow) || isNaN(endRow)) {
        throw new Error("Start RowとEnd Rowは有効な数字である必要があります。");
      }

      if (!picUrlKey) {
        throw new Error("PicURLカラムが見つかりません。");
      }

      const actualStartRow = parseInt(startRow) - 1; // インデックスは0から開始
      const maxEndRow = calculateMaxEndRow();
      const actualEndRow = Math.min(parseInt(endRow) - 1, maxEndRow - 1);

      if (actualStartRow < 0 || actualEndRow < actualStartRow) {
        throw new Error("Start RowとEnd Rowが有効ではありません。");
      }

      // データの更新
      const updatedData = [...data];
      for (let i = actualStartRow; i <= actualEndRow; i++) {
        const item = { ...updatedData[i] };
        const originalImages = item[picUrlKey]
          ? item[picUrlKey].split("|")
          : [];
        const newImages = originalImages.filter(
          (url) => !selectedImages.has(url)
        );
        item[picUrlKey] = newImages.join("|");
        updatedData[i] = item;
      }

      setData(updatedData);

      // プレビュー画像を更新
      const updatedPreviewImages = previewImages.map((product) => {
        const item = updatedData[product.rowIndex];
        const picUrl = item[picUrlKey];
        const imageUrls = picUrl ? picUrl.split("|") : [];
        const images = imageUrls.map((url, idx) => ({
          url,
          imageIndex: idx + 1,
        }));
        return {
          ...product,
          images,
        };
      });
      setPreviewImages(updatedPreviewImages);

      const finalMessage = `画像の削除が完了しました。`;
      showSnackbar(finalMessage);
    } catch (err) {
      const errorMessage = `画像の削除に失敗しました: ${err.message}`;
      setError(errorMessage);
      showSnackbar(errorMessage);
      console.error(errorMessage, err);
    } finally {
      setIsProcessing(false);
      setOpenConfirmDialog(false);
      setOpenPreviewDialog(false);
    }
  }, [
    data,
    startRow,
    endRow,
    selectedImages,
    calculateMaxEndRow,
    setData,
    previewImages,
    setIsProcessing,
    setError,
    showSnackbar,
    setOpenPreviewDialog,
    picUrlKey,
  ]);

  const handleAddImageClick = (product) => {
    setSelectedProductForEdit(product);
    setIsAddImageDialogOpen(true);
  };

  const handleImageFileChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setNewImageFile(e.target.files[0]);
      setNewImageURL("");
    }
  };

  const handleAddImage = async () => {
    const storage = getStorage();

    try {
      let imageUrl = newImageURL;

      if (newImageFile) {
        // Firebase Storage に画像をアップロード
        const fileExtension = newImageFile.name.split(".").pop();
        const storageReference = storageRef(
          storage,
          `images/${uuidv4()}.${fileExtension}`
        );
        await uploadBytes(storageReference, newImageFile);
        imageUrl = await getDownloadURL(storageReference);
      }

      if (!imageUrl) {
        alert(
          "画像のURLを入力するか、画像ファイルをアップロードしてください。"
        );
        return;
      }

      // データの更新
      const updatedData = [...data];
      const item = { ...updatedData[selectedProductForEdit.rowIndex] };
      const imageUrls = item[picUrlKey] ? item[picUrlKey].split("|") : [];
      imageUrls.push(imageUrl);
      item[picUrlKey] = imageUrls.join("|");
      updatedData[selectedProductForEdit.rowIndex] = item;
      setData(updatedData);

      // previewImagesを更新
      const updatedPreviewImages = previewImages.map((product) => {
        if (product.rowIndex === selectedProductForEdit.rowIndex) {
          const images = [
            ...product.images,
            { url: imageUrl, imageIndex: product.images.length + 1 },
          ];
          return {
            ...product,
            images,
          };
        }
        return product;
      });
      setPreviewImages(updatedPreviewImages);

      setIsAddImageDialogOpen(false);
      setNewImageFile(null);
      setNewImageURL("");
    } catch (error) {
      console.error("画像の追加中にエラーが発生しました:", error);
      alert("画像の追加に失敗しました。再度お試しください。");
    }
  };

  // 商品削除の関数を修正
  const handleDeleteProduct = async (rowIndex) => {
    // 削除中であれば何もしない
    if (deletingProductIndices.has(rowIndex)) return;

    // 削除中の商品のインデックスを追加
    setDeletingProductIndices((prev) => new Set(prev).add(rowIndex));

    try {
      // 削除する商品のデータを保存
      const deletedProduct = {
        data: { ...data[rowIndex] }, // 商品データを完全にコピー
        previewImage: previewImages.find((product) => product.rowIndex === rowIndex),
        originalRowIndex: rowIndex, // 削除時のrowIndexを保持
      };

      // データから商品を削除
      const updatedData = data.filter((_, index) => index !== rowIndex);

      setData(updatedData);

      // プレビュー画像から商品を削除
      const updatedPreviewImages = previewImages.filter(
        (product) => product.rowIndex !== rowIndex
      );

      // 削除後の rowIndex を再計算
      const adjustedPreviewImages = updatedPreviewImages.map((product) => {
        if (product.rowIndex > rowIndex) {
          return {
            ...product,
            rowIndex: product.rowIndex - 1,
          };
        }
        return product;
      });

      setPreviewImages(adjustedPreviewImages);

      // 削除された商品の情報を保存
      setDeletedProducts((prevDeletedProducts) => [
        ...prevDeletedProducts,
        deletedProduct,
      ]);

      // 最後に削除された商品を保存
      setLastDeletedProduct(deletedProduct);

      // スナックバーで通知
      localShowSnackbar("商品が削除されました。");
    } finally {
      // 削除処理が完了したら削除中の商品のインデックスをセットから削除
      setDeletingProductIndices((prev) => {
        const newSet = new Set(prev);
        newSet.delete(rowIndex);
        return newSet;
      });
    }
  };

  // 削除された商品を復元する関数を修正
  const handleRestoreDeletedProduct = (deletedProductIndex) => {
    const deletedProduct = deletedProducts[deletedProductIndex];
    if (!deletedProduct) return;

    const { data: deletedData, previewImage: deletedPreviewImage, originalRowIndex } =
      deletedProduct;

    // データを復元
    const updatedData = [...data];
    updatedData.splice(originalRowIndex, 0, deletedData);

    setData(updatedData);

    // プレビュー画像を復元
    const adjustedPreviewImages = previewImages.map((product) => {
      if (product.rowIndex >= originalRowIndex) {
        return {
          ...product,
          rowIndex: product.rowIndex + 1,
        };
      }
      return product;
    });

    const updatedPreviewImages = [
      ...adjustedPreviewImages.slice(0, originalRowIndex),
      { ...deletedPreviewImage, rowIndex: originalRowIndex },
      ...adjustedPreviewImages.slice(originalRowIndex),
    ];

    setPreviewImages(updatedPreviewImages);

    // 削除された商品のリストから削除
    const updatedDeletedProducts = deletedProducts.filter(
      (_, index) => index !== deletedProductIndex
    );
    setDeletedProducts(updatedDeletedProducts);

    // スナックバーで通知
    localShowSnackbar("商品を復元しました。");
  };

  // 最後に削除された商品を元に戻す関数を修正
  const handleUndoDeleteProduct = () => {
    if (!lastDeletedProduct) return;

    const { data: deletedData, previewImage: deletedPreviewImage, originalRowIndex } =
      lastDeletedProduct;

    // データを復元
    const updatedData = [...data];
    updatedData.splice(originalRowIndex, 0, deletedData);

    setData(updatedData);

    // プレビュー画像を復元
    const adjustedPreviewImages = previewImages.map((product) => {
      if (product.rowIndex >= originalRowIndex) {
        return {
          ...product,
          rowIndex: product.rowIndex + 1,
        };
      }
      return product;
    });

    const updatedPreviewImages = [
      ...adjustedPreviewImages.slice(0, originalRowIndex),
      { ...deletedPreviewImage, rowIndex: originalRowIndex },
      ...adjustedPreviewImages.slice(originalRowIndex),
    ];

    setPreviewImages(updatedPreviewImages);

    // 削除された商品のリストから削除
    const updatedDeletedProducts = deletedProducts.filter(
      (product) => product !== lastDeletedProduct
    );
    setDeletedProducts(updatedDeletedProducts);

    // 'lastDeletedProduct' をクリア
    setLastDeletedProduct(null);

    // スナックバーで通知
    localShowSnackbar("削除を元に戻しました。");
  };

  // ドラッグ終了時のハンドラー
  const handleDragEnd = (result, product) => {
    if (!result.destination) {
      return;
    }

    const { source, destination } = result;

    const productIndex = previewImages.findIndex(
      (p) => p.rowIndex === product.rowIndex
    );
    if (productIndex === -1) {
      return;
    }

    const images = Array.from(previewImages[productIndex].images);
    const [movedImage] = images.splice(source.index, 1);
    images.splice(destination.index, 0, movedImage);

    // previewImagesを更新
    const updatedPreviewImages = [...previewImages];
    updatedPreviewImages[productIndex] = {
      ...previewImages[productIndex],
      images,
    };
    setPreviewImages(updatedPreviewImages);

    // dataを更新
    const updatedData = [...data];
    const item = { ...updatedData[product.rowIndex] };
    item[picUrlKey] = images.map((img) => img.url).join("|");
    updatedData[product.rowIndex] = item;
    setData(updatedData);
  };

  // 画像解析を行う関数
  const handleImageAnalysis = async () => {
    setIsProcessing(true);
    setError("");

    try {
      const updatedData = [...data];

      if (!picUrlKey) {
        throw new Error("PicURLカラムが見つかりません。");
      }

      if (selectedImages.size === 0) {
        throw new Error("解析する画像を選択してください。");
      }

      for (const selectedImageUrl of selectedImages) {
        // 対応する商品（行）を見つける
        const itemIndex = data.findIndex((item) => {
          const picUrl = item[picUrlKey];
          const imageUrls = picUrl ? picUrl.split("|") : [];
          return imageUrls.includes(selectedImageUrl);
        });

        if (itemIndex !== -1) {
          const item = updatedData[itemIndex];
          // 画像を解析して説明を取得
          // const description = await performImageAnalysis(selectedImageUrl, apiKey);
          const description = await ImageAnalysis(selectedImageUrl, apiKey);
          // 既存の説明がある場合は追記、それ以外は新規作成
          if (item["jp_image_description"]) {
            item["jp_image_description"] += `\n\n${description}`;
          } else {
            item["jp_image_description"] = description;
          }
        }
      }

      setData(updatedData);
      showSnackbar("選択された画像の解析が完了し、データが更新されました。");
    } catch (error) {
      setError(`画像解析中にエラーが発生しました: ${error.message}`);
      console.error(error);
    } finally {
      setIsProcessing(false);
      setOpenPreviewDialog(false);
    }
  };

  // 画像を削除する関数
  const handleDeleteImage = (imageUrl, productRowIndex) => {
    try {
      const updatedData = [...data];
      const item = { ...updatedData[productRowIndex] };
      const imageUrls = item[picUrlKey] ? item[picUrlKey].split("|") : [];

      // 削除する画像のインデックスを取得
      const imageIndex = imageUrls.findIndex((url) => url === imageUrl);

      // 画像を削除
      const newImageUrls = imageUrls.filter((url) => url !== imageUrl);
      item[picUrlKey] = newImageUrls.join("|");
      updatedData[productRowIndex] = item;
      setData(updatedData);

      // プレビュー画像を更新
      const updatedPreviewImages = previewImages.map((product) => {
        if (product.rowIndex === productRowIndex) {
          const images = product.images.filter((img) => img.url !== imageUrl);
          return {
            ...product,
            images,
          };
        }
        return product;
      });
      setPreviewImages(updatedPreviewImages);

      // 削除された画像の情報を保存
      setLastDeletedImage({
        imageUrl,
        productRowIndex,
        imageIndex,
      });

      // スナックバーで「元に戻す」ボタンを表示
      localShowSnackbar("画像が削除されました。");
    } catch (error) {
      console.error("画像の削除中にエラーが発生しました:", error);
      setError(`画像の削除中にエラーが発生しました: ${error.message}`);
    }
  };

  // 画像削除を元に戻す関数
  const handleUndoDeleteImage = () => {
    if (!lastDeletedImage) return;

    const { imageUrl, productRowIndex, imageIndex } = lastDeletedImage;

    try {
      // データを更新して画像を元の位置に挿入
      const updatedData = [...data];
      const item = { ...updatedData[productRowIndex] };
      const imageUrls = item[picUrlKey] ? item[picUrlKey].split("|") : [];

      // 元の位置に画像を挿入
      imageUrls.splice(imageIndex, 0, imageUrl);
      item[picUrlKey] = imageUrls.join("|");
      updatedData[productRowIndex] = item;
      setData(updatedData);

      // プレビュー画像を更新
      const updatedPreviewImages = previewImages.map((product) => {
        if (product.rowIndex === productRowIndex) {
          const images = [...product.images];
          images.splice(imageIndex, 0, { url: imageUrl, imageIndex });
          return {
            ...product,
            images,
          };
        }
        return product;
      });
      setPreviewImages(updatedPreviewImages);

      // 削除された画像の情報をクリア
      setLastDeletedImage(null);

      // スナックバーで復元が完了したことを通知
      localShowSnackbar("削除を元に戻しました。");
    } catch (error) {
      console.error("画像の復元中にエラーが発生しました:", error);
      setError(`画像の復元中にエラーが発生しました: ${error.message}`);
    }
  };

  // Snackbarを表示する関数
  const handleSnackbarClose = () => {
    setSnackbarState({ ...snackbarState, open: false });
  };

  // プロパティとして渡された showSnackbar と区別するために関数名を変更
  const localShowSnackbar = (message) => {
    setSnackbarState({
      open: true,
      message,
    });
  };

  return (
    <>
      {/* 画像解析ボタンを追加 */}
      <Button
        variant="contained"
        color="primary"
        startIcon={<DescriptionIcon />}
        onClick={() => {
          setPreviewDialogMode("analyze");
          setOpenPreviewDialog(true);
        }}
        disabled={isProcessing}
        sx={{ mt: 2 }}
      >
        画像解析
      </Button>

      {/* 削除された商品一覧を開くボタンを追加 */}
      <Button
        variant="outlined"
        color="secondary"
        onClick={() => setOpenDeletedProductsDialog(true)}
        sx={{ mt: 2, ml: 2 }}
      >
        削除された商品一覧
      </Button>

      {/* プレビューダイアログ */}
      <Dialog
        open={openPreviewDialog}
        onClose={() => setOpenPreviewDialog(false)}
        maxWidth="xl"
        fullWidth
      >
        <DialogTitle>
          {previewDialogMode === "edit" ? "画像編集・削除" : "画像解析"}
          <IconButton
            aria-label="close"
            onClick={() => setOpenPreviewDialog(false)}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {/* 表示件数の選択 */}
          <Box sx={{ mb: 2, display: "flex", alignItems: "center" }}>
            <Typography variant="body2" sx={{ mr: 2 }}>
              表示件数:
            </Typography>
            <FormControl variant="outlined" size="small">
              <Select
                value={itemsPerPage}
                onChange={handleItemsPerPageChange}
                sx={{ minWidth: 120 }}
              >
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
                <MenuItem value={150}>150</MenuItem>
                <MenuItem value={200}>200</MenuItem>
                <MenuItem value="ALL">ALL</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Typography variant="body2" sx={{ mb: 2 }}>
            {previewDialogMode === "edit"
              ? "削除したい画像の「×」をクリックしてください。画像をドラッグ＆ドロップで並び替えることもできます。"
              : "解析したい画像をクリックして選択してください。"}
          </Typography>
          <Box
            ref={scrollableContainerRef} // ここでrefを設定
            sx={{ maxHeight: "70vh", overflowY: "auto", position: "relative" }}
          >
            {displayedPreviewImages.map((product, index) => (
              <Box key={product.rowIndex} sx={{ mb: 4 }}>
                <Typography variant="h6">{product.title}</Typography>

                {/* 商品を削除するボタンを修正 */}
                {previewDialogMode === "edit" && (
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={() => handleDeleteProduct(product.rowIndex)}
                    sx={{ mb: 2 }}
                    disabled={deletingProductIndices.has(product.rowIndex)}
                  >
                    商品を削除
                  </Button>
                )}

                <DragDropContext
                  onDragEnd={(result) => handleDragEnd(result, product)}
                >
                  <Droppable
                    droppableId={`droppable-${product.rowIndex}`}
                    direction="horizontal"
                  >
                    {(provided) => (
                      <Grid
                        container
                        spacing={2}
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                      >
                        {product.images.map((image, idx) => (
                          <Draggable
                            key={image.url}
                            draggableId={image.url}
                            index={idx}
                            isDragDisabled={previewDialogMode === "analyze"}
                          >
                            {(provided) => (
                              <Grid
                                item
                                xs={3}
                                sm={2}
                                md={1.5}
                                lg={1}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <Paper
                                  elevation={1}
                                  sx={{
                                    p: 1,
                                    position: "relative",
                                    opacity: selectedImages.has(image.url)
                                      ? 0.4
                                      : 1,
                                    border: selectedImages.has(image.url)
                                      ? "2px solid blue"
                                      : "none",
                                  }}
                                  onClick={() => toggleImageSelection(image.url)}
                                >
                                  {previewDialogMode === "edit" && (
                                    <IconButton
                                      aria-label="delete"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleDeleteImage(
                                          image.url,
                                          product.rowIndex
                                        );
                                      }}
                                      sx={{
                                        position: "absolute",
                                        top: 0,
                                        right: 0,
                                        color: (theme) =>
                                          theme.palette.error.main,
                                      }}
                                    >
                                      <CloseIcon />
                                    </IconButton>
                                  )}
                                  <img
                                    src={image.url}
                                    alt={`Image ${image.imageIndex}`}
                                    style={{ width: "100%", height: "auto" }}
                                    loading="lazy"
                                  />
                                  <Typography variant="caption">
                                    画像 {idx + 1}
                                  </Typography>
                                </Paper>
                              </Grid>
                            )}
                          </Draggable>
                        ))}

                        {provided.placeholder}

                        {/* プラスアイコンのDraggableアイテム */}
                        {previewDialogMode === "edit" && (
                          <Draggable
                            key={`add-image-${product.rowIndex}`}
                            draggableId={`add-image-${product.rowIndex}`}
                            index={product.images.length}
                            isDragDisabled={true} // ドラッグ不可に設定
                          >
                            {(provided) => (
                              <Grid
                                item
                                xs={3}
                                sm={2}
                                md={1.5}
                                lg={1}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                              >
                                <Paper
                                  elevation={1}
                                  sx={{
                                    p: 1,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    height: "100%",
                                    border: "2px dashed #1976d2",
                                    color: "#1976d2",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => handleAddImageClick(product)}
                                >
                                  <Typography variant="h4">+</Typography>
                                </Paper>
                              </Grid>
                            )}
                          </Draggable>
                        )}
                      </Grid>
                    )}
                  </Droppable>
                </DragDropContext>
              </Box>
            ))}
          </Box>
          {/* スクロールトップボタン */}
          {showScrollTopButton && (
            <IconButton
              onClick={() => {
                if (scrollableContainerRef.current) {
                  scrollableContainerRef.current.scrollTo({
                    top: 0,
                    behavior: "smooth",
                  });
                }
              }}
              sx={{
                position: "fixed",
                bottom: 100,
                right: 40,
                zIndex: 1000,
                backgroundColor: theme.palette.background.paper,
                "&:hover": {
                  backgroundColor: theme.palette.grey[300],
                },
              }}
            >
              <KeyboardArrowUpIcon />
            </IconButton>
          )}
          {/* ページネーション */}
          {totalPages > 1 && (
            <Box sx={{ mt: 2, display: "flex", justifyContent: "center" }}>
              <Pagination
                count={totalPages}
                page={currentPage}
                onChange={handlePageChange}
                color="primary"
              />
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenPreviewDialog(false)}>閉じる</Button>
          {previewDialogMode === "edit" ? (
            <Button
              onClick={() => {
                setOpenConfirmDialog(true);
              }}
              color="primary"
              variant="contained"
              disabled={selectedImages.size === 0}
            >
              削除実行
            </Button>
          ) : (
            <Button
              onClick={handleImageAnalysis}
              color="primary"
              variant="contained"
              disabled={selectedImages.size === 0}
            >
              解析実行
            </Button>
          )}
        </DialogActions>
      </Dialog>

      {/* 画像追加ダイアログ */}
      <Dialog
        open={isAddImageDialogOpen}
        onClose={() => setIsAddImageDialogOpen(false)}
      >
        <DialogTitle>画像を追加</DialogTitle>
        <DialogContent>
          <Typography variant="body2" sx={{ mb: 2 }}>
            画像のURLを入力するか、画像ファイルをアップロードしてください。
          </Typography>
          <TextField
            label="画像URL"
            value={newImageURL}
            onChange={(e) => setNewImageURL(e.target.value)}
            fullWidth
            sx={{ mb: 2 }}
          />
          <Typography variant="body2" sx={{ mb: 1 }}>
            または
          </Typography>
          <Button variant="contained" component="label">
            画像を選択
            <input
              type="file"
              accept="image/*"
              hidden
              onChange={handleImageFileChange}
            />
          </Button>
          {newImageFile && (
            <Typography variant="body2" sx={{ mt: 1 }}>
              選択されたファイル: {newImageFile.name}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setIsAddImageDialogOpen(false);
              setNewImageFile(null);
              setNewImageURL("");
            }}
          >
            キャンセル
          </Button>
          <Button onClick={handleAddImage} color="primary" variant="contained">
            追加
          </Button>
        </DialogActions>
      </Dialog>

      {/* 削除確認ダイアログを追加 */}
      <Dialog
        open={openConfirmDialog}
        onClose={() => setOpenConfirmDialog(false)}
      >
        <DialogTitle>確認</DialogTitle>
        <DialogContent>
          <DialogContentText>
            選択された画像を削除しますか？
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenConfirmDialog(false)}>キャンセル</Button>
          <Button onClick={handleDelete} color="primary" variant="contained">
            削除
          </Button>
        </DialogActions>
      </Dialog>

      {/* 削除された商品一覧ダイアログ */}
      <Dialog
        open={openDeletedProductsDialog}
        onClose={() => setOpenDeletedProductsDialog(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>削除された商品一覧</DialogTitle>
        <DialogContent>
          {deletedProducts.length === 0 ? (
            <Typography>削除された商品はありません。</Typography>
          ) : (
            deletedProducts.map((deletedProduct, index) => (
              <Box key={index} sx={{ mb: 4 }}>
                <Typography variant="h6">
                  {deletedProduct.data[titleKey] ||
                    `商品 ${deletedProduct.originalRowIndex + 1}`}
                </Typography>
                {/* 商品の画像を表示 */}
                <Grid container spacing={2}>
                  {deletedProduct.previewImage.images.map((image, idx) => (
                    <Grid item xs={3} sm={2} md={1.5} lg={1} key={idx}>
                      <Paper elevation={1} sx={{ p: 1 }}>
                        <img
                          src={image.url}
                          alt={`Deleted Product Image ${image.imageIndex}`}
                          style={{ width: "100%", height: "auto" }}
                          loading="lazy"
                        />
                        <Typography variant="caption">画像 {idx + 1}</Typography>
                      </Paper>
                    </Grid>
                  ))}
                </Grid>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleRestoreDeletedProduct(index)}
                  sx={{ mt: 1 }}
                >
                  復元
                </Button>
              </Box>
            ))
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeletedProductsDialog(false)}>閉じる</Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar */}
      <Snackbar
        open={snackbarState.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbarState.message}
        action={
          (lastDeletedImage || lastDeletedProduct) && (
            <Button
              color="secondary"
              size="small"
              onClick={
                lastDeletedImage ? handleUndoDeleteImage : handleUndoDeleteProduct
              }
            >
              元に戻す
            </Button>
          )
        }
      />
    </>
  );
}

export default ImageOperations;





// // ImageOperations.js

// import React, { useState, useCallback, useEffect, useRef, useMemo } from "react";
// import {
//   Button,
//   Typography,
//   Box,
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogTitle,
//   Paper,
//   Grid,
//   IconButton,
//   TextField,
//   MenuItem,
//   Select,
//   FormControl,
//   Pagination,
//   Snackbar,
//   DialogContentText,
// } from "@mui/material";
// import { useTheme } from "@mui/material/styles";
// import CloseIcon from "@mui/icons-material/Close";
// import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
// import DescriptionIcon from "@mui/icons-material/Description";
// import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
// import {
//   getStorage,
//   ref as storageRef,
//   uploadBytes,
//   getDownloadURL,
// } from "firebase/storage";
// import axios from "axios";
// import performImageAnalysis from "./ImageAnalysis"; // 画像解析関数をインポート
// import ImageAnalysis from "./ImageAnalysis";
// import { v4 as uuidv4 } from 'uuid';


// function ImageOperations({
//   data,
//   setData,
//   startRow,
//   endRow,
//   calculateMaxEndRow,
//   isProcessing,
//   setIsProcessing,
//   setError,
//   showSnackbar, // プロパティとして渡される
//   openPreviewDialog,
//   setOpenPreviewDialog,
//   apiKey, // OpenAIのAPIキー
// }) {
//   const [previewImages, setPreviewImages] = useState([]);
//   const [displayedPreviewImages, setDisplayedPreviewImages] = useState([]);
//   const [selectedImages, setSelectedImages] = useState(new Set());
//   const [selectedProductForEdit, setSelectedProductForEdit] = useState(null);
//   const [isAddImageDialogOpen, setIsAddImageDialogOpen] = useState(false);
//   const [newImageFile, setNewImageFile] = useState(null);
//   const [newImageURL, setNewImageURL] = useState("");
//   const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

//   const [itemsPerPage, setItemsPerPage] = useState(50); // 1ページあたりの表示件数
//   const [currentPage, setCurrentPage] = useState(1); // 現在のページ番号

//   const [previewDialogMode, setPreviewDialogMode] = useState("edit"); // 'edit' または 'analyze'
//   const [isAnalyzing, setIsAnalyzing] = useState(false);

//   const theme = useTheme();

//   // スクロール可能なコンテナのrefを追加
//   const scrollableContainerRef = useRef(null);
//   const [showScrollTopButton, setShowScrollTopButton] = useState(false); // スクロールトップボタンの表示制御

//   // 追加: 削除された画像の情報を保存する状態
//   const [lastDeletedImage, setLastDeletedImage] = useState(null);
//   const [snackbarState, setSnackbarState] = useState({
//     open: false,
//     message: "",
//   });

//   // 追加: 削除された商品の情報を保存する状態
//   const [deletedProducts, setDeletedProducts] = useState([]);
//   const [lastDeletedProduct, setLastDeletedProduct] = useState(null);
//   const [openDeletedProductsDialog, setOpenDeletedProductsDialog] = useState(false);

//   // 商品IDを管理するための状態（uniqueIdを使用しないので削除）

//   // 追加: 削除中の商品のインデックスを管理するステート
//   const [deletingProductIndices, setDeletingProductIndices] = useState(new Set());

//   // titleKey と picUrlKey を定義
//   const titleKey = useMemo(() => {
//     return Object.keys(data[0]).find((key) => key.toLowerCase() === "title");
//   }, [data]);

//   const picUrlKey = useMemo(() => {
//     return Object.keys(data[0]).find((key) => key.toLowerCase() === "picurl");
//   }, [data]);

//   const handleOpenPreviewDialog = useCallback(() => {
//     try {
//       if (!startRow || !endRow || isNaN(startRow) || isNaN(endRow)) {
//         throw new Error("Start RowとEnd Rowは有効な数字である必要があります。");
//       }

//       if (!picUrlKey) {
//         throw new Error("PicURLカラムが見つかりません。");
//       }

//       const actualStartRow = parseInt(startRow) - 1; // インデックスは0から開始
//       const maxEndRow = calculateMaxEndRow();
//       const actualEndRow = Math.min(parseInt(endRow) - 1, maxEndRow - 1);

//       if (actualStartRow < 0 || actualEndRow < actualStartRow) {
//         throw new Error("Start RowとEnd Rowが有効ではありません。");
//       }

//       let previewItems = [];

//       // すべての画像を取得
//       for (let i = actualStartRow; i <= actualEndRow; i++) {
//         const item = data[i];
//         const picUrl = item[picUrlKey];
//         const imageUrls = picUrl ? picUrl.split("|") : [];
//         const title = item[titleKey] || `商品 ${i + 1}`;
//         const images = imageUrls.map((url, idx) => ({
//           url,
//           imageIndex: idx + 1,
//         }));
//         if (images.length > 0) {
//           previewItems.push({
//             title,
//             rowIndex: i,
//             images,
//           });
//         }
//       }

//       setPreviewImages(previewItems);
//       setSelectedImages(new Set()); // すべて未選択
//       setCurrentPage(1); // ページをリセット
//     } catch (err) {
//       const errorMessage = `プレビューの準備中にエラーが発生しました: ${err.message}`;
//       setError(errorMessage);
//       console.error(errorMessage, err);
//     }
//   }, [data, startRow, endRow, calculateMaxEndRow, setError, titleKey, picUrlKey]);

//   // openPreviewDialogがtrueになったときにプレビューを準備
//   useEffect(() => {
//     if (openPreviewDialog) {
//       handleOpenPreviewDialog();
//     } else {
//       // ダイアログが閉じられたときにプレビュー画像をクリア
//       setPreviewImages([]);
//       setSelectedImages(new Set());
//     }
//   }, [openPreviewDialog, handleOpenPreviewDialog]);

//   // ページネーションの処理
//   useEffect(() => {
//     if (itemsPerPage === "ALL") {
//       setDisplayedPreviewImages(previewImages);
//     } else {
//       const startIndex = (currentPage - 1) * itemsPerPage;
//       const endIndex = startIndex + itemsPerPage;
//       setDisplayedPreviewImages(previewImages.slice(startIndex, endIndex));
//     }
//   }, [previewImages, currentPage, itemsPerPage]);

//   // currentPageが変更されたときにスクロール位置をトップに戻す
//   useEffect(() => {
//     if (scrollableContainerRef.current) {
//       scrollableContainerRef.current.scrollTop = 0;
//     }
//   }, [currentPage]);

//   // スクロール位置に応じてスクロールトップボタンの表示を制御
//   useEffect(() => {
//     const handleScroll = () => {
//       if (scrollableContainerRef.current) {
//         const scrollTop = scrollableContainerRef.current.scrollTop;
//         setShowScrollTopButton(scrollTop > 200);
//       }
//     };

//     const container = scrollableContainerRef.current;
//     if (container) {
//       container.addEventListener("scroll", handleScroll);
//       return () => container.removeEventListener("scroll", handleScroll);
//     }
//   }, [scrollableContainerRef]);

//   // Snackbarの自動非表示に合わせてlastDeletedImageとlastDeletedProductをクリア
//   useEffect(() => {
//     if (snackbarState.open && (lastDeletedImage || lastDeletedProduct)) {
//       const timer = setTimeout(() => {
//         setLastDeletedImage(null);
//         setLastDeletedProduct(null);
//       }, 6000); // SnackbarのautoHideDurationと同じ

//       return () => clearTimeout(timer);
//     }
//   }, [snackbarState.open, lastDeletedImage, lastDeletedProduct]);

//   const totalPages =
//     itemsPerPage === "ALL" ? 1 : Math.ceil(previewImages.length / itemsPerPage);

//   const handleItemsPerPageChange = (event) => {
//     const value =
//       event.target.value === "ALL" ? "ALL" : parseInt(event.target.value);
//     setItemsPerPage(value);
//     setCurrentPage(1); // ページをリセット
//   };

//   const handlePageChange = (event, value) => {
//     setCurrentPage(value);
//   };

//   const toggleImageSelection = (url) => {
//     setSelectedImages((prevSelected) => {
//       const newSelected = new Set(prevSelected);
//       if (newSelected.has(url)) {
//         newSelected.delete(url);
//       } else {
//         newSelected.add(url);
//       }
//       return newSelected;
//     });
//   };

//   const handleDelete = useCallback(async () => {
//     setIsProcessing(true);
//     setError("");

//     try {
//       if (!startRow || !endRow || isNaN(startRow) || isNaN(endRow)) {
//         throw new Error("Start RowとEnd Rowは有効な数字である必要があります。");
//       }

//       if (!picUrlKey) {
//         throw new Error("PicURLカラムが見つかりません。");
//       }

//       const actualStartRow = parseInt(startRow) - 1; // インデックスは0から開始
//       const maxEndRow = calculateMaxEndRow();
//       const actualEndRow = Math.min(parseInt(endRow) - 1, maxEndRow - 1);

//       if (actualStartRow < 0 || actualEndRow < actualStartRow) {
//         throw new Error("Start RowとEnd Rowが有効ではありません。");
//       }

//       // データの更新
//       const updatedData = [...data];
//       for (let i = actualStartRow; i <= actualEndRow; i++) {
//         const item = { ...updatedData[i] };
//         const originalImages = item[picUrlKey]
//           ? item[picUrlKey].split("|")
//           : [];
//         const newImages = originalImages.filter(
//           (url) => !selectedImages.has(url)
//         );
//         item[picUrlKey] = newImages.join("|");
//         updatedData[i] = item;
//       }

//       setData(updatedData);

//       // プレビュー画像を更新
//       const updatedPreviewImages = previewImages.map((product) => {
//         const item = updatedData[product.rowIndex];
//         const picUrl = item[picUrlKey];
//         const imageUrls = picUrl ? picUrl.split("|") : [];
//         const images = imageUrls.map((url, idx) => ({
//           url,
//           imageIndex: idx + 1,
//         }));
//         return {
//           ...product,
//           images,
//         };
//       });
//       setPreviewImages(updatedPreviewImages);

//       const finalMessage = `画像の削除が完了しました。`;
//       showSnackbar(finalMessage);
//     } catch (err) {
//       const errorMessage = `画像の削除に失敗しました: ${err.message}`;
//       setError(errorMessage);
//       showSnackbar(errorMessage);
//       console.error(errorMessage, err);
//     } finally {
//       setIsProcessing(false);
//       setOpenConfirmDialog(false);
//       setOpenPreviewDialog(false);
//     }
//   }, [
//     data,
//     startRow,
//     endRow,
//     selectedImages,
//     calculateMaxEndRow,
//     setData,
//     previewImages,
//     setIsProcessing,
//     setError,
//     showSnackbar,
//     setOpenPreviewDialog,
//     picUrlKey,
//   ]);

//   const handleAddImageClick = (product) => {
//     setSelectedProductForEdit(product);
//     setIsAddImageDialogOpen(true);
//   };

//   const handleImageFileChange = (e) => {
//     if (e.target.files && e.target.files[0]) {
//       setNewImageFile(e.target.files[0]);
//       setNewImageURL("");
//     }
//   };

//   const handleAddImage = async () => {
//     const storage = getStorage();

//     try {
//       let imageUrl = newImageURL;

//       if (newImageFile) {
//         // Firebase Storage に画像をアップロード
//         const fileExtension = newImageFile.name.split(".").pop();
//         const storageReference = storageRef(
//           storage,
//           `images/${uuidv4()}.${fileExtension}`
//         );
//         await uploadBytes(storageReference, newImageFile);
//         imageUrl = await getDownloadURL(storageReference);
//       }

//       if (!imageUrl) {
//         alert(
//           "画像のURLを入力するか、画像ファイルをアップロードしてください。"
//         );
//         return;
//       }

//       // データの更新
//       const updatedData = [...data];
//       const item = { ...updatedData[selectedProductForEdit.rowIndex] };
//       const imageUrls = item[picUrlKey] ? item[picUrlKey].split("|") : [];
//       imageUrls.push(imageUrl);
//       item[picUrlKey] = imageUrls.join("|");
//       updatedData[selectedProductForEdit.rowIndex] = item;
//       setData(updatedData);

//       // previewImagesを更新
//       const updatedPreviewImages = previewImages.map((product) => {
//         if (product.rowIndex === selectedProductForEdit.rowIndex) {
//           const images = [
//             ...product.images,
//             { url: imageUrl, imageIndex: product.images.length + 1 },
//           ];
//           return {
//             ...product,
//             images,
//           };
//         }
//         return product;
//       });
//       setPreviewImages(updatedPreviewImages);

//       setIsAddImageDialogOpen(false);
//       setNewImageFile(null);
//       setNewImageURL("");
//     } catch (error) {
//       console.error("画像の追加中にエラーが発生しました:", error);
//       alert("画像の追加に失敗しました。再度お試しください。");
//     }
//   };

//   // 商品削除の関数を修正
//   const handleDeleteProduct = async (rowIndex) => {
//     // 削除中であれば何もしない
//     if (deletingProductIndices.has(rowIndex)) return;

//     // 削除中の商品のインデックスを追加
//     setDeletingProductIndices((prev) => new Set(prev).add(rowIndex));

//     try {
//       // 削除する商品のデータを保存
//       const deletedProduct = {
//         data: { ...data[rowIndex] }, // 商品データを完全にコピー
//         previewImage: previewImages.find((product) => product.rowIndex === rowIndex),
//         originalRowIndex: rowIndex, // 削除時のrowIndexを保持
//       };

//       // データから商品を削除
//       const updatedData = data.filter((_, index) => index !== rowIndex);

//       setData(updatedData);

//       // プレビュー画像から商品を削除
//       const updatedPreviewImages = previewImages.filter(
//         (product) => product.rowIndex !== rowIndex
//       );

//       // 削除後の rowIndex を再計算
//       const adjustedPreviewImages = updatedPreviewImages.map((product) => {
//         if (product.rowIndex > rowIndex) {
//           return {
//             ...product,
//             rowIndex: product.rowIndex - 1,
//           };
//         }
//         return product;
//       });

//       setPreviewImages(adjustedPreviewImages);

//       // 削除された商品の情報を保存
//       setDeletedProducts((prevDeletedProducts) => [
//         ...prevDeletedProducts,
//         deletedProduct,
//       ]);

//       // 最後に削除された商品を保存
//       setLastDeletedProduct(deletedProduct);

//       // スナックバーで通知
//       localShowSnackbar("商品が削除されました。");
//     } finally {
//       // 削除処理が完了したら削除中の商品のインデックスをセットから削除
//       setDeletingProductIndices((prev) => {
//         const newSet = new Set(prev);
//         newSet.delete(rowIndex);
//         return newSet;
//       });
//     }
//   };

//   // 削除された商品を復元する関数を修正
//   const handleRestoreDeletedProduct = (deletedProductIndex) => {
//     const deletedProduct = deletedProducts[deletedProductIndex];
//     if (!deletedProduct) return;

//     const { data: deletedData, previewImage: deletedPreviewImage, originalRowIndex } =
//       deletedProduct;

//     // データを復元
//     const updatedData = [...data];
//     updatedData.splice(originalRowIndex, 0, deletedData);

//     setData(updatedData);

//     // プレビュー画像を復元
//     const adjustedPreviewImages = previewImages.map((product) => {
//       if (product.rowIndex >= originalRowIndex) {
//         return {
//           ...product,
//           rowIndex: product.rowIndex + 1,
//         };
//       }
//       return product;
//     });

//     const updatedPreviewImages = [
//       ...adjustedPreviewImages.slice(0, originalRowIndex),
//       { ...deletedPreviewImage, rowIndex: originalRowIndex },
//       ...adjustedPreviewImages.slice(originalRowIndex),
//     ];

//     setPreviewImages(updatedPreviewImages);

//     // 削除された商品のリストから削除
//     const updatedDeletedProducts = deletedProducts.filter(
//       (_, index) => index !== deletedProductIndex
//     );
//     setDeletedProducts(updatedDeletedProducts);

//     // スナックバーで通知
//     localShowSnackbar("商品を復元しました。");
//   };

//   // 最後に削除された商品を元に戻す関数を修正
//   const handleUndoDeleteProduct = () => {
//     if (!lastDeletedProduct) return;

//     const { data: deletedData, previewImage: deletedPreviewImage, originalRowIndex } =
//       lastDeletedProduct;

//     // データを復元
//     const updatedData = [...data];
//     updatedData.splice(originalRowIndex, 0, deletedData);

//     setData(updatedData);

//     // プレビュー画像を復元
//     const adjustedPreviewImages = previewImages.map((product) => {
//       if (product.rowIndex >= originalRowIndex) {
//         return {
//           ...product,
//           rowIndex: product.rowIndex + 1,
//         };
//       }
//       return product;
//     });

//     const updatedPreviewImages = [
//       ...adjustedPreviewImages.slice(0, originalRowIndex),
//       { ...deletedPreviewImage, rowIndex: originalRowIndex },
//       ...adjustedPreviewImages.slice(originalRowIndex),
//     ];

//     setPreviewImages(updatedPreviewImages);

//     // 削除された商品のリストから削除
//     const updatedDeletedProducts = deletedProducts.filter(
//       (product) => product !== lastDeletedProduct
//     );
//     setDeletedProducts(updatedDeletedProducts);

//     // 'lastDeletedProduct' をクリア
//     setLastDeletedProduct(null);

//     // スナックバーで通知
//     localShowSnackbar("削除を元に戻しました。");
//   };

//   // ドラッグ終了時のハンドラー
//   const handleDragEnd = (result, product) => {
//     if (!result.destination) {
//       return;
//     }

//     const { source, destination } = result;

//     const productIndex = previewImages.findIndex(
//       (p) => p.rowIndex === product.rowIndex
//     );
//     if (productIndex === -1) {
//       return;
//     }

//     const images = Array.from(previewImages[productIndex].images);
//     const [movedImage] = images.splice(source.index, 1);
//     images.splice(destination.index, 0, movedImage);

//     // previewImagesを更新
//     const updatedPreviewImages = [...previewImages];
//     updatedPreviewImages[productIndex] = {
//       ...previewImages[productIndex],
//       images,
//     };
//     setPreviewImages(updatedPreviewImages);

//     // dataを更新
//     const updatedData = [...data];
//     const item = { ...updatedData[product.rowIndex] };
//     item[picUrlKey] = images.map((img) => img.url).join("|");
//     updatedData[product.rowIndex] = item;
//     setData(updatedData);
//   };

//   // 画像解析を行う関数
//   const handleImageAnalysis = async () => {
//     setIsProcessing(true);
//     setError("");

//     try {
//       const updatedData = [...data];

//       if (!picUrlKey) {
//         throw new Error("PicURLカラムが見つかりません。");
//       }

//       if (selectedImages.size === 0) {
//         throw new Error("解析する画像を選択してください。");
//       }

//       for (const selectedImageUrl of selectedImages) {
//         // 対応する商品（行）を見つける
//         const itemIndex = data.findIndex((item) => {
//           const picUrl = item[picUrlKey];
//           const imageUrls = picUrl ? picUrl.split("|") : [];
//           return imageUrls.includes(selectedImageUrl);
//         });

//         if (itemIndex !== -1) {
//           const item = updatedData[itemIndex];
//           // 画像を解析して説明を取得
//           // const description = await performImageAnalysis(selectedImageUrl, apiKey);
//           const description = await ImageAnalysis(selectedImageUrl, apiKey);
//           // 既存の説明がある場合は追記、それ以外は新規作成
//           if (item["jp_image_description"]) {
//             item["jp_image_description"] += `\n\n${description}`;
//           } else {
//             item["jp_image_description"] = description;
//           }
//         }
//       }

//       setData(updatedData);
//       showSnackbar("選択された画像の解析が完了し、データが更新されました。");
//     } catch (error) {
//       setError(`画像解析中にエラーが発生しました: ${error.message}`);
//       console.error(error);
//     } finally {
//       setIsProcessing(false);
//       setOpenPreviewDialog(false);
//     }
//   };

//   // 画像を削除する関数
//   const handleDeleteImage = (imageUrl, productRowIndex) => {
//     try {
//       const updatedData = [...data];
//       const item = { ...updatedData[productRowIndex] };
//       const imageUrls = item[picUrlKey] ? item[picUrlKey].split("|") : [];

//       // 削除する画像のインデックスを取得
//       const imageIndex = imageUrls.findIndex((url) => url === imageUrl);

//       // 画像を削除
//       const newImageUrls = imageUrls.filter((url) => url !== imageUrl);
//       item[picUrlKey] = newImageUrls.join("|");
//       updatedData[productRowIndex] = item;
//       setData(updatedData);

//       // プレビュー画像を更新
//       const updatedPreviewImages = previewImages.map((product) => {
//         if (product.rowIndex === productRowIndex) {
//           const images = product.images.filter((img) => img.url !== imageUrl);
//           return {
//             ...product,
//             images,
//           };
//         }
//         return product;
//       });
//       setPreviewImages(updatedPreviewImages);

//       // 削除された画像の情報を保存
//       setLastDeletedImage({
//         imageUrl,
//         productRowIndex,
//         imageIndex,
//       });

//       // スナックバーで「元に戻す」ボタンを表示
//       localShowSnackbar("画像が削除されました。");
//     } catch (error) {
//       console.error("画像の削除中にエラーが発生しました:", error);
//       setError(`画像の削除中にエラーが発生しました: ${error.message}`);
//     }
//   };

//   // 画像削除を元に戻す関数
//   const handleUndoDeleteImage = () => {
//     if (!lastDeletedImage) return;

//     const { imageUrl, productRowIndex, imageIndex } = lastDeletedImage;

//     try {
//       // データを更新して画像を元の位置に挿入
//       const updatedData = [...data];
//       const item = { ...updatedData[productRowIndex] };
//       const imageUrls = item[picUrlKey] ? item[picUrlKey].split("|") : [];

//       // 元の位置に画像を挿入
//       imageUrls.splice(imageIndex, 0, imageUrl);
//       item[picUrlKey] = imageUrls.join("|");
//       updatedData[productRowIndex] = item;
//       setData(updatedData);

//       // プレビュー画像を更新
//       const updatedPreviewImages = previewImages.map((product) => {
//         if (product.rowIndex === productRowIndex) {
//           const images = [...product.images];
//           images.splice(imageIndex, 0, { url: imageUrl, imageIndex });
//           return {
//             ...product,
//             images,
//           };
//         }
//         return product;
//       });
//       setPreviewImages(updatedPreviewImages);

//       // 削除された画像の情報をクリア
//       setLastDeletedImage(null);

//       // スナックバーで復元が完了したことを通知
//       localShowSnackbar("削除を元に戻しました。");
//     } catch (error) {
//       console.error("画像の復元中にエラーが発生しました:", error);
//       setError(`画像の復元中にエラーが発生しました: ${error.message}`);
//     }
//   };

//   // Snackbarを表示する関数
//   const handleSnackbarClose = () => {
//     setSnackbarState({ ...snackbarState, open: false });
//   };

//   // プロパティとして渡された showSnackbar と区別するために関数名を変更
//   const localShowSnackbar = (message) => {
//     setSnackbarState({
//       open: true,
//       message,
//     });
//   };

//   return (
//     <>
//       {/* 画像解析ボタンを追加 */}
//       <Button
//         variant="contained"
//         color="primary"
//         startIcon={<DescriptionIcon />}
//         onClick={() => {
//           setPreviewDialogMode("analyze");
//           setOpenPreviewDialog(true);
//         }}
//         disabled={isProcessing}
//         sx={{ mt: 2 }}
//       >
//         画像解析
//       </Button>

//       {/* 削除された商品一覧を開くボタンを追加 */}
//       <Button
//         variant="outlined"
//         color="secondary"
//         onClick={() => setOpenDeletedProductsDialog(true)}
//         sx={{ mt: 2, ml: 2 }}
//       >
//         削除された商品一覧
//       </Button>

//       {/* プレビューダイアログ */}
//       <Dialog
//         open={openPreviewDialog}
//         onClose={() => setOpenPreviewDialog(false)}
//         maxWidth="xl"
//         fullWidth
//       >
//         <DialogTitle>
//           {previewDialogMode === "edit" ? "画像編集・削除" : "画像解析"}
//           <IconButton
//             aria-label="close"
//             onClick={() => setOpenPreviewDialog(false)}
//             sx={{
//               position: "absolute",
//               right: 8,
//               top: 8,
//               color: (theme) => theme.palette.grey[500],
//             }}
//           >
//             <CloseIcon />
//           </IconButton>
//         </DialogTitle>
//         <DialogContent>
//           {/* 表示件数の選択 */}
//           <Box sx={{ mb: 2, display: "flex", alignItems: "center" }}>
//             <Typography variant="body2" sx={{ mr: 2 }}>
//               表示件数:
//             </Typography>
//             <FormControl variant="outlined" size="small">
//               <Select
//                 value={itemsPerPage}
//                 onChange={handleItemsPerPageChange}
//                 sx={{ minWidth: 120 }}
//               >
//                 <MenuItem value={50}>50</MenuItem>
//                 <MenuItem value={100}>100</MenuItem>
//                 <MenuItem value={150}>150</MenuItem>
//                 <MenuItem value={200}>200</MenuItem>
//                 <MenuItem value="ALL">ALL</MenuItem>
//               </Select>
//             </FormControl>
//           </Box>
//           <Typography variant="body2" sx={{ mb: 2 }}>
//             {previewDialogMode === "edit"
//               ? "削除したい画像の「×」をクリックしてください。画像をドラッグ＆ドロップで並び替えることもできます。"
//               : "解析したい画像をクリックして選択してください。"}
//           </Typography>
//           <Box
//             ref={scrollableContainerRef} // ここでrefを設定
//             sx={{ maxHeight: "70vh", overflowY: "auto", position: "relative" }}
//           >
//             {displayedPreviewImages.map((product, index) => (
//               <Box key={product.rowIndex} sx={{ mb: 4 }}>
//                 <Typography variant="h6">{product.title}</Typography>

//                 {/* 商品を削除するボタンを修正 */}
//                 {previewDialogMode === "edit" && (
//                   <Button
//                     variant="outlined"
//                     color="error"
//                     onClick={() => handleDeleteProduct(product.rowIndex)}
//                     sx={{ mb: 2 }}
//                     disabled={deletingProductIndices.has(product.rowIndex)}
//                   >
//                     商品を削除
//                   </Button>
//                 )}

//                 <DragDropContext
//                   onDragEnd={(result) => handleDragEnd(result, product)}
//                 >
//                   <Droppable
//                     droppableId={`droppable-${product.rowIndex}`}
//                     direction="horizontal"
//                   >
//                     {(provided) => (
//                       <Grid
//                         container
//                         spacing={2}
//                         ref={provided.innerRef}
//                         {...provided.droppableProps}
//                       >
//                         {product.images.map((image, idx) => (
//                           <Draggable
//                             key={image.url}
//                             draggableId={image.url}
//                             index={idx}
//                             isDragDisabled={previewDialogMode === "analyze"}
//                           >
//                             {(provided) => (
//                               <Grid
//                                 item
//                                 xs={3}
//                                 sm={2}
//                                 md={1.5}
//                                 lg={1}
//                                 ref={provided.innerRef}
//                                 {...provided.draggableProps}
//                                 {...provided.dragHandleProps}
//                               >
//                                 <Paper
//                                   elevation={1}
//                                   sx={{
//                                     p: 1,
//                                     position: "relative",
//                                     opacity: selectedImages.has(image.url)
//                                       ? 0.4
//                                       : 1,
//                                     border: selectedImages.has(image.url)
//                                       ? "2px solid blue"
//                                       : "none",
//                                   }}
//                                   onClick={() => toggleImageSelection(image.url)}
//                                 >
//                                   {previewDialogMode === "edit" && (
//                                     <IconButton
//                                       aria-label="delete"
//                                       onClick={(e) => {
//                                         e.stopPropagation();
//                                         handleDeleteImage(
//                                           image.url,
//                                           product.rowIndex
//                                         );
//                                       }}
//                                       sx={{
//                                         position: "absolute",
//                                         top: 0,
//                                         right: 0,
//                                         color: (theme) =>
//                                           theme.palette.error.main,
//                                       }}
//                                     >
//                                       <CloseIcon />
//                                     </IconButton>
//                                   )}
//                                   <img
//                                     src={image.url}
//                                     alt={`Image ${image.imageIndex}`}
//                                     style={{ width: "100%", height: "auto" }}
//                                     loading="lazy"
//                                   />
//                                   <Typography variant="caption">
//                                     画像 {idx + 1}
//                                   </Typography>
//                                 </Paper>
//                               </Grid>
//                             )}
//                           </Draggable>
//                         ))}

//                         {provided.placeholder}

//                         {/* プラスアイコンのDraggableアイテム */}
//                         {previewDialogMode === "edit" && (
//                           <Draggable
//                             key={`add-image-${product.rowIndex}`}
//                             draggableId={`add-image-${product.rowIndex}`}
//                             index={product.images.length}
//                             isDragDisabled={true} // ドラッグ不可に設定
//                           >
//                             {(provided) => (
//                               <Grid
//                                 item
//                                 xs={3}
//                                 sm={2}
//                                 md={1.5}
//                                 lg={1}
//                                 ref={provided.innerRef}
//                                 {...provided.draggableProps}
//                               >
//                                 <Paper
//                                   elevation={1}
//                                   sx={{
//                                     p: 1,
//                                     display: "flex",
//                                     alignItems: "center",
//                                     justifyContent: "center",
//                                     height: "100%",
//                                     border: "2px dashed #1976d2",
//                                     color: "#1976d2",
//                                     cursor: "pointer",
//                                   }}
//                                   onClick={() => handleAddImageClick(product)}
//                                 >
//                                   <Typography variant="h4">+</Typography>
//                                 </Paper>
//                               </Grid>
//                             )}
//                           </Draggable>
//                         )}
//                       </Grid>
//                     )}
//                   </Droppable>
//                 </DragDropContext>
//               </Box>
//             ))}
//           </Box>
//           {/* スクロールトップボタン */}
//           {showScrollTopButton && (
//             <IconButton
//               onClick={() => {
//                 if (scrollableContainerRef.current) {
//                   scrollableContainerRef.current.scrollTo({
//                     top: 0,
//                     behavior: "smooth",
//                   });
//                 }
//               }}
//               sx={{
//                 position: "fixed",
//                 bottom: 100,
//                 right: 40,
//                 zIndex: 1000,
//                 backgroundColor: theme.palette.background.paper,
//                 "&:hover": {
//                   backgroundColor: theme.palette.grey[300],
//                 },
//               }}
//             >
//               <KeyboardArrowUpIcon />
//             </IconButton>
//           )}
//           {/* ページネーション */}
//           {totalPages > 1 && (
//             <Box sx={{ mt: 2, display: "flex", justifyContent: "center" }}>
//               <Pagination
//                 count={totalPages}
//                 page={currentPage}
//                 onChange={handlePageChange}
//                 color="primary"
//               />
//             </Box>
//           )}
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => setOpenPreviewDialog(false)}>閉じる</Button>
//           {previewDialogMode === "edit" ? (
//             <Button
//               onClick={() => {
//                 setOpenConfirmDialog(true);
//               }}
//               color="primary"
//               variant="contained"
//               disabled={selectedImages.size === 0}
//             >
//               削除実行
//             </Button>
//           ) : (
//             <Button
//               onClick={handleImageAnalysis}
//               color="primary"
//               variant="contained"
//               disabled={selectedImages.size === 0}
//             >
//               解析実行
//             </Button>
//           )}
//         </DialogActions>
//       </Dialog>

//       {/* 画像追加ダイアログ */}
//       <Dialog
//         open={isAddImageDialogOpen}
//         onClose={() => setIsAddImageDialogOpen(false)}
//       >
//         <DialogTitle>画像を追加</DialogTitle>
//         <DialogContent>
//           <Typography variant="body2" sx={{ mb: 2 }}>
//             画像のURLを入力するか、画像ファイルをアップロードしてください。
//           </Typography>
//           <TextField
//             label="画像URL"
//             value={newImageURL}
//             onChange={(e) => setNewImageURL(e.target.value)}
//             fullWidth
//             sx={{ mb: 2 }}
//           />
//           <Typography variant="body2" sx={{ mb: 1 }}>
//             または
//           </Typography>
//           <Button variant="contained" component="label">
//             画像を選択
//             <input
//               type="file"
//               accept="image/*"
//               hidden
//               onChange={handleImageFileChange}
//             />
//           </Button>
//           {newImageFile && (
//             <Typography variant="body2" sx={{ mt: 1 }}>
//               選択されたファイル: {newImageFile.name}
//             </Typography>
//           )}
//         </DialogContent>
//         <DialogActions>
//           <Button
//             onClick={() => {
//               setIsAddImageDialogOpen(false);
//               setNewImageFile(null);
//               setNewImageURL("");
//             }}
//           >
//             キャンセル
//           </Button>
//           <Button onClick={handleAddImage} color="primary" variant="contained">
//             追加
//           </Button>
//         </DialogActions>
//       </Dialog>

//       {/* 削除確認ダイアログを追加 */}
//       <Dialog
//         open={openConfirmDialog}
//         onClose={() => setOpenConfirmDialog(false)}
//       >
//         <DialogTitle>確認</DialogTitle>
//         <DialogContent>
//           <DialogContentText>
//             選択された画像を削除しますか？
//           </DialogContentText>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => setOpenConfirmDialog(false)}>キャンセル</Button>
//           <Button onClick={handleDelete} color="primary" variant="contained">
//             削除
//           </Button>
//         </DialogActions>
//       </Dialog>

//       {/* 削除された商品一覧ダイアログ */}
//       <Dialog
//         open={openDeletedProductsDialog}
//         onClose={() => setOpenDeletedProductsDialog(false)}
//         maxWidth="md"
//         fullWidth
//       >
//         <DialogTitle>削除された商品一覧</DialogTitle>
//         <DialogContent>
//           {deletedProducts.length === 0 ? (
//             <Typography>削除された商品はありません。</Typography>
//           ) : (
//             deletedProducts.map((deletedProduct, index) => (
//               <Box key={index} sx={{ mb: 4 }}>
//                 <Typography variant="h6">
//                   {deletedProduct.data[titleKey] ||
//                     `商品 ${deletedProduct.originalRowIndex + 1}`}
//                 </Typography>
//                 {/* 商品の画像を表示 */}
//                 <Grid container spacing={2}>
//                   {deletedProduct.previewImage.images.map((image, idx) => (
//                     <Grid item xs={3} sm={2} md={1.5} lg={1} key={idx}>
//                       <Paper elevation={1} sx={{ p: 1 }}>
//                         <img
//                           src={image.url}
//                           alt={`Deleted Product Image ${image.imageIndex}`}
//                           style={{ width: "100%", height: "auto" }}
//                           loading="lazy"
//                         />
//                         <Typography variant="caption">画像 {idx + 1}</Typography>
//                       </Paper>
//                     </Grid>
//                   ))}
//                 </Grid>
//                 <Button
//                   variant="contained"
//                   color="primary"
//                   onClick={() => handleRestoreDeletedProduct(index)}
//                   sx={{ mt: 1 }}
//                 >
//                   復元
//                 </Button>
//               </Box>
//             ))
//           )}
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => setOpenDeletedProductsDialog(false)}>閉じる</Button>
//         </DialogActions>
//       </Dialog>

//       {/* Snackbar */}
//       <Snackbar
//         open={snackbarState.open}
//         autoHideDuration={6000}
//         onClose={handleSnackbarClose}
//         message={snackbarState.message}
//         action={
//           (lastDeletedImage || lastDeletedProduct) && (
//             <Button
//               color="secondary"
//               size="small"
//               onClick={
//                 lastDeletedImage ? handleUndoDeleteImage : handleUndoDeleteProduct
//               }
//             >
//               元に戻す
//             </Button>
//           )
//         }
//       />
//     </>
//   );
// }

// export default ImageOperations;







// // ImageOperations.js

// import React, { useState, useCallback, useEffect, useRef } from "react";
// import {
//   Button,
//   Typography,
//   Box,
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogTitle,
//   Paper,
//   Grid,
//   IconButton,
//   TextField,
//   MenuItem,
//   Select,
//   FormControl,
//   Pagination,
//   Snackbar,
//   DialogContentText,
// } from "@mui/material";
// import { useTheme } from "@mui/material/styles";
// import CloseIcon from "@mui/icons-material/Close";
// import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
// import DescriptionIcon from "@mui/icons-material/Description";
// import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
// import {
//   getStorage,
//   ref as storageRef,
//   uploadBytes,
//   getDownloadURL,
// } from "firebase/storage";
// import { v4 as uuidv4 } from "uuid";
// import axios from "axios";
// import performImageAnalysis from "./ImageAnalysis"; // 画像解析関数をインポート
// import ImageAnalysis from "./ImageAnalysis";

// function ImageOperations({
//   data,
//   setData,
//   startRow,
//   endRow,
//   calculateMaxEndRow,
//   isProcessing,
//   setIsProcessing,
//   setError,
//   showSnackbar, // プロパティとして渡される
//   openPreviewDialog,
//   setOpenPreviewDialog,
//   apiKey, // OpenAIのAPIキー
// }) {
//   const [previewImages, setPreviewImages] = useState([]);
//   const [displayedPreviewImages, setDisplayedPreviewImages] = useState([]);
//   const [selectedImages, setSelectedImages] = useState(new Set());
//   const [selectedProductForEdit, setSelectedProductForEdit] = useState(null);
//   const [isAddImageDialogOpen, setIsAddImageDialogOpen] = useState(false);
//   const [newImageFile, setNewImageFile] = useState(null);
//   const [newImageURL, setNewImageURL] = useState("");
//   const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

//   const [itemsPerPage, setItemsPerPage] = useState(50); // 1ページあたりの表示件数
//   const [currentPage, setCurrentPage] = useState(1); // 現在のページ番号

//   const [previewDialogMode, setPreviewDialogMode] = useState("edit"); // 'edit' または 'analyze'
//   const [isAnalyzing, setIsAnalyzing] = useState(false);

//   const theme = useTheme();

//   // スクロール可能なコンテナのrefを追加
//   const scrollableContainerRef = useRef(null);
//   const [showScrollTopButton, setShowScrollTopButton] = useState(false); // スクロールトップボタンの表示制御

//   // 追加: 削除された画像の情報を保存する状態
//   const [lastDeletedImage, setLastDeletedImage] = useState(null);
//   const [snackbarState, setSnackbarState] = useState({
//     open: false,
//     message: "",
//   });

//   // 追加: 削除された商品の情報を保存する状態
//   const [deletedProducts, setDeletedProducts] = useState([]);
//   const [lastDeletedProduct, setLastDeletedProduct] = useState(null);
//   const [openDeletedProductsDialog, setOpenDeletedProductsDialog] = useState(false);

//   // 商品IDを管理するための状態
//   const [productIdCounter, setProductIdCounter] = useState(0);

//   const handleOpenPreviewDialog = useCallback(() => {
//     try {
//       if (!startRow || !endRow || isNaN(startRow) || isNaN(endRow)) {
//         throw new Error("Start RowとEnd Rowは有効な数字である必要があります。");
//       }

//       const picUrlKey = Object.keys(data[0]).find(
//         (key) => key.toLowerCase() === "picurl"
//       );
//       const titleKey = Object.keys(data[0]).find(
//         (key) => key.toLowerCase() === "title"
//       );

//       if (!picUrlKey) {
//         throw new Error("PicURLカラムが見つかりません。");
//       }

//       const actualStartRow = parseInt(startRow) - 1; // インデックスは0から開始
//       const maxEndRow = calculateMaxEndRow();
//       const actualEndRow = Math.min(parseInt(endRow) - 1, maxEndRow - 1);

//       if (actualStartRow < 0 || actualEndRow < actualStartRow) {
//         throw new Error("Start RowとEnd Rowが有効ではありません。");
//       }

//       let previewItems = [];

//       // すべての画像を取得
//       for (let i = actualStartRow; i <= actualEndRow; i++) {
//         const item = data[i];
//         const picUrl = item[picUrlKey];
//         const imageUrls = picUrl ? picUrl.split("|") : [];
//         const title = item[titleKey] || `商品 ${i + 1}`;
//         const images = imageUrls.map((url, idx) => ({
//           url,
//           imageIndex: idx + 1,
//         }));
//         if (images.length > 0) {
//           previewItems.push({
//             id: item._uniqueId, // 一意のIDを使用
//             title,
//             rowIndex: i,
//             images,
//           });
//         }
//       }

//       setPreviewImages(previewItems);
//       setSelectedImages(new Set()); // すべて未選択
//       setCurrentPage(1); // ページをリセット
//     } catch (err) {
//       const errorMessage = `プレビューの準備中にエラーが発生しました: ${err.message}`;
//       setError(errorMessage);
//       console.error(errorMessage, err);
//     }
//   }, [data, startRow, endRow, calculateMaxEndRow, setError]);

//   // openPreviewDialogがtrueになったときにプレビューを準備
//   useEffect(() => {
//     if (openPreviewDialog) {
//       handleOpenPreviewDialog();
//     } else {
//       // ダイアログが閉じられたときにプレビュー画像をクリア
//       setPreviewImages([]);
//       setSelectedImages(new Set());
//     }
//   }, [openPreviewDialog, handleOpenPreviewDialog]);

//   // ページネーションの処理
//   useEffect(() => {
//     if (itemsPerPage === "ALL") {
//       setDisplayedPreviewImages(previewImages);
//     } else {
//       const startIndex = (currentPage - 1) * itemsPerPage;
//       const endIndex = startIndex + itemsPerPage;
//       setDisplayedPreviewImages(previewImages.slice(startIndex, endIndex));
//     }
//   }, [previewImages, currentPage, itemsPerPage]);

//   // currentPageが変更されたときにスクロール位置をトップに戻す
//   useEffect(() => {
//     if (scrollableContainerRef.current) {
//       scrollableContainerRef.current.scrollTop = 0;
//     }
//   }, [currentPage]);

//   // スクロール位置に応じてスクロールトップボタンの表示を制御
//   useEffect(() => {
//     const handleScroll = () => {
//       if (scrollableContainerRef.current) {
//         const scrollTop = scrollableContainerRef.current.scrollTop;
//         setShowScrollTopButton(scrollTop > 200);
//       }
//     };

//     const container = scrollableContainerRef.current;
//     if (container) {
//       container.addEventListener("scroll", handleScroll);
//       return () => container.removeEventListener("scroll", handleScroll);
//     }
//   }, [scrollableContainerRef]);

//   // Snackbarの自動非表示に合わせてlastDeletedImageとlastDeletedProductをクリア
//   useEffect(() => {
//     if (snackbarState.open && (lastDeletedImage || lastDeletedProduct)) {
//       const timer = setTimeout(() => {
//         setLastDeletedImage(null);
//         setLastDeletedProduct(null);
//       }, 6000); // SnackbarのautoHideDurationと同じ

//       return () => clearTimeout(timer);
//     }
//   }, [snackbarState.open, lastDeletedImage, lastDeletedProduct]);

//   const totalPages =
//     itemsPerPage === "ALL" ? 1 : Math.ceil(previewImages.length / itemsPerPage);

//   const handleItemsPerPageChange = (event) => {
//     const value =
//       event.target.value === "ALL" ? "ALL" : parseInt(event.target.value);
//     setItemsPerPage(value);
//     setCurrentPage(1); // ページをリセット
//   };

//   const handlePageChange = (event, value) => {
//     setCurrentPage(value);
//   };

//   const toggleImageSelection = (url) => {
//     setSelectedImages((prevSelected) => {
//       const newSelected = new Set(prevSelected);
//       if (newSelected.has(url)) {
//         newSelected.delete(url);
//       } else {
//         newSelected.add(url);
//       }
//       return newSelected;
//     });
//   };

//   const handleDelete = useCallback(async () => {
//     setIsProcessing(true);
//     setError("");

//     try {
//       if (!startRow || !endRow || isNaN(startRow) || isNaN(endRow)) {
//         throw new Error("Start RowとEnd Rowは有効な数字である必要があります。");
//       }

//       const picUrlKey = Object.keys(data[0]).find(
//         (key) => key.toLowerCase() === "picurl"
//       );

//       if (!picUrlKey) {
//         throw new Error("PicURLカラムが見つかりません。");
//       }

//       const actualStartRow = parseInt(startRow) - 1; // インデックスは0から開始
//       const maxEndRow = calculateMaxEndRow();
//       const actualEndRow = Math.min(parseInt(endRow) - 1, maxEndRow - 1);

//       if (actualStartRow < 0 || actualEndRow < actualStartRow) {
//         throw new Error("Start RowとEnd Rowが有効ではありません。");
//       }

//       // データの更新
//       const updatedData = [...data];
//       for (let i = actualStartRow; i <= actualEndRow; i++) {
//         const item = { ...updatedData[i] };
//         const originalImages = item[picUrlKey]
//           ? item[picUrlKey].split("|")
//           : [];
//         const newImages = originalImages.filter(
//           (url) => !selectedImages.has(url)
//         );
//         item[picUrlKey] = newImages.join("|");
//         updatedData[i] = item;
//       }

//       setData(updatedData);

//       // プレビュー画像を更新
//       const updatedPreviewImages = previewImages.map((product) => {
//         const item = updatedData[product.rowIndex];
//         const picUrl = item[picUrlKey];
//         const imageUrls = picUrl ? picUrl.split("|") : [];
//         const images = imageUrls.map((url, idx) => ({
//           url,
//           imageIndex: idx + 1,
//         }));
//         return {
//           ...product,
//           images,
//         };
//       });
//       setPreviewImages(updatedPreviewImages);

//       const finalMessage = `画像の削除が完了しました。`;
//       showSnackbar(finalMessage);
//     } catch (err) {
//       const errorMessage = `画像の削除に失敗しました: ${err.message}`;
//       setError(errorMessage);
//       showSnackbar(errorMessage);
//       console.error(errorMessage, err);
//     } finally {
//       setIsProcessing(false);
//       setOpenConfirmDialog(false);
//       setOpenPreviewDialog(false);
//     }
//   }, [
//     data,
//     startRow,
//     endRow,
//     selectedImages,
//     calculateMaxEndRow,
//     setData,
//     previewImages,
//     setIsProcessing,
//     setError,
//     showSnackbar,
//     setOpenPreviewDialog,
//   ]);

//   const handleAddImageClick = (product) => {
//     setSelectedProductForEdit(product);
//     setIsAddImageDialogOpen(true);
//   };

//   const handleImageFileChange = (e) => {
//     if (e.target.files && e.target.files[0]) {
//       setNewImageFile(e.target.files[0]);
//       setNewImageURL("");
//     }
//   };

//   const handleAddImage = async () => {
//     const storage = getStorage();

//     try {
//       let imageUrl = newImageURL;

//       if (newImageFile) {
//         // Firebase Storage に画像をアップロード
//         const fileExtension = newImageFile.name.split(".").pop();
//         const storageReference = storageRef(
//           storage,
//           `images/${uuidv4()}.${fileExtension}`
//         );
//         await uploadBytes(storageReference, newImageFile);
//         imageUrl = await getDownloadURL(storageReference);
//       }

//       if (!imageUrl) {
//         alert(
//           "画像のURLを入力するか、画像ファイルをアップロードしてください。"
//         );
//         return;
//       }

//       // データの更新
//       const picUrlKey = Object.keys(data[0]).find(
//         (key) => key.toLowerCase() === "picurl"
//       );
//       const updatedData = [...data];
//       const item = { ...updatedData[selectedProductForEdit.rowIndex] };
//       const imageUrls = item[picUrlKey] ? item[picUrlKey].split("|") : [];
//       imageUrls.push(imageUrl);
//       item[picUrlKey] = imageUrls.join("|");
//       updatedData[selectedProductForEdit.rowIndex] = item;
//       setData(updatedData);

//       // previewImagesを更新
//       const updatedPreviewImages = previewImages.map((product) => {
//         if (product.rowIndex === selectedProductForEdit.rowIndex) {
//           const images = [
//             ...product.images,
//             { url: imageUrl, imageIndex: product.images.length + 1 },
//           ];
//           return {
//             ...product,
//             images,
//           };
//         }
//         return product;
//       });
//       setPreviewImages(updatedPreviewImages);

//       setIsAddImageDialogOpen(false);
//       setNewImageFile(null);
//       setNewImageURL("");
//     } catch (error) {
//       console.error("画像の追加中にエラーが発生しました:", error);
//       alert("画像の追加に失敗しました。再度お試しください。");
//     }
//   };

//   // 商品削除の関数を修正
//   const handleDeleteProduct = (rowIndex) => {
//     // 削除する商品のデータを保存
//     const deletedProduct = {
//       data: data[rowIndex],
//       previewImage: previewImages.find((product) => product.rowIndex === rowIndex),
//       originalRowIndex: rowIndex, // 削除時のrowIndexを保持
//     };

//     // データから商品を削除
//     const updatedData = data.filter((_, index) => index !== rowIndex);

//     setData(updatedData);

//     // プレビュー画像から商品を削除
//     const updatedPreviewImages = previewImages.filter(
//       (product) => product.rowIndex !== rowIndex
//     );

//     setPreviewImages(updatedPreviewImages);

//     // 削除された商品の情報を保存
//     setDeletedProducts((prevDeletedProducts) => [
//       ...prevDeletedProducts,
//       deletedProduct,
//     ]);

//     // 最後に削除された商品を保存
//     setLastDeletedProduct(deletedProduct);

//     // スナックバーで通知
//     localShowSnackbar("商品が削除されました。");

//     // rowIndexを再計算しないことで、削除された商品のoriginalRowIndexが保持され、重複が防止されます
//   };

//   // 削除された商品を復元する関数を修正
//   const handleRestoreDeletedProduct = (deletedProductIndex) => {
//     const deletedProduct = deletedProducts[deletedProductIndex];
//     if (!deletedProduct) return;

//     const { data: deletedData, previewImage: deletedPreviewImage, originalRowIndex } =
//       deletedProduct;

//     // データを復元
//     const updatedData = [...data];
//     updatedData.splice(originalRowIndex, 0, deletedData);

//     setData(updatedData);

//     // プレビュー画像を復元
//     const updatedPreviewImages = [...previewImages];
//     updatedPreviewImages.splice(originalRowIndex, 0, deletedPreviewImage);

//     setPreviewImages(updatedPreviewImages);

//     // 削除された商品のリストから削除
//     const updatedDeletedProducts = deletedProducts.filter(
//       (_, index) => index !== deletedProductIndex
//     );
//     setDeletedProducts(updatedDeletedProducts);

//     // スナックバーで通知
//     localShowSnackbar("商品を復元しました。");
//   };

//   // 最後に削除された商品を元に戻す関数を修正
//   const handleUndoDeleteProduct = () => {
//     if (!lastDeletedProduct) return;

//     const { data: deletedData, previewImage: deletedPreviewImage, originalRowIndex } =
//       lastDeletedProduct;

//     // データを復元
//     const updatedData = [...data];
//     updatedData.splice(originalRowIndex, 0, deletedData);

//     setData(updatedData);

//     // プレビュー画像を復元
//     const updatedPreviewImages = [...previewImages];
//     updatedPreviewImages.splice(originalRowIndex, 0, deletedPreviewImage);

//     setPreviewImages(updatedPreviewImages);

//     // 削除された商品のリストから削除
//     const updatedDeletedProducts = deletedProducts.filter(
//       (product) => product !== lastDeletedProduct
//     );
//     setDeletedProducts(updatedDeletedProducts);

//     // 'lastDeletedProduct' をクリア
//     setLastDeletedProduct(null);

//     // スナックバーで通知
//     localShowSnackbar("削除を元に戻しました。");
//   };

//   // ドラッグ終了時のハンドラー
//   const handleDragEnd = (result, product) => {
//     if (!result.destination) {
//       return;
//     }

//     const { source, destination } = result;

//     const productIndex = previewImages.findIndex(
//       (p) => p.rowIndex === product.rowIndex
//     );
//     if (productIndex === -1) {
//       return;
//     }

//     const images = Array.from(previewImages[productIndex].images);
//     const [movedImage] = images.splice(source.index, 1);
//     images.splice(destination.index, 0, movedImage);

//     // previewImagesを更新
//     const updatedPreviewImages = [...previewImages];
//     updatedPreviewImages[productIndex] = {
//       ...previewImages[productIndex],
//       images,
//     };
//     setPreviewImages(updatedPreviewImages);

//     // dataを更新
//     const picUrlKey = Object.keys(data[0]).find(
//       (key) => key.toLowerCase() === "picurl"
//     );
//     const updatedData = [...data];
//     const item = { ...updatedData[product.rowIndex] };
//     item[picUrlKey] = images.map((img) => img.url).join("|");
//     updatedData[product.rowIndex] = item;
//     setData(updatedData);
//   };

//   // 画像解析を行う関数
//   const handleImageAnalysis = async () => {
//     setIsProcessing(true);
//     setError("");

//     try {
//       const updatedData = [...data];
//       const picUrlKey = Object.keys(data[0]).find(
//         (key) => key.toLowerCase() === "picurl"
//       );

//       if (!picUrlKey) {
//         throw new Error("PicURLカラムが見つかりません。");
//       }

//       if (selectedImages.size === 0) {
//         throw new Error("解析する画像を選択してください。");
//       }

//       for (const selectedImageUrl of selectedImages) {
//         // 対応する商品（行）を見つける
//         const itemIndex = data.findIndex((item) => {
//           const picUrl = item[picUrlKey];
//           const imageUrls = picUrl ? picUrl.split("|") : [];
//           return imageUrls.includes(selectedImageUrl);
//         });

//         if (itemIndex !== -1) {
//           const item = updatedData[itemIndex];
//           // 画像を解析して説明を取得
//           // const description = await performImageAnalysis(selectedImageUrl, apiKey);
//           const description = await ImageAnalysis(selectedImageUrl, apiKey);
//           // 既存の説明がある場合は追記、それ以外は新規作成
//           if (item["jp_image_description"]) {
//             item["jp_image_description"] += `\n\n${description}`;
//           } else {
//             item["jp_image_description"] = description;
//           }
//         }
//       }

//       setData(updatedData);
//       showSnackbar("選択された画像の解析が完了し、データが更新されました。");
//     } catch (error) {
//       setError(`画像解析中にエラーが発生しました: ${error.message}`);
//       console.error(error);
//     } finally {
//       setIsProcessing(false);
//       setOpenPreviewDialog(false);
//     }
//   };

//   // 画像を削除する関数
//   const handleDeleteImage = (imageUrl, productRowIndex) => {
//     try {
//       const picUrlKey = Object.keys(data[0]).find(
//         (key) => key.toLowerCase() === "picurl"
//       );

//       const updatedData = [...data];
//       const item = { ...updatedData[productRowIndex] };
//       const imageUrls = item[picUrlKey] ? item[picUrlKey].split("|") : [];

//       // 削除する画像のインデックスを取得
//       const imageIndex = imageUrls.findIndex((url) => url === imageUrl);

//       // 画像を削除
//       const newImageUrls = imageUrls.filter((url) => url !== imageUrl);
//       item[picUrlKey] = newImageUrls.join("|");
//       updatedData[productRowIndex] = item;
//       setData(updatedData);

//       // プレビュー画像を更新
//       const updatedPreviewImages = previewImages.map((product) => {
//         if (product.rowIndex === productRowIndex) {
//           const images = product.images.filter((img) => img.url !== imageUrl);
//           return {
//             ...product,
//             images,
//           };
//         }
//         return product;
//       });
//       setPreviewImages(updatedPreviewImages);

//       // 削除された画像の情報を保存
//       setLastDeletedImage({
//         imageUrl,
//         productRowIndex,
//         imageIndex,
//       });

//       // スナックバーで「元に戻す」ボタンを表示
//       localShowSnackbar("画像が削除されました。");
//     } catch (error) {
//       console.error("画像の削除中にエラーが発生しました:", error);
//       setError(`画像の削除中にエラーが発生しました: ${error.message}`);
//     }
//   };

//   // 画像削除を元に戻す関数
//   const handleUndoDeleteImage = () => {
//     if (!lastDeletedImage) return;

//     const { imageUrl, productRowIndex, imageIndex } = lastDeletedImage;

//     try {
//       const picUrlKey = Object.keys(data[0]).find(
//         (key) => key.toLowerCase() === "picurl"
//       );

//       // データを更新して画像を元の位置に挿入
//       const updatedData = [...data];
//       const item = { ...updatedData[productRowIndex] };
//       const imageUrls = item[picUrlKey] ? item[picUrlKey].split("|") : [];

//       // 元の位置に画像を挿入
//       imageUrls.splice(imageIndex, 0, imageUrl);
//       item[picUrlKey] = imageUrls.join("|");
//       updatedData[productRowIndex] = item;
//       setData(updatedData);

//       // プレビュー画像を更新
//       const updatedPreviewImages = previewImages.map((product) => {
//         if (product.rowIndex === productRowIndex) {
//           const images = [...product.images];
//           images.splice(imageIndex, 0, { url: imageUrl, imageIndex });
//           return {
//             ...product,
//             images,
//           };
//         }
//         return product;
//       });
//       setPreviewImages(updatedPreviewImages);

//       // 削除された画像の情報をクリア
//       setLastDeletedImage(null);

//       // スナックバーで復元が完了したことを通知
//       localShowSnackbar("削除を元に戻しました。");
//     } catch (error) {
//       console.error("画像の復元中にエラーが発生しました:", error);
//       setError(`画像の復元中にエラーが発生しました: ${error.message}`);
//     }
//   };

//   // Snackbarを表示する関数
//   const handleSnackbarClose = () => {
//     setSnackbarState({ ...snackbarState, open: false });
//   };

//   // プロパティとして渡された showSnackbar と区別するために関数名を変更
//   const localShowSnackbar = (message) => {
//     setSnackbarState({
//       open: true,
//       message,
//     });
//   };

//   return (
//     <>
//       {/* 画像解析ボタンを追加 */}
//       <Button
//         variant="contained"
//         color="primary"
//         startIcon={<DescriptionIcon />}
//         onClick={() => {
//           setPreviewDialogMode("analyze");
//           setOpenPreviewDialog(true);
//         }}
//         disabled={isProcessing}
//         sx={{ mt: 2 }}
//       >
//         画像解析
//       </Button>

//       {/* 削除された商品一覧を開くボタンを追加 */}
//       <Button
//         variant="outlined"
//         color="secondary"
//         onClick={() => setOpenDeletedProductsDialog(true)}
//         sx={{ mt: 2, ml: 2 }}
//       >
//         削除された商品一覧
//       </Button>

//       {/* プレビューダイアログ */}
//       <Dialog
//         open={openPreviewDialog}
//         onClose={() => setOpenPreviewDialog(false)}
//         maxWidth="xl"
//         fullWidth
//       >
//         <DialogTitle>
//           {previewDialogMode === "edit" ? "画像編集・削除" : "画像解析"}
//           <IconButton
//             aria-label="close"
//             onClick={() => setOpenPreviewDialog(false)}
//             sx={{
//               position: "absolute",
//               right: 8,
//               top: 8,
//               color: (theme) => theme.palette.grey[500],
//             }}
//           >
//             <CloseIcon />
//           </IconButton>
//         </DialogTitle>
//         <DialogContent>
//           {/* 表示件数の選択 */}
//           <Box sx={{ mb: 2, display: "flex", alignItems: "center" }}>
//             <Typography variant="body2" sx={{ mr: 2 }}>
//               表示件数:
//             </Typography>
//             <FormControl variant="outlined" size="small">
//               <Select
//                 value={itemsPerPage}
//                 onChange={handleItemsPerPageChange}
//                 sx={{ minWidth: 120 }}
//               >
//                 <MenuItem value={50}>50</MenuItem>
//                 <MenuItem value={100}>100</MenuItem>
//                 <MenuItem value={150}>150</MenuItem>
//                 <MenuItem value={200}>200</MenuItem>
//                 <MenuItem value="ALL">ALL</MenuItem>
//               </Select>
//             </FormControl>
//           </Box>
//           <Typography variant="body2" sx={{ mb: 2 }}>
//             {previewDialogMode === "edit"
//               ? "削除したい画像の「×」をクリックしてください。画像をドラッグ＆ドロップで並び替えることもできます。"
//               : "解析したい画像をクリックして選択してください。"}
//           </Typography>
//           <Box
//             ref={scrollableContainerRef} // ここでrefを設定
//             sx={{ maxHeight: "70vh", overflowY: "auto", position: "relative" }}
//           >
//             {displayedPreviewImages.map((product, index) => (
//               <Box key={index} sx={{ mb: 4 }}>
//                 <Typography variant="h6">{product.title}</Typography>

//                 {/* 商品を削除するボタンを修正 */}
//                 {previewDialogMode === "edit" && (
//                   <Button
//                     variant="outlined"
//                     color="error"
//                     onClick={() => handleDeleteProduct(product.rowIndex)}
//                     sx={{ mb: 2 }}
//                   >
//                     商品を削除
//                   </Button>
//                 )}

//                 <DragDropContext
//                   onDragEnd={(result) => handleDragEnd(result, product)}
//                 >
//                   <Droppable
//                     droppableId={`droppable-${product.rowIndex}`}
//                     direction="horizontal"
//                   >
//                     {(provided) => (
//                       <Grid
//                         container
//                         spacing={2}
//                         ref={provided.innerRef}
//                         {...provided.droppableProps}
//                       >
//                         {product.images.map((image, idx) => (
//                           <Draggable
//                             key={image.url}
//                             draggableId={image.url}
//                             index={idx}
//                             isDragDisabled={previewDialogMode === "analyze"}
//                           >
//                             {(provided) => (
//                               <Grid
//                                 item
//                                 xs={3}
//                                 sm={2}
//                                 md={1.5}
//                                 lg={1}
//                                 ref={provided.innerRef}
//                                 {...provided.draggableProps}
//                                 {...provided.dragHandleProps}
//                               >
//                                 <Paper
//                                   elevation={1}
//                                   sx={{
//                                     p: 1,
//                                     position: "relative",
//                                     opacity: selectedImages.has(image.url)
//                                       ? 0.4
//                                       : 1,
//                                     border: selectedImages.has(image.url)
//                                       ? "2px solid blue"
//                                       : "none",
//                                   }}
//                                   onClick={() => toggleImageSelection(image.url)}
//                                 >
//                                   {previewDialogMode === "edit" && (
//                                     <IconButton
//                                       aria-label="delete"
//                                       onClick={(e) => {
//                                         e.stopPropagation();
//                                         handleDeleteImage(
//                                           image.url,
//                                           product.rowIndex
//                                         );
//                                       }}
//                                       sx={{
//                                         position: "absolute",
//                                         top: 0,
//                                         right: 0,
//                                         color: (theme) =>
//                                           theme.palette.error.main,
//                                       }}
//                                     >
//                                       <CloseIcon />
//                                     </IconButton>
//                                   )}
//                                   <img
//                                     src={image.url}
//                                     alt={`Row ${product.rowIndex + 1} Image ${
//                                       image.imageIndex
//                                     }`}
//                                     style={{ width: "100%", height: "auto" }}
//                                     loading="lazy"
//                                   />
//                                   <Typography variant="caption">
//                                     画像 {idx + 1}
//                                   </Typography>
//                                 </Paper>
//                               </Grid>
//                             )}
//                           </Draggable>
//                         ))}

//                         {provided.placeholder}

//                         {/* プラスアイコンのDraggableアイテム */}
//                         {previewDialogMode === "edit" && (
//                           <Draggable
//                             key={`add-image-${product.rowIndex}`}
//                             draggableId={`add-image-${product.rowIndex}`}
//                             index={product.images.length}
//                             isDragDisabled={true} // ドラッグ不可に設定
//                           >
//                             {(provided) => (
//                               <Grid
//                                 item
//                                 xs={3}
//                                 sm={2}
//                                 md={1.5}
//                                 lg={1}
//                                 ref={provided.innerRef}
//                                 {...provided.draggableProps}
//                               >
//                                 <Paper
//                                   elevation={1}
//                                   sx={{
//                                     p: 1,
//                                     display: "flex",
//                                     alignItems: "center",
//                                     justifyContent: "center",
//                                     height: "100%",
//                                     border: "2px dashed #1976d2",
//                                     color: "#1976d2",
//                                     cursor: "pointer",
//                                   }}
//                                   onClick={() => handleAddImageClick(product)}
//                                 >
//                                   <Typography variant="h4">+</Typography>
//                                 </Paper>
//                               </Grid>
//                             )}
//                           </Draggable>
//                         )}
//                       </Grid>
//                     )}
//                   </Droppable>
//                 </DragDropContext>
//               </Box>
//             ))}
//           </Box>
//           {/* スクロールトップボタン */}
//           {showScrollTopButton && (
//             <IconButton
//               onClick={() => {
//                 if (scrollableContainerRef.current) {
//                   scrollableContainerRef.current.scrollTo({
//                     top: 0,
//                     behavior: "smooth",
//                   });
//                 }
//               }}
//               sx={{
//                 position: "fixed",
//                 bottom: 100,
//                 right: 40,
//                 zIndex: 1000,
//                 backgroundColor: theme.palette.background.paper,
//                 "&:hover": {
//                   backgroundColor: theme.palette.grey[300],
//                 },
//               }}
//             >
//               <KeyboardArrowUpIcon />
//             </IconButton>
//           )}
//           {/* ページネーション */}
//           {totalPages > 1 && (
//             <Box sx={{ mt: 2, display: "flex", justifyContent: "center" }}>
//               <Pagination
//                 count={totalPages}
//                 page={currentPage}
//                 onChange={handlePageChange}
//                 color="primary"
//               />
//             </Box>
//           )}
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => setOpenPreviewDialog(false)}>閉じる</Button>
//           {previewDialogMode === "edit" ? (
//             <Button
//               onClick={() => {
//                 setOpenConfirmDialog(true);
//               }}
//               color="primary"
//               variant="contained"
//               disabled={selectedImages.size === 0}
//             >
//               削除実行
//             </Button>
//           ) : (
//             <Button
//               onClick={handleImageAnalysis}
//               color="primary"
//               variant="contained"
//               disabled={selectedImages.size === 0}
//             >
//               解析実行
//             </Button>
//           )}
//         </DialogActions>
//       </Dialog>

//       {/* 画像追加ダイアログ */}
//       <Dialog
//         open={isAddImageDialogOpen}
//         onClose={() => setIsAddImageDialogOpen(false)}
//       >
//         <DialogTitle>画像を追加</DialogTitle>
//         <DialogContent>
//           <Typography variant="body2" sx={{ mb: 2 }}>
//             画像のURLを入力するか、画像ファイルをアップロードしてください。
//           </Typography>
//           <TextField
//             label="画像URL"
//             value={newImageURL}
//             onChange={(e) => setNewImageURL(e.target.value)}
//             fullWidth
//             sx={{ mb: 2 }}
//           />
//           <Typography variant="body2" sx={{ mb: 1 }}>
//             または
//           </Typography>
//           <Button variant="contained" component="label">
//             画像を選択
//             <input
//               type="file"
//               accept="image/*"
//               hidden
//               onChange={handleImageFileChange}
//             />
//           </Button>
//           {newImageFile && (
//             <Typography variant="body2" sx={{ mt: 1 }}>
//               選択されたファイル: {newImageFile.name}
//             </Typography>
//           )}
//         </DialogContent>
//         <DialogActions>
//           <Button
//             onClick={() => {
//               setIsAddImageDialogOpen(false);
//               setNewImageFile(null);
//               setNewImageURL("");
//             }}
//           >
//             キャンセル
//           </Button>
//           <Button onClick={handleAddImage} color="primary" variant="contained">
//             追加
//           </Button>
//         </DialogActions>
//       </Dialog>

//       {/* 削除確認ダイアログを追加 */}
//       <Dialog
//         open={openConfirmDialog}
//         onClose={() => setOpenConfirmDialog(false)}
//       >
//         <DialogTitle>確認</DialogTitle>
//         <DialogContent>
//           <DialogContentText>
//             選択された画像を削除しますか？
//           </DialogContentText>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => setOpenConfirmDialog(false)}>キャンセル</Button>
//           <Button onClick={handleDelete} color="primary" variant="contained">
//             削除
//           </Button>
//         </DialogActions>
//       </Dialog>

//       {/* 削除された商品一覧ダイアログ */}
//       <Dialog
//         open={openDeletedProductsDialog}
//         onClose={() => setOpenDeletedProductsDialog(false)}
//         maxWidth="md"
//         fullWidth
//       >
//         <DialogTitle>削除された商品一覧</DialogTitle>
//         <DialogContent>
//           {deletedProducts.length === 0 ? (
//             <Typography>削除された商品はありません。</Typography>
//           ) : (
//             deletedProducts.map((deletedProduct, index) => (
//               <Box key={index} sx={{ mb: 4 }}>
//                 <Typography variant="h6">
//                   {deletedProduct.data.title || `商品 ${deletedProduct.originalRowIndex + 1}`}
//                 </Typography>
//                 {/* 商品の画像を表示 */}
//                 <Grid container spacing={2}>
//                   {deletedProduct.previewImage.images.map((image, idx) => (
//                     <Grid item xs={3} sm={2} md={1.5} lg={1} key={idx}>
//                       <Paper elevation={1} sx={{ p: 1 }}>
//                         <img
//                           src={image.url}
//                           alt={`Deleted Product ${index + 1} Image ${image.imageIndex}`}
//                           style={{ width: "100%", height: "auto" }}
//                           loading="lazy"
//                         />
//                         <Typography variant="caption">
//                           画像 {idx + 1}
//                         </Typography>
//                       </Paper>
//                     </Grid>
//                   ))}
//                 </Grid>
//                 <Button
//                   variant="contained"
//                   color="primary"
//                   onClick={() => handleRestoreDeletedProduct(index)}
//                   sx={{ mt: 1 }}
//                 >
//                   復元
//                 </Button>
//               </Box>
//             ))
//           )}
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => setOpenDeletedProductsDialog(false)}>閉じる</Button>
//         </DialogActions>
//       </Dialog>

//       {/* Snackbar */}
//       <Snackbar
//         open={snackbarState.open}
//         autoHideDuration={6000}
//         onClose={handleSnackbarClose}
//         message={snackbarState.message}
//         action={
//           (lastDeletedImage || lastDeletedProduct) && (
//             <Button
//               color="secondary"
//               size="small"
//               onClick={
//                 lastDeletedImage ? handleUndoDeleteImage : handleUndoDeleteProduct
//               }
//             >
//               元に戻す
//             </Button>
//           )
//         }
//       />
//     </>
//   );
// }

// export default ImageOperations;






// // ImageOperations.js

// import React, { useState, useCallback, useEffect, useRef } from "react";
// import {
//   Button,
//   Typography,
//   Box,
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogTitle,
//   Paper,
//   Grid,
//   IconButton,
//   TextField,
//   MenuItem,
//   Select,
//   FormControl,
//   Pagination,
//   Snackbar,
//   DialogContentText,
// } from "@mui/material";
// import { useTheme } from "@mui/material/styles";
// import CloseIcon from "@mui/icons-material/Close";
// import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
// import DescriptionIcon from "@mui/icons-material/Description";
// import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
// import {
//   getStorage,
//   ref as storageRef,
//   uploadBytes,
//   getDownloadURL,
// } from "firebase/storage";
// import { v4 as uuidv4 } from "uuid";
// import axios from "axios";
// import performImageAnalysis from "./ImageAnalysis"; // 画像解析関数をインポート
// import ImageAnalysis from "./ImageAnalysis";

// function ImageOperations({
//   data,
//   setData,
//   startRow,
//   endRow,
//   calculateMaxEndRow,
//   isProcessing,
//   setIsProcessing,
//   setError,
//   showSnackbar, // プロパティとして渡される
//   openPreviewDialog,
//   setOpenPreviewDialog,
//   apiKey, // OpenAIのAPIキー
// }) {
//   const [previewImages, setPreviewImages] = useState([]);
//   const [displayedPreviewImages, setDisplayedPreviewImages] = useState([]);
//   const [selectedImages, setSelectedImages] = useState(new Set());
//   const [selectedProductForEdit, setSelectedProductForEdit] = useState(null);
//   const [isAddImageDialogOpen, setIsAddImageDialogOpen] = useState(false);
//   const [newImageFile, setNewImageFile] = useState(null);
//   const [newImageURL, setNewImageURL] = useState("");
//   const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

//   const [itemsPerPage, setItemsPerPage] = useState(50); // 1ページあたりの表示件数
//   const [currentPage, setCurrentPage] = useState(1); // 現在のページ番号

//   const [previewDialogMode, setPreviewDialogMode] = useState("edit"); // 'edit' または 'analyze'
//   const [isAnalyzing, setIsAnalyzing] = useState(false);

//   const theme = useTheme();

//   // スクロール可能なコンテナのrefを追加
//   const scrollableContainerRef = useRef(null);
//   const [showScrollTopButton, setShowScrollTopButton] = useState(false); // スクロールトップボタンの表示制御

//   // 追加: 削除された画像の情報を保存する状態
//   const [lastDeletedImage, setLastDeletedImage] = useState(null);
//   const [snackbarState, setSnackbarState] = useState({
//     open: false,
//     message: "",
//   });

//   // 追加: 削除された商品の情報を保存する状態
//   const [deletedProducts, setDeletedProducts] = useState([]);
//   const [lastDeletedProduct, setLastDeletedProduct] = useState(null);
//   const [openDeletedProductsDialog, setOpenDeletedProductsDialog] = useState(false);

//   const handleOpenPreviewDialog = useCallback(() => {
//     try {
//       if (!startRow || !endRow || isNaN(startRow) || isNaN(endRow)) {
//         throw new Error("Start RowとEnd Rowは有効な数字である必要があります。");
//       }

//       const picUrlKey = Object.keys(data[0]).find(
//         (key) => key.toLowerCase() === "picurl"
//       );
//       const titleKey = Object.keys(data[0]).find(
//         (key) => key.toLowerCase() === "title"
//       );

//       if (!picUrlKey) {
//         throw new Error("PicURLカラムが見つかりません。");
//       }

//       const actualStartRow = parseInt(startRow) - 1; // インデックスは0から開始
//       const maxEndRow = calculateMaxEndRow();
//       const actualEndRow = Math.min(parseInt(endRow) - 1, maxEndRow - 1);

//       if (actualStartRow < 0 || actualEndRow < actualStartRow) {
//         throw new Error("Start RowとEnd Rowが有効ではありません。");
//       }

//       let previewItems = [];

//       // すべての画像を取得
//       for (let i = actualStartRow; i <= actualEndRow; i++) {
//         const item = data[i];
//         const picUrl = item[picUrlKey];
//         const imageUrls = picUrl ? picUrl.split("|") : [];
//         const title = item[titleKey] || `商品 ${i + 1}`;
//         const images = imageUrls.map((url, idx) => ({
//           url,
//           imageIndex: idx + 1,
//         }));
//         if (images.length > 0) {
//           previewItems.push({
//             title,
//             rowIndex: i,
//             images,
//           });
//         }
//       }

//       setPreviewImages(previewItems);
//       setSelectedImages(new Set()); // すべて未選択
//       setCurrentPage(1); // ページをリセット
//     } catch (err) {
//       const errorMessage = `プレビューの準備中にエラーが発生しました: ${err.message}`;
//       setError(errorMessage);
//       console.error(errorMessage, err);
//     }
//   }, [data, startRow, endRow, calculateMaxEndRow, setError]);

//   // openPreviewDialogがtrueになったときにプレビューを準備
//   useEffect(() => {
//     if (openPreviewDialog) {
//       handleOpenPreviewDialog();
//     } else {
//       // ダイアログが閉じられたときにプレビュー画像をクリア
//       setPreviewImages([]);
//       setSelectedImages(new Set());
//     }
//   }, [openPreviewDialog, handleOpenPreviewDialog]);

//   // ページネーションの処理
//   useEffect(() => {
//     if (itemsPerPage === "ALL") {
//       setDisplayedPreviewImages(previewImages);
//     } else {
//       const startIndex = (currentPage - 1) * itemsPerPage;
//       const endIndex = startIndex + itemsPerPage;
//       setDisplayedPreviewImages(previewImages.slice(startIndex, endIndex));
//     }
//   }, [previewImages, currentPage, itemsPerPage]);

//   // currentPageが変更されたときにスクロール位置をトップに戻す
//   useEffect(() => {
//     if (scrollableContainerRef.current) {
//       scrollableContainerRef.current.scrollTop = 0;
//     }
//   }, [currentPage]);

//   // スクロール位置に応じてスクロールトップボタンの表示を制御
//   useEffect(() => {
//     const handleScroll = () => {
//       if (scrollableContainerRef.current) {
//         const scrollTop = scrollableContainerRef.current.scrollTop;
//         setShowScrollTopButton(scrollTop > 200);
//       }
//     };

//     const container = scrollableContainerRef.current;
//     if (container) {
//       container.addEventListener("scroll", handleScroll);
//       return () => container.removeEventListener("scroll", handleScroll);
//     }
//   }, [scrollableContainerRef]);

//   // Snackbarの自動非表示に合わせてlastDeletedImageとlastDeletedProductをクリア
//   useEffect(() => {
//     if (snackbarState.open && (lastDeletedImage || lastDeletedProduct)) {
//       const timer = setTimeout(() => {
//         setLastDeletedImage(null);
//         setLastDeletedProduct(null);
//       }, 6000); // SnackbarのautoHideDurationと同じ

//       return () => clearTimeout(timer);
//     }
//   }, [snackbarState.open, lastDeletedImage, lastDeletedProduct]);

//   const totalPages =
//     itemsPerPage === "ALL" ? 1 : Math.ceil(previewImages.length / itemsPerPage);

//   const handleItemsPerPageChange = (event) => {
//     const value =
//       event.target.value === "ALL" ? "ALL" : parseInt(event.target.value);
//     setItemsPerPage(value);
//     setCurrentPage(1); // ページをリセット
//   };

//   const handlePageChange = (event, value) => {
//     setCurrentPage(value);
//   };

//   const toggleImageSelection = (url) => {
//     setSelectedImages((prevSelected) => {
//       const newSelected = new Set(prevSelected);
//       if (newSelected.has(url)) {
//         newSelected.delete(url);
//       } else {
//         newSelected.add(url);
//       }
//       return newSelected;
//     });
//   };

//   const handleDelete = useCallback(async () => {
//     setIsProcessing(true);
//     setError("");

//     try {
//       if (!startRow || !endRow || isNaN(startRow) || isNaN(endRow)) {
//         throw new Error("Start RowとEnd Rowは有効な数字である必要があります。");
//       }

//       const picUrlKey = Object.keys(data[0]).find(
//         (key) => key.toLowerCase() === "picurl"
//       );

//       if (!picUrlKey) {
//         throw new Error("PicURLカラムが見つかりません。");
//       }

//       const actualStartRow = parseInt(startRow) - 1; // インデックスは0から開始
//       const maxEndRow = calculateMaxEndRow();
//       const actualEndRow = Math.min(parseInt(endRow) - 1, maxEndRow - 1);

//       if (actualStartRow < 0 || actualEndRow < actualStartRow) {
//         throw new Error("Start RowとEnd Rowが有効ではありません。");
//       }

//       // データの更新
//       const updatedData = [...data];
//       for (let i = actualStartRow; i <= actualEndRow; i++) {
//         const item = { ...updatedData[i] };
//         const originalImages = item[picUrlKey]
//           ? item[picUrlKey].split("|")
//           : [];
//         const newImages = originalImages.filter(
//           (url) => !selectedImages.has(url)
//         );
//         item[picUrlKey] = newImages.join("|");
//         updatedData[i] = item;
//       }

//       setData(updatedData);

//       // プレビュー画像を更新
//       const updatedPreviewImages = previewImages.map((product) => {
//         const item = updatedData[product.rowIndex];
//         const picUrl = item[picUrlKey];
//         const imageUrls = picUrl ? picUrl.split("|") : [];
//         const images = imageUrls.map((url, idx) => ({
//           url,
//           imageIndex: idx + 1,
//         }));
//         return {
//           ...product,
//           images,
//         };
//       });
//       setPreviewImages(updatedPreviewImages);

//       const finalMessage = `画像の削除が完了しました。`;
//       showSnackbar(finalMessage);
//     } catch (err) {
//       const errorMessage = `画像の削除に失敗しました: ${err.message}`;
//       setError(errorMessage);
//       showSnackbar(errorMessage);
//       console.error(errorMessage, err);
//     } finally {
//       setIsProcessing(false);
//       setOpenConfirmDialog(false);
//       setOpenPreviewDialog(false);
//     }
//   }, [
//     data,
//     startRow,
//     endRow,
//     selectedImages,
//     calculateMaxEndRow,
//     setData,
//     previewImages,
//     setIsProcessing,
//     setError,
//     showSnackbar,
//     setOpenPreviewDialog,
//   ]);

//   const handleAddImageClick = (product) => {
//     setSelectedProductForEdit(product);
//     setIsAddImageDialogOpen(true);
//   };

//   const handleImageFileChange = (e) => {
//     if (e.target.files && e.target.files[0]) {
//       setNewImageFile(e.target.files[0]);
//       setNewImageURL("");
//     }
//   };

//   const handleAddImage = async () => {
//     const storage = getStorage();

//     try {
//       let imageUrl = newImageURL;

//       if (newImageFile) {
//         // Firebase Storage に画像をアップロード
//         const fileExtension = newImageFile.name.split(".").pop();
//         const storageReference = storageRef(
//           storage,
//           `images/${uuidv4()}.${fileExtension}`
//         );
//         await uploadBytes(storageReference, newImageFile);
//         imageUrl = await getDownloadURL(storageReference);
//       }

//       if (!imageUrl) {
//         alert(
//           "画像のURLを入力するか、画像ファイルをアップロードしてください。"
//         );
//         return;
//       }

//       // データの更新
//       const picUrlKey = Object.keys(data[0]).find(
//         (key) => key.toLowerCase() === "picurl"
//       );
//       const updatedData = [...data];
//       const item = { ...updatedData[selectedProductForEdit.rowIndex] };
//       const imageUrls = item[picUrlKey] ? item[picUrlKey].split("|") : [];
//       imageUrls.push(imageUrl);
//       item[picUrlKey] = imageUrls.join("|");
//       updatedData[selectedProductForEdit.rowIndex] = item;
//       setData(updatedData);

//       // previewImagesを更新
//       const updatedPreviewImages = previewImages.map((product) => {
//         if (product.rowIndex === selectedProductForEdit.rowIndex) {
//           const images = [
//             ...product.images,
//             { url: imageUrl, imageIndex: product.images.length + 1 },
//           ];
//           return {
//             ...product,
//             images,
//           };
//         }
//         return product;
//       });
//       setPreviewImages(updatedPreviewImages);

//       setIsAddImageDialogOpen(false);
//       setNewImageFile(null);
//       setNewImageURL("");
//     } catch (error) {
//       console.error("画像の追加中にエラーが発生しました:", error);
//       alert("画像の追加に失敗しました。再度お試しください。");
//     }
//   };

//   // 商品削除の関数を修正
//   const handleDeleteProduct = (rowIndex) => {
//     // 削除する商品のデータを保存
//     const deletedProduct = {
//       data: data[rowIndex],
//       previewImage: previewImages.find((product) => product.rowIndex === rowIndex),
//       rowIndex,
//     };

//     // データから商品を削除
//     const updatedData = data.filter((_, index) => index !== rowIndex);

//     // インデックスを再計算
//     const recalculatedData = updatedData.map((item, index) => ({
//       ...item,
//       rowIndex: index,
//     }));

//     setData(recalculatedData);

//     // プレビュー画像から商品を削除
//     const updatedPreviewImages = previewImages.filter(
//       (product) => product.rowIndex !== rowIndex
//     );

//     // インデックスを再計算
//     const recalculatedPreviewImages = updatedPreviewImages.map((product, index) => ({
//       ...product,
//       rowIndex: index,
//     }));

//     setPreviewImages(recalculatedPreviewImages);

//     // 削除された商品の情報を保存
//     setDeletedProducts((prevDeletedProducts) => [
//       ...prevDeletedProducts,
//       deletedProduct,
//     ]);

//     // 最後に削除された商品を保存
//     setLastDeletedProduct(deletedProduct);

//     // スナックバーで通知
//     localShowSnackbar("商品が削除されました。");
//   };

//   // 削除された商品を復元する関数を修正
//   const handleRestoreDeletedProduct = (deletedProductIndex) => {
//     const deletedProduct = deletedProducts[deletedProductIndex];
//     if (!deletedProduct) return;

//     const { data: deletedData, previewImage: deletedPreviewImage, rowIndex } =
//       deletedProduct;

//     // データを復元
//     const updatedData = [...data];
//     updatedData.splice(rowIndex, 0, deletedData);

//     // インデックスを再計算
//     const recalculatedData = updatedData.map((item, index) => ({
//       ...item,
//       rowIndex: index,
//     }));

//     setData(recalculatedData);

//     // プレビュー画像を復元
//     const updatedPreviewImages = [...previewImages];
//     updatedPreviewImages.splice(rowIndex, 0, deletedPreviewImage);

//     // インデックスを再計算
//     const recalculatedPreviewImages = updatedPreviewImages.map((product, index) => ({
//       ...product,
//       rowIndex: index,
//     }));

//     setPreviewImages(recalculatedPreviewImages);

//     // 削除された商品のリストから削除
//     const updatedDeletedProducts = deletedProducts.filter(
//       (_, index) => index !== deletedProductIndex
//     );
//     setDeletedProducts(updatedDeletedProducts);

//     // スナックバーで通知
//     localShowSnackbar("商品を復元しました。");
//   };

//   // 最後に削除された商品を元に戻す関数を修正
//   const handleUndoDeleteProduct = () => {
//     if (!lastDeletedProduct) return;

//     const { data: deletedData, previewImage: deletedPreviewImage, rowIndex } =
//       lastDeletedProduct;

//     // データを復元
//     const updatedData = [...data];
//     updatedData.splice(rowIndex, 0, deletedData);

//     // インデックスを再計算
//     const recalculatedData = updatedData.map((item, index) => ({
//       ...item,
//       rowIndex: index,
//     }));

//     setData(recalculatedData);

//     // プレビュー画像を復元
//     const updatedPreviewImages = [...previewImages];
//     updatedPreviewImages.splice(rowIndex, 0, deletedPreviewImage);

//     // インデックスを再計算
//     const recalculatedPreviewImages = updatedPreviewImages.map((product, index) => ({
//       ...product,
//       rowIndex: index,
//     }));

//     setPreviewImages(recalculatedPreviewImages);

//     // 削除された商品のリストから削除
//     const updatedDeletedProducts = deletedProducts.filter(
//       (product) => product !== lastDeletedProduct
//     );
//     setDeletedProducts(updatedDeletedProducts);

//     // 'lastDeletedProduct' をクリア
//     setLastDeletedProduct(null);

//     // スナックバーで通知
//     localShowSnackbar("削除を元に戻しました。");
//   };

//   // ドラッグ終了時のハンドラー
//   const handleDragEnd = (result, product) => {
//     if (!result.destination) {
//       return;
//     }

//     const { source, destination } = result;

//     const productIndex = previewImages.findIndex(
//       (p) => p.rowIndex === product.rowIndex
//     );
//     if (productIndex === -1) {
//       return;
//     }

//     const images = Array.from(previewImages[productIndex].images);
//     const [movedImage] = images.splice(source.index, 1);
//     images.splice(destination.index, 0, movedImage);

//     // previewImagesを更新
//     const updatedPreviewImages = [...previewImages];
//     updatedPreviewImages[productIndex] = {
//       ...previewImages[productIndex],
//       images,
//     };
//     setPreviewImages(updatedPreviewImages);

//     // dataを更新
//     const picUrlKey = Object.keys(data[0]).find(
//       (key) => key.toLowerCase() === "picurl"
//     );
//     const updatedData = [...data];
//     const item = { ...updatedData[product.rowIndex] };
//     item[picUrlKey] = images.map((img) => img.url).join("|");
//     updatedData[product.rowIndex] = item;
//     setData(updatedData);
//   };

//   // 画像解析を行う関数
//   const handleImageAnalysis = async () => {
//     setIsProcessing(true);
//     setError("");

//     try {
//       const updatedData = [...data];
//       const picUrlKey = Object.keys(data[0]).find(
//         (key) => key.toLowerCase() === "picurl"
//       );

//       if (!picUrlKey) {
//         throw new Error("PicURLカラムが見つかりません。");
//       }

//       if (selectedImages.size === 0) {
//         throw new Error("解析する画像を選択してください。");
//       }

//       for (const selectedImageUrl of selectedImages) {
//         // 対応する商品（行）を見つける
//         const itemIndex = data.findIndex((item) => {
//           const picUrl = item[picUrlKey];
//           const imageUrls = picUrl ? picUrl.split("|") : [];
//           return imageUrls.includes(selectedImageUrl);
//         });

//         if (itemIndex !== -1) {
//           const item = updatedData[itemIndex];
//           // 画像を解析して説明を取得
//           // const description = await performImageAnalysis(selectedImageUrl, apiKey);
//           const description = await ImageAnalysis(selectedImageUrl, apiKey);
//           // 既存の説明がある場合は追記、それ以外は新規作成
//           if (item["jp_image_description"]) {
//             item["jp_image_description"] += `\n\n${description}`;
//           } else {
//             item["jp_image_description"] = description;
//           }
//         }
//       }

//       setData(updatedData);
//       showSnackbar("選択された画像の解析が完了し、データが更新されました。");
//     } catch (error) {
//       setError(`画像解析中にエラーが発生しました: ${error.message}`);
//       console.error(error);
//     } finally {
//       setIsProcessing(false);
//       setOpenPreviewDialog(false);
//     }
//   };

//   // 画像を削除する関数
//   const handleDeleteImage = (imageUrl, productRowIndex) => {
//     try {
//       const picUrlKey = Object.keys(data[0]).find(
//         (key) => key.toLowerCase() === "picurl"
//       );

//       const updatedData = [...data];
//       const item = { ...updatedData[productRowIndex] };
//       const imageUrls = item[picUrlKey] ? item[picUrlKey].split("|") : [];

//       // 削除する画像のインデックスを取得
//       const imageIndex = imageUrls.findIndex((url) => url === imageUrl);

//       // 画像を削除
//       const newImageUrls = imageUrls.filter((url) => url !== imageUrl);
//       item[picUrlKey] = newImageUrls.join("|");
//       updatedData[productRowIndex] = item;
//       setData(updatedData);

//       // プレビュー画像を更新
//       const updatedPreviewImages = previewImages.map((product) => {
//         if (product.rowIndex === productRowIndex) {
//           const images = product.images.filter((img) => img.url !== imageUrl);
//           return {
//             ...product,
//             images,
//           };
//         }
//         return product;
//       });
//       setPreviewImages(updatedPreviewImages);

//       // 削除された画像の情報を保存
//       setLastDeletedImage({
//         imageUrl,
//         productRowIndex,
//         imageIndex,
//       });

//       // スナックバーで「元に戻す」ボタンを表示
//       localShowSnackbar("画像が削除されました。");
//     } catch (error) {
//       console.error("画像の削除中にエラーが発生しました:", error);
//       setError(`画像の削除中にエラーが発生しました: ${error.message}`);
//     }
//   };

//   // 画像削除を元に戻す関数
//   const handleUndoDeleteImage = () => {
//     if (!lastDeletedImage) return;

//     const { imageUrl, productRowIndex, imageIndex } = lastDeletedImage;

//     try {
//       const picUrlKey = Object.keys(data[0]).find(
//         (key) => key.toLowerCase() === "picurl"
//       );

//       // データを更新して画像を元の位置に挿入
//       const updatedData = [...data];
//       const item = { ...updatedData[productRowIndex] };
//       const imageUrls = item[picUrlKey] ? item[picUrlKey].split("|") : [];

//       // 元の位置に画像を挿入
//       imageUrls.splice(imageIndex, 0, imageUrl);
//       item[picUrlKey] = imageUrls.join("|");
//       updatedData[productRowIndex] = item;
//       setData(updatedData);

//       // プレビュー画像を更新
//       const updatedPreviewImages = previewImages.map((product) => {
//         if (product.rowIndex === productRowIndex) {
//           const images = [...product.images];
//           images.splice(imageIndex, 0, { url: imageUrl, imageIndex });
//           return {
//             ...product,
//             images,
//           };
//         }
//         return product;
//       });
//       setPreviewImages(updatedPreviewImages);

//       // 削除された画像の情報をクリア
//       setLastDeletedImage(null);

//       // スナックバーで復元が完了したことを通知
//       localShowSnackbar("削除を元に戻しました。");
//     } catch (error) {
//       console.error("画像の復元中にエラーが発生しました:", error);
//       setError(`画像の復元中にエラーが発生しました: ${error.message}`);
//     }
//   };

//   // Snackbarを表示する関数
//   const handleSnackbarClose = () => {
//     setSnackbarState({ ...snackbarState, open: false });
//   };

//   // プロパティとして渡された showSnackbar と区別するために関数名を変更
//   const localShowSnackbar = (message) => {
//     setSnackbarState({
//       open: true,
//       message,
//     });
//   };

//   return (
//     <>
//       {/* 画像解析ボタンを追加 */}
//       <Button
//         variant="contained"
//         color="primary"
//         startIcon={<DescriptionIcon />}
//         onClick={() => {
//           setPreviewDialogMode("analyze");
//           setOpenPreviewDialog(true);
//         }}
//         disabled={isProcessing}
//         sx={{ mt: 2 }}
//       >
//         画像解析
//       </Button>

//       {/* 削除された商品一覧を開くボタンを追加 */}
//       <Button
//         variant="outlined"
//         color="secondary"
//         onClick={() => setOpenDeletedProductsDialog(true)}
//         sx={{ mt: 2, ml: 2 }}
//       >
//         削除された商品一覧
//       </Button>

//       {/* プレビューダイアログ */}
//       <Dialog
//         open={openPreviewDialog}
//         onClose={() => setOpenPreviewDialog(false)}
//         maxWidth="xl"
//         fullWidth
//       >
//         <DialogTitle>
//           {previewDialogMode === "edit" ? "画像編集・削除" : "画像解析"}
//           <IconButton
//             aria-label="close"
//             onClick={() => setOpenPreviewDialog(false)}
//             sx={{
//               position: "absolute",
//               right: 8,
//               top: 8,
//               color: (theme) => theme.palette.grey[500],
//             }}
//           >
//             <CloseIcon />
//           </IconButton>
//         </DialogTitle>
//         <DialogContent>
//           {/* 表示件数の選択 */}
//           <Box sx={{ mb: 2, display: "flex", alignItems: "center" }}>
//             <Typography variant="body2" sx={{ mr: 2 }}>
//               表示件数:
//             </Typography>
//             <FormControl variant="outlined" size="small">
//               <Select
//                 value={itemsPerPage}
//                 onChange={handleItemsPerPageChange}
//                 sx={{ minWidth: 120 }}
//               >
//                 <MenuItem value={50}>50</MenuItem>
//                 <MenuItem value={100}>100</MenuItem>
//                 <MenuItem value={150}>150</MenuItem>
//                 <MenuItem value={200}>200</MenuItem>
//                 <MenuItem value="ALL">ALL</MenuItem>
//               </Select>
//             </FormControl>
//           </Box>
//           <Typography variant="body2" sx={{ mb: 2 }}>
//             {previewDialogMode === "edit"
//               ? "削除したい画像の「×」をクリックしてください。画像をドラッグ＆ドロップで並び替えることもできます。"
//               : "解析したい画像をクリックして選択してください。"}
//           </Typography>
//           <Box
//             ref={scrollableContainerRef} // ここでrefを設定
//             sx={{ maxHeight: "70vh", overflowY: "auto", position: "relative" }}
//           >
//             {displayedPreviewImages.map((product, index) => (
//               <Box key={index} sx={{ mb: 4 }}>
//                 <Typography variant="h6">{product.title}</Typography>

//                 {/* 商品を削除するボタンを修正 */}
//                 {previewDialogMode === "edit" && (
//                   <Button
//                     variant="outlined"
//                     color="error"
//                     onClick={() => handleDeleteProduct(product.rowIndex)}
//                     sx={{ mb: 2 }}
//                   >
//                     商品を削除
//                   </Button>
//                 )}

//                 <DragDropContext
//                   onDragEnd={(result) => handleDragEnd(result, product)}
//                 >
//                   <Droppable
//                     droppableId={`droppable-${product.rowIndex}`}
//                     direction="horizontal"
//                   >
//                     {(provided) => (
//                       <Grid
//                         container
//                         spacing={2}
//                         ref={provided.innerRef}
//                         {...provided.droppableProps}
//                       >
//                         {product.images.map((image, idx) => (
//                           <Draggable
//                             key={image.url}
//                             draggableId={image.url}
//                             index={idx}
//                             isDragDisabled={previewDialogMode === "analyze"}
//                           >
//                             {(provided) => (
//                               <Grid
//                                 item
//                                 xs={3}
//                                 sm={2}
//                                 md={1.5}
//                                 lg={1}
//                                 ref={provided.innerRef}
//                                 {...provided.draggableProps}
//                                 {...provided.dragHandleProps}
//                               >
//                                 <Paper
//                                   elevation={1}
//                                   sx={{
//                                     p: 1,
//                                     position: "relative",
//                                     opacity: selectedImages.has(image.url)
//                                       ? 0.4
//                                       : 1,
//                                     border: selectedImages.has(image.url)
//                                       ? "2px solid blue"
//                                       : "none",
//                                   }}
//                                   onClick={() => toggleImageSelection(image.url)}
//                                 >
//                                   {previewDialogMode === "edit" && (
//                                     <IconButton
//                                       aria-label="delete"
//                                       onClick={(e) => {
//                                         e.stopPropagation();
//                                         handleDeleteImage(
//                                           image.url,
//                                           product.rowIndex
//                                         );
//                                       }}
//                                       sx={{
//                                         position: "absolute",
//                                         top: 0,
//                                         right: 0,
//                                         color: (theme) =>
//                                           theme.palette.error.main,
//                                       }}
//                                     >
//                                       <CloseIcon />
//                                     </IconButton>
//                                   )}
//                                   <img
//                                     src={image.url}
//                                     alt={`Row ${product.rowIndex + 1} Image ${
//                                       image.imageIndex
//                                     }`}
//                                     style={{ width: "100%", height: "auto" }}
//                                     loading="lazy"
//                                   />
//                                   <Typography variant="caption">
//                                     画像 {idx + 1}
//                                   </Typography>
//                                 </Paper>
//                               </Grid>
//                             )}
//                           </Draggable>
//                         ))}

//                         {provided.placeholder}

//                         {/* プラスアイコンのDraggableアイテム */}
//                         {previewDialogMode === "edit" && (
//                           <Draggable
//                             key={`add-image-${product.rowIndex}`}
//                             draggableId={`add-image-${product.rowIndex}`}
//                             index={product.images.length}
//                             isDragDisabled={true} // ドラッグ不可に設定
//                           >
//                             {(provided) => (
//                               <Grid
//                                 item
//                                 xs={3}
//                                 sm={2}
//                                 md={1.5}
//                                 lg={1}
//                                 ref={provided.innerRef}
//                                 {...provided.draggableProps}
//                               >
//                                 <Paper
//                                   elevation={1}
//                                   sx={{
//                                     p: 1,
//                                     display: "flex",
//                                     alignItems: "center",
//                                     justifyContent: "center",
//                                     height: "100%",
//                                     border: "2px dashed #1976d2",
//                                     color: "#1976d2",
//                                     cursor: "pointer",
//                                   }}
//                                   onClick={() => handleAddImageClick(product)}
//                                 >
//                                   <Typography variant="h4">+</Typography>
//                                 </Paper>
//                               </Grid>
//                             )}
//                           </Draggable>
//                         )}
//                       </Grid>
//                     )}
//                   </Droppable>
//                 </DragDropContext>
//               </Box>
//             ))}
//           </Box>
//           {/* スクロールトップボタン */}
//           {showScrollTopButton && (
//             <IconButton
//               onClick={() => {
//                 if (scrollableContainerRef.current) {
//                   scrollableContainerRef.current.scrollTo({
//                     top: 0,
//                     behavior: "smooth",
//                   });
//                 }
//               }}
//               sx={{
//                 position: "fixed",
//                 bottom: 100,
//                 right: 40,
//                 zIndex: 1000,
//                 backgroundColor: theme.palette.background.paper,
//                 "&:hover": {
//                   backgroundColor: theme.palette.grey[300],
//                 },
//               }}
//             >
//               <KeyboardArrowUpIcon />
//             </IconButton>
//           )}
//           {/* ページネーション */}
//           {totalPages > 1 && (
//             <Box sx={{ mt: 2, display: "flex", justifyContent: "center" }}>
//               <Pagination
//                 count={totalPages}
//                 page={currentPage}
//                 onChange={handlePageChange}
//                 color="primary"
//               />
//             </Box>
//           )}
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => setOpenPreviewDialog(false)}>閉じる</Button>
//           {previewDialogMode === "edit" ? (
//             <Button
//               onClick={() => {
//                 setOpenConfirmDialog(true);
//               }}
//               color="primary"
//               variant="contained"
//               disabled={selectedImages.size === 0}
//             >
//               削除実行
//             </Button>
//           ) : (
//             <Button
//               onClick={handleImageAnalysis}
//               color="primary"
//               variant="contained"
//               disabled={selectedImages.size === 0}
//             >
//               解析実行
//             </Button>
//           )}
//         </DialogActions>
//       </Dialog>

//       {/* 画像追加ダイアログ */}
//       <Dialog
//         open={isAddImageDialogOpen}
//         onClose={() => setIsAddImageDialogOpen(false)}
//       >
//         <DialogTitle>画像を追加</DialogTitle>
//         <DialogContent>
//           <Typography variant="body2" sx={{ mb: 2 }}>
//             画像のURLを入力するか、画像ファイルをアップロードしてください。
//           </Typography>
//           <TextField
//             label="画像URL"
//             value={newImageURL}
//             onChange={(e) => setNewImageURL(e.target.value)}
//             fullWidth
//             sx={{ mb: 2 }}
//           />
//           <Typography variant="body2" sx={{ mb: 1 }}>
//             または
//           </Typography>
//           <Button variant="contained" component="label">
//             画像を選択
//             <input
//               type="file"
//               accept="image/*"
//               hidden
//               onChange={handleImageFileChange}
//             />
//           </Button>
//           {newImageFile && (
//             <Typography variant="body2" sx={{ mt: 1 }}>
//               選択されたファイル: {newImageFile.name}
//             </Typography>
//           )}
//         </DialogContent>
//         <DialogActions>
//           <Button
//             onClick={() => {
//               setIsAddImageDialogOpen(false);
//               setNewImageFile(null);
//               setNewImageURL("");
//             }}
//           >
//             キャンセル
//           </Button>
//           <Button onClick={handleAddImage} color="primary" variant="contained">
//             追加
//           </Button>
//         </DialogActions>
//       </Dialog>

//       {/* 削除確認ダイアログを追加 */}
//       <Dialog
//         open={openConfirmDialog}
//         onClose={() => setOpenConfirmDialog(false)}
//       >
//         <DialogTitle>確認</DialogTitle>
//         <DialogContent>
//           <DialogContentText>
//             選択された画像を削除しますか？
//           </DialogContentText>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => setOpenConfirmDialog(false)}>キャンセル</Button>
//           <Button onClick={handleDelete} color="primary" variant="contained">
//             削除
//           </Button>
//         </DialogActions>
//       </Dialog>

//       {/* 削除された商品一覧ダイアログ */}
//       <Dialog
//         open={openDeletedProductsDialog}
//         onClose={() => setOpenDeletedProductsDialog(false)}
//         maxWidth="md"
//         fullWidth
//       >
//         <DialogTitle>削除された商品一覧</DialogTitle>
//         <DialogContent>
//           {deletedProducts.length === 0 ? (
//             <Typography>削除された商品はありません。</Typography>
//           ) : (
//             deletedProducts.map((deletedProduct, index) => (
//               <Box key={index} sx={{ mb: 4 }}>
//                 <Typography variant="h6">
//                   {deletedProduct.data.title || `商品 ${deletedProduct.rowIndex + 1}`}
//                 </Typography>
//                 {/* 商品の画像を表示 */}
//                 <Grid container spacing={2}>
//                   {deletedProduct.previewImage.images.map((image, idx) => (
//                     <Grid item xs={3} sm={2} md={1.5} lg={1} key={idx}>
//                       <Paper elevation={1} sx={{ p: 1 }}>
//                         <img
//                           src={image.url}
//                           alt={`Deleted Product ${index + 1} Image ${image.imageIndex}`}
//                           style={{ width: "100%", height: "auto" }}
//                           loading="lazy"
//                         />
//                         <Typography variant="caption">
//                           画像 {idx + 1}
//                         </Typography>
//                       </Paper>
//                     </Grid>
//                   ))}
//                 </Grid>
//                 <Button
//                   variant="contained"
//                   color="primary"
//                   onClick={() => handleRestoreDeletedProduct(index)}
//                   sx={{ mt: 1 }}
//                 >
//                   復元
//                 </Button>
//               </Box>
//             ))
//           )}
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => setOpenDeletedProductsDialog(false)}>閉じる</Button>
//         </DialogActions>
//       </Dialog>

//       {/* Snackbar */}
//       <Snackbar
//         open={snackbarState.open}
//         autoHideDuration={6000}
//         onClose={handleSnackbarClose}
//         message={snackbarState.message}
//         action={
//           (lastDeletedImage || lastDeletedProduct) && (
//             <Button
//               color="secondary"
//               size="small"
//               onClick={
//                 lastDeletedImage ? handleUndoDeleteImage : handleUndoDeleteProduct
//               }
//             >
//               元に戻す
//             </Button>
//           )
//         }
//       />
//     </>
//   );
// }

// export default ImageOperations;









// // ImageOperations.js

// import React, { useState, useCallback, useEffect, useRef } from "react";
// import {
//   Button,
//   Typography,
//   Box,
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogTitle,
//   Paper,
//   Grid,
//   IconButton,
//   DialogContentText,
//   TextField,
//   MenuItem,
//   Select,
//   FormControl,
//   Pagination,
//   CircularProgress,
//   Tooltip,
//   Snackbar,
// } from "@mui/material";
// import { useTheme } from "@mui/material/styles";
// import CloseIcon from "@mui/icons-material/Close";
// import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
// import DescriptionIcon from "@mui/icons-material/Description";
// import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
// import {
//   getStorage,
//   ref as storageRef,
//   uploadBytes,
//   getDownloadURL,
// } from "firebase/storage";
// import { v4 as uuidv4 } from "uuid";
// import axios from "axios";
// import performImageAnalysis from "./ImageAnalysis"; // 画像解析関数をインポート
// import ImageAnalysis from "./ImageAnalysis";

// function ImageOperations({
//   data,
//   setData,
//   startRow,
//   endRow,
//   calculateMaxEndRow,
//   isProcessing,
//   setIsProcessing,
//   setError,
//   showSnackbar, // プロパティとして渡される
//   openPreviewDialog,
//   setOpenPreviewDialog,
//   apiKey, // OpenAIのAPIキー
// }) {
//   const [previewImages, setPreviewImages] = useState([]);
//   const [displayedPreviewImages, setDisplayedPreviewImages] = useState([]);
//   const [selectedImages, setSelectedImages] = useState(new Set());
//   const [selectedProductForEdit, setSelectedProductForEdit] = useState(null);
//   const [isAddImageDialogOpen, setIsAddImageDialogOpen] = useState(false);
//   const [newImageFile, setNewImageFile] = useState(null);
//   const [newImageURL, setNewImageURL] = useState("");
//   const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
//   const [openProductDeleteConfirmDialog, setOpenProductDeleteConfirmDialog] =
//     useState(false);
//   const [productToDelete, setProductToDelete] = useState(null);

//   const [itemsPerPage, setItemsPerPage] = useState(50); // 1ページあたりの表示件数
//   const [currentPage, setCurrentPage] = useState(1); // 現在のページ番号

//   const [previewDialogMode, setPreviewDialogMode] = useState("edit"); // 'edit' または 'analyze'
//   const [isAnalyzing, setIsAnalyzing] = useState(false);

//   const theme = useTheme();

//   // スクロール可能なコンテナのrefを追加
//   const scrollableContainerRef = useRef(null);
//   const [showScrollTopButton, setShowScrollTopButton] = useState(false); // スクロールトップボタンの表示制御

//   // 追加: 削除された画像の情報を保存する状態
//   const [lastDeletedImage, setLastDeletedImage] = useState(null);
//   const [snackbarState, setSnackbarState] = useState({
//     open: false,
//     message: "",
//   });

//   const handleOpenPreviewDialog = useCallback(() => {
//     try {
//       if (!startRow || !endRow || isNaN(startRow) || isNaN(endRow)) {
//         throw new Error("Start RowとEnd Rowは有効な数字である必要があります。");
//       }

//       const picUrlKey = Object.keys(data[0]).find(
//         (key) => key.toLowerCase() === "picurl"
//       );
//       const titleKey = Object.keys(data[0]).find(
//         (key) => key.toLowerCase() === "title"
//       );

//       if (!picUrlKey) {
//         throw new Error("PicURLカラムが見つかりません。");
//       }

//       const actualStartRow = parseInt(startRow) - 1; // インデックスは0から開始
//       const maxEndRow = calculateMaxEndRow();
//       const actualEndRow = Math.min(parseInt(endRow) - 1, maxEndRow - 1);

//       if (actualStartRow < 0 || actualEndRow < actualStartRow) {
//         throw new Error("Start RowとEnd Rowが有効ではありません。");
//       }

//       let previewItems = [];

//       // すべての画像を取得
//       for (let i = actualStartRow; i <= actualEndRow; i++) {
//         const item = data[i];
//         const picUrl = item[picUrlKey];
//         const imageUrls = picUrl ? picUrl.split("|") : [];
//         const title = item[titleKey] || `商品 ${i + 1}`;
//         const images = imageUrls.map((url, idx) => ({
//           url,
//           imageIndex: idx + 1,
//         }));
//         if (images.length > 0) {
//           previewItems.push({
//             title,
//             rowIndex: i,
//             images,
//           });
//         }
//       }

//       setPreviewImages(previewItems);
//       setSelectedImages(new Set()); // すべて未選択
//       setCurrentPage(1); // ページをリセット
//     } catch (err) {
//       const errorMessage = `プレビューの準備中にエラーが発生しました: ${err.message}`;
//       setError(errorMessage);
//       console.error(errorMessage, err);
//     }
//   }, [data, startRow, endRow, calculateMaxEndRow, setError]);

//   // openPreviewDialogがtrueになったときにプレビューを準備
//   useEffect(() => {
//     if (openPreviewDialog) {
//       handleOpenPreviewDialog();
//     } else {
//       // ダイアログが閉じられたときにプレビュー画像をクリア
//       setPreviewImages([]);
//       setSelectedImages(new Set());
//     }
//   }, [openPreviewDialog, handleOpenPreviewDialog]);

//   // ページネーションの処理
//   useEffect(() => {
//     if (itemsPerPage === "ALL") {
//       setDisplayedPreviewImages(previewImages);
//     } else {
//       const startIndex = (currentPage - 1) * itemsPerPage;
//       const endIndex = startIndex + itemsPerPage;
//       setDisplayedPreviewImages(previewImages.slice(startIndex, endIndex));
//     }
//   }, [previewImages, currentPage, itemsPerPage]);

//   // currentPageが変更されたときにスクロール位置をトップに戻す
//   useEffect(() => {
//     if (scrollableContainerRef.current) {
//       scrollableContainerRef.current.scrollTop = 0;
//     }
//   }, [currentPage]);

//   // スクロール位置に応じてスクロールトップボタンの表示を制御
//   useEffect(() => {
//     const handleScroll = () => {
//       if (scrollableContainerRef.current) {
//         const scrollTop = scrollableContainerRef.current.scrollTop;
//         setShowScrollTopButton(scrollTop > 200);
//       }
//     };

//     const container = scrollableContainerRef.current;
//     if (container) {
//       container.addEventListener("scroll", handleScroll);
//       return () => container.removeEventListener("scroll", handleScroll);
//     }
//   }, [scrollableContainerRef]);

//   // Snackbarの自動非表示に合わせてlastDeletedImageをクリア
//   useEffect(() => {
//     if (snackbarState.open && lastDeletedImage) {
//       const timer = setTimeout(() => {
//         setLastDeletedImage(null);
//       }, 6000); // SnackbarのautoHideDurationと同じ

//       return () => clearTimeout(timer);
//     }
//   }, [snackbarState.open, lastDeletedImage]);

//   const totalPages =
//     itemsPerPage === "ALL" ? 1 : Math.ceil(previewImages.length / itemsPerPage);

//   const handleItemsPerPageChange = (event) => {
//     const value =
//       event.target.value === "ALL" ? "ALL" : parseInt(event.target.value);
//     setItemsPerPage(value);
//     setCurrentPage(1); // ページをリセット
//   };

//   const handlePageChange = (event, value) => {
//     setCurrentPage(value);
//   };

//   const toggleImageSelection = (url) => {
//     setSelectedImages((prevSelected) => {
//       const newSelected = new Set(prevSelected);
//       if (newSelected.has(url)) {
//         newSelected.delete(url);
//       } else {
//         newSelected.add(url);
//       }
//       return newSelected;
//     });
//   };

//   const handleDelete = useCallback(async () => {
//     setIsProcessing(true);
//     setError("");

//     try {
//       if (!startRow || !endRow || isNaN(startRow) || isNaN(endRow)) {
//         throw new Error("Start RowとEnd Rowは有効な数字である必要があります。");
//       }

//       const picUrlKey = Object.keys(data[0]).find(
//         (key) => key.toLowerCase() === "picurl"
//       );

//       if (!picUrlKey) {
//         throw new Error("PicURLカラムが見つかりません。");
//       }

//       const actualStartRow = parseInt(startRow) - 1; // インデックスは0から開始
//       const maxEndRow = calculateMaxEndRow();
//       const actualEndRow = Math.min(parseInt(endRow) - 1, maxEndRow - 1);

//       if (actualStartRow < 0 || actualEndRow < actualStartRow) {
//         throw new Error("Start RowとEnd Rowが有効ではありません。");
//       }

//       // データの更新
//       const updatedData = [...data];
//       for (let i = actualStartRow; i <= actualEndRow; i++) {
//         const item = { ...updatedData[i] };
//         const originalImages = item[picUrlKey]
//           ? item[picUrlKey].split("|")
//           : [];
//         const newImages = originalImages.filter(
//           (url) => !selectedImages.has(url)
//         );
//         item[picUrlKey] = newImages.join("|");
//         updatedData[i] = item;
//       }

//       setData(updatedData);

//       // プレビュー画像を更新
//       const updatedPreviewImages = previewImages.map((product) => {
//         const item = updatedData[product.rowIndex];
//         const picUrl = item[picUrlKey];
//         const imageUrls = picUrl ? picUrl.split("|") : [];
//         const images = imageUrls.map((url, idx) => ({
//           url,
//           imageIndex: idx + 1,
//         }));
//         return {
//           ...product,
//           images,
//         };
//       });
//       setPreviewImages(updatedPreviewImages);

//       const finalMessage = `画像の削除が完了しました。`;
//       showSnackbar(finalMessage);
//     } catch (err) {
//       const errorMessage = `画像の削除に失敗しました: ${err.message}`;
//       setError(errorMessage);
//       showSnackbar(errorMessage);
//       console.error(errorMessage, err);
//     } finally {
//       setIsProcessing(false);
//       setOpenConfirmDialog(false);
//       setOpenPreviewDialog(false);
//     }
//   }, [
//     data,
//     startRow,
//     endRow,
//     selectedImages,
//     calculateMaxEndRow,
//     setData,
//     previewImages,
//     setIsProcessing,
//     setError,
//     showSnackbar,
//     setOpenPreviewDialog,
//   ]);

//   const handleAddImageClick = (product) => {
//     setSelectedProductForEdit(product);
//     setIsAddImageDialogOpen(true);
//   };

//   const handleImageFileChange = (e) => {
//     if (e.target.files && e.target.files[0]) {
//       setNewImageFile(e.target.files[0]);
//       setNewImageURL("");
//     }
//   };

//   const handleAddImage = async () => {
//     const storage = getStorage();

//     try {
//       let imageUrl = newImageURL;

//       if (newImageFile) {
//         // Firebase Storage に画像をアップロード
//         const fileExtension = newImageFile.name.split(".").pop();
//         const storageReference = storageRef(
//           storage,
//           `images/${uuidv4()}.${fileExtension}`
//         );
//         await uploadBytes(storageReference, newImageFile);
//         imageUrl = await getDownloadURL(storageReference);
//       }

//       if (!imageUrl) {
//         alert(
//           "画像のURLを入力するか、画像ファイルをアップロードしてください。"
//         );
//         return;
//       }

//       // データの更新
//       const picUrlKey = Object.keys(data[0]).find(
//         (key) => key.toLowerCase() === "picurl"
//       );
//       const updatedData = [...data];
//       const item = { ...updatedData[selectedProductForEdit.rowIndex] };
//       const imageUrls = item[picUrlKey] ? item[picUrlKey].split("|") : [];
//       imageUrls.push(imageUrl);
//       item[picUrlKey] = imageUrls.join("|");
//       updatedData[selectedProductForEdit.rowIndex] = item;
//       setData(updatedData);

//       // previewImagesを更新
//       const updatedPreviewImages = previewImages.map((product) => {
//         if (product.rowIndex === selectedProductForEdit.rowIndex) {
//           const images = [
//             ...product.images,
//             { url: imageUrl, imageIndex: product.images.length + 1 },
//           ];
//           return {
//             ...product,
//             images,
//           };
//         }
//         return product;
//       });
//       setPreviewImages(updatedPreviewImages);

//       setIsAddImageDialogOpen(false);
//       setNewImageFile(null);
//       setNewImageURL("");
//     } catch (error) {
//       console.error("画像の追加中にエラーが発生しました:", error);
//       alert("画像の追加に失敗しました。再度お試しください。");
//     }
//   };

//   // 商品削除の関数
//   const handleDeleteProduct = (rowIndex) => {
//     setProductToDelete(rowIndex);
//     setOpenProductDeleteConfirmDialog(true);
//   };

//   // 削除を確定する関数
//   const confirmDeleteProduct = (rowIndex) => {
//     // データから商品を削除
//     const updatedData = data.filter((_, index) => index !== rowIndex);
//     setData(updatedData);

//     // プレビュー画像から商品を削除
//     const updatedPreviewImages = previewImages.filter(
//       (product) => product.rowIndex !== rowIndex
//     );

//     // インデックスを再計算
//     const recalculatedPreviewImages = updatedPreviewImages.map((product) => {
//       return {
//         ...product,
//         rowIndex:
//           product.rowIndex > rowIndex ? product.rowIndex - 1 : product.rowIndex,
//       };
//     });

//     setPreviewImages(recalculatedPreviewImages);
//   };

//   // ドラッグ終了時のハンドラー
//   const handleDragEnd = (result, product) => {
//     if (!result.destination) {
//       return;
//     }

//     const { source, destination } = result;

//     const productIndex = previewImages.findIndex(
//       (p) => p.rowIndex === product.rowIndex
//     );
//     if (productIndex === -1) {
//       return;
//     }

//     const images = Array.from(previewImages[productIndex].images);
//     const [movedImage] = images.splice(source.index, 1);
//     images.splice(destination.index, 0, movedImage);

//     // previewImagesを更新
//     const updatedPreviewImages = [...previewImages];
//     updatedPreviewImages[productIndex] = {
//       ...previewImages[productIndex],
//       images,
//     };
//     setPreviewImages(updatedPreviewImages);

//     // dataを更新
//     const picUrlKey = Object.keys(data[0]).find(
//       (key) => key.toLowerCase() === "picurl"
//     );
//     const updatedData = [...data];
//     const item = { ...updatedData[product.rowIndex] };
//     item[picUrlKey] = images.map((img) => img.url).join("|");
//     updatedData[product.rowIndex] = item;
//     setData(updatedData);
//   };

//   // 画像解析を行う関数
//   const handleImageAnalysis = async () => {
//     setIsProcessing(true);
//     setError("");

//     try {
//       const updatedData = [...data];
//       const picUrlKey = Object.keys(data[0]).find(
//         (key) => key.toLowerCase() === "picurl"
//       );

//       if (!picUrlKey) {
//         throw new Error("PicURLカラムが見つかりません。");
//       }

//       if (selectedImages.size === 0) {
//         throw new Error("解析する画像を選択してください。");
//       }

//       for (const selectedImageUrl of selectedImages) {
//         // 対応する商品（行）を見つける
//         const itemIndex = data.findIndex((item) => {
//           const picUrl = item[picUrlKey];
//           const imageUrls = picUrl ? picUrl.split("|") : [];
//           return imageUrls.includes(selectedImageUrl);
//         });

//         if (itemIndex !== -1) {
//           const item = updatedData[itemIndex];
//           // 画像を解析して説明を取得
//           // const description = await performImageAnalysis(selectedImageUrl, apiKey);
//           const description = await ImageAnalysis(selectedImageUrl, apiKey);
//           // 既存の説明がある場合は追記、それ以外は新規作成
//           if (item["jp_image_description"]) {
//             item["jp_image_description"] += `\n\n${description}`;
//           } else {
//             item["jp_image_description"] = description;
//           }
//         }
//       }

//       setData(updatedData);
//       showSnackbar("選択された画像の解析が完了し、データが更新されました。");
//     } catch (error) {
//       setError(`画像解析中にエラーが発生しました: ${error.message}`);
//       console.error(error);
//     } finally {
//       setIsProcessing(false);
//       setOpenPreviewDialog(false);
//     }
//   };

//   // 画像を削除する関数
//   const handleDeleteImage = (imageUrl, productRowIndex) => {
//     try {
//       const picUrlKey = Object.keys(data[0]).find(
//         (key) => key.toLowerCase() === "picurl"
//       );

//       const updatedData = [...data];
//       const item = { ...updatedData[productRowIndex] };
//       const imageUrls = item[picUrlKey] ? item[picUrlKey].split("|") : [];

//       // 削除する画像のインデックスを取得
//       const imageIndex = imageUrls.findIndex((url) => url === imageUrl);

//       // 画像を削除
//       const newImageUrls = imageUrls.filter((url) => url !== imageUrl);
//       item[picUrlKey] = newImageUrls.join("|");
//       updatedData[productRowIndex] = item;
//       setData(updatedData);

//       // プレビュー画像を更新
//       const updatedPreviewImages = previewImages.map((product) => {
//         if (product.rowIndex === productRowIndex) {
//           const images = product.images.filter((img) => img.url !== imageUrl);
//           return {
//             ...product,
//             images,
//           };
//         }
//         return product;
//       });
//       setPreviewImages(updatedPreviewImages);

//       // 削除された画像の情報を保存
//       setLastDeletedImage({
//         imageUrl,
//         productRowIndex,
//         imageIndex,
//       });

//       // スナックバーで「元に戻す」ボタンを表示
//       localShowSnackbar("画像が削除されました。");
//     } catch (error) {
//       console.error("画像の削除中にエラーが発生しました:", error);
//       setError(`画像の削除中にエラーが発生しました: ${error.message}`);
//     }
//   };

//   // 画像削除を元に戻す関数
//   const handleUndoDeleteImage = () => {
//     if (!lastDeletedImage) return;

//     const { imageUrl, productRowIndex, imageIndex } = lastDeletedImage;

//     try {
//       const picUrlKey = Object.keys(data[0]).find(
//         (key) => key.toLowerCase() === "picurl"
//       );

//       // データを更新して画像を元の位置に挿入
//       const updatedData = [...data];
//       const item = { ...updatedData[productRowIndex] };
//       const imageUrls = item[picUrlKey] ? item[picUrlKey].split("|") : [];

//       // 元の位置に画像を挿入
//       imageUrls.splice(imageIndex, 0, imageUrl);
//       item[picUrlKey] = imageUrls.join("|");
//       updatedData[productRowIndex] = item;
//       setData(updatedData);

//       // プレビュー画像を更新
//       const updatedPreviewImages = previewImages.map((product) => {
//         if (product.rowIndex === productRowIndex) {
//           const images = [...product.images];
//           images.splice(imageIndex, 0, { url: imageUrl, imageIndex });
//           return {
//             ...product,
//             images,
//           };
//         }
//         return product;
//       });
//       setPreviewImages(updatedPreviewImages);

//       // 削除された画像の情報をクリア
//       setLastDeletedImage(null);

//       // スナックバーで復元が完了したことを通知
//       localShowSnackbar("削除を元に戻しました。");
//     } catch (error) {
//       console.error("画像の復元中にエラーが発生しました:", error);
//       setError(`画像の復元中にエラーが発生しました: ${error.message}`);
//     }
//   };

//   // Snackbarを表示する関数
//   const handleSnackbarClose = () => {
//     setSnackbarState({ ...snackbarState, open: false });
//   };

//   // プロパティとして渡された showSnackbar と区別するために関数名を変更
//   const localShowSnackbar = (message) => {
//     setSnackbarState({
//       open: true,
//       message,
//     });
//   };

//   return (
//     <>
//       {/* 画像解析ボタンを追加 */}
//       <Button
//         variant="contained"
//         color="primary"
//         startIcon={<DescriptionIcon />}
//         onClick={() => {
//           setPreviewDialogMode("analyze");
//           setOpenPreviewDialog(true);
//         }}
//         disabled={isProcessing}
//         sx={{ mt: 2 }}
//       >
//         画像解析
//       </Button>

//       {/* 以下、既存のコード... */}
//       {/* プレビューダイアログ */}
//       <Dialog
//         open={openPreviewDialog}
//         onClose={() => setOpenPreviewDialog(false)}
//         maxWidth="xl"
//         fullWidth
//       >
//         <DialogTitle>
//           {previewDialogMode === "edit" ? "画像編集・削除" : "画像解析"}
//           <IconButton
//             aria-label="close"
//             onClick={() => setOpenPreviewDialog(false)}
//             sx={{
//               position: "absolute",
//               right: 8,
//               top: 8,
//               color: (theme) => theme.palette.grey[500],
//             }}
//           >
//             <CloseIcon />
//           </IconButton>
//         </DialogTitle>
//         <DialogContent>
//           {/* 表示件数の選択 */}
//           <Box sx={{ mb: 2, display: "flex", alignItems: "center" }}>
//             <Typography variant="body2" sx={{ mr: 2 }}>
//               表示件数:
//             </Typography>
//             <FormControl variant="outlined" size="small">
//               <Select
//                 value={itemsPerPage}
//                 onChange={handleItemsPerPageChange}
//                 sx={{ minWidth: 120 }}
//               >
//                 <MenuItem value={50}>50</MenuItem>
//                 <MenuItem value={100}>100</MenuItem>
//                 <MenuItem value={150}>150</MenuItem>
//                 <MenuItem value={200}>200</MenuItem>
//                 <MenuItem value="ALL">ALL</MenuItem>
//               </Select>
//             </FormControl>
//           </Box>
//           <Typography variant="body2" sx={{ mb: 2 }}>
//             {previewDialogMode === "edit"
//               ? "削除したい画像の「×」をクリックしてください。画像をドラッグ＆ドロップで並び替えることもできます。"
//               : "解析したい画像をクリックして選択してください。"}
//           </Typography>
//           <Box
//             ref={scrollableContainerRef} // ここでrefを設定
//             sx={{ maxHeight: "70vh", overflowY: "auto", position: "relative" }}
//           >
//             {displayedPreviewImages.map((product, index) => (
//               <Box key={index} sx={{ mb: 4 }}>
//                 <Typography variant="h6">{product.title}</Typography>

//                 {/* 商品を削除するボタンを追加 */}
//                 {previewDialogMode === "edit" && (
//                   <Button
//                     variant="outlined"
//                     color="error"
//                     onClick={() => handleDeleteProduct(product.rowIndex)}
//                     sx={{ mb: 2 }}
//                   >
//                     商品を削除
//                   </Button>
//                 )}

//                 <DragDropContext
//                   onDragEnd={(result) => handleDragEnd(result, product)}
//                 >
//                   <Droppable
//                     droppableId={`droppable-${product.rowIndex}`}
//                     direction="horizontal"
//                   >
//                     {(provided) => (
//                       <Grid
//                         container
//                         spacing={2}
//                         ref={provided.innerRef}
//                         {...provided.droppableProps}
//                       >
//                         {product.images.map((image, idx) => (
//                           <Draggable
//                             key={image.url}
//                             draggableId={image.url}
//                             index={idx}
//                             isDragDisabled={previewDialogMode === "analyze"}
//                           >
//                             {(provided) => (
//                               <Grid
//                                 item
//                                 xs={3}
//                                 sm={2}
//                                 md={1.5}
//                                 lg={1}
//                                 ref={provided.innerRef}
//                                 {...provided.draggableProps}
//                                 {...provided.dragHandleProps}
//                               >
//                                 <Paper
//                                   elevation={1}
//                                   sx={{
//                                     p: 1,
//                                     position: "relative",
//                                     opacity: selectedImages.has(image.url)
//                                       ? 0.4
//                                       : 1,
//                                     border: selectedImages.has(image.url)
//                                       ? "2px solid blue"
//                                       : "none",
//                                   }}
//                                   onClick={() => toggleImageSelection(image.url)}
//                                 >
//                                   {previewDialogMode === "edit" && (
//                                     <IconButton
//                                       aria-label="delete"
//                                       onClick={(e) => {
//                                         e.stopPropagation();
//                                         handleDeleteImage(
//                                           image.url,
//                                           product.rowIndex
//                                         );
//                                       }}
//                                       sx={{
//                                         position: "absolute",
//                                         top: 0,
//                                         right: 0,
//                                         color: (theme) =>
//                                           theme.palette.error.main,
//                                       }}
//                                     >
//                                       <CloseIcon />
//                                     </IconButton>
//                                   )}
//                                   <img
//                                     src={image.url}
//                                     alt={`Row ${product.rowIndex + 1} Image ${
//                                       image.imageIndex
//                                     }`}
//                                     style={{ width: "100%", height: "auto" }}
//                                     loading="lazy"
//                                   />
//                                   <Typography variant="caption">
//                                     画像 {idx + 1}
//                                   </Typography>
//                                 </Paper>
//                               </Grid>
//                             )}
//                           </Draggable>
//                         ))}

//                         {provided.placeholder}

//                         {/* プラスアイコンのDraggableアイテム */}
//                         {previewDialogMode === "edit" && (
//                           <Draggable
//                             key={`add-image-${product.rowIndex}`}
//                             draggableId={`add-image-${product.rowIndex}`}
//                             index={product.images.length}
//                             isDragDisabled={true} // ドラッグ不可に設定
//                           >
//                             {(provided) => (
//                               <Grid
//                                 item
//                                 xs={3}
//                                 sm={2}
//                                 md={1.5}
//                                 lg={1}
//                                 ref={provided.innerRef}
//                                 {...provided.draggableProps}
//                               >
//                                 <Paper
//                                   elevation={1}
//                                   sx={{
//                                     p: 1,
//                                     display: "flex",
//                                     alignItems: "center",
//                                     justifyContent: "center",
//                                     height: "100%",
//                                     border: "2px dashed #1976d2",
//                                     color: "#1976d2",
//                                     cursor: "pointer",
//                                   }}
//                                   onClick={() => handleAddImageClick(product)}
//                                 >
//                                   <Typography variant="h4">+</Typography>
//                                 </Paper>
//                               </Grid>
//                             )}
//                           </Draggable>
//                         )}
//                       </Grid>
//                     )}
//                   </Droppable>
//                 </DragDropContext>
//               </Box>
//             ))}
//           </Box>
//           {/* スクロールトップボタン */}
//           {showScrollTopButton && (
//             <IconButton
//               onClick={() => {
//                 if (scrollableContainerRef.current) {
//                   scrollableContainerRef.current.scrollTo({
//                     top: 0,
//                     behavior: "smooth",
//                   });
//                 }
//               }}
//               sx={{
//                 position: "fixed",
//                 bottom: 100,
//                 right: 40,
//                 zIndex: 1000,
//                 backgroundColor: theme.palette.background.paper,
//                 "&:hover": {
//                   backgroundColor: theme.palette.grey[300],
//                 },
//               }}
//             >
//               <KeyboardArrowUpIcon />
//             </IconButton>
//           )}
//           {/* ページネーション */}
//           {totalPages > 1 && (
//             <Box sx={{ mt: 2, display: "flex", justifyContent: "center" }}>
//               <Pagination
//                 count={totalPages}
//                 page={currentPage}
//                 onChange={handlePageChange}
//                 color="primary"
//               />
//             </Box>
//           )}
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => setOpenPreviewDialog(false)}>閉じる</Button>
//           {previewDialogMode === "edit" ? (
//             <Button
//               onClick={() => {
//                 setOpenConfirmDialog(true);
//               }}
//               color="primary"
//               variant="contained"
//               disabled={selectedImages.size === 0}
//             >
//               削除実行
//             </Button>
//           ) : (
//             <Button
//               onClick={handleImageAnalysis}
//               color="primary"
//               variant="contained"
//               disabled={selectedImages.size === 0}
//             >
//               解析実行
//             </Button>
//           )}
//         </DialogActions>
//       </Dialog>

//       {/* 画像追加ダイアログ */}
//       <Dialog
//         open={isAddImageDialogOpen}
//         onClose={() => setIsAddImageDialogOpen(false)}
//       >
//         <DialogTitle>画像を追加</DialogTitle>
//         <DialogContent>
//           <Typography variant="body2" sx={{ mb: 2 }}>
//             画像のURLを入力するか、画像ファイルをアップロードしてください。
//           </Typography>
//           <TextField
//             label="画像URL"
//             value={newImageURL}
//             onChange={(e) => setNewImageURL(e.target.value)}
//             fullWidth
//             sx={{ mb: 2 }}
//           />
//           <Typography variant="body2" sx={{ mb: 1 }}>
//             または
//           </Typography>
//           <Button variant="contained" component="label">
//             画像を選択
//             <input
//               type="file"
//               accept="image/*"
//               hidden
//               onChange={handleImageFileChange}
//             />
//           </Button>
//           {newImageFile && (
//             <Typography variant="body2" sx={{ mt: 1 }}>
//               選択されたファイル: {newImageFile.name}
//             </Typography>
//           )}
//         </DialogContent>
//         <DialogActions>
//           <Button
//             onClick={() => {
//               setIsAddImageDialogOpen(false);
//               setNewImageFile(null);
//               setNewImageURL("");
//             }}
//           >
//             キャンセル
//           </Button>
//           <Button onClick={handleAddImage} color="primary" variant="contained">
//             追加
//           </Button>
//         </DialogActions>
//       </Dialog>

//       {/* 画像削除確認ダイアログ */}
//       <Dialog
//         open={openConfirmDialog}
//         onClose={() => setOpenConfirmDialog(false)}
//         aria-labelledby="alert-dialog-title"
//         aria-describedby="alert-dialog-description"
//       >
//         <DialogTitle id="alert-dialog-title">{"確認"}</DialogTitle>
//         <DialogContent>
//           <DialogContentText id="alert-dialog-description">
//             選択された画像が商品データから削除されます。よろしいですか？
//           </DialogContentText>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => setOpenConfirmDialog(false)} color="primary">
//             キャンセル
//           </Button>
//           <Button onClick={handleDelete} color="primary" autoFocus>
//             実行
//           </Button>
//         </DialogActions>
//       </Dialog>

//       {/* 商品削除確認ダイアログ */}
//       <Dialog
//         open={openProductDeleteConfirmDialog}
//         onClose={() => setOpenProductDeleteConfirmDialog(false)}
//       >
//         <DialogTitle>商品を削除</DialogTitle>
//         <DialogContent>
//           <DialogContentText>
//             この商品を削除してもよろしいですか？
//           </DialogContentText>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => setOpenProductDeleteConfirmDialog(false)}>
//             キャンセル
//           </Button>
//           <Button
//             onClick={() => {
//               confirmDeleteProduct(productToDelete);
//               setOpenProductDeleteConfirmDialog(false);
//             }}
//             color="error"
//           >
//             削除
//           </Button>
//         </DialogActions>
//       </Dialog>

//       {/* Snackbar */}
//       <Snackbar
//         open={snackbarState.open}
//         autoHideDuration={6000}
//         onClose={handleSnackbarClose}
//         message={snackbarState.message}
//         action={
//           lastDeletedImage ? (
//             <Button color="secondary" size="small" onClick={handleUndoDeleteImage}>
//               元に戻す
//             </Button>
//           ) : null
//         }
//       />
//     </>
//   );
// }

// export default ImageOperations;







// // ImageOperations.js

// import React, { useState, useCallback, useEffect, useRef } from "react";
// import {
//   Button,
//   Typography,
//   Box,
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogTitle,
//   Paper,
//   Grid,
//   IconButton,
//   DialogContentText,
//   TextField,
//   MenuItem,
//   Select,
//   FormControl,
//   Pagination,
//   CircularProgress,
//   Tooltip,
// } from "@mui/material";
// import { useTheme } from "@mui/material/styles";
// import CloseIcon from "@mui/icons-material/Close";
// import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
// import DescriptionIcon from "@mui/icons-material/Description";
// import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
// import {
//   getStorage,
//   ref as storageRef,
//   uploadBytes,
//   getDownloadURL,
// } from "firebase/storage";
// import { v4 as uuidv4 } from "uuid";
// import axios from "axios";
// import performImageAnalysis from "./ImageAnalysis"; // 画像解析関数をインポート
// import ImageAnalysis from "./ImageAnalysis";

// function ImageOperations({
//   data,
//   setData,
//   startRow,
//   endRow,
//   calculateMaxEndRow,
//   isProcessing,
//   setIsProcessing,
//   setError,
//   showSnackbar,
//   openPreviewDialog,
//   setOpenPreviewDialog,
//   apiKey, // OpenAIのAPIキー
// }) {
//   const [previewImages, setPreviewImages] = useState([]);
//   const [displayedPreviewImages, setDisplayedPreviewImages] = useState([]);
//   const [selectedImages, setSelectedImages] = useState(new Set());
//   const [selectedProductForEdit, setSelectedProductForEdit] = useState(null);
//   const [isAddImageDialogOpen, setIsAddImageDialogOpen] = useState(false);
//   const [newImageFile, setNewImageFile] = useState(null);
//   const [newImageURL, setNewImageURL] = useState("");
//   const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
//   const [openProductDeleteConfirmDialog, setOpenProductDeleteConfirmDialog] =
//     useState(false);
//   const [productToDelete, setProductToDelete] = useState(null);

//   const [itemsPerPage, setItemsPerPage] = useState(50); // 1ページあたりの表示件数
//   const [currentPage, setCurrentPage] = useState(1); // 現在のページ番号

//   const [previewDialogMode, setPreviewDialogMode] = useState("edit"); // 'edit' または 'analyze'
//   const [isAnalyzing, setIsAnalyzing] = useState(false);

//   const theme = useTheme();

//   // スクロール可能なコンテナのrefを追加
//   const scrollableContainerRef = useRef(null);
//   const [showScrollTopButton, setShowScrollTopButton] = useState(false); // スクロールトップボタンの表示制御

//   const handleOpenPreviewDialog = useCallback(() => {
//     try {
//       if (!startRow || !endRow || isNaN(startRow) || isNaN(endRow)) {
//         throw new Error("Start RowとEnd Rowは有効な数字である必要があります。");
//       }

//       const picUrlKey = Object.keys(data[0]).find(
//         (key) => key.toLowerCase() === "picurl"
//       );
//       const titleKey = Object.keys(data[0]).find(
//         (key) => key.toLowerCase() === "title"
//       );

//       if (!picUrlKey) {
//         throw new Error("PicURLカラムが見つかりません。");
//       }

//       const actualStartRow = parseInt(startRow) - 1; // インデックスは0から開始
//       const maxEndRow = calculateMaxEndRow();
//       const actualEndRow = Math.min(parseInt(endRow) - 1, maxEndRow - 1);

//       if (actualStartRow < 0 || actualEndRow < actualStartRow) {
//         throw new Error("Start RowとEnd Rowが有効ではありません。");
//       }

//       let previewItems = [];

//       // すべての画像を取得
//       for (let i = actualStartRow; i <= actualEndRow; i++) {
//         const item = data[i];
//         const picUrl = item[picUrlKey];
//         const imageUrls = picUrl ? picUrl.split("|") : [];
//         const title = item[titleKey] || `商品 ${i + 1}`;
//         const images = imageUrls.map((url, idx) => ({
//           url,
//           imageIndex: idx + 1,
//         }));
//         if (images.length > 0) {
//           previewItems.push({
//             title,
//             rowIndex: i,
//             images,
//           });
//         }
//       }

//       setPreviewImages(previewItems);
//       setSelectedImages(new Set()); // すべて未選択
//       setCurrentPage(1); // ページをリセット
//     } catch (err) {
//       const errorMessage = `プレビューの準備中にエラーが発生しました: ${err.message}`;
//       setError(errorMessage);
//       console.error(errorMessage, err);
//     }
//   }, [data, startRow, endRow, calculateMaxEndRow, setError]);

//   // openPreviewDialogがtrueになったときにプレビューを準備
//   useEffect(() => {
//     if (openPreviewDialog) {
//       handleOpenPreviewDialog();
//     } else {
//       // ダイアログが閉じられたときにプレビュー画像をクリア
//       setPreviewImages([]);
//       setSelectedImages(new Set());
//     }
//   }, [openPreviewDialog, handleOpenPreviewDialog]);

//   // ページネーションの処理
//   useEffect(() => {
//     if (itemsPerPage === "ALL") {
//       setDisplayedPreviewImages(previewImages);
//     } else {
//       const startIndex = (currentPage - 1) * itemsPerPage;
//       const endIndex = startIndex + itemsPerPage;
//       setDisplayedPreviewImages(previewImages.slice(startIndex, endIndex));
//     }
//   }, [previewImages, currentPage, itemsPerPage]);

//   // currentPageが変更されたときにスクロール位置をトップに戻す
//   useEffect(() => {
//     if (scrollableContainerRef.current) {
//       scrollableContainerRef.current.scrollTop = 0;
//     }
//   }, [currentPage]);

//   // スクロール位置に応じてスクロールトップボタンの表示を制御
//   useEffect(() => {
//     const handleScroll = () => {
//       if (scrollableContainerRef.current) {
//         const scrollTop = scrollableContainerRef.current.scrollTop;
//         setShowScrollTopButton(scrollTop > 200);
//       }
//     };

//     const container = scrollableContainerRef.current;
//     if (container) {
//       container.addEventListener("scroll", handleScroll);
//       return () => container.removeEventListener("scroll", handleScroll);
//     }
//   }, [scrollableContainerRef]);

//   const totalPages =
//     itemsPerPage === "ALL" ? 1 : Math.ceil(previewImages.length / itemsPerPage);

//   const handleItemsPerPageChange = (event) => {
//     const value =
//       event.target.value === "ALL" ? "ALL" : parseInt(event.target.value);
//     setItemsPerPage(value);
//     setCurrentPage(1); // ページをリセット
//   };

//   const handlePageChange = (event, value) => {
//     setCurrentPage(value);
//   };

//   const toggleImageSelection = (url) => {
//     setSelectedImages((prevSelected) => {
//       const newSelected = new Set(prevSelected);
//       if (newSelected.has(url)) {
//         newSelected.delete(url);
//       } else {
//         newSelected.add(url);
//       }
//       return newSelected;
//     });
//   };

//   const handleDelete = useCallback(async () => {
//     setIsProcessing(true);
//     setError("");

//     try {
//       if (!startRow || !endRow || isNaN(startRow) || isNaN(endRow)) {
//         throw new Error("Start RowとEnd Rowは有効な数字である必要があります。");
//       }

//       const picUrlKey = Object.keys(data[0]).find(
//         (key) => key.toLowerCase() === "picurl"
//       );

//       if (!picUrlKey) {
//         throw new Error("PicURLカラムが見つかりません。");
//       }

//       const actualStartRow = parseInt(startRow) - 1; // インデックスは0から開始
//       const maxEndRow = calculateMaxEndRow();
//       const actualEndRow = Math.min(parseInt(endRow) - 1, maxEndRow - 1);

//       if (actualStartRow < 0 || actualEndRow < actualStartRow) {
//         throw new Error("Start RowとEnd Rowが有効ではありません。");
//       }

//       // データの更新
//       const updatedData = [...data];
//       for (let i = actualStartRow; i <= actualEndRow; i++) {
//         const item = { ...updatedData[i] };
//         const originalImages = item[picUrlKey]
//           ? item[picUrlKey].split("|")
//           : [];
//         const newImages = originalImages.filter(
//           (url) => !selectedImages.has(url)
//         );
//         item[picUrlKey] = newImages.join("|");
//         updatedData[i] = item;
//       }

//       setData(updatedData);

//       // プレビュー画像を更新
//       const updatedPreviewImages = previewImages.map((product) => {
//         const item = updatedData[product.rowIndex];
//         const picUrl = item[picUrlKey];
//         const imageUrls = picUrl ? picUrl.split("|") : [];
//         const images = imageUrls.map((url, idx) => ({
//           url,
//           imageIndex: idx + 1,
//         }));
//         return {
//           ...product,
//           images,
//         };
//       });
//       setPreviewImages(updatedPreviewImages);

//       const finalMessage = `画像の削除が完了しました。`;
//       showSnackbar(finalMessage);
//     } catch (err) {
//       const errorMessage = `画像の削除に失敗しました: ${err.message}`;
//       setError(errorMessage);
//       showSnackbar(errorMessage);
//       console.error(errorMessage, err);
//     } finally {
//       setIsProcessing(false);
//       setOpenConfirmDialog(false);
//       setOpenPreviewDialog(false);
//     }
//   }, [
//     data,
//     startRow,
//     endRow,
//     selectedImages,
//     calculateMaxEndRow,
//     setData,
//     previewImages,
//     setIsProcessing,
//     setError,
//     showSnackbar,
//     setOpenPreviewDialog,
//   ]);

//   const handleAddImageClick = (product) => {
//     setSelectedProductForEdit(product);
//     setIsAddImageDialogOpen(true);
//   };

//   const handleImageFileChange = (e) => {
//     if (e.target.files && e.target.files[0]) {
//       setNewImageFile(e.target.files[0]);
//       setNewImageURL("");
//     }
//   };

//   const handleAddImage = async () => {
//     const storage = getStorage();

//     try {
//       let imageUrl = newImageURL;

//       if (newImageFile) {
//         // Firebase Storage に画像をアップロード
//         const fileExtension = newImageFile.name.split(".").pop();
//         const storageReference = storageRef(
//           storage,
//           `images/${uuidv4()}.${fileExtension}`
//         );
//         await uploadBytes(storageReference, newImageFile);
//         imageUrl = await getDownloadURL(storageReference);
//       }

//       if (!imageUrl) {
//         alert(
//           "画像のURLを入力するか、画像ファイルをアップロードしてください。"
//         );
//         return;
//       }

//       // データの更新
//       const picUrlKey = Object.keys(data[0]).find(
//         (key) => key.toLowerCase() === "picurl"
//       );
//       const updatedData = [...data];
//       const item = { ...updatedData[selectedProductForEdit.rowIndex] };
//       const imageUrls = item[picUrlKey] ? item[picUrlKey].split("|") : [];
//       imageUrls.push(imageUrl);
//       item[picUrlKey] = imageUrls.join("|");
//       updatedData[selectedProductForEdit.rowIndex] = item;
//       setData(updatedData);

//       // previewImagesを更新
//       const updatedPreviewImages = previewImages.map((product) => {
//         if (product.rowIndex === selectedProductForEdit.rowIndex) {
//           const images = [
//             ...product.images,
//             { url: imageUrl, imageIndex: product.images.length + 1 },
//           ];
//           return {
//             ...product,
//             images,
//           };
//         }
//         return product;
//       });
//       setPreviewImages(updatedPreviewImages);

//       setIsAddImageDialogOpen(false);
//       setNewImageFile(null);
//       setNewImageURL("");
//     } catch (error) {
//       console.error("画像の追加中にエラーが発生しました:", error);
//       alert("画像の追加に失敗しました。再度お試しください。");
//     }
//   };

//   // 商品削除の関数
//   const handleDeleteProduct = (rowIndex) => {
//     setProductToDelete(rowIndex);
//     setOpenProductDeleteConfirmDialog(true);
//   };

//   // 削除を確定する関数
//   const confirmDeleteProduct = (rowIndex) => {
//     // データから商品を削除
//     const updatedData = data.filter((_, index) => index !== rowIndex);
//     setData(updatedData);

//     // プレビュー画像から商品を削除
//     const updatedPreviewImages = previewImages.filter(
//       (product) => product.rowIndex !== rowIndex
//     );

//     // インデックスを再計算
//     const recalculatedPreviewImages = updatedPreviewImages.map((product) => {
//       return {
//         ...product,
//         rowIndex:
//           product.rowIndex > rowIndex ? product.rowIndex - 1 : product.rowIndex,
//       };
//     });

//     setPreviewImages(recalculatedPreviewImages);
//   };

//   // ドラッグ終了時のハンドラー
//   const handleDragEnd = (result, product) => {
//     if (!result.destination) {
//       return;
//     }

//     const { source, destination } = result;

//     const productIndex = previewImages.findIndex(
//       (p) => p.rowIndex === product.rowIndex
//     );
//     if (productIndex === -1) {
//       return;
//     }

//     const images = Array.from(previewImages[productIndex].images);
//     const [movedImage] = images.splice(source.index, 1);
//     images.splice(destination.index, 0, movedImage);

//     // previewImagesを更新
//     const updatedPreviewImages = [...previewImages];
//     updatedPreviewImages[productIndex] = {
//       ...previewImages[productIndex],
//       images,
//     };
//     setPreviewImages(updatedPreviewImages);

//     // dataを更新
//     const picUrlKey = Object.keys(data[0]).find(
//       (key) => key.toLowerCase() === "picurl"
//     );
//     const updatedData = [...data];
//     const item = { ...updatedData[product.rowIndex] };
//     item[picUrlKey] = images.map((img) => img.url).join("|");
//     updatedData[product.rowIndex] = item;
//     setData(updatedData);
//   };

//   // 画像解析を行う関数
//   const handleImageAnalysis = async () => {
//     setIsProcessing(true);
//     setError("");

//     try {
//       const updatedData = [...data];
//       const picUrlKey = Object.keys(data[0]).find(
//         (key) => key.toLowerCase() === "picurl"
//       );

//       if (!picUrlKey) {
//         throw new Error("PicURLカラムが見つかりません。");
//       }

//       if (selectedImages.size === 0) {
//         throw new Error("解析する画像を選択してください。");
//       }

//       for (const selectedImageUrl of selectedImages) {
//         // 対応する商品（行）を見つける
//         const itemIndex = data.findIndex((item) => {
//           const picUrl = item[picUrlKey];
//           const imageUrls = picUrl ? picUrl.split("|") : [];
//           return imageUrls.includes(selectedImageUrl);
//         });

//         if (itemIndex !== -1) {
//           const item = updatedData[itemIndex];
//           // 画像を解析して説明を取得
//           // const description = await performImageAnalysis(selectedImageUrl, apiKey);
//           const description = await ImageAnalysis(selectedImageUrl, apiKey);
//           // 既存の説明がある場合は追記、それ以外は新規作成
//           if (item["jp_image_description"]) {
//             item["jp_image_description"] += `\n\n${description}`;
//           } else {
//             item["jp_image_description"] = description;
//           }
//         }
//       }

//       setData(updatedData);
//       showSnackbar("選択された画像の解析が完了し、データが更新されました。");
//     } catch (error) {
//       setError(`画像解析中にエラーが発生しました: ${error.message}`);
//       console.error(error);
//     } finally {
//       setIsProcessing(false);
//       setOpenPreviewDialog(false);
//     }
//   };

//   return (
//     <>
//       {/* 画像解析ボタンを追加 */}
//       <Button
//         variant="contained"
//         color="primary"
//         startIcon={<DescriptionIcon />}
//         onClick={() => {
//           setPreviewDialogMode("analyze");
//           setOpenPreviewDialog(true);
//         }}
//         disabled={isProcessing}
//         sx={{ mt: 2 }}
//       >
//         画像解析
//       </Button>

//       {/* 以下、既存のコード... */}
//       {/* プレビューダイアログ */}
//       <Dialog
//         open={openPreviewDialog}
//         onClose={() => setOpenPreviewDialog(false)}
//         maxWidth="xl"
//         fullWidth
//       >
//         <DialogTitle>
//           {previewDialogMode === "edit" ? "画像編集・削除" : "画像解析"}
//           <IconButton
//             aria-label="close"
//             onClick={() => setOpenPreviewDialog(false)}
//             sx={{
//               position: "absolute",
//               right: 8,
//               top: 8,
//               color: (theme) => theme.palette.grey[500],
//             }}
//           >
//             <CloseIcon />
//           </IconButton>
//         </DialogTitle>
//         <DialogContent>
//           {/* 表示件数の選択 */}
//           <Box sx={{ mb: 2, display: "flex", alignItems: "center" }}>
//             <Typography variant="body2" sx={{ mr: 2 }}>
//               表示件数:
//             </Typography>
//             <FormControl variant="outlined" size="small">
//               <Select
//                 value={itemsPerPage}
//                 onChange={handleItemsPerPageChange}
//                 sx={{ minWidth: 120 }}
//               >
//                 <MenuItem value={50}>50</MenuItem>
//                 <MenuItem value={100}>100</MenuItem>
//                 <MenuItem value={150}>150</MenuItem>
//                 <MenuItem value={200}>200</MenuItem>
//                 <MenuItem value="ALL">ALL</MenuItem>
//               </Select>
//             </FormControl>
//           </Box>
//           <Typography variant="body2" sx={{ mb: 2 }}>
//             {previewDialogMode === "edit"
//               ? "削除したい画像の「×」をクリックしてください。画像をドラッグ＆ドロップで並び替えることもできます。"
//               : "解析したい画像をクリックして選択してください。"}
//           </Typography>
//           <Box
//             ref={scrollableContainerRef} // ここでrefを設定
//             sx={{ maxHeight: "70vh", overflowY: "auto", position: "relative" }}
//           >
//             {displayedPreviewImages.map((product, index) => (
//               <Box key={index} sx={{ mb: 4 }}>
//                 <Typography variant="h6">{product.title}</Typography>

//                 {/* 商品を削除するボタンを追加 */}
//                 {previewDialogMode === "edit" && (
//                   <Button
//                     variant="outlined"
//                     color="error"
//                     onClick={() => handleDeleteProduct(product.rowIndex)}
//                     sx={{ mb: 2 }}
//                   >
//                     商品を削除
//                   </Button>
//                 )}

//                 <DragDropContext
//                   onDragEnd={(result) => handleDragEnd(result, product)}
//                 >
//                   <Droppable
//                     droppableId={`droppable-${product.rowIndex}`}
//                     direction="horizontal"
//                   >
//                     {(provided) => (
//                       <Grid
//                         container
//                         spacing={2}
//                         ref={provided.innerRef}
//                         {...provided.droppableProps}
//                       >
//                         {product.images.map((image, idx) => (
//                           <Draggable
//                             key={image.url}
//                             draggableId={image.url}
//                             index={idx}
//                             isDragDisabled={previewDialogMode === "analyze"}
//                           >
//                             {(provided) => (
//                               <Grid
//                                 item
//                                 xs={3}
//                                 sm={2}
//                                 md={1.5}
//                                 lg={1}
//                                 ref={provided.innerRef}
//                                 {...provided.draggableProps}
//                                 {...provided.dragHandleProps}
//                               >
//                                 <Paper
//                                   elevation={1}
//                                   sx={{
//                                     p: 1,
//                                     position: "relative",
//                                     opacity: selectedImages.has(image.url)
//                                       ? 0.4
//                                       : 1,
//                                     border: selectedImages.has(image.url)
//                                       ? "2px solid blue"
//                                       : "none",
//                                   }}
//                                   onClick={() =>
//                                     toggleImageSelection(image.url)
//                                   }
//                                 >
//                                   {previewDialogMode === "edit" && (
//                                     <IconButton
//                                       aria-label="delete"
//                                       onClick={(e) => {
//                                         e.stopPropagation();
//                                         toggleImageSelection(image.url);
//                                       }}
//                                       sx={{
//                                         position: "absolute",
//                                         top: 0,
//                                         right: 0,
//                                         color: (theme) =>
//                                           theme.palette.error.main,
//                                       }}
//                                     >
//                                       <CloseIcon />
//                                     </IconButton>
//                                   )}
//                                   <img
//                                     src={image.url}
//                                     alt={`Row ${product.rowIndex + 1} Image ${
//                                       image.imageIndex
//                                     }`}
//                                     style={{ width: "100%", height: "auto" }}
//                                     loading="lazy"
//                                   />
//                                   <Typography variant="caption">
//                                     画像 {idx + 1}
//                                   </Typography>
//                                 </Paper>
//                               </Grid>
//                             )}
//                           </Draggable>
//                         ))}

//                         {provided.placeholder}

//                         {/* プラスアイコンのDraggableアイテム */}
//                         {previewDialogMode === "edit" && (
//                           <Draggable
//                             key={`add-image-${product.rowIndex}`}
//                             draggableId={`add-image-${product.rowIndex}`}
//                             index={product.images.length}
//                             isDragDisabled={true} // ドラッグ不可に設定
//                           >
//                             {(provided) => (
//                               <Grid
//                                 item
//                                 xs={3}
//                                 sm={2}
//                                 md={1.5}
//                                 lg={1}
//                                 ref={provided.innerRef}
//                                 {...provided.draggableProps}
//                               >
//                                 <Paper
//                                   elevation={1}
//                                   sx={{
//                                     p: 1,
//                                     display: "flex",
//                                     alignItems: "center",
//                                     justifyContent: "center",
//                                     height: "100%",
//                                     border: "2px dashed #1976d2",
//                                     color: "#1976d2",
//                                     cursor: "pointer",
//                                   }}
//                                   onClick={() => handleAddImageClick(product)}
//                                 >
//                                   <Typography variant="h4">+</Typography>
//                                 </Paper>
//                               </Grid>
//                             )}
//                           </Draggable>
//                         )}
//                       </Grid>
//                     )}
//                   </Droppable>
//                 </DragDropContext>
//               </Box>
//             ))}
//           </Box>
//           {/* スクロールトップボタン */}
//           {showScrollTopButton && (
//             <IconButton
//               onClick={() => {
//                 if (scrollableContainerRef.current) {
//                   scrollableContainerRef.current.scrollTo({
//                     top: 0,
//                     behavior: "smooth",
//                   });
//                 }
//               }}
//               sx={{
//                 position: "fixed",
//                 bottom: 100,
//                 right: 40,
//                 zIndex: 1000,
//                 backgroundColor: theme.palette.background.paper,
//                 "&:hover": {
//                   backgroundColor: theme.palette.grey[300],
//                 },
//               }}
//             >
//               <KeyboardArrowUpIcon />
//             </IconButton>
//           )}
//           {/* ページネーション */}
//           {totalPages > 1 && (
//             <Box sx={{ mt: 2, display: "flex", justifyContent: "center" }}>
//               <Pagination
//                 count={totalPages}
//                 page={currentPage}
//                 onChange={handlePageChange}
//                 color="primary"
//               />
//             </Box>
//           )}
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => setOpenPreviewDialog(false)}>閉じる</Button>
//           {previewDialogMode === "edit" ? (
//             <Button
//               onClick={() => {
//                 setOpenConfirmDialog(true);
//               }}
//               color="primary"
//               variant="contained"
//               disabled={selectedImages.size === 0}
//             >
//               削除実行
//             </Button>
//           ) : (
//             <Button
//               onClick={handleImageAnalysis}
//               color="primary"
//               variant="contained"
//               disabled={selectedImages.size === 0}
//             >
//               解析実行
//             </Button>
//           )}
//         </DialogActions>
//       </Dialog>

//       {/* 画像追加ダイアログ */}
//       <Dialog
//         open={isAddImageDialogOpen}
//         onClose={() => setIsAddImageDialogOpen(false)}
//       >
//         <DialogTitle>画像を追加</DialogTitle>
//         <DialogContent>
//           <Typography variant="body2" sx={{ mb: 2 }}>
//             画像のURLを入力するか、画像ファイルをアップロードしてください。
//           </Typography>
//           <TextField
//             label="画像URL"
//             value={newImageURL}
//             onChange={(e) => setNewImageURL(e.target.value)}
//             fullWidth
//             sx={{ mb: 2 }}
//           />
//           <Typography variant="body2" sx={{ mb: 1 }}>
//             または
//           </Typography>
//           <Button variant="contained" component="label">
//             画像を選択
//             <input
//               type="file"
//               accept="image/*"
//               hidden
//               onChange={handleImageFileChange}
//             />
//           </Button>
//           {newImageFile && (
//             <Typography variant="body2" sx={{ mt: 1 }}>
//               選択されたファイル: {newImageFile.name}
//             </Typography>
//           )}
//         </DialogContent>
//         <DialogActions>
//           <Button
//             onClick={() => {
//               setIsAddImageDialogOpen(false);
//               setNewImageFile(null);
//               setNewImageURL("");
//             }}
//           >
//             キャンセル
//           </Button>
//           <Button onClick={handleAddImage} color="primary" variant="contained">
//             追加
//           </Button>
//         </DialogActions>
//       </Dialog>

//       {/* 画像削除確認ダイアログ */}
//       <Dialog
//         open={openConfirmDialog}
//         onClose={() => setOpenConfirmDialog(false)}
//         aria-labelledby="alert-dialog-title"
//         aria-describedby="alert-dialog-description"
//       >
//         <DialogTitle id="alert-dialog-title">{"確認"}</DialogTitle>
//         <DialogContent>
//           <DialogContentText id="alert-dialog-description">
//             選択された画像が商品データから削除されます。よろしいですか？
//           </DialogContentText>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => setOpenConfirmDialog(false)} color="primary">
//             キャンセル
//           </Button>
//           <Button onClick={handleDelete} color="primary" autoFocus>
//             実行
//           </Button>
//         </DialogActions>
//       </Dialog>

//       {/* 商品削除確認ダイアログ */}
//       <Dialog
//         open={openProductDeleteConfirmDialog}
//         onClose={() => setOpenProductDeleteConfirmDialog(false)}
//       >
//         <DialogTitle>商品を削除</DialogTitle>
//         <DialogContent>
//           <DialogContentText>
//             この商品を削除してもよろしいですか？
//           </DialogContentText>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => setOpenProductDeleteConfirmDialog(false)}>
//             キャンセル
//           </Button>
//           <Button
//             onClick={() => {
//               confirmDeleteProduct(productToDelete);
//               setOpenProductDeleteConfirmDialog(false);
//             }}
//             color="error"
//           >
//             削除
//           </Button>
//         </DialogActions>
//       </Dialog>
//     </>
//   );
// }

// export default ImageOperations;