// ItemSpecificsApplier.js

import React, { useState, useEffect } from "react";
import {
  Button,
  Typography,
  Box,
  CircularProgress,
  Snackbar,
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Checkbox,
  FormControlLabel,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  RadioGroup,
  Radio,
  Chip,
} from "@mui/material";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import {
  Settings as SettingsIcon,
  Refresh as RefreshIcon,
} from "@mui/icons-material";

const ItemSpecificsApplier = ({ data, setData, userId, headers }) => {
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "info",
  });
  const [categories, setCategories] = useState({});
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [caseSensitive, setCaseSensitive] = useState(false);
  const [partialMatch, setPartialMatch] = useState(true);
  const [matchSource, setMatchSource] = useState("title");
  const [matchingOption, setMatchingOption] = useState("first");
  const [overwriteExisting, setOverwriteExisting] = useState(false); // 上書きオプションの状態
  const [previewData, setPreviewData] = useState([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [updatedData, setUpdatedData] = useState([]);

  const db = getFirestore();

  // Firestoreから設定を読み込む
  const loadSettings = async () => {
    if (!userId) {
      setSnackbar({
        open: true,
        message: "ユーザー情報がありません。",
        severity: "error",
      });
      return;
    }

    try {
      const docRef = doc(db, "itemSpecificsSettings", userId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const userSettings = docSnap.data();
        setCategories(userSettings.categories || {});
        setSnackbar({
          open: true,
          message: "設定を読み込みました。",
          severity: "success",
        });
      } else {
        setCategories({});
        setSnackbar({
          open: true,
          message: "設定が見つかりません。",
          severity: "info",
        });
      }
    } catch (error) {
      console.error("設定の読み込みに失敗しました:", error);
      setSnackbar({
        open: true,
        message: "設定の読み込みに失敗しました。",
        severity: "error",
      });
    }
  };

  // 初回マウント時に設定を読み込む
  useEffect(() => {
    loadSettings();
  }, [userId]);

  // 設定を再読み込みする関数
  const handleReloadSettings = () => {
    loadSettings();
  };

  // 大文字小文字区別の切り替え
  const handleCaseSensitiveToggle = (event) => {
    setCaseSensitive(event.target.checked);
  };

  // 部分一致の切り替え
  const handlePartialMatchToggle = (event) => {
    setPartialMatch(event.target.checked);
  };

  // マッチングソースの変更
  const handleMatchSourceChange = (event) => {
    setMatchSource(event.target.value);
  };

  // マッチングの動作を選択する関数
  const handleMatchingOptionChange = (event) => {
    setMatchingOption(event.target.value);
  };

  // 上書きオプションの切り替え
  const handleOverwriteExistingToggle = (event) => {
    setOverwriteExisting(event.target.checked);
  };

  // DescriptionからAI生成部分を抽出する関数
  const extractAIGeneratedContent = (description) => {
    if (!description) return "";
    const parser = new DOMParser();
    const doc = parser.parseFromString(description, "text/html");
    const aiContentDiv = doc.getElementById("ai-generated-content");
    return aiContentDiv ? aiContentDiv.innerHTML : "";
  };

  // 商品説明から KEY: Value のペアを抽出する関数
  const extractKeyValuePairs = (htmlContent) => {
    const keyValuePairs = {};
    if (!htmlContent) return keyValuePairs;

    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, "text/html");

    // すべての <li> 要素を取得
    const liElements = doc.querySelectorAll("li");

    liElements.forEach((li) => {
      let text = li.textContent.trim();

      // 特殊文字や装飾を除去
      text = text
        .replace(/^\s*[-•✓]+[\s]*/, "") // 先頭の記号を除去
        .replace(/\*\*/g, "") // マークダウンの強調を除去
        .replace(/\s+/g, " "); // 複数のスペースを一つに

      // コロンで分割
      const colonIndex = text.indexOf(":");
      if (colonIndex > -1) {
        let key = text.substring(0, colonIndex).trim();
        const value = text.substring(colonIndex + 1).trim();

        // さらに特殊文字を除去
        key = key.replace(/^\*+/, "").replace(/\*+$/, "");

        if (key && value) {
          keyValuePairs[key] = value;
        }
      }
    });

    return keyValuePairs;
  };

  // 単語が途中で切れないように64文字以内に調整する関数
  const truncateValueByWords = (value, maxLength) => {
    if (value.length <= maxLength) return value;

    const words = value.split(" ");
    let truncatedValue = "";
    for (let i = 0; i < words.length; i++) {
      const word = words[i];
      const separator = truncatedValue ? " " : "";
      if ((truncatedValue + separator + word).length > maxLength) {
        break;
      }
      truncatedValue += separator + word;
    }

    return truncatedValue;
  };

  // データにItem Specificsを適用し、プレビューを表示する
  const handleUpdateData = () => {
    if (!selectedCategory) {
      setSnackbar({
        open: true,
        message: "適用するカテゴリーを選択してください。",
        severity: "warning",
      });
      return;
    }

    if (selectedColumns.length === 0) {
      setSnackbar({
        open: true,
        message: "適用するカラムを選択してください。",
        severity: "warning",
      });
      return;
    }

    setLoading(true);

    try {
      const titleKey = headers.find(
        (header) => header.toLowerCase() === "title"
      );
      const descriptionKey = headers.find(
        (header) => header.toLowerCase() === "description"
      );

      if (!titleKey && (matchSource === "title" || matchSource === "both")) {
        throw new Error("「Title」カラムが見つかりません。");
      }

      if (
        !descriptionKey &&
        (matchSource === "description" || matchSource === "both")
      ) {
        throw new Error("「Description」カラムが見つかりません。");
      }

      const newData = data.map((item) => {
        const title = item[titleKey] || "";
        let description = item[descriptionKey] || "";
        let sourceText = "";

        // DescriptionからAI生成部分を抽出
        const aiContent = extractAIGeneratedContent(description);

        // マッチングソースに応じてテキストを設定
        if (matchSource === "title") {
          sourceText = title;
        } else if (matchSource === "description") {
          sourceText = aiContent;
        } else if (matchSource === "both") {
          sourceText = `${title} ${aiContent}`;
        }

        const newItem = { ...item };

        // 商品説明から KEY: Value ペアを抽出
        const keyValuePairs = extractKeyValuePairs(aiContent);

        // 正規化関数を定義
        const normalizeString = (str) =>
          str
            .toLowerCase()
            .replace(/\s+/g, "")
            .replace(/[^a-z0-9]/g, "");

        // KEY とカラム名を照合して一致すれば値を設定
        Object.keys(keyValuePairs).forEach((key) => {
          const normalizedKey = normalizeString(key);
          const matchingHeader = headers.find(
            (header) =>
              header.startsWith("C:") &&
              normalizeString(header.slice(2)) === normalizedKey
          );
          if (matchingHeader) {
            let valueToApply = keyValuePairs[key];

            // 値を64文字以内に調整し、単語が途中で切れないようにする
            valueToApply = truncateValueByWords(valueToApply, 64);

            if (overwriteExisting || !newItem[matchingHeader]) {
              newItem[matchingHeader] = valueToApply;
            }
          }
        });

        // selectedColumnsに対してマッチング処理を行う
        selectedColumns.forEach((column) => {
          const value = newItem[column];

          if (!overwriteExisting) {
            // 上書きを許可しない場合、既存の値があるときはスキップ
            if (
              value && // null や undefined でない
              value.trim() !== "" && // 空白のみでない
              value !== "NA" &&
              value !== "N/A"
            ) {
              return; // 値が適切に設定されているのでスキップ
            }
          }

          const columnData = categories[selectedCategory].columns[column];
          const conversionRules =
            (columnData && columnData.conversionRules) || [];
          const values = (columnData && columnData.values) || [];

          // マッチングアイテムのリストを作成（変換ルールと値）
          const matchingItems = [
            ...conversionRules.map((rule) => ({
              type: "rule",
              keyword: rule.keyword,
              value: rule.value,
            })),
            ...values.map((value) => ({
              type: "value",
              keyword: value,
              value,
            })),
          ];

          const matchedValues = new Set();

          // 正規表現の特殊文字をエスケープする関数
          const escapeRegExp = (string) => {
            return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
          };

          // マッチング処理
          for (const item of matchingItems) {
            const { keyword, value } = item;
            if (!keyword || !value) continue;

            const flags = caseSensitive ? "g" : "gi";
            const escapedKeyword = escapeRegExp(keyword);
            const regex = new RegExp(`\\b${escapedKeyword}\\b`, flags);

            const matchCondition = partialMatch
              ? regex.test(sourceText)
              : caseSensitive
              ? sourceText === keyword
              : sourceText.toLowerCase() === keyword.toLowerCase();

            if (matchCondition) {
              matchedValues.add(value);
              if (matchingOption === "first") break;
              if (matchingOption === "priority") {
                let valueToApply = value;

                // 値を64文字以内に調整し、単語が途中で切れないようにする
                valueToApply = truncateValueByWords(valueToApply, 64);

                newItem[column] = valueToApply;
                break;
              }
            }
          }

          // 既存の値をチェック
          const existingValue = newItem[column];

          // 値を適用
          if (matchedValues.size > 0) {
            let valueToApply;
            if (matchingOption === "first" || matchingOption === "priority") {
              valueToApply = matchedValues.values().next().value;
            } else if (matchingOption === "all") {
              valueToApply = Array.from(matchedValues).join(", ");
            }

            // 値を64文字以内に調整し、単語が途中で切れないようにする
            valueToApply = truncateValueByWords(valueToApply, 64);

            if (overwriteExisting || !existingValue || existingValue === "NA" || existingValue === "N/A" || existingValue.trim() === "") {
              newItem[column] = valueToApply;
            }
          }
        });

        // keyValuePairsをnewItemに保存
        newItem.__keyValuePairs = keyValuePairs;

        return newItem;
      });

      setUpdatedData(newData);

      // プレビュー用データを作成（上位10件のみ）
      const previewItems = newData.slice(0, 10).map((item, index) => {
        const originalItem = data[index];
        const previewItem = { Title: item[titleKey] || "" };

        if (matchSource !== "title") {
          previewItem.Description = item[descriptionKey] || "";
          // AI生成部分のみを抽出してプレビューに表示
          previewItem["AI Generated Content"] = extractAIGeneratedContent(
            item[descriptionKey]
          );
        }

        selectedColumns.forEach((column) => {
          previewItem[`${column} (Before)`] = originalItem[column] || "";
          previewItem[`${column} (After)`] = item[column] || "";
        });

        // 商品説明から抽出した KEY: Value ペアもプレビューに追加
        const keyValuePairs = item.__keyValuePairs || {};
        Object.keys(keyValuePairs).forEach((key) => {
          if (headers.includes(key)) {
            previewItem[`${key} (Extracted)`] = keyValuePairs[key];
          }
        });

        return previewItem;
      });

      setPreviewData(previewItems);
      setPreviewOpen(true);
    } catch (error) {
      console.error("データの更新中にエラーが発生しました:", error);
      setSnackbar({
        open: true,
        message: `データの更新中にエラーが発生しました: ${error.message}`,
        severity: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  // 更新を確定する関数
  const confirmUpdate = () => {
    if (updatedData.length === 0) {
      setSnackbar({
        open: true,
        message: "更新データが存在しません。",
        severity: "warning",
      });
      return;
    }

    setData(updatedData);
    setSnackbar({
      open: true,
      message: "データが正常に更新されました。",
      severity: "success",
    });
    setPreviewOpen(false);
  };

  // 適用するカテゴリーのカラムを取得
  const getColumnsForSelectedCategory = () => {
    if (selectedCategory && categories[selectedCategory]) {
      return Object.keys(categories[selectedCategory].columns || {});
    }
    return [];
  };

  return (
    <Box sx={{ p: 2, mt: 2 }}>
      <Typography variant="h5" gutterBottom>
        Item Specifics適用
      </Typography>
      <Typography variant="body1" paragraph>
        設定したItem
        Specificsをタイトルや商品説明に基づいて適用し、該当するカラムを更新します。
      </Typography>
      <Box
        sx={{
          mt: 2,
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
          <FormControl
            fullWidth
            variant="outlined"
            size="small"
            sx={{ minWidth: 200 }}
          >
            <InputLabel>適用するカテゴリー</InputLabel>
            <Select
              value={selectedCategory}
              onChange={(e) => {
                setSelectedCategory(e.target.value);
                setSelectedColumns([]); // カテゴリー変更時に選択カラムをリセット
              }}
              label="適用するカテゴリー"
            >
              {Object.keys(categories).map((category) => (
                <MenuItem key={category} value={category}>
                  {category}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {selectedCategory && (
            <FormControl
              fullWidth
              variant="outlined"
              size="small"
              sx={{ minWidth: 200 }}
            >
              <InputLabel>適用するカラムを選択</InputLabel>
              <Select
                multiple
                value={selectedColumns}
                onChange={(e) => {
                  const value = e.target.value;
                  if (value.includes("all")) {
                    // 「全選択」が選択された場合
                    if (
                      selectedColumns.length ===
                      getColumnsForSelectedCategory().length
                    ) {
                      // すでにすべて選択されている場合は選択を解除
                      setSelectedColumns([]);
                    } else {
                      // すべてのカラムを選択
                      setSelectedColumns(getColumnsForSelectedCategory());
                    }
                  } else {
                    setSelectedColumns(value);
                  }
                }}
                label="適用するカラムを選択"
                renderValue={(selected) => {
                  if (selected.length === 0) {
                    return "適用するカラムを選択";
                  }

                  const displayedValues = selected.slice(0, 5); // 最初の5つを表示
                  const hiddenCount = selected.length - displayedValues.length;

                  return (
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                      {displayedValues.map((value) => (
                        <Chip key={value} label={value} size="small" />
                      ))}
                      {hiddenCount > 0 && (
                        <Chip label={`+${hiddenCount}個`} size="small" />
                      )}
                    </Box>
                  );
                }}
              >
                <MenuItem
                  value="all"
                  onClick={() => {
                    if (
                      selectedColumns.length ===
                      getColumnsForSelectedCategory().length
                    ) {
                      // すでにすべて選択されている場合は選択を解除
                      setSelectedColumns([]);
                    } else {
                      // すべてのカラムを選択
                      setSelectedColumns(getColumnsForSelectedCategory());
                    }
                  }}
                >
                  <Checkbox
                    checked={
                      selectedColumns.length ===
                        getColumnsForSelectedCategory().length &&
                      selectedColumns.length > 0
                    }
                    indeterminate={
                      selectedColumns.length > 0 &&
                      selectedColumns.length <
                        getColumnsForSelectedCategory().length
                    }
                  />
                  <Typography variant="body1">全選択</Typography>
                </MenuItem>
                {getColumnsForSelectedCategory().map((column) => (
                  <MenuItem key={column} value={column}>
                    <Checkbox checked={selectedColumns.indexOf(column) > -1} />
                    <Typography variant="body1">{column}</Typography>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          <Button
            startIcon={<SettingsIcon />}
            onClick={handleUpdateData}
            variant="contained"
            color="primary"
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : "データを更新"}
          </Button>
          <Button
            startIcon={<RefreshIcon />}
            onClick={handleReloadSettings}
            variant="outlined"
            color="secondary"
          >
            データ再読み込み
          </Button>
        </Box>
      </Box>

      {/* マッチングソースの選択 */}
      {selectedCategory && (
        <>
          <Box sx={{ mt: 2 }}>
            <Typography variant="subtitle1" gutterBottom>
              マッチングの基準を選択してください。
            </Typography>
            <FormControl component="fieldset">
              <RadioGroup
                value={matchSource}
                onChange={handleMatchSourceChange}
              >
                <FormControlLabel
                  value="title"
                  control={<Radio />}
                  label="タイトルのみ"
                />
                <FormControlLabel
                  value="description"
                  control={<Radio />}
                  label="商品説明のみ (AI生成部分のみ)"
                />
                <FormControlLabel
                  value="both"
                  control={<Radio />}
                  label="タイトルと商品説明の両方 (AI生成部分のみ)"
                />
              </RadioGroup>
            </FormControl>
          </Box>

          {/* オプション */}
          <Box sx={{ mt: 2 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={caseSensitive}
                  onChange={handleCaseSensitiveToggle}
                  name="caseSensitive"
                />
              }
              label="大文字小文字を区別する"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={partialMatch}
                  onChange={handlePartialMatchToggle}
                  name="partialMatch"
                />
              }
              label="部分一致を許可する"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={overwriteExisting}
                  onChange={handleOverwriteExistingToggle}
                  name="overwriteExisting"
                />
              }
              label="既存の値を上書きする"
            />
          </Box>

          {/* マッチングの動作を選択 */}
          <Box sx={{ mt: 2 }}>
            <Typography variant="subtitle1" gutterBottom>
              マッチングの動作を選択してください。
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              (上記でAI商品説明からマッチングを選択している場合は、自動処理でAI生成して文章からカラムの適所に適用されます。自動処理によって特に値の変更がなかった場合以下の設定が適用されます。)
            </Typography>
            <FormControl component="fieldset">
              <RadioGroup
                value={matchingOption}
                onChange={handleMatchingOptionChange}
              >
                {/* <FormControlLabel
                  value="first"
                  control={<Radio />}
                  label="マッチした最初の値のみを適用"
                /> */}
                <FormControlLabel
                  value="priority"
                  control={<Radio />}
                  label="優先順位に基づいて適用"
                />
                <FormControlLabel
                  value="all"
                  control={<Radio />}
                  label="マッチしたすべての値を適用"
                />
              </RadioGroup>
            </FormControl>
          </Box>
        </>
      )}

      {/* プレビューダイアログ */}
      <Dialog
        open={previewOpen}
        onClose={() => setPreviewOpen(false)}
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle>更新プレビュー</DialogTitle>
        <DialogContent>
          <Typography variant="body2" paragraph>
            以下の内容で更新されます。確認してください。
          </Typography>
          <Box sx={{ maxHeight: 400, overflow: "auto" }}>
            <table style={{ width: "100%", borderCollapse: "collapse" }}>
              <thead>
                <tr>
                  {previewData[0] &&
                    Object.keys(previewData[0]).map((header, index) => (
                      <th
                        key={index}
                        style={{
                          border: "1px solid #ccc",
                          padding: "8px",
                          backgroundColor: "#f5f5f5",
                          position: "sticky",
                          top: 0,
                        }}
                      >
                        {header}
                      </th>
                    ))}
                </tr>
              </thead>
              <tbody>
                {previewData.map((row, rowIndex) => (
                  <tr key={rowIndex}>
                    {Object.values(row).map((cell, cellIndex) => (
                      <td
                        key={cellIndex}
                        style={{ border: "1px solid #ccc", padding: "8px" }}
                      >
                        {cell}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </Box>
          {updatedData.length > 10 && (
            <Typography variant="body2" sx={{ mt: 2 }}>
              (表示は上位10件のみです。実際の更新はすべてのデータに適用されます。)
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setPreviewOpen(false)} color="secondary">
            キャンセル
          </Button>
          <Button onClick={confirmUpdate} color="primary" variant="contained">
            更新を確定
          </Button>
        </DialogActions>
      </Dialog>

      {/* スナックバー */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
          variant="filled"
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default ItemSpecificsApplier;





// // ItemSpecificsApplier.js

// import React, { useState, useEffect } from "react";
// import {
//   Button,
//   Typography,
//   Box,
//   CircularProgress,
//   Snackbar,
//   Alert,
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogTitle,
//   Checkbox,
//   FormControlLabel,
//   FormControl,
//   InputLabel,
//   Select,
//   MenuItem,
//   RadioGroup,
//   Radio,
//   Chip,
// } from "@mui/material";
// import { getFirestore, doc, getDoc } from "firebase/firestore";
// import {
//   Settings as SettingsIcon,
//   Refresh as RefreshIcon,
// } from "@mui/icons-material";

// const ItemSpecificsApplier = ({ data, setData, userId, headers }) => {
//   const [loading, setLoading] = useState(false);
//   const [snackbar, setSnackbar] = useState({
//     open: false,
//     message: "",
//     severity: "info",
//   });
//   const [categories, setCategories] = useState({});
//   const [selectedCategory, setSelectedCategory] = useState("");
//   const [selectedColumns, setSelectedColumns] = useState([]);
//   const [caseSensitive, setCaseSensitive] = useState(false);
//   const [partialMatch, setPartialMatch] = useState(true);
//   const [matchSource, setMatchSource] = useState("title");
//   const [matchingOption, setMatchingOption] = useState("first");
//   const [previewData, setPreviewData] = useState([]);
//   const [previewOpen, setPreviewOpen] = useState(false);
//   const [updatedData, setUpdatedData] = useState([]);

//   const db = getFirestore();

//   // Firestoreから設定を読み込む
//   const loadSettings = async () => {
//     if (!userId) {
//       setSnackbar({
//         open: true,
//         message: "ユーザー情報がありません。",
//         severity: "error",
//       });
//       return;
//     }

//     try {
//       const docRef = doc(db, "itemSpecificsSettings", userId);
//       const docSnap = await getDoc(docRef);

//       if (docSnap.exists()) {
//         const userSettings = docSnap.data();
//         setCategories(userSettings.categories || {});
//         setSnackbar({
//           open: true,
//           message: "設定を読み込みました。",
//           severity: "success",
//         });
//       } else {
//         setCategories({});
//         setSnackbar({
//           open: true,
//           message: "設定が見つかりません。",
//           severity: "info",
//         });
//       }
//     } catch (error) {
//       console.error("設定の読み込みに失敗しました:", error);
//       setSnackbar({
//         open: true,
//         message: "設定の読み込みに失敗しました。",
//         severity: "error",
//       });
//     }
//   };

//   // 初回マウント時に設定を読み込む
//   useEffect(() => {
//     loadSettings();
//   }, [userId]);

//   // 設定を再読み込みする関数
//   const handleReloadSettings = () => {
//     loadSettings();
//   };

//   // 大文字小文字区別の切り替え
//   const handleCaseSensitiveToggle = (event) => {
//     setCaseSensitive(event.target.checked);
//   };

//   // 部分一致の切り替え
//   const handlePartialMatchToggle = (event) => {
//     setPartialMatch(event.target.checked);
//   };

//   // マッチングソースの変更
//   const handleMatchSourceChange = (event) => {
//     setMatchSource(event.target.value);
//   };

//   // マッチングの動作を選択する関数
//   const handleMatchingOptionChange = (event) => {
//     setMatchingOption(event.target.value);
//   };

//   // DescriptionからAI生成部分を抽出する関数
//   const extractAIGeneratedContent = (description) => {
//     if (!description) return "";
//     const parser = new DOMParser();
//     const doc = parser.parseFromString(description, "text/html");
//     const aiContentDiv = doc.getElementById("ai-generated-content");
//     return aiContentDiv ? aiContentDiv.innerHTML : "";
//   };

//   // 商品説明から KEY: Value のペアを抽出する関数
//   const extractKeyValuePairs = (htmlContent) => {
//     const keyValuePairs = {};
//     if (!htmlContent) return keyValuePairs;

//     const parser = new DOMParser();
//     const doc = parser.parseFromString(htmlContent, "text/html");

//     // すべての <li> 要素を取得
//     const liElements = doc.querySelectorAll("li");

//     liElements.forEach((li) => {
//       let text = li.textContent.trim();

//       // 特殊文字や装飾を除去
//       text = text
//         .replace(/^\s*[-•✓]+[\s]*/, "") // 先頭の記号を除去
//         .replace(/\*\*/g, "") // マークダウンの強調を除去
//         .replace(/\s+/g, " "); // 複数のスペースを一つに

//       // コロンで分割
//       const colonIndex = text.indexOf(":");
//       if (colonIndex > -1) {
//         let key = text.substring(0, colonIndex).trim();
//         const value = text.substring(colonIndex + 1).trim();

//         // さらに特殊文字を除去
//         key = key.replace(/^\*+/, "").replace(/\*+$/, "");

//         if (key && value) {
//           keyValuePairs[key] = value;
//         }
//       }
//     });

//     return keyValuePairs;
//   };

//   // 単語が途中で切れないように64文字以内に調整する関数
//   const truncateValueByWords = (value, maxLength) => {
//     if (value.length <= maxLength) return value;

//     const words = value.split(" ");
//     let truncatedValue = "";
//     for (let i = 0; i < words.length; i++) {
//       const word = words[i];
//       const separator = truncatedValue ? " " : "";
//       if ((truncatedValue + separator + word).length > maxLength) {
//         break;
//       }
//       truncatedValue += separator + word;
//     }

//     return truncatedValue;
//   };

//   // データにItem Specificsを適用し、プレビューを表示する
//   const handleUpdateData = () => {
//     if (!selectedCategory) {
//       setSnackbar({
//         open: true,
//         message: "適用するカテゴリーを選択してください。",
//         severity: "warning",
//       });
//       return;
//     }

//     if (selectedColumns.length === 0) {
//       setSnackbar({
//         open: true,
//         message: "適用するカラムを選択してください。",
//         severity: "warning",
//       });
//       return;
//     }

//     setLoading(true);

//     try {
//       const titleKey = headers.find(
//         (header) => header.toLowerCase() === "title"
//       );
//       const descriptionKey = headers.find(
//         (header) => header.toLowerCase() === "description"
//       );

//       if (!titleKey && (matchSource === "title" || matchSource === "both")) {
//         throw new Error("「Title」カラムが見つかりません。");
//       }

//       if (
//         !descriptionKey &&
//         (matchSource === "description" || matchSource === "both")
//       ) {
//         throw new Error("「Description」カラムが見つかりません。");
//       }

//       const newData = data.map((item) => {
//         const title = item[titleKey] || "";
//         let description = item[descriptionKey] || "";
//         let sourceText = "";

//         // DescriptionからAI生成部分を抽出
//         const aiContent = extractAIGeneratedContent(description);

//         // マッチングソースに応じてテキストを設定
//         if (matchSource === "title") {
//           sourceText = title;
//         } else if (matchSource === "description") {
//           sourceText = aiContent;
//         } else if (matchSource === "both") {
//           sourceText = `${title} ${aiContent}`;
//         }

//         const newItem = { ...item };

//         // 商品説明から KEY: Value ペアを抽出
//         const keyValuePairs = extractKeyValuePairs(aiContent);

//         // 正規化関数を定義
//         const normalizeString = (str) =>
//           str
//             .toLowerCase()
//             .replace(/\s+/g, "")
//             .replace(/[^a-z0-9]/g, "");

//         // KEY とカラム名を照合して一致すれば値を設定
//         Object.keys(keyValuePairs).forEach((key) => {
//           const normalizedKey = normalizeString(key);
//           const matchingHeader = headers.find(
//             (header) =>
//               header.startsWith("C:") &&
//               normalizeString(header.slice(2)) === normalizedKey
//           );
//           if (matchingHeader) {
//             let valueToApply = keyValuePairs[key];

//             // 値を64文字以内に調整し、単語が途中で切れないようにする
//             valueToApply = truncateValueByWords(valueToApply, 64);

//             newItem[matchingHeader] = valueToApply;
//           }
//         });

//         // selectedColumnsに対してマッチング処理を行う
//         selectedColumns.forEach((column) => {
//           const value = newItem[column];

//           // 値が存在し、かつ空白や特定の文字列でない場合はスキップ
//           if (
//             value && // null や undefined でない
//             value.trim() !== "" && // 空白のみでない
//             value !== "NA" &&
//             value !== "N/A"
//           ) {
//             return; // 値が適切に設定されているのでスキップ
//           }

//           const columnData = categories[selectedCategory].columns[column];
//           const conversionRules =
//             (columnData && columnData.conversionRules) || [];
//           const values = (columnData && columnData.values) || [];

//           // マッチングアイテムのリストを作成（変換ルールと値）
//           const matchingItems = [
//             ...conversionRules.map((rule) => ({
//               type: "rule",
//               keyword: rule.keyword,
//               value: rule.value,
//             })),
//             ...values.map((value) => ({
//               type: "value",
//               keyword: value,
//               value,
//             })),
//           ];

//           const matchedValues = new Set();

//           // 正規表現の特殊文字をエスケープする関数
//           const escapeRegExp = (string) => {
//             return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
//           };

//           // マッチング処理
//           for (const item of matchingItems) {
//             const { keyword, value } = item;
//             if (!keyword || !value) continue;

//             const flags = caseSensitive ? "g" : "gi";
//             const escapedKeyword = escapeRegExp(keyword);
//             const regex = new RegExp(`\\b${escapedKeyword}\\b`, flags);

//             const matchCondition = partialMatch
//               ? regex.test(sourceText)
//               : caseSensitive
//               ? sourceText === keyword
//               : sourceText.toLowerCase() === keyword.toLowerCase();

//             if (matchCondition) {
//               matchedValues.add(value);
//               if (matchingOption === "first") break;
//               if (matchingOption === "priority") {
//                 let valueToApply = value;

//                 // 値を64文字以内に調整し、単語が途中で切れないようにする
//                 valueToApply = truncateValueByWords(valueToApply, 64);

//                 newItem[column] = valueToApply;
//                 break;
//               }
//             }
//           }

//           // 既存の値をチェック
//           const existingValue = newItem[column];

//           // 値が未設定、または "NA" や "N/A" の場合に値を適用
//           if (
//             matchedValues.size > 0 &&
//             (!existingValue || existingValue === "NA" || existingValue === "N/A")
//           ) {
//             let valueToApply;
//             if (matchingOption === "first" || matchingOption === "priority") {
//               valueToApply = matchedValues.values().next().value;
//             } else if (matchingOption === "all") {
//               valueToApply = Array.from(matchedValues).join(", ");
//             }

//             // 値を64文字以内に調整し、単語が途中で切れないようにする
//             valueToApply = truncateValueByWords(valueToApply, 64);

//             newItem[column] = valueToApply;
//           }
//         });

//         // keyValuePairsをnewItemに保存
//         newItem.__keyValuePairs = keyValuePairs;

//         return newItem;
//       });

//       setUpdatedData(newData);

//       // プレビュー用データを作成（上位10件のみ）
//       const previewItems = newData.slice(0, 10).map((item, index) => {
//         const originalItem = data[index];
//         const previewItem = { Title: item[titleKey] || "" };

//         if (matchSource !== "title") {
//           previewItem.Description = item[descriptionKey] || "";
//           // AI生成部分のみを抽出してプレビューに表示
//           previewItem["AI Generated Content"] = extractAIGeneratedContent(
//             item[descriptionKey]
//           );
//         }

//         selectedColumns.forEach((column) => {
//           previewItem[`${column} (Before)`] = originalItem[column] || "";
//           previewItem[`${column} (After)`] = item[column] || "";
//         });

//         // 商品説明から抽出した KEY: Value ペアもプレビューに追加
//         const keyValuePairs = item.__keyValuePairs || {};
//         Object.keys(keyValuePairs).forEach((key) => {
//           if (headers.includes(key)) {
//             previewItem[`${key} (Extracted)`] = keyValuePairs[key];
//           }
//         });

//         return previewItem;
//       });

//       setPreviewData(previewItems);
//       setPreviewOpen(true);
//     } catch (error) {
//       console.error("データの更新中にエラーが発生しました:", error);
//       setSnackbar({
//         open: true,
//         message: `データの更新中にエラーが発生しました: ${error.message}`,
//         severity: "error",
//       });
//     } finally {
//       setLoading(false);
//     }
//   };

//   // 更新を確定する関数
//   const confirmUpdate = () => {
//     if (updatedData.length === 0) {
//       setSnackbar({
//         open: true,
//         message: "更新データが存在しません。",
//         severity: "warning",
//       });
//       return;
//     }

//     setData(updatedData);
//     setSnackbar({
//       open: true,
//       message: "データが正常に更新されました。",
//       severity: "success",
//     });
//     setPreviewOpen(false);
//   };

//   // 適用するカテゴリーのカラムを取得
//   const getColumnsForSelectedCategory = () => {
//     if (selectedCategory && categories[selectedCategory]) {
//       return Object.keys(categories[selectedCategory].columns || {});
//     }
//     return [];
//   };

//   return (
//     <Box sx={{ p: 2, mt: 2 }}>
//       <Typography variant="h5" gutterBottom>
//         Item Specifics適用
//       </Typography>
//       <Typography variant="body1" paragraph>
//         設定したItem
//         Specificsをタイトルや商品説明に基づいて適用し、該当するカラムを更新します。
//       </Typography>
//       <Box
//         sx={{
//           mt: 2,
//           display: "flex",
//           flexDirection: { xs: "column", sm: "row" },
//           justifyContent: "space-between",
//           alignItems: "center",
//         }}
//       >
//         <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
//           <FormControl
//             fullWidth
//             variant="outlined"
//             size="small"
//             sx={{ minWidth: 200 }}
//           >
//             <InputLabel>適用するカテゴリー</InputLabel>
//             <Select
//               value={selectedCategory}
//               onChange={(e) => {
//                 setSelectedCategory(e.target.value);
//                 setSelectedColumns([]); // カテゴリー変更時に選択カラムをリセット
//               }}
//               label="適用するカテゴリー"
//             >
//               {Object.keys(categories).map((category) => (
//                 <MenuItem key={category} value={category}>
//                   {category}
//                 </MenuItem>
//               ))}
//             </Select>
//           </FormControl>
//           {selectedCategory && (
//             <FormControl
//               fullWidth
//               variant="outlined"
//               size="small"
//               sx={{ minWidth: 200 }}
//             >
//               <InputLabel>適用するカラムを選択</InputLabel>
//               <Select
//                 multiple
//                 value={selectedColumns}
//                 onChange={(e) => {
//                   const value = e.target.value;
//                   if (value.includes("all")) {
//                     // 「全選択」が選択された場合
//                     if (
//                       selectedColumns.length ===
//                       getColumnsForSelectedCategory().length
//                     ) {
//                       // すでにすべて選択されている場合は選択を解除
//                       setSelectedColumns([]);
//                     } else {
//                       // すべてのカラムを選択
//                       setSelectedColumns(getColumnsForSelectedCategory());
//                     }
//                   } else {
//                     setSelectedColumns(value);
//                   }
//                 }}
//                 label="適用するカラムを選択"
//                 renderValue={(selected) => {
//                   if (selected.length === 0) {
//                     return "適用するカラムを選択";
//                   }

//                   const displayedValues = selected.slice(0, 5); // 最初の5つを表示
//                   const hiddenCount = selected.length - displayedValues.length;

//                   return (
//                     <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
//                       {displayedValues.map((value) => (
//                         <Chip key={value} label={value} size="small" />
//                       ))}
//                       {hiddenCount > 0 && (
//                         <Chip label={`+${hiddenCount}個`} size="small" />
//                       )}
//                     </Box>
//                   );
//                 }}
//               >
//                 <MenuItem
//                   value="all"
//                   onClick={() => {
//                     if (
//                       selectedColumns.length ===
//                       getColumnsForSelectedCategory().length
//                     ) {
//                       // すでにすべて選択されている場合は選択を解除
//                       setSelectedColumns([]);
//                     } else {
//                       // すべてのカラムを選択
//                       setSelectedColumns(getColumnsForSelectedCategory());
//                     }
//                   }}
//                 >
//                   <Checkbox
//                     checked={
//                       selectedColumns.length ===
//                         getColumnsForSelectedCategory().length &&
//                       selectedColumns.length > 0
//                     }
//                     indeterminate={
//                       selectedColumns.length > 0 &&
//                       selectedColumns.length <
//                         getColumnsForSelectedCategory().length
//                     }
//                   />
//                   <Typography variant="body1">全選択</Typography>
//                 </MenuItem>
//                 {getColumnsForSelectedCategory().map((column) => (
//                   <MenuItem key={column} value={column}>
//                     <Checkbox checked={selectedColumns.indexOf(column) > -1} />
//                     <Typography variant="body1">{column}</Typography>
//                   </MenuItem>
//                 ))}
//               </Select>
//             </FormControl>
//           )}
//           <Button
//             startIcon={<SettingsIcon />}
//             onClick={handleUpdateData}
//             variant="contained"
//             color="primary"
//             disabled={loading}
//           >
//             {loading ? <CircularProgress size={24} /> : "データを更新"}
//           </Button>
//           <Button
//             startIcon={<RefreshIcon />}
//             onClick={handleReloadSettings}
//             variant="outlined"
//             color="secondary"
//           >
//             データ再読み込み
//           </Button>
//         </Box>
//       </Box>

//       {/* マッチングソースの選択 */}
//       {selectedCategory && (
//         <>
//           <Box sx={{ mt: 2 }}>
//             <Typography variant="subtitle1" gutterBottom>
//               マッチングの基準を選択してください。
//             </Typography>
//             <FormControl component="fieldset">
//               <RadioGroup
//                 value={matchSource}
//                 onChange={handleMatchSourceChange}
//               >
//                 <FormControlLabel
//                   value="title"
//                   control={<Radio />}
//                   label="タイトルのみ"
//                 />
//                 <FormControlLabel
//                   value="description"
//                   control={<Radio />}
//                   label="商品説明のみ (AI生成部分のみ)"
//                 />
//                 <FormControlLabel
//                   value="both"
//                   control={<Radio />}
//                   label="タイトルと商品説明の両方 (AI生成部分のみ)"
//                 />
//               </RadioGroup>
//             </FormControl>
//           </Box>

//           {/* オプション */}
//           <Box sx={{ mt: 2 }}>
//             <FormControlLabel
//               control={
//                 <Checkbox
//                   checked={caseSensitive}
//                   onChange={handleCaseSensitiveToggle}
//                   name="caseSensitive"
//                 />
//               }
//               label="大文字小文字を区別する"
//             />
//             <FormControlLabel
//               control={
//                 <Checkbox
//                   checked={partialMatch}
//                   onChange={handlePartialMatchToggle}
//                   name="partialMatch"
//                 />
//               }
//               label="部分一致を許可する"
//             />
//           </Box>

//           {/* マッチングの動作を選択 */}
//           <Box sx={{ mt: 2 }}>
//             <Typography variant="subtitle1" gutterBottom>
//               マッチングの動作を選択してください。
//             </Typography>
//             <Typography variant="subtitle1" gutterBottom>
//               (上記でAI商品説明からマッチングを選択している場合は、自動処理でAI生成して文章からカラムの適所に適用されます。自動処理によって特に値の変更がなかった場合以下の設定が適用されます。)
//             </Typography>
//             <FormControl component="fieldset">
//               <RadioGroup
//                 value={matchingOption}
//                 onChange={handleMatchingOptionChange}
//               >
//                 {/* <FormControlLabel
//                   value="first"
//                   control={<Radio />}
//                   label="マッチした最初の値のみを適用"
//                 /> */}
//                 <FormControlLabel
//                   value="priority"
//                   control={<Radio />}
//                   label="優先順位に基づいて適用"
//                 />
//                 <FormControlLabel
//                   value="all"
//                   control={<Radio />}
//                   label="マッチしたすべての値を適用"
//                 />
//               </RadioGroup>
//             </FormControl>
//           </Box>
//         </>
//       )}

//       {/* プレビューダイアログ */}
//       <Dialog
//         open={previewOpen}
//         onClose={() => setPreviewOpen(false)}
//         maxWidth="lg"
//         fullWidth
//       >
//         <DialogTitle>更新プレビュー</DialogTitle>
//         <DialogContent>
//           <Typography variant="body2" paragraph>
//             以下の内容で更新されます。確認してください。
//           </Typography>
//           <Box sx={{ maxHeight: 400, overflow: "auto" }}>
//             <table style={{ width: "100%", borderCollapse: "collapse" }}>
//               <thead>
//                 <tr>
//                   {previewData[0] &&
//                     Object.keys(previewData[0]).map((header, index) => (
//                       <th
//                         key={index}
//                         style={{
//                           border: "1px solid #ccc",
//                           padding: "8px",
//                           backgroundColor: "#f5f5f5",
//                           position: "sticky",
//                           top: 0,
//                         }}
//                       >
//                         {header}
//                       </th>
//                     ))}
//                 </tr>
//               </thead>
//               <tbody>
//                 {previewData.map((row, rowIndex) => (
//                   <tr key={rowIndex}>
//                     {Object.values(row).map((cell, cellIndex) => (
//                       <td
//                         key={cellIndex}
//                         style={{ border: "1px solid #ccc", padding: "8px" }}
//                       >
//                         {cell}
//                       </td>
//                     ))}
//                   </tr>
//                 ))}
//               </tbody>
//             </table>
//           </Box>
//           {updatedData.length > 10 && (
//             <Typography variant="body2" sx={{ mt: 2 }}>
//               (表示は上位10件のみです。実際の更新はすべてのデータに適用されます。)
//             </Typography>
//           )}
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => setPreviewOpen(false)} color="secondary">
//             キャンセル
//           </Button>
//           <Button onClick={confirmUpdate} color="primary" variant="contained">
//             更新を確定
//           </Button>
//         </DialogActions>
//       </Dialog>

//       {/* スナックバー */}
//       <Snackbar
//         open={snackbar.open}
//         autoHideDuration={6000}
//         onClose={() => setSnackbar({ ...snackbar, open: false })}
//         anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
//       >
//         <Alert
//           onClose={() => setSnackbar({ ...snackbar, open: false })}
//           severity={snackbar.severity}
//           variant="filled"
//         >
//           {snackbar.message}
//         </Alert>
//       </Snackbar>
//     </Box>
//   );
// };

// export default ItemSpecificsApplier;





// // ItemSpecificsApplier.js

// import React, { useState, useEffect } from "react";
// import {
//   Button,
//   Typography,
//   Box,
//   CircularProgress,
//   Snackbar,
//   Alert,
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogTitle,
//   Checkbox,
//   FormControlLabel,
//   FormControl,
//   InputLabel,
//   Select,
//   MenuItem,
//   RadioGroup,
//   Radio,
//   Chip,
// } from "@mui/material";
// import { getFirestore, doc, getDoc } from "firebase/firestore";
// import {
//   Settings as SettingsIcon,
//   Refresh as RefreshIcon,
// } from "@mui/icons-material";

// const ItemSpecificsApplier = ({ data, setData, userId, headers }) => {
//   const [loading, setLoading] = useState(false);
//   const [snackbar, setSnackbar] = useState({
//     open: false,
//     message: "",
//     severity: "info",
//   });
//   const [categories, setCategories] = useState({});
//   const [selectedCategory, setSelectedCategory] = useState("");
//   const [selectedColumns, setSelectedColumns] = useState([]);
//   const [caseSensitive, setCaseSensitive] = useState(false);
//   const [partialMatch, setPartialMatch] = useState(true);
//   const [matchSource, setMatchSource] = useState("title");
//   const [matchingOption, setMatchingOption] = useState("first");
//   const [previewData, setPreviewData] = useState([]);
//   const [previewOpen, setPreviewOpen] = useState(false);
//   const [updatedData, setUpdatedData] = useState([]);

//   const db = getFirestore();

//   // Firestoreから設定を読み込む
//   const loadSettings = async () => {
//     if (!userId) {
//       setSnackbar({
//         open: true,
//         message: "ユーザー情報がありません。",
//         severity: "error",
//       });
//       return;
//     }

//     try {
//       const docRef = doc(db, "itemSpecificsSettings", userId);
//       const docSnap = await getDoc(docRef);

//       if (docSnap.exists()) {
//         const userSettings = docSnap.data();
//         setCategories(userSettings.categories || {});
//         setSnackbar({
//           open: true,
//           message: "設定を読み込みました。",
//           severity: "success",
//         });
//       } else {
//         setCategories({});
//         setSnackbar({
//           open: true,
//           message: "設定が見つかりません。",
//           severity: "info",
//         });
//       }
//     } catch (error) {
//       console.error("設定の読み込みに失敗しました:", error);
//       setSnackbar({
//         open: true,
//         message: "設定の読み込みに失敗しました。",
//         severity: "error",
//       });
//     }
//   };

//   // 初回マウント時に設定を読み込む
//   useEffect(() => {
//     loadSettings();
//   }, [userId]);

//   // 設定を再読み込みする関数
//   const handleReloadSettings = () => {
//     loadSettings();
//   };

//   // 大文字小文字区別の切り替え
//   const handleCaseSensitiveToggle = (event) => {
//     setCaseSensitive(event.target.checked);
//   };

//   // 部分一致の切り替え
//   const handlePartialMatchToggle = (event) => {
//     setPartialMatch(event.target.checked);
//   };

//   // マッチングソースの変更
//   const handleMatchSourceChange = (event) => {
//     setMatchSource(event.target.value);
//   };

//   // マッチングの動作を選択する関数
//   const handleMatchingOptionChange = (event) => {
//     setMatchingOption(event.target.value);
//   };

//   // DescriptionからAI生成部分を抽出する関数
//   const extractAIGeneratedContent = (description) => {
//     if (!description) return "";
//     const parser = new DOMParser();
//     const doc = parser.parseFromString(description, "text/html");
//     const aiContentDiv = doc.getElementById("ai-generated-content");
//     return aiContentDiv ? aiContentDiv.innerHTML : "";
//   };

//   // 商品説明から KEY: Value のペアを抽出する関数
//   const extractKeyValuePairs = (htmlContent) => {
//     const keyValuePairs = {};
//     if (!htmlContent) return keyValuePairs;

//     const parser = new DOMParser();
//     const doc = parser.parseFromString(htmlContent, "text/html");

//     // すべての <li> 要素を取得
//     const liElements = doc.querySelectorAll("li");

//     liElements.forEach((li) => {
//       let text = li.textContent.trim();

//       // 特殊文字や装飾を除去
//       text = text
//         .replace(/^\s*[-•✓]+[\s]*/, "") // 先頭の記号を除去
//         .replace(/\*\*/g, "") // マークダウンの強調を除去
//         .replace(/\s+/g, " "); // 複数のスペースを一つに

//       // コロンで分割
//       const colonIndex = text.indexOf(":");
//       if (colonIndex > -1) {
//         let key = text.substring(0, colonIndex).trim();
//         const value = text.substring(colonIndex + 1).trim();

//         // さらに特殊文字を除去
//         key = key.replace(/^\*+/, "").replace(/\*+$/, "");

//         if (key && value) {
//           keyValuePairs[key] = value;
//         }
//       }
//     });

//     return keyValuePairs;
//   };

//   // データにItem Specificsを適用し、プレビューを表示する
//   const handleUpdateData = () => {
//     if (!selectedCategory) {
//       setSnackbar({
//         open: true,
//         message: "適用するカテゴリーを選択してください。",
//         severity: "warning",
//       });
//       return;
//     }

//     if (selectedColumns.length === 0) {
//       setSnackbar({
//         open: true,
//         message: "適用するカラムを選択してください。",
//         severity: "warning",
//       });
//       return;
//     }

//     setLoading(true);

//     try {
//       const titleKey = headers.find(
//         (header) => header.toLowerCase() === "title"
//       );
//       const descriptionKey = headers.find(
//         (header) => header.toLowerCase() === "description"
//       );

//       if (!titleKey && (matchSource === "title" || matchSource === "both")) {
//         throw new Error("「Title」カラムが見つかりません。");
//       }

//       if (
//         !descriptionKey &&
//         (matchSource === "description" || matchSource === "both")
//       ) {
//         throw new Error("「Description」カラムが見つかりません。");
//       }

//       const newData = data.map((item) => {
//         const title = item[titleKey] || "";
//         let description = item[descriptionKey] || "";
//         let sourceText = "";

//         // DescriptionからAI生成部分を抽出
//         const aiContent = extractAIGeneratedContent(description);

//         // マッチングソースに応じてテキストを設定
//         if (matchSource === "title") {
//           sourceText = title;
//         } else if (matchSource === "description") {
//           sourceText = aiContent;
//         } else if (matchSource === "both") {
//           sourceText = `${title} ${aiContent}`;
//         }

//         const newItem = { ...item };

//         // 商品説明から KEY: Value ペアを抽出
//         const keyValuePairs = extractKeyValuePairs(aiContent);

//         // 正規化関数を定義
//         const normalizeString = (str) =>
//           str
//             .toLowerCase()
//             .replace(/\s+/g, "")
//             .replace(/[^a-z0-9]/g, "");

//         // KEY とカラム名を照合して一致すれば値を設定
//         Object.keys(keyValuePairs).forEach((key) => {
//           const normalizedKey = normalizeString(key);
//           const matchingHeader = headers.find(
//             (header) =>
//               header.startsWith("C:") &&
//               normalizeString(header.slice(2)) === normalizedKey
//           );
//           if (matchingHeader) {
//             newItem[matchingHeader] = keyValuePairs[key];
//           }
//         });

//         // 既存の機能による処理
//         selectedColumns.forEach((column) => {
//           const value = newItem[column];
        
//           // 値が存在し、かつ空白や特定の文字列でない場合はスキップ
//           if (
//             value && // null や undefined でない
//             value.trim() !== "" && // 空白のみでない
//             value !== "NA" &&
//             value !== "N/A"
//           ) {
//             return; // 値が適切に設定されているのでスキップ
//           }

//           const columnData = categories[selectedCategory].columns[column];
//           const conversionRules =
//             (columnData && columnData.conversionRules) || [];
//           const values = (columnData && columnData.values) || [];

//           // マッチングアイテムのリストを作成（変換ルールと値）
//           const matchingItems = [
//             ...conversionRules.map((rule) => ({
//               type: "rule",
//               keyword: rule.keyword,
//               value: rule.value,
//             })),
//             ...values.map((value) => ({
//               type: "value",
//               keyword: value,
//               value,
//             })),
//           ];

//           const matchedValues = new Set();

//           // 正規表現の特殊文字をエスケープする関数
//           const escapeRegExp = (string) => {
//             return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
//           };

//           // マッチング処理
//           for (const item of matchingItems) {
//             const { keyword, value } = item;
//             if (!keyword || !value) continue;

//             const flags = caseSensitive ? "g" : "gi";
//             const escapedKeyword = escapeRegExp(keyword);
//             const regex = new RegExp(`\\b${escapedKeyword}\\b`, flags);

//             const matchCondition = partialMatch
//               ? regex.test(sourceText)
//               : caseSensitive
//               ? sourceText === keyword
//               : sourceText.toLowerCase() === keyword.toLowerCase();

//             if (matchCondition) {
//               matchedValues.add(value);
//               if (matchingOption === "first") break;
//               if (matchingOption === "priority") {
//                 newItem[column] = value;
//                 break;
//               }
//             }
//           }

//           // 既存の値をチェック
//           const existingValue = newItem[column];

//           // 値が未設定、または "NA" や "N/A" の場合に値を適用
//           if (
//             matchedValues.size > 0 &&
//             (!existingValue || existingValue === "NA" || existingValue === "N/A")
//           ) {
//             if (matchingOption === "first" || matchingOption === "priority") {
//               newItem[column] = matchedValues.values().next().value;
//             } else if (matchingOption === "all") {
//               newItem[column] = Array.from(matchedValues).join(", ");
//             }
//           }
//         });

//         // keyValuePairsをnewItemに保存
//         newItem.__keyValuePairs = keyValuePairs;

//         return newItem;
//       });

//       setUpdatedData(newData);

//       // プレビュー用データを作成（上位10件のみ）
//       const previewItems = newData.slice(0, 10).map((item, index) => {
//         const originalItem = data[index];
//         const previewItem = { Title: item[titleKey] || "" };

//         if (matchSource !== "title") {
//           previewItem.Description = item[descriptionKey] || "";
//           // AI生成部分のみを抽出してプレビューに表示
//           previewItem["AI Generated Content"] = extractAIGeneratedContent(
//             item[descriptionKey]
//           );
//         }

//         selectedColumns.forEach((column) => {
//           previewItem[`${column} (Before)`] = originalItem[column] || "";
//           previewItem[`${column} (After)`] = item[column] || "";
//         });

//         // 商品説明から抽出した KEY: Value ペアもプレビューに追加
//         const keyValuePairs = item.__keyValuePairs || {};
//         Object.keys(keyValuePairs).forEach((key) => {
//           if (headers.includes(key)) {
//             previewItem[`${key} (Extracted)`] = keyValuePairs[key];
//           }
//         });

//         return previewItem;
//       });

//       setPreviewData(previewItems);
//       setPreviewOpen(true);
//     } catch (error) {
//       console.error("データの更新中にエラーが発生しました:", error);
//       setSnackbar({
//         open: true,
//         message: `データの更新中にエラーが発生しました: ${error.message}`,
//         severity: "error",
//       });
//     } finally {
//       setLoading(false);
//     }
//   };

//   // 更新を確定する関数
//   const confirmUpdate = () => {
//     if (updatedData.length === 0) {
//       setSnackbar({
//         open: true,
//         message: "更新データが存在しません。",
//         severity: "warning",
//       });
//       return;
//     }

//     setData(updatedData);
//     setSnackbar({
//       open: true,
//       message: "データが正常に更新されました。",
//       severity: "success",
//     });
//     setPreviewOpen(false);
//   };

//   // 適用するカテゴリーのカラムを取得
//   const getColumnsForSelectedCategory = () => {
//     if (selectedCategory && categories[selectedCategory]) {
//       return Object.keys(categories[selectedCategory].columns || {});
//     }
//     return [];
//   };

//   return (
//     <Box sx={{ p: 2, mt: 2 }}>
//       <Typography variant="h5" gutterBottom>
//         Item Specifics適用
//       </Typography>
//       <Typography variant="body1" paragraph>
//         設定したItem
//         Specificsをタイトルや商品説明に基づいて適用し、該当するカラムを更新します。
//       </Typography>
//       <Box
//         sx={{
//           mt: 2,
//           display: "flex",
//           flexDirection: { xs: "column", sm: "row" },
//           justifyContent: "space-between",
//           alignItems: "center",
//         }}
//       >
//         <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
//           <FormControl
//             fullWidth
//             variant="outlined"
//             size="small"
//             sx={{ minWidth: 200 }}
//           >
//             <InputLabel>適用するカテゴリー</InputLabel>
//             <Select
//               value={selectedCategory}
//               onChange={(e) => {
//                 setSelectedCategory(e.target.value);
//                 setSelectedColumns([]); // カテゴリー変更時に選択カラムをリセット
//               }}
//               label="適用するカテゴリー"
//             >
//               {Object.keys(categories).map((category) => (
//                 <MenuItem key={category} value={category}>
//                   {category}
//                 </MenuItem>
//               ))}
//             </Select>
//           </FormControl>
//           {selectedCategory && (
//             <FormControl
//               fullWidth
//               variant="outlined"
//               size="small"
//               sx={{ minWidth: 200 }}
//             >
//               <InputLabel>適用するカラムを選択</InputLabel>
//               <Select
//                 multiple
//                 value={selectedColumns}
//                 onChange={(e) => {
//                   const value = e.target.value;
//                   if (value.includes("all")) {
//                     // 「全選択」が選択された場合
//                     if (
//                       selectedColumns.length ===
//                       getColumnsForSelectedCategory().length
//                     ) {
//                       // すでにすべて選択されている場合は選択を解除
//                       setSelectedColumns([]);
//                     } else {
//                       // すべてのカラムを選択
//                       setSelectedColumns(getColumnsForSelectedCategory());
//                     }
//                   } else {
//                     setSelectedColumns(value);
//                   }
//                 }}
//                 label="適用するカラムを選択"
//                 renderValue={(selected) => {
//                   if (selected.length === 0) {
//                     return "適用するカラムを選択";
//                   }

//                   const displayedValues = selected.slice(0, 5); // 最初の5つを表示
//                   const hiddenCount = selected.length - displayedValues.length;

//                   return (
//                     <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
//                       {displayedValues.map((value) => (
//                         <Chip key={value} label={value} size="small" />
//                       ))}
//                       {hiddenCount > 0 && (
//                         <Chip label={`+${hiddenCount}個`} size="small" />
//                       )}
//                     </Box>
//                   );
//                 }}
//               >
//                 <MenuItem
//                   value="all"
//                   onClick={() => {
//                     if (
//                       selectedColumns.length ===
//                       getColumnsForSelectedCategory().length
//                     ) {
//                       // すでにすべて選択されている場合は選択を解除
//                       setSelectedColumns([]);
//                     } else {
//                       // すべてのカラムを選択
//                       setSelectedColumns(getColumnsForSelectedCategory());
//                     }
//                   }}
//                 >
//                   <Checkbox
//                     checked={
//                       selectedColumns.length ===
//                         getColumnsForSelectedCategory().length &&
//                       selectedColumns.length > 0
//                     }
//                     indeterminate={
//                       selectedColumns.length > 0 &&
//                       selectedColumns.length <
//                         getColumnsForSelectedCategory().length
//                     }
//                   />
//                   <Typography variant="body1">全選択</Typography>
//                 </MenuItem>
//                 {getColumnsForSelectedCategory().map((column) => (
//                   <MenuItem key={column} value={column}>
//                     <Checkbox checked={selectedColumns.indexOf(column) > -1} />
//                     <Typography variant="body1">{column}</Typography>
//                   </MenuItem>
//                 ))}
//               </Select>
//             </FormControl>
//           )}
//           <Button
//             startIcon={<SettingsIcon />}
//             onClick={handleUpdateData}
//             variant="contained"
//             color="primary"
//             disabled={loading}
//           >
//             {loading ? <CircularProgress size={24} /> : "データを更新"}
//           </Button>
//           <Button
//             startIcon={<RefreshIcon />}
//             onClick={handleReloadSettings}
//             variant="outlined"
//             color="secondary"
//           >
//             データ再読み込み
//           </Button>
//         </Box>
//       </Box>

//       {/* マッチングソースの選択 */}
//       {selectedCategory && (
//         <>
//           <Box sx={{ mt: 2 }}>
//             <Typography variant="subtitle1" gutterBottom>
//               マッチングの基準を選択してください。
//             </Typography>
//             <FormControl component="fieldset">
//               <RadioGroup
//                 value={matchSource}
//                 onChange={handleMatchSourceChange}
//               >
//                 <FormControlLabel
//                   value="title"
//                   control={<Radio />}
//                   label="タイトルのみ"
//                 />
//                 <FormControlLabel
//                   value="description"
//                   control={<Radio />}
//                   label="商品説明のみ (AI生成部分のみ)"
//                 />
//                 <FormControlLabel
//                   value="both"
//                   control={<Radio />}
//                   label="タイトルと商品説明の両方 (AI生成部分のみ)"
//                 />
//               </RadioGroup>
//             </FormControl>
//           </Box>

//           {/* オプション */}
//           <Box sx={{ mt: 2 }}>
//             <FormControlLabel
//               control={
//                 <Checkbox
//                   checked={caseSensitive}
//                   onChange={handleCaseSensitiveToggle}
//                   name="caseSensitive"
//                 />
//               }
//               label="大文字小文字を区別する"
//             />
//             <FormControlLabel
//               control={
//                 <Checkbox
//                   checked={partialMatch}
//                   onChange={handlePartialMatchToggle}
//                   name="partialMatch"
//                 />
//               }
//               label="部分一致を許可する"
//             />
//           </Box>

//           {/* マッチングの動作を選択 */}
//           <Box sx={{ mt: 2 }}>
//             <Typography variant="subtitle1" gutterBottom>
//               マッチングの動作を選択してください。
//             </Typography>
//             <Typography variant="subtitle1" gutterBottom>
//               (上記でAI商品説明からマッチングを選択している場合は、自動処理でAI生成して文章からカラムの適所に適用されます。自動処理によって特に値の変更がなかった場合以下の設定が適用されます。)
//             </Typography>
//             <FormControl component="fieldset">
//               <RadioGroup
//                 value={matchingOption}
//                 onChange={handleMatchingOptionChange}
//               >
//                 {/* <FormControlLabel
//                   value="first"
//                   control={<Radio />}
//                   label="マッチした最初の値のみを適用"
//                 /> */}
//                 <FormControlLabel
//                   value="priority"
//                   control={<Radio />}
//                   label="優先順位に基づいて適用"
//                 />
//                 <FormControlLabel
//                   value="all"
//                   control={<Radio />}
//                   label="マッチしたすべての値を適用"
//                 />
//               </RadioGroup>
//             </FormControl>
//           </Box>
//         </>
//       )}

//       {/* プレビューダイアログ */}
//       <Dialog
//         open={previewOpen}
//         onClose={() => setPreviewOpen(false)}
//         maxWidth="lg"
//         fullWidth
//       >
//         <DialogTitle>更新プレビュー</DialogTitle>
//         <DialogContent>
//           <Typography variant="body2" paragraph>
//             以下の内容で更新されます。確認してください。
//           </Typography>
//           <Box sx={{ maxHeight: 400, overflow: "auto" }}>
//             <table style={{ width: "100%", borderCollapse: "collapse" }}>
//               <thead>
//                 <tr>
//                   {previewData[0] &&
//                     Object.keys(previewData[0]).map((header, index) => (
//                       <th
//                         key={index}
//                         style={{
//                           border: "1px solid #ccc",
//                           padding: "8px",
//                           backgroundColor: "#f5f5f5",
//                           position: "sticky",
//                           top: 0,
//                         }}
//                       >
//                         {header}
//                       </th>
//                     ))}
//                 </tr>
//               </thead>
//               <tbody>
//                 {previewData.map((row, rowIndex) => (
//                   <tr key={rowIndex}>
//                     {Object.values(row).map((cell, cellIndex) => (
//                       <td
//                         key={cellIndex}
//                         style={{ border: "1px solid #ccc", padding: "8px" }}
//                       >
//                         {cell}
//                       </td>
//                     ))}
//                   </tr>
//                 ))}
//               </tbody>
//             </table>
//           </Box>
//           {updatedData.length > 10 && (
//             <Typography variant="body2" sx={{ mt: 2 }}>
//               (表示は上位10件のみです。実際の更新はすべてのデータに適用されます。)
//             </Typography>
//           )}
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => setPreviewOpen(false)} color="secondary">
//             キャンセル
//           </Button>
//           <Button onClick={confirmUpdate} color="primary" variant="contained">
//             更新を確定
//           </Button>
//         </DialogActions>
//       </Dialog>

//       {/* スナックバー */}
//       <Snackbar
//         open={snackbar.open}
//         autoHideDuration={6000}
//         onClose={() => setSnackbar({ ...snackbar, open: false })}
//         anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
//       >
//         <Alert
//           onClose={() => setSnackbar({ ...snackbar, open: false })}
//           severity={snackbar.severity}
//           variant="filled"
//         >
//           {snackbar.message}
//         </Alert>
//       </Snackbar>
//     </Box>
//   );
// };

// export default ItemSpecificsApplier;






// // ItemSpecificsApplier.js

// import React, { useState, useEffect } from 'react';
// import {
//   Button,
//   Typography,
//   Box,
//   CircularProgress,
//   Snackbar,
//   Alert,
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogTitle,
//   Checkbox,
//   FormControlLabel,
//   FormControl,
//   InputLabel,
//   Select,
//   MenuItem,
//   RadioGroup,
//   Radio,
//   Autocomplete,
// } from '@mui/material';
// import {
//   getFirestore,
//   doc,
//   getDoc,
// } from 'firebase/firestore';
// import {
//   Add as AddIcon,
//   Save as SaveIcon,
//   Delete as DeleteIcon,
//   Settings as SettingsIcon,
//   Refresh as RefreshIcon,
// } from '@mui/icons-material';

// const ItemSpecificsApplier = ({ data, setData, userId, headers }) => {
//   const [loading, setLoading] = useState(false);
//   const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
//   const [categories, setCategories] = useState({});
//   const [selectedCategory, setSelectedCategory] = useState('');
//   const [selectedColumns, setSelectedColumns] = useState([]);
//   const [caseSensitive, setCaseSensitive] = useState(false);
//   const [partialMatch, setPartialMatch] = useState(true);
//   const [matchSource, setMatchSource] = useState('title');
//   const [matchingOption, setMatchingOption] = useState('first');
//   const [previewData, setPreviewData] = useState([]);
//   const [previewOpen, setPreviewOpen] = useState(false);
//   const [updatedData, setUpdatedData] = useState([]);

//   const db = getFirestore();

//   // Firestoreから設定を読み込む
//   const loadSettings = async () => {
//     if (!userId) {
//       setSnackbar({ open: true, message: 'ユーザー情報がありません。', severity: 'error' });
//       return;
//     }

//     try {
//       const docRef = doc(db, 'itemSpecificsSettings', userId);
//       const docSnap = await getDoc(docRef);

//       if (docSnap.exists()) {
//         const userSettings = docSnap.data();
//         setCategories(userSettings.categories || {});
//         setSnackbar({ open: true, message: '設定を読み込みました。', severity: 'success' });
//       } else {
//         setCategories({});
//         setSnackbar({ open: true, message: '設定が見つかりません。', severity: 'info' });
//       }
//     } catch (error) {
//       console.error('設定の読み込みに失敗しました:', error);
//       setSnackbar({ open: true, message: '設定の読み込みに失敗しました。', severity: 'error' });
//     }
//   };

//   // 初回マウント時に設定を読み込む
//   useEffect(() => {
//     loadSettings();
//   }, [userId]);

//   // 設定を再読み込みする関数
//   const handleReloadSettings = () => {
//     loadSettings();
//   };

//   // 大文字小文字区別の切り替え
//   const handleCaseSensitiveToggle = (event) => {
//     setCaseSensitive(event.target.checked);
//   };

//   // 部分一致の切り替え
//   const handlePartialMatchToggle = (event) => {
//     setPartialMatch(event.target.checked);
//   };

//   // マッチングソースの変更
//   const handleMatchSourceChange = (event) => {
//     setMatchSource(event.target.value);
//   };

//   // マッチングの動作を選択する関数
//   const handleMatchingOptionChange = (event) => {
//     setMatchingOption(event.target.value);
//   };

//   // DescriptionからAI生成部分を抽出する関数
//   const extractAIGeneratedContent = (description) => {
//     if (!description) return '';
//     const regex = /<div id="ai-generated-content">(.*?)<\/div>/is;
//     const match = description.match(regex);
//     if (match && match[1]) {
//       // マッチした部分のHTMLタグを除去してテキストのみを取得
//       const tempDiv = document.createElement('div');
//       tempDiv.innerHTML = match[1];
//       return tempDiv.textContent || tempDiv.innerText || '';
//     }
//     return '';
//   };

//   // データにItem Specificsを適用し、プレビューを表示する
//   const handleUpdateData = () => {
//     if (!selectedCategory) {
//       setSnackbar({ open: true, message: '適用するカテゴリーを選択してください。', severity: 'warning' });
//       return;
//     }

//     if (selectedColumns.length === 0) {
//       setSnackbar({ open: true, message: '適用するカラムを選択してください。', severity: 'warning' });
//       return;
//     }

//     setLoading(true);

//     try {
//       const titleKey = headers.find((header) => header.toLowerCase() === 'title');
//       const descriptionKey = headers.find((header) => header.toLowerCase() === 'description');

//       if (!titleKey && (matchSource === 'title' || matchSource === 'both')) {
//         throw new Error('「Title」カラムが見つかりません。');
//       }

//       if (!descriptionKey && (matchSource === 'description' || matchSource === 'both')) {
//         throw new Error('「Description」カラムが見つかりません。');
//       }

//       const newData = data.map((item) => {
//         const title = item[titleKey] || '';
//         let description = item[descriptionKey] || '';
//         let sourceText = '';

//         // マッチングソースに応じてテキストを設定
//         if (matchSource === 'title') {
//           sourceText = title;
//         } else if (matchSource === 'description') {
//           // DescriptionからAI生成部分を抽出
//           const aiContent = extractAIGeneratedContent(description);
//           sourceText = aiContent;
//         } else if (matchSource === 'both') {
//           // DescriptionからAI生成部分を抽出
//           const aiContent = extractAIGeneratedContent(description);
//           sourceText = `${title} ${aiContent}`;
//         }

//         const newItem = { ...item };

//         selectedColumns.forEach((column) => {
//           const columnData = categories[selectedCategory].columns[column];
//           const conversionRules = (columnData && columnData.conversionRules) || [];
//           const values = (columnData && columnData.values) || [];

//           // マッチングアイテムのリストを作成（変換ルールと値）
//           const matchingItems = [
//             ...conversionRules.map((rule) => ({ type: 'rule', keyword: rule.keyword, value: rule.value })),
//             ...values.map((value) => ({ type: 'value', keyword: value, value })),
//           ];

//           const matchedValues = new Set();

//           // 正規表現の特殊文字をエスケープする関数
//           const escapeRegExp = (string) => {
//             return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
//           };

//           // マッチング処理
//           for (const item of matchingItems) {
//             const { keyword, value } = item;
//             if (!keyword || !value) continue;

//             const flags = caseSensitive ? 'g' : 'gi';
//             const escapedKeyword = escapeRegExp(keyword);
//             const regex = new RegExp(`\\b${escapedKeyword}\\b`, flags);

//             const matchCondition = partialMatch
//               ? regex.test(sourceText)
//               : caseSensitive
//               ? sourceText === keyword
//               : sourceText.toLowerCase() === keyword.toLowerCase();

//             if (matchCondition) {
//               matchedValues.add(value);
//               if (matchingOption === 'first') break;
//               if (matchingOption === 'priority') {
//                 newItem[column] = value;
//                 break;
//               }
//             }
//           }

//           // マッチした値を適用
//           if (matchedValues.size > 0) {
//             if (matchingOption === 'first' || matchingOption === 'priority') {
//               newItem[column] = matchedValues.values().next().value;
//             } else if (matchingOption === 'all') {
//               newItem[column] = Array.from(matchedValues).join(', ');
//             }
//           }
//         });

//         return newItem;
//       });

//       setUpdatedData(newData);

//       // プレビュー用データを作成（上位10件のみ）
//       const previewItems = newData.slice(0, 10).map((item, index) => {
//         const originalItem = data[index];
//         const previewItem = { Title: item[titleKey] || '' };

//         if (matchSource !== 'title') {
//           previewItem.Description = item[descriptionKey] || '';
//           // AI生成部分のみを抽出してプレビューに表示
//           previewItem['AI Generated Content'] = extractAIGeneratedContent(item[descriptionKey]);
//         }

//         selectedColumns.forEach((column) => {
//           previewItem[`${column} (Before)`] = originalItem[column] || '';
//           previewItem[`${column} (After)`] = item[column] || '';
//         });

//         return previewItem;
//       });

//       setPreviewData(previewItems);
//       setPreviewOpen(true);
//     } catch (error) {
//       console.error('データの更新中にエラーが発生しました:', error);
//       setSnackbar({ open: true, message: `データの更新中にエラーが発生しました: ${error.message}`, severity: 'error' });
//     } finally {
//       setLoading(false);
//     }
//   };

//   // 更新を確定する関数
//   const confirmUpdate = () => {
//     if (updatedData.length === 0) {
//       setSnackbar({ open: true, message: '更新データが存在しません。', severity: 'warning' });
//       return;
//     }

//     setData(updatedData);
//     setSnackbar({ open: true, message: 'データが正常に更新されました。', severity: 'success' });
//     setPreviewOpen(false);
//   };

//   // 適用するカテゴリーのカラムを取得
//   const getColumnsForSelectedCategory = () => {
//     if (selectedCategory && categories[selectedCategory]) {
//       return Object.keys(categories[selectedCategory].columns || {});
//     }
//     return [];
//   };

//   // ヘッダーからC:で始まるカラム名を抽出
//   const cColumns = Array.isArray(headers)
//     ? headers.filter((header) => header.startsWith('C:'))
//     : [];

//   return (
//     <Box sx={{ p: 2, mt: 2 }}>
//       <Typography variant="h5" gutterBottom>
//         Item Specifics適用
//       </Typography>
//       <Typography variant="body1" paragraph>
//         設定したItem Specificsをタイトルや商品説明に基づいて適用し、該当するカラムを更新します。
//       </Typography>
//       <Box
//         sx={{
//           mt: 2,
//           display: 'flex',
//           flexDirection: { xs: 'column', sm: 'row' },
//           justifyContent: 'space-between',
//           alignItems: 'center',
//         }}
//       >
//         <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
//           <FormControl fullWidth variant="outlined" size="small" sx={{ minWidth: 200 }}>
//             <InputLabel>適用するカテゴリー</InputLabel>
//             <Select
//               value={selectedCategory}
//               onChange={(e) => {
//                 setSelectedCategory(e.target.value);
//                 setSelectedColumns([]); // カテゴリー変更時に選択カラムをリセット
//               }}
//               label="適用するカテゴリー"
//             >
//               {Object.keys(categories).map((category) => (
//                 <MenuItem key={category} value={category}>
//                   {category}
//                 </MenuItem>
//               ))}
//             </Select>
//           </FormControl>
//           {selectedCategory && (
//             <FormControl fullWidth variant="outlined" size="small" sx={{ minWidth: 200 }}>
//               <InputLabel>適用するカラムを選択</InputLabel>
//               <Select
//                 multiple
//                 value={selectedColumns}
//                 onChange={(e) => setSelectedColumns(e.target.value)}
//                 label="適用するカラムを選択"
//                 renderValue={(selected) => selected.join(', ')}
//               >
//                 {getColumnsForSelectedCategory().map((column) => (
//                   <MenuItem key={column} value={column}>
//                     <Checkbox checked={selectedColumns.indexOf(column) > -1} />
//                     <Typography variant="body1">{column}</Typography>
//                   </MenuItem>
//                 ))}
//               </Select>
//             </FormControl>
//           )}
//           <Button
//             startIcon={<SettingsIcon />}
//             onClick={handleUpdateData}
//             variant="contained"
//             color="primary"
//             disabled={loading}
//           >
//             {loading ? <CircularProgress size={24} /> : 'データを更新'}
//           </Button>
//           <Button
//             startIcon={<RefreshIcon />}
//             onClick={handleReloadSettings}
//             variant="outlined"
//             color="secondary"
//           >
//             データ再読み込み
//           </Button>
//         </Box>
//       </Box>

//       {/* マッチングソースの選択 */}
//       {selectedCategory && (
//         <>
//           <Box sx={{ mt: 2 }}>
//             <Typography variant="subtitle1" gutterBottom>
//               マッチングの基準を選択してください。
//             </Typography>
//             <FormControl component="fieldset">
//               <RadioGroup value={matchSource} onChange={handleMatchSourceChange}>
//                 <FormControlLabel value="title" control={<Radio />} label="タイトルのみ" />
//                 <FormControlLabel value="description" control={<Radio />} label="商品説明のみ (AI生成部分のみ)" />
//                 <FormControlLabel value="both" control={<Radio />} label="タイトルと商品説明の両方 (AI生成部分のみ)" />
//               </RadioGroup>
//             </FormControl>
//           </Box>

//           {/* オプション */}
//           <Box sx={{ mt: 2 }}>
//             <FormControlLabel
//               control={
//                 <Checkbox
//                   checked={caseSensitive}
//                   onChange={handleCaseSensitiveToggle}
//                   name="caseSensitive"
//                 />
//               }
//               label="大文字小文字を区別する"
//             />
//             <FormControlLabel
//               control={
//                 <Checkbox
//                   checked={partialMatch}
//                   onChange={handlePartialMatchToggle}
//                   name="partialMatch"
//                 />
//               }
//               label="部分一致を許可する"
//             />
//           </Box>

//           {/* マッチングの動作を選択 */}
//           <Box sx={{ mt: 2 }}>
//             <Typography variant="subtitle1" gutterBottom>
//               マッチングの動作を選択してください。
//             </Typography>
//             <FormControl component="fieldset">
//               <RadioGroup value={matchingOption} onChange={handleMatchingOptionChange}>
//                 <FormControlLabel value="first" control={<Radio />} label="マッチした最初の値のみを適用" />
//                 <FormControlLabel value="priority" control={<Radio />} label="優先順位に基づいて適用" />
//                 <FormControlLabel value="all" control={<Radio />} label="マッチしたすべての値を適用" />
//               </RadioGroup>
//             </FormControl>
//           </Box>
//         </>
//       )}

//       {/* プレビューダイアログ */}
//       <Dialog open={previewOpen} onClose={() => setPreviewOpen(false)} maxWidth="lg" fullWidth>
//         <DialogTitle>更新プレビュー</DialogTitle>
//         <DialogContent>
//           <Typography variant="body2" paragraph>
//             以下の内容で更新されます。確認してください。
//           </Typography>
//           <Box sx={{ maxHeight: 400, overflow: 'auto' }}>
//             <table style={{ width: '100%', borderCollapse: 'collapse' }}>
//               <thead>
//                 <tr>
//                   {previewData[0] &&
//                     Object.keys(previewData[0]).map((header, index) => (
//                       <th
//                         key={index}
//                         style={{
//                           border: '1px solid #ccc',
//                           padding: '8px',
//                           backgroundColor: '#f5f5f5',
//                           position: 'sticky',
//                           top: 0,
//                         }}
//                       >
//                         {header}
//                       </th>
//                     ))}
//                 </tr>
//               </thead>
//               <tbody>
//                 {previewData.map((row, rowIndex) => (
//                   <tr key={rowIndex}>
//                     {Object.values(row).map((cell, cellIndex) => (
//                       <td key={cellIndex} style={{ border: '1px solid #ccc', padding: '8px' }}>
//                         {cell}
//                       </td>
//                     ))}
//                   </tr>
//                 ))}
//               </tbody>
//             </table>
//           </Box>
//           {updatedData.length > 10 && (
//             <Typography variant="body2" sx={{ mt: 2 }}>
//               (表示は上位10件のみです。実際の更新はすべてのデータに適用されます。)
//             </Typography>
//           )}
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => setPreviewOpen(false)} color="secondary">
//             キャンセル
//           </Button>
//           <Button onClick={confirmUpdate} color="primary" variant="contained">
//             更新を確定
//           </Button>
//         </DialogActions>
//       </Dialog>

//       {/* スナックバー */}
//       <Snackbar
//         open={snackbar.open}
//         autoHideDuration={6000}
//         onClose={() => setSnackbar({ ...snackbar, open: false })}
//         anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
//       >
//         <Alert
//           onClose={() => setSnackbar({ ...snackbar, open: false })}
//           severity={snackbar.severity}
//           variant="filled"
//         >
//           {snackbar.message}
//         </Alert>
//       </Snackbar>
//     </Box>
//   );
// };

// export default ItemSpecificsApplier;

// // ItemSpecificsApplier.js

// import React, { useState, useEffect } from 'react';
// import {
//   Button,
//   Typography,
//   Box,
//   CircularProgress,
//   Snackbar,
//   Alert,
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogTitle,
//   List,
//   ListItem,
//   ListItemText,
//   Checkbox,
//   FormControlLabel,
//   FormControl,
//   InputLabel,
//   Select,
//   MenuItem,
//   RadioGroup,
//   Radio,
// } from '@mui/material';
// import UpdateIcon from '@mui/icons-material/Update';
// import SettingsIcon from '@mui/icons-material/Settings';
// import { getFirestore, doc, getDoc } from 'firebase/firestore';

// const ItemSpecificsApplier = ({ data, setData, userId }) => {
//   const [loading, setLoading] = useState(false);
//   const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
//   const [categories, setCategories] = useState({});
//   const [openSettingsDialog, setOpenSettingsDialog] = useState(false);
//   const [selectedCategory, setSelectedCategory] = useState('');
//   const [selectedColumns, setSelectedColumns] = useState([]);
//   const [caseSensitive, setCaseSensitive] = useState(false);
//   const [partialMatch, setPartialMatch] = useState(true);
//   const [previewData, setPreviewData] = useState([]);
//   const [previewOpen, setPreviewOpen] = useState(false);
//   const [headers, setHeaders] = useState([]);
//   const [updatedData, setUpdatedData] = useState([]);
//   const [matchSource, setMatchSource] = useState('title'); // マッチングの基準

//   const db = getFirestore();

//   // Firestoreから設定を読み込む
//   const loadSettings = async () => {
//     if (!userId) {
//       setSnackbar({ open: true, message: 'ユーザー情報がありません。', severity: 'error' });
//       return;
//     }

//     try {
//       const docRef = doc(db, 'itemSpecificsSettings', userId);
//       const docSnap = await getDoc(docRef);

//       if (docSnap.exists()) {
//         const userSettings = docSnap.data();
//         setCategories(userSettings.categories || {});
//         setSnackbar({ open: true, message: '設定を読み込みました。', severity: 'success' });
//       } else {
//         setCategories({});
//         setSnackbar({ open: true, message: '設定が見つかりません。', severity: 'info' });
//       }
//     } catch (error) {
//       console.error('Failed to load item specifics settings:', error);
//       setSnackbar({ open: true, message: '設定の読み込みに失敗しました。', severity: 'error' });
//     }
//   };

//   // 初回マウント時に設定を読み込む
//   useEffect(() => {
//     loadSettings();
//   }, [userId]);

//   // ヘッダーの取得
//   useEffect(() => {
//     if (data && data.length > 0) {
//       setHeaders(Object.keys(data[0]));
//     }
//   }, [data]);

//   // 設定を再読み込みする関数
//   const handleReloadSettings = () => {
//     loadSettings();
//   };

//   // DescriptionからAI生成部分を抽出する関数を追加
//   const extractAIGeneratedContent = (description) => {
//     if (!description) return '';
//     const regex = /<div id="ai-generated-content">(.*?)<\/div>/is;
//     const match = description.match(regex);
//     if (match && match[1]) {
//       // マッチした部分のHTMLタグを除去してテキストのみを取得
//       const tempDiv = document.createElement('div');
//       tempDiv.innerHTML = match[1];
//       return tempDiv.textContent || tempDiv.innerText || '';
//     }
//     return '';
//   };

//   // データにItem Specificsを適用し、プレビューを表示する
//   const handleUpdateData = () => {
//     if (!selectedCategory) {
//       setSnackbar({ open: true, message: '適用するカテゴリーを選択してください。', severity: 'warning' });
//       return;
//     }

//     if (selectedColumns.length === 0) {
//       setSnackbar({ open: true, message: '適用するカラムを選択してください。', severity: 'warning' });
//       return;
//     }

//     setLoading(true);

//     try {
//       const titleKey = headers.find((header) => header.toLowerCase() === 'title');
//       const descriptionKey = headers.find((header) => header.toLowerCase() === 'description');

//       if (!titleKey && (matchSource === 'title' || matchSource === 'both')) {
//         throw new Error('「Title」カラムが見つかりません。');
//       }

//       if (!descriptionKey && (matchSource === 'description' || matchSource === 'both')) {
//         throw new Error('「Description」カラムが見つかりません。');
//       }

//       const newData = data.map((item) => {
//         const title = item[titleKey] || '';
//         let description = item[descriptionKey] || '';
//         let sourceText = '';

//         // マッチングソースに応じてテキストを設定
//         if (matchSource === 'title') {
//           sourceText = title;
//         } else if (matchSource === 'description') {
//           // DescriptionからAI生成部分を抽出
//           const aiContent = extractAIGeneratedContent(description);
//           sourceText = aiContent;
//         } else if (matchSource === 'both') {
//           // DescriptionからAI生成部分を抽出
//           const aiContent = extractAIGeneratedContent(description);
//           sourceText = `${title} ${aiContent}`;
//         }

//         const newItem = { ...item };

//         selectedColumns.forEach((column) => {
//           const conversionRules = categories[selectedCategory]?.columns[column]?.conversionRules || [];
//           let valueSet = false;

//           // 変換ルールの適用
//           for (const rule of conversionRules) {
//             const { keyword, value } = rule;
//             if (!keyword || !value) continue;

//             const flags = caseSensitive ? 'g' : 'gi';

//             // 正規表現の特殊文字をエスケープする関数
//             function escapeRegExp(string) {
//               return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
//             }

//             // キーワードをエスケープしてワードバウンダリを追加
//             const escapedKeyword = escapeRegExp(keyword);
//             const regex = new RegExp(`\\b${escapedKeyword}\\b`, flags);

//             const matchCondition = partialMatch
//               ? regex.test(sourceText)
//               : caseSensitive
//               ? sourceText === keyword
//               : sourceText.toLowerCase() === keyword.toLowerCase();

//             if (matchCondition) {
//               newItem[column] = value;
//               valueSet = true;
//               break;
//             }
//           }

//           // 変換ルールで値が設定されなかった場合、値のリストを適用
//           if (!valueSet) {
//             const values = categories[selectedCategory]?.columns[column]?.values || [];
//             for (const value of values) {
//               if (typeof value !== 'string') continue;

//               const flags = caseSensitive ? 'g' : 'gi';

//               // 正規表現の特殊文字をエスケープする関数
//               function escapeRegExp(string) {
//                 return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
//               }

//               const escapedValue = escapeRegExp(value);
//               const regex = new RegExp(`\\b${escapedValue}\\b`, flags);

//               const matchCondition = partialMatch
//                 ? regex.test(sourceText)
//                 : caseSensitive
//                 ? sourceText === value
//                 : sourceText.toLowerCase() === value.toLowerCase();

//               if (matchCondition) {
//                 newItem[column] = value;
//                 break;
//               }
//             }
//           }
//         });

//         return newItem;
//       });

//       setUpdatedData(newData);

//       // プレビュー用データを作成（上位10件のみ）
//       const previewItems = newData.slice(0, 10).map((item, index) => {
//         const originalItem = data[index];
//         const previewItem = { Title: item[titleKey] || '' };

//         if (matchSource !== 'title') {
//           previewItem.Description = item[descriptionKey] || '';
//           // AI生成部分のみを抽出してプレビューに表示
//           previewItem['AI Generated Content'] = extractAIGeneratedContent(item[descriptionKey]);
//         }

//         selectedColumns.forEach((column) => {
//           previewItem[`${column} (Before)`] = originalItem[column] || '';
//           previewItem[`${column} (After)`] = item[column] || '';
//         });

//         return previewItem;
//       });

//       setPreviewData(previewItems);
//       setPreviewOpen(true);
//     } catch (error) {
//       console.error('Error updating data:', error);
//       setSnackbar({ open: true, message: `データの更新中にエラーが発生しました: ${error.message}`, severity: 'error' });
//     } finally {
//       setLoading(false);
//     }
//   };

//   // プレビューを確認し、データを更新する
//   const confirmUpdate = () => {
//     if (updatedData.length === 0) {
//       setSnackbar({ open: true, message: '更新データが存在しません。', severity: 'warning' });
//       return;
//     }

//     setData(updatedData);
//     setSnackbar({ open: true, message: 'データが正常に更新されました。', severity: 'success' });
//     setPreviewOpen(false);
//   };

//   // 設定ダイアログを開く
//   const handleOpenSettingsDialog = () => {
//     setOpenSettingsDialog(true);
//   };

//   // 設定ダイアログを閉じる
//   const handleCloseSettingsDialog = () => {
//     setOpenSettingsDialog(false);
//   };

//   // カラムの選択/解除を行う
//   const handleColumnToggle = (column) => {
//     setSelectedColumns((prev) =>
//       prev.includes(column) ? prev.filter((c) => c !== column) : [...prev, column]
//     );
//   };

//   // 大文字小文字区別の切り替え
//   const handleCaseSensitiveToggle = (event) => {
//     setCaseSensitive(event.target.checked);
//   };

//   // 部分一致の切り替え
//   const handlePartialMatchToggle = (event) => {
//     setPartialMatch(event.target.checked);
//   };

//   // 設定ダイアログ内で選択されたカテゴリーのカラムを取得する
//   const getColumnsForSelectedCategory = () => {
//     if (selectedCategory && categories[selectedCategory]) {
//       return Object.keys(categories[selectedCategory].columns || {});
//     }
//     return [];
//   };

//   // マッチングソースの変更
//   const handleMatchSourceChange = (event) => {
//     setMatchSource(event.target.value);
//   };

//   return (
//     <Box sx={{ p: 2, mt: 2 }}>
//       <Typography variant="h5" gutterBottom>
//         Item Specifics適用
//       </Typography>
//       <Typography variant="body1" paragraph>
//         設定したItem Specificsをタイトルや商品説明に基づいて適用し、該当するカラムを更新します。
//       </Typography>
//       <Box
//         sx={{
//           mt: 2,
//           display: 'flex',
//           flexDirection: { xs: 'column', sm: 'row' },
//           justifyContent: 'space-between',
//           alignItems: 'center',
//         }}
//       >
//         <Box>
//           <Button
//             startIcon={<UpdateIcon />}
//             onClick={handleUpdateData}
//             variant="contained"
//             color="primary"
//             disabled={loading}
//             sx={{ mr: 2, mb: { xs: 2, sm: 0 } }}
//           >
//             {loading ? <CircularProgress size={24} /> : 'データを更新'}
//           </Button>
//           <Button
//             startIcon={<SettingsIcon />}
//             onClick={handleOpenSettingsDialog}
//             variant="outlined"
//             color="secondary"
//             sx={{ mr: 2 }}
//           >
//             適用設定
//           </Button>
//           <Button variant="text" color="info" onClick={handleReloadSettings}>
//             データ再読み込み
//           </Button>
//         </Box>
//       </Box>

//       {/* 設定ダイアログ */}
//       <Dialog open={openSettingsDialog} onClose={handleCloseSettingsDialog} maxWidth="sm" fullWidth>
//         <DialogTitle>Item Specifics設定</DialogTitle>
//         <DialogContent>
//           {/* カテゴリー選択 */}
//           <FormControl fullWidth variant="outlined" size="small" sx={{ mt: 2, mb: 2 }}>
//             <InputLabel>適用するカテゴリー</InputLabel>
//             <Select
//               value={selectedCategory}
//               onChange={(e) => {
//                 setSelectedCategory(e.target.value);
//                 setSelectedColumns([]); // カテゴリー変更時に選択カラムをリセット
//               }}
//               label="適用するカテゴリー"
//             >
//               {Object.keys(categories).map((category) => (
//                 <MenuItem key={category} value={category}>
//                   {category}
//                 </MenuItem>
//               ))}
//             </Select>
//           </FormControl>

//           {/* カラムの選択 */}
//           {selectedCategory && (
//             <>
//               <Typography variant="subtitle1" gutterBottom>
//                 適用するカラムを選択してください。
//               </Typography>
//               <List>
//                 {getColumnsForSelectedCategory().map((column) => (
//                   <ListItem key={column} button onClick={() => handleColumnToggle(column)}>
//                     <Checkbox
//                       edge="start"
//                       checked={selectedColumns.includes(column)}
//                       tabIndex={-1}
//                       disableRipple
//                     />
//                     <ListItemText primary={column} />
//                   </ListItem>
//                 ))}
//               </List>
//             </>
//           )}

//           {/* マッチングソースの選択 */}
//           <Box sx={{ mt: 2 }}>
//             <Typography variant="subtitle1" gutterBottom>
//               マッチングの基準を選択してください。
//             </Typography>
//             <FormControl component="fieldset">
//               <RadioGroup value={matchSource} onChange={handleMatchSourceChange}>
//                 <FormControlLabel value="title" control={<Radio />} label="タイトルのみ" />
//                 <FormControlLabel value="description" control={<Radio />} label="商品説明のみ (AI生成部分のみ)" />
//                 <FormControlLabel value="both" control={<Radio />} label="タイトルと商品説明の両方 (AI生成部分のみ)" />
//               </RadioGroup>
//             </FormControl>
//           </Box>

//           {/* オプション */}
//           <Box sx={{ mt: 2 }}>
//             <FormControlLabel
//               control={
//                 <Checkbox
//                   checked={caseSensitive}
//                   onChange={handleCaseSensitiveToggle}
//                   name="caseSensitive"
//                 />
//               }
//               label="大文字小文字を区別する"
//             />
//             <FormControlLabel
//               control={
//                 <Checkbox
//                   checked={partialMatch}
//                   onChange={handlePartialMatchToggle}
//                   name="partialMatch"
//                 />
//               }
//               label="部分一致を許可する"
//             />
//           </Box>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={handleCloseSettingsDialog} color="primary">
//             閉じる
//           </Button>
//         </DialogActions>
//       </Dialog>

//       {/* プレビューダイアログ */}
//       <Dialog open={previewOpen} onClose={() => setPreviewOpen(false)} maxWidth="lg" fullWidth>
//         <DialogTitle>更新プレビュー</DialogTitle>
//         <DialogContent>
//           <Typography variant="body2" paragraph>
//             以下の内容で更新されます。確認してください。
//           </Typography>
//           <Box sx={{ maxHeight: 400, overflow: 'auto' }}>
//             <table style={{ width: '100%', borderCollapse: 'collapse' }}>
//               <thead>
//                 <tr>
//                   {previewData[0] &&
//                     Object.keys(previewData[0]).map((header, index) => (
//                       <th
//                         key={index}
//                         style={{
//                           border: '1px solid #ccc',
//                           padding: '8px',
//                           backgroundColor: '#f5f5f5',
//                           position: 'sticky',
//                           top: 0,
//                         }}
//                       >
//                         {header}
//                       </th>
//                     ))}
//                 </tr>
//               </thead>
//               <tbody>
//                 {previewData.map((row, rowIndex) => (
//                   <tr key={rowIndex}>
//                     {Object.values(row).map((cell, cellIndex) => (
//                       <td key={cellIndex} style={{ border: '1px solid #ccc', padding: '8px' }}>
//                         {cell}
//                       </td>
//                     ))}
//                   </tr>
//                 ))}
//               </tbody>
//             </table>
//           </Box>
//           {updatedData.length > 10 && (
//             <Typography variant="body2" sx={{ mt: 2 }}>
//               (表示は上位10件のみです。実際の更新はすべてのデータに適用されます。)
//             </Typography>
//           )}
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => setPreviewOpen(false)} color="secondary">
//             キャンセル
//           </Button>
//           <Button onClick={confirmUpdate} color="primary" variant="contained">
//             更新を確定
//           </Button>
//         </DialogActions>
//       </Dialog>

//       {/* スナックバー */}
//       <Snackbar
//         open={snackbar.open}
//         autoHideDuration={6000}
//         onClose={() => setSnackbar({ ...snackbar, open: false })}
//         anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
//       >
//         <Alert
//           onClose={() => setSnackbar({ ...snackbar, open: false })}
//           severity={snackbar.severity}
//           variant="filled"
//         >
//           {snackbar.message}
//         </Alert>
//       </Snackbar>
//     </Box>
//   );
// };

// export default ItemSpecificsApplier;
