// ItemSpecificsProcessor.js

import { getFirestore, doc, getDoc } from 'firebase/firestore';
import Papa from 'papaparse';

// Item Specificsを適用する関数
export const applyItemSpecificsProcessor = async (
  currentData,
  userId,
  options,
  setProgress
) => {
  console.log('Received options:', options);

  const { selectedCategory, selectedColumns, matchingOptions } = options || {};

  console.log(
    'applyItemSpecificsProcessor内のselectedCategory:',
    selectedCategory
  );

  if (!userId) {
    throw new Error('ユーザー情報が取得できません。');
  }

  if (!selectedCategory) {
    throw new Error('適用するカテゴリーを選択してください。');
  }

  if (!selectedColumns || selectedColumns.length === 0) {
    throw new Error('適用するカラムを選択してください。');
  }

  try {
    // FirebaseからユーザーのItem Specifics設定を取得
    const db = getFirestore();
    const docRef = doc(db, 'itemSpecificsSettings', userId);
    const docSnap = await getDoc(docRef);

    if (!docSnap.exists()) {
      throw new Error('Item Specificsの設定が見つかりませんでした。');
    }

    const itemSpecificsSettings = docSnap.data();
    const categories = itemSpecificsSettings.categories || {};

    // カテゴリー名の正規化
    const normalizedSelectedCategory = selectedCategory.trim().toLowerCase();

    // カテゴリーキーの正規化
    const normalizedCategories = Object.keys(categories).reduce((acc, key) => {
      acc[key.trim().toLowerCase()] = categories[key];
      return acc;
    }, {});

    if (!normalizedCategories[normalizedSelectedCategory]) {
      throw new Error(
        `選択されたカテゴリー「${selectedCategory}」の設定が見つかりませんでした。`
      );
    }

    const categorySettings = normalizedCategories[normalizedSelectedCategory];

    // 処理の進捗を初期化
    let totalItems = 0;
    let completedItems = 0;

    // currentDataはファイルの配列なので、各ファイルを処理
    const updatedData = await Promise.all(
      currentData.map(async (file) => {
        // CSVデータをパース
        const parsedResult = Papa.parse(file.content, { header: true });
        const data = parsedResult.data;
        const headers = parsedResult.meta.fields;

        totalItems += data.length;

        // データにItem Specificsを適用
        const newData = applyItemSpecificsToData(
          data,
          headers,
          categorySettings,
          selectedColumns,
          matchingOptions,
          () => {
            completedItems += 1;
            if (setProgress) {
              setProgress({ totalItems, completedItems });
            }
          }
        );

        // CSVに再変換
        const csvContent = Papa.unparse(newData);

        return {
          ...file,
          content: csvContent,
        };
      })
    );

    return updatedData;
  } catch (error) {
    console.error('Item Specifics適用中にエラーが発生しました:', error);
    throw error;
  }
};

// データにItem Specificsを適用するロジック
const applyItemSpecificsToData = (
  data,
  headers,
  categorySettings,
  selectedColumns,
  matchingOptions,
  updateProgress
) => {
  const {
    caseSensitive = false,
    partialMatch = true,
    matchSource = 'title',
    matchingOption = 'first',
    overwriteExisting = true, // 上書きするかどうかのオプション
  } = matchingOptions || {};

  const titleKey = headers.find((header) => header.toLowerCase() === 'title');
  const descriptionKey = headers.find(
    (header) => header.toLowerCase() === 'description'
  );

  const newData = data.map((item) => {
    const title = item[titleKey] || '';
    let description = item[descriptionKey] || '';
    let sourceText = '';

    // 商品説明からAI生成部分を抽出
    const aiContent = extractAIGeneratedContent(description);

    // マッチングソースに応じてテキストを設定
    if (matchSource === 'title') {
      sourceText = title;
    } else if (matchSource === 'description') {
      sourceText = aiContent;
    } else if (matchSource === 'both') {
      sourceText = `${title} ${aiContent}`;
    }

    const newItem = { ...item };

    // 商品説明から KEY: Value ペアを抽出
    const keyValuePairs = extractKeyValuePairs(aiContent);

    // デバッグログを追加
    console.log('Extracted Key-Value Pairs:', keyValuePairs);

    // KEY とカラム名を照合して一致すれば値を設定
    Object.keys(keyValuePairs).forEach((key) => {
      const normalizedKey = normalizeString(key);
      const matchingHeader = headers.find(
        (header) =>
          header.startsWith('C:') &&
          normalizeString(header.slice(2)) === normalizedKey
      );

      // デバッグログを追加
      console.log('Headers:', headers);
      console.log('Current Key:', key, 'Normalized Key:', normalizedKey);
      console.log('Matching Header:', matchingHeader);

      if (matchingHeader) {
        newItem[matchingHeader] = keyValuePairs[key];
      }
    });

    // 選択されたカラムに対してItem Specificsを適用
    selectedColumns.forEach((column) => {
      const value = newItem[column];

      // 値が存在し、かつ空白や特定の文字列でない場合の処理
      if (
        !overwriteExisting && // 上書きしない設定の場合
        value && // null や undefined でない
        value.trim() !== '' && // 空白のみでない
        value !== 'NA' &&
        value !== 'N/A' &&
        value !== 'No Brand' // "No Brand" を追加
      ) {
        return; // 値が適切に設定されているのでスキップ
      }

      const columnData = categorySettings.columns[column];
      if (!columnData) {
        console.warn(`カラム「${column}」の設定が見つかりません。`);
        return;
      }

      const conversionRules = columnData.conversionRules || [];
      const values = columnData.values || [];

      let matchedValues = new Set();

      // 正規表現のフラグ設定
      const flags = caseSensitive ? 'g' : 'gi';

      // 変換ルールの適用
      for (const rule of conversionRules) {
        const keyword = rule.keyword;
        const ruleValue = rule.value;

        if (!keyword || !ruleValue) continue;

        const escapedKeyword = escapeRegExp(keyword);
        const regex = new RegExp(
          partialMatch ? escapedKeyword : `\\b${escapedKeyword}\\b`,
          flags
        );

        if (regex.test(sourceText)) {
          matchedValues.add(ruleValue);
          if (matchingOption === 'first' || matchingOption === 'priority') {
            newItem[column] = ruleValue;
            break;
          }
        }
      }

      // 変換ルールでマッチしなかった場合、値リストでマッチング
      if (matchedValues.size === 0) {
        for (const val of values) {
          if (!val) continue;

          const escapedValue = escapeRegExp(val);
          const regex = new RegExp(
            partialMatch ? escapedValue : `\\b${escapedValue}\\b`,
            flags
          );

          if (regex.test(sourceText)) {
            matchedValues.add(val);
            if (matchingOption === 'first') {
              newItem[column] = val;
              break;
            }
          }
        }
      }

      // 既存の値をチェック
      const existingValue = newItem[column];

      // 値が未設定、または "NA" や "N/A"、"No Brand" の場合に値を適用
      if (
        matchedValues.size > 0 &&
        (overwriteExisting ||
          !existingValue ||
          existingValue === 'NA' ||
          existingValue === 'N/A' ||
          existingValue === 'No Brand')
      ) {
        if (matchingOption === 'first' || matchingOption === 'priority') {
          newItem[column] = matchedValues.values().next().value;
        } else if (matchingOption === 'all') {
          newItem[column] = Array.from(matchedValues).join(', ');
        }
      }
    });

    if (updateProgress) {
      updateProgress();
    }

    return newItem;
  });

  return newData;
};

// 商品説明からAI生成部分を抽出する関数
const extractAIGeneratedContent = (description) => {
  if (!description) return '';

  // 商品説明全体を取得
  return description;
};

// 商品説明から KEY: Value のペアを抽出する関数
const extractKeyValuePairs = (content) => {
  const keyValuePairs = {};
  if (!content) return keyValuePairs;

  // HTMLをパースする
  const parser = new DOMParser();
  const doc = parser.parseFromString(content, 'text/html');

  // デバッグログを追加
  console.log('Parsed HTML:', doc);

  // <li> 要素を取得
  const listItems = doc.querySelectorAll('li');

  listItems.forEach((li) => {
    const strongElement = li.querySelector('strong');
    if (strongElement) {
      const key = strongElement.textContent.replace(/:$/, '').trim();
      const valueNode = strongElement.nextSibling;
      const value =
        valueNode && valueNode.nodeType === Node.TEXT_NODE
          ? valueNode.textContent.trim()
          : '';

      if (key && value) {
        keyValuePairs[key] = value;

        // デバッグログを追加
        console.log(`Extracted Key: ${key}, Value: ${value}`);
      }
    }
  });

  return keyValuePairs;
};

// 文字列を正規化する関数
const normalizeString = (str) =>
  str
    .toLowerCase()
    .replace(/\s+/g, '')
    .replace(/[^a-z0-9]/g, '');

// 正規表現の特殊文字をエスケープする関数
const escapeRegExp = (string) => {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
};






// // ItemSpecificsProcessor.js

// import { getFirestore, doc, getDoc } from 'firebase/firestore';
// import Papa from 'papaparse';

// // Item Specificsを適用する関数
// export const applyItemSpecificsProcessor = async (
//   currentData,
//   userId,
//   options,
//   setProgress
// ) => {
//   // デバッグ用のログ出力
//   console.log('Received options:', options);

//   const {
//     selectedCategory,
//     selectedColumns,
//     matchingOptions,
//   } = options || {};

//   console.log('applyItemSpecificsProcessor内のselectedCategory:', selectedCategory);

//   if (!userId) {
//     throw new Error('ユーザー情報が取得できません。');
//   }

//   if (!selectedCategory) {
//     throw new Error('適用するカテゴリーを選択してください。');
//   }

//   if (!selectedColumns || selectedColumns.length === 0) {
//     throw new Error('適用するカラムを選択してください。');
//   }

//   try {
//     // FirebaseからユーザーのItem Specifics設定を取得
//     const db = getFirestore();
//     const docRef = doc(db, 'itemSpecificsSettings', userId);
//     const docSnap = await getDoc(docRef);

//     if (!docSnap.exists()) {
//       throw new Error('Item Specificsの設定が見つかりませんでした。');
//     }

//     const itemSpecificsSettings = docSnap.data();
//     const categories = itemSpecificsSettings.categories || {};

//     // カテゴリー名の正規化
//     const normalizedSelectedCategory = selectedCategory.trim().toLowerCase();

//     // カテゴリーキーの正規化
//     const normalizedCategories = Object.keys(categories).reduce((acc, key) => {
//       acc[key.trim().toLowerCase()] = categories[key];
//       return acc;
//     }, {});

//     if (!normalizedCategories[normalizedSelectedCategory]) {
//       throw new Error(`選択されたカテゴリー「${selectedCategory}」の設定が見つかりませんでした。`);
//     }

//     const categorySettings = normalizedCategories[normalizedSelectedCategory];

//     // 処理の進捗を初期化
//     let totalItems = 0;
//     let completedItems = 0;

//     // currentDataはファイルの配列なので、各ファイルを処理
//     const updatedData = await Promise.all(
//       currentData.map(async (file) => {
//         // CSVデータをパース
//         const parsedResult = Papa.parse(file.content, { header: true });
//         const data = parsedResult.data;
//         const headers = parsedResult.meta.fields;

//         totalItems += data.length;

//         // データにItem Specificsを適用
//         const newData = applyItemSpecificsToData(
//           data,
//           headers,
//           categorySettings,
//           selectedColumns,
//           matchingOptions,
//           () => {
//             completedItems += 1;
//             if (setProgress) {
//               setProgress({ totalItems, completedItems });
//             }
//           }
//         );

//         // CSVに再変換
//         const csvContent = Papa.unparse(newData);

//         return {
//           ...file,
//           content: csvContent,
//         };
//       })
//     );

//     return updatedData;
//   } catch (error) {
//     console.error('Item Specifics適用中にエラーが発生しました:', error);
//     throw error;
//   }
// };

// // データにItem Specificsを適用するロジック
// const applyItemSpecificsToData = (
//   data,
//   headers,
//   categorySettings,
//   selectedColumns,
//   matchingOptions,
//   updateProgress
// ) => {
//   const {
//     caseSensitive = false,
//     partialMatch = true,
//     matchSource = 'title',
//     matchingOption = 'first',
//     overwriteExisting = true, // 上書きするかどうかのオプション
//   } = matchingOptions || {};

//   const titleKey = headers.find((header) => header.toLowerCase() === 'title');
//   const descriptionKey = headers.find(
//     (header) => header.toLowerCase() === 'description'
//   );

//   const newData = data.map((item) => {
//     const title = item[titleKey] || '';
//     let description = item[descriptionKey] || '';
//     let sourceText = '';

//     // 商品説明からAI生成部分を抽出
//     const aiContent = extractAIGeneratedContent(description);

//     // マッチングソースに応じてテキストを設定
//     if (matchSource === 'title') {
//       sourceText = title;
//     } else if (matchSource === 'description') {
//       sourceText = aiContent;
//     } else if (matchSource === 'both') {
//       sourceText = `${title} ${aiContent}`;
//     }

//     const newItem = { ...item };

//     // 商品説明から KEY: Value ペアを抽出
//     const keyValuePairs = extractKeyValuePairs(aiContent);

//     // KEY とカラム名を照合して一致すれば値を設定
//     Object.keys(keyValuePairs).forEach((key) => {
//       const normalizedKey = normalizeString(key);
//       const matchingHeader = headers.find(
//         (header) =>
//           header.startsWith('C:') &&
//           normalizeString(header.slice(2)) === normalizedKey
//       );
//       if (matchingHeader) {
//         newItem[matchingHeader] = keyValuePairs[key];
//       }
//     });

//     // 選択されたカラムに対してItem Specificsを適用
//     selectedColumns.forEach((column) => {
//       const value = newItem[column];

//       // 値が存在し、かつ空白や特定の文字列でない場合の処理
//       if (
//         !overwriteExisting && // 上書きしない設定の場合
//         value && // null や undefined でない
//         value.trim() !== '' && // 空白のみでない
//         value !== 'NA' &&
//         value !== 'N/A' &&
//         value !== 'No Brand' // "No Brand" を追加
//       ) {
//         return; // 値が適切に設定されているのでスキップ
//       }

//       const columnData = categorySettings.columns[column];
//       if (!columnData) {
//         console.warn(`カラム「${column}」の設定が見つかりません。`);
//         return;
//       }

//       const conversionRules = columnData.conversionRules || [];
//       const values = columnData.values || [];

//       let matchedValues = new Set();

//       // 正規表現のフラグ設定
//       const flags = caseSensitive ? 'g' : 'gi';

//       // 変換ルールの適用
//       for (const rule of conversionRules) {
//         const keyword = rule.keyword;
//         const ruleValue = rule.value;

//         if (!keyword || !ruleValue) continue;

//         const escapedKeyword = escapeRegExp(keyword);
//         const regex = new RegExp(
//           partialMatch ? escapedKeyword : `\\b${escapedKeyword}\\b`,
//           flags
//         );

//         // デバッグログ（必要に応じてコメントアウトを外してください）
//         // console.log(`Source Text: ${sourceText}`);
//         // console.log(`Testing keyword: ${keyword}`);
//         // console.log(`Regex: ${regex}`);

//         if (regex.test(sourceText)) {
//           // console.log(`Match found for keyword: ${keyword}`);
//           matchedValues.add(ruleValue);
//           if (matchingOption === 'first' || matchingOption === 'priority') {
//             newItem[column] = ruleValue;
//             break;
//           }
//         }
//       }

//       // 変換ルールでマッチしなかった場合、値リストでマッチング
//       if (matchedValues.size === 0) {
//         for (const val of values) {
//           if (!val) continue;

//           const escapedValue = escapeRegExp(val);
//           const regex = new RegExp(
//             partialMatch ? escapedValue : `\\b${escapedValue}\\b`,
//             flags
//           );

//           if (regex.test(sourceText)) {
//             matchedValues.add(val);
//             if (matchingOption === 'first') {
//               newItem[column] = val;
//               break;
//             }
//           }
//         }
//       }

//       // 既存の値をチェック
//       const existingValue = newItem[column];

//       // 値が未設定、または "NA" や "N/A"、"No Brand" の場合に値を適用
//       if (
//         matchedValues.size > 0 &&
//         (overwriteExisting ||
//           !existingValue ||
//           existingValue === 'NA' ||
//           existingValue === 'N/A' ||
//           existingValue === 'No Brand')
//       ) {
//         if (matchingOption === 'first' || matchingOption === 'priority') {
//           newItem[column] = matchedValues.values().next().value;
//         } else if (matchingOption === 'all') {
//           newItem[column] = Array.from(matchedValues).join(', ');
//         }
//       }
//     });

//     if (updateProgress) {
//       updateProgress();
//     }

//     return newItem;
//   });

//   return newData;
// };

// // 商品説明からAI生成部分を抽出する関数
// const extractAIGeneratedContent = (description) => {
//   if (!description) return '';

//   // 商品説明全体を取得
//   return description;
// };

// // 商品説明から KEY: Value のペアを抽出する関数
// const extractKeyValuePairs = (content) => {
//   const keyValuePairs = {};
//   if (!content) return keyValuePairs;

//   // マークダウン風のテキストをパースする
//   const lines = content.split('\n');

//   lines.forEach((line) => {
//     let text = line.trim();

//     // デバッグログを追加（必要に応じてコメントアウトを外してください）
//     // console.log('Processing line:', text);

//     // マークダウンのリスト記号と強調を除去
//     text = text
//       .replace(/^\s*[-•✓]+\s*/, '') // 先頭のリスト記号を除去
//       .replace(/^\*\*/, '') // 先頭の強調を除去
//       .replace(/\*\*$/, '') // 末尾の強調を除去
//       .replace(/\s+/g, ' '); // 複数のスペースを一つに

//     // コロンで分割
//     const colonIndex = text.indexOf(':');
//     if (colonIndex > -1) {
//       let key = text.substring(0, colonIndex).trim();
//       const value = text.substring(colonIndex + 1).trim();

//       // さらに特殊文字を除去
//       key = key.replace(/^\*+/, '').replace(/\*+$/, '');

//       if (key && value) {
//         keyValuePairs[key] = value;

//         // デバッグログを追加（必要に応じてコメントアウトを外してください）
//         // console.log(`Extracted Key: ${key}, Value: ${value}`);
//       }
//     }
//   });

//   return keyValuePairs;
// };

// // 文字列を正規化する関数
// const normalizeString = (str) =>
//   str
//     .toLowerCase()
//     .replace(/\s+/g, '')
//     .replace(/[^a-z0-9]/g, '');

// // 正規表現の特殊文字をエスケープする関数
// const escapeRegExp = (string) => {
//   return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
// };





// // ItemSpecificsProcessor.js

// import { getFirestore, doc, getDoc } from 'firebase/firestore';
// import Papa from 'papaparse';

// // Item Specificsを適用する関数
// export const applyItemSpecificsProcessor = async (
//   currentData,
//   userId,
//   options,
//   setProgress
// ) => {
//   // デバッグ用のログ出力
//   console.log('Received options:', options);

//   const {
//     selectedCategory,
//     selectedColumns,
//     matchingOptions,
//   } = options || {};

//   console.log('applyItemSpecificsProcessor内のselectedCategory:', selectedCategory);

//   if (!userId) {
//     throw new Error('ユーザー情報が取得できません。');
//   }

//   if (!selectedCategory) {
//     throw new Error('適用するカテゴリーを選択してください。');
//   }

//   if (!selectedColumns || selectedColumns.length === 0) {
//     throw new Error('適用するカラムを選択してください。');
//   }

//   try {
//     // FirebaseからユーザーのItem Specifics設定を取得
//     const db = getFirestore();
//     const docRef = doc(db, 'itemSpecificsSettings', userId);
//     const docSnap = await getDoc(docRef);

//     if (!docSnap.exists()) {
//       throw new Error('Item Specificsの設定が見つかりませんでした。');
//     }

//     const itemSpecificsSettings = docSnap.data();
//     const categories = itemSpecificsSettings.categories || {};

//     // カテゴリー名の正規化
//     const normalizedSelectedCategory = selectedCategory.trim().toLowerCase();

//     // カテゴリーキーの正規化
//     const normalizedCategories = Object.keys(categories).reduce((acc, key) => {
//       acc[key.trim().toLowerCase()] = categories[key];
//       return acc;
//     }, {});

//     if (!normalizedCategories[normalizedSelectedCategory]) {
//       throw new Error(`選択されたカテゴリー「${selectedCategory}」の設定が見つかりませんでした。`);
//     }

//     const categorySettings = normalizedCategories[normalizedSelectedCategory];

//     // 処理の進捗を初期化
//     let totalItems = 0;
//     let completedItems = 0;

//     // currentDataはファイルの配列なので、各ファイルを処理
//     const updatedData = await Promise.all(
//       currentData.map(async (file) => {
//         // CSVデータをパース
//         const parsedResult = Papa.parse(file.content, { header: true });
//         const data = parsedResult.data;
//         const headers = parsedResult.meta.fields;

//         totalItems += data.length;

//         // データにItem Specificsを適用
//         const newData = applyItemSpecificsToData(
//           data,
//           headers,
//           categorySettings,
//           selectedColumns,
//           matchingOptions,
//           () => {
//             completedItems += 1;
//             if (setProgress) {
//               setProgress({ totalItems, completedItems });
//             }
//           }
//         );

//         // CSVに再変換
//         const csvContent = Papa.unparse(newData);

//         return {
//           ...file,
//           content: csvContent,
//         };
//       })
//     );

//     return updatedData;
//   } catch (error) {
//     console.error('Item Specifics適用中にエラーが発生しました:', error);
//     throw error;
//   }
// };

// // データにItem Specificsを適用するロジック
// const applyItemSpecificsToData = (
//   data,
//   headers,
//   categorySettings,
//   selectedColumns,
//   matchingOptions,
//   updateProgress
// ) => {
//   const {
//     caseSensitive = false,
//     partialMatch = true,
//     matchSource = 'title',
//     matchingOption = 'first',
//     overwriteExisting = false, // 上書きするかどうかのオプション
//   } = matchingOptions || {};

//   const titleKey = headers.find((header) => header.toLowerCase() === 'title');
//   const descriptionKey = headers.find(
//     (header) => header.toLowerCase() === 'description'
//   );

//   const newData = data.map((item) => {
//     const title = item[titleKey] || '';
//     let description = item[descriptionKey] || '';
//     let sourceText = '';

//     // 商品説明からAI生成部分を抽出
//     const aiContent = extractAIGeneratedContent(description);

//     // マッチングソースに応じてテキストを設定
//     if (matchSource === 'title') {
//       sourceText = title;
//     } else if (matchSource === 'description') {
//       sourceText = aiContent;
//     } else if (matchSource === 'both') {
//       sourceText = `${title} ${aiContent}`;
//     }

//     const newItem = { ...item };

//     // 商品説明から KEY: Value ペアを抽出
//     const keyValuePairs = extractKeyValuePairs(aiContent);

//     // KEY とカラム名を照合して一致すれば値を設定
//     Object.keys(keyValuePairs).forEach((key) => {
//       const normalizedKey = normalizeString(key);
//       const matchingHeader = headers.find(
//         (header) =>
//           header.startsWith('C:') &&
//           normalizeString(header.slice(2)) === normalizedKey
//       );
//       if (matchingHeader) {
//         newItem[matchingHeader] = keyValuePairs[key];
//       }
//     });

//     // 選択されたカラムに対してItem Specificsを適用
//     selectedColumns.forEach((column) => {
//       const value = newItem[column];

//       // 値が存在し、かつ空白や特定の文字列でない場合の処理
//       if (
//         !overwriteExisting && // 上書きしない設定の場合
//         value && // null や undefined でない
//         value.trim() !== '' && // 空白のみでない
//         value !== 'NA' &&
//         value !== 'N/A' &&
//         value !== 'No Brand' // "No Brand" を追加
//       ) {
//         return; // 値が適切に設定されているのでスキップ
//       }

//       const columnData = categorySettings.columns[column];
//       if (!columnData) {
//         console.warn(`カラム「${column}」の設定が見つかりません。`);
//         return;
//       }

//       const conversionRules = columnData.conversionRules || [];
//       const values = columnData.values || [];

//       let matchedValues = new Set();

//       // 正規表現のフラグ設定
//       const flags = caseSensitive ? 'g' : 'gi';

//       // 変換ルールの適用
//       for (const rule of conversionRules) {
//         const keyword = rule.keyword;
//         const ruleValue = rule.value;

//         if (!keyword || !ruleValue) continue;

//         const escapedKeyword = escapeRegExp(keyword);
//         const regex = new RegExp(
//           partialMatch ? escapedKeyword : `\\b${escapedKeyword}\\b`,
//           flags
//         );

//         // デバッグログ（必要に応じてコメントアウトを外してください）
//         // console.log(`Source Text: ${sourceText}`);
//         // console.log(`Testing keyword: ${keyword}`);
//         // console.log(`Regex: ${regex}`);

//         if (regex.test(sourceText)) {
//           // console.log(`Match found for keyword: ${keyword}`);
//           matchedValues.add(ruleValue);
//           if (matchingOption === 'first' || matchingOption === 'priority') {
//             newItem[column] = ruleValue;
//             break;
//           }
//         }
//       }

//       // 変換ルールでマッチしなかった場合、値リストでマッチング
//       if (matchedValues.size === 0) {
//         for (const val of values) {
//           if (!val) continue;

//           const escapedValue = escapeRegExp(val);
//           const regex = new RegExp(
//             partialMatch ? escapedValue : `\\b${escapedValue}\\b`,
//             flags
//           );

//           if (regex.test(sourceText)) {
//             matchedValues.add(val);
//             if (matchingOption === 'first') {
//               newItem[column] = val;
//               break;
//             }
//           }
//         }
//       }

//       // 既存の値をチェック
//       const existingValue = newItem[column];

//       // 値が未設定、または "NA" や "N/A"、"No Brand" の場合に値を適用
//       if (
//         matchedValues.size > 0 &&
//         (overwriteExisting ||
//           !existingValue ||
//           existingValue === 'NA' ||
//           existingValue === 'N/A' ||
//           existingValue === 'No Brand')
//       ) {
//         if (matchingOption === 'first' || matchingOption === 'priority') {
//           newItem[column] = matchedValues.values().next().value;
//         } else if (matchingOption === 'all') {
//           newItem[column] = Array.from(matchedValues).join(', ');
//         }
//       }
//     });

//     if (updateProgress) {
//       updateProgress();
//     }

//     return newItem;
//   });

//   return newData;
// };

// // 商品説明からAI生成部分を抽出する関数
// const extractAIGeneratedContent = (description) => {
//   if (!description) return '';
//   const parser = new DOMParser();
//   const doc = parser.parseFromString(description, 'text/html');
//   const aiContentDiv = doc.getElementById('ai-generated-content');
//   return aiContentDiv ? aiContentDiv.textContent || '' : '';
// };

// // 商品説明から KEY: Value のペアを抽出する関数
// const extractKeyValuePairs = (htmlContent) => {
//   const keyValuePairs = {};
//   if (!htmlContent) return keyValuePairs;

//   const parser = new DOMParser();
//   const doc = parser.parseFromString(htmlContent, 'text/html');

//   // すべての <li> 要素を取得
//   const liElements = doc.querySelectorAll('li');

//   liElements.forEach((li) => {
//     let text = li.textContent.trim();

//     // 特殊文字や装飾を除去
//     text = text
//       .replace(/^\s*[-•✓]+[\s]*/, '') // 先頭の記号を除去
//       .replace(/\*\*/g, '') // マークダウンの強調を除去
//       .replace(/\s+/g, ' '); // 複数のスペースを一つに

//     // コロンで分割
//     const colonIndex = text.indexOf(':');
//     if (colonIndex > -1) {
//       let key = text.substring(0, colonIndex).trim();
//       const value = text.substring(colonIndex + 1).trim();

//       // さらに特殊文字を除去
//       key = key.replace(/^\*+/, '').replace(/\*+$/, '');

//       if (key && value) {
//         keyValuePairs[key] = value;
//       }
//     }
//   });

//   return keyValuePairs;
// };

// // 文字列を正規化する関数
// const normalizeString = (str) =>
//   str
//     .toLowerCase()
//     .replace(/\s+/g, '')
//     .replace(/[^a-z0-9]/g, '');

// // 正規表現の特殊文字をエスケープする関数
// const escapeRegExp = (string) => {
//   return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
// };






// // applyItemSpecificsProcessor.js

// import { getFirestore, doc, getDoc } from 'firebase/firestore';
// import Papa from 'papaparse';

// // Item Specificsを適用する関数
// export const applyItemSpecificsProcessor = async (
//   currentData,
//   userId,
//   options,
//   setProgress
// ) => {
//   // デバッグ用のログ出力
//   console.log('Received options:', options);

//   const {
//     selectedCategory,
//     selectedColumns,
//     matchingOptions,
//   } = options || {};

//   console.log('applyItemSpecificsProcessor内のselectedCategory:', selectedCategory);

//   if (!userId) {
//     throw new Error('ユーザー情報が取得できません。');
//   }

//   if (!selectedCategory) {
//     throw new Error('適用するカテゴリーを選択してください。');
//   }

//   if (!selectedColumns || selectedColumns.length === 0) {
//     throw new Error('適用するカラムを選択してください。');
//   }

//   try {
//     // FirebaseからユーザーのItem Specifics設定を取得
//     const db = getFirestore();
//     const docRef = doc(db, 'itemSpecificsSettings', userId);
//     const docSnap = await getDoc(docRef);

//     if (!docSnap.exists()) {
//       throw new Error('Item Specificsの設定が見つかりませんでした。');
//     }

//     const itemSpecificsSettings = docSnap.data();
//     const categories = itemSpecificsSettings.categories || {};

//     // カテゴリー名の正規化
//     const normalizedSelectedCategory = selectedCategory.trim().toLowerCase();

//     // カテゴリーキーの正規化
//     const normalizedCategories = Object.keys(categories).reduce((acc, key) => {
//       acc[key.trim().toLowerCase()] = categories[key];
//       return acc;
//     }, {});

//     if (!normalizedCategories[normalizedSelectedCategory]) {
//       throw new Error(`選択されたカテゴリー「${selectedCategory}」の設定が見つかりませんでした。`);
//     }

//     const categorySettings = normalizedCategories[normalizedSelectedCategory];

//     // 処理の進捗を初期化
//     let totalItems = 0;
//     let completedItems = 0;

//     // currentDataはファイルの配列なので、各ファイルを処理
//     const updatedData = await Promise.all(
//       currentData.map(async (file) => {
//         // CSVデータをパース
//         const parsedResult = Papa.parse(file.content, { header: true });
//         const data = parsedResult.data;
//         const headers = parsedResult.meta.fields;

//         totalItems += data.length;

//         // データにItem Specificsを適用
//         const newData = applyItemSpecificsToData(
//           data,
//           headers,
//           categorySettings,
//           selectedColumns,
//           matchingOptions,
//           () => {
//             completedItems += 1;
//             if (setProgress) {
//               setProgress({ totalItems, completedItems });
//             }
//           }
//         );

//         // CSVに再変換
//         const csvContent = Papa.unparse(newData);

//         return {
//           ...file,
//           content: csvContent,
//         };
//       })
//     );

//     return updatedData;
//   } catch (error) {
//     console.error('Item Specifics適用中にエラーが発生しました:', error);
//     throw error;
//   }
// };

// // データにItem Specificsを適用するロジック
// const applyItemSpecificsToData = (
//   data,
//   headers,
//   categorySettings,
//   selectedColumns,
//   matchingOptions,
//   updateProgress
// ) => {
//   const {
//     caseSensitive = false,
//     partialMatch = true,
//     matchSource = 'title',
//     matchingOption = 'first',
//   } = matchingOptions || {};

//   const titleKey = headers.find((header) => header.toLowerCase() === 'title');
//   const descriptionKey = headers.find(
//     (header) => header.toLowerCase() === 'description'
//   );

//   const newData = data.map((item) => {
//     const title = item[titleKey] || '';
//     let description = item[descriptionKey] || '';
//     let sourceText = '';

//     // 商品説明からAI生成部分を抽出
//     const aiContent = extractAIGeneratedContent(description);

//     // マッチングソースに応じてテキストを設定
//     if (matchSource === 'title') {
//       sourceText = title;
//     } else if (matchSource === 'description') {
//       sourceText = aiContent;
//     } else if (matchSource === 'both') {
//       sourceText = `${title} ${aiContent}`;
//     }

//     const newItem = { ...item };

//     // 商品説明から KEY: Value ペアを抽出
//     const keyValuePairs = extractKeyValuePairs(aiContent);

//     // KEY とカラム名を照合して一致すれば値を設定
//     Object.keys(keyValuePairs).forEach((key) => {
//       const normalizedKey = normalizeString(key);
//       const matchingHeader = headers.find(
//         (header) =>
//           header.startsWith('C:') &&
//           normalizeString(header.slice(2)) === normalizedKey
//       );
//       if (matchingHeader) {
//         newItem[matchingHeader] = keyValuePairs[key];
//       }
//     });

//     // 選択されたカラムに対してItem Specificsを適用
//     selectedColumns.forEach((column) => {
//       const value = newItem[column];

//       // 値が存在し、かつ空白や特定の文字列でない場合はスキップ
//       if (
//         value && // null や undefined でない
//         value.trim() !== '' && // 空白のみでない
//         value !== 'NA' &&
//         value !== 'N/A'
//       ) {
//         return; // 値が適切に設定されているのでスキップ
//       }

//       const columnData = categorySettings.columns[column];
//       if (!columnData) {
//         console.warn(`カラム「${column}」の設定が見つかりません。`);
//         return;
//       }

//       const conversionRules = columnData.conversionRules || [];
//       const values = columnData.values || [];

//       let matchedValues = new Set();

//       // 正規表現のフラグ設定
//       const flags = caseSensitive ? 'g' : 'gi';

//       // 変換ルールの適用
//       for (const rule of conversionRules) {
//         const keyword = rule.keyword;
//         const ruleValue = rule.value;

//         if (!keyword || !ruleValue) continue;

//         const escapedKeyword = escapeRegExp(keyword);
//         const regex = new RegExp(
//           partialMatch ? escapedKeyword : `\\b${escapedKeyword}\\b`,
//           flags
//         );

//         if (regex.test(sourceText)) {
//           matchedValues.add(ruleValue);
//           if (matchingOption === 'first' || matchingOption === 'priority') {
//             newItem[column] = ruleValue;
//             break;
//           }
//         }
//       }

//       // 変換ルールでマッチしなかった場合、値リストでマッチング
//       if (matchedValues.size === 0) {
//         for (const value of values) {
//           if (!value) continue;

//           const escapedValue = escapeRegExp(value);
//           const regex = new RegExp(
//             partialMatch ? escapedValue : `\\b${escapedValue}\\b`,
//             flags
//           );

//           if (regex.test(sourceText)) {
//             matchedValues.add(value);
//             if (matchingOption === 'first') {
//               break;
//             }
//           }
//         }
//       }

//       // 既存の値をチェック
//       const existingValue = newItem[column];

//       // 値が未設定、または "NA" や "N/A" の場合に値を適用
//       if (
//         matchedValues.size > 0 &&
//         (!existingValue || existingValue === 'NA' || existingValue === 'N/A')
//       ) {
//         if (matchingOption === 'first' || matchingOption === 'priority') {
//           newItem[column] = matchedValues.values().next().value;
//         } else if (matchingOption === 'all') {
//           newItem[column] = Array.from(matchedValues).join(', ');
//         }
//       }
//     });

//     if (updateProgress) {
//       updateProgress();
//     }

//     return newItem;
//   });

//   return newData;
// };

// // 商品説明からAI生成部分を抽出する関数
// const extractAIGeneratedContent = (description) => {
//   if (!description) return '';
//   const parser = new DOMParser();
//   const doc = parser.parseFromString(description, 'text/html');
//   const aiContentDiv = doc.getElementById('ai-generated-content');
//   return aiContentDiv ? aiContentDiv.textContent || '' : '';
// };

// // 商品説明から KEY: Value のペアを抽出する関数
// const extractKeyValuePairs = (htmlContent) => {
//   const keyValuePairs = {};
//   if (!htmlContent) return keyValuePairs;

//   const parser = new DOMParser();
//   const doc = parser.parseFromString(htmlContent, 'text/html');

//   // すべての <li> 要素を取得
//   const liElements = doc.querySelectorAll('li');

//   liElements.forEach((li) => {
//     let text = li.textContent.trim();

//     // 特殊文字や装飾を除去
//     text = text
//       .replace(/^\s*[-•✓]+[\s]*/, '') // 先頭の記号を除去
//       .replace(/\*\*/g, '') // マークダウンの強調を除去
//       .replace(/\s+/g, ' '); // 複数のスペースを一つに

//     // コロンで分割
//     const colonIndex = text.indexOf(':');
//     if (colonIndex > -1) {
//       let key = text.substring(0, colonIndex).trim();
//       const value = text.substring(colonIndex + 1).trim();

//       // さらに特殊文字を除去
//       key = key.replace(/^\*+/, '').replace(/\*+$/, '');

//       if (key && value) {
//         keyValuePairs[key] = value;
//       }
//     }
//   });

//   return keyValuePairs;
// };

// // 文字列を正規化する関数
// const normalizeString = (str) =>
//   str
//     .toLowerCase()
//     .replace(/\s+/g, '')
//     .replace(/[^a-z0-9]/g, '');

// // 正規表現の特殊文字をエスケープする関数
// const escapeRegExp = (string) => {
//   return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
// };





// // ItemSpecificsProcessor.js

// import { getFirestore, doc, getDoc } from 'firebase/firestore';
// import Papa from 'papaparse';

// // Item Specificsを適用する関数
// export const applyItemSpecificsProcessor = async (
//     currentData,
//     userId,
//     options,
//     setProgress
//   ) => {
//     const {
//       selectedCategory,
//       selectedColumns,
//       matchingOptions,
//     } = options || {};
  
//     console.log('applyItemSpecificsProcessor内のselectedCategory:', selectedCategory);

//   if (!userId) {
//     throw new Error('ユーザー情報が取得できません。');
//   }

//   if (!selectedCategory) {
//     throw new Error('適用するカテゴリーを選択してください。');
//   }

//   if (!selectedColumns || selectedColumns.length === 0) {
//     throw new Error('適用するカラムを選択してください。');
//   }

//   try {
//     // FirebaseからユーザーのItem Specifics設定を取得
//     const db = getFirestore();
//     const docRef = doc(db, 'itemSpecificsSettings', userId);
//     const docSnap = await getDoc(docRef);

//     if (!docSnap.exists()) {
//       throw new Error('Item Specificsの設定が見つかりませんでした。');
//     }

//     const itemSpecificsSettings = docSnap.data();
//     const categories = itemSpecificsSettings.categories || {};

//     if (!categories[selectedCategory]) {
//       throw new Error(`選択されたカテゴリー「${selectedCategory}」の設定が見つかりませんでした。`);
//     }

//     const categorySettings = categories[selectedCategory];

//     // 処理の進捗を初期化
//     let totalItems = 0;
//     let completedItems = 0;

//     // currentDataはファイルの配列なので、各ファイルを処理
//     const updatedData = await Promise.all(currentData.map(async (file) => {
//       // CSVデータをパース
//       const parsedResult = Papa.parse(file.content, { header: true });
//       const data = parsedResult.data;
//       const headers = parsedResult.meta.fields;

//       totalItems += data.length;

//       // データにItem Specificsを適用
//       const newData = applyItemSpecificsToData(
//         data,
//         headers,
//         categorySettings,
//         selectedColumns,
//         matchingOptions,
//         () => {
//           completedItems += 1;
//           if (setProgress) {
//             setProgress({ totalItems, completedItems });
//           }
//         }
//       );

//       // CSVに再変換
//       const csvContent = Papa.unparse(newData);

//       return {
//         ...file,
//         content: csvContent,
//       };
//     }));

//     return updatedData;
//   } catch (error) {
//     console.error('Item Specifics適用中にエラーが発生しました:', error);
//     throw error;
//   }
// };

// // データにItem Specificsを適用するロジック
// const applyItemSpecificsToData = (
//   data,
//   headers,
//   categorySettings,
//   selectedColumns,
//   matchingOptions,
//   updateProgress
// ) => {
//   const {
//     caseSensitive = false,
//     partialMatch = true,
//     matchSource = 'title',
//     matchingOption = 'first',
//   } = matchingOptions || {};

//   const titleKey = headers.find(
//     (header) => header.toLowerCase() === 'title'
//   );
//   const descriptionKey = headers.find(
//     (header) => header.toLowerCase() === 'description'
//   );

//   const newData = data.map((item) => {
//     const title = item[titleKey] || '';
//     let description = item[descriptionKey] || '';
//     let sourceText = '';

//     // 商品説明からAI生成部分を抽出
//     const aiContent = extractAIGeneratedContent(description);

//     // マッチングソースに応じてテキストを設定
//     if (matchSource === 'title') {
//       sourceText = title;
//     } else if (matchSource === 'description') {
//       sourceText = aiContent;
//     } else if (matchSource === 'both') {
//       sourceText = `${title} ${aiContent}`;
//     }

//     const newItem = { ...item };

//     // 商品説明から KEY: Value ペアを抽出
//     const keyValuePairs = extractKeyValuePairs(aiContent);

//     // KEY とカラム名を照合して一致すれば値を設定
//     Object.keys(keyValuePairs).forEach((key) => {
//       const normalizedKey = normalizeString(key);
//       const matchingHeader = headers.find(
//         (header) =>
//           header.startsWith('C:') &&
//           normalizeString(header.slice(2)) === normalizedKey
//       );
//       if (matchingHeader) {
//         newItem[matchingHeader] = keyValuePairs[key];
//       }
//     });

//     // 選択されたカラムに対してItem Specificsを適用
//     selectedColumns.forEach((column) => {
//       const value = newItem[column];

//       // 値が存在し、かつ空白や特定の文字列でない場合はスキップ
//       if (
//         value && // null や undefined でない
//         value.trim() !== '' && // 空白のみでない
//         value !== 'NA' &&
//         value !== 'N/A'
//       ) {
//         return; // 値が適切に設定されているのでスキップ
//       }

//       const columnData = categorySettings.columns[column];
//       if (!columnData) {
//         console.warn(`カラム「${column}」の設定が見つかりません。`);
//         return;
//       }

//       const conversionRules = columnData.conversionRules || [];
//       const values = columnData.values || [];

//       let matchedValues = new Set();

//       // 正規表現のフラグ設定
//       const flags = caseSensitive ? 'g' : 'gi';

//       // 変換ルールの適用
//       for (const rule of conversionRules) {
//         const keyword = rule.keyword;
//         const ruleValue = rule.value;

//         if (!keyword || !ruleValue) continue;

//         const escapedKeyword = escapeRegExp(keyword);
//         const regex = new RegExp(partialMatch ? escapedKeyword : `\\b${escapedKeyword}\\b`, flags);

//         if (regex.test(sourceText)) {
//           matchedValues.add(ruleValue);
//           if (matchingOption === 'first' || matchingOption === 'priority') {
//             newItem[column] = ruleValue;
//             break;
//           }
//         }
//       }

//       // 変換ルールでマッチしなかった場合、値リストでマッチング
//       if (matchedValues.size === 0) {
//         for (const value of values) {
//           if (!value) continue;

//           const escapedValue = escapeRegExp(value);
//           const regex = new RegExp(partialMatch ? escapedValue : `\\b${escapedValue}\\b`, flags);

//           if (regex.test(sourceText)) {
//             matchedValues.add(value);
//             if (matchingOption === 'first') {
//               break;
//             }
//           }
//         }
//       }

//       // 既存の値をチェック
//       const existingValue = newItem[column];

//       // 値が未設定、または "NA" や "N/A" の場合に値を適用
//       if (
//         matchedValues.size > 0 &&
//         (!existingValue || existingValue === 'NA' || existingValue === 'N/A')
//       ) {
//         if (matchingOption === 'first' || matchingOption === 'priority') {
//           newItem[column] = matchedValues.values().next().value;
//         } else if (matchingOption === 'all') {
//           newItem[column] = Array.from(matchedValues).join(', ');
//         }
//       }
//     });

//     if (updateProgress) {
//       updateProgress();
//     }

//     return newItem;
//   });

//   return newData;
// };

// // 商品説明からAI生成部分を抽出する関数
// const extractAIGeneratedContent = (description) => {
//   if (!description) return '';
//   const parser = new DOMParser();
//   const doc = parser.parseFromString(description, 'text/html');
//   const aiContentDiv = doc.getElementById('ai-generated-content');
//   return aiContentDiv ? aiContentDiv.textContent || '' : '';
// };

// // 商品説明から KEY: Value のペアを抽出する関数
// const extractKeyValuePairs = (htmlContent) => {
//   const keyValuePairs = {};
//   if (!htmlContent) return keyValuePairs;

//   const parser = new DOMParser();
//   const doc = parser.parseFromString(htmlContent, 'text/html');

//   // すべての <li> 要素を取得
//   const liElements = doc.querySelectorAll('li');

//   liElements.forEach((li) => {
//     let text = li.textContent.trim();

//     // 特殊文字や装飾を除去
//     text = text
//       .replace(/^\s*[-•✓]+[\s]*/, '') // 先頭の記号を除去
//       .replace(/\*\*/g, '') // マークダウンの強調を除去
//       .replace(/\s+/g, ' '); // 複数のスペースを一つに

//     // コロンで分割
//     const colonIndex = text.indexOf(':');
//     if (colonIndex > -1) {
//       let key = text.substring(0, colonIndex).trim();
//       const value = text.substring(colonIndex + 1).trim();

//       // さらに特殊文字を除去
//       key = key.replace(/^\*+/, '').replace(/\*+$/, '');

//       if (key && value) {
//         keyValuePairs[key] = value;
//       }
//     }
//   });

//   return keyValuePairs;
// };

// // 文字列を正規化する関数
// const normalizeString = (str) =>
//   str
//     .toLowerCase()
//     .replace(/\s+/g, '')
//     .replace(/[^a-z0-9]/g, '');

// // 正規表現の特殊文字をエスケープする関数
// const escapeRegExp = (string) => {
//   return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
// };