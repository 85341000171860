// functions.js

import { getDoc, doc } from 'firebase/firestore';
import { db } from './firebaseConfig';
import Papa from 'papaparse';

// conditionData.json と item_specifics.json をインポート（必要に応じて）
import conditionData from './conditionData.json';
import itemSpecificsData from './item_specifics.json';

// 1. テンプレートを実行する
export const applyTemplate = async (filesData, user, selectedTemplateName) => {
  // ユーザーが認証されているか確認
  if (!user) {
    console.error('ユーザーが認証されていません');
    return filesData;
  }

  // ユーザーのテンプレートを取得
  let templates = [];
  try {
    const docRef = doc(db, 'userTemplates', user.uid);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const userData = docSnap.data();
      const userCategories = userData.categories || {};
      // テンプレートをリスト化
      templates = Object.values(userCategories).flat();
    } else {
      console.error('ユーザーのテンプレートが見つかりません');
      return filesData;
    }
  } catch (error) {
    console.error('テンプレートの取得中にエラーが発生しました:', error);
    return filesData;
  }

  // 適用するテンプレートを選択
  const selectedTemplate = templates.find((t) => t.name === selectedTemplateName);
  if (!selectedTemplate) {
    console.error('指定されたテンプレートが見つかりません');
    return filesData;
  }

  // テンプレートを適用する処理
  const processedFilesData = filesData.map((file) => {
    // ファイルの内容をパース
    const parsedResult = Papa.parse(file.content, { header: true });
    const data = parsedResult.data;
    let columns = parsedResult.meta.fields;

    let updatedData = [...data];
    let updatedColumns = [...columns];

    // テンプレートの各操作を適用
    for (const op of selectedTemplate.operations) {
      if (!updatedColumns.includes(op.columnName)) {
        updatedColumns.push(op.columnName);
        updatedData = updatedData.map((row) => ({ ...row, [op.columnName]: '' }));
      }

      switch (op.action) {
        case 'replace':
          updatedData = updatedData.map((row) => {
            row[op.columnName] = op.value;
            return row;
          });
          break;
        case 'prefix':
          updatedData = updatedData.map((row) => {
            row[op.columnName] = op.value + (row[op.columnName] || '');
            return row;
          });
          break;
        case 'suffix':
          updatedData = updatedData.map((row) => {
            row[op.columnName] = (row[op.columnName] || '') + op.value;
            return row;
          });
          break;
        case 'setConditionID':
          // ConditionID の設定
          updatedData = updatedData.map((row) => {
            row[op.columnName] = op.value;
            return row;
          });
          break;
        case 'setItemSpecifics':
          // Item Specifics の処理
          const specificsColumnName = `ItemSpecifics:${op.itemSpecificName}`;
          if (!updatedColumns.includes(specificsColumnName)) {
            updatedColumns.push(specificsColumnName);
            updatedData = updatedData.map((row) => ({ ...row, [specificsColumnName]: '' }));
          }
          updatedData = updatedData.map((row) => {
            row[specificsColumnName] = op.value;
            return row;
          });
          break;
        default:
          console.warn(`不明なアクション: ${op.action}`);
          break;
      }
    }

    // 更新されたデータを CSV に変換
    const updatedContent = Papa.unparse(updatedData, { columns: updatedColumns });
    return { ...file, content: updatedContent };
  });

  return processedFilesData;
};

// 2. 価格の変更を適用する
export const applyPriceChanges = (filesData) => {
  // 価格変更のロジックを実装します
  // ここでは、例として価格を10%増加させる処理を行います
  return filesData.map((file) => {
    const parsedResult = Papa.parse(file.content, { header: true });
    const data = parsedResult.data;
    const columns = parsedResult.meta.fields;

    // 価格を変更するカラム名（例として 'StartPrice' を使用）
    const priceColumn = 'StartPrice';

    const updatedData = data.map((row) => {
      let price = parseFloat(row[priceColumn]);
      if (!isNaN(price)) {
        price = price * 1.10; // 10%増加
        row[priceColumn] = price.toFixed(2);
      }
      return row;
    });

    // 更新されたデータを CSV に変換
    const updatedContent = Papa.unparse(updatedData, { columns });
    return { ...file, content: updatedContent };
  });
};

// 3. AIタイトルを適用する
export const applyAITitles = async (filesData) => {
  // AIを使用してタイトルを生成するロジックを実装します
  // ここでは、例としてタイトルに 'AI Generated: ' を追加します
  return filesData.map((file) => {
    const parsedResult = Papa.parse(file.content, { header: true });
    const data = parsedResult.data;
    const columns = parsedResult.meta.fields;

    const titleColumn = 'Title';

    const updatedData = data.map((row) => {
      // 実際には AI API を呼び出してタイトルを生成する処理を実装します
      // ここでは例として、既存のタイトルに 'AI Generated: ' を付加します
      row[titleColumn] = `AI Generated: ${row[titleColumn]}`;
      return row;
    });

    const updatedContent = Papa.unparse(updatedData, { columns });
    return { ...file, content: updatedContent };
  });
};

// 4. AI商品説明を適用する
export const applyAIProductDescriptions = async (filesData) => {
  // AIを使用して商品説明を生成するロジックを実装します
  // ここでは、例として商品説明に 'This is an AI-generated description.' を追加します
  return filesData.map((file) => {
    const parsedResult = Papa.parse(file.content, { header: true });
    const data = parsedResult.data;
    const columns = parsedResult.meta.fields;

    const descriptionColumn = 'Description';

    const updatedData = data.map((row) => {
      // 実際には AI API を呼び出して商品説明を生成する処理を実装します
      // ここでは例として、既存の説明に文を追加します
      row[descriptionColumn] = `${row[descriptionColumn]} This is an AI-generated description.`;
      return row;
    });

    const updatedContent = Papa.unparse(updatedData, { columns });
    return { ...file, content: updatedContent };
  });
};

// 5. Item Specificsの適用をする
export const applyItemSpecifics = (filesData) => {
  // Item Specificsを適用するロジックを実装します
  // ここでは、例として 'Brand' という Item Specifics を追加します
  return filesData.map((file) => {
    const parsedResult = Papa.parse(file.content, { header: true });
    const data = parsedResult.data;
    let columns = parsedResult.meta.fields;

    const itemSpecificsColumn = 'ItemSpecifics:Brand';

    if (!columns.includes(itemSpecificsColumn)) {
      columns.push(itemSpecificsColumn);
    }

    const updatedData = data.map((row) => {
      row[itemSpecificsColumn] = 'ExampleBrand';
      return row;
    });

    const updatedContent = Papa.unparse(updatedData, { columns });
    return { ...file, content: updatedContent };
  });
};
