// PriceManager.js

import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Button,
  Paper,
  Slider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  TextField,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  Tooltip,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Alert,
  Snackbar,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
} from '@mui/material';
import {
  ExpandMore as ExpandMoreIcon,
  HelpOutline as HelpOutlineIcon,
  Save as SaveIcon,
  SettingsBackupRestore as SettingsBackupRestoreIcon,
  Add as AddIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  CheckCircle as CheckCircleIcon,
  WarningAmber as WarningAmberIcon,
} from '@mui/icons-material';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

// Firebase関連のインポート
import { getAuth } from 'firebase/auth';
import { getFirestore, doc, getDoc, setDoc } from 'firebase/firestore';

const PriceManager = ({ data, setData }) => {
  // ユーザー状態の定義
  const [user, setUser] = useState(null);

  // ユーザーの取得
  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = auth.onAuthStateChanged((firebaseUser) => {
      setUser(firebaseUser);
    });
    return () => unsubscribe();
  }, []);

  // 状態変数の定義
  const [targetProfitMargin, setTargetProfitMargin] = useState(25);
  const [exchangeRate, setExchangeRate] = useState(140);
  const [categories, setCategories] = useState({
    additionalCosts: [],
    fees: [],
  });
  const [isHelpDialogOpen, setIsHelpDialogOpen] = useState(false);
  const [isResetDialogOpen, setIsResetDialogOpen] = useState(false);
  const [isShippingDialogOpen, setIsShippingDialogOpen] = useState(false);
  const [isFeeDialogOpen, setIsFeeDialogOpen] = useState(false);
  const [isBestOfferDialogOpen, setIsBestOfferDialogOpen] = useState(false);
  const [isEditTemplateNameDialogOpen, setIsEditTemplateNameDialogOpen] = useState(false);
  const [editingTemplateId, setEditingTemplateId] = useState('');
  const [newTemplateName, setNewTemplateName] = useState('');
  const [selectedShippingTemplateId, setSelectedShippingTemplateId] = useState('default');
  const [shippingRateTemplates, setShippingRateTemplates] = useState([
    {
      id: 'default',
      name: 'デフォルト送料設定',
      shippingRates: [
        {
          minPriceJPY: 0,
          maxPriceJPY: 7000,
          costJPY: 700,
          minPriceUSD: 0,
          maxPriceUSD: 50,
          costUSD: 5,
        },
        {
          minPriceJPY: 7000,
          maxPriceJPY: 14000,
          costJPY: 1400,
          minPriceUSD: 50,
          maxPriceUSD: 100,
          costUSD: 10,
        },
        {
          minPriceJPY: 14000,
          maxPriceJPY: Infinity,
          costJPY: 2100,
          minPriceUSD: 100,
          maxPriceUSD: Infinity,
          costUSD: 15,
        },
      ],
    },
  ]);
  const [fees, setFees] = useState({
    ebayFinalValue: 10,
    payoneer: 2,
    promotedListing: 3,
    ebayTransactionFee: 0.3,
  });
  const [bestOfferAutoAcceptPercentage, setBestOfferAutoAcceptPercentage] = useState(95);
  const [minimumBestOfferPercentage, setMinimumBestOfferPercentage] = useState(80);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'info',
  });
  const [error, setError] = useState(null);
  const [isPriceApplied, setIsPriceApplied] = useState(false);
  const [isCalculating, setIsCalculating] = useState(false);
  const [calculatedPrices, setCalculatedPrices] = useState([]);
  const [showPriceSimulation, setShowPriceSimulation] = useState(false);
  const [originalData, setOriginalData] = useState(null);

  // ユーザー設定の読み込み
  useEffect(() => {
    if (user) {
      const loadSettings = async () => {
        const db = getFirestore();
        const userDocRef = doc(db, 'userSettings', user.uid);
        try {
          const docSnap = await getDoc(userDocRef);
          if (docSnap.exists()) {
            const settings = docSnap.data();
            setTargetProfitMargin(settings.targetProfitMargin || 25);
            setExchangeRate(settings.exchangeRate || 140);
            setCategories(settings.categories || { additionalCosts: [], fees: [] });
            setShippingRateTemplates(settings.shippingRateTemplates || shippingRateTemplates);
            setSelectedShippingTemplateId(settings.selectedShippingTemplateId || 'default');
            setFees(
              settings.fees || {
                ebayFinalValue: 10,
                payoneer: 2,
                promotedListing: 3,
                ebayTransactionFee: 0.3,
              }
            );
            setBestOfferAutoAcceptPercentage(settings.bestOfferAutoAcceptPercentage || 95);
            setMinimumBestOfferPercentage(settings.minimumBestOfferPercentage || 80);
          }
        } catch (error) {
          console.error('設定の読み込みに失敗しました:', error);
          setSnackbar({
            open: true,
            message: '設定の読み込みに失敗しました',
            severity: 'error',
          });
        }
      };
      loadSettings();
    }
  }, [user]);

  // 利益率の変更ハンドラー
  const handleProfitMarginChange = (event, newValue) => {
    setTargetProfitMargin(newValue);
  };

  // 為替レートの変更ハンドラー
  const handleExchangeRateChange = (event) => {
    const newRate = parseFloat(event.target.value);
    if (!isNaN(newRate) && newRate > 0) {
      setExchangeRate(newRate);
    }
  };

  // 設定の保存ハンドラー
  const handleSaveSettings = async () => {
    if (!user) {
      setSnackbar({
        open: true,
        message: 'ユーザーが認証されていません。再度ログインしてください。',
        severity: 'error',
      });
      return;
    }

    const db = getFirestore();
    const userDocRef = doc(db, 'userSettings', user.uid);

    const settings = {
      targetProfitMargin,
      exchangeRate,
      categories,
      shippingRateTemplates,
      selectedShippingTemplateId,
      fees,
      bestOfferAutoAcceptPercentage,
      minimumBestOfferPercentage,
    };

    try {
      await setDoc(userDocRef, settings);
      setSnackbar({
        open: true,
        message: '設定を保存しました',
        severity: 'success',
      });
    } catch (error) {
      console.error('設定の保存に失敗しました:', error);
      setSnackbar({
        open: true,
        message: '設定の保存に失敗しました',
        severity: 'error',
      });
    }
  };

  // 設定のリセットハンドラー
  const handleResetSettings = () => {
    setIsResetDialogOpen(true);
  };

  // リセットの確認ハンドラー
  const confirmResetSettings = () => {
    // デフォルトの設定にリセットする処理を実装
    setTargetProfitMargin(25);
    setExchangeRate(140);
    setCategories({
      additionalCosts: [],
      fees: [],
    });
    setShippingRateTemplates([
      {
        id: 'default',
        name: 'デフォルト送料設定',
        shippingRates: [
          {
            minPriceJPY: 0,
            maxPriceJPY: 7000,
            costJPY: 700,
            minPriceUSD: 0,
            maxPriceUSD: 50,
            costUSD: 5,
          },
          {
            minPriceJPY: 7000,
            maxPriceJPY: 14000,
            costJPY: 1400,
            minPriceUSD: 50,
            maxPriceUSD: 100,
            costUSD: 10,
          },
          {
            minPriceJPY: 14000,
            maxPriceJPY: Infinity,
            costJPY: 2100,
            minPriceUSD: 100,
            maxPriceUSD: Infinity,
            costUSD: 15,
          },
        ],
      },
    ]);
    setSelectedShippingTemplateId('default');
    setFees({
      ebayFinalValue: 10,
      payoneer: 2,
      promotedListing: 3,
      ebayTransactionFee: 0.3,
    });
    setBestOfferAutoAcceptPercentage(95);
    setMinimumBestOfferPercentage(80);
    setIsResetDialogOpen(false);
    setSnackbar({
      open: true,
      message: '設定をデフォルトに戻しました',
      severity: 'info',
    });
  };

  // ドラッグ＆ドロップの終了ハンドラー
  const onDragEnd = (result) => {
    if (!result.destination) return;

    const sourceCategory = result.source.droppableId;
    const destCategory = result.destination.droppableId;

    setCategories((prevCategories) => {
      const newCategories = { ...prevCategories };
      const [movedItem] = newCategories[sourceCategory].splice(result.source.index, 1);
      newCategories[destCategory].splice(result.destination.index, 0, movedItem);
      return newCategories;
    });

    setSnackbar({
      open: true,
      message: '項目の順序を変更しました',
      severity: 'info',
    });
  };

  // 項目の値を変更するハンドラー
  const handleItemValueChange = (categoryKey, itemId, newValue, field) => {
    setCategories((prevCategories) => ({
      ...prevCategories,
      [categoryKey]: prevCategories[categoryKey].map((item) =>
        item.id === itemId ? { ...item, [field]: field === 'value' ? parseFloat(newValue) || 0 : newValue } : item
      ),
    }));
  };

  // 項目を削除するハンドラー
  const handleRemoveItem = (categoryKey, itemId) => {
    setCategories((prevCategories) => ({
      ...prevCategories,
      [categoryKey]: prevCategories[categoryKey].filter((item) => item.id !== itemId),
    }));
    setSnackbar({
      open: true,
      message: '項目を削除しました',
      severity: 'info',
    });
  };

  // 項目を追加するハンドラー
  const handleAddItem = (categoryKey) => {
    const newItem = { id: `new-item-${Date.now()}`, name: '新しい項目', value: 0, type: 'fixed' };
    setCategories((prevCategories) => ({
      ...prevCategories,
      [categoryKey]: [...prevCategories[categoryKey], newItem],
    }));
    setSnackbar({
      open: true,
      message: '新しい項目を追加しました',
      severity: 'success',
    });
  };

  // カテゴリーをレンダリングする関数
  const renderCategory = (categoryKey, categoryName) => (
    <Accordion key={categoryKey}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>{categoryName}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Droppable droppableId={categoryKey}>
          {(provided) => (
            <Box {...provided.droppableProps} ref={provided.innerRef}>
              {categories[categoryKey].map((item, index) => (
                <Draggable key={item.id} draggableId={item.id} index={index}>
                  {(provided) => (
                    <Box
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      sx={{ display: 'flex', alignItems: 'center', mb: 1 }}
                    >
                      <TextField
                        label="項目名"
                        value={item.name}
                        onChange={(e) => handleItemValueChange(categoryKey, item.id, e.target.value, 'name')}
                        size="small"
                        sx={{ flexGrow: 1, mr: 1 }}
                      />
                      <TextField
                        label="値"
                        value={item.value}
                        onChange={(e) => handleItemValueChange(categoryKey, item.id, e.target.value, 'value')}
                        type="number"
                        size="small"
                        sx={{ width: 100, mr: 1 }}
                      />
                      <FormControl size="small" sx={{ minWidth: 120, mr: 1 }}>
                        <InputLabel>タイプ</InputLabel>
                        <Select
                          value={item.type}
                          onChange={(e) => handleItemValueChange(categoryKey, item.id, e.target.value, 'type')}
                          label="タイプ"
                        >
                          <MenuItem value="fixed">固定</MenuItem>
                          <MenuItem value="percentage">割合 (%)</MenuItem>
                        </Select>
                      </FormControl>
                      <IconButton onClick={() => handleRemoveItem(categoryKey, item.id)}>
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </Box>
          )}
        </Droppable>
        <Button startIcon={<AddIcon />} onClick={() => handleAddItem(categoryKey)}>
          項目を追加
        </Button>
      </AccordionDetails>
    </Accordion>
  );

  // 価格を計算する関数
  const calculatePrices = () => {
    setIsCalculating(true);
    setError(null);

    try {
      if (!data || data.length === 0) {
        throw new Error('データが不足しています。');
      }

      const columns = Object.keys(data[0]);
      if (!columns.includes('StartPrice')) {
        throw new Error('StartPrice列が見つかりません。');
      }

      const selectedShippingTemplate = shippingRateTemplates.find(
        (template) => template.id === selectedShippingTemplateId
      );

      if (!selectedShippingTemplate) {
        throw new Error('選択された送料テンプレートが見つかりません。');
      }

      const newCalculatedPrices = data.map((item) => {
        const costPrice = parseFloat(item.StartPrice);
        if (isNaN(costPrice)) {
          return null;
        }

        let totalCost = costPrice;
        let totalPercentage = 0;

        // カテゴリーの項目を適用
        Object.values(categories)
          .flat()
          .forEach((categoryItem) => {
            if (categoryItem.type === 'fixed') {
              totalCost += parseFloat(categoryItem.value) || 0;
            } else if (categoryItem.type === 'percentage') {
              totalPercentage += parseFloat(categoryItem.value) || 0;
            }
          });

        // 手数料を追加
        totalPercentage +=
          parseFloat(fees.ebayFinalValue || 0) +
          parseFloat(fees.payoneer || 0) +
          parseFloat(fees.promotedListing || 0);
        totalCost += parseFloat(fees.ebayTransactionFee || 0);

        // 送料を追加
        const shippingRate = selectedShippingTemplate?.shippingRates?.find(
          (rate) =>
            costPrice >= parseFloat(rate.minPriceUSD) &&
            (parseFloat(rate.maxPriceUSD) === Infinity || costPrice < parseFloat(rate.maxPriceUSD))
        );
        if (shippingRate) {
          totalCost += parseFloat(shippingRate.costUSD) || 0;
        }

        // 目標利益率を追加
        totalPercentage += parseFloat(targetProfitMargin) || 0;

        // 販売価格を計算
        let calculatedPrice = totalCost / (1 - totalPercentage / 100);
        calculatedPrice = adjustPriceTo99(calculatedPrice);

        // ベストオファー価格を計算
        const bestOfferAutoAcceptPrice = calculatedPrice * (bestOfferAutoAcceptPercentage / 100);
        const minimumBestOfferPrice = calculatedPrice * (minimumBestOfferPercentage / 100);

        // 利益と利益率を計算
        const profit = calculatedPrice - totalCost;
        const profitMargin = (profit / calculatedPrice) * 100;

        return {
          originalCostPrice: costPrice.toFixed(2),
          calculatedPrice: calculatedPrice.toFixed(2),
          bestOfferAutoAcceptPrice: bestOfferAutoAcceptPrice.toFixed(2),
          minimumBestOfferPrice: minimumBestOfferPrice.toFixed(2),
          profit: profit.toFixed(2),
          profitMargin: profitMargin.toFixed(2),
        };
      });

      setCalculatedPrices(newCalculatedPrices.filter((price) => price !== null));
    } catch (err) {
      setError(err.message);
    } finally {
      setIsCalculating(false);
    }
  };

  // 価格を99に調整する関数
  const adjustPriceTo99 = (price) => {
    return Math.floor(price) + 0.99;
  };

  // 価格を適用するハンドラー
  const handleApplyPrices = () => {
    try {
      // 価格を適用する前に元のデータを保存
      if (!originalData) {
        setOriginalData([...data]);
      }

      const updatedData = data.map((item, index) => {
        const calculatedPrice = calculatedPrices[index];
        if (!calculatedPrice) return item;

        return {
          ...item,
          StartPrice: calculatedPrice.calculatedPrice,
          BestOfferAutoAcceptPrice: calculatedPrice.bestOfferAutoAcceptPrice,
          MinimumBestOfferPrice: calculatedPrice.minimumBestOfferPrice,
          Profit: calculatedPrice.profit,
          ProfitMargin: calculatedPrice.profitMargin,
        };
      });

      setData(updatedData);
      setSnackbar({
        open: true,
        message: '価格が更新されました',
        severity: 'success',
      });
      setIsPriceApplied(true); // 価格が適用されたことを記録
    } catch (err) {
      setError('価格の更新に失敗しました: ' + err.message);
    }
  };

  // 価格を元に戻すハンドラー
  const handleRestorePrices = () => {
    if (originalData) {
      setData(originalData);
      setOriginalData(null); // 元のデータをクリア
      setSnackbar({
        open: true,
        message: '価格を元に戻されました',
        severity: 'success',
      });
      setIsPriceApplied(false); // 価格が適用されていない状態に戻す
    } else {
      setSnackbar({
        open: true,
        message: '元の価格がありません',
        severity: 'warning',
      });
    }
  };

  // テンプレート名の編集ハンドラー
  const handleEditTemplateName = (templateId) => {
    const template = shippingRateTemplates.find((t) => t.id === templateId);
    if (template) {
      setEditingTemplateId(templateId);
      setNewTemplateName(template.name);
      setIsEditTemplateNameDialogOpen(true);
    }
  };

  // テンプレート名を更新するハンドラー
  const handleTemplateNameUpdate = () => {
    setShippingRateTemplates((prevTemplates) =>
      prevTemplates.map((template) =>
        template.id === editingTemplateId ? { ...template, name: newTemplateName } : template
      )
    );
    setIsEditTemplateNameDialogOpen(false);
    setSnackbar({
      open: true,
      message: 'テンプレート名を更新しました',
      severity: 'success',
    });
  };

  // 新しい送料テンプレートを追加するハンドラー
  const handleAddShippingTemplate = () => {
    const newTemplateId = `template-${Date.now()}`;
    setShippingRateTemplates((prevTemplates) => [
      ...prevTemplates,
      {
        id: newTemplateId,
        name: `新しいテンプレート ${prevTemplates.length + 1}`,
        shippingRates: [],
      },
    ]);
    setSelectedShippingTemplateId(newTemplateId);
    setSnackbar({
      open: true,
      message: '新しい送料テンプレートを追加しました',
      severity: 'success',
    });
  };

  // 送料テンプレートを削除するハンドラー
  const handleRemoveShippingTemplate = (templateId) => {
    setShippingRateTemplates((prevTemplates) => prevTemplates.filter((template) => template.id !== templateId));
    if (selectedShippingTemplateId === templateId && shippingRateTemplates.length > 1) {
      setSelectedShippingTemplateId(shippingRateTemplates[0].id);
    }
    setSnackbar({
      open: true,
      message: '送料テンプレートを削除しました',
      severity: 'info',
    });
  };

  // 送料設定を変更するハンドラー
  const handleShippingRateChange = (index, field, value) => {
    setShippingRateTemplates((prevTemplates) =>
      prevTemplates.map((template) =>
        template.id === selectedShippingTemplateId
          ? {
              ...template,
              shippingRates: template.shippingRates.map((rate, i) =>
                i === index
                  ? {
                      ...rate,
                      [field]: parseFloat(value),
                      [field.endsWith('JPY') ? field.replace('JPY', 'USD') : field.replace('USD', 'JPY')]:
                        field.endsWith('JPY')
                          ? (parseFloat(value) / exchangeRate).toFixed(2)
                          : Math.round(parseFloat(value) * exchangeRate),
                    }
                  : rate
              ),
            }
          : template
      )
    );
  };

  // 送料設定を削除するハンドラー
  const handleRemoveShippingRate = (index) => {
    setShippingRateTemplates((prevTemplates) =>
      prevTemplates.map((template) =>
        template.id === selectedShippingTemplateId
          ? {
              ...template,
              shippingRates: template.shippingRates.filter((_, i) => i !== index),
            }
          : template
      )
    );
  };

  // 新しい送料設定を追加するハンドラー
  const handleAddShippingRate = () => {
    setShippingRateTemplates((prevTemplates) =>
      prevTemplates.map((template) =>
        template.id === selectedShippingTemplateId
          ? {
              ...template,
              shippingRates: [
                ...template.shippingRates,
                { minPriceJPY: 0, maxPriceJPY: 0, costJPY: 0, minPriceUSD: 0, maxPriceUSD: 0, costUSD: 0 },
              ],
            }
          : template
      )
    );
  };

  // 手数料を変更するハンドラー
  const handleFeeChange = (feeType, value) => {
    setFees((prevFees) => ({
      ...prevFees,
      [feeType]: parseFloat(value),
    }));
  };

  // ベストオファー自動承認価格の変更ハンドラー
  const handleBestOfferAutoAcceptChange = (event) => {
    setBestOfferAutoAcceptPercentage(parseFloat(event.target.value));
  };

  // 最小ベストオファー価格の変更ハンドラー
  const handleMinimumBestOfferChange = (event) => {
    setMinimumBestOfferPercentage(parseFloat(event.target.value));
  };

  // 価格シミュレーションダイアログを閉じる関数
  const closePriceSimulation = () => {
    setShowPriceSimulation(false);
  };

  // 通貨のフォーマット関数
  const formatCurrency = (value, currency = 'USD') => {
    if (value === undefined || value === null || isNaN(parseFloat(value))) {
      return currency === 'USD' ? '$0.00' : '¥0';
    }
    if (currency === 'USD') {
      return `$${parseFloat(value).toFixed(2)}`;
    } else if (currency === 'JPY') {
      return `¥${Math.round(parseFloat(value) * exchangeRate)}`;
    }
  };

  // 設定項目を表示するコンポーネント
  const SettingItem = ({ label, value }) => (
    <Typography variant="body2">
      <strong>{label}:</strong> {value}
    </Typography>
  );

  // 選択された送料テンプレートの名前を取得
  const selectedShippingTemplate =
    shippingRateTemplates.find((template) => template.id === selectedShippingTemplateId) ||
    shippingRateTemplates[0];

  const selectedShippingTemplateName = selectedShippingTemplate
    ? selectedShippingTemplate.name
    : 'デフォルト送料設定';

  // 商品の価格を手入力で変更するハンドラー
  const handlePriceChange = (index, newPrice) => {
    const updatedData = data.map((item, i) => {
      if (i === index) {
        const newStartPrice = newPrice;

        // 新しい価格を数値に変換して計算
        const newStartPriceFloat = parseFloat(newStartPrice);
        if (isNaN(newStartPriceFloat)) {
          return { ...item, StartPrice: newStartPrice };
        }

        // ベストオファー価格を再計算
        const newBestOfferAutoAcceptPrice = newStartPriceFloat * (bestOfferAutoAcceptPercentage / 100);
        const newMinimumBestOfferPrice = newStartPriceFloat * (minimumBestOfferPercentage / 100);

        // 利益と利益率を再計算（CostPriceが存在する場合）
        let profit = null;
        let profitMargin = null;
        const costPrice = parseFloat(item.StartPrice);
        if (!isNaN(costPrice)) {
          profit = newStartPriceFloat - costPrice;
          profitMargin = (profit / newStartPriceFloat) * 100;
        }

        return {
          ...item,
          StartPrice: newStartPrice, // ユーザーの入力をそのまま保持
          BestOfferAutoAcceptPrice: newBestOfferAutoAcceptPrice.toFixed(2),
          MinimumBestOfferPrice: newMinimumBestOfferPrice.toFixed(2),
          Profit: profit !== null ? profit.toFixed(2) : '',
          ProfitMargin: profitMargin !== null ? profitMargin.toFixed(2) : '',
        };
      }
      return item;
    });

    setData(updatedData);
  };

  // ベストオファー価格を手入力で変更するハンドラー
  const handleBestOfferPriceChange = (index, field, newValue) => {
    const updatedData = data.map((item, i) => {
      if (i === index) {
        return {
          ...item,
          [field]: newValue, // ユーザーの入力をそのまま保持
        };
      }
      return item;
    });
    setData(updatedData);
  };

  return (
    <Box sx={{ flexGrow: 1, p: 2 }}>
      <Grid container spacing={2}>
        {/* 左側のコンテンツ */}
        <Grid item xs={12} md={6}>
          <Typography variant="h4" gutterBottom>
            価格管理
            <Tooltip title="ヘルプを表示">
              <IconButton onClick={() => setIsHelpDialogOpen(true)}>
                <HelpOutlineIcon />
              </IconButton>
            </Tooltip>
          </Typography>

          {/* 目標利益率設定 */}
          <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
            <Typography variant="h6" gutterBottom>
              目標利益率: {targetProfitMargin}%
            </Typography>
            <Slider
              value={targetProfitMargin}
              onChange={handleProfitMarginChange}
              aria-labelledby="profit-margin-slider"
              valueLabelDisplay="auto"
              step={1}
              marks
              min={0}
              max={100}
            />
          </Paper>

          {/* 為替レート設定 */}
          <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
            <Typography variant="h6" gutterBottom>
              為替レート設定
            </Typography>
            <Typography>1 USD = {exchangeRate.toFixed(2)} JPY</Typography>
            <TextField
              label="為替レート (1 USD = X JPY)"
              value={exchangeRate}
              onChange={handleExchangeRateChange}
              type="number"
              inputProps={{ step: '0.01' }}
              sx={{ width: 200, mt: 2 }}
            />
          </Paper>

          {/* その他の設定ボタン */}
          <Grid container spacing={2} sx={{ mb: 3 }}>
            <Grid item xs={12} sm={4}>
              <Button variant="outlined" color="primary" onClick={() => setIsShippingDialogOpen(true)} fullWidth>
                送料設定
              </Button>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Button variant="outlined" color="primary" onClick={() => setIsFeeDialogOpen(true)} fullWidth>
                手数料設定
              </Button>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Button variant="outlined" color="primary" onClick={() => setIsBestOfferDialogOpen(true)} fullWidth>
                ベストオファー設定
              </Button>
            </Grid>
          </Grid>

          {/* カテゴリー設定 */}
          <DragDropContext onDragEnd={onDragEnd}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                {renderCategory('additionalCosts', '上乗せ金額')}
              </Grid>
              <Grid item xs={12} md={6}>
                {renderCategory('fees', '手数料')}
              </Grid>
            </Grid>
          </DragDropContext>

          {/* 設定保存とリセット */}
          <Box sx={{ mt: 3, display: 'flex', justifyContent: 'space-between' }}>
            <Button startIcon={<SaveIcon />} variant="contained" color="primary" onClick={handleSaveSettings}>
              設定を保存
            </Button>
            <Button startIcon={<SettingsBackupRestoreIcon />} variant="outlined" onClick={handleResetSettings}>
              デフォルトに戻す
            </Button>
          </Box>
        </Grid>

        {/* 右側のコンテンツ */}
        <Grid item xs={12} md={6}>
          <Box sx={{ mt: { xs: 4, md: 0 } }}>
            <Typography variant="h5" gutterBottom>
              価格計算と適用
            </Typography>

            {/* 現在の設定セクション */}
            <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
              <Typography variant="h6" gutterBottom>
                現在の設定
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <SettingItem label="目標利益率" value={`${targetProfitMargin}%`} />
                  <SettingItem label="eBay最終価値手数料" value={`${fees.ebayFinalValue}%`} />
                  <SettingItem label="Payoneer手数料" value={`${fees.payoneer}%`} />
                  <SettingItem label="広告出品手数料" value={`${fees.promotedListing}%`} />
                  <SettingItem
                    label="eBay取引手数料"
                    value={`${formatCurrency(fees.ebayTransactionFee)} (${formatCurrency(
                      fees.ebayTransactionFee,
                      'JPY'
                    )})`}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <SettingItem label="為替レート" value={`1 USD = ${exchangeRate.toFixed(2)} JPY`} />
                  <SettingItem label="Best Offer自動承認価格" value={`${bestOfferAutoAcceptPercentage}%`} />
                  <SettingItem label="最小Best Offer価格" value={`${minimumBestOfferPercentage}%`} />
                  <SettingItem label="適用されている送料テンプレート" value={`${selectedShippingTemplateName}`} />
                </Grid>
              </Grid>
            </Paper>

            {error && (
              <Alert severity="error" sx={{ mb: 2 }}>
                {error}
              </Alert>
            )}

            {/* 適用状態の表示 */}
            {isPriceApplied ? (
              <Alert
                severity="success"
                iconMapping={{
                  success: <CheckCircleIcon fontSize="inherit" />,
                }}
                sx={{ mb: 2, fontWeight: 'bold', textAlign: 'center' }}
              >
                適用完了
              </Alert>
            ) : (
              <Alert
                severity="warning"
                iconMapping={{
                  warning: <WarningAmberIcon fontSize="inherit" />,
                }}
                sx={{ mb: 2, fontWeight: 'bold', textAlign: 'center' }}
              >
                未適用
              </Alert>
            )}

            <Grid container spacing={2} sx={{ mb: 2 }}>
              <Grid item xs={12} sm={6}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={calculatePrices}
                  disabled={isCalculating}
                  fullWidth
                >
                  {isCalculating ? '計算中...' : '価格を再計算'}
                </Button>
              </Grid>
            </Grid>

            {calculatedPrices.length > 0 && (
              <Grid container spacing={2} sx={{ mt: 2 }}>
                <Grid item xs={12} sm={4}>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => setShowPriceSimulation(true)}
                    fullWidth
                  >
                    価格シミュレーション結果
                  </Button>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleApplyPrices}
                    fullWidth
                    disabled={isPriceApplied}
                  >
                    計算した価格を適用
                  </Button>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={handleRestorePrices}
                    fullWidth
                    disabled={!isPriceApplied}
                  >
                    価格を元に戻す
                  </Button>
                </Grid>
              </Grid>
            )}
          </Box>
        </Grid>

        {/* 中央のセクション */}
        <Grid item xs={12}>
          <Box sx={{ mt: 4 }}>
            <Typography variant="h5" gutterBottom>
              商品の価格一覧
            </Typography>
            <Paper elevation={3} sx={{ p: 2 }}>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ width: '80px' }}>番号</TableCell>
                      <TableCell>タイトル</TableCell>
                      <TableCell>価格 (USD)</TableCell>
                      <TableCell>ベストオファー自動承認価格 (USD)</TableCell>
                      <TableCell>最小ベストオファー価格 (USD)</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.map((item, index) => (
                      <TableRow key={index}>
                        <TableCell sx={{ width: '80px' }}>{index + 1}</TableCell>
                        <TableCell>{item.Title}</TableCell>
                        <TableCell>
                          <TextField
                            type="text"
                            inputMode="decimal"
                            value={item.StartPrice}
                            onChange={(e) => handlePriceChange(index, e.target.value)}
                            size="small"
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            type="text"
                            inputMode="decimal"
                            value={item.BestOfferAutoAcceptPrice}
                            onChange={(e) =>
                              handleBestOfferPriceChange(index, 'BestOfferAutoAcceptPrice', e.target.value)
                            }
                            size="small"
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            type="text"
                            inputMode="decimal"
                            value={item.MinimumBestOfferPrice}
                            onChange={(e) =>
                              handleBestOfferPriceChange(index, 'MinimumBestOfferPrice', e.target.value)
                            }
                            size="small"
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Box>
        </Grid>
      </Grid>

      {/* ヘルプダイアログ */}
      <Dialog open={isHelpDialogOpen} onClose={() => setIsHelpDialogOpen(false)} maxWidth="md" fullWidth>
        <DialogTitle>価格計算の具体的な説明</DialogTitle>
        <DialogContent>
          {/* ここに価格計算の詳細な説明を表示 */}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsHelpDialogOpen(false)}>閉じる</Button>
        </DialogActions>
      </Dialog>

      {/* リセット確認ダイアログ */}
      <Dialog open={isResetDialogOpen} onClose={() => setIsResetDialogOpen(false)}>
        <DialogTitle>設定をリセット</DialogTitle>
        <DialogContent>
          <Typography>本当に設定をデフォルトに戻しますか？この操作は元に戻せません。</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsResetDialogOpen(false)}>キャンセル</Button>
          <Button onClick={confirmResetSettings} color="error">
            リセット
          </Button>
        </DialogActions>
      </Dialog>

      {/* 送料設定ダイアログ */}
      <Dialog open={isShippingDialogOpen} onClose={() => setIsShippingDialogOpen(false)} maxWidth="lg" fullWidth>
        <DialogTitle>送料設定</DialogTitle>
        <DialogContent>
          {/* 送料テンプレートの選択と管理 */}
          <Box sx={{ mb: 2 }}>
            <FormControl fullWidth>
              <InputLabel>送料テンプレートを選択</InputLabel>
              <Select
                value={selectedShippingTemplateId}
                onChange={(e) => setSelectedShippingTemplateId(e.target.value)}
                label="送料テンプレートを選択"
              >
                {shippingRateTemplates.map((template) => (
                  <MenuItem
                    key={template.id}
                    value={template.id}
                    sx={{ display: 'flex', alignItems: 'center' }}
                  >
                    <Typography variant="body1" sx={{ flexGrow: 1 }}>
                      {template.name}
                    </Typography>
                    <IconButton
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleEditTemplateName(template.id);
                      }}
                    >
                      <EditIcon fontSize="small" />
                    </IconButton>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Box sx={{ mt: 1, display: 'flex', justifyContent: 'space-between' }}>
              <Button startIcon={<AddIcon />} onClick={handleAddShippingTemplate}>
                新しいテンプレートを追加
              </Button>
              <Button
                startIcon={<DeleteIcon />}
                onClick={() => handleRemoveShippingTemplate(selectedShippingTemplateId)}
                disabled={shippingRateTemplates.length <= 1}
              >
                テンプレートを削除
              </Button>
            </Box>
          </Box>

          {/* 選択されたテンプレートの送料設定 */}
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>最小仕入れ価格 (JPY)</TableCell>
                  <TableCell>最大仕入れ価格 (JPY)</TableCell>
                  <TableCell>送料 (JPY)</TableCell>
                  <TableCell>最小仕入れ価格 (USD)</TableCell>
                  <TableCell>最大仕入れ価格 (USD)</TableCell>
                  <TableCell>送料 (USD)</TableCell>
                  <TableCell>操作</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {shippingRateTemplates
                  .find((template) => template.id === selectedShippingTemplateId)
                  .shippingRates.map((rate, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        <TextField
                          type="number"
                          value={rate.minPriceJPY}
                          onChange={(e) => handleShippingRateChange(index, 'minPriceJPY', e.target.value)}
                          size="small"
                          fullWidth
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          type="number"
                          value={rate.maxPriceJPY === Infinity ? '' : rate.maxPriceJPY}
                          onChange={(e) =>
                            handleShippingRateChange(index, 'maxPriceJPY', e.target.value || Infinity)
                          }
                          size="small"
                          fullWidth
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          type="number"
                          value={rate.costJPY}
                          onChange={(e) => handleShippingRateChange(index, 'costJPY', e.target.value)}
                          size="small"
                          fullWidth
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          type="number"
                          value={rate.minPriceUSD}
                          onChange={(e) => handleShippingRateChange(index, 'minPriceUSD', e.target.value)}
                          size="small"
                          fullWidth
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          type="number"
                          value={rate.maxPriceUSD === Infinity ? '' : rate.maxPriceUSD}
                          onChange={(e) =>
                            handleShippingRateChange(index, 'maxPriceUSD', e.target.value || Infinity)
                          }
                          size="small"
                          fullWidth
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          type="number"
                          value={rate.costUSD}
                          onChange={(e) => handleShippingRateChange(index, 'costUSD', e.target.value)}
                          size="small"
                          fullWidth
                        />
                      </TableCell>
                      <TableCell>
                        <IconButton onClick={() => handleRemoveShippingRate(index)}>
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Button startIcon={<AddIcon />} onClick={handleAddShippingRate} sx={{ mt: 2 }}>
            送料設定を追加
          </Button>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsShippingDialogOpen(false)}>閉じる</Button>
        </DialogActions>
      </Dialog>

      {/* テンプレート名編集ダイアログ */}
      <Dialog
        open={isEditTemplateNameDialogOpen}
        onClose={() => setIsEditTemplateNameDialogOpen(false)}
      >
        <DialogTitle>テンプレート名の編集</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            label="テンプレート名"
            value={newTemplateName}
            onChange={(e) => setNewTemplateName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsEditTemplateNameDialogOpen(false)}>キャンセル</Button>
          <Button onClick={handleTemplateNameUpdate} color="primary">
            更新
          </Button>
        </DialogActions>
      </Dialog>

      {/* 手数料設定ダイアログ */}
      <Dialog open={isFeeDialogOpen} onClose={() => setIsFeeDialogOpen(false)} maxWidth="sm" fullWidth>
        <DialogTitle>手数料設定</DialogTitle>
        <DialogContent>
          <List>
            <ListItem>
              <ListItemText primary="eBay最終価値手数料 (%)" />
              <ListItemSecondaryAction>
                <TextField
                  type="number"
                  value={fees.ebayFinalValue}
                  onChange={(e) => handleFeeChange('ebayFinalValue', e.target.value)}
                  size="small"
                />
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem>
              <ListItemText primary="Payoneer手数料 (%)" />
              <ListItemSecondaryAction>
                <TextField
                  type="number"
                  value={fees.payoneer}
                  onChange={(e) => handleFeeChange('payoneer', e.target.value)}
                  size="small"
                />
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem>
              <ListItemText primary="広告出品手数料 (%)" />
              <ListItemSecondaryAction>
                <TextField
                  type="number"
                  value={fees.promotedListing}
                  onChange={(e) => handleFeeChange('promotedListing', e.target.value)}
                  size="small"
                />
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem>
              <ListItemText primary="eBay取引手数料 ($)" />
              <ListItemSecondaryAction>
                <TextField
                  type="number"
                  value={fees.ebayTransactionFee}
                  onChange={(e) => handleFeeChange('ebayTransactionFee', e.target.value)}
                  size="small"
                />
              </ListItemSecondaryAction>
            </ListItem>
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsFeeDialogOpen(false)}>閉じる</Button>
        </DialogActions>
      </Dialog>

      {/* ベストオファー設定ダイアログ */}
      <Dialog
        open={isBestOfferDialogOpen}
        onClose={() => setIsBestOfferDialogOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>ベストオファー設定</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="ベストオファー自動承認価格 (%)"
                type="number"
                value={bestOfferAutoAcceptPercentage}
                onChange={handleBestOfferAutoAcceptChange}
                InputProps={{ inputProps: { min: 0, max: 100 } }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="最小ベストオファー価格 (%)"
                type="number"
                value={minimumBestOfferPercentage}
                onChange={handleMinimumBestOfferChange}
                InputProps={{ inputProps: { min: 0, max: 100 } }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsBestOfferDialogOpen(false)}>閉じる</Button>
        </DialogActions>
      </Dialog>

      {/* 価格シミュレーション結果ダイアログ */}
      <Dialog open={showPriceSimulation} onClose={closePriceSimulation} maxWidth="lg" fullWidth>
        <DialogTitle>価格シミュレーション結果</DialogTitle>
        <DialogContent sx={{ height: '90vh' }}>
          {/* 価格シミュレーション結果を表示 */}
          {calculatedPrices.length > 0 && (
            <TableContainer component={Paper} sx={{ maxHeight: '100%', overflow: 'auto' }}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>原価 (USD)</TableCell>
                    <TableCell>計算後の価格 (USD)</TableCell>
                    <TableCell>ベストオファー自動承認価格 (USD)</TableCell>
                    <TableCell>最小ベストオファー価格 (USD)</TableCell>
                    <TableCell>利益 (USD)</TableCell>
                    <TableCell>利益率 (%)</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {calculatedPrices.map((price, index) => (
                    <TableRow key={index}>
                      <TableCell>{formatCurrency(price.StartPrice)}</TableCell>
                      <TableCell>{formatCurrency(price.calculatedPrice)}</TableCell>
                      <TableCell>{formatCurrency(price.bestOfferAutoAcceptPrice)}</TableCell>
                      <TableCell>{formatCurrency(price.minimumBestOfferPrice)}</TableCell>
                      <TableCell>{formatCurrency(price.profit)}</TableCell>
                      <TableCell>{price.profitMargin}%</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={closePriceSimulation}>閉じる</Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default PriceManager;





// // PriceManager.js

// import React, { useState, useEffect } from 'react';
// import {
//   Box,
//   Typography,
//   Button,
//   Paper,
//   Slider,
//   Dialog,
//   DialogTitle,
//   DialogContent,
//   DialogActions,
//   Grid,
//   TextField,
//   Accordion,
//   AccordionSummary,
//   AccordionDetails,
//   IconButton,
//   Tooltip,
//   FormControl,
//   InputLabel,
//   Select,
//   MenuItem,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   Alert,
//   Snackbar,
//   List,
//   ListItem,
//   ListItemText,
//   ListItemSecondaryAction,
// } from '@mui/material';
// import {
//   ExpandMore as ExpandMoreIcon,
//   HelpOutline as HelpOutlineIcon,
//   Save as SaveIcon,
//   SettingsBackupRestore as SettingsBackupRestoreIcon,
//   Add as AddIcon,
//   Delete as DeleteIcon,
//   Edit as EditIcon,
//   CheckCircle as CheckCircleIcon,
//   WarningAmber as WarningAmberIcon,
// } from '@mui/icons-material';
// import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

// // Firebase関連のインポート
// import { getAuth } from 'firebase/auth';
// import { getFirestore, doc, getDoc, setDoc } from 'firebase/firestore';

// const PriceManager = ({ data, setData }) => {
//   // ユーザー状態の定義
//   const [user, setUser] = useState(null);

//   // ユーザーの取得
//   useEffect(() => {
//     const auth = getAuth();
//     const unsubscribe = auth.onAuthStateChanged((firebaseUser) => {
//       setUser(firebaseUser);
//     });
//     return () => unsubscribe();
//   }, []);

//   // 状態変数の定義
//   const [targetProfitMargin, setTargetProfitMargin] = useState(25);
//   const [exchangeRate, setExchangeRate] = useState(140);
//   const [categories, setCategories] = useState({
//     additionalCosts: [],
//     fees: [],
//   });
//   const [isHelpDialogOpen, setIsHelpDialogOpen] = useState(false);
//   const [isResetDialogOpen, setIsResetDialogOpen] = useState(false);
//   const [isShippingDialogOpen, setIsShippingDialogOpen] = useState(false);
//   const [isFeeDialogOpen, setIsFeeDialogOpen] = useState(false);
//   const [isBestOfferDialogOpen, setIsBestOfferDialogOpen] = useState(false);
//   const [isEditTemplateNameDialogOpen, setIsEditTemplateNameDialogOpen] = useState(false);
//   const [editingTemplateId, setEditingTemplateId] = useState('');
//   const [newTemplateName, setNewTemplateName] = useState('');
//   const [selectedShippingTemplateId, setSelectedShippingTemplateId] = useState('default');
//   const [shippingRateTemplates, setShippingRateTemplates] = useState([
//     {
//       id: 'default',
//       name: 'デフォルト送料設定',
//       shippingRates: [
//         {
//           minPriceJPY: 0,
//           maxPriceJPY: 7000,
//           costJPY: 700,
//           minPriceUSD: 0,
//           maxPriceUSD: 50,
//           costUSD: 5,
//         },
//         {
//           minPriceJPY: 7000,
//           maxPriceJPY: 14000,
//           costJPY: 1400,
//           minPriceUSD: 50,
//           maxPriceUSD: 100,
//           costUSD: 10,
//         },
//         {
//           minPriceJPY: 14000,
//           maxPriceJPY: Infinity,
//           costJPY: 2100,
//           minPriceUSD: 100,
//           maxPriceUSD: Infinity,
//           costUSD: 15,
//         },
//       ],
//     },
//   ]);
//   const [fees, setFees] = useState({
//     ebayFinalValue: 10,
//     payoneer: 2,
//     promotedListing: 3,
//     ebayTransactionFee: 0.3,
//   });
//   const [bestOfferAutoAcceptPercentage, setBestOfferAutoAcceptPercentage] = useState(95);
//   const [minimumBestOfferPercentage, setMinimumBestOfferPercentage] = useState(80);
//   const [snackbar, setSnackbar] = useState({
//     open: false,
//     message: '',
//     severity: 'info',
//   });
//   const [error, setError] = useState(null);
//   const [isPriceApplied, setIsPriceApplied] = useState(false);
//   const [isCalculating, setIsCalculating] = useState(false);
//   const [calculatedPrices, setCalculatedPrices] = useState([]);
//   const [showPriceSimulation, setShowPriceSimulation] = useState(false);
//   const [originalData, setOriginalData] = useState(null);

//   // ユーザー設定の読み込み
//   useEffect(() => {
//     if (user) {
//       const loadSettings = async () => {
//         const db = getFirestore();
//         const userDocRef = doc(db, 'userSettings', user.uid);
//         try {
//           const docSnap = await getDoc(userDocRef);
//           if (docSnap.exists()) {
//             const settings = docSnap.data();
//             setTargetProfitMargin(settings.targetProfitMargin || 25);
//             setExchangeRate(settings.exchangeRate || 140);
//             setCategories(settings.categories || { additionalCosts: [], fees: [] });
//             setShippingRateTemplates(settings.shippingRateTemplates || shippingRateTemplates);
//             setSelectedShippingTemplateId(settings.selectedShippingTemplateId || 'default');
//             setFees(
//               settings.fees || {
//                 ebayFinalValue: 10,
//                 payoneer: 2,
//                 promotedListing: 3,
//                 ebayTransactionFee: 0.3,
//               }
//             );
//             setBestOfferAutoAcceptPercentage(settings.bestOfferAutoAcceptPercentage || 95);
//             setMinimumBestOfferPercentage(settings.minimumBestOfferPercentage || 80);
//           }
//         } catch (error) {
//           console.error('設定の読み込みに失敗しました:', error);
//           setSnackbar({
//             open: true,
//             message: '設定の読み込みに失敗しました',
//             severity: 'error',
//           });
//         }
//       };
//       loadSettings();
//     }
//   }, [user]);

//   // 利益率の変更ハンドラー
//   const handleProfitMarginChange = (event, newValue) => {
//     setTargetProfitMargin(newValue);
//   };

//   // 為替レートの変更ハンドラー
//   const handleExchangeRateChange = (event) => {
//     const newRate = parseFloat(event.target.value);
//     if (!isNaN(newRate) && newRate > 0) {
//       setExchangeRate(newRate);
//     }
//   };

//   // 設定の保存ハンドラー
//   const handleSaveSettings = async () => {
//     if (!user) {
//       setSnackbar({
//         open: true,
//         message: 'ユーザーが認証されていません。再度ログインしてください。',
//         severity: 'error',
//       });
//       return;
//     }

//     const db = getFirestore();
//     const userDocRef = doc(db, 'userSettings', user.uid);

//     const settings = {
//       targetProfitMargin,
//       exchangeRate,
//       categories,
//       shippingRateTemplates,
//       selectedShippingTemplateId,
//       fees,
//       bestOfferAutoAcceptPercentage,
//       minimumBestOfferPercentage,
//     };

//     try {
//       await setDoc(userDocRef, settings);
//       setSnackbar({
//         open: true,
//         message: '設定を保存しました',
//         severity: 'success',
//       });
//     } catch (error) {
//       console.error('設定の保存に失敗しました:', error);
//       setSnackbar({
//         open: true,
//         message: '設定の保存に失敗しました',
//         severity: 'error',
//       });
//     }
//   };

//   // 設定のリセットハンドラー
//   const handleResetSettings = () => {
//     setIsResetDialogOpen(true);
//   };

//   // リセットの確認ハンドラー
//   const confirmResetSettings = () => {
//     // デフォルトの設定にリセットする処理を実装
//     setTargetProfitMargin(25);
//     setExchangeRate(140);
//     setCategories({
//       additionalCosts: [],
//       fees: [],
//     });
//     setShippingRateTemplates([
//       {
//         id: 'default',
//         name: 'デフォルト送料設定',
//         shippingRates: [
//           {
//             minPriceJPY: 0,
//             maxPriceJPY: 7000,
//             costJPY: 700,
//             minPriceUSD: 0,
//             maxPriceUSD: 50,
//             costUSD: 5,
//           },
//           {
//             minPriceJPY: 7000,
//             maxPriceJPY: 14000,
//             costJPY: 1400,
//             minPriceUSD: 50,
//             maxPriceUSD: 100,
//             costUSD: 10,
//           },
//           {
//             minPriceJPY: 14000,
//             maxPriceJPY: Infinity,
//             costJPY: 2100,
//             minPriceUSD: 100,
//             maxPriceUSD: Infinity,
//             costUSD: 15,
//           },
//         ],
//       },
//     ]);
//     setSelectedShippingTemplateId('default');
//     setFees({
//       ebayFinalValue: 10,
//       payoneer: 2,
//       promotedListing: 3,
//       ebayTransactionFee: 0.3,
//     });
//     setBestOfferAutoAcceptPercentage(95);
//     setMinimumBestOfferPercentage(80);
//     setIsResetDialogOpen(false);
//     setSnackbar({
//       open: true,
//       message: '設定をデフォルトに戻しました',
//       severity: 'info',
//     });
//   };

//   // ドラッグ＆ドロップの終了ハンドラー
//   const onDragEnd = (result) => {
//     if (!result.destination) return;

//     const sourceCategory = result.source.droppableId;
//     const destCategory = result.destination.droppableId;

//     setCategories((prevCategories) => {
//       const newCategories = { ...prevCategories };
//       const [movedItem] = newCategories[sourceCategory].splice(result.source.index, 1);
//       newCategories[destCategory].splice(result.destination.index, 0, movedItem);
//       return newCategories;
//     });

//     setSnackbar({
//       open: true,
//       message: '項目の順序を変更しました',
//       severity: 'info',
//     });
//   };

//   // 項目の値を変更するハンドラー
//   const handleItemValueChange = (categoryKey, itemId, newValue, field) => {
//     setCategories((prevCategories) => ({
//       ...prevCategories,
//       [categoryKey]: prevCategories[categoryKey].map((item) =>
//         item.id === itemId ? { ...item, [field]: field === 'value' ? parseFloat(newValue) || 0 : newValue } : item
//       ),
//     }));
//   };

//   // 項目を削除するハンドラー
//   const handleRemoveItem = (categoryKey, itemId) => {
//     setCategories((prevCategories) => ({
//       ...prevCategories,
//       [categoryKey]: prevCategories[categoryKey].filter((item) => item.id !== itemId),
//     }));
//     setSnackbar({
//       open: true,
//       message: '項目を削除しました',
//       severity: 'info',
//     });
//   };

//   // 項目を追加するハンドラー
//   const handleAddItem = (categoryKey) => {
//     const newItem = { id: `new-item-${Date.now()}`, name: '新しい項目', value: 0, type: 'fixed' };
//     setCategories((prevCategories) => ({
//       ...prevCategories,
//       [categoryKey]: [...prevCategories[categoryKey], newItem],
//     }));
//     setSnackbar({
//       open: true,
//       message: '新しい項目を追加しました',
//       severity: 'success',
//     });
//   };

//   // カテゴリーをレンダリングする関数
//   const renderCategory = (categoryKey, categoryName) => (
//     <Accordion key={categoryKey}>
//       <AccordionSummary expandIcon={<ExpandMoreIcon />}>
//         <Typography>{categoryName}</Typography>
//       </AccordionSummary>
//       <AccordionDetails>
//         <Droppable droppableId={categoryKey}>
//           {(provided) => (
//             <Box {...provided.droppableProps} ref={provided.innerRef}>
//               {categories[categoryKey].map((item, index) => (
//                 <Draggable key={item.id} draggableId={item.id} index={index}>
//                   {(provided) => (
//                     <Box
//                       ref={provided.innerRef}
//                       {...provided.draggableProps}
//                       {...provided.dragHandleProps}
//                       sx={{ display: 'flex', alignItems: 'center', mb: 1 }}
//                     >
//                       <TextField
//                         label="項目名"
//                         value={item.name}
//                         onChange={(e) => handleItemValueChange(categoryKey, item.id, e.target.value, 'name')}
//                         size="small"
//                         sx={{ flexGrow: 1, mr: 1 }}
//                       />
//                       <TextField
//                         label="値"
//                         value={item.value}
//                         onChange={(e) => handleItemValueChange(categoryKey, item.id, e.target.value, 'value')}
//                         type="number"
//                         size="small"
//                         sx={{ width: 100, mr: 1 }}
//                       />
//                       <FormControl size="small" sx={{ minWidth: 120, mr: 1 }}>
//                         <InputLabel>タイプ</InputLabel>
//                         <Select
//                           value={item.type}
//                           onChange={(e) => handleItemValueChange(categoryKey, item.id, e.target.value, 'type')}
//                           label="タイプ"
//                         >
//                           <MenuItem value="fixed">固定</MenuItem>
//                           <MenuItem value="percentage">割合 (%)</MenuItem>
//                         </Select>
//                       </FormControl>
//                       <IconButton onClick={() => handleRemoveItem(categoryKey, item.id)}>
//                         <DeleteIcon />
//                       </IconButton>
//                     </Box>
//                   )}
//                 </Draggable>
//               ))}
//               {provided.placeholder}
//             </Box>
//           )}
//         </Droppable>
//         <Button startIcon={<AddIcon />} onClick={() => handleAddItem(categoryKey)}>
//           項目を追加
//         </Button>
//       </AccordionDetails>
//     </Accordion>
//   );

//   // 価格を計算する関数
//   const calculatePrices = () => {
//     setIsCalculating(true);
//     setError(null);

//     try {
//       if (!data || data.length === 0) {
//         throw new Error('データが不足しています。');
//       }

//       const columns = Object.keys(data[0]);
//       if (!columns.includes('CostPrice')) {
//         throw new Error('CostPrice列が見つかりません。');
//       }

//       const selectedShippingTemplate = shippingRateTemplates.find(
//         (template) => template.id === selectedShippingTemplateId
//       );

//       if (!selectedShippingTemplate) {
//         throw new Error('選択された送料テンプレートが見つかりません。');
//       }

//       const newCalculatedPrices = data.map((item) => {
//         const costPrice = parseFloat(item.CostPrice);
//         if (isNaN(costPrice)) {
//           return null;
//         }

//         let totalCost = costPrice;
//         let totalPercentage = 0;

//         // カテゴリーの項目を適用
//         Object.values(categories)
//           .flat()
//           .forEach((categoryItem) => {
//             if (categoryItem.type === 'fixed') {
//               totalCost += parseFloat(categoryItem.value) || 0;
//             } else if (categoryItem.type === 'percentage') {
//               totalPercentage += parseFloat(categoryItem.value) || 0;
//             }
//           });

//         // 手数料を追加
//         totalPercentage +=
//           parseFloat(fees.ebayFinalValue || 0) +
//           parseFloat(fees.payoneer || 0) +
//           parseFloat(fees.promotedListing || 0);
//         totalCost += parseFloat(fees.ebayTransactionFee || 0);

//         // 送料を追加
//         const shippingRate = selectedShippingTemplate?.shippingRates?.find(
//           (rate) =>
//             costPrice >= parseFloat(rate.minPriceUSD) &&
//             (parseFloat(rate.maxPriceUSD) === Infinity || costPrice < parseFloat(rate.maxPriceUSD))
//         );
//         if (shippingRate) {
//           totalCost += parseFloat(shippingRate.costUSD) || 0;
//         }

//         // 目標利益率を追加
//         totalPercentage += parseFloat(targetProfitMargin) || 0;

//         // 販売価格を計算
//         let calculatedPrice = totalCost / (1 - totalPercentage / 100);
//         calculatedPrice = adjustPriceTo99(calculatedPrice);

//         // ベストオファー価格を計算
//         const bestOfferAutoAcceptPrice = calculatedPrice * (bestOfferAutoAcceptPercentage / 100);
//         const minimumBestOfferPrice = calculatedPrice * (minimumBestOfferPercentage / 100);

//         // 利益と利益率を計算
//         const profit = calculatedPrice - totalCost;
//         const profitMargin = (profit / calculatedPrice) * 100;

//         return {
//           originalCostPrice: costPrice.toFixed(2),
//           calculatedPrice: calculatedPrice.toFixed(2),
//           bestOfferAutoAcceptPrice: bestOfferAutoAcceptPrice.toFixed(2),
//           minimumBestOfferPrice: minimumBestOfferPrice.toFixed(2),
//           profit: profit.toFixed(2),
//           profitMargin: profitMargin.toFixed(2),
//         };
//       });

//       setCalculatedPrices(newCalculatedPrices.filter((price) => price !== null));
//     } catch (err) {
//       setError(err.message);
//     } finally {
//       setIsCalculating(false);
//     }
//   };

//   // 価格を99に調整する関数
//   const adjustPriceTo99 = (price) => {
//     return Math.floor(price) + 0.99;
//   };

//   // 価格を適用するハンドラー
//   const handleApplyPrices = () => {
//     try {
//       // 価格を適用する前に元のデータを保存
//       if (!originalData) {
//         setOriginalData([...data]);
//       }

//       const updatedData = data.map((item, index) => {
//         const calculatedPrice = calculatedPrices[index];
//         if (!calculatedPrice) return item;

//         return {
//           ...item,
//           StartPrice: calculatedPrice.calculatedPrice,
//           BestOfferAutoAcceptPrice: calculatedPrice.bestOfferAutoAcceptPrice,
//           MinimumBestOfferPrice: calculatedPrice.minimumBestOfferPrice,
//           Profit: calculatedPrice.profit,
//           ProfitMargin: calculatedPrice.profitMargin,
//         };
//       });

//       setData(updatedData);
//       setSnackbar({
//         open: true,
//         message: '価格が更新されました',
//         severity: 'success',
//       });
//       setIsPriceApplied(true); // 価格が適用されたことを記録
//     } catch (err) {
//       setError('価格の更新に失敗しました: ' + err.message);
//     }
//   };

//   // 価格を元に戻すハンドラー
//   const handleRestorePrices = () => {
//     if (originalData) {
//       setData(originalData);
//       setOriginalData(null); // 元のデータをクリア
//       setSnackbar({
//         open: true,
//         message: '価格を元に戻されました',
//         severity: 'success',
//       });
//       setIsPriceApplied(false); // 価格が適用されていない状態に戻す
//     } else {
//       setSnackbar({
//         open: true,
//         message: '元の価格がありません',
//         severity: 'warning',
//       });
//     }
//   };

//   // テンプレート名の編集ハンドラー
//   const handleEditTemplateName = (templateId) => {
//     const template = shippingRateTemplates.find((t) => t.id === templateId);
//     if (template) {
//       setEditingTemplateId(templateId);
//       setNewTemplateName(template.name);
//       setIsEditTemplateNameDialogOpen(true);
//     }
//   };

//   // テンプレート名を更新するハンドラー
//   const handleTemplateNameUpdate = () => {
//     setShippingRateTemplates((prevTemplates) =>
//       prevTemplates.map((template) =>
//         template.id === editingTemplateId ? { ...template, name: newTemplateName } : template
//       )
//     );
//     setIsEditTemplateNameDialogOpen(false);
//     setSnackbar({
//       open: true,
//       message: 'テンプレート名を更新しました',
//       severity: 'success',
//     });
//   };

//   // 新しい送料テンプレートを追加するハンドラー
//   const handleAddShippingTemplate = () => {
//     const newTemplateId = `template-${Date.now()}`;
//     setShippingRateTemplates((prevTemplates) => [
//       ...prevTemplates,
//       {
//         id: newTemplateId,
//         name: `新しいテンプレート ${prevTemplates.length + 1}`,
//         shippingRates: [],
//       },
//     ]);
//     setSelectedShippingTemplateId(newTemplateId);
//     setSnackbar({
//       open: true,
//       message: '新しい送料テンプレートを追加しました',
//       severity: 'success',
//     });
//   };

//   // 送料テンプレートを削除するハンドラー
//   const handleRemoveShippingTemplate = (templateId) => {
//     setShippingRateTemplates((prevTemplates) => prevTemplates.filter((template) => template.id !== templateId));
//     if (selectedShippingTemplateId === templateId && shippingRateTemplates.length > 1) {
//       setSelectedShippingTemplateId(shippingRateTemplates[0].id);
//     }
//     setSnackbar({
//       open: true,
//       message: '送料テンプレートを削除しました',
//       severity: 'info',
//     });
//   };

//   // 送料設定を変更するハンドラー
//   const handleShippingRateChange = (index, field, value) => {
//     setShippingRateTemplates((prevTemplates) =>
//       prevTemplates.map((template) =>
//         template.id === selectedShippingTemplateId
//           ? {
//               ...template,
//               shippingRates: template.shippingRates.map((rate, i) =>
//                 i === index
//                   ? {
//                       ...rate,
//                       [field]: parseFloat(value),
//                       [field.endsWith('JPY') ? field.replace('JPY', 'USD') : field.replace('USD', 'JPY')]:
//                         field.endsWith('JPY')
//                           ? (parseFloat(value) / exchangeRate).toFixed(2)
//                           : Math.round(parseFloat(value) * exchangeRate),
//                     }
//                   : rate
//               ),
//             }
//           : template
//       )
//     );
//   };

//   // 送料設定を削除するハンドラー
//   const handleRemoveShippingRate = (index) => {
//     setShippingRateTemplates((prevTemplates) =>
//       prevTemplates.map((template) =>
//         template.id === selectedShippingTemplateId
//           ? {
//               ...template,
//               shippingRates: template.shippingRates.filter((_, i) => i !== index),
//             }
//           : template
//       )
//     );
//   };

//   // 新しい送料設定を追加するハンドラー
//   const handleAddShippingRate = () => {
//     setShippingRateTemplates((prevTemplates) =>
//       prevTemplates.map((template) =>
//         template.id === selectedShippingTemplateId
//           ? {
//               ...template,
//               shippingRates: [
//                 ...template.shippingRates,
//                 { minPriceJPY: 0, maxPriceJPY: 0, costJPY: 0, minPriceUSD: 0, maxPriceUSD: 0, costUSD: 0 },
//               ],
//             }
//           : template
//       )
//     );
//   };

//   // 手数料を変更するハンドラー
//   const handleFeeChange = (feeType, value) => {
//     setFees((prevFees) => ({
//       ...prevFees,
//       [feeType]: parseFloat(value),
//     }));
//   };

//   // ベストオファー自動承認価格の変更ハンドラー
//   const handleBestOfferAutoAcceptChange = (event) => {
//     setBestOfferAutoAcceptPercentage(parseFloat(event.target.value));
//   };

//   // 最小ベストオファー価格の変更ハンドラー
//   const handleMinimumBestOfferChange = (event) => {
//     setMinimumBestOfferPercentage(parseFloat(event.target.value));
//   };

//   // 価格シミュレーションダイアログを閉じる関数
//   const closePriceSimulation = () => {
//     setShowPriceSimulation(false);
//   };

//   // 通貨のフォーマット関数
//   const formatCurrency = (value, currency = 'USD') => {
//     if (value === undefined || value === null || isNaN(parseFloat(value))) {
//       return currency === 'USD' ? '$0.00' : '¥0';
//     }
//     if (currency === 'USD') {
//       return `$${parseFloat(value).toFixed(2)}`;
//     } else if (currency === 'JPY') {
//       return `¥${Math.round(parseFloat(value) * exchangeRate)}`;
//     }
//   };

//   // 設定項目を表示するコンポーネント
//   const SettingItem = ({ label, value }) => (
//     <Typography variant="body2">
//       <strong>{label}:</strong> {value}
//     </Typography>
//   );

//   // 選択された送料テンプレートの名前を取得
//   const selectedShippingTemplate =
//     shippingRateTemplates.find((template) => template.id === selectedShippingTemplateId) ||
//     shippingRateTemplates[0];

//   const selectedShippingTemplateName = selectedShippingTemplate
//     ? selectedShippingTemplate.name
//     : 'デフォルト送料設定';

//   // 商品の価格を手入力で変更するハンドラー
//   const handlePriceChange = (index, newPrice) => {
//     const updatedData = data.map((item, i) => {
//       if (i === index) {
//         const newStartPrice = parseFloat(newPrice);
//         if (isNaN(newStartPrice)) {
//           return item;
//         }

//         // ベストオファー価格を再計算
//         const newBestOfferAutoAcceptPrice = newStartPrice * (bestOfferAutoAcceptPercentage / 100);
//         const newMinimumBestOfferPrice = newStartPrice * (minimumBestOfferPercentage / 100);

//         // 利益と利益率を再計算（CostPriceが存在する場合）
//         let profit = null;
//         let profitMargin = null;
//         const costPrice = parseFloat(item.CostPrice);
//         if (!isNaN(costPrice)) {
//           profit = newStartPrice - costPrice;
//           profitMargin = (profit / newStartPrice) * 100;
//         }

//         return {
//           ...item,
//           StartPrice: newStartPrice.toFixed(2),
//           BestOfferAutoAcceptPrice: newBestOfferAutoAcceptPrice.toFixed(2),
//           MinimumBestOfferPrice: newMinimumBestOfferPrice.toFixed(2),
//           Profit: profit !== null ? profit.toFixed(2) : '',
//           ProfitMargin: profitMargin !== null ? profitMargin.toFixed(2) : '',
//         };
//       }
//       return item;
//     });

//     setData(updatedData);
//   };

//   // ベストオファー価格を手入力で変更するハンドラー
//   const handleBestOfferPriceChange = (index, field, newValue) => {
//     const updatedData = data.map((item, i) => {
//       if (i === index) {
//         return {
//           ...item,
//           [field]: parseFloat(newValue).toFixed(2),
//         };
//       }
//       return item;
//     });
//     setData(updatedData);
//   };

//   return (
//     <Box sx={{ flexGrow: 1, p: 2 }}>
//       <Grid container spacing={2}>
//         {/* 左側のコンテンツ */}
//         <Grid item xs={12} md={6}>
//           <Typography variant="h4" gutterBottom>
//             価格管理
//             <Tooltip title="ヘルプを表示">
//               <IconButton onClick={() => setIsHelpDialogOpen(true)}>
//                 <HelpOutlineIcon />
//               </IconButton>
//             </Tooltip>
//           </Typography>

//           {/* 目標利益率設定 */}
//           <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
//             <Typography variant="h6" gutterBottom>
//               目標利益率: {targetProfitMargin}%
//             </Typography>
//             <Slider
//               value={targetProfitMargin}
//               onChange={handleProfitMarginChange}
//               aria-labelledby="profit-margin-slider"
//               valueLabelDisplay="auto"
//               step={1}
//               marks
//               min={0}
//               max={100}
//             />
//           </Paper>

//           {/* 為替レート設定 */}
//           <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
//             <Typography variant="h6" gutterBottom>
//               為替レート設定
//             </Typography>
//             <Typography>1 USD = {exchangeRate.toFixed(2)} JPY</Typography>
//             <TextField
//               label="為替レート (1 USD = X JPY)"
//               value={exchangeRate}
//               onChange={handleExchangeRateChange}
//               type="number"
//               inputProps={{ step: '0.01' }}
//               sx={{ width: 200, mt: 2 }}
//             />
//           </Paper>

//           {/* その他の設定ボタン */}
//           <Grid container spacing={2} sx={{ mb: 3 }}>
//             <Grid item xs={12} sm={4}>
//               <Button variant="outlined" color="primary" onClick={() => setIsShippingDialogOpen(true)} fullWidth>
//                 送料設定
//               </Button>
//             </Grid>
//             <Grid item xs={12} sm={4}>
//               <Button variant="outlined" color="primary" onClick={() => setIsFeeDialogOpen(true)} fullWidth>
//                 手数料設定
//               </Button>
//             </Grid>
//             <Grid item xs={12} sm={4}>
//               <Button variant="outlined" color="primary" onClick={() => setIsBestOfferDialogOpen(true)} fullWidth>
//                 ベストオファー設定
//               </Button>
//             </Grid>
//           </Grid>

//           {/* カテゴリー設定 */}
//           <DragDropContext onDragEnd={onDragEnd}>
//             <Grid container spacing={2}>
//               <Grid item xs={12} md={6}>
//                 {renderCategory('additionalCosts', '上乗せ金額')}
//               </Grid>
//               <Grid item xs={12} md={6}>
//                 {renderCategory('fees', '手数料')}
//               </Grid>
//             </Grid>
//           </DragDropContext>

//           {/* 設定保存とリセット */}
//           <Box sx={{ mt: 3, display: 'flex', justifyContent: 'space-between' }}>
//             <Button startIcon={<SaveIcon />} variant="contained" color="primary" onClick={handleSaveSettings}>
//               設定を保存
//             </Button>
//             <Button startIcon={<SettingsBackupRestoreIcon />} variant="outlined" onClick={handleResetSettings}>
//               デフォルトに戻す
//             </Button>
//           </Box>
//         </Grid>

//         {/* 右側のコンテンツ */}
//         <Grid item xs={12} md={6}>
//           <Box sx={{ mt: { xs: 4, md: 0 } }}>
//             <Typography variant="h5" gutterBottom>
//               価格計算と適用
//             </Typography>

//             {/* 現在の設定セクション */}
//             <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
//               <Typography variant="h6" gutterBottom>
//                 現在の設定
//               </Typography>
//               <Grid container spacing={2}>
//                 <Grid item xs={12} sm={6}>
//                   <SettingItem label="目標利益率" value={`${targetProfitMargin}%`} />
//                   <SettingItem label="eBay最終価値手数料" value={`${fees.ebayFinalValue}%`} />
//                   <SettingItem label="Payoneer手数料" value={`${fees.payoneer}%`} />
//                   <SettingItem label="広告出品手数料" value={`${fees.promotedListing}%`} />
//                   <SettingItem
//                     label="eBay取引手数料"
//                     value={`${formatCurrency(fees.ebayTransactionFee)} (${formatCurrency(
//                       fees.ebayTransactionFee,
//                       'JPY'
//                     )})`}
//                   />
//                 </Grid>
//                 <Grid item xs={12} sm={6}>
//                   <SettingItem label="為替レート" value={`1 USD = ${exchangeRate.toFixed(2)} JPY`} />
//                   <SettingItem label="Best Offer自動承認価格" value={`${bestOfferAutoAcceptPercentage}%`} />
//                   <SettingItem label="最小Best Offer価格" value={`${minimumBestOfferPercentage}%`} />
//                   <SettingItem label="適用されている送料テンプレート" value={`${selectedShippingTemplateName}`} />
//                 </Grid>
//               </Grid>
//             </Paper>

//             {error && (
//               <Alert severity="error" sx={{ mb: 2 }}>
//                 {error}
//               </Alert>
//             )}

//             {/* 適用状態の表示 */}
//             {isPriceApplied ? (
//               <Alert
//                 severity="success"
//                 iconMapping={{
//                   success: <CheckCircleIcon fontSize="inherit" />,
//                 }}
//                 sx={{ mb: 2, fontWeight: 'bold', textAlign: 'center' }}
//               >
//                 適用完了
//               </Alert>
//             ) : (
//               <Alert
//                 severity="warning"
//                 iconMapping={{
//                   warning: <WarningAmberIcon fontSize="inherit" />,
//                 }}
//                 sx={{ mb: 2, fontWeight: 'bold', textAlign: 'center' }}
//               >
//                 未適用
//               </Alert>
//             )}

//             <Grid container spacing={2} sx={{ mb: 2 }}>
//               <Grid item xs={12} sm={6}>
//                 <Button
//                   variant="contained"
//                   color="primary"
//                   onClick={calculatePrices}
//                   disabled={isCalculating}
//                   fullWidth
//                 >
//                   {isCalculating ? '計算中...' : '価格を再計算'}
//                 </Button>
//               </Grid>
//             </Grid>

//             {calculatedPrices.length > 0 && (
//               <Grid container spacing={2} sx={{ mt: 2 }}>
//                 <Grid item xs={12} sm={4}>
//                   <Button
//                     variant="contained"
//                     color="secondary"
//                     onClick={() => setShowPriceSimulation(true)}
//                     fullWidth
//                   >
//                     価格シミュレーション結果
//                   </Button>
//                 </Grid>
//                 <Grid item xs={12} sm={4}>
//                   <Button
//                     variant="contained"
//                     color="secondary"
//                     onClick={handleApplyPrices}
//                     fullWidth
//                     disabled={isPriceApplied}
//                   >
//                     計算した価格を適用
//                   </Button>
//                 </Grid>
//                 <Grid item xs={12} sm={4}>
//                   <Button
//                     variant="outlined"
//                     color="primary"
//                     onClick={handleRestorePrices}
//                     fullWidth
//                     disabled={!isPriceApplied}
//                   >
//                     価格を元に戻す
//                   </Button>
//                 </Grid>
//               </Grid>
//             )}
//           </Box>
//         </Grid>

//         {/* 中央のセクション */}
//         <Grid item xs={12}>
//           <Box sx={{ mt: 4 }}>
//             <Typography variant="h5" gutterBottom>
//               商品の価格一覧
//             </Typography>
//             <Paper elevation={3} sx={{ p: 2 }}>
//               <TableContainer component={Paper}>
//                 <Table>
//                   <TableHead>
//                     <TableRow>
//                       <TableCell sx={{ width: '80px' }}>番号</TableCell>
//                       <TableCell>タイトル</TableCell>
//                       <TableCell>価格 (USD)</TableCell>
//                       <TableCell>ベストオファー自動承認価格 (USD)</TableCell>
//                       <TableCell>最小ベストオファー価格 (USD)</TableCell>
//                     </TableRow>
//                   </TableHead>
//                   <TableBody>
//                     {data.map((item, index) => (
//                       <TableRow key={index}>
//                         <TableCell sx={{ width: '80px' }}>{index + 1}</TableCell>
//                         <TableCell>{item.Title}</TableCell>
//                         <TableCell>
//                           <TextField
//                             type="number"
//                             value={item.StartPrice}
//                             onChange={(e) => handlePriceChange(index, e.target.value)}
//                             size="small"
//                           />
//                         </TableCell>
//                         <TableCell>
//                           <TextField
//                             type="number"
//                             value={item.BestOfferAutoAcceptPrice}
//                             onChange={(e) =>
//                               handleBestOfferPriceChange(index, 'BestOfferAutoAcceptPrice', e.target.value)
//                             }
//                             size="small"
//                           />
//                         </TableCell>
//                         <TableCell>
//                           <TextField
//                             type="number"
//                             value={item.MinimumBestOfferPrice}
//                             onChange={(e) =>
//                               handleBestOfferPriceChange(index, 'MinimumBestOfferPrice', e.target.value)
//                             }
//                             size="small"
//                           />
//                         </TableCell>
//                       </TableRow>
//                     ))}
//                   </TableBody>
//                 </Table>
//               </TableContainer>
//             </Paper>
//           </Box>
//         </Grid>
//       </Grid>

//       {/* ヘルプダイアログ */}
//       <Dialog open={isHelpDialogOpen} onClose={() => setIsHelpDialogOpen(false)} maxWidth="md" fullWidth>
//         <DialogTitle>価格計算の具体的な説明</DialogTitle>
//         <DialogContent>
//           {/* ここに価格計算の詳細な説明を表示 */}
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => setIsHelpDialogOpen(false)}>閉じる</Button>
//         </DialogActions>
//       </Dialog>

//       {/* リセット確認ダイアログ */}
//       <Dialog open={isResetDialogOpen} onClose={() => setIsResetDialogOpen(false)}>
//         <DialogTitle>設定をリセット</DialogTitle>
//         <DialogContent>
//           <Typography>本当に設定をデフォルトに戻しますか？この操作は元に戻せません。</Typography>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => setIsResetDialogOpen(false)}>キャンセル</Button>
//           <Button onClick={confirmResetSettings} color="error">
//             リセット
//           </Button>
//         </DialogActions>
//       </Dialog>

//       {/* 送料設定ダイアログ */}
//       <Dialog open={isShippingDialogOpen} onClose={() => setIsShippingDialogOpen(false)} maxWidth="lg" fullWidth>
//         <DialogTitle>送料設定</DialogTitle>
//         <DialogContent>
//           {/* 送料テンプレートの選択と管理 */}
//           <Box sx={{ mb: 2 }}>
//             <FormControl fullWidth>
//               <InputLabel>送料テンプレートを選択</InputLabel>
//               <Select
//                 value={selectedShippingTemplateId}
//                 onChange={(e) => setSelectedShippingTemplateId(e.target.value)}
//                 label="送料テンプレートを選択"
//               >
//                 {shippingRateTemplates.map((template) => (
//                   <MenuItem
//                     key={template.id}
//                     value={template.id}
//                     sx={{ display: 'flex', alignItems: 'center' }}
//                   >
//                     <Typography variant="body1" sx={{ flexGrow: 1 }}>
//                       {template.name}
//                     </Typography>
//                     <IconButton
//                       size="small"
//                       onClick={(e) => {
//                         e.stopPropagation();
//                         handleEditTemplateName(template.id);
//                       }}
//                     >
//                       <EditIcon fontSize="small" />
//                     </IconButton>
//                   </MenuItem>
//                 ))}
//               </Select>
//             </FormControl>
//             <Box sx={{ mt: 1, display: 'flex', justifyContent: 'space-between' }}>
//               <Button startIcon={<AddIcon />} onClick={handleAddShippingTemplate}>
//                 新しいテンプレートを追加
//               </Button>
//               <Button
//                 startIcon={<DeleteIcon />}
//                 onClick={() => handleRemoveShippingTemplate(selectedShippingTemplateId)}
//                 disabled={shippingRateTemplates.length <= 1}
//               >
//                 テンプレートを削除
//               </Button>
//             </Box>
//           </Box>

//           {/* 選択されたテンプレートの送料設定 */}
//           <TableContainer component={Paper}>
//             <Table>
//               <TableHead>
//                 <TableRow>
//                   <TableCell>最小仕入れ価格 (JPY)</TableCell>
//                   <TableCell>最大仕入れ価格 (JPY)</TableCell>
//                   <TableCell>送料 (JPY)</TableCell>
//                   <TableCell>最小仕入れ価格 (USD)</TableCell>
//                   <TableCell>最大仕入れ価格 (USD)</TableCell>
//                   <TableCell>送料 (USD)</TableCell>
//                   <TableCell>操作</TableCell>
//                 </TableRow>
//               </TableHead>
//               <TableBody>
//                 {shippingRateTemplates
//                   .find((template) => template.id === selectedShippingTemplateId)
//                   .shippingRates.map((rate, index) => (
//                     <TableRow key={index}>
//                       <TableCell>
//                         <TextField
//                           type="number"
//                           value={rate.minPriceJPY}
//                           onChange={(e) => handleShippingRateChange(index, 'minPriceJPY', e.target.value)}
//                           size="small"
//                           fullWidth
//                         />
//                       </TableCell>
//                       <TableCell>
//                         <TextField
//                           type="number"
//                           value={rate.maxPriceJPY === Infinity ? '' : rate.maxPriceJPY}
//                           onChange={(e) =>
//                             handleShippingRateChange(index, 'maxPriceJPY', e.target.value || Infinity)
//                           }
//                           size="small"
//                           fullWidth
//                         />
//                       </TableCell>
//                       <TableCell>
//                         <TextField
//                           type="number"
//                           value={rate.costJPY}
//                           onChange={(e) => handleShippingRateChange(index, 'costJPY', e.target.value)}
//                           size="small"
//                           fullWidth
//                         />
//                       </TableCell>
//                       <TableCell>
//                         <TextField
//                           type="number"
//                           value={rate.minPriceUSD}
//                           onChange={(e) => handleShippingRateChange(index, 'minPriceUSD', e.target.value)}
//                           size="small"
//                           fullWidth
//                         />
//                       </TableCell>
//                       <TableCell>
//                         <TextField
//                           type="number"
//                           value={rate.maxPriceUSD === Infinity ? '' : rate.maxPriceUSD}
//                           onChange={(e) =>
//                             handleShippingRateChange(index, 'maxPriceUSD', e.target.value || Infinity)
//                           }
//                           size="small"
//                           fullWidth
//                         />
//                       </TableCell>
//                       <TableCell>
//                         <TextField
//                           type="number"
//                           value={rate.costUSD}
//                           onChange={(e) => handleShippingRateChange(index, 'costUSD', e.target.value)}
//                           size="small"
//                           fullWidth
//                         />
//                       </TableCell>
//                       <TableCell>
//                         <IconButton onClick={() => handleRemoveShippingRate(index)}>
//                           <DeleteIcon />
//                         </IconButton>
//                       </TableCell>
//                     </TableRow>
//                   ))}
//               </TableBody>
//             </Table>
//           </TableContainer>
//           <Button startIcon={<AddIcon />} onClick={handleAddShippingRate} sx={{ mt: 2 }}>
//             送料設定を追加
//           </Button>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => setIsShippingDialogOpen(false)}>閉じる</Button>
//         </DialogActions>
//       </Dialog>

//       {/* テンプレート名編集ダイアログ */}
//       <Dialog
//         open={isEditTemplateNameDialogOpen}
//         onClose={() => setIsEditTemplateNameDialogOpen(false)}
//       >
//         <DialogTitle>テンプレート名の編集</DialogTitle>
//         <DialogContent>
//           <TextField
//             fullWidth
//             label="テンプレート名"
//             value={newTemplateName}
//             onChange={(e) => setNewTemplateName(e.target.value)}
//           />
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => setIsEditTemplateNameDialogOpen(false)}>キャンセル</Button>
//           <Button onClick={handleTemplateNameUpdate} color="primary">
//             更新
//           </Button>
//         </DialogActions>
//       </Dialog>

//       {/* 手数料設定ダイアログ */}
//       <Dialog open={isFeeDialogOpen} onClose={() => setIsFeeDialogOpen(false)} maxWidth="sm" fullWidth>
//         <DialogTitle>手数料設定</DialogTitle>
//         <DialogContent>
//           <List>
//             <ListItem>
//               <ListItemText primary="eBay最終価値手数料 (%)" />
//               <ListItemSecondaryAction>
//                 <TextField
//                   type="number"
//                   value={fees.ebayFinalValue}
//                   onChange={(e) => handleFeeChange('ebayFinalValue', e.target.value)}
//                   size="small"
//                 />
//               </ListItemSecondaryAction>
//             </ListItem>
//             <ListItem>
//               <ListItemText primary="Payoneer手数料 (%)" />
//               <ListItemSecondaryAction>
//                 <TextField
//                   type="number"
//                   value={fees.payoneer}
//                   onChange={(e) => handleFeeChange('payoneer', e.target.value)}
//                   size="small"
//                 />
//               </ListItemSecondaryAction>
//             </ListItem>
//             <ListItem>
//               <ListItemText primary="広告出品手数料 (%)" />
//               <ListItemSecondaryAction>
//                 <TextField
//                   type="number"
//                   value={fees.promotedListing}
//                   onChange={(e) => handleFeeChange('promotedListing', e.target.value)}
//                   size="small"
//                 />
//               </ListItemSecondaryAction>
//             </ListItem>
//             <ListItem>
//               <ListItemText primary="eBay取引手数料 ($)" />
//               <ListItemSecondaryAction>
//                 <TextField
//                   type="number"
//                   value={fees.ebayTransactionFee}
//                   onChange={(e) => handleFeeChange('ebayTransactionFee', e.target.value)}
//                   size="small"
//                 />
//               </ListItemSecondaryAction>
//             </ListItem>
//           </List>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => setIsFeeDialogOpen(false)}>閉じる</Button>
//         </DialogActions>
//       </Dialog>

//       {/* ベストオファー設定ダイアログ */}
//       <Dialog
//         open={isBestOfferDialogOpen}
//         onClose={() => setIsBestOfferDialogOpen(false)}
//         maxWidth="sm"
//         fullWidth
//       >
//         <DialogTitle>ベストオファー設定</DialogTitle>
//         <DialogContent>
//           <Grid container spacing={2}>
//             <Grid item xs={12}>
//               <TextField
//                 fullWidth
//                 label="ベストオファー自動承認価格 (%)"
//                 type="number"
//                 value={bestOfferAutoAcceptPercentage}
//                 onChange={handleBestOfferAutoAcceptChange}
//                 InputProps={{ inputProps: { min: 0, max: 100 } }}
//               />
//             </Grid>
//             <Grid item xs={12}>
//               <TextField
//                 fullWidth
//                 label="最小ベストオファー価格 (%)"
//                 type="number"
//                 value={minimumBestOfferPercentage}
//                 onChange={handleMinimumBestOfferChange}
//                 InputProps={{ inputProps: { min: 0, max: 100 } }}
//               />
//             </Grid>
//           </Grid>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => setIsBestOfferDialogOpen(false)}>閉じる</Button>
//         </DialogActions>
//       </Dialog>

//       {/* 価格シミュレーション結果ダイアログ */}
//       <Dialog open={showPriceSimulation} onClose={closePriceSimulation} maxWidth="lg" fullWidth>
//         <DialogTitle>価格シミュレーション結果</DialogTitle>
//         <DialogContent sx={{ height: '90vh' }}>
//           {/* 価格シミュレーション結果を表示 */}
//           {calculatedPrices.length > 0 && (
//             <TableContainer component={Paper} sx={{ maxHeight: '100%', overflow: 'auto' }}>
//               <Table stickyHeader>
//                 <TableHead>
//                   <TableRow>
//                     <TableCell>原価 (USD)</TableCell>
//                     <TableCell>計算後の価格 (USD)</TableCell>
//                     <TableCell>ベストオファー自動承認価格 (USD)</TableCell>
//                     <TableCell>最小ベストオファー価格 (USD)</TableCell>
//                     <TableCell>利益 (USD)</TableCell>
//                     <TableCell>利益率 (%)</TableCell>
//                   </TableRow>
//                 </TableHead>
//                 <TableBody>
//                   {calculatedPrices.map((price, index) => (
//                     <TableRow key={index}>
//                       <TableCell>{formatCurrency(price.originalCostPrice)}</TableCell>
//                       <TableCell>{formatCurrency(price.calculatedPrice)}</TableCell>
//                       <TableCell>{formatCurrency(price.bestOfferAutoAcceptPrice)}</TableCell>
//                       <TableCell>{formatCurrency(price.minimumBestOfferPrice)}</TableCell>
//                       <TableCell>{formatCurrency(price.profit)}</TableCell>
//                       <TableCell>{price.profitMargin}%</TableCell>
//                     </TableRow>
//                   ))}
//                 </TableBody>
//               </Table>
//             </TableContainer>
//           )}
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={closePriceSimulation}>閉じる</Button>
//         </DialogActions>
//       </Dialog>

//       {/* Snackbar */}
//       <Snackbar
//         open={snackbar.open}
//         autoHideDuration={6000}
//         onClose={() => setSnackbar({ ...snackbar, open: false })}
//       >
//         <Alert
//           onClose={() => setSnackbar({ ...snackbar, open: false })}
//           severity={snackbar.severity}
//           sx={{ width: '100%' }}
//         >
//           {snackbar.message}
//         </Alert>
//       </Snackbar>
//     </Box>
//   );
// };

// export default PriceManager;






// import React, { useState, useEffect } from 'react';
// import {
//   Box,
//   Typography,
//   Button,
//   Paper,
//   Slider,
//   Dialog,
//   DialogTitle,
//   DialogContent,
//   DialogActions,
//   Grid,
//   TextField,
//   Accordion,
//   AccordionSummary,
//   AccordionDetails,
//   IconButton,
//   Tooltip,
//   FormControl,
//   InputLabel,
//   Select,
//   MenuItem,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   Alert,
//   Snackbar,
//   List,
//   ListItem,
//   ListItemText,
//   ListItemSecondaryAction,
// } from '@mui/material';
// import {
//   ExpandMore as ExpandMoreIcon,
//   HelpOutline as HelpOutlineIcon,
//   Save as SaveIcon,
//   SettingsBackupRestore as SettingsBackupRestoreIcon,
//   Add as AddIcon,
//   Delete as DeleteIcon,
//   Edit as EditIcon,
//   CheckCircle as CheckCircleIcon,
//   WarningAmber as WarningAmberIcon,
// } from '@mui/icons-material';
// import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

// // Firebase関連のインポート
// import { getAuth } from 'firebase/auth';
// import { getFirestore, doc, getDoc, setDoc } from 'firebase/firestore';

// const PriceManager = ({ data, setData }) => {
//   // ユーザー状態の定義
//   const [user, setUser] = useState(null);

//   // ユーザーの取得
//   useEffect(() => {
//     const auth = getAuth();
//     const unsubscribe = auth.onAuthStateChanged((firebaseUser) => {
//       setUser(firebaseUser);
//     });
//     return () => unsubscribe();
//   }, []);

//   // 状態変数の定義
//   const [targetProfitMargin, setTargetProfitMargin] = useState(25);
//   const [exchangeRate, setExchangeRate] = useState(140);
//   const [categories, setCategories] = useState({
//     additionalCosts: [],
//     fees: [],
//   });
//   const [isHelpDialogOpen, setIsHelpDialogOpen] = useState(false);
//   const [isResetDialogOpen, setIsResetDialogOpen] = useState(false);
//   const [isShippingDialogOpen, setIsShippingDialogOpen] = useState(false);
//   const [isFeeDialogOpen, setIsFeeDialogOpen] = useState(false);
//   const [isBestOfferDialogOpen, setIsBestOfferDialogOpen] = useState(false);
//   const [isEditTemplateNameDialogOpen, setIsEditTemplateNameDialogOpen] = useState(false);
//   const [editingTemplateId, setEditingTemplateId] = useState('');
//   const [newTemplateName, setNewTemplateName] = useState('');
//   const [selectedShippingTemplateId, setSelectedShippingTemplateId] = useState('default');
//   const [shippingRateTemplates, setShippingRateTemplates] = useState([
//     {
//       id: 'default',
//       name: 'デフォルト送料設定',
//       shippingRates: [
//         {
//           minPriceJPY: 0,
//           maxPriceJPY: 7000,
//           costJPY: 700,
//           minPriceUSD: 0,
//           maxPriceUSD: 50,
//           costUSD: 5,
//         },
//         {
//           minPriceJPY: 7000,
//           maxPriceJPY: 14000,
//           costJPY: 1400,
//           minPriceUSD: 50,
//           maxPriceUSD: 100,
//           costUSD: 10,
//         },
//         {
//           minPriceJPY: 14000,
//           maxPriceJPY: Infinity,
//           costJPY: 2100,
//           minPriceUSD: 100,
//           maxPriceUSD: Infinity,
//           costUSD: 15,
//         },
//       ],
//     },
//   ]);
//   const [fees, setFees] = useState({
//     ebayFinalValue: 10,
//     payoneer: 2,
//     promotedListing: 3,
//     ebayTransactionFee: 0.3,
//   });
//   const [bestOfferAutoAcceptPercentage, setBestOfferAutoAcceptPercentage] = useState(95);
//   const [minimumBestOfferPercentage, setMinimumBestOfferPercentage] = useState(80);
//   const [snackbar, setSnackbar] = useState({
//     open: false,
//     message: '',
//     severity: 'info',
//   });
//   const [error, setError] = useState(null);
//   const [isPriceApplied, setIsPriceApplied] = useState(false);
//   const [isCalculating, setIsCalculating] = useState(false);
//   const [calculatedPrices, setCalculatedPrices] = useState([]);
//   const [showPriceSimulation, setShowPriceSimulation] = useState(false);
//   const [originalData, setOriginalData] = useState(null);

//   // ユーザー設定の読み込み
//   useEffect(() => {
//     if (user) {
//       const loadSettings = async () => {
//         const db = getFirestore();
//         const userDocRef = doc(db, 'userSettings', user.uid);
//         try {
//           const docSnap = await getDoc(userDocRef);
//           if (docSnap.exists()) {
//             const settings = docSnap.data();
//             setTargetProfitMargin(settings.targetProfitMargin || 25);
//             setExchangeRate(settings.exchangeRate || 140);
//             setCategories(settings.categories || { additionalCosts: [], fees: [] });
//             setShippingRateTemplates(settings.shippingRateTemplates || shippingRateTemplates);
//             setSelectedShippingTemplateId(settings.selectedShippingTemplateId || 'default');
//             setFees(
//               settings.fees || {
//                 ebayFinalValue: 10,
//                 payoneer: 2,
//                 promotedListing: 3,
//                 ebayTransactionFee: 0.3,
//               }
//             );
//             setBestOfferAutoAcceptPercentage(settings.bestOfferAutoAcceptPercentage || 95);
//             setMinimumBestOfferPercentage(settings.minimumBestOfferPercentage || 80);
//           }
//         } catch (error) {
//           console.error('設定の読み込みに失敗しました:', error);
//           setSnackbar({
//             open: true,
//             message: '設定の読み込みに失敗しました',
//             severity: 'error',
//           });
//         }
//       };
//       loadSettings();
//     }
//   }, [user]);

//   // 利益率の変更ハンドラー
//   const handleProfitMarginChange = (event, newValue) => {
//     setTargetProfitMargin(newValue);
//   };

//   // 為替レートの変更ハンドラー
//   const handleExchangeRateChange = (event) => {
//     const newRate = parseFloat(event.target.value);
//     if (!isNaN(newRate) && newRate > 0) {
//       setExchangeRate(newRate);
//     }
//   };

//   // 設定の保存ハンドラー
//   const handleSaveSettings = async () => {
//     if (!user) {
//       setSnackbar({
//         open: true,
//         message: 'ユーザーが認証されていません。再度ログインしてください。',
//         severity: 'error',
//       });
//       return;
//     }

//     const db = getFirestore();
//     const userDocRef = doc(db, 'userSettings', user.uid);

//     const settings = {
//       targetProfitMargin,
//       exchangeRate,
//       categories,
//       shippingRateTemplates,
//       selectedShippingTemplateId,
//       fees,
//       bestOfferAutoAcceptPercentage,
//       minimumBestOfferPercentage,
//     };

//     try {
//       await setDoc(userDocRef, settings);
//       setSnackbar({
//         open: true,
//         message: '設定を保存しました',
//         severity: 'success',
//       });
//     } catch (error) {
//       console.error('設定の保存に失敗しました:', error);
//       setSnackbar({
//         open: true,
//         message: '設定の保存に失敗しました',
//         severity: 'error',
//       });
//     }
//   };

//   // 設定のリセットハンドラー
//   const handleResetSettings = () => {
//     setIsResetDialogOpen(true);
//   };

//   // リセットの確認ハンドラー
//   const confirmResetSettings = () => {
//     // デフォルトの設定にリセットする処理を実装
//     setTargetProfitMargin(25);
//     setExchangeRate(140);
//     setCategories({
//       additionalCosts: [],
//       fees: [],
//     });
//     setShippingRateTemplates([
//       {
//         id: 'default',
//         name: 'デフォルト送料設定',
//         shippingRates: [
//           {
//             minPriceJPY: 0,
//             maxPriceJPY: 7000,
//             costJPY: 700,
//             minPriceUSD: 0,
//             maxPriceUSD: 50,
//             costUSD: 5,
//           },
//           {
//             minPriceJPY: 7000,
//             maxPriceJPY: 14000,
//             costJPY: 1400,
//             minPriceUSD: 50,
//             maxPriceUSD: 100,
//             costUSD: 10,
//           },
//           {
//             minPriceJPY: 14000,
//             maxPriceJPY: Infinity,
//             costJPY: 2100,
//             minPriceUSD: 100,
//             maxPriceUSD: Infinity,
//             costUSD: 15,
//           },
//         ],
//       },
//     ]);
//     setSelectedShippingTemplateId('default');
//     setFees({
//       ebayFinalValue: 10,
//       payoneer: 2,
//       promotedListing: 3,
//       ebayTransactionFee: 0.3,
//     });
//     setBestOfferAutoAcceptPercentage(95);
//     setMinimumBestOfferPercentage(80);
//     setIsResetDialogOpen(false);
//     setSnackbar({
//       open: true,
//       message: '設定をデフォルトに戻しました',
//       severity: 'info',
//     });
//   };

//   // ドラッグ＆ドロップの終了ハンドラー
//   const onDragEnd = (result) => {
//     if (!result.destination) return;

//     const sourceCategory = result.source.droppableId;
//     const destCategory = result.destination.droppableId;

//     setCategories((prevCategories) => {
//       const newCategories = { ...prevCategories };
//       const [movedItem] = newCategories[sourceCategory].splice(result.source.index, 1);
//       newCategories[destCategory].splice(result.destination.index, 0, movedItem);
//       return newCategories;
//     });

//     setSnackbar({
//       open: true,
//       message: '項目の順序を変更しました',
//       severity: 'info',
//     });
//   };

//   // 項目の値を変更するハンドラー
//   const handleItemValueChange = (categoryKey, itemId, newValue, field) => {
//     setCategories((prevCategories) => ({
//       ...prevCategories,
//       [categoryKey]: prevCategories[categoryKey].map((item) =>
//         item.id === itemId ? { ...item, [field]: field === 'value' ? parseFloat(newValue) || 0 : newValue } : item
//       ),
//     }));
//   };

//   // 項目を削除するハンドラー
//   const handleRemoveItem = (categoryKey, itemId) => {
//     setCategories((prevCategories) => ({
//       ...prevCategories,
//       [categoryKey]: prevCategories[categoryKey].filter((item) => item.id !== itemId),
//     }));
//     setSnackbar({
//       open: true,
//       message: '項目を削除しました',
//       severity: 'info',
//     });
//   };

//   // 項目を追加するハンドラー
//   const handleAddItem = (categoryKey) => {
//     const newItem = { id: `new-item-${Date.now()}`, name: '新しい項目', value: 0, type: 'fixed' };
//     setCategories((prevCategories) => ({
//       ...prevCategories,
//       [categoryKey]: [...prevCategories[categoryKey], newItem],
//     }));
//     setSnackbar({
//       open: true,
//       message: '新しい項目を追加しました',
//       severity: 'success',
//     });
//   };

//   // カテゴリーをレンダリングする関数
//   const renderCategory = (categoryKey, categoryName) => (
//     <Accordion key={categoryKey}>
//       <AccordionSummary expandIcon={<ExpandMoreIcon />}>
//         <Typography>{categoryName}</Typography>
//       </AccordionSummary>
//       <AccordionDetails>
//         <Droppable droppableId={categoryKey}>
//           {(provided) => (
//             <Box {...provided.droppableProps} ref={provided.innerRef}>
//               {categories[categoryKey].map((item, index) => (
//                 <Draggable key={item.id} draggableId={item.id} index={index}>
//                   {(provided) => (
//                     <Box
//                       ref={provided.innerRef}
//                       {...provided.draggableProps}
//                       {...provided.dragHandleProps}
//                       sx={{ display: 'flex', alignItems: 'center', mb: 1 }}
//                     >
//                       <TextField
//                         label="項目名"
//                         value={item.name}
//                         onChange={(e) => handleItemValueChange(categoryKey, item.id, e.target.value, 'name')}
//                         size="small"
//                         sx={{ flexGrow: 1, mr: 1 }}
//                       />
//                       <TextField
//                         label="値"
//                         value={item.value}
//                         onChange={(e) => handleItemValueChange(categoryKey, item.id, e.target.value, 'value')}
//                         type="number"
//                         size="small"
//                         sx={{ width: 100, mr: 1 }}
//                       />
//                       <FormControl size="small" sx={{ minWidth: 120, mr: 1 }}>
//                         <InputLabel>タイプ</InputLabel>
//                         <Select
//                           value={item.type}
//                           onChange={(e) => handleItemValueChange(categoryKey, item.id, e.target.value, 'type')}
//                           label="タイプ"
//                         >
//                           <MenuItem value="fixed">固定</MenuItem>
//                           <MenuItem value="percentage">割合 (%)</MenuItem>
//                         </Select>
//                       </FormControl>
//                       <IconButton onClick={() => handleRemoveItem(categoryKey, item.id)}>
//                         <DeleteIcon />
//                       </IconButton>
//                     </Box>
//                   )}
//                 </Draggable>
//               ))}
//               {provided.placeholder}
//             </Box>
//           )}
//         </Droppable>
//         <Button startIcon={<AddIcon />} onClick={() => handleAddItem(categoryKey)}>
//           項目を追加
//         </Button>
//       </AccordionDetails>
//     </Accordion>
//   );

//   // 価格を計算する関数
//   const calculatePrices = () => {
//     setIsCalculating(true);
//     setError(null);

//     try {
//       if (!data || data.length === 0) {
//         throw new Error('データが不足しています。');
//       }

//       const columns = Object.keys(data[0]);
//       if (!columns.includes('StartPrice')) {
//         throw new Error('StartPrice列が見つかりません。');
//       }

//       const selectedShippingTemplate = shippingRateTemplates.find(
//         (template) => template.id === selectedShippingTemplateId
//       );

//       if (!selectedShippingTemplate) {
//         throw new Error('選択された送料テンプレートが見つかりません。');
//       }

//       const newCalculatedPrices = data.map((item) => {
//         const costPrice = parseFloat(item.CostPrice);
//         if (isNaN(costPrice)) {
//           return null;
//         }

//         let totalCost = costPrice;
//         let totalPercentage = 0;

//         // カテゴリーの項目を適用
//         Object.values(categories)
//           .flat()
//           .forEach((categoryItem) => {
//             if (categoryItem.type === 'fixed') {
//               totalCost += parseFloat(categoryItem.value) || 0;
//             } else if (categoryItem.type === 'percentage') {
//               totalPercentage += parseFloat(categoryItem.value) || 0;
//             }
//           });

//         // 手数料を追加
//         totalPercentage +=
//           parseFloat(fees.ebayFinalValue || 0) +
//           parseFloat(fees.payoneer || 0) +
//           parseFloat(fees.promotedListing || 0);
//         totalCost += parseFloat(fees.ebayTransactionFee || 0);

//         // 送料を追加
//         const shippingRate = selectedShippingTemplate?.shippingRates?.find(
//           (rate) =>
//             costPrice >= parseFloat(rate.minPriceUSD) &&
//             (parseFloat(rate.maxPriceUSD) === Infinity || costPrice < parseFloat(rate.maxPriceUSD))
//         );
//         if (shippingRate) {
//           totalCost += parseFloat(shippingRate.costUSD) || 0;
//         }

//         // 目標利益率を追加
//         totalPercentage += parseFloat(targetProfitMargin) || 0;

//         // 販売価格を計算
//         let calculatedPrice = totalCost / (1 - totalPercentage / 100);
//         calculatedPrice = adjustPriceTo99(calculatedPrice);

//         // ベストオファー価格を計算
//         const bestOfferAutoAcceptPrice = calculatedPrice * (bestOfferAutoAcceptPercentage / 100);
//         const minimumBestOfferPrice = calculatedPrice * (minimumBestOfferPercentage / 100);

//         // 利益と利益率を計算
//         const profit = calculatedPrice - totalCost;
//         const profitMargin = (profit / calculatedPrice) * 100;

//         return {
//           originalCostPrice: costPrice.toFixed(2),
//           calculatedPrice: calculatedPrice.toFixed(2),
//           bestOfferAutoAcceptPrice: bestOfferAutoAcceptPrice.toFixed(2),
//           minimumBestOfferPrice: minimumBestOfferPrice.toFixed(2),
//           profit: profit.toFixed(2),
//           profitMargin: profitMargin.toFixed(2),
//         };
//       });

//       setCalculatedPrices(newCalculatedPrices.filter((price) => price !== null));
//     } catch (err) {
//       setError(err.message);
//     } finally {
//       setIsCalculating(false);
//     }
//   };

//   // 価格を99に調整する関数
//   const adjustPriceTo99 = (price) => {
//     return Math.floor(price) + 0.99;
//   };

//   // 価格を適用するハンドラー
//   const handleApplyPrices = () => {
//     try {
//       // 価格を適用する前に元のデータを保存
//       if (!originalData) {
//         setOriginalData([...data]);
//       }

//       const updatedData = data.map((item, index) => {
//         const calculatedPrice = calculatedPrices[index];
//         if (!calculatedPrice) return item;

//         return {
//           ...item,
//           StartPrice: calculatedPrice.calculatedPrice,
//           BestOfferAutoAcceptPrice: calculatedPrice.bestOfferAutoAcceptPrice,
//           MinimumBestOfferPrice: calculatedPrice.minimumBestOfferPrice,
//           Profit: calculatedPrice.profit,
//           ProfitMargin: calculatedPrice.profitMargin,
//         };
//       });

//       setData(updatedData);
//       setSnackbar({
//         open: true,
//         message: '価格が更新されました',
//         severity: 'success',
//       });
//       setIsPriceApplied(true); // 価格が適用されたことを記録
//     } catch (err) {
//       setError('価格の更新に失敗しました: ' + err.message);
//     }
//   };

//   // 価格を元に戻すハンドラー
//   const handleRestorePrices = () => {
//     if (originalData) {
//       setData(originalData);
//       setOriginalData(null); // 元のデータをクリア
//       setSnackbar({
//         open: true,
//         message: '価格を元に戻されました',
//         severity: 'success',
//       });
//       setIsPriceApplied(false); // 価格が適用されていない状態に戻す
//     } else {
//       setSnackbar({
//         open: true,
//         message: '元の価格がありません',
//         severity: 'warning',
//       });
//     }
//   };

//   // テンプレート名の編集ハンドラー
//   const handleEditTemplateName = (templateId) => {
//     const template = shippingRateTemplates.find((t) => t.id === templateId);
//     if (template) {
//       setEditingTemplateId(templateId);
//       setNewTemplateName(template.name);
//       setIsEditTemplateNameDialogOpen(true);
//     }
//   };

//   // テンプレート名を更新するハンドラー
//   const handleTemplateNameUpdate = () => {
//     setShippingRateTemplates((prevTemplates) =>
//       prevTemplates.map((template) =>
//         template.id === editingTemplateId ? { ...template, name: newTemplateName } : template
//       )
//     );
//     setIsEditTemplateNameDialogOpen(false);
//     setSnackbar({
//       open: true,
//       message: 'テンプレート名を更新しました',
//       severity: 'success',
//     });
//   };

//   // 新しい送料テンプレートを追加するハンドラー
//   const handleAddShippingTemplate = () => {
//     const newTemplateId = `template-${Date.now()}`;
//     setShippingRateTemplates((prevTemplates) => [
//       ...prevTemplates,
//       {
//         id: newTemplateId,
//         name: `新しいテンプレート ${prevTemplates.length + 1}`,
//         shippingRates: [],
//       },
//     ]);
//     setSelectedShippingTemplateId(newTemplateId);
//     setSnackbar({
//       open: true,
//       message: '新しい送料テンプレートを追加しました',
//       severity: 'success',
//     });
//   };

//   // 送料テンプレートを削除するハンドラー
//   const handleRemoveShippingTemplate = (templateId) => {
//     setShippingRateTemplates((prevTemplates) => prevTemplates.filter((template) => template.id !== templateId));
//     if (selectedShippingTemplateId === templateId && shippingRateTemplates.length > 1) {
//       setSelectedShippingTemplateId(shippingRateTemplates[0].id);
//     }
//     setSnackbar({
//       open: true,
//       message: '送料テンプレートを削除しました',
//       severity: 'info',
//     });
//   };

//   // 送料設定を変更するハンドラー
//   const handleShippingRateChange = (index, field, value) => {
//     setShippingRateTemplates((prevTemplates) =>
//       prevTemplates.map((template) =>
//         template.id === selectedShippingTemplateId
//           ? {
//               ...template,
//               shippingRates: template.shippingRates.map((rate, i) =>
//                 i === index
//                   ? {
//                       ...rate,
//                       [field]: parseFloat(value),
//                       [field.endsWith('JPY') ? field.replace('JPY', 'USD') : field.replace('USD', 'JPY')]:
//                         field.endsWith('JPY')
//                           ? (parseFloat(value) / exchangeRate).toFixed(2)
//                           : Math.round(parseFloat(value) * exchangeRate),
//                     }
//                   : rate
//               ),
//             }
//           : template
//       )
//     );
//   };

//   // 送料設定を削除するハンドラー
//   const handleRemoveShippingRate = (index) => {
//     setShippingRateTemplates((prevTemplates) =>
//       prevTemplates.map((template) =>
//         template.id === selectedShippingTemplateId
//           ? {
//               ...template,
//               shippingRates: template.shippingRates.filter((_, i) => i !== index),
//             }
//           : template
//       )
//     );
//   };

//   // 新しい送料設定を追加するハンドラー
//   const handleAddShippingRate = () => {
//     setShippingRateTemplates((prevTemplates) =>
//       prevTemplates.map((template) =>
//         template.id === selectedShippingTemplateId
//           ? {
//               ...template,
//               shippingRates: [
//                 ...template.shippingRates,
//                 { minPriceJPY: 0, maxPriceJPY: 0, costJPY: 0, minPriceUSD: 0, maxPriceUSD: 0, costUSD: 0 },
//               ],
//             }
//           : template
//       )
//     );
//   };

//   // 手数料を変更するハンドラー
//   const handleFeeChange = (feeType, value) => {
//     setFees((prevFees) => ({
//       ...prevFees,
//       [feeType]: parseFloat(value),
//     }));
//   };

//   // ベストオファー自動承認価格の変更ハンドラー
//   const handleBestOfferAutoAcceptChange = (event) => {
//     setBestOfferAutoAcceptPercentage(parseFloat(event.target.value));
//   };

//   // 最小ベストオファー価格の変更ハンドラー
//   const handleMinimumBestOfferChange = (event) => {
//     setMinimumBestOfferPercentage(parseFloat(event.target.value));
//   };

//   // 価格シミュレーションダイアログを閉じる関数
//   const closePriceSimulation = () => {
//     setShowPriceSimulation(false);
//   };

//   // 通貨のフォーマット関数
//   const formatCurrency = (value, currency = 'USD') => {
//     if (value === undefined || value === null || isNaN(parseFloat(value))) {
//       return currency === 'USD' ? '$0.00' : '¥0';
//     }
//     if (currency === 'USD') {
//       return `$${parseFloat(value).toFixed(2)}`;
//     } else if (currency === 'JPY') {
//       return `¥${Math.round(parseFloat(value) * exchangeRate)}`;
//     }
//   };

//   // 設定項目を表示するコンポーネント
//   const SettingItem = ({ label, value }) => (
//     <Typography variant="body2">
//       <strong>{label}:</strong> {value}
//     </Typography>
//   );

//   // 選択された送料テンプレートの名前を取得
//   const selectedShippingTemplate =
//     shippingRateTemplates.find((template) => template.id === selectedShippingTemplateId) ||
//     shippingRateTemplates[0];

//   const selectedShippingTemplateName = selectedShippingTemplate
//     ? selectedShippingTemplate.name
//     : 'デフォルト送料設定';

//   // 価格を手入力で変更するハンドラー
//   const handlePriceChange = (index, newPrice) => {
//     const updatedData = data.map((item, i) => {
//       if (i === index) {
//         const newStartPrice = parseFloat(newPrice);
//         if (isNaN(newStartPrice)) {
//           return item;
//         }

//         // ベストオファー価格を再計算
//         const newBestOfferAutoAcceptPrice = newStartPrice * (bestOfferAutoAcceptPercentage / 100);
//         const newMinimumBestOfferPrice = newStartPrice * (minimumBestOfferPercentage / 100);

//         // 利益と利益率を再計算（CostPriceが存在する場合）
//         let profit = null;
//         let profitMargin = null;
//         const costPrice = parseFloat(item.CostPrice);
//         if (!isNaN(costPrice)) {
//           profit = newStartPrice - costPrice;
//           profitMargin = (profit / newStartPrice) * 100;
//         }

//         return {
//           ...item,
//           StartPrice: newStartPrice.toFixed(2),
//           BestOfferAutoAcceptPrice: newBestOfferAutoAcceptPrice.toFixed(2),
//           MinimumBestOfferPrice: newMinimumBestOfferPrice.toFixed(2),
//           Profit: profit !== null ? profit.toFixed(2) : '',
//           ProfitMargin: profitMargin !== null ? profitMargin.toFixed(2) : '',
//         };
//       }
//       return item;
//     });

//     setData(updatedData);
//   };

//   return (
//     <Box sx={{ flexGrow: 1, p: 2 }}>
//       <Grid container spacing={2}>
//         {/* 左側のコンテンツ */}
//         <Grid item xs={12} md={6}>
//           <Typography variant="h4" gutterBottom>
//             価格管理
//             <Tooltip title="ヘルプを表示">
//               <IconButton onClick={() => setIsHelpDialogOpen(true)}>
//                 <HelpOutlineIcon />
//               </IconButton>
//             </Tooltip>
//           </Typography>

//           {/* 目標利益率設定 */}
//           <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
//             <Typography variant="h6" gutterBottom>
//               目標利益率: {targetProfitMargin}%
//             </Typography>
//             <Slider
//               value={targetProfitMargin}
//               onChange={handleProfitMarginChange}
//               aria-labelledby="profit-margin-slider"
//               valueLabelDisplay="auto"
//               step={1}
//               marks
//               min={0}
//               max={100}
//             />
//           </Paper>

//           {/* 為替レート設定 */}
//           <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
//             <Typography variant="h6" gutterBottom>
//               為替レート設定
//             </Typography>
//             <Typography>1 USD = {exchangeRate.toFixed(2)} JPY</Typography>
//             <TextField
//               label="為替レート (1 USD = X JPY)"
//               value={exchangeRate}
//               onChange={handleExchangeRateChange}
//               type="number"
//               inputProps={{ step: '0.01' }}
//               sx={{ width: 200, mt: 2 }}
//             />
//           </Paper>

//           {/* その他の設定ボタン */}
//           <Grid container spacing={2} sx={{ mb: 3 }}>
//             <Grid item xs={12} sm={4}>
//               <Button variant="outlined" color="primary" onClick={() => setIsShippingDialogOpen(true)} fullWidth>
//                 送料設定
//               </Button>
//             </Grid>
//             <Grid item xs={12} sm={4}>
//               <Button variant="outlined" color="primary" onClick={() => setIsFeeDialogOpen(true)} fullWidth>
//                 手数料設定
//               </Button>
//             </Grid>
//             <Grid item xs={12} sm={4}>
//               <Button variant="outlined" color="primary" onClick={() => setIsBestOfferDialogOpen(true)} fullWidth>
//                 ベストオファー設定
//               </Button>
//             </Grid>
//           </Grid>

//           {/* カテゴリー設定 */}
//           <DragDropContext onDragEnd={onDragEnd}>
//             <Grid container spacing={2}>
//               <Grid item xs={12} md={6}>
//                 {renderCategory('additionalCosts', '上乗せ金額')}
//               </Grid>
//               <Grid item xs={12} md={6}>
//                 {renderCategory('fees', '手数料')}
//               </Grid>
//             </Grid>
//           </DragDropContext>

//           {/* 設定保存とリセット */}
//           <Box sx={{ mt: 3, display: 'flex', justifyContent: 'space-between' }}>
//             <Button startIcon={<SaveIcon />} variant="contained" color="primary" onClick={handleSaveSettings}>
//               設定を保存
//             </Button>
//             <Button startIcon={<SettingsBackupRestoreIcon />} variant="outlined" onClick={handleResetSettings}>
//               デフォルトに戻す
//             </Button>
//           </Box>
//         </Grid>

//         {/* 右側のコンテンツ */}
//         <Grid item xs={12} md={6}>
//           <Box sx={{ mt: { xs: 4, md: 0 } }}>
//             <Typography variant="h5" gutterBottom>
//               価格計算と適用
//             </Typography>

//             {/* 現在の設定セクション */}
//             <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
//               <Typography variant="h6" gutterBottom>
//                 現在の設定
//               </Typography>
//               <Grid container spacing={2}>
//                 <Grid item xs={12} sm={6}>
//                   <SettingItem label="目標利益率" value={`${targetProfitMargin}%`} />
//                   <SettingItem label="eBay最終価値手数料" value={`${fees.ebayFinalValue}%`} />
//                   <SettingItem label="Payoneer手数料" value={`${fees.payoneer}%`} />
//                   <SettingItem label="広告出品手数料" value={`${fees.promotedListing}%`} />
//                   <SettingItem
//                     label="eBay取引手数料"
//                     value={`${formatCurrency(fees.ebayTransactionFee)} (${formatCurrency(
//                       fees.ebayTransactionFee,
//                       'JPY'
//                     )})`}
//                   />
//                 </Grid>
//                 <Grid item xs={12} sm={6}>
//                   <SettingItem label="為替レート" value={`1 USD = ${exchangeRate.toFixed(2)} JPY`} />
//                   <SettingItem label="Best Offer自動承認価格" value={`${bestOfferAutoAcceptPercentage}%`} />
//                   <SettingItem label="最小Best Offer価格" value={`${minimumBestOfferPercentage}%`} />
//                   <SettingItem label="適用されている送料テンプレート" value={`${selectedShippingTemplateName}`} />
//                 </Grid>
//               </Grid>
//             </Paper>

//             {error && (
//               <Alert severity="error" sx={{ mb: 2 }}>
//                 {error}
//               </Alert>
//             )}

//             {/* 適用状態の表示 */}
//             {isPriceApplied ? (
//               <Alert
//                 severity="success"
//                 iconMapping={{
//                   success: <CheckCircleIcon fontSize="inherit" />,
//                 }}
//                 sx={{ mb: 2, fontWeight: 'bold', textAlign: 'center' }}
//               >
//                 適用完了
//               </Alert>
//             ) : (
//               <Alert
//                 severity="warning"
//                 iconMapping={{
//                   warning: <WarningAmberIcon fontSize="inherit" />,
//                 }}
//                 sx={{ mb: 2, fontWeight: 'bold', textAlign: 'center' }}
//               >
//                 未適用
//               </Alert>
//             )}

//             <Grid container spacing={2} sx={{ mb: 2 }}>
//               <Grid item xs={12} sm={6}>
//                 <Button
//                   variant="contained"
//                   color="primary"
//                   onClick={calculatePrices}
//                   disabled={isCalculating}
//                   fullWidth
//                 >
//                   {isCalculating ? '計算中...' : '価格を再計算'}
//                 </Button>
//               </Grid>
//             </Grid>

//             {calculatedPrices.length > 0 && (
//               <Grid container spacing={2} sx={{ mt: 2 }}>
//                 <Grid item xs={12} sm={4}>
//                   <Button
//                     variant="contained"
//                     color="secondary"
//                     onClick={() => setShowPriceSimulation(true)}
//                     fullWidth
//                   >
//                     価格シミュレーション結果
//                   </Button>
//                 </Grid>
//                 <Grid item xs={12} sm={4}>
//                   <Button
//                     variant="contained"
//                     color="secondary"
//                     onClick={handleApplyPrices}
//                     fullWidth
//                     disabled={isPriceApplied}
//                   >
//                     計算した価格を適用
//                   </Button>
//                 </Grid>
//                 <Grid item xs={12} sm={4}>
//                   <Button
//                     variant="outlined"
//                     color="primary"
//                     onClick={handleRestorePrices}
//                     fullWidth
//                     disabled={!isPriceApplied}
//                   >
//                     価格を元に戻す
//                   </Button>
//                 </Grid>
//               </Grid>
//             )}
//           </Box>
//         </Grid>

//         {/* 新しい中央セクション */}
//         <Grid item xs={12}>
//           <Box sx={{ mt: 4 }}>
//             <Typography variant="h5" gutterBottom>
//               商品の価格一覧
//             </Typography>
//             <Paper elevation={3} sx={{ p: 2 }}>
//               <TableContainer component={Paper}>
//                 <Table>
//                   <TableHead>
//                     <TableRow>
//                       <TableCell>番号</TableCell>
//                       <TableCell>タイトル</TableCell>
//                       <TableCell>価格 (USD)</TableCell>
//                     </TableRow>
//                   </TableHead>
//                   <TableBody>
//                     {data.map((item, index) => (
//                       <TableRow key={index}>
//                         <TableCell>{index + 1}</TableCell>
//                         <TableCell>{item.Title}</TableCell>
//                         <TableCell>
//                           <TextField
//                             type="number"
//                             value={item.StartPrice}
//                             onChange={(e) => handlePriceChange(index, e.target.value)}
//                             size="small"
//                           />
//                         </TableCell>
//                       </TableRow>
//                     ))}
//                   </TableBody>
//                 </Table>
//               </TableContainer>
//             </Paper>
//           </Box>
//         </Grid>
//       </Grid>

//        {/* ヘルプダイアログ */}
//        <Dialog open={isHelpDialogOpen} onClose={() => setIsHelpDialogOpen(false)} maxWidth="md" fullWidth>
//         <DialogTitle>価格計算の具体的な説明</DialogTitle>
//         <DialogContent>
//           {/* ここに価格計算の詳細な説明を表示 */}
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => setIsHelpDialogOpen(false)}>閉じる</Button>
//         </DialogActions>
//       </Dialog>

//       {/* リセット確認ダイアログ */}
//       <Dialog open={isResetDialogOpen} onClose={() => setIsResetDialogOpen(false)}>
//         <DialogTitle>設定をリセット</DialogTitle>
//         <DialogContent>
//           <Typography>本当に設定をデフォルトに戻しますか？この操作は元に戻せません。</Typography>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => setIsResetDialogOpen(false)}>キャンセル</Button>
//           <Button onClick={confirmResetSettings} color="error">
//             リセット
//           </Button>
//         </DialogActions>
//       </Dialog>

//       {/* 送料設定ダイアログ */}
//       <Dialog open={isShippingDialogOpen} onClose={() => setIsShippingDialogOpen(false)} maxWidth="lg" fullWidth>
//         <DialogTitle>送料設定</DialogTitle>
//         <DialogContent>
//           {/* 送料テンプレートの選択と管理 */}
//           <Box sx={{ mb: 2 }}>
//             <FormControl fullWidth>
//               <InputLabel>送料テンプレートを選択</InputLabel>
//               <Select
//                 value={selectedShippingTemplateId}
//                 onChange={(e) => setSelectedShippingTemplateId(e.target.value)}
//                 label="送料テンプレートを選択"
//               >
//                 {shippingRateTemplates.map((template) => (
//                   <MenuItem
//                     key={template.id}
//                     value={template.id}
//                     sx={{ display: 'flex', alignItems: 'center' }}
//                   >
//                     <Typography variant="body1" sx={{ flexGrow: 1 }}>
//                       {template.name}
//                     </Typography>
//                     <IconButton
//                       size="small"
//                       onClick={(e) => {
//                         e.stopPropagation();
//                         handleEditTemplateName(template.id);
//                       }}
//                     >
//                       <EditIcon fontSize="small" />
//                     </IconButton>
//                   </MenuItem>
//                 ))}
//               </Select>
//             </FormControl>
//             <Box sx={{ mt: 1, display: 'flex', justifyContent: 'space-between' }}>
//               <Button startIcon={<AddIcon />} onClick={handleAddShippingTemplate}>
//                 新しいテンプレートを追加
//               </Button>
//               <Button
//                 startIcon={<DeleteIcon />}
//                 onClick={() => handleRemoveShippingTemplate(selectedShippingTemplateId)}
//                 disabled={shippingRateTemplates.length <= 1}
//               >
//                 テンプレートを削除
//               </Button>
//             </Box>
//           </Box>

//           {/* 選択されたテンプレートの送料設定 */}
//           <TableContainer component={Paper}>
//             <Table>
//               <TableHead>
//                 <TableRow>
//                   <TableCell>最小仕入れ価格 (JPY)</TableCell>
//                   <TableCell>最大仕入れ価格 (JPY)</TableCell>
//                   <TableCell>送料 (JPY)</TableCell>
//                   <TableCell>最小仕入れ価格 (USD)</TableCell>
//                   <TableCell>最大仕入れ価格 (USD)</TableCell>
//                   <TableCell>送料 (USD)</TableCell>
//                   <TableCell>操作</TableCell>
//                 </TableRow>
//               </TableHead>
//               <TableBody>
//                 {shippingRateTemplates
//                   .find((template) => template.id === selectedShippingTemplateId)
//                   .shippingRates.map((rate, index) => (
//                     <TableRow key={index}>
//                       <TableCell>
//                         <TextField
//                           type="number"
//                           value={rate.minPriceJPY}
//                           onChange={(e) => handleShippingRateChange(index, 'minPriceJPY', e.target.value)}
//                           size="small"
//                           fullWidth
//                         />
//                       </TableCell>
//                       <TableCell>
//                         <TextField
//                           type="number"
//                           value={rate.maxPriceJPY === Infinity ? '' : rate.maxPriceJPY}
//                           onChange={(e) =>
//                             handleShippingRateChange(index, 'maxPriceJPY', e.target.value || Infinity)
//                           }
//                           size="small"
//                           fullWidth
//                         />
//                       </TableCell>
//                       <TableCell>
//                         <TextField
//                           type="number"
//                           value={rate.costJPY}
//                           onChange={(e) => handleShippingRateChange(index, 'costJPY', e.target.value)}
//                           size="small"
//                           fullWidth
//                         />
//                       </TableCell>
//                       <TableCell>
//                         <TextField
//                           type="number"
//                           value={rate.minPriceUSD}
//                           onChange={(e) => handleShippingRateChange(index, 'minPriceUSD', e.target.value)}
//                           size="small"
//                           fullWidth
//                         />
//                       </TableCell>
//                       <TableCell>
//                         <TextField
//                           type="number"
//                           value={rate.maxPriceUSD === Infinity ? '' : rate.maxPriceUSD}
//                           onChange={(e) =>
//                             handleShippingRateChange(index, 'maxPriceUSD', e.target.value || Infinity)
//                           }
//                           size="small"
//                           fullWidth
//                         />
//                       </TableCell>
//                       <TableCell>
//                         <TextField
//                           type="number"
//                           value={rate.costUSD}
//                           onChange={(e) => handleShippingRateChange(index, 'costUSD', e.target.value)}
//                           size="small"
//                           fullWidth
//                         />
//                       </TableCell>
//                       <TableCell>
//                         <IconButton onClick={() => handleRemoveShippingRate(index)}>
//                           <DeleteIcon />
//                         </IconButton>
//                       </TableCell>
//                     </TableRow>
//                   ))}
//               </TableBody>
//             </Table>
//           </TableContainer>
//           <Button startIcon={<AddIcon />} onClick={handleAddShippingRate} sx={{ mt: 2 }}>
//             送料設定を追加
//           </Button>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => setIsShippingDialogOpen(false)}>閉じる</Button>
//         </DialogActions>
//       </Dialog>

//       {/* テンプレート名編集ダイアログ */}
//       <Dialog
//         open={isEditTemplateNameDialogOpen}
//         onClose={() => setIsEditTemplateNameDialogOpen(false)}
//       >
//         <DialogTitle>テンプレート名の編集</DialogTitle>
//         <DialogContent>
//           <TextField
//             fullWidth
//             label="テンプレート名"
//             value={newTemplateName}
//             onChange={(e) => setNewTemplateName(e.target.value)}
//           />
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => setIsEditTemplateNameDialogOpen(false)}>キャンセル</Button>
//           <Button onClick={handleTemplateNameUpdate} color="primary">
//             更新
//           </Button>
//         </DialogActions>
//       </Dialog>

//       {/* 手数料設定ダイアログ */}
//       <Dialog open={isFeeDialogOpen} onClose={() => setIsFeeDialogOpen(false)} maxWidth="sm" fullWidth>
//         <DialogTitle>手数料設定</DialogTitle>
//         <DialogContent>
//           <List>
//             <ListItem>
//               <ListItemText primary="eBay最終価値手数料 (%)" />
//               <ListItemSecondaryAction>
//                 <TextField
//                   type="number"
//                   value={fees.ebayFinalValue}
//                   onChange={(e) => handleFeeChange('ebayFinalValue', e.target.value)}
//                   size="small"
//                 />
//               </ListItemSecondaryAction>
//             </ListItem>
//             <ListItem>
//               <ListItemText primary="Payoneer手数料 (%)" />
//               <ListItemSecondaryAction>
//                 <TextField
//                   type="number"
//                   value={fees.payoneer}
//                   onChange={(e) => handleFeeChange('payoneer', e.target.value)}
//                   size="small"
//                 />
//               </ListItemSecondaryAction>
//             </ListItem>
//             <ListItem>
//               <ListItemText primary="広告出品手数料 (%)" />
//               <ListItemSecondaryAction>
//                 <TextField
//                   type="number"
//                   value={fees.promotedListing}
//                   onChange={(e) => handleFeeChange('promotedListing', e.target.value)}
//                   size="small"
//                 />
//               </ListItemSecondaryAction>
//             </ListItem>
//             <ListItem>
//               <ListItemText primary="eBay取引手数料 ($)" />
//               <ListItemSecondaryAction>
//                 <TextField
//                   type="number"
//                   value={fees.ebayTransactionFee}
//                   onChange={(e) => handleFeeChange('ebayTransactionFee', e.target.value)}
//                   size="small"
//                 />
//               </ListItemSecondaryAction>
//             </ListItem>
//           </List>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => setIsFeeDialogOpen(false)}>閉じる</Button>
//         </DialogActions>
//       </Dialog>

//       {/* ベストオファー設定ダイアログ */}
//       <Dialog
//         open={isBestOfferDialogOpen}
//         onClose={() => setIsBestOfferDialogOpen(false)}
//         maxWidth="sm"
//         fullWidth
//       >
//         <DialogTitle>ベストオファー設定</DialogTitle>
//         <DialogContent>
//           <Grid container spacing={2}>
//             <Grid item xs={12}>
//               <TextField
//                 fullWidth
//                 label="ベストオファー自動承認価格 (%)"
//                 type="number"
//                 value={bestOfferAutoAcceptPercentage}
//                 onChange={handleBestOfferAutoAcceptChange}
//                 InputProps={{ inputProps: { min: 0, max: 100 } }}
//               />
//             </Grid>
//             <Grid item xs={12}>
//               <TextField
//                 fullWidth
//                 label="最小ベストオファー価格 (%)"
//                 type="number"
//                 value={minimumBestOfferPercentage}
//                 onChange={handleMinimumBestOfferChange}
//                 InputProps={{ inputProps: { min: 0, max: 100 } }}
//               />
//             </Grid>
//           </Grid>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => setIsBestOfferDialogOpen(false)}>閉じる</Button>
//         </DialogActions>
//       </Dialog>

//       {/* 価格シミュレーション結果ダイアログ */}
//       <Dialog open={showPriceSimulation} onClose={closePriceSimulation} maxWidth="lg" fullWidth>
//         <DialogTitle>価格シミュレーション結果</DialogTitle>
//         <DialogContent sx={{ height: '90vh' }}>
//           {/* 価格シミュレーション結果を表示 */}
//           {calculatedPrices.length > 0 && (
//             <TableContainer component={Paper} sx={{ maxHeight: '100%', overflow: 'auto' }}>
//               <Table stickyHeader>
//                 <TableHead>
//                   <TableRow>
//                     <TableCell>元の価格 (USD)</TableCell>
//                     <TableCell>計算後の価格 (USD)</TableCell>
//                     <TableCell>ベストオファー自動承認価格 (USD)</TableCell>
//                     <TableCell>最小ベストオファー価格 (USD)</TableCell>
//                     <TableCell>利益 (USD)</TableCell>
//                     <TableCell>利益率 (%)</TableCell>
//                   </TableRow>
//                 </TableHead>
//                 <TableBody>
//                   {calculatedPrices.map((price, index) => (
//                     <TableRow key={index}>
//                       <TableCell>{formatCurrency(price.originalStartPrice)}</TableCell>
//                       <TableCell>{formatCurrency(price.calculatedPrice)}</TableCell>
//                       <TableCell>{formatCurrency(price.bestOfferAutoAcceptPrice)}</TableCell>
//                       <TableCell>{formatCurrency(price.minimumBestOfferPrice)}</TableCell>
//                       <TableCell>{formatCurrency(price.profit)}</TableCell>
//                       <TableCell>{price.profitMargin}%</TableCell>
//                     </TableRow>
//                   ))}
//                 </TableBody>
//               </Table>
//             </TableContainer>
//           )}
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={closePriceSimulation}>閉じる</Button>
//         </DialogActions>
//       </Dialog>

//       {/* Snackbar */}
//       <Snackbar
//         open={snackbar.open}
//         autoHideDuration={6000}
//         onClose={() => setSnackbar({ ...snackbar, open: false })}
//       >
//         <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity} sx={{ width: '100%' }}>
//           {snackbar.message}
//         </Alert>
//       </Snackbar>
//     </Box>
//   );
// };

// export default PriceManager;






// // PriceManager.js

// import React, { useState, useEffect } from 'react';
// import {
//   Box,
//   Typography,
//   Button,
//   Paper,
//   Slider,
//   Dialog,
//   DialogTitle,
//   DialogContent,
//   DialogActions,
//   Grid,
//   TextField,
//   Accordion,
//   AccordionSummary,
//   AccordionDetails,
//   IconButton,
//   Tooltip,
//   FormControl,
//   InputLabel,
//   Select,
//   MenuItem,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   Alert,
//   Snackbar,
//   List,
//   ListItem,
//   ListItemText,
//   ListItemSecondaryAction,
// } from '@mui/material';
// import {
//   ExpandMore as ExpandMoreIcon,
//   HelpOutline as HelpOutlineIcon,
//   Save as SaveIcon,
//   SettingsBackupRestore as SettingsBackupRestoreIcon,
//   Add as AddIcon,
//   Delete as DeleteIcon,
//   Edit as EditIcon,
//   CheckCircle as CheckCircleIcon,
//   WarningAmber as WarningAmberIcon,
// } from '@mui/icons-material';
// import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

// // Firebase関連のインポート
// import { getAuth } from 'firebase/auth';
// import { getFirestore, doc, getDoc, setDoc } from 'firebase/firestore';

// const PriceManager = ({ data, setData }) => {
//   // ユーザー状態の定義
//   const [user, setUser] = useState(null);

//   // ユーザーの取得
//   useEffect(() => {
//     const auth = getAuth();
//     const unsubscribe = auth.onAuthStateChanged((firebaseUser) => {
//       setUser(firebaseUser);
//     });
//     return () => unsubscribe();
//   }, []);

//   // 状態変数の定義
//   const [targetProfitMargin, setTargetProfitMargin] = useState(25);
//   const [exchangeRate, setExchangeRate] = useState(140);
//   const [categories, setCategories] = useState({
//     additionalCosts: [],
//     fees: [],
//   });
//   const [isHelpDialogOpen, setIsHelpDialogOpen] = useState(false);
//   const [isResetDialogOpen, setIsResetDialogOpen] = useState(false);
//   const [isShippingDialogOpen, setIsShippingDialogOpen] = useState(false);
//   const [isFeeDialogOpen, setIsFeeDialogOpen] = useState(false);
//   const [isBestOfferDialogOpen, setIsBestOfferDialogOpen] = useState(false);
//   const [isEditTemplateNameDialogOpen, setIsEditTemplateNameDialogOpen] = useState(false);
//   const [editingTemplateId, setEditingTemplateId] = useState('');
//   const [newTemplateName, setNewTemplateName] = useState('');
//   const [selectedShippingTemplateId, setSelectedShippingTemplateId] = useState('default');
//   const [shippingRateTemplates, setShippingRateTemplates] = useState([
//     {
//       id: 'default',
//       name: 'デフォルト送料設定',
//       shippingRates: [
//         {
//           minPriceJPY: 0,
//           maxPriceJPY: 7000,
//           costJPY: 700,
//           minPriceUSD: 0,
//           maxPriceUSD: 50,
//           costUSD: 5,
//         },
//         {
//           minPriceJPY: 7000,
//           maxPriceJPY: 14000,
//           costJPY: 1400,
//           minPriceUSD: 50,
//           maxPriceUSD: 100,
//           costUSD: 10,
//         },
//         {
//           minPriceJPY: 14000,
//           maxPriceJPY: Infinity,
//           costJPY: 2100,
//           minPriceUSD: 100,
//           maxPriceUSD: Infinity,
//           costUSD: 15,
//         },
//       ],
//     },
//   ]);
//   const [fees, setFees] = useState({
//     ebayFinalValue: 10,
//     payoneer: 2,
//     promotedListing: 3,
//     ebayTransactionFee: 0.3,
//   });
//   const [bestOfferAutoAcceptPercentage, setBestOfferAutoAcceptPercentage] = useState(95);
//   const [minimumBestOfferPercentage, setMinimumBestOfferPercentage] = useState(80);
//   const [snackbar, setSnackbar] = useState({
//     open: false,
//     message: '',
//     severity: 'info',
//   });
//   const [error, setError] = useState(null);
//   const [isPriceApplied, setIsPriceApplied] = useState(false);
//   const [isCalculating, setIsCalculating] = useState(false);
//   const [calculatedPrices, setCalculatedPrices] = useState([]);
//   const [showPriceSimulation, setShowPriceSimulation] = useState(false);
//   const [originalData, setOriginalData] = useState(null);

//   // ユーザー設定の読み込み
//   useEffect(() => {
//     if (user) {
//       const loadSettings = async () => {
//         const db = getFirestore();
//         const userDocRef = doc(db, 'userSettings', user.uid);
//         try {
//           const docSnap = await getDoc(userDocRef);
//           if (docSnap.exists()) {
//             const settings = docSnap.data();
//             setTargetProfitMargin(settings.targetProfitMargin || 25);
//             setExchangeRate(settings.exchangeRate || 140);
//             setCategories(settings.categories || { additionalCosts: [], fees: [] });
//             setShippingRateTemplates(settings.shippingRateTemplates || shippingRateTemplates);
//             setSelectedShippingTemplateId(settings.selectedShippingTemplateId || 'default');
//             setFees(
//               settings.fees || {
//                 ebayFinalValue: 10,
//                 payoneer: 2,
//                 promotedListing: 3,
//                 ebayTransactionFee: 0.3,
//               }
//             );
//             setBestOfferAutoAcceptPercentage(settings.bestOfferAutoAcceptPercentage || 95);
//             setMinimumBestOfferPercentage(settings.minimumBestOfferPercentage || 80);
//           }
//         } catch (error) {
//           console.error('設定の読み込みに失敗しました:', error);
//           setSnackbar({
//             open: true,
//             message: '設定の読み込みに失敗しました',
//             severity: 'error',
//           });
//         }
//       };
//       loadSettings();
//     }
//   }, [user]);

//   // 利益率の変更ハンドラー
//   const handleProfitMarginChange = (event, newValue) => {
//     setTargetProfitMargin(newValue);
//   };

//   // 為替レートの変更ハンドラー
//   const handleExchangeRateChange = (event) => {
//     const newRate = parseFloat(event.target.value);
//     if (!isNaN(newRate) && newRate > 0) {
//       setExchangeRate(newRate);
//     }
//   };

//   // 設定の保存ハンドラー
//   const handleSaveSettings = async () => {
//     if (!user) {
//       setSnackbar({
//         open: true,
//         message: 'ユーザーが認証されていません。再度ログインしてください。',
//         severity: 'error',
//       });
//       return;
//     }

//     const db = getFirestore();
//     const userDocRef = doc(db, 'userSettings', user.uid);

//     const settings = {
//       targetProfitMargin,
//       exchangeRate,
//       categories,
//       shippingRateTemplates,
//       selectedShippingTemplateId,
//       fees,
//       bestOfferAutoAcceptPercentage,
//       minimumBestOfferPercentage,
//     };

//     try {
//       await setDoc(userDocRef, settings);
//       setSnackbar({
//         open: true,
//         message: '設定を保存しました',
//         severity: 'success',
//       });
//     } catch (error) {
//       console.error('設定の保存に失敗しました:', error);
//       setSnackbar({
//         open: true,
//         message: '設定の保存に失敗しました',
//         severity: 'error',
//       });
//     }
//   };

//   // 設定のリセットハンドラー
//   const handleResetSettings = () => {
//     setIsResetDialogOpen(true);
//   };

//   // リセットの確認ハンドラー
//   const confirmResetSettings = () => {
//     // デフォルトの設定にリセットする処理を実装
//     setTargetProfitMargin(25);
//     setExchangeRate(140);
//     setCategories({
//       additionalCosts: [],
//       fees: [],
//     });
//     setShippingRateTemplates([
//       {
//         id: 'default',
//         name: 'デフォルト送料設定',
//         shippingRates: [
//           {
//             minPriceJPY: 0,
//             maxPriceJPY: 7000,
//             costJPY: 700,
//             minPriceUSD: 0,
//             maxPriceUSD: 50,
//             costUSD: 5,
//           },
//           {
//             minPriceJPY: 7000,
//             maxPriceJPY: 14000,
//             costJPY: 1400,
//             minPriceUSD: 50,
//             maxPriceUSD: 100,
//             costUSD: 10,
//           },
//           {
//             minPriceJPY: 14000,
//             maxPriceJPY: Infinity,
//             costJPY: 2100,
//             minPriceUSD: 100,
//             maxPriceUSD: Infinity,
//             costUSD: 15,
//           },
//         ],
//       },
//     ]);
//     setSelectedShippingTemplateId('default');
//     setFees({
//       ebayFinalValue: 10,
//       payoneer: 2,
//       promotedListing: 3,
//       ebayTransactionFee: 0.3,
//     });
//     setBestOfferAutoAcceptPercentage(95);
//     setMinimumBestOfferPercentage(80);
//     setIsResetDialogOpen(false);
//     setSnackbar({
//       open: true,
//       message: '設定をデフォルトに戻しました',
//       severity: 'info',
//     });
//   };

//   // ドラッグ＆ドロップの終了ハンドラー
//   const onDragEnd = (result) => {
//     if (!result.destination) return;

//     const sourceCategory = result.source.droppableId;
//     const destCategory = result.destination.droppableId;

//     setCategories((prevCategories) => {
//       const newCategories = { ...prevCategories };
//       const [movedItem] = newCategories[sourceCategory].splice(result.source.index, 1);
//       newCategories[destCategory].splice(result.destination.index, 0, movedItem);
//       return newCategories;
//     });

//     setSnackbar({
//       open: true,
//       message: '項目の順序を変更しました',
//       severity: 'info',
//     });
//   };

//   // 項目の値を変更するハンドラー
//   const handleItemValueChange = (categoryKey, itemId, newValue, field) => {
//     setCategories((prevCategories) => ({
//       ...prevCategories,
//       [categoryKey]: prevCategories[categoryKey].map((item) =>
//         item.id === itemId ? { ...item, [field]: field === 'value' ? parseFloat(newValue) || 0 : newValue } : item
//       ),
//     }));
//   };

//   // 項目を削除するハンドラー
//   const handleRemoveItem = (categoryKey, itemId) => {
//     setCategories((prevCategories) => ({
//       ...prevCategories,
//       [categoryKey]: prevCategories[categoryKey].filter((item) => item.id !== itemId),
//     }));
//     setSnackbar({
//       open: true,
//       message: '項目を削除しました',
//       severity: 'info',
//     });
//   };

//   // 項目を追加するハンドラー
//   const handleAddItem = (categoryKey) => {
//     const newItem = { id: `new-item-${Date.now()}`, name: '新しい項目', value: 0, type: 'fixed' };
//     setCategories((prevCategories) => ({
//       ...prevCategories,
//       [categoryKey]: [...prevCategories[categoryKey], newItem],
//     }));
//     setSnackbar({
//       open: true,
//       message: '新しい項目を追加しました',
//       severity: 'success',
//     });
//   };

//   // カテゴリーをレンダリングする関数
//   const renderCategory = (categoryKey, categoryName) => (
//     <Accordion key={categoryKey}>
//       <AccordionSummary expandIcon={<ExpandMoreIcon />}>
//         <Typography>{categoryName}</Typography>
//       </AccordionSummary>
//       <AccordionDetails>
//         <Droppable droppableId={categoryKey}>
//           {(provided) => (
//             <Box {...provided.droppableProps} ref={provided.innerRef}>
//               {categories[categoryKey].map((item, index) => (
//                 <Draggable key={item.id} draggableId={item.id} index={index}>
//                   {(provided) => (
//                     <Box
//                       ref={provided.innerRef}
//                       {...provided.draggableProps}
//                       {...provided.dragHandleProps}
//                       sx={{ display: 'flex', alignItems: 'center', mb: 1 }}
//                     >
//                       <TextField
//                         label="項目名"
//                         value={item.name}
//                         onChange={(e) => handleItemValueChange(categoryKey, item.id, e.target.value, 'name')}
//                         size="small"
//                         sx={{ flexGrow: 1, mr: 1 }}
//                       />
//                       <TextField
//                         label="値"
//                         value={item.value}
//                         onChange={(e) => handleItemValueChange(categoryKey, item.id, e.target.value, 'value')}
//                         type="number"
//                         size="small"
//                         sx={{ width: 100, mr: 1 }}
//                       />
//                       <FormControl size="small" sx={{ minWidth: 120, mr: 1 }}>
//                         <InputLabel>タイプ</InputLabel>
//                         <Select
//                           value={item.type}
//                           onChange={(e) => handleItemValueChange(categoryKey, item.id, e.target.value, 'type')}
//                           label="タイプ"
//                         >
//                           <MenuItem value="fixed">固定</MenuItem>
//                           <MenuItem value="percentage">割合 (%)</MenuItem>
//                         </Select>
//                       </FormControl>
//                       <IconButton onClick={() => handleRemoveItem(categoryKey, item.id)}>
//                         <DeleteIcon />
//                       </IconButton>
//                     </Box>
//                   )}
//                 </Draggable>
//               ))}
//               {provided.placeholder}
//             </Box>
//           )}
//         </Droppable>
//         <Button startIcon={<AddIcon />} onClick={() => handleAddItem(categoryKey)}>
//           項目を追加
//         </Button>
//       </AccordionDetails>
//     </Accordion>
//   );

//   // 価格を計算する関数
//   const calculatePrices = () => {
//     setIsCalculating(true);
//     setError(null);

//     try {
//       if (!data || data.length === 0) {
//         throw new Error('データが不足しています。');
//       }

//       const columns = Object.keys(data[0]);
//       if (!columns.includes('StartPrice')) {
//         throw new Error('StartPrice列が見つかりません。');
//       }

//       const selectedShippingTemplate = shippingRateTemplates.find(
//         (template) => template.id === selectedShippingTemplateId
//       );

//       if (!selectedShippingTemplate) {
//         throw new Error('選択された送料テンプレートが見つかりません。');
//       }

//       const newCalculatedPrices = data.map((item) => {
//         const startPrice = parseFloat(item.StartPrice);
//         if (isNaN(startPrice)) {
//           return null;
//         }

//         let totalCost = startPrice;
//         let totalPercentage = 0;

//         // カテゴリーの項目を適用
//         Object.values(categories)
//           .flat()
//           .forEach((categoryItem) => {
//             if (categoryItem.type === 'fixed') {
//               totalCost += parseFloat(categoryItem.value) || 0;
//             } else if (categoryItem.type === 'percentage') {
//               totalPercentage += parseFloat(categoryItem.value) || 0;
//             }
//           });

//         // 手数料を追加
//         totalPercentage +=
//           parseFloat(fees.ebayFinalValue || 0) +
//           parseFloat(fees.payoneer || 0) +
//           parseFloat(fees.promotedListing || 0);
//         totalCost += parseFloat(fees.ebayTransactionFee || 0);

//         // 送料を追加
//         const shippingRate = selectedShippingTemplate?.shippingRates?.find(
//           (rate) =>
//             startPrice >= parseFloat(rate.minPriceUSD) &&
//             (parseFloat(rate.maxPriceUSD) === Infinity || startPrice < parseFloat(rate.maxPriceUSD))
//         );
//         if (shippingRate) {
//           totalCost += parseFloat(shippingRate.costUSD) || 0;
//         }

//         // 目標利益率を追加
//         totalPercentage += parseFloat(targetProfitMargin) || 0;

//         // 販売価格を計算
//         let calculatedPrice = totalCost / (1 - totalPercentage / 100);
//         calculatedPrice = adjustPriceTo99(calculatedPrice);

//         // ベストオファー価格を計算
//         const bestOfferAutoAcceptPrice = calculatedPrice * (bestOfferAutoAcceptPercentage / 100);
//         const minimumBestOfferPrice = calculatedPrice * (minimumBestOfferPercentage / 100);

//         // 利益と利益率を計算
//         const profit = calculatedPrice - totalCost;
//         const profitMargin = (profit / calculatedPrice) * 100;

//         return {
//           originalStartPrice: startPrice.toFixed(2),
//           calculatedPrice: calculatedPrice.toFixed(2),
//           bestOfferAutoAcceptPrice: bestOfferAutoAcceptPrice.toFixed(2),
//           minimumBestOfferPrice: minimumBestOfferPrice.toFixed(2),
//           profit: profit.toFixed(2),
//           profitMargin: profitMargin.toFixed(2),
//         };
//       });

//       setCalculatedPrices(newCalculatedPrices.filter((price) => price !== null));
//     } catch (err) {
//       setError(err.message);
//     } finally {
//       setIsCalculating(false);
//     }
//   };

//   // 価格を99に調整する関数
//   const adjustPriceTo99 = (price) => {
//     return Math.floor(price) + 0.99;
//   };

//   // 価格を適用するハンドラー
//   const handleApplyPrices = () => {
//     try {
//       // 価格を適用する前に元のデータを保存
//       if (!originalData) {
//         setOriginalData([...data]);
//       }

//       const updatedData = data.map((item, index) => {
//         const calculatedPrice = calculatedPrices[index];
//         if (!calculatedPrice) return item;

//         return {
//           ...item,
//           StartPrice: calculatedPrice.calculatedPrice,
//           BestOfferAutoAcceptPrice: calculatedPrice.bestOfferAutoAcceptPrice,
//           MinimumBestOfferPrice: calculatedPrice.minimumBestOfferPrice,
//         };
//       });

//       setData(updatedData);
//       setSnackbar({
//         open: true,
//         message: '価格が更新されました',
//         severity: 'success',
//       });
//       setIsPriceApplied(true); // 価格が適用されたことを記録
//     } catch (err) {
//       setError('価格の更新に失敗しました: ' + err.message);
//     }
//   };

//   // 価格を元に戻すハンドラー
//   const handleRestorePrices = () => {
//     if (originalData) {
//       setData(originalData);
//       setOriginalData(null); // 元のデータをクリア
//       setSnackbar({
//         open: true,
//         message: '価格が元に戻されました',
//         severity: 'success',
//       });
//       setIsPriceApplied(false); // 価格が適用されていない状態に戻す
//     } else {
//       setSnackbar({
//         open: true,
//         message: '元の価格がありません',
//         severity: 'warning',
//       });
//     }
//   };

//   // テンプレート名の編集ハンドラー
//   const handleEditTemplateName = (templateId) => {
//     const template = shippingRateTemplates.find((t) => t.id === templateId);
//     if (template) {
//       setEditingTemplateId(templateId);
//       setNewTemplateName(template.name);
//       setIsEditTemplateNameDialogOpen(true);
//     }
//   };

//   // テンプレート名を更新するハンドラー
//   const handleTemplateNameUpdate = () => {
//     setShippingRateTemplates((prevTemplates) =>
//       prevTemplates.map((template) =>
//         template.id === editingTemplateId ? { ...template, name: newTemplateName } : template
//       )
//     );
//     setIsEditTemplateNameDialogOpen(false);
//     setSnackbar({
//       open: true,
//       message: 'テンプレート名を更新しました',
//       severity: 'success',
//     });
//   };

//   // 新しい送料テンプレートを追加するハンドラー
//   const handleAddShippingTemplate = () => {
//     const newTemplateId = `template-${Date.now()}`;
//     setShippingRateTemplates((prevTemplates) => [
//       ...prevTemplates,
//       {
//         id: newTemplateId,
//         name: `新しいテンプレート ${prevTemplates.length + 1}`,
//         shippingRates: [],
//       },
//     ]);
//     setSelectedShippingTemplateId(newTemplateId);
//     setSnackbar({
//       open: true,
//       message: '新しい送料テンプレートを追加しました',
//       severity: 'success',
//     });
//   };

//   // 送料テンプレートを削除するハンドラー
//   const handleRemoveShippingTemplate = (templateId) => {
//     setShippingRateTemplates((prevTemplates) => prevTemplates.filter((template) => template.id !== templateId));
//     if (selectedShippingTemplateId === templateId && shippingRateTemplates.length > 1) {
//       setSelectedShippingTemplateId(shippingRateTemplates[0].id);
//     }
//     setSnackbar({
//       open: true,
//       message: '送料テンプレートを削除しました',
//       severity: 'info',
//     });
//   };

//   // 送料設定を変更するハンドラー
//   const handleShippingRateChange = (index, field, value) => {
//     setShippingRateTemplates((prevTemplates) =>
//       prevTemplates.map((template) =>
//         template.id === selectedShippingTemplateId
//           ? {
//               ...template,
//               shippingRates: template.shippingRates.map((rate, i) =>
//                 i === index
//                   ? {
//                       ...rate,
//                       [field]: parseFloat(value),
//                       [field.endsWith('JPY') ? field.replace('JPY', 'USD') : field.replace('USD', 'JPY')]:
//                         field.endsWith('JPY')
//                           ? (parseFloat(value) / exchangeRate).toFixed(2)
//                           : Math.round(parseFloat(value) * exchangeRate),
//                     }
//                   : rate
//               ),
//             }
//           : template
//       )
//     );
//   };

//   // 送料設定を削除するハンドラー
//   const handleRemoveShippingRate = (index) => {
//     setShippingRateTemplates((prevTemplates) =>
//       prevTemplates.map((template) =>
//         template.id === selectedShippingTemplateId
//           ? {
//               ...template,
//               shippingRates: template.shippingRates.filter((_, i) => i !== index),
//             }
//           : template
//       )
//     );
//   };

//   // 新しい送料設定を追加するハンドラー
//   const handleAddShippingRate = () => {
//     setShippingRateTemplates((prevTemplates) =>
//       prevTemplates.map((template) =>
//         template.id === selectedShippingTemplateId
//           ? {
//               ...template,
//               shippingRates: [
//                 ...template.shippingRates,
//                 { minPriceJPY: 0, maxPriceJPY: 0, costJPY: 0, minPriceUSD: 0, maxPriceUSD: 0, costUSD: 0 },
//               ],
//             }
//           : template
//       )
//     );
//   };

//   // 手数料を変更するハンドラー
//   const handleFeeChange = (feeType, value) => {
//     setFees((prevFees) => ({
//       ...prevFees,
//       [feeType]: parseFloat(value),
//     }));
//   };

//   // ベストオファー自動承認価格の変更ハンドラー
//   const handleBestOfferAutoAcceptChange = (event) => {
//     setBestOfferAutoAcceptPercentage(parseFloat(event.target.value));
//   };

//   // 最小ベストオファー価格の変更ハンドラー
//   const handleMinimumBestOfferChange = (event) => {
//     setMinimumBestOfferPercentage(parseFloat(event.target.value));
//   };

//   // 価格シミュレーションダイアログを閉じる関数
//   const closePriceSimulation = () => {
//     setShowPriceSimulation(false);
//   };

//   // 通貨のフォーマット関数
//   const formatCurrency = (value, currency = 'USD') => {
//     if (value === undefined || value === null || isNaN(parseFloat(value))) {
//       return currency === 'USD' ? '$0.00' : '¥0';
//     }
//     if (currency === 'USD') {
//       return `$${parseFloat(value).toFixed(2)}`;
//     } else if (currency === 'JPY') {
//       return `¥${Math.round(parseFloat(value) * exchangeRate)}`;
//     }
//   };

//   // 設定項目を表示するコンポーネント
//   const SettingItem = ({ label, value }) => (
//     <Typography variant="body2">
//       <strong>{label}:</strong> {value}
//     </Typography>
//   );

//   // 選択された送料テンプレートの名前を取得
//   const selectedShippingTemplate =
//     shippingRateTemplates.find((template) => template.id === selectedShippingTemplateId) ||
//     shippingRateTemplates[0];

//   const selectedShippingTemplateName = selectedShippingTemplate
//     ? selectedShippingTemplate.name
//     : 'デフォルト送料設定';

//   return (
//     <Box sx={{ flexGrow: 1, p: 2 }}>
//       <Grid container spacing={2}>
//         {/* 左側のコンテンツ */}
//         <Grid item xs={12} md={6}>
//           <Typography variant="h4" gutterBottom>
//             価格管理
//             <Tooltip title="ヘルプを表示">
//               <IconButton onClick={() => setIsHelpDialogOpen(true)}>
//                 <HelpOutlineIcon />
//               </IconButton>
//             </Tooltip>
//           </Typography>

//           {/* 目標利益率設定 */}
//           <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
//             <Typography variant="h6" gutterBottom>
//               目標利益率: {targetProfitMargin}%
//             </Typography>
//             <Slider
//               value={targetProfitMargin}
//               onChange={handleProfitMarginChange}
//               aria-labelledby="profit-margin-slider"
//               valueLabelDisplay="auto"
//               step={1}
//               marks
//               min={0}
//               max={100}
//             />
//           </Paper>

//           {/* 為替レート設定 */}
//           <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
//             <Typography variant="h6" gutterBottom>
//               為替レート設定
//             </Typography>
//             <Typography>1 USD = {exchangeRate.toFixed(2)} JPY</Typography>
//             <TextField
//               label="為替レート (1 USD = X JPY)"
//               value={exchangeRate}
//               onChange={handleExchangeRateChange}
//               type="number"
//               inputProps={{ step: '0.01' }}
//               sx={{ width: 200, mt: 2 }}
//             />
//           </Paper>

//           {/* その他の設定ボタン */}
//           <Grid container spacing={2} sx={{ mb: 3 }}>
//             <Grid item xs={12} sm={4}>
//               <Button variant="outlined" color="primary" onClick={() => setIsShippingDialogOpen(true)} fullWidth>
//                 送料設定
//               </Button>
//             </Grid>
//             <Grid item xs={12} sm={4}>
//               <Button variant="outlined" color="primary" onClick={() => setIsFeeDialogOpen(true)} fullWidth>
//                 手数料設定
//               </Button>
//             </Grid>
//             <Grid item xs={12} sm={4}>
//               <Button variant="outlined" color="primary" onClick={() => setIsBestOfferDialogOpen(true)} fullWidth>
//                 ベストオファー設定
//               </Button>
//             </Grid>
//           </Grid>

//           {/* カテゴリー設定 */}
//           <DragDropContext onDragEnd={onDragEnd}>
//             <Grid container spacing={2}>
//               <Grid item xs={12} md={6}>
//                 {renderCategory('additionalCosts', '上乗せ金額')}
//               </Grid>
//               <Grid item xs={12} md={6}>
//                 {renderCategory('fees', '手数料')}
//               </Grid>
//             </Grid>
//           </DragDropContext>

//           {/* 設定保存とリセット */}
//           <Box sx={{ mt: 3, display: 'flex', justifyContent: 'space-between' }}>
//             <Button startIcon={<SaveIcon />} variant="contained" color="primary" onClick={handleSaveSettings}>
//               設定を保存
//             </Button>
//             <Button startIcon={<SettingsBackupRestoreIcon />} variant="outlined" onClick={handleResetSettings}>
//               デフォルトに戻す
//             </Button>
//           </Box>
//         </Grid>

//         {/* 右側のコンテンツ */}
//         <Grid item xs={12} md={6}>
//           <Box sx={{ mt: { xs: 4, md: 0 } }}>
//             <Typography variant="h5" gutterBottom>
//               価格計算と適用
//             </Typography>

//             {/* 現在の設定セクション */}
//             <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
//               <Typography variant="h6" gutterBottom>
//                 現在の設定
//               </Typography>
//               <Grid container spacing={2}>
//                 <Grid item xs={12} sm={6}>
//                   <SettingItem label="目標利益率" value={`${targetProfitMargin}%`} />
//                   <SettingItem label="eBay最終価値手数料" value={`${fees.ebayFinalValue}%`} />
//                   <SettingItem label="Payoneer手数料" value={`${fees.payoneer}%`} />
//                   <SettingItem label="広告出品手数料" value={`${fees.promotedListing}%`} />
//                   <SettingItem
//                     label="eBay取引手数料"
//                     value={`${formatCurrency(fees.ebayTransactionFee)} (${formatCurrency(
//                       fees.ebayTransactionFee,
//                       'JPY'
//                     )})`}
//                   />
//                 </Grid>
//                 <Grid item xs={12} sm={6}>
//                   <SettingItem label="為替レート" value={`1 USD = ${exchangeRate.toFixed(2)} JPY`} />
//                   <SettingItem label="Best Offer自動承認価格" value={`${bestOfferAutoAcceptPercentage}%`} />
//                   <SettingItem label="最小Best Offer価格" value={`${minimumBestOfferPercentage}%`} />
//                   <SettingItem label="適用されている送料テンプレート" value={`${selectedShippingTemplateName}`} />
//                 </Grid>
//               </Grid>
//             </Paper>

//             {error && (
//               <Alert severity="error" sx={{ mb: 2 }}>
//                 {error}
//               </Alert>
//             )}

//             {/* 適用状態の表示 */}
//             {isPriceApplied ? (
//               <Alert
//                 severity="success"
//                 iconMapping={{
//                   success: <CheckCircleIcon fontSize="inherit" />,
//                 }}
//                 sx={{ mb: 2, fontWeight: 'bold', textAlign: 'center' }}
//               >
//                 適用完了
//               </Alert>
//             ) : (
//               <Alert
//                 severity="warning"
//                 iconMapping={{
//                   warning: <WarningAmberIcon fontSize="inherit" />,
//                 }}
//                 sx={{ mb: 2, fontWeight: 'bold', textAlign: 'center' }}
//               >
//                 未適用
//               </Alert>
//             )}

//             <Grid container spacing={2} sx={{ mb: 2 }}>
//               <Grid item xs={12} sm={6}>
//                 <Button
//                   variant="contained"
//                   color="primary"
//                   onClick={calculatePrices}
//                   disabled={isCalculating}
//                   fullWidth
//                 >
//                   {isCalculating ? '計算中...' : '価格を再計算'}
//                 </Button>
//               </Grid>
//             </Grid>

//             {calculatedPrices.length > 0 && (
//               <Grid container spacing={2} sx={{ mt: 2 }}>
//                 <Grid item xs={12} sm={4}>
//                   <Button
//                     variant="contained"
//                     color="secondary"
//                     onClick={() => setShowPriceSimulation(true)}
//                     fullWidth
//                   >
//                     価格シミュレーション結果
//                   </Button>
//                 </Grid>
//                 <Grid item xs={12} sm={4}>
//                   <Button
//                     variant="contained"
//                     color="secondary"
//                     onClick={handleApplyPrices}
//                     fullWidth
//                     disabled={isPriceApplied}
//                   >
//                     計算した価格を適用
//                   </Button>
//                 </Grid>
//                 <Grid item xs={12} sm={4}>
//                   <Button
//                     variant="outlined"
//                     color="primary"
//                     onClick={handleRestorePrices}
//                     fullWidth
//                     disabled={!isPriceApplied}
//                   >
//                     価格を元に戻す
//                   </Button>
//                 </Grid>
//               </Grid>
//             )}
//           </Box>
//         </Grid>
//       </Grid>

//       {/* ヘルプダイアログ */}
//       <Dialog open={isHelpDialogOpen} onClose={() => setIsHelpDialogOpen(false)} maxWidth="md" fullWidth>
//         <DialogTitle>価格計算の具体的な説明</DialogTitle>
//         <DialogContent>
//           {/* ここに価格計算の詳細な説明を表示 */}
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => setIsHelpDialogOpen(false)}>閉じる</Button>
//         </DialogActions>
//       </Dialog>

//       {/* リセット確認ダイアログ */}
//       <Dialog open={isResetDialogOpen} onClose={() => setIsResetDialogOpen(false)}>
//         <DialogTitle>設定をリセット</DialogTitle>
//         <DialogContent>
//           <Typography>本当に設定をデフォルトに戻しますか？この操作は元に戻せません。</Typography>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => setIsResetDialogOpen(false)}>キャンセル</Button>
//           <Button onClick={confirmResetSettings} color="error">
//             リセット
//           </Button>
//         </DialogActions>
//       </Dialog>

//       {/* 送料設定ダイアログ */}
//       <Dialog open={isShippingDialogOpen} onClose={() => setIsShippingDialogOpen(false)} maxWidth="lg" fullWidth>
//         <DialogTitle>送料設定</DialogTitle>
//         <DialogContent>
//           {/* 送料テンプレートの選択と管理 */}
//           <Box sx={{ mb: 2 }}>
//             <FormControl fullWidth>
//               <InputLabel>送料テンプレートを選択</InputLabel>
//               <Select
//                 value={selectedShippingTemplateId}
//                 onChange={(e) => setSelectedShippingTemplateId(e.target.value)}
//                 label="送料テンプレートを選択"
//               >
//                 {shippingRateTemplates.map((template) => (
//                   <MenuItem
//                     key={template.id}
//                     value={template.id}
//                     sx={{ display: 'flex', alignItems: 'center' }}
//                   >
//                     <Typography variant="body1" sx={{ flexGrow: 1 }}>
//                       {template.name}
//                     </Typography>
//                     <IconButton
//                       size="small"
//                       onClick={(e) => {
//                         e.stopPropagation();
//                         handleEditTemplateName(template.id);
//                       }}
//                     >
//                       <EditIcon fontSize="small" />
//                     </IconButton>
//                   </MenuItem>
//                 ))}
//               </Select>
//             </FormControl>
//             <Box sx={{ mt: 1, display: 'flex', justifyContent: 'space-between' }}>
//               <Button startIcon={<AddIcon />} onClick={handleAddShippingTemplate}>
//                 新しいテンプレートを追加
//               </Button>
//               <Button
//                 startIcon={<DeleteIcon />}
//                 onClick={() => handleRemoveShippingTemplate(selectedShippingTemplateId)}
//                 disabled={shippingRateTemplates.length <= 1}
//               >
//                 テンプレートを削除
//               </Button>
//             </Box>
//           </Box>

//           {/* 選択されたテンプレートの送料設定 */}
//           <TableContainer component={Paper}>
//             <Table>
//               <TableHead>
//                 <TableRow>
//                   <TableCell>最小仕入れ価格 (JPY)</TableCell>
//                   <TableCell>最大仕入れ価格 (JPY)</TableCell>
//                   <TableCell>送料 (JPY)</TableCell>
//                   <TableCell>最小仕入れ価格 (USD)</TableCell>
//                   <TableCell>最大仕入れ価格 (USD)</TableCell>
//                   <TableCell>送料 (USD)</TableCell>
//                   <TableCell>操作</TableCell>
//                 </TableRow>
//               </TableHead>
//               <TableBody>
//                 {shippingRateTemplates
//                   .find((template) => template.id === selectedShippingTemplateId)
//                   .shippingRates.map((rate, index) => (
//                     <TableRow key={index}>
//                       <TableCell>
//                         <TextField
//                           type="number"
//                           value={rate.minPriceJPY}
//                           onChange={(e) => handleShippingRateChange(index, 'minPriceJPY', e.target.value)}
//                           size="small"
//                           fullWidth
//                         />
//                       </TableCell>
//                       <TableCell>
//                         <TextField
//                           type="number"
//                           value={rate.maxPriceJPY === Infinity ? '' : rate.maxPriceJPY}
//                           onChange={(e) =>
//                             handleShippingRateChange(index, 'maxPriceJPY', e.target.value || Infinity)
//                           }
//                           size="small"
//                           fullWidth
//                         />
//                       </TableCell>
//                       <TableCell>
//                         <TextField
//                           type="number"
//                           value={rate.costJPY}
//                           onChange={(e) => handleShippingRateChange(index, 'costJPY', e.target.value)}
//                           size="small"
//                           fullWidth
//                         />
//                       </TableCell>
//                       <TableCell>
//                         <TextField
//                           type="number"
//                           value={rate.minPriceUSD}
//                           onChange={(e) => handleShippingRateChange(index, 'minPriceUSD', e.target.value)}
//                           size="small"
//                           fullWidth
//                         />
//                       </TableCell>
//                       <TableCell>
//                         <TextField
//                           type="number"
//                           value={rate.maxPriceUSD === Infinity ? '' : rate.maxPriceUSD}
//                           onChange={(e) =>
//                             handleShippingRateChange(index, 'maxPriceUSD', e.target.value || Infinity)
//                           }
//                           size="small"
//                           fullWidth
//                         />
//                       </TableCell>
//                       <TableCell>
//                         <TextField
//                           type="number"
//                           value={rate.costUSD}
//                           onChange={(e) => handleShippingRateChange(index, 'costUSD', e.target.value)}
//                           size="small"
//                           fullWidth
//                         />
//                       </TableCell>
//                       <TableCell>
//                         <IconButton onClick={() => handleRemoveShippingRate(index)}>
//                           <DeleteIcon />
//                         </IconButton>
//                       </TableCell>
//                     </TableRow>
//                   ))}
//               </TableBody>
//             </Table>
//           </TableContainer>
//           <Button startIcon={<AddIcon />} onClick={handleAddShippingRate} sx={{ mt: 2 }}>
//             送料設定を追加
//           </Button>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => setIsShippingDialogOpen(false)}>閉じる</Button>
//         </DialogActions>
//       </Dialog>

//       {/* テンプレート名編集ダイアログ */}
//       <Dialog
//         open={isEditTemplateNameDialogOpen}
//         onClose={() => setIsEditTemplateNameDialogOpen(false)}
//       >
//         <DialogTitle>テンプレート名の編集</DialogTitle>
//         <DialogContent>
//           <TextField
//             fullWidth
//             label="テンプレート名"
//             value={newTemplateName}
//             onChange={(e) => setNewTemplateName(e.target.value)}
//           />
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => setIsEditTemplateNameDialogOpen(false)}>キャンセル</Button>
//           <Button onClick={handleTemplateNameUpdate} color="primary">
//             更新
//           </Button>
//         </DialogActions>
//       </Dialog>

//       {/* 手数料設定ダイアログ */}
//       <Dialog open={isFeeDialogOpen} onClose={() => setIsFeeDialogOpen(false)} maxWidth="sm" fullWidth>
//         <DialogTitle>手数料設定</DialogTitle>
//         <DialogContent>
//           <List>
//             <ListItem>
//               <ListItemText primary="eBay最終価値手数料 (%)" />
//               <ListItemSecondaryAction>
//                 <TextField
//                   type="number"
//                   value={fees.ebayFinalValue}
//                   onChange={(e) => handleFeeChange('ebayFinalValue', e.target.value)}
//                   size="small"
//                 />
//               </ListItemSecondaryAction>
//             </ListItem>
//             <ListItem>
//               <ListItemText primary="Payoneer手数料 (%)" />
//               <ListItemSecondaryAction>
//                 <TextField
//                   type="number"
//                   value={fees.payoneer}
//                   onChange={(e) => handleFeeChange('payoneer', e.target.value)}
//                   size="small"
//                 />
//               </ListItemSecondaryAction>
//             </ListItem>
//             <ListItem>
//               <ListItemText primary="広告出品手数料 (%)" />
//               <ListItemSecondaryAction>
//                 <TextField
//                   type="number"
//                   value={fees.promotedListing}
//                   onChange={(e) => handleFeeChange('promotedListing', e.target.value)}
//                   size="small"
//                 />
//               </ListItemSecondaryAction>
//             </ListItem>
//             <ListItem>
//               <ListItemText primary="eBay取引手数料 ($)" />
//               <ListItemSecondaryAction>
//                 <TextField
//                   type="number"
//                   value={fees.ebayTransactionFee}
//                   onChange={(e) => handleFeeChange('ebayTransactionFee', e.target.value)}
//                   size="small"
//                 />
//               </ListItemSecondaryAction>
//             </ListItem>
//           </List>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => setIsFeeDialogOpen(false)}>閉じる</Button>
//         </DialogActions>
//       </Dialog>

//       {/* ベストオファー設定ダイアログ */}
//       <Dialog
//         open={isBestOfferDialogOpen}
//         onClose={() => setIsBestOfferDialogOpen(false)}
//         maxWidth="sm"
//         fullWidth
//       >
//         <DialogTitle>ベストオファー設定</DialogTitle>
//         <DialogContent>
//           <Grid container spacing={2}>
//             <Grid item xs={12}>
//               <TextField
//                 fullWidth
//                 label="ベストオファー自動承認価格 (%)"
//                 type="number"
//                 value={bestOfferAutoAcceptPercentage}
//                 onChange={handleBestOfferAutoAcceptChange}
//                 InputProps={{ inputProps: { min: 0, max: 100 } }}
//               />
//             </Grid>
//             <Grid item xs={12}>
//               <TextField
//                 fullWidth
//                 label="最小ベストオファー価格 (%)"
//                 type="number"
//                 value={minimumBestOfferPercentage}
//                 onChange={handleMinimumBestOfferChange}
//                 InputProps={{ inputProps: { min: 0, max: 100 } }}
//               />
//             </Grid>
//           </Grid>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => setIsBestOfferDialogOpen(false)}>閉じる</Button>
//         </DialogActions>
//       </Dialog>

//       {/* 価格シミュレーション結果ダイアログ */}
//       <Dialog open={showPriceSimulation} onClose={closePriceSimulation} maxWidth="lg" fullWidth>
//         <DialogTitle>価格シミュレーション結果</DialogTitle>
//         <DialogContent sx={{ height: '90vh' }}>
//           {/* 価格シミュレーション結果を表示 */}
//           {calculatedPrices.length > 0 && (
//             <TableContainer component={Paper} sx={{ maxHeight: '100%', overflow: 'auto' }}>
//               <Table stickyHeader>
//                 <TableHead>
//                   <TableRow>
//                     <TableCell>元の価格 (USD)</TableCell>
//                     <TableCell>計算後の価格 (USD)</TableCell>
//                     <TableCell>ベストオファー自動承認価格 (USD)</TableCell>
//                     <TableCell>最小ベストオファー価格 (USD)</TableCell>
//                     <TableCell>利益 (USD)</TableCell>
//                     <TableCell>利益率 (%)</TableCell>
//                   </TableRow>
//                 </TableHead>
//                 <TableBody>
//                   {calculatedPrices.map((price, index) => (
//                     <TableRow key={index}>
//                       <TableCell>{formatCurrency(price.originalStartPrice)}</TableCell>
//                       <TableCell>{formatCurrency(price.calculatedPrice)}</TableCell>
//                       <TableCell>{formatCurrency(price.bestOfferAutoAcceptPrice)}</TableCell>
//                       <TableCell>{formatCurrency(price.minimumBestOfferPrice)}</TableCell>
//                       <TableCell>{formatCurrency(price.profit)}</TableCell>
//                       <TableCell>{price.profitMargin}%</TableCell>
//                     </TableRow>
//                   ))}
//                 </TableBody>
//               </Table>
//             </TableContainer>
//           )}
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={closePriceSimulation}>閉じる</Button>
//         </DialogActions>
//       </Dialog>

//       {/* Snackbar */}
//       <Snackbar
//         open={snackbar.open}
//         autoHideDuration={6000}
//         onClose={() => setSnackbar({ ...snackbar, open: false })}
//       >
//         <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity} sx={{ width: '100%' }}>
//           {snackbar.message}
//         </Alert>
//       </Snackbar>
//     </Box>
//   );
// };

// export default PriceManager;




// // PriceManager.js

// import React, { useState, useEffect } from 'react';
// import {
//   Box,
//   Typography,
//   Button,
//   Paper,
//   Slider,
//   Dialog,
//   DialogTitle,
//   DialogContent,
//   DialogActions,
//   Grid,
//   TextField,
//   Accordion,
//   AccordionSummary,
//   AccordionDetails,
//   IconButton,
//   Tooltip,
//   FormControl,
//   InputLabel,
//   Select,
//   MenuItem,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   Alert,
//   Snackbar,
//   List,
//   ListItem,
//   ListItemText,
//   ListItemSecondaryAction,
// } from '@mui/material';
// import {
//   ExpandMore as ExpandMoreIcon,
//   HelpOutline as HelpOutlineIcon,
//   Save as SaveIcon,
//   SettingsBackupRestore as SettingsBackupRestoreIcon,
//   Add as AddIcon,
//   Delete as DeleteIcon,
//   Edit as EditIcon,
//   CheckCircle as CheckCircleIcon,
//   WarningAmber as WarningAmberIcon,
// } from '@mui/icons-material';
// import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

// // Firebase関連のインポート
// import { getAuth } from 'firebase/auth';
// import { getFirestore, doc, getDoc, setDoc } from 'firebase/firestore';

// const PriceManager = ({ data, setData }) => {
//   // ユーザー状態の定義
//   const [user, setUser] = useState(null);

//   // ユーザーの取得
//   useEffect(() => {
//     const auth = getAuth();
//     const unsubscribe = auth.onAuthStateChanged((firebaseUser) => {
//       setUser(firebaseUser);
//     });
//     return () => unsubscribe();
//   }, []);

//   // 状態変数の定義
//   const [targetProfitMargin, setTargetProfitMargin] = useState(25);
//   const [exchangeRate, setExchangeRate] = useState(140);
//   const [categories, setCategories] = useState({
//     additionalCosts: [],
//     fees: [],
//   });
//   const [isHelpDialogOpen, setIsHelpDialogOpen] = useState(false);
//   const [isResetDialogOpen, setIsResetDialogOpen] = useState(false);
//   const [isShippingDialogOpen, setIsShippingDialogOpen] = useState(false);
//   const [isFeeDialogOpen, setIsFeeDialogOpen] = useState(false);
//   const [isBestOfferDialogOpen, setIsBestOfferDialogOpen] = useState(false);
//   const [isEditTemplateNameDialogOpen, setIsEditTemplateNameDialogOpen] = useState(false);
//   const [editingTemplateId, setEditingTemplateId] = useState('');
//   const [newTemplateName, setNewTemplateName] = useState('');
//   const [selectedShippingTemplateId, setSelectedShippingTemplateId] = useState('default');
//   const [shippingRateTemplates, setShippingRateTemplates] = useState([
//     {
//       id: 'default',
//       name: 'デフォルト送料設定',
//       shippingRates: [
//         {
//           minPriceJPY: 0,
//           maxPriceJPY: 7000,
//           costJPY: 700,
//           minPriceUSD: 0,
//           maxPriceUSD: 50,
//           costUSD: 5,
//         },
//         {
//           minPriceJPY: 7000,
//           maxPriceJPY: 14000,
//           costJPY: 1400,
//           minPriceUSD: 50,
//           maxPriceUSD: 100,
//           costUSD: 10,
//         },
//         {
//           minPriceJPY: 14000,
//           maxPriceJPY: Infinity,
//           costJPY: 2100,
//           minPriceUSD: 100,
//           maxPriceUSD: Infinity,
//           costUSD: 15,
//         },
//       ],
//     },
//   ]);
//   const [fees, setFees] = useState({
//     ebayFinalValue: 10,
//     payoneer: 2,
//     promotedListing: 3,
//     ebayTransactionFee: 0.3,
//   });
//   const [bestOfferAutoAcceptPercentage, setBestOfferAutoAcceptPercentage] = useState(95);
//   const [minimumBestOfferPercentage, setMinimumBestOfferPercentage] = useState(80);
//   const [snackbar, setSnackbar] = useState({
//     open: false,
//     message: '',
//     severity: 'info',
//   });
//   const [error, setError] = useState(null);
//   const [isPriceApplied, setIsPriceApplied] = useState(false);
//   const [isCalculating, setIsCalculating] = useState(false);
//   const [calculatedPrices, setCalculatedPrices] = useState([]);
//   const [showPriceSimulation, setShowPriceSimulation] = useState(false);
//   const [originalData, setOriginalData] = useState(null);

//   // ユーザー設定の読み込み
//   useEffect(() => {
//     if (user) {
//       const loadSettings = async () => {
//         const db = getFirestore();
//         const userDocRef = doc(db, 'userSettings', user.uid);
//         try {
//           const docSnap = await getDoc(userDocRef);
//           if (docSnap.exists()) {
//             const settings = docSnap.data();
//             setTargetProfitMargin(settings.targetProfitMargin || 25);
//             setExchangeRate(settings.exchangeRate || 140);
//             setCategories(settings.categories || { additionalCosts: [], fees: [] });
//             setShippingRateTemplates(settings.shippingRateTemplates || shippingRateTemplates);
//             setSelectedShippingTemplateId(settings.selectedShippingTemplateId || 'default');
//             setFees(
//               settings.fees || {
//                 ebayFinalValue: 10,
//                 payoneer: 2,
//                 promotedListing: 3,
//                 ebayTransactionFee: 0.3,
//               }
//             );
//             setBestOfferAutoAcceptPercentage(settings.bestOfferAutoAcceptPercentage || 95);
//             setMinimumBestOfferPercentage(settings.minimumBestOfferPercentage || 80);
//           }
//         } catch (error) {
//           console.error('設定の読み込みに失敗しました:', error);
//           setSnackbar({
//             open: true,
//             message: '設定の読み込みに失敗しました',
//             severity: 'error',
//           });
//         }
//       };
//       loadSettings();
//     }
//   }, [user]);

//   // 利益率の変更ハンドラー
//   const handleProfitMarginChange = (event, newValue) => {
//     setTargetProfitMargin(newValue);
//   };

//   // 為替レートの変更ハンドラー
//   const handleExchangeRateChange = (event) => {
//     const newRate = parseFloat(event.target.value);
//     if (!isNaN(newRate) && newRate > 0) {
//       setExchangeRate(newRate);
//     }
//   };

//   // 設定の保存ハンドラー
//   const handleSaveSettings = async () => {
//     if (!user) {
//       setSnackbar({
//         open: true,
//         message: 'ユーザーが認証されていません。再度ログインしてください。',
//         severity: 'error',
//       });
//       return;
//     }

//     const db = getFirestore();
//     const userDocRef = doc(db, 'userSettings', user.uid);

//     const settings = {
//       targetProfitMargin,
//       exchangeRate,
//       categories,
//       shippingRateTemplates,
//       selectedShippingTemplateId,
//       fees,
//       bestOfferAutoAcceptPercentage,
//       minimumBestOfferPercentage,
//     };

//     try {
//       await setDoc(userDocRef, settings);
//       setSnackbar({
//         open: true,
//         message: '設定を保存しました',
//         severity: 'success',
//       });
//     } catch (error) {
//       console.error('設定の保存に失敗しました:', error);
//       setSnackbar({
//         open: true,
//         message: '設定の保存に失敗しました',
//         severity: 'error',
//       });
//     }
//   };

//   // 設定のリセットハンドラー
//   const handleResetSettings = () => {
//     setIsResetDialogOpen(true);
//   };

//   // リセットの確認ハンドラー
//   const confirmResetSettings = () => {
//     // デフォルトの設定にリセットする処理を実装
//     setTargetProfitMargin(25);
//     setExchangeRate(140);
//     setCategories({
//       additionalCosts: [],
//       fees: [],
//     });
//     setShippingRateTemplates([
//       {
//         id: 'default',
//         name: 'デフォルト送料設定',
//         shippingRates: [
//           {
//             minPriceJPY: 0,
//             maxPriceJPY: 7000,
//             costJPY: 700,
//             minPriceUSD: 0,
//             maxPriceUSD: 50,
//             costUSD: 5,
//           },
//           {
//             minPriceJPY: 7000,
//             maxPriceJPY: 14000,
//             costJPY: 1400,
//             minPriceUSD: 50,
//             maxPriceUSD: 100,
//             costUSD: 10,
//           },
//           {
//             minPriceJPY: 14000,
//             maxPriceJPY: Infinity,
//             costJPY: 2100,
//             minPriceUSD: 100,
//             maxPriceUSD: Infinity,
//             costUSD: 15,
//           },
//         ],
//       },
//     ]);
//     setSelectedShippingTemplateId('default');
//     setFees({
//       ebayFinalValue: 10,
//       payoneer: 2,
//       promotedListing: 3,
//       ebayTransactionFee: 0.3,
//     });
//     setBestOfferAutoAcceptPercentage(95);
//     setMinimumBestOfferPercentage(80);
//     setIsResetDialogOpen(false);
//     setSnackbar({
//       open: true,
//       message: '設定をデフォルトに戻しました',
//       severity: 'info',
//     });
//   };

//   // ドラッグ＆ドロップの終了ハンドラー
//   const onDragEnd = (result) => {
//     if (!result.destination) return;

//     const sourceCategory = result.source.droppableId;
//     const destCategory = result.destination.droppableId;

//     setCategories((prevCategories) => {
//       const newCategories = { ...prevCategories };
//       const [movedItem] = newCategories[sourceCategory].splice(result.source.index, 1);
//       newCategories[destCategory].splice(result.destination.index, 0, movedItem);
//       return newCategories;
//     });

//     setSnackbar({
//       open: true,
//       message: '項目の順序を変更しました',
//       severity: 'info',
//     });
//   };

//   // 項目の値を変更するハンドラー
//   const handleItemValueChange = (categoryKey, itemId, newValue, field) => {
//     setCategories((prevCategories) => ({
//       ...prevCategories,
//       [categoryKey]: prevCategories[categoryKey].map((item) =>
//         item.id === itemId ? { ...item, [field]: field === 'value' ? parseFloat(newValue) || 0 : newValue } : item
//       ),
//     }));
//   };

//   // 項目を削除するハンドラー
//   const handleRemoveItem = (categoryKey, itemId) => {
//     setCategories((prevCategories) => ({
//       ...prevCategories,
//       [categoryKey]: prevCategories[categoryKey].filter((item) => item.id !== itemId),
//     }));
//     setSnackbar({
//       open: true,
//       message: '項目を削除しました',
//       severity: 'info',
//     });
//   };

//   // 項目を追加するハンドラー
//   const handleAddItem = (categoryKey) => {
//     const newItem = { id: `new-item-${Date.now()}`, name: '新しい項目', value: 0, type: 'fixed' };
//     setCategories((prevCategories) => ({
//       ...prevCategories,
//       [categoryKey]: [...prevCategories[categoryKey], newItem],
//     }));
//     setSnackbar({
//       open: true,
//       message: '新しい項目を追加しました',
//       severity: 'success',
//     });
//   };

//   // カテゴリーをレンダリングする関数
//   const renderCategory = (categoryKey, categoryName) => (
//     <Accordion key={categoryKey}>
//       <AccordionSummary expandIcon={<ExpandMoreIcon />}>
//         <Typography>{categoryName}</Typography>
//       </AccordionSummary>
//       <AccordionDetails>
//         <Droppable droppableId={categoryKey}>
//           {(provided) => (
//             <Box {...provided.droppableProps} ref={provided.innerRef}>
//               {categories[categoryKey].map((item, index) => (
//                 <Draggable key={item.id} draggableId={item.id} index={index}>
//                   {(provided) => (
//                     <Box
//                       ref={provided.innerRef}
//                       {...provided.draggableProps}
//                       {...provided.dragHandleProps}
//                       sx={{ display: 'flex', alignItems: 'center', mb: 1 }}
//                     >
//                       <TextField
//                         label="項目名"
//                         value={item.name}
//                         onChange={(e) => handleItemValueChange(categoryKey, item.id, e.target.value, 'name')}
//                         size="small"
//                         sx={{ flexGrow: 1, mr: 1 }}
//                       />
//                       <TextField
//                         label="値"
//                         value={item.value}
//                         onChange={(e) => handleItemValueChange(categoryKey, item.id, e.target.value, 'value')}
//                         type="number"
//                         size="small"
//                         sx={{ width: 100, mr: 1 }}
//                       />
//                       <FormControl size="small" sx={{ minWidth: 120, mr: 1 }}>
//                         <InputLabel>タイプ</InputLabel>
//                         <Select
//                           value={item.type}
//                           onChange={(e) => handleItemValueChange(categoryKey, item.id, e.target.value, 'type')}
//                           label="タイプ"
//                         >
//                           <MenuItem value="fixed">固定</MenuItem>
//                           <MenuItem value="percentage">割合 (%)</MenuItem>
//                         </Select>
//                       </FormControl>
//                       <IconButton onClick={() => handleRemoveItem(categoryKey, item.id)}>
//                         <DeleteIcon />
//                       </IconButton>
//                     </Box>
//                   )}
//                 </Draggable>
//               ))}
//               {provided.placeholder}
//             </Box>
//           )}
//         </Droppable>
//         <Button startIcon={<AddIcon />} onClick={() => handleAddItem(categoryKey)}>
//           項目を追加
//         </Button>
//       </AccordionDetails>
//     </Accordion>
//   );

//   // 価格を計算する関数
//   const calculatePrices = () => {
//     setIsCalculating(true);
//     setError(null);

//     try {
//       if (!data || data.length === 0) {
//         throw new Error('データが不足しています。');
//       }

//       const columns = Object.keys(data[0]);
//       if (!columns.includes('StartPrice')) {
//         throw new Error('StartPrice列が見つかりません。');
//       }

//       const selectedShippingTemplate = shippingRateTemplates.find(
//         (template) => template.id === selectedShippingTemplateId
//       );

//       if (!selectedShippingTemplate) {
//         throw new Error('選択された送料テンプレートが見つかりません。');
//       }

//       const newCalculatedPrices = data.map((item) => {
//         const startPrice = parseFloat(item.StartPrice);
//         if (isNaN(startPrice)) {
//           return null;
//         }

//         let totalCost = startPrice;
//         let totalPercentage = 0;

//         // カテゴリーの項目を適用
//         Object.values(categories)
//           .flat()
//           .forEach((categoryItem) => {
//             if (categoryItem.type === 'fixed') {
//               totalCost += parseFloat(categoryItem.value) || 0;
//             } else if (categoryItem.type === 'percentage') {
//               totalPercentage += parseFloat(categoryItem.value) || 0;
//             }
//           });

//         // 手数料を追加
//         totalPercentage +=
//           parseFloat(fees.ebayFinalValue || 0) +
//           parseFloat(fees.payoneer || 0) +
//           parseFloat(fees.promotedListing || 0);
//         totalCost += parseFloat(fees.ebayTransactionFee || 0);

//         // 送料を追加
//         const shippingRate = selectedShippingTemplate?.shippingRates?.find(
//           (rate) =>
//             startPrice >= parseFloat(rate.minPriceUSD) &&
//             (parseFloat(rate.maxPriceUSD) === Infinity || startPrice < parseFloat(rate.maxPriceUSD))
//         );
//         if (shippingRate) {
//           totalCost += parseFloat(shippingRate.costUSD) || 0;
//         }

//         // 目標利益率を追加
//         totalPercentage += parseFloat(targetProfitMargin) || 0;

//         // 販売価格を計算
//         let calculatedPrice = totalCost / (1 - totalPercentage / 100);
//         calculatedPrice = adjustPriceTo99(calculatedPrice);

//         // ベストオファー価格を計算
//         const bestOfferAutoAcceptPrice = calculatedPrice * (bestOfferAutoAcceptPercentage / 100);
//         const minimumBestOfferPrice = calculatedPrice * (minimumBestOfferPercentage / 100);

//         // 利益と利益率を計算
//         const profit = calculatedPrice - totalCost;
//         const profitMargin = (profit / calculatedPrice) * 100;

//         return {
//           originalStartPrice: startPrice.toFixed(2),
//           calculatedPrice: calculatedPrice.toFixed(2),
//           bestOfferAutoAcceptPrice: bestOfferAutoAcceptPrice.toFixed(2),
//           minimumBestOfferPrice: minimumBestOfferPrice.toFixed(2),
//           profit: profit.toFixed(2),
//           profitMargin: profitMargin.toFixed(2),
//         };
//       });

//       setCalculatedPrices(newCalculatedPrices.filter((price) => price !== null));
//     } catch (err) {
//       setError(err.message);
//     } finally {
//       setIsCalculating(false);
//     }
//   };

//   // 価格を99に調整する関数
//   const adjustPriceTo99 = (price) => {
//     return Math.floor(price) + 0.99;
//   };

//   // 価格を適用するハンドラー
//   const handleApplyPrices = () => {
//     try {
//       // 価格を適用する前に元のデータを保存
//       if (!originalData) {
//         setOriginalData([...data]);
//       }

//       const updatedData = data.map((item, index) => {
//         const calculatedPrice = calculatedPrices[index];
//         if (!calculatedPrice) return item;

//         return {
//           ...item,
//           StartPrice: calculatedPrice.calculatedPrice,
//           BestOfferAutoAcceptPrice: calculatedPrice.bestOfferAutoAcceptPrice,
//           MinimumBestOfferPrice: calculatedPrice.minimumBestOfferPrice,
//         };
//       });

//       setData(updatedData);
//       setSnackbar({
//         open: true,
//         message: '価格が更新されました',
//         severity: 'success',
//       });
//       setIsPriceApplied(true); // 価格が適用されたことを記録
//     } catch (err) {
//       setError('価格の更新に失敗しました: ' + err.message);
//     }
//   };

//   // 価格を元に戻すハンドラー
//   const handleRestorePrices = () => {
//     if (originalData) {
//       setData(originalData);
//       setOriginalData(null); // 元のデータをクリア
//       setSnackbar({
//         open: true,
//         message: '価格が元に戻されました',
//         severity: 'success',
//       });
//       setIsPriceApplied(false); // 価格が適用されていない状態に戻す
//     } else {
//       setSnackbar({
//         open: true,
//         message: '元の価格がありません',
//         severity: 'warning',
//       });
//     }
//   };

//   // テンプレート名の編集ハンドラー
//   const handleEditTemplateName = (templateId) => {
//     const template = shippingRateTemplates.find((t) => t.id === templateId);
//     if (template) {
//       setEditingTemplateId(templateId);
//       setNewTemplateName(template.name);
//       setIsEditTemplateNameDialogOpen(true);
//     }
//   };

//   // テンプレート名を更新するハンドラー
//   const handleTemplateNameUpdate = () => {
//     setShippingRateTemplates((prevTemplates) =>
//       prevTemplates.map((template) =>
//         template.id === editingTemplateId ? { ...template, name: newTemplateName } : template
//       )
//     );
//     setIsEditTemplateNameDialogOpen(false);
//     setSnackbar({
//       open: true,
//       message: 'テンプレート名を更新しました',
//       severity: 'success',
//     });
//   };

//   // 新しい送料テンプレートを追加するハンドラー
//   const handleAddShippingTemplate = () => {
//     const newTemplateId = `template-${Date.now()}`;
//     setShippingRateTemplates((prevTemplates) => [
//       ...prevTemplates,
//       {
//         id: newTemplateId,
//         name: `新しいテンプレート ${prevTemplates.length + 1}`,
//         shippingRates: [],
//       },
//     ]);
//     setSelectedShippingTemplateId(newTemplateId);
//     setSnackbar({
//       open: true,
//       message: '新しい送料テンプレートを追加しました',
//       severity: 'success',
//     });
//   };

//   // 送料テンプレートを削除するハンドラー
//   const handleRemoveShippingTemplate = (templateId) => {
//     setShippingRateTemplates((prevTemplates) => prevTemplates.filter((template) => template.id !== templateId));
//     if (selectedShippingTemplateId === templateId && shippingRateTemplates.length > 1) {
//       setSelectedShippingTemplateId(shippingRateTemplates[0].id);
//     }
//     setSnackbar({
//       open: true,
//       message: '送料テンプレートを削除しました',
//       severity: 'info',
//     });
//   };

//   // 送料設定を変更するハンドラー
//   const handleShippingRateChange = (index, field, value) => {
//     setShippingRateTemplates((prevTemplates) =>
//       prevTemplates.map((template) =>
//         template.id === selectedShippingTemplateId
//           ? {
//               ...template,
//               shippingRates: template.shippingRates.map((rate, i) =>
//                 i === index
//                   ? {
//                       ...rate,
//                       [field]: parseFloat(value),
//                       [field.endsWith('JPY') ? field.replace('JPY', 'USD') : field.replace('USD', 'JPY')]:
//                         field.endsWith('JPY')
//                           ? (parseFloat(value) / exchangeRate).toFixed(2)
//                           : Math.round(parseFloat(value) * exchangeRate),
//                     }
//                   : rate
//               ),
//             }
//           : template
//       )
//     );
//   };

//   // 送料設定を削除するハンドラー
//   const handleRemoveShippingRate = (index) => {
//     setShippingRateTemplates((prevTemplates) =>
//       prevTemplates.map((template) =>
//         template.id === selectedShippingTemplateId
//           ? {
//               ...template,
//               shippingRates: template.shippingRates.filter((_, i) => i !== index),
//             }
//           : template
//       )
//     );
//   };

//   // 新しい送料設定を追加するハンドラー
//   const handleAddShippingRate = () => {
//     setShippingRateTemplates((prevTemplates) =>
//       prevTemplates.map((template) =>
//         template.id === selectedShippingTemplateId
//           ? {
//               ...template,
//               shippingRates: [
//                 ...template.shippingRates,
//                 { minPriceJPY: 0, maxPriceJPY: 0, costJPY: 0, minPriceUSD: 0, maxPriceUSD: 0, costUSD: 0 },
//               ],
//             }
//           : template
//       )
//     );
//   };

//   // 手数料を変更するハンドラー
//   const handleFeeChange = (feeType, value) => {
//     setFees((prevFees) => ({
//       ...prevFees,
//       [feeType]: parseFloat(value),
//     }));
//   };

//   // ベストオファー自動承認価格の変更ハンドラー
//   const handleBestOfferAutoAcceptChange = (event) => {
//     setBestOfferAutoAcceptPercentage(parseFloat(event.target.value));
//   };

//   // 最小ベストオファー価格の変更ハンドラー
//   const handleMinimumBestOfferChange = (event) => {
//     setMinimumBestOfferPercentage(parseFloat(event.target.value));
//   };

//   // 価格シミュレーションダイアログを閉じる関数
//   const closePriceSimulation = () => {
//     setShowPriceSimulation(false);
//   };

//   // 通貨のフォーマット関数
//   const formatCurrency = (value, currency = 'USD') => {
//     if (value === undefined || value === null || isNaN(parseFloat(value))) {
//       return currency === 'USD' ? '$0.00' : '¥0';
//     }
//     if (currency === 'USD') {
//       return `$${parseFloat(value).toFixed(2)}`;
//     } else if (currency === 'JPY') {
//       return `¥${Math.round(parseFloat(value) * exchangeRate)}`;
//     }
//   };

//   return (
//     <Box sx={{ flexGrow: 1, p: 2 }}>
//       <Grid container spacing={2}>
//         {/* 左側のコンテンツ */}
//         <Grid item xs={12} md={6}>
//           <Typography variant="h4" gutterBottom>
//             価格管理
//             <Tooltip title="ヘルプを表示">
//               <IconButton onClick={() => setIsHelpDialogOpen(true)}>
//                 <HelpOutlineIcon />
//               </IconButton>
//             </Tooltip>
//           </Typography>

//           {/* 目標利益率設定 */}
//           <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
//             <Typography variant="h6" gutterBottom>
//               目標利益率: {targetProfitMargin}%
//             </Typography>
//             <Slider
//               value={targetProfitMargin}
//               onChange={handleProfitMarginChange}
//               aria-labelledby="profit-margin-slider"
//               valueLabelDisplay="auto"
//               step={1}
//               marks
//               min={0}
//               max={100}
//             />
//           </Paper>

//           {/* 為替レート設定 */}
//           <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
//             <Typography variant="h6" gutterBottom>
//               為替レート設定
//             </Typography>
//             <Typography>1 USD = {exchangeRate.toFixed(2)} JPY</Typography>
//             <TextField
//               label="為替レート (1 USD = X JPY)"
//               value={exchangeRate}
//               onChange={handleExchangeRateChange}
//               type="number"
//               inputProps={{ step: '0.01' }}
//               sx={{ width: 200, mt: 2 }}
//             />
//           </Paper>

//           {/* その他の設定ボタン */}
//           <Grid container spacing={2} sx={{ mb: 3 }}>
//             <Grid item xs={12} sm={4}>
//               <Button variant="outlined" color="primary" onClick={() => setIsShippingDialogOpen(true)} fullWidth>
//                 送料設定
//               </Button>
//             </Grid>
//             <Grid item xs={12} sm={4}>
//               <Button variant="outlined" color="primary" onClick={() => setIsFeeDialogOpen(true)} fullWidth>
//                 手数料設定
//               </Button>
//             </Grid>
//             <Grid item xs={12} sm={4}>
//               <Button variant="outlined" color="primary" onClick={() => setIsBestOfferDialogOpen(true)} fullWidth>
//                 ベストオファー設定
//               </Button>
//             </Grid>
//           </Grid>

//           {/* カテゴリー設定 */}
//           <DragDropContext onDragEnd={onDragEnd}>
//             <Grid container spacing={2}>
//               <Grid item xs={12} md={6}>
//                 {renderCategory('additionalCosts', '上乗せ金額')}
//               </Grid>
//               <Grid item xs={12} md={6}>
//                 {renderCategory('fees', '手数料')}
//               </Grid>
//             </Grid>
//           </DragDropContext>

//           {/* 設定保存とリセット */}
//           <Box sx={{ mt: 3, display: 'flex', justifyContent: 'space-between' }}>
//             <Button startIcon={<SaveIcon />} variant="contained" color="primary" onClick={handleSaveSettings}>
//               設定を保存
//             </Button>
//             <Button startIcon={<SettingsBackupRestoreIcon />} variant="outlined" onClick={handleResetSettings}>
//               デフォルトに戻す
//             </Button>
//           </Box>
//         </Grid>

//         {/* 右側のコンテンツ */}
//         <Grid item xs={12} md={6}>
//           <Box sx={{ mt: { xs: 4, md: 0 } }}>
//             <Typography variant="h5" gutterBottom>
//               価格計算と適用
//             </Typography>

//             {error && (
//               <Alert severity="error" sx={{ mb: 2 }}>
//                 {error}
//               </Alert>
//             )}

//             {/* 適用状態の表示 */}
//             {isPriceApplied ? (
//               <Alert
//                 severity="success"
//                 iconMapping={{
//                   success: <CheckCircleIcon fontSize="inherit" />,
//                 }}
//                 sx={{ mb: 2, fontWeight: 'bold', textAlign: 'center' }}
//               >
//                 適用完了
//               </Alert>
//             ) : (
//               <Alert
//                 severity="warning"
//                 iconMapping={{
//                   warning: <WarningAmberIcon fontSize="inherit" />,
//                 }}
//                 sx={{ mb: 2, fontWeight: 'bold', textAlign: 'center' }}
//               >
//                 未適用
//               </Alert>
//             )}

//             <Grid container spacing={2} sx={{ mb: 2 }}>
//               <Grid item xs={12} sm={6}>
//                 <Button
//                   variant="contained"
//                   color="primary"
//                   onClick={calculatePrices}
//                   disabled={isCalculating}
//                   fullWidth
//                 >
//                   {isCalculating ? '計算中...' : '価格を再計算'}
//                 </Button>
//               </Grid>
//             </Grid>

//             {calculatedPrices.length > 0 && (
//               <Grid container spacing={2} sx={{ mt: 2 }}>
//                 <Grid item xs={12} sm={4}>
//                   <Button
//                     variant="contained"
//                     color="secondary"
//                     onClick={() => setShowPriceSimulation(true)}
//                     fullWidth
//                   >
//                     価格シミュレーション結果
//                   </Button>
//                 </Grid>
//                 <Grid item xs={12} sm={4}>
//                   <Button
//                     variant="contained"
//                     color="secondary"
//                     onClick={handleApplyPrices}
//                     fullWidth
//                     disabled={isPriceApplied}
//                   >
//                     計算した価格を適用
//                   </Button>
//                 </Grid>
//                 <Grid item xs={12} sm={4}>
//                   <Button
//                     variant="outlined"
//                     color="primary"
//                     onClick={handleRestorePrices}
//                     fullWidth
//                     disabled={!isPriceApplied}
//                   >
//                     価格を元に戻す
//                   </Button>
//                 </Grid>
//               </Grid>
//             )}
//           </Box>
//         </Grid>
//       </Grid>

//       {/* ヘルプダイアログ */}
//       <Dialog open={isHelpDialogOpen} onClose={() => setIsHelpDialogOpen(false)} maxWidth="md" fullWidth>
//         <DialogTitle>価格計算の具体的な説明</DialogTitle>
//         <DialogContent>
//           {/* ここに価格計算の詳細な説明を表示 */}
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => setIsHelpDialogOpen(false)}>閉じる</Button>
//         </DialogActions>
//       </Dialog>

//       {/* リセット確認ダイアログ */}
//       <Dialog open={isResetDialogOpen} onClose={() => setIsResetDialogOpen(false)}>
//         <DialogTitle>設定をリセット</DialogTitle>
//         <DialogContent>
//           <Typography>本当に設定をデフォルトに戻しますか？この操作は元に戻せません。</Typography>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => setIsResetDialogOpen(false)}>キャンセル</Button>
//           <Button onClick={confirmResetSettings} color="error">
//             リセット
//           </Button>
//         </DialogActions>
//       </Dialog>

//       {/* 送料設定ダイアログ */}
//       <Dialog open={isShippingDialogOpen} onClose={() => setIsShippingDialogOpen(false)} maxWidth="lg" fullWidth>
//         <DialogTitle>送料設定</DialogTitle>
//         <DialogContent>
//           {/* 送料テンプレートの選択と管理 */}
//           <Box sx={{ mb: 2 }}>
//             <FormControl fullWidth>
//               <InputLabel>送料テンプレートを選択</InputLabel>
//               <Select
//                 value={selectedShippingTemplateId}
//                 onChange={(e) => setSelectedShippingTemplateId(e.target.value)}
//                 label="送料テンプレートを選択"
//               >
//                 {shippingRateTemplates.map((template) => (
//                   <MenuItem
//                     key={template.id}
//                     value={template.id}
//                     sx={{ display: 'flex', alignItems: 'center' }}
//                   >
//                     <Typography variant="body1" sx={{ flexGrow: 1 }}>
//                       {template.name}
//                     </Typography>
//                     <IconButton
//                       size="small"
//                       onClick={(e) => {
//                         e.stopPropagation();
//                         handleEditTemplateName(template.id);
//                       }}
//                     >
//                       <EditIcon fontSize="small" />
//                     </IconButton>
//                   </MenuItem>
//                 ))}
//               </Select>
//             </FormControl>
//             <Box sx={{ mt: 1, display: 'flex', justifyContent: 'space-between' }}>
//               <Button startIcon={<AddIcon />} onClick={handleAddShippingTemplate}>
//                 新しいテンプレートを追加
//               </Button>
//               <Button
//                 startIcon={<DeleteIcon />}
//                 onClick={() => handleRemoveShippingTemplate(selectedShippingTemplateId)}
//                 disabled={shippingRateTemplates.length <= 1}
//               >
//                 テンプレートを削除
//               </Button>
//             </Box>
//           </Box>

//           {/* 選択されたテンプレートの送料設定 */}
//           <TableContainer component={Paper}>
//             <Table>
//               <TableHead>
//                 <TableRow>
//                   <TableCell>最小仕入れ価格 (JPY)</TableCell>
//                   <TableCell>最大仕入れ価格 (JPY)</TableCell>
//                   <TableCell>送料 (JPY)</TableCell>
//                   <TableCell>最小仕入れ価格 (USD)</TableCell>
//                   <TableCell>最大仕入れ価格 (USD)</TableCell>
//                   <TableCell>送料 (USD)</TableCell>
//                   <TableCell>操作</TableCell>
//                 </TableRow>
//               </TableHead>
//               <TableBody>
//                 {shippingRateTemplates
//                   .find((template) => template.id === selectedShippingTemplateId)
//                   .shippingRates.map((rate, index) => (
//                     <TableRow key={index}>
//                       <TableCell>
//                         <TextField
//                           type="number"
//                           value={rate.minPriceJPY}
//                           onChange={(e) => handleShippingRateChange(index, 'minPriceJPY', e.target.value)}
//                           size="small"
//                           fullWidth
//                         />
//                       </TableCell>
//                       <TableCell>
//                         <TextField
//                           type="number"
//                           value={rate.maxPriceJPY === Infinity ? '' : rate.maxPriceJPY}
//                           onChange={(e) =>
//                             handleShippingRateChange(index, 'maxPriceJPY', e.target.value || Infinity)
//                           }
//                           size="small"
//                           fullWidth
//                         />
//                       </TableCell>
//                       <TableCell>
//                         <TextField
//                           type="number"
//                           value={rate.costJPY}
//                           onChange={(e) => handleShippingRateChange(index, 'costJPY', e.target.value)}
//                           size="small"
//                           fullWidth
//                         />
//                       </TableCell>
//                       <TableCell>
//                         <TextField
//                           type="number"
//                           value={rate.minPriceUSD}
//                           onChange={(e) => handleShippingRateChange(index, 'minPriceUSD', e.target.value)}
//                           size="small"
//                           fullWidth
//                         />
//                       </TableCell>
//                       <TableCell>
//                         <TextField
//                           type="number"
//                           value={rate.maxPriceUSD === Infinity ? '' : rate.maxPriceUSD}
//                           onChange={(e) =>
//                             handleShippingRateChange(index, 'maxPriceUSD', e.target.value || Infinity)
//                           }
//                           size="small"
//                           fullWidth
//                         />
//                       </TableCell>
//                       <TableCell>
//                         <TextField
//                           type="number"
//                           value={rate.costUSD}
//                           onChange={(e) => handleShippingRateChange(index, 'costUSD', e.target.value)}
//                           size="small"
//                           fullWidth
//                         />
//                       </TableCell>
//                       <TableCell>
//                         <IconButton onClick={() => handleRemoveShippingRate(index)}>
//                           <DeleteIcon />
//                         </IconButton>
//                       </TableCell>
//                     </TableRow>
//                   ))}
//               </TableBody>
//             </Table>
//           </TableContainer>
//           <Button startIcon={<AddIcon />} onClick={handleAddShippingRate} sx={{ mt: 2 }}>
//             送料設定を追加
//           </Button>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => setIsShippingDialogOpen(false)}>閉じる</Button>
//         </DialogActions>
//       </Dialog>

//       {/* テンプレート名編集ダイアログ */}
//       <Dialog
//         open={isEditTemplateNameDialogOpen}
//         onClose={() => setIsEditTemplateNameDialogOpen(false)}
//       >
//         <DialogTitle>テンプレート名の編集</DialogTitle>
//         <DialogContent>
//           <TextField
//             fullWidth
//             label="テンプレート名"
//             value={newTemplateName}
//             onChange={(e) => setNewTemplateName(e.target.value)}
//           />
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => setIsEditTemplateNameDialogOpen(false)}>キャンセル</Button>
//           <Button onClick={handleTemplateNameUpdate} color="primary">
//             更新
//           </Button>
//         </DialogActions>
//       </Dialog>

//       {/* 手数料設定ダイアログ */}
//       <Dialog open={isFeeDialogOpen} onClose={() => setIsFeeDialogOpen(false)} maxWidth="sm" fullWidth>
//         <DialogTitle>手数料設定</DialogTitle>
//         <DialogContent>
//           <List>
//             <ListItem>
//               <ListItemText primary="eBay最終価値手数料 (%)" />
//               <ListItemSecondaryAction>
//                 <TextField
//                   type="number"
//                   value={fees.ebayFinalValue}
//                   onChange={(e) => handleFeeChange('ebayFinalValue', e.target.value)}
//                   size="small"
//                 />
//               </ListItemSecondaryAction>
//             </ListItem>
//             <ListItem>
//               <ListItemText primary="Payoneer手数料 (%)" />
//               <ListItemSecondaryAction>
//                 <TextField
//                   type="number"
//                   value={fees.payoneer}
//                   onChange={(e) => handleFeeChange('payoneer', e.target.value)}
//                   size="small"
//                 />
//               </ListItemSecondaryAction>
//             </ListItem>
//             <ListItem>
//               <ListItemText primary="広告出品手数料 (%)" />
//               <ListItemSecondaryAction>
//                 <TextField
//                   type="number"
//                   value={fees.promotedListing}
//                   onChange={(e) => handleFeeChange('promotedListing', e.target.value)}
//                   size="small"
//                 />
//               </ListItemSecondaryAction>
//             </ListItem>
//             <ListItem>
//               <ListItemText primary="eBay取引手数料 ($)" />
//               <ListItemSecondaryAction>
//                 <TextField
//                   type="number"
//                   value={fees.ebayTransactionFee}
//                   onChange={(e) => handleFeeChange('ebayTransactionFee', e.target.value)}
//                   size="small"
//                 />
//               </ListItemSecondaryAction>
//             </ListItem>
//           </List>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => setIsFeeDialogOpen(false)}>閉じる</Button>
//         </DialogActions>
//       </Dialog>

//       {/* ベストオファー設定ダイアログ */}
//       <Dialog
//         open={isBestOfferDialogOpen}
//         onClose={() => setIsBestOfferDialogOpen(false)}
//         maxWidth="sm"
//         fullWidth
//       >
//         <DialogTitle>ベストオファー設定</DialogTitle>
//         <DialogContent>
//           <Grid container spacing={2}>
//             <Grid item xs={12}>
//               <TextField
//                 fullWidth
//                 label="ベストオファー自動承認価格 (%)"
//                 type="number"
//                 value={bestOfferAutoAcceptPercentage}
//                 onChange={handleBestOfferAutoAcceptChange}
//                 InputProps={{ inputProps: { min: 0, max: 100 } }}
//               />
//             </Grid>
//             <Grid item xs={12}>
//               <TextField
//                 fullWidth
//                 label="最小ベストオファー価格 (%)"
//                 type="number"
//                 value={minimumBestOfferPercentage}
//                 onChange={handleMinimumBestOfferChange}
//                 InputProps={{ inputProps: { min: 0, max: 100 } }}
//               />
//             </Grid>
//           </Grid>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => setIsBestOfferDialogOpen(false)}>閉じる</Button>
//         </DialogActions>
//       </Dialog>

//       {/* 価格シミュレーション結果ダイアログ */}
//       <Dialog open={showPriceSimulation} onClose={closePriceSimulation} maxWidth="lg" fullWidth>
//         <DialogTitle>価格シミュレーション結果</DialogTitle>
//         <DialogContent sx={{ height: '90vh' }}>
//           {/* 価格シミュレーション結果を表示 */}
//           {calculatedPrices.length > 0 && (
//             <TableContainer component={Paper} sx={{ maxHeight: '100%', overflow: 'auto' }}>
//               <Table stickyHeader>
//                 <TableHead>
//                   <TableRow>
//                     <TableCell>元の価格 (USD)</TableCell>
//                     <TableCell>計算後の価格 (USD)</TableCell>
//                     <TableCell>ベストオファー自動承認価格 (USD)</TableCell>
//                     <TableCell>最小ベストオファー価格 (USD)</TableCell>
//                     <TableCell>利益 (USD)</TableCell>
//                     <TableCell>利益率 (%)</TableCell>
//                   </TableRow>
//                 </TableHead>
//                 <TableBody>
//                   {calculatedPrices.map((price, index) => (
//                     <TableRow key={index}>
//                       <TableCell>{formatCurrency(price.originalStartPrice)}</TableCell>
//                       <TableCell>{formatCurrency(price.calculatedPrice)}</TableCell>
//                       <TableCell>{formatCurrency(price.bestOfferAutoAcceptPrice)}</TableCell>
//                       <TableCell>{formatCurrency(price.minimumBestOfferPrice)}</TableCell>
//                       <TableCell>{formatCurrency(price.profit)}</TableCell>
//                       <TableCell>{price.profitMargin}%</TableCell>
//                     </TableRow>
//                   ))}
//                 </TableBody>
//               </Table>
//             </TableContainer>
//           )}
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={closePriceSimulation}>閉じる</Button>
//         </DialogActions>
//       </Dialog>

//       {/* Snackbar */}
//       <Snackbar
//         open={snackbar.open}
//         autoHideDuration={6000}
//         onClose={() => setSnackbar({ ...snackbar, open: false })}
//       >
//         <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity} sx={{ width: '100%' }}>
//           {snackbar.message}
//         </Alert>
//       </Snackbar>
//     </Box>
//   );
// };

// export default PriceManager;