// NewLocalImageUploader.js

import React, { useState } from 'react';
import { Box, Button, Typography, TextField, CircularProgress, Alert, Dialog, DialogTitle, DialogContent, DialogActions, LinearProgress } from '@mui/material';
import { getStorage, ref as storageRef, uploadBytes } from 'firebase/storage';
import { v4 as uuidv4 } from 'uuid';
import imageCompression from 'browser-image-compression';

function NewLocalImageUploader({ user }) {
  const [localFiles, setLocalFiles] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadedUrls, setUploadedUrls] = useState([]);
  const [error, setError] = useState(null);

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const bucketName = "ebayprice-405908.appspot.com";

  // アップロード進捗：完了したファイル数
  const [uploadCount, setUploadCount] = useState(0);

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    setLocalFiles(files);
    setError(null);
    setUploadedUrls([]);
    setUploadCount(0);
  };

  const handleUpload = async () => {
    if (!user) {
      setError('ユーザーが認証されていません。ログインしてください。');
      return;
    }
    if (localFiles.length === 0) {
      setError('ファイルが選択されていません。');
      return;
    }

    setIsUploading(true);
    setError(null);
    setUploadedUrls([]);
    setUploadCount(0);

    try {
      const storage = getStorage();
      const newUrls = [];

      // 画像圧縮設定例
      const compressionOptions = {
        maxSizeMB: 0.5,          // 最大0.5MBに圧縮
        maxWidthOrHeight: 1600,  // 画像の長辺を1600pxに縮小
        useWebWorker: true,
      };

      // 全ファイルを非同期で処理（圧縮→アップロード）するPromise配列
      const uploadPromises = localFiles.map(async (file) => {
        // ファイル圧縮
        const compressedFile = await imageCompression(file, compressionOptions);

        // ファイル名生成
        const originalName = file.name; 
        const extension = originalName.split('.').pop();
        const baseName = originalName.substring(0, 20);
        const shortUuid = uuidv4().split('-')[0];
        const newFileName = `${baseName}-${shortUuid}.${extension}`;

        const fileRef = storageRef(storage, `users/${user.uid}/images/${newFileName}`);
        
        // アップロード
        await uploadBytes(fileRef, compressedFile);
        
        const fileUrl = `https://storage.googleapis.com/${bucketName}/users/${encodeURIComponent(user.uid)}/images/${encodeURIComponent(newFileName)}`;
        // 1枚アップロード完了ごとにカウントを更新
        setUploadCount((prev) => prev + 1);

        return fileUrl;
      });

      // 全ファイルのアップロード完了を待つ
      const results = await Promise.all(uploadPromises);
      newUrls.push(...results);

      setUploadedUrls(newUrls);
      setIsDialogOpen(true);
    } catch (err) {
      console.error(err);
      setError('アップロード中にエラーが発生しました。');
    } finally {
      setIsUploading(false);
    }
  };

  const handleCopyAll = () => {
    if (uploadedUrls.length > 0) {
      const textToCopy = uploadedUrls.join('\n');
      navigator.clipboard.writeText(textToCopy).then(
        () => {
          alert('URLをクリップボードにコピーしました!');
        },
        (err) => {
          console.error('クリップボードへのコピーに失敗しました:', err);
        }
      );
    }
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  const totalFiles = localFiles.length;
  const progressValue = totalFiles > 0 ? (uploadCount / totalFiles) * 100 : 0;

  return (
    <Box sx={{ p: 2, border: '1px solid #ccc', borderRadius: '4px' }}>
      <Typography variant="h6" gutterBottom>ローカル画像アップロード</Typography>
      <Typography variant="body2" sx={{ mb: 2 }}>
        複数のローカル画像を圧縮後、アップロードします。
        アップロード後にURLが表示されるので、それをコピーしてください。
      </Typography>
      <TextField
        type="file"
        inputProps={{ multiple: true }}
        onChange={handleFileChange}
        helperText="複数ファイルを選択できます"
      />
      <Button
        variant="contained"
        onClick={handleUpload}
        disabled={isUploading || localFiles.length === 0}
        sx={{ ml: 2 }}
      >
        {isUploading ? <CircularProgress size={24} /> : 'アップロード開始'}
      </Button>

      {error && <Alert severity="error" sx={{ mt: 2 }}>{error}</Alert>}

      {isUploading && totalFiles > 0 && (
        <Box sx={{ mt: 2 }}>
          <Typography variant="body2" sx={{ mb: 1 }}>
            アップロード中: {uploadCount} / {totalFiles}
          </Typography>
          <LinearProgress variant="determinate" value={progressValue} />
        </Box>
      )}

      {/* URL一覧コピー用ダイアログ */}
      <Dialog open={isDialogOpen} onClose={handleDialogClose} fullWidth maxWidth="sm">
        <DialogTitle>アップロード完了</DialogTitle>
        <DialogContent>
          <Typography variant="body2" sx={{ mb: 2 }}>
            以下がアップロードされた画像のURLです。
            「URLをすべてコピー」ボタンでクリップボードへコピーできます。
          </Typography>
          {uploadedUrls.map((url, index) => (
            <Typography key={index} variant="body2" sx={{ mb: 1, wordBreak: 'break-all' }}>
              {url}
            </Typography>
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCopyAll} color="primary">
            URLをすべてコピー
          </Button>
          <Button onClick={handleDialogClose} color="secondary">
            閉じる
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default NewLocalImageUploader;





// // NewLocalImageUploader.js

// import React, { useState } from 'react';
// import { Box, Button, Typography, TextField, CircularProgress, Alert, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
// import { getStorage, ref as storageRef, uploadBytes } from 'firebase/storage';
// import { v4 as uuidv4 } from 'uuid';

// function NewLocalImageUploader({ user }) {
//   const [localFiles, setLocalFiles] = useState([]);
//   const [isUploading, setIsUploading] = useState(false);
//   const [uploadedUrls, setUploadedUrls] = useState([]);
//   const [error, setError] = useState(null);

//   // ダイアログ開閉管理
//   const [isDialogOpen, setIsDialogOpen] = useState(false);

//   // 使用するFirebase Storageのバケット名を指定
//   const bucketName = "ebayprice-405908.appspot.com";

//   const handleFileChange = (e) => {
//     const files = Array.from(e.target.files);
//     setLocalFiles(files);
//     setError(null);
//     setUploadedUrls([]);
//   };

//   const handleUpload = async () => {
//     if (!user) {
//       setError('ユーザーが認証されていません。ログインしてください。');
//       return;
//     }
//     if (localFiles.length === 0) {
//       setError('ファイルが選択されていません。');
//       return;
//     }

//     setIsUploading(true);
//     setError(null);
//     setUploadedUrls([]);

//     try {
//       const storage = getStorage();
//       const newUrls = [];

//       for (const file of localFiles) {
//         // 元ファイル名から拡張子と先頭10文字＋短縮UUIDで新ファイル名を生成
//         const originalName = file.name; 
//         const extension = originalName.split('.').pop();
//         const baseName = originalName.substring(0, 20); // 先頭10文字
//         const shortUuid = uuidv4().split('-')[0]; // UUIDの先頭ブロックのみ使用

//         const newFileName = `${baseName}-${shortUuid}.${extension}`;
//         const fileRef = storageRef(storage, `users/${user.uid}/images/${newFileName}`);

//         // Firebase Storageへアップロード
//         await uploadBytes(fileRef, file);
        
//         // パブリックアクセス前提で公開URLを生成
//         const fileUrl = `https://storage.googleapis.com/${bucketName}/users/${encodeURIComponent(user.uid)}/images/${encodeURIComponent(newFileName)}`;
//         newUrls.push(fileUrl);
//       }

//       setUploadedUrls(newUrls);
//       setIsDialogOpen(true); // アップロード完了後ダイアログオープン
//     } catch (err) {
//       console.error(err);
//       setError('アップロード中にエラーが発生しました。');
//     } finally {
//       setIsUploading(false);
//     }
//   };

//   const handleCopyAll = () => {
//     if (uploadedUrls.length > 0) {
//       const textToCopy = uploadedUrls.join('\n');
//       navigator.clipboard.writeText(textToCopy).then(
//         () => {
//           alert('URLをクリップボードにコピーしました!');
//         },
//         (err) => {
//           console.error('クリップボードへのコピーに失敗しました:', err);
//         }
//       );
//     }
//   };

//   const handleDialogClose = () => {
//     setIsDialogOpen(false);
//   };

//   return (
//     <Box sx={{ p: 2, border: '1px solid #ccc', borderRadius: '4px' }}>
//       <Typography variant="h6" gutterBottom>新機能: ローカル画像アップロード</Typography>
//       <Typography variant="body2" sx={{ mb: 2 }}>
//         複数のローカル画像を選択してFirebase Storageにアップロードし、そのURLを表示します。
//         パブリックアクセスを許容した場合、?alt=media&token=...なしの短いURLで直接アクセス可能です。
//       </Typography>
//       <TextField
//         type="file"
//         inputProps={{ multiple: true }}
//         onChange={handleFileChange}
//         helperText="複数ファイルを選択できます"
//       />
//       <Button
//         variant="contained"
//         onClick={handleUpload}
//         disabled={isUploading || localFiles.length === 0}
//         sx={{ ml: 2 }}
//       >
//         {isUploading ? <CircularProgress size={24} /> : 'アップロード開始'}
//       </Button>
//       {error && <Alert severity="error" sx={{ mt: 2 }}>{error}</Alert>}

//       {/* URL一覧コピー用ダイアログ */}
//       <Dialog open={isDialogOpen} onClose={handleDialogClose} fullWidth maxWidth="sm">
//         <DialogTitle>アップロード完了</DialogTitle>
//         <DialogContent>
//           <Typography variant="body2" sx={{ mb: 2 }}>
//             以下がアップロードされた画像のURLです。
//             「URLをすべてコピー」ボタンでクリップボードへコピーできます。
//           </Typography>
//           {uploadedUrls.map((url, index) => (
//             <Typography key={index} variant="body2" sx={{ mb: 1, wordBreak: 'break-all' }}>
//               {url}
//             </Typography>
//           ))}
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={handleCopyAll} color="primary">
//             URLをすべてコピー
//           </Button>
//           <Button onClick={handleDialogClose} color="secondary">
//             閉じる
//           </Button>
//         </DialogActions>
//       </Dialog>
//     </Box>
//   );
// }

// export default NewLocalImageUploader;



