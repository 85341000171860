// applyAIProductDescriptions.js

import axios from 'axios';
import PQueue from 'p-queue';
import designTemplates from './designTemplates';
import { applyFilters } from './filters'; // フィルター適用関数

// キャッシュ機能
const descriptionCache = {};

const getCachedDescription = (key) => descriptionCache[key];
const setCachedDescription = (key, value) => {
  descriptionCache[key] = value;
};

// テキスト変換を適用する関数
function applyCustomTransformations(text, sentencesToDelete, wordsToReplace) {
  let transformedText = text;

  // 文章の削除
  sentencesToDelete.forEach((sentence) => {
    if (sentence.trim() !== '') {
      const escapedSentence = sentence.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
      const regex = new RegExp(escapedSentence, 'g');
      transformedText = transformedText.replace(regex, '');
    }
  });

  // 単語の置換
  wordsToReplace.forEach(({ from, to }) => {
    if (from.trim() !== '') {
      const escapedFrom = from.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
      const regex = new RegExp(escapedFrom, 'g');
      transformedText = transformedText.replace(regex, to);
    }
  });

  return transformedText;
}

// HTMLをクリーンアップする関数
const cleanHTML = (html) => {
  let cleanedHtml = html.replace(/<style[^>]*>[\s\S]*?<\/style>/gi, '');
  cleanedHtml = cleanedHtml.replace(/\sclass="[^"]*"/gi, '');
  cleanedHtml = cleanedHtml.replace(/\s+/g, ' ').trim();
  cleanedHtml = cleanedHtml.replace(/>\s+</g, '><');
  return cleanedHtml;
};

// フォーマットされた商品説明を生成する関数
const generateFormattedDescription = (item, selectedTemplate) => {
  let template = designTemplates[selectedTemplate];

  if (!template || typeof template.generateHTML !== 'function') {
    console.error(
      `デザインテンプレート "${selectedTemplate}" が見つからないか、generateHTMLメソッドが定義されていません。デフォルトテンプレートを使用します。`
    );
    // デフォルトテンプレートを使用
    template = designTemplates['simple'];
    if (!template || typeof template.generateHTML !== 'function') {
      throw new Error('有効なデフォルトテンプレートが見つかりません。');
    }
  }

  const title = item.Title || '';
  const features = item.Features || '';
  const description = item.Description || '';
  const specifications = item.Specifications || {};

  const html = template.generateHTML(title, features, description, specifications);
  const cleanedHtml = cleanHTML(html);
  return cleanedHtml;
};

// メインのAI商品説明適用関数
export const applyAIProductDescriptions = async (
  currentData,
  apiKey,
  progressCallback,
  selectedDesignTemplate,
  userSettings
) => {
  if (!apiKey) {
    throw new Error('OpenAI APIキーが設定されていません');
  }

  // p-queueの設定
  const CONCURRENCY = 2;
  const INTERVAL = 1000;
  const INTERVAL_CAP = 5;
  const queue = new PQueue({
    concurrency: CONCURRENCY,
    interval: INTERVAL,
    intervalCap: INTERVAL_CAP,
  });

  let totalItems = 0;
  let completedItems = 0;
  const errorItems = [];

  // 全ファイルに対して処理
  const updatedData = await Promise.all(
    currentData.map(async (file) => {
      // CSVデータをパース
      const Papa = require('papaparse');
      const parsedResult = Papa.parse(file.content, { header: true });
      const data = parsedResult.data;

      totalItems += data.length;

      // 商品説明生成タスクの配列
      const generateDescriptionTasks = data.map((item, index) => async () => {
        const originalTitle = item.Title || '';
        const jpDesc = item.jp_desc || item.JP_Description || '';

        // ユーザー設定に基づいてjpDescをフィルタリング
        const filteredJpDesc = applyFilters(
          jpDesc,
          userSettings.selectedFilters || [],
          userSettings.customFilters || []
        );

        // 文章の削除や単語の置換を適用
        const transformedJpDesc = applyCustomTransformations(
          filteredJpDesc,
          userSettings.sentencesToDelete || [],
          userSettings.wordsToReplace || []
        );

        // キャッシュキーの生成
        const cacheKey =
          originalTitle + transformedJpDesc.slice(0, 150) + selectedDesignTemplate;
        const cachedDescription = getCachedDescription(cacheKey);
        if (cachedDescription) {
          item.Description = cachedDescription;
          completedItems++;
          if (progressCallback) {
            progressCallback({ totalItems, completedItems });
          }
          return;
        }

        try {
          await generateSingleDescription(
            item,
            transformedJpDesc,
            apiKey,
            selectedDesignTemplate,
            userSettings
          );
          setCachedDescription(cacheKey, item.Description);
        } catch (error) {
          console.error(`商品説明生成エラー（行 ${index + 1}）:`, error);
          // エラー情報を収集
          errorItems.push({
            fileName: file.name,
            rowIndex: index + 1,
            error: error.message || error,
          });
        } finally {
          completedItems++;
          if (progressCallback) {
            progressCallback({ totalItems, completedItems });
          }
        }
      });

      // タスクをキューに追加して実行
      await queue.addAll(generateDescriptionTasks);

      // CSVに変換
      const csvContent = Papa.unparse(data);

      return {
        ...file,
        content: csvContent,
      };
    })
  );

  return { updatedData, errorItems };
};

// 単一の商品説明を生成する関数
async function generateSingleDescription(
  item,
  transformedJpDesc,
  apiKey,
  selectedDesignTemplate,
  userSettings
) {
  const originalTitle = item.Title || '';

  // 必須キーワードのプロンプトを生成
  const mandatoryKeywordsPrompt =
    userSettings.mandatoryKeywords && userSettings.mandatoryKeywords.length > 0
      ? `Also, be sure to include the following keywords: ${userSettings.mandatoryKeywords.join(
          ', '
        )}.`
      : '';

  // OpenAI APIリクエストデータの作成
  // 修正後のコード

const requestData = {
  model: 'gpt-4o-mini',
  messages: [
    {
      role: 'system',
      content: `You are an AI assistant specializing in eBay product description optimization. Based on the given original title and Japanese product information, create an effective English product description within 500 tokens.

${mandatoryKeywordsPrompt}

You are tasked with generating a product description based on the provided product information. Your goal is to create a clear, concise, and accurate description in English, structured in HTML format.

Here is the product information:
<product_info>
{{PRODUCT_INFO}}
</product_info>

Please follow these instructions to generate the product description:

1. Analyze the provided product information carefully.

2. Generate the product description inside a single div with the id "ai-generated-content".

3. Use the following HTML structure, including only the fields for which information is available:

<example_structure>
<div id="ai-generated-content">
  <h1>[Product Title]</h1>
  <ul>
    <li><strong>Brand Name:</strong> [Brand Name]</li>
    <li><strong>Product Type:</strong> [Product Type]</li>
    <li><strong>Model:</strong> [Model or Product Name]</li>
    <li><strong>Color:</strong> [Color]</li>
    <li><strong>Material:</strong> [Material]</li>
    <li><strong>Size:</strong> [Size]</li>
    <li><strong>Condition:</strong> [Condition of the Item]</li>
    <li><strong>Other Features:</strong> [Any additional features]</li>
  </ul>
  <p>[Additional description if necessary]</p>
</div>
</example_structure>

4. Adapt the field names as necessary based on the specific product category. For example, "Size" might become "Dimensions" for furniture or "Capacity" for appliances.

5. Use clear and concise English for all text content.

6. If any information is unclear or ambiguous, omit that item from the description.

7. Only include reliable information from the provided product details. Do not include any speculative or uncertain information.

8. If the product information is entirely in Japanese and contains no descriptive content, output only the title (if available) or nothing at all.

9. Keep the total length of the English description under 500 tokens.

10. Do not include any content outside of the specified HTML structure.

11. Ensure all HTML tags are properly opened and closed.

Remember to focus on accuracy and clarity in your description, prioritizing the most important and distinctive features of the product.`,
    },
    {
      role: 'user',
      content: `Original Title: "${originalTitle}"
Japanese Product Description: "${transformedJpDesc.slice(0, 500)}"
Based on this information, please generate an optimized new eBay product description.`,
    },
  ],
  max_tokens: 500,
  temperature: 0.1,
};

const response = await axios.post('https://api.openai.com/v1/chat/completions', requestData, {
  headers: { Authorization: `Bearer ${apiKey}` },
});

  

  const generatedContent = response.data.choices[0].message.content.trim();

  // AIが生成した説明文をitemに保存
  item.Description = generatedContent;

  // フォーマットされた説明を生成
  const formattedDescription = generateFormattedDescription(item, selectedDesignTemplate);

  // itemのDescriptionを更新
  item.Description = formattedDescription;

  return item;
}





// // applyAIProductDescriptions.js

// import axios from 'axios';
// import PQueue from 'p-queue';
// import designTemplates from './designTemplates';
// import { applyFilters } from './filters'; // フィルター適用関数

// // キャッシュ機能
// const descriptionCache = {};

// const getCachedDescription = (key) => descriptionCache[key];
// const setCachedDescription = (key, value) => {
//   descriptionCache[key] = value;
// };

// // テキスト変換を適用する関数
// function applyCustomTransformations(text, sentencesToDelete, wordsToReplace) {
//   let transformedText = text;

//   // 文章の削除
//   sentencesToDelete.forEach((sentence) => {
//     if (sentence.trim() !== '') {
//       const escapedSentence = sentence.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
//       const regex = new RegExp(escapedSentence, 'g');
//       transformedText = transformedText.replace(regex, '');
//     }
//   });

//   // 単語の置換
//   wordsToReplace.forEach(({ from, to }) => {
//     if (from.trim() !== '') {
//       const escapedFrom = from.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
//       const regex = new RegExp(escapedFrom, 'g');
//       transformedText = transformedText.replace(regex, to);
//     }
//   });

//   return transformedText;
// }

// // HTMLをクリーンアップする関数
// const cleanHTML = (html) => {
//   let cleanedHtml = html.replace(/<style[^>]*>[\s\S]*?<\/style>/gi, '');
//   cleanedHtml = cleanedHtml.replace(/\sclass="[^"]*"/gi, '');
//   cleanedHtml = cleanedHtml.replace(/\s+/g, ' ').trim();
//   cleanedHtml = cleanedHtml.replace(/>\s+</g, '><');
//   return cleanedHtml;
// };

// // フォーマットされた商品説明を生成する関数
// const generateFormattedDescription = (
//   title,
//   features,
//   description,
//   specifications,
//   selectedTemplate
// ) => {
//   const template = designTemplates[selectedTemplate];
//   const html = template.generateHTML(title, features, description, specifications);
//   const cleanedHtml = cleanHTML(html);
//   return cleanedHtml;
// };

// // メインのAI商品説明適用関数
// export const applyAIProductDescriptions = async (
//   currentData,
//   apiKey,
//   progressCallback,
//   selectedDesignTemplate,
//   userSettings
// ) => {
//   if (!apiKey) {
//     throw new Error('OpenAI APIキーが設定されていません');
//   }

//   // p-queueの設定
//   const CONCURRENCY = 2;
//   const INTERVAL = 1000;
//   const INTERVAL_CAP = 5;
//   const queue = new PQueue({
//     concurrency: CONCURRENCY,
//     interval: INTERVAL,
//     intervalCap: INTERVAL_CAP,
//   });

//   let totalItems = 0;
//   let completedItems = 0;
//   const errorItems = [];

//   // 全ファイルに対して処理
//   const updatedData = await Promise.all(
//     currentData.map(async (file) => {
//       // CSVデータをパース
//       const Papa = require('papaparse');
//       const parsedResult = Papa.parse(file.content, { header: true });
//       const data = parsedResult.data;

//       totalItems += data.length;

//       // 商品説明生成タスクの配列
//       const generateDescriptionTasks = data.map((item, index) => async () => {
//         const originalTitle = item.Title || '';
//         const jpDesc = item.jp_desc || item.JP_Description || '';

//         // ユーザー設定に基づいてjpDescをフィルタリング
//         const filteredJpDesc = applyFilters(
//           jpDesc,
//           userSettings.selectedFilters || [],
//           userSettings.customFilters || []
//         );

//         // 文章の削除や単語の置換を適用
//         const transformedJpDesc = applyCustomTransformations(
//           filteredJpDesc,
//           userSettings.sentencesToDelete || [],
//           userSettings.wordsToReplace || []
//         );

//         // キャッシュキーの生成
//         const cacheKey = originalTitle + transformedJpDesc.slice(0, 150) + selectedDesignTemplate;
//         const cachedDescription = getCachedDescription(cacheKey);
//         if (cachedDescription) {
//           item.Description = cachedDescription;
//           completedItems++;
//           if (progressCallback) {
//             progressCallback({ totalItems, completedItems });
//           }
//           return;
//         }

//         try {
//           const generatedDescription = await generateSingleDescription(
//             originalTitle,
//             transformedJpDesc,
//             apiKey,
//             selectedDesignTemplate,
//             userSettings
//           );
//           item.Description = generatedDescription;
//           setCachedDescription(cacheKey, generatedDescription);
//         } catch (error) {
//           console.error(`商品説明生成エラー（行 ${index + 1}）:`, error);
//           // エラー情報を収集
//           errorItems.push({
//             fileName: file.name,
//             rowIndex: index + 1,
//             error: error.message || error,
//           });
//         } finally {
//           completedItems++;
//           if (progressCallback) {
//             progressCallback({ totalItems, completedItems });
//           }
//         }
//       });

//       // タスクをキューに追加して実行
//       await queue.addAll(generateDescriptionTasks);

//       // CSVに変換
//       const csvContent = Papa.unparse(data);

//       return {
//         ...file,
//         content: csvContent,
//       };
//     })
//   );

//   return { updatedData, errorItems };
// };

// // 単一の商品説明を生成する関数
// async function generateSingleDescription(
//   originalTitle,
//   jpDesc,
//   apiKey,
//   selectedDesignTemplate,
//   userSettings
// ) {
//   const url = 'https://api.openai.com/v1/chat/completions';
//   const limitedJpDesc = jpDesc.slice(0, 500); // 最大500文字までに制限

//   // 必須キーワードのプロンプトを生成
//   const mandatoryKeywordsPrompt =
//     userSettings.mandatoryKeywords && userSettings.mandatoryKeywords.length > 0
//       ? `Also, be sure to include the following keywords: ${userSettings.mandatoryKeywords.join(
//           ', '
//         )}.`
//       : '';

//   // OpenAI APIリクエストデータの作成
//   const requestData = {
//     model: 'gpt-4o-mini',
//     messages: [
//       {
//         role: 'system',
//         content: `You are an AI assistant specializing in eBay product description optimization. Based on the given original title and Japanese product information, create an effective English product description within 500 tokens.
// ${mandatoryKeywordsPrompt}
// Order of description composition:
// 1. **Product Description** (if applicable)
//    - Brand name (if applicable)
//    - Product type
//    - Model or product name
// 2. **Detailed Features** (if applicable)
//    - Color, material, size
// 3. **Condition of the Item** (if applicable)
//    - Details of usage, scratches, stains, etc. (if used)
// NOTES:
// - No content other than the above will be output.
// - Additional information is not required.
// - Do not output information related to shipping or options.
// - Use clear and concise English.
// - Use bullet points to ensure readability.
// - If information is unclear or ambiguous, omit the item.
// - Use only reliable information and do not include speculative or uncertain information.
// - If there is nothing in the Japanese product description, please do not output anything in particular. Only the title is acceptable.
// - The description should be generated in English and should not exceed 500 tokens.`,
//       },
//       {
//         role: 'user',
//         content: `元のタイトル：「${originalTitle}」
// 日本語の商品説明：「${limitedJpDesc}」
// この情報を基に、最適化された新しいeBayの商品説明を生成してください。`,
//       },
//     ],
//     max_tokens: 500,
//     temperature: 0.5,
//   };

//   const response = await axios.post(url, requestData, {
//     headers: { Authorization: `Bearer ${apiKey}` },
//   });

//   const generatedContent = response.data.choices[0].message.content.trim();

//   // フォーマットされた説明を生成
//   const formattedDescription = generateFormattedDescription(
//     originalTitle,
//     generatedContent,
//     '', // 追加の説明があればここに
//     {}, // Specificationsがあればここに
//     selectedDesignTemplate
//   );

//   return formattedDescription;
// }





// // applyAIProductDescriptions.js

// import axios from 'axios';
// import PQueue from 'p-queue';

// // キャッシュ機能
// const descriptionCache = {};

// const getCachedDescription = (key) => descriptionCache[key];
// const setCachedDescription = (key, value) => {
//   descriptionCache[key] = value;
// };

// // 商品説明生成のための関数
// export const applyAIProductDescriptions = async (currentData, apiKey, progressCallback) => {
//   if (!apiKey) {
//     throw new Error('OpenAI APIキーが設定されていません');
//   }

//   // p-queueの設定
//   const CONCURRENCY = 2;
//   const INTERVAL = 1000;
//   const INTERVAL_CAP = 5;
//   const queue = new PQueue({
//     concurrency: CONCURRENCY,
//     interval: INTERVAL,
//     intervalCap: INTERVAL_CAP,
//   });

//   let totalItems = 0;
//   let completedItems = 0;
//   const errorItems = [];

//   // 全ファイルに対して処理
//   const updatedData = await Promise.all(
//     currentData.map(async (file) => {
//       // CSVデータをパース
//       const Papa = require('papaparse');
//       const parsedResult = Papa.parse(file.content, { header: true });
//       const data = parsedResult.data;

//       totalItems += data.length;

//       // 商品説明生成タスクの配列
//       const generateDescriptionTasks = data.map((item, index) => async () => {
//         const originalTitle = item.Title || '';
//         const jpDesc = item.jp_desc || item.JP_Description || '';

//         // キャッシュキーの生成
//         const cacheKey = originalTitle + jpDesc.slice(0, 150);
//         const cachedDescription = getCachedDescription(cacheKey);
//         if (cachedDescription) {
//           item.Description = cachedDescription;
//           completedItems++;
//           if (progressCallback) {
//             progressCallback({ totalItems, completedItems });
//           }
//           return;
//         }

//         try {
//           const generatedDescription = await generateSingleDescription(
//             originalTitle,
//             jpDesc,
//             apiKey
//           );
//           item.Description = generatedDescription;
//           setCachedDescription(cacheKey, generatedDescription);
//         } catch (error) {
//           console.error(`商品説明生成エラー（行 ${index + 1}）:`, error);
//           // エラー時は元の説明をそのまま使用
//           errorItems.push({
//             fileName: file.name,
//             rowIndex: index + 1,
//             error: error.message || error,
//           });
//         } finally {
//           completedItems++;
//           if (progressCallback) {
//             progressCallback({ totalItems, completedItems });
//           }
//         }
//       });

//       // タスクをキューに追加して実行
//       await queue.addAll(generateDescriptionTasks);

//       // CSVに変換
//       const csvContent = Papa.unparse(data);

//       return {
//         ...file,
//         content: csvContent,
//       };
//     })
//   );

//   return { updatedData, errorItems };
// };

// // 単一の商品説明を生成する関数
// async function generateSingleDescription(originalTitle, jpDesc, apiKey) {
//   const url = 'https://api.openai.com/v1/chat/completions';
//   const limitedJpDesc = jpDesc.slice(0, 500); // 最大500文字までに制限
//   const requestData = {
//     model: 'gpt-4o-mini',
//     messages: [
//       {
//         role: 'system',
//         content: `You are an AI assistant specializing in eBay product description optimization. Based on the given original title and Japanese product information, create an effective English product description within 500 tokens.
// Order of description composition:
// 1. **Product Description** (if applicable)
//    - Brand name (if applicable)
//    - Product type
//    - Model or product name
// 2. **Detailed Features** (if applicable)
//    - Color, material, size
// 3. **Condition of the Item** (if applicable)
//    - Details of usage, scratches, stains, etc. (if used)
// NOTES:
// - No content other than the above will be output.
// - Additional information is not required.
// - Do not output information related to shipping or options.
// - Use clear and concise English.
// - Use bullet points to ensure readability.
// - If information is unclear or ambiguous, omit the item.
// - Use only reliable information and do not include speculative or uncertain information.
// - If there is nothing in the Japanese product description, please do not output anything in particular. Only the title is acceptable.
// - The description should be generated in English and should not exceed 500 tokens.`,
//       },
//       {
//         role: 'user',
//         content: `元のタイトル：「${originalTitle}」
// 日本語の商品説明：「${limitedJpDesc}」
// この情報を基に、最適化された新しいeBayの商品説明を生成してください。`,
//       },
//     ],
//     max_tokens: 500,
//     temperature: 0.5,
//   };

//   const response = await axios.post(url, requestData, {
//     headers: { Authorization: `Bearer ${apiKey}` },
//   });

//   const generatedDescription = response.data.choices[0].message.content.trim();
//   return generatedDescription;
// }
