// BulkListingFromScratch.js

import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Typography,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Snackbar,
  Alert,
  IconButton,
  AppBar,
  Toolbar,
  Grid,
  CssBaseline,
  List,
  ListItem,
  ListItemText,
  Divider,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import { styled } from '@mui/material/styles';
import Drawer from '@mui/material/Drawer';
import EditableSpreadsheet from './EditableSpreadsheet';
import { doc, getDoc, updateDoc, setDoc, arrayUnion } from 'firebase/firestore';
import { db } from './firebaseConfig';
import { getAuth } from 'firebase/auth';
import CryptoJS from 'crypto-js';
import ColumnNameBasedTemplateManager from './ColumnNameBasedTemplateManager';
import APIKeyInput from './APIKeyInput';
import TitleGenerationComponent from './TitleGenerationComponent';
import OpenAIDescriptionGenerator from './OpenAIDescriptionGenerator';
import ItemSpecificsManager from './ItemSpecificsManager';
import UserTemplateManager from './UserTemplateManager';
import { categoryTemplates } from './CategoryTemplates';
import PriceManager from './PriceManager';
import ImageOperations from './ImageOperations';
import ImageUploader from './ImageUploader';
import { useNavigate } from 'react-router-dom';
import { parse } from 'papaparse';
import ColumnDeletion from './ColumnDeletion';

const drawerWidth = 250;

// スタイリングされたMainコンポーネントを定義
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    maxWidth: '100%',
    overflowX: 'auto',
    transition: theme.transitions.create(['margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create(['margin'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);

function BulkListingFromScratch() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const [activeSection, setActiveSection] = useState('main');
  const [selectedTemplate, setSelectedTemplate] = useState('');
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);

  // デフォルト値を設定したいカラムとその値を定義
  const defaultValues = {
    'Action(CC=Cp1252)': 'Add',
    'ConditionID': '3000',
    'C:Brand': 'No Brand',
    'PayPalAccepted': '1',
    'PayPalEmailAddress': 'payAddress',
    'Country': 'JP',
    'Apply Profile Domestic': '0.0',
    'Apply Profile International': '0.0',
    'BuyerRequirements:LinkedPayPalAccount': '0.0',
    'Duration': 'GTC',
    'Format': 'FixedPriceItem',
    'Quantity': '1',
    'Currency': 'USD',
    'SiteID': 'US',
    'C:Country': 'Japan',
    'BestOfferEnabled': '0',
    // 必要に応じて他のデフォルト値を追加
  };

  // ImageOperations用のステートと関数
  const [startRow, setStartRow] = useState(1);
  const [endRow, setEndRow] = useState(1);
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState('');
  const [openPreviewDialog, setOpenPreviewDialog] = useState(false);
  const [previewDialogMode, setPreviewDialogMode] = useState('edit');
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'info',
  });

  // OpenAIのAPIキーを管理するステート
  const [apiKey, setApiKey] = useState(null); // 初期値をnullに変更

  const [user, setUser] = useState(null);

  const navigate = useNavigate();

  // CSVファイルアップロード用のステート
  const [isCSVUploadDialogOpen, setIsCSVUploadDialogOpen] = useState(false);
  const [csvFile, setCsvFile] = useState(null);

  // ユーザーテンプレート管理用のステート
  const [userTemplates, setUserTemplates] = useState([]);
  // カテゴリーテンプレート管理用のステート
  const [userCategoryTemplates, setUserCategoryTemplates] = useState([]);

  // データが変更されたかどうかを追跡するステート
  const [isDataModified, setIsDataModified] = useState(false);

  // ユーザー情報を取得
  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = auth.onAuthStateChanged((firebaseUser) => {
      setUser(firebaseUser);
    });
    return () => unsubscribe();
  }, []);

  // ユーザー固有の秘密鍵を生成
  const generateEncryptionKey = (uid) => {
    const salt = 'your-fixed-salt-value';
    return CryptoJS.PBKDF2(uid, salt, { keySize: 256 / 32 }).toString();
  };

  // Snackbarを閉じる
  const handleSnackbarClose = () => {
    setSnackbar({
      ...snackbar,
      open: false,
    });
  };

  // エラーメッセージを表示
  const showSnackbar = (message, severity = 'info') => {
    setSnackbar({
      open: true,
      message,
      severity,
    });
  };

  // FirebaseからAPIキーを取得
  useEffect(() => {
    const fetchApiKey = async () => {
      if (user) {
        try {
          const docRef = doc(db, 'userApiKeys', user.uid);
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            const data = docSnap.data();
            // APIキーを復号化
            const encryptionKey = generateEncryptionKey(user.uid);
            const decryptedBytes = CryptoJS.AES.decrypt(data.apiKey, encryptionKey);
            const decryptedKey = decryptedBytes.toString(CryptoJS.enc.Utf8);

            if (decryptedKey) {
              setApiKey(decryptedKey);
            } else {
              console.error('APIキーの復号化に失敗しました。');
              setApiKey(null);
              showSnackbar('APIキーの復号化に失敗しました。再度設定してください。', 'error');
            }
          } else {
            console.error('APIキーが設定されていません。');
            setApiKey(null);
            showSnackbar('APIキーが設定されていません。APIキーを入力してください。', 'warning');
          }
        } catch (error) {
          console.error('APIキーの取得に失敗しました:', error);
          setApiKey(null);
          showSnackbar('APIキーの取得に失敗しました。', 'error');
        }
      }
    };

    fetchApiKey();
  }, [user]);

  // ユーザーテンプレートを取得
  useEffect(() => {
    const fetchUserTemplates = async () => {
      if (user) {
        try {
          const userTemplatesRef = doc(db, 'userTemplates', user.uid);
          const userTemplatesSnap = await getDoc(userTemplatesRef);
          if (userTemplatesSnap.exists()) {
            const data = userTemplatesSnap.data();
            setUserTemplates(data.templates || []);
          }
        } catch (error) {
          console.error('テンプレートの取得に失敗しました:', error);
        }
      }
    };

    fetchUserTemplates();
  }, [user]);

  // ユーザーカテゴリーテンプレートを取得
  useEffect(() => {
    const fetchUserCategoryTemplates = async () => {
      if (user) {
        try {
          const userCategoryTemplatesRef = doc(db, 'userCategoryTemplates', user.uid);
          const userCategoryTemplatesSnap = await getDoc(userCategoryTemplatesRef);
          if (userCategoryTemplatesSnap.exists()) {
            const data = userCategoryTemplatesSnap.data();
            setUserCategoryTemplates(data.templates || []);
          }
        } catch (error) {
          console.error('カテゴリーテンプレートの取得に失敗しました:', error);
        }
      }
    };

    fetchUserCategoryTemplates();
  }, [user]);

  const handleTemplateChange = (event) => {
    const templateName = event.target.value;
    setSelectedTemplate(templateName);

    if (templateName.startsWith('user-category-')) {
      const userTemplateName = templateName.replace('user-category-', '');
      const selectedUserCategoryTemplate = userCategoryTemplates.find(
        (template) => template.name === userTemplateName
      );
      setColumns(selectedUserCategoryTemplate.columns || []);
    } else {
      const selectedColumns = categoryTemplates[templateName] || [];
      setColumns(selectedColumns);
    }

    // 初期データをクリア
    setData([]);
    // startRowとendRowをリセット
    setStartRow(1);
    setEndRow(1);
  };

  // サイドバーのセクション切り替え
  const handleSectionChange = (section) => {
    setActiveSection(section);
  };

  // ホーム画面に戻る処理
  const handleGoHome = () => {
    navigate('/'); // ホーム画面へのパスに変更してください
  };

  // CSVアップロードダイアログを開く
  const openCSVUploadDialog = () => {
    setIsCSVUploadDialogOpen(true);
  };

  // CSVファイルが選択されたときの処理
  const handleCSVFileChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setCsvFile(e.target.files[0]);
    }
  };

  // CSVファイルをアップロードしてカテゴリーテンプレートを作成
  const handleCSVUpload = () => {
    if (!csvFile) {
      alert('CSVファイルを選択してください。');
      return;
    }

    parse(csvFile, {
      header: true,
      skipEmptyLines: true,
      complete: async (results) => {
        const columnsFromCSV = results.meta.fields;
        // テンプレート名をユーザーに入力してもらう
        const templateName = prompt('テンプレート名を入力してください:');
        if (templateName && columnsFromCSV) {
          // ユーザーのカテゴリーテンプレートとして保存
          await saveUserCategoryTemplate(templateName, columnsFromCSV);
          setIsCSVUploadDialogOpen(false);
          setCsvFile(null);
          showSnackbar('テンプレートが保存されました。', 'success');
        } else {
          alert('テンプレート名が無効です。');
        }
      },
      error: (error) => {
        console.error('CSVのパースに失敗しました:', error);
        alert('CSVファイルの読み込みに失敗しました。');
      },
    });
  };

  // ユーザーカテゴリーテンプレートを保存
  const saveUserCategoryTemplate = async (templateName, columns) => {
    if (!user) {
      alert('ユーザーが認証されていません。再度ログインしてください。');
      return;
    }

    try {
      const userCategoryTemplatesRef = doc(db, 'userCategoryTemplates', user.uid);
      const userCategoryTemplatesSnap = await getDoc(userCategoryTemplatesRef);

      const newTemplate = { name: templateName, columns };

      if (userCategoryTemplatesSnap.exists()) {
        const data = userCategoryTemplatesSnap.data();
        const existingTemplates = data.templates || [];
        // 同名のテンプレートが存在するか確認
        const duplicate = existingTemplates.find((t) => t.name === templateName);
        if (duplicate) {
          alert('同じ名前のテンプレートが既に存在します。別の名前を使用してください。');
          return;
        }
        // 既存のテンプレートに追加
        await updateDoc(userCategoryTemplatesRef, {
          templates: arrayUnion(newTemplate),
        });
        setUserCategoryTemplates([...existingTemplates, newTemplate]);
      } else {
        // 新しいドキュメントを作成
        await setDoc(userCategoryTemplatesRef, {
          templates: [newTemplate],
        });
        setUserCategoryTemplates([newTemplate]);
      }
    } catch (error) {
      console.error('カテゴリーテンプレートの保存に失敗しました:', error);
      alert('カテゴリーテンプレートの保存に失敗しました。');
    }
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      {/* AppBar */}
      <AppBar
        position="fixed"
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          transition: (theme) =>
            theme.transitions.create(['width', 'margin'], {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.leavingScreen,
            }),
          ...(isSidebarOpen && {
            marginLeft: drawerWidth,
            width: `calc(100% - ${drawerWidth}px)`,
          }),
        }}
      >
        <Toolbar>
          {/* ホーム画面へのリンクを追加 */}
          <IconButton
            color="inherit"
            aria-label="go to home"
            onClick={handleGoHome}
            edge="start"
            sx={{
              marginRight: 2,
            }}
          >
            <HomeIcon />
          </IconButton>

          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={() => setIsSidebarOpen(!isSidebarOpen)}
            edge="start"
            sx={{
              marginRight: 2,
            }}
          >
            {isSidebarOpen ? <CloseIcon /> : <MenuIcon />}
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            0から一括出品
          </Typography>
        </Toolbar>
      </AppBar>

      {/* サイドバー */}
      <Drawer
        variant="persistent"
        anchor="left"
        open={isSidebarOpen}
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
      >
        <Toolbar />
        <Box sx={{ overflow: 'auto' }}>
          <List>
            <ListItem button onClick={() => handleSectionChange('main')}>
              <ListItemText primary="メイン" />
            </ListItem>
            <Divider />
            <ListItem button onClick={() => handleSectionChange('priceManager')}>
              <ListItemText primary="価格管理" />
            </ListItem>
            <ListItem button onClick={() => handleSectionChange('itemSpecifics')}>
              <ListItemText primary="Item Specifics管理" />
            </ListItem>
            <ListItem button onClick={() => handleSectionChange('templateManager')}>
              <ListItemText primary="テンプレート管理" />
            </ListItem>
            <Divider />
            <ListItem button onClick={() => handleSectionChange('titleGeneration')}>
              <ListItemText primary="AIタイトル生成" />
            </ListItem>
            <ListItem button onClick={() => handleSectionChange('descriptionGeneration')}>
              <ListItemText primary="AI商品説明生成" />
            </ListItem>
            <Divider />
            <ListItem button onClick={() => handleSectionChange('apiKeyInput')}>
              <ListItemText primary="APIキー入力" />
            </ListItem>
            <ListItem button onClick={() => handleSectionChange('userTemplateManager')}>
              <ListItemText primary="ユーザーテンプレート管理" />
            </ListItem>
          </List>
        </Box>
      </Drawer>

      {/* メインコンテンツ */}
      <Main open={isSidebarOpen}>
        <Toolbar />
        {/* APIキーが未設定の場合に警告を表示 */}
        {!apiKey && (
          <Alert severity="warning" sx={{ mb: 2 }}>
            OpenAI APIキーが設定されていません。左のサイドバーから「APIキー入力」を選択し、APIキーを入力してください。
          </Alert>
        )}
        {activeSection === 'main' && (
          <Box>
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel id="template-select-label">カテゴリーテンプレートを選択</InputLabel>
              <Select
                labelId="template-select-label"
                value={selectedTemplate}
                label="カテゴリーテンプレートを選択"
                onChange={handleTemplateChange}
              >
                <MenuItem value="">
                  <em>テンプレートを選択してください</em>
                </MenuItem>
                {/* 既存のテンプレート */}
                {Object.keys(categoryTemplates).map((templateName) => (
                  <MenuItem key={templateName} value={templateName}>
                    {templateName}
                  </MenuItem>
                ))}
                {/* ユーザーのカテゴリーテンプレート */}
                {userCategoryTemplates.map((template) => (
                  <MenuItem key={template.name} value={`user-category-${template.name}`}>
                    {template.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <Grid container spacing={2} sx={{ mb: 2 }}>
              <Grid item xs={12} sm={6} md={4}>
                <Button variant="contained" color="primary" onClick={openCSVUploadDialog}>
                  CSVファイルからテンプレートを作成
                </Button>
              </Grid>
            </Grid>

            {/* CSVアップロードダイアログ */}
            <Dialog open={isCSVUploadDialogOpen} onClose={() => setIsCSVUploadDialogOpen(false)}>
              <DialogTitle>CSVファイルをアップロード</DialogTitle>
              <DialogContent>
                <input type="file" accept=".csv" onChange={handleCSVFileChange} />
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setIsCSVUploadDialogOpen(false)}>キャンセル</Button>
                <Button onClick={handleCSVUpload} disabled={!csvFile}>
                  アップロード
                </Button>
              </DialogActions>
            </Dialog>

            {selectedTemplate && (
              <>
                {/* ImageUploaderコンポーネントを追加 */}
                <ImageUploader
                  user={user}
                  showSnackbar={showSnackbar}
                  defaultValues={defaultValues}
                  columns={columns}
                  setColumns={setColumns}
                  data={data}
                  setData={setData}
                  startRow={startRow}
                  setStartRow={setStartRow}
                  endRow={endRow}
                  setEndRow={setEndRow}
                />

                {/* ImageOperationsコンポーネントを追加 */}
                <Box sx={{ mt: 4 }}>
                  <ImageOperations
                    data={data}
                    setData={setData}
                    startRow={startRow}
                    endRow={endRow}
                    calculateMaxEndRow={() => data.length}
                    isProcessing={isProcessing}
                    setIsProcessing={setIsProcessing}
                    setError={setError}
                    showSnackbar={showSnackbar}
                    openPreviewDialog={openPreviewDialog}
                    setOpenPreviewDialog={setOpenPreviewDialog}
                    apiKey={apiKey} // OpenAIのAPIキー
                    previewDialogMode={previewDialogMode}
                    setPreviewDialogMode={setPreviewDialogMode}
                  />
                </Box>

                {/* カラム削除コンポーネントを追加 */}
                <Box sx={{ mt: 4 }}>
                  <ColumnDeletion
                    data={data}
                    setData={setData}
                    columns={columns}
                    setColumns={setColumns}
                  />
                </Box>
              </>
            )}

            {data.length > 0 && (
              <>
                {/* EditableSpreadsheetを常に表示 */}
                <Paper elevation={3} sx={{ p: 2, mt: 4 }}>
                  <EditableSpreadsheet
                    data={data}
                    setData={setData}
                    columns={columns}
                    setColumns={setColumns}
                  />
                </Paper>

                {/* Snackbar */}
                <Snackbar
                  open={snackbar.open}
                  autoHideDuration={6000}
                  onClose={handleSnackbarClose}
                >
                  <Alert
                    onClose={handleSnackbarClose}
                    severity={snackbar.severity}
                    sx={{ width: '100%' }}
                  >
                    {snackbar.message}
                  </Alert>
                </Snackbar>
              </>
            )}
          </Box>
        )}

        {activeSection === 'priceManager' && (
          <Box>
            <PriceManager data={data} setData={setData} />
          </Box>
        )}

        {activeSection === 'itemSpecifics' && (
          <Box>
            <ItemSpecificsManager
              userId={user ? user.uid : null}
              headers={columns}
              data={data}
              setData={setData}
            />
          </Box>
        )}

        {activeSection === 'templateManager' && (
          <Box>
            <ColumnNameBasedTemplateManager
              data={data}
              setData={setData}
              columns={columns}
              setColumns={setColumns}
              setIsDataModified={setIsDataModified} // ここで渡す
            />
          </Box>
        )}

        {activeSection === 'titleGeneration' && (
          <Box>
            <TitleGenerationComponent data={data} setData={setData} apiKey={apiKey} user={user} />
          </Box>
        )}

        {activeSection === 'descriptionGeneration' && (
          <Box>
            <OpenAIDescriptionGenerator data={data} setData={setData} apiKey={apiKey} user={user} />
          </Box>
        )}

        {activeSection === 'apiKeyInput' && (
          <Box>
            <APIKeyInput user={user} apiKey={apiKey} setApiKey={setApiKey} />
          </Box>
        )}

        {activeSection === 'userTemplateManager' && (
          <Box>
            <UserTemplateManager
              userTemplates={userTemplates}
              setUserTemplates={setUserTemplates}
              user={user}
            />
          </Box>
        )}
      </Main>
    </Box>
  );
}

export default BulkListingFromScratch;





// // BulkListingFromScratch.js

// import React, { useState, useEffect } from 'react';
// import {
//   Box,
//   Button,
//   Typography,
//   Paper,
//   FormControl,
//   InputLabel,
//   Select,
//   MenuItem,
//   Dialog,
//   DialogTitle,
//   DialogContent,
//   DialogActions,
//   LinearProgress,
//   Snackbar,
//   Alert,
//   IconButton,
//   AppBar,
//   Toolbar,
//   Grid,
//   CssBaseline,
//   Divider,
//   List,
//   ListItem,
//   ListItemText,
// } from '@mui/material';
// import CloseIcon from '@mui/icons-material/Close';
// import MenuIcon from '@mui/icons-material/Menu';
// import HomeIcon from '@mui/icons-material/Home';
// import { styled } from '@mui/material/styles';
// import Drawer from '@mui/material/Drawer';
// import EditableSpreadsheet from './EditableSpreadsheet';
// import { doc, getDoc, updateDoc, setDoc, arrayUnion } from 'firebase/firestore';
// import { db } from './firebaseConfig';
// import { getAuth } from 'firebase/auth';
// import CryptoJS from 'crypto-js';
// import ColumnNameBasedTemplateManager from './ColumnNameBasedTemplateManager';
// import APIKeyInput from './APIKeyInput';
// import TitleGenerationComponent from './TitleGenerationComponent';
// import OpenAIDescriptionGenerator from './OpenAIDescriptionGenerator';
// import ItemSpecificsManager from './ItemSpecificsManager';
// import UserTemplateManager from './UserTemplateManager';
// import { categoryTemplates } from './CategoryTemplates';
// import PriceManager from './PriceManager';
// import ImageOperations from './ImageOperations';
// import ImageUploader from './ImageUploader'; // 新しいコンポーネントをインポート
// import { useNavigate } from 'react-router-dom';
// import { parse } from 'papaparse'; // CSVパース用のライブラリ
// import ColumnDeletion from './ColumnDeletion'; // ColumnDeletionをインポート

// const drawerWidth = 250;

// // スタイリングされたMainコンポーネントを定義
// const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
//   ({ theme, open }) => ({
//     flexGrow: 1,
//     padding: theme.spacing(3),
//     maxWidth: '100%',
//     overflowX: 'auto',
//     transition: theme.transitions.create(['margin'], {
//       easing: theme.transitions.easing.sharp,
//       duration: theme.transitions.duration.leavingScreen,
//     }),
//     marginLeft: `-${drawerWidth}px`,
//     ...(open && {
//       transition: theme.transitions.create(['margin'], {
//         easing: theme.transitions.easing.easeOut,
//         duration: theme.transitions.duration.enteringScreen,
//       }),
//       marginLeft: 0,
//     }),
//   }),
// );

// function BulkListingFromScratch() {
//   const [isSidebarOpen, setIsSidebarOpen] = useState(true);

//   const [activeSection, setActiveSection] = useState('main');
//   const [selectedTemplate, setSelectedTemplate] = useState('');
//   const [columns, setColumns] = useState([]);
//   const [data, setData] = useState([]);

//   // デフォルト値を設定したいカラムとその値を定義
//   const defaultValues = {
//     'Action(CC=Cp1252)': 'Add',
//     'ConditionID': '3000',
//     'C:Brand': 'No Brand',
//     'PayPalAccepted': '1',
//     'PayPalEmailAddress': 'payAddress',
//     'Country': 'JP',
//     'Apply Profile Domestic': '0.0',
//     'Apply Profile International': '0.0',
//     'BuyerRequirements:LinkedPayPalAccount': '0.0',
//     'Duration': 'GTC',
//     'Format': 'FixedPriceItem',
//     'Quantity': '1',
//     'Currency': 'USD',
//     'SiteID': 'US',
//     'C:Country': 'Japan',
//     'BestOfferEnabled': '0',
//     // 必要に応じて他のデフォルト値を追加
//   };

//   // ImageOperations用のステートと関数
//   const [startRow, setStartRow] = useState(1);
//   const [endRow, setEndRow] = useState(1);
//   const [isProcessing, setIsProcessing] = useState(false);
//   const [error, setError] = useState('');
//   const [openPreviewDialog, setOpenPreviewDialog] = useState(false);
//   const [previewDialogMode, setPreviewDialogMode] = useState('edit');
//   const [snackbar, setSnackbar] = useState({
//     open: false,
//     message: '',
//     severity: 'info',
//   });

//   // OpenAIのAPIキーを管理するステート
//   const [apiKey, setApiKey] = useState(null); // 初期値をnullに変更

//   const [user, setUser] = useState(null);

//   const navigate = useNavigate();

//   // CSVファイルアップロード用のステート
//   const [isCSVUploadDialogOpen, setIsCSVUploadDialogOpen] = useState(false);
//   const [csvFile, setCsvFile] = useState(null);

//   // ユーザーテンプレート管理用のステート
//   const [userTemplates, setUserTemplates] = useState([]);
//   // カテゴリーテンプレート管理用のステート
//   const [userCategoryTemplates, setUserCategoryTemplates] = useState([]);

//   // ユーザー情報を取得
//   useEffect(() => {
//     const auth = getAuth();
//     const unsubscribe = auth.onAuthStateChanged((firebaseUser) => {
//       setUser(firebaseUser);
//     });
//     return () => unsubscribe();
//   }, []);

//   // ユーザー固有の秘密鍵を生成
//   const generateEncryptionKey = (uid) => {
//     const salt = 'your-fixed-salt-value';
//     return CryptoJS.PBKDF2(uid, salt, { keySize: 256 / 32 }).toString();
//   };

//   // Snackbarを閉じる
//   const handleSnackbarClose = () => {
//     setSnackbar({
//       ...snackbar,
//       open: false,
//     });
//   };

//   // エラーメッセージを表示
//   const showSnackbar = (message, severity = 'info') => {
//     setSnackbar({
//       open: true,
//       message,
//       severity,
//     });
//   };

//   // FirebaseからAPIキーを取得
//   useEffect(() => {
//     const fetchApiKey = async () => {
//       if (user) {
//         try {
//           const docRef = doc(db, 'userApiKeys', user.uid);
//           const docSnap = await getDoc(docRef);
//           if (docSnap.exists()) {
//             const data = docSnap.data();
//             // APIキーを復号化
//             const encryptionKey = generateEncryptionKey(user.uid);
//             const decryptedBytes = CryptoJS.AES.decrypt(data.apiKey, encryptionKey);
//             const decryptedKey = decryptedBytes.toString(CryptoJS.enc.Utf8);

//             if (decryptedKey) {
//               setApiKey(decryptedKey);
//             } else {
//               console.error('APIキーの復号化に失敗しました。');
//               setApiKey(null);
//               showSnackbar('APIキーの復号化に失敗しました。再度設定してください。', 'error');
//             }
//           } else {
//             console.error('APIキーが設定されていません。');
//             setApiKey(null);
//             showSnackbar('APIキーが設定されていません。APIキーを入力してください。', 'warning');
//           }
//         } catch (error) {
//           console.error('APIキーの取得に失敗しました:', error);
//           setApiKey(null);
//           showSnackbar('APIキーの取得に失敗しました。', 'error');
//         }
//       }
//     };

//     fetchApiKey();
//   }, [user]);

//   // ユーザーテンプレートを取得
//   useEffect(() => {
//     const fetchUserTemplates = async () => {
//       if (user) {
//         try {
//           const userTemplatesRef = doc(db, 'userTemplates', user.uid);
//           const userTemplatesSnap = await getDoc(userTemplatesRef);
//           if (userTemplatesSnap.exists()) {
//             const data = userTemplatesSnap.data();
//             setUserTemplates(data.templates || []);
//           }
//         } catch (error) {
//           console.error('テンプレートの取得に失敗しました:', error);
//         }
//       }
//     };

//     fetchUserTemplates();
//   }, [user]);

//   // ユーザーカテゴリーテンプレートを取得
//   useEffect(() => {
//     const fetchUserCategoryTemplates = async () => {
//       if (user) {
//         try {
//           const userCategoryTemplatesRef = doc(db, 'userCategoryTemplates', user.uid);
//           const userCategoryTemplatesSnap = await getDoc(userCategoryTemplatesRef);
//           if (userCategoryTemplatesSnap.exists()) {
//             const data = userCategoryTemplatesSnap.data();
//             setUserCategoryTemplates(data.templates || []);
//           }
//         } catch (error) {
//           console.error('カテゴリーテンプレートの取得に失敗しました:', error);
//         }
//       }
//     };

//     fetchUserCategoryTemplates();
//   }, [user]);

//   const handleTemplateChange = (event) => {
//     const templateName = event.target.value;
//     setSelectedTemplate(templateName);

//     if (templateName.startsWith('user-category-')) {
//       const userTemplateName = templateName.replace('user-category-', '');
//       const selectedUserCategoryTemplate = userCategoryTemplates.find(
//         (template) => template.name === userTemplateName
//       );
//       setColumns(selectedUserCategoryTemplate.columns || []);
//     } else {
//       const selectedColumns = categoryTemplates[templateName] || [];
//       setColumns(selectedColumns);
//     }

//     // 初期データをクリア
//     setData([]);
//     // startRowとendRowをリセット
//     setStartRow(1);
//     setEndRow(1);
//   };

//   // サイドバーのセクション切り替え
//   const handleSectionChange = (section) => {
//     setActiveSection(section);
//   };

//   // ホーム画面に戻る処理
//   const handleGoHome = () => {
//     navigate('/'); // ホーム画面へのパスに変更してください
//   };

//   // CSVアップロードダイアログを開く
//   const openCSVUploadDialog = () => {
//     setIsCSVUploadDialogOpen(true);
//   };

//   // CSVファイルが選択されたときの処理
//   const handleCSVFileChange = (e) => {
//     if (e.target.files && e.target.files.length > 0) {
//       setCsvFile(e.target.files[0]);
//     }
//   };

//   // CSVファイルをアップロードしてカテゴリーテンプレートを作成
//   const handleCSVUpload = () => {
//     if (!csvFile) {
//       alert('CSVファイルを選択してください。');
//       return;
//     }

//     parse(csvFile, {
//       header: true,
//       skipEmptyLines: true,
//       complete: async (results) => {
//         const columnsFromCSV = results.meta.fields;
//         // テンプレート名をユーザーに入力してもらう
//         const templateName = prompt('テンプレート名を入力してください:');
//         if (templateName && columnsFromCSV) {
//           // ユーザーのカテゴリーテンプレートとして保存
//           await saveUserCategoryTemplate(templateName, columnsFromCSV);
//           setIsCSVUploadDialogOpen(false);
//           setCsvFile(null);
//           showSnackbar('テンプレートが保存されました。', 'success');
//         } else {
//           alert('テンプレート名が無効です。');
//         }
//       },
//       error: (error) => {
//         console.error('CSVのパースに失敗しました:', error);
//         alert('CSVファイルの読み込みに失敗しました。');
//       },
//     });
//   };

//   // ユーザーカテゴリーテンプレートを保存
//   const saveUserCategoryTemplate = async (templateName, columns) => {
//     if (!user) {
//       alert('ユーザーが認証されていません。再度ログインしてください。');
//       return;
//     }

//     try {
//       const userCategoryTemplatesRef = doc(db, 'userCategoryTemplates', user.uid);
//       const userCategoryTemplatesSnap = await getDoc(userCategoryTemplatesRef);

//       const newTemplate = { name: templateName, columns };

//       if (userCategoryTemplatesSnap.exists()) {
//         const data = userCategoryTemplatesSnap.data();
//         const existingTemplates = data.templates || [];
//         // 同名のテンプレートが存在するか確認
//         const duplicate = existingTemplates.find((t) => t.name === templateName);
//         if (duplicate) {
//           alert('同じ名前のテンプレートが既に存在します。別の名前を使用してください。');
//           return;
//         }
//         // 既存のテンプレートに追加
//         await updateDoc(userCategoryTemplatesRef, {
//           templates: arrayUnion(newTemplate),
//         });
//         setUserCategoryTemplates([...existingTemplates, newTemplate]);
//       } else {
//         // 新しいドキュメントを作成
//         await setDoc(userCategoryTemplatesRef, {
//           templates: [newTemplate],
//         });
//         setUserCategoryTemplates([newTemplate]);
//       }
//     } catch (error) {
//       console.error('カテゴリーテンプレートの保存に失敗しました:', error);
//       alert('カテゴリーテンプレートの保存に失敗しました。');
//     }
//   };

//   return (
//     <Box sx={{ display: 'flex' }}>
//       <CssBaseline />
//       {/* AppBar */}
//       <AppBar
//         position="fixed"
//         sx={{
//           zIndex: (theme) => theme.zIndex.drawer + 1,
//           transition: (theme) =>
//             theme.transitions.create(['width', 'margin'], {
//               easing: theme.transitions.easing.sharp,
//               duration: theme.transitions.duration.leavingScreen,
//             }),
//           ...(isSidebarOpen && {
//             marginLeft: drawerWidth,
//             width: `calc(100% - ${drawerWidth}px)`,
//           }),
//         }}
//       >
//         <Toolbar>
//           {/* ホーム画面へのリンクを追加 */}
//           <IconButton
//             color="inherit"
//             aria-label="go to home"
//             onClick={handleGoHome}
//             edge="start"
//             sx={{
//               marginRight: 2,
//             }}
//           >
//             <HomeIcon />
//           </IconButton>

//           <IconButton
//             color="inherit"
//             aria-label="open drawer"
//             onClick={() => setIsSidebarOpen(!isSidebarOpen)}
//             edge="start"
//             sx={{
//               marginRight: 2,
//             }}
//           >
//             {isSidebarOpen ? <CloseIcon /> : <MenuIcon />}
//           </IconButton>
//           <Typography variant="h6" noWrap component="div">
//             0から一括出品
//           </Typography>
//         </Toolbar>
//       </AppBar>

//       {/* サイドバー */}
//       <Drawer
//         variant="persistent"
//         anchor="left"
//         open={isSidebarOpen}
//         sx={{
//           width: drawerWidth,
//           flexShrink: 0,
//           '& .MuiDrawer-paper': {
//             width: drawerWidth,
//             boxSizing: 'border-box',
//           },
//         }}
//       >
//         <Toolbar />
//         <Box sx={{ overflow: 'auto' }}>
//           <List>
//             <ListItem button onClick={() => handleSectionChange('main')}>
//               <ListItemText primary="メイン" />
//             </ListItem>
//             <ListItem button onClick={() => handleSectionChange('priceManager')}>
//               <ListItemText primary="価格管理" />
//             </ListItem>
//             <ListItem button onClick={() => handleSectionChange('itemSpecifics')}>
//               <ListItemText primary="Item Specifics管理" />
//             </ListItem>
//             <ListItem button onClick={() => handleSectionChange('templateManager')}>
//               <ListItemText primary="テンプレート管理" />
//             </ListItem>
//             <ListItem button onClick={() => handleSectionChange('titleGeneration')}>
//               <ListItemText primary="AIタイトル生成" />
//             </ListItem>
//             <ListItem button onClick={() => handleSectionChange('descriptionGeneration')}>
//               <ListItemText primary="AI商品説明生成" />
//             </ListItem>
//             <ListItem button onClick={() => handleSectionChange('apiKeyInput')}>
//               <ListItemText primary="APIキー入力" />
//             </ListItem>
//             <ListItem button onClick={() => handleSectionChange('userTemplateManager')}>
//               <ListItemText primary="ユーザーテンプレート管理" />
//             </ListItem>
//           </List>
//         </Box>
//       </Drawer>

//       {/* メインコンテンツ */}
//       <Main open={isSidebarOpen}>
//         <Toolbar />
//         {/* APIキーが未設定の場合に警告を表示 */}
//         {!apiKey && (
//           <Alert severity="warning" sx={{ mb: 2 }}>
//             OpenAI APIキーが設定されていません。左のサイドバーから「APIキー入力」を選択し、APIキーを入力してください。
//           </Alert>
//         )}
//         {activeSection === 'main' && (
//           <Box>
//             <FormControl fullWidth sx={{ mb: 2 }}>
//               <InputLabel id="template-select-label">カテゴリーテンプレートを選択</InputLabel>
//               <Select
//                 labelId="template-select-label"
//                 value={selectedTemplate}
//                 label="カテゴリーテンプレートを選択"
//                 onChange={handleTemplateChange}
//               >
//                 <MenuItem value="">
//                   <em>テンプレートを選択してください</em>
//                 </MenuItem>
//                 {/* 既存のテンプレート */}
//                 {Object.keys(categoryTemplates).map((templateName) => (
//                   <MenuItem key={templateName} value={templateName}>
//                     {templateName}
//                   </MenuItem>
//                 ))}
//                 {/* ユーザーのカテゴリーテンプレート */}
//                 {userCategoryTemplates.map((template) => (
//                   <MenuItem key={template.name} value={`user-category-${template.name}`}>
//                     {template.name}
//                   </MenuItem>
//                 ))}
//               </Select>
//             </FormControl>

//             <Grid container spacing={2} sx={{ mb: 2 }}>
//               <Grid item xs={12} sm={6} md={4}>
//                 <Button variant="contained" color="primary" onClick={openCSVUploadDialog}>
//                   CSVファイルからテンプレートを作成
//                 </Button>
//               </Grid>
//             </Grid>

//             {/* CSVアップロードダイアログ */}
//             <Dialog open={isCSVUploadDialogOpen} onClose={() => setIsCSVUploadDialogOpen(false)}>
//               <DialogTitle>CSVファイルをアップロード</DialogTitle>
//               <DialogContent>
//                 <input type="file" accept=".csv" onChange={handleCSVFileChange} />
//               </DialogContent>
//               <DialogActions>
//                 <Button onClick={() => setIsCSVUploadDialogOpen(false)}>キャンセル</Button>
//                 <Button onClick={handleCSVUpload} disabled={!csvFile}>
//                   アップロード
//                 </Button>
//               </DialogActions>
//             </Dialog>

//             {selectedTemplate && (
//               <>
//                 {/* ImageUploaderコンポーネントを追加 */}
//                 <ImageUploader
//                   user={user}
//                   showSnackbar={showSnackbar}
//                   defaultValues={defaultValues}
//                   columns={columns}
//                   setColumns={setColumns}
//                   data={data}
//                   setData={setData}
//                   startRow={startRow}
//                   setStartRow={setStartRow}
//                   endRow={endRow}
//                   setEndRow={setEndRow}
//                 />

//                 {/* ImageOperationsコンポーネントを追加 */}
//                 <Box sx={{ mt: 4 }}>
//                   <ImageOperations
//                     data={data}
//                     setData={setData}
//                     startRow={startRow}
//                     endRow={endRow}
//                     calculateMaxEndRow={() => data.length}
//                     isProcessing={isProcessing}
//                     setIsProcessing={setIsProcessing}
//                     setError={setError}
//                     showSnackbar={showSnackbar}
//                     openPreviewDialog={openPreviewDialog}
//                     setOpenPreviewDialog={setOpenPreviewDialog}
//                     apiKey={apiKey} // OpenAIのAPIキー
//                     previewDialogMode={previewDialogMode}
//                     setPreviewDialogMode={setPreviewDialogMode}
//                   />
//                 </Box>

//                 {/* カラム削除コンポーネントを追加 */}
//                 <Box sx={{ mt: 4 }}>
//                   <ColumnDeletion
//                     data={data}
//                     setData={setData}
//                     columns={columns}
//                     setColumns={setColumns}
//                   />
//                 </Box>
//               </>
//             )}

//             {data.length > 0 && (
//               <>
//                 {/* EditableSpreadsheetを常に表示 */}
//                 <Paper elevation={3} sx={{ p: 2, mt: 4 }}>
//                   <EditableSpreadsheet
//                     data={data}
//                     setData={setData}
//                     columns={columns}
//                     setColumns={setColumns}
//                   />
//                 </Paper>

//                 {/* Snackbar */}
//                 <Snackbar
//                   open={snackbar.open}
//                   autoHideDuration={6000}
//                   onClose={handleSnackbarClose}
//                 >
//                   <Alert
//                     onClose={handleSnackbarClose}
//                     severity={snackbar.severity}
//                     sx={{ width: '100%' }}
//                   >
//                     {snackbar.message}
//                   </Alert>
//                 </Snackbar>
//               </>
//             )}
//           </Box>
//         )}

//         {activeSection === 'priceManager' && (
//           <Box>
//             <PriceManager data={data} setData={setData} />
//           </Box>
//         )}

//         {activeSection === 'itemSpecifics' && (
//           <Box>
//             <ItemSpecificsManager
//               userId={user ? user.uid : null}
//               headers={columns}
//               data={data}
//               setData={setData}
//             />
//           </Box>
//         )}

//         {activeSection === 'templateManager' && (
//           <Box>
//             <ColumnNameBasedTemplateManager
//               data={data}
//               setData={setData}
//               columns={columns}
//               setColumns={setColumns}
//             />
//           </Box>
//         )}

//         {activeSection === 'titleGeneration' && (
//           <Box>
//             <TitleGenerationComponent data={data} setData={setData} apiKey={apiKey} user={user} />
//           </Box>
//         )}

//         {activeSection === 'descriptionGeneration' && (
//           <Box>
//             <OpenAIDescriptionGenerator data={data} setData={setData} apiKey={apiKey} user={user} />
//           </Box>
//         )}

//         {activeSection === 'apiKeyInput' && (
//           <Box>
//             <APIKeyInput user={user} apiKey={apiKey} setApiKey={setApiKey} />
//           </Box>
//         )}

//         {activeSection === 'userTemplateManager' && (
//           <Box>
//             <UserTemplateManager
//               userTemplates={userTemplates}
//               setUserTemplates={setUserTemplates}
//               user={user}
//             />
//           </Box>
//         )}
//       </Main>
//     </Box>
//   );
// }

// export default BulkListingFromScratch;