// calculatePrices.js

// 価格を計算する関数
export const calculatePrices = (data, settings, selectedShippingTemplateId) => {
    if (!settings) {
      throw new Error('価格設定が提供されていません。');
    }
  
    const {
      fees,
      targetProfitMargin,
      discountRate,
      shippingRateTemplates,
      categories,
      bestOfferAutoAcceptPercentage,
      minimumBestOfferPercentage,
      exchangeRate,
    } = settings;
  
    // 選択された送料テンプレートを取得
    const selectedShippingTemplate =
      shippingRateTemplates.find(
        (template) => template.id === selectedShippingTemplateId
      ) || shippingRateTemplates[0];
  
    if (!selectedShippingTemplate) {
      throw new Error('選択された送料テンプレートが見つかりません。');
    }
  
    const newPrices = [];
  
    data.forEach((item, index) => {
      try {
        const startPrice = parseFloat(item.StartPrice);
        if (isNaN(startPrice)) {
          throw new Error(`StartPriceが数値ではありません（行 ${index + 1}）`);
        }
  
        let totalCost = startPrice;
        let totalPercentage = 0;
  
        // カテゴリーの項目を適用
        if (categories && typeof categories === 'object') {
          Object.values(categories)
            .flat()
            .forEach((categoryItem) => {
              if (categoryItem.type === 'fixed') {
                totalCost += parseFloat(categoryItem.value) || 0;
              } else if (categoryItem.type === 'percentage') {
                totalPercentage += parseFloat(categoryItem.value) || 0;
              }
            });
        }
  
        // 手数料を追加
        totalPercentage +=
          parseFloat(fees.ebayFinalValue || 0) +
          parseFloat(fees.payoneer || 0) +
          parseFloat(fees.promotedListing || 0);
        totalCost += parseFloat(fees.ebayTransactionFee || 0);
  
        // 送料を追加
        const shippingRate = selectedShippingTemplate?.shippingRates?.find(
          (rate) =>
            startPrice >= parseFloat(rate.minPriceUSD) &&
            (parseFloat(rate.maxPriceUSD) === Infinity ||
              startPrice < parseFloat(rate.maxPriceUSD))
        );
        if (shippingRate) {
          totalCost += parseFloat(shippingRate.costUSD) || 0;
        }
  
        // 目標利益率を追加
        totalPercentage += parseFloat(targetProfitMargin) || 0;
  
        // 販売価格を計算
        let calculatedPrice = totalCost / (1 - totalPercentage / 100);
        calculatedPrice = adjustPriceTo99(calculatedPrice);
  
        // 割引後価格を計算
        const discount = parseFloat(discountRate) || 0;
        const discountedPrice = calculatedPrice * (1 - discount / 100);
        const finalPrice = adjustPriceTo99(discountedPrice);
  
        // ベストオファー価格を計算
        const bestOfferAutoAcceptPrice =
          finalPrice * (bestOfferAutoAcceptPercentage / 100);
        const minimumBestOfferPrice =
          finalPrice * (minimumBestOfferPercentage / 100);
  
        // 利益と利益率を計算
        const profit = finalPrice - totalCost;
        const profitMargin = (profit / finalPrice) * 100;
  
        // 計算結果を保存
        newPrices.push({
          originalStartPrice: startPrice.toFixed(2),
          calculatedPrice: calculatedPrice.toFixed(2),
          discountedPrice: finalPrice.toFixed(2),
          bestOfferAutoAcceptPrice: bestOfferAutoAcceptPrice.toFixed(2),
          minimumBestOfferPrice: minimumBestOfferPrice.toFixed(2),
          profit: profit.toFixed(2),
          profitMargin: profitMargin.toFixed(2),
        });
      } catch (error) {
        console.error(
          `アイテムの処理中にエラーが発生しました（行 ${index + 1}）:`,
          error
        );
        newPrices.push(null); // エラーが発生したアイテムは null を設定
      }
    });
  
    return newPrices;
  };
  
  // 価格を99で終わるように調整する関数
  const adjustPriceTo99 = (price) => {
    return Math.floor(price) + 0.99;
  };
  
  
  

// // calculatePrices.js

// // デフォルトの設定値
// export const defaultSettings = {
//     fees: {
//       ebayFinalValue: 10,        // eBay最終価値手数料（%）
//       payoneer: 2,               // Payoneer手数料（%）
//       promotedListing: 3,        // 広告出品手数料（%）
//       ebayTransactionFee: 0.3,   // eBay取引手数料（固定額、USD）
//     },
//     targetProfitMargin: 25,      // 目標利益率（%）
//     discountRate: 0,             // 割引率（%）
//     shippingRateTemplates: [
//       {
//         id: 'default',
//         name: 'デフォルト送料設定',
//         shippingRates: [
//           { minPriceUSD: 0, maxPriceUSD: 50, costUSD: 5 },           // 仕入れ価格が$0〜$50の場合の送料
//           { minPriceUSD: 50, maxPriceUSD: 100, costUSD: 10 },        // 仕入れ価格が$50〜$100の場合の送料
//           { minPriceUSD: 100, maxPriceUSD: Infinity, costUSD: 15 },  // 仕入れ価格が$100以上の場合の送料
//         ],
//       },
//     ],
//     selectedShippingTemplateId: 'default',  // 使用する送料テンプレートのID
//     categories: {
//       additionalCosts: [],  // 追加コスト（固定額または割合）
//       expenses: [],         // 経費（固定額または割合）
//       fees: [],             // その他の手数料（固定額または割合）
//       shipping: [],         // 送料に関連するコスト（固定額または割合）
//     },
//     bestOfferAutoAcceptPercentage: 95,  // ベストオファー自動承認価格の割合（%）
//     minimumBestOfferPercentage: 80,     // 最小ベストオファー価格の割合（%）
//     exchangeRate: 140,                  // 為替レート（1 USD = X JPY）
//   };
  
//   // 価格を計算する関数
//   export const calculatePrices = (data, settings = defaultSettings) => {
//     const {
//       fees,
//       targetProfitMargin,
//       discountRate,
//       shippingRateTemplates,
//       selectedShippingTemplateId,
//       categories,
//       bestOfferAutoAcceptPercentage,
//       minimumBestOfferPercentage,
//       exchangeRate,
//     } = settings;
  
//     // 選択された送料テンプレートを取得
//     const selectedShippingTemplate =
//       shippingRateTemplates.find(
//         (template) => template.id === selectedShippingTemplateId
//       ) || shippingRateTemplates[0];
  
//     const newPrices = [];
  
//     data.forEach((item, index) => {
//       try {
//         const startPrice = parseFloat(item.StartPrice);
//         if (isNaN(startPrice)) {
//           throw new Error(`StartPriceが数値ではありません（行 ${index + 1}）`);
//         }
  
//         let totalCost = startPrice;
//         let totalPercentage = 0;
  
//         // カテゴリーの項目を適用
//         if (categories && typeof categories === 'object') {
//           Object.values(categories)
//             .flat()
//             .forEach((categoryItem) => {
//               if (categoryItem.type === 'fixed') {
//                 totalCost += parseFloat(categoryItem.value) || 0;
//               } else if (categoryItem.type === 'percentage') {
//                 totalPercentage += parseFloat(categoryItem.value) || 0;
//               }
//             });
//         }
  
//         // 手数料を追加
//         totalPercentage +=
//           parseFloat(fees.ebayFinalValue || 0) +
//           parseFloat(fees.payoneer || 0) +
//           parseFloat(fees.promotedListing || 0);
//         totalCost += parseFloat(fees.ebayTransactionFee || 0);
  
//         // 送料を追加
//         const shippingRate = selectedShippingTemplate?.shippingRates?.find(
//           (rate) =>
//             startPrice >= parseFloat(rate.minPriceUSD) &&
//             (parseFloat(rate.maxPriceUSD) === Infinity ||
//               startPrice < parseFloat(rate.maxPriceUSD))
//         );
//         if (shippingRate) {
//           totalCost += parseFloat(shippingRate.costUSD) || 0;
//         }
  
//         // 目標利益率を追加
//         totalPercentage += parseFloat(targetProfitMargin) || 0;
  
//         // 販売価格を計算
//         let calculatedPrice = totalCost / (1 - totalPercentage / 100);
//         calculatedPrice = adjustPriceTo99(calculatedPrice);
  
//         // 割引後価格を計算
//         const discount = parseFloat(discountRate) || 0;
//         const discountedPrice = calculatedPrice * (1 - discount / 100);
//         const finalPrice = adjustPriceTo99(discountedPrice);
  
//         // ベストオファー価格を計算
//         const bestOfferAutoAcceptPrice =
//           finalPrice * (bestOfferAutoAcceptPercentage / 100);
//         const minimumBestOfferPrice =
//           finalPrice * (minimumBestOfferPercentage / 100);
  
//         // 利益と利益率を計算
//         const profit = finalPrice - totalCost;
//         const profitMargin = (profit / finalPrice) * 100;
  
//         // 計算結果を保存
//         newPrices.push({
//           originalStartPrice: startPrice.toFixed(2),
//           calculatedPrice: calculatedPrice.toFixed(2),
//           discountedPrice: finalPrice.toFixed(2),
//           bestOfferAutoAcceptPrice: bestOfferAutoAcceptPrice.toFixed(2),
//           minimumBestOfferPrice: minimumBestOfferPrice.toFixed(2),
//           profit: profit.toFixed(2),
//           profitMargin: profitMargin.toFixed(2),
//         });
//       } catch (error) {
//         console.error(
//           `アイテムの処理中にエラーが発生しました（行 ${index + 1}）:`,
//           error
//         );
//         newPrices.push(null); // エラーが発生したアイテムは null を設定
//       }
//     });
  
//     return newPrices;
//   };
  
//   // 価格を99セントに調整する関数
//   const adjustPriceTo99 = (price) => {
//     return Math.floor(price) + 0.99;
//   };
  