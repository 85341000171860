// ImageUrlReplacer.js

import React, { useState, useEffect } from 'react';
import {
  Button,
  TextField,
  Typography,
  Box,
  CircularProgress,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  IconButton,
  Paper,
  Checkbox,
  FormControlLabel,
  Divider,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/material/styles';

function ImageUrlReplacer({ data, setData }) {
  const [startRow, setStartRow] = useState(1);
  const [endRow, setEndRow] = useState(150);
  const [newUrls, setNewUrls] = useState('');
  const [isReplacing, setIsReplacing] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [openPreview, setOpenPreview] = useState(false);
  const [previewData, setPreviewData] = useState([]);
  const [enlargedImages, setEnlargedImages] = useState(null);
  const [skippedRows, setSkippedRows] = useState(new Set());

  const theme = useTheme();

  const calculateMaxEndRow = () => {
    if (data && data.length > 0) {
      return Math.min(startRow + 149, data.length);
    } else {
      return startRow + 149;
    }
  };

  useEffect(() => {
    if (data && data.length > 0) {
      setStartRow(1);
      setEndRow(Math.min(startRow + 149, data.length));
    }
  }, [data]);

  const handleStartRowChange = (e) => {
    const value = parseInt(e.target.value);
    setStartRow(value);

    const maxEndRow = calculateMaxEndRow();
    if (endRow < value || endRow > maxEndRow || isNaN(endRow)) {
      setEndRow(maxEndRow);
    }
  };

  const handleEndRowChange = (e) => {
    const value = parseInt(e.target.value);
    const maxEndRow = calculateMaxEndRow();
    setEndRow(Math.min(Math.max(value, startRow), maxEndRow));
  };

  const generatePreviewData = () => {
    setError('');
    setSuccess('');

    // 行範囲バリデーション
    const isValidRowRange =
      startRow !== '' &&
      endRow !== '' &&
      !isNaN(startRow) &&
      !isNaN(endRow) &&
      endRow >= startRow &&
      data &&
      data.length > 0;
    if (!isValidRowRange) {
      setError('Start Row と End Row を正しく指定してください');
      return;
    }

    const picUrlKey = Object.keys(data[0]).find(
      (key) => key.toLowerCase() === 'picurl'
    );
    if (!picUrlKey) {
      setError('PicURL カラムが見つかりません');
      return;
    }

    const titleKey = Object.keys(data[0]).find(
      (key) => key.toLowerCase() === 'title'
    );
    if (!titleKey) {
      setError('Title カラムが見つかりません');
      return;
    }

    const actualStartRow = parseInt(startRow) - 1;
    const actualEndRow = parseInt(endRow) - 1;

    let newUrlList = newUrls.split('\n').filter((url) => url.trim() !== '');

    // 正規表現を変更：imageとrow,imgの間にハイフンまたはアンダースコアを許容
    // パターン例: image_row6_img1 or image-row6-img1 などにマッチ
    const urlPattern = /image[_-]row(\d+)[_-]img(\d+)/;

    const urlMap = new Map();
    newUrlList.forEach((url) => {
      const match = url.match(urlPattern);
      if (match) {
        const rowIndex = parseInt(match[1]) - 1;
        const imgIndex = parseInt(match[2]) - 1;
        if (!urlMap.has(rowIndex)) {
          urlMap.set(rowIndex, []);
        }
        urlMap.get(rowIndex).push({ imgIndex, url });
      }
    });

    const previewItems = [];
    for (let i = actualStartRow; i <= actualEndRow; i++) {
      const item = data[i];
      if (!item) continue;

      const title = item[titleKey] || '';
      const oldUrls = item[picUrlKey]?.split('|') || [];

      if (urlMap.has(i)) {
        const newImages = urlMap.get(i);
        const updatedUrls = [...oldUrls];
        newImages.forEach(({ imgIndex, url }) => {
          updatedUrls[imgIndex] = url;
        });

        previewItems.push({
          rowIndex: i,
          title,
          oldUrls,
          updatedUrls,
        });
      }
    }

    if (previewItems.length === 0) {
      setError('選択した行に一致するURLが見つかりません');
      return;
    }

    setPreviewData(previewItems);
    setOpenPreview(true);
  };

  const handleReplace = () => {
    setIsReplacing(true);
    setError('');
    setSuccess('');

    try {
      const isValidRowRange =
        startRow !== '' &&
        endRow !== '' &&
        !isNaN(startRow) &&
        !isNaN(endRow) &&
        endRow >= startRow &&
        data &&
        data.length > 0;
      if (!isValidRowRange) {
        throw new Error('Start Row と End Row を正しく指定してください');
      }

      const picUrlKey = Object.keys(data[0]).find(
        (key) => key.toLowerCase() === 'picurl'
      );
      if (!picUrlKey) {
        throw new Error('PicURL カラムが見つかりません');
      }

      const actualStartRow = parseInt(startRow) - 1;
      const actualEndRow = parseInt(endRow) - 1;
      let newUrlList = newUrls.split('\n').filter((url) => url.trim() !== '');
      const urlPattern = /image[_-]row(\d+)[_-]img(\d+)/;

      const urlMap = new Map();
      newUrlList.forEach((url) => {
        const match = url.match(urlPattern);
        if (match) {
          const rowIndex = parseInt(match[1]) - 1;
          const imgIndex = parseInt(match[2]) - 1;
          if (!urlMap.has(rowIndex)) {
            urlMap.set(rowIndex, []);
          }
          urlMap.get(rowIndex).push({ imgIndex, url });
        }
      });

      const updatedData = [...data];

      for (let i = actualStartRow; i <= actualEndRow; i++) {
        if (!skippedRows.has(i)) {
          const item = { ...updatedData[i] };
          if (!item) continue;

          if (urlMap.has(i)) {
            const currentUrls = item[picUrlKey]?.split('|') || [];
            const newImages = urlMap.get(i);
            newImages.forEach(({ imgIndex, url }) => {
              currentUrls[imgIndex] = url;
            });
            item[picUrlKey] = currentUrls.join('|');
            updatedData[i] = item;
          }
        }
      }

      setData(updatedData);
      setSuccess('URLの置換が成功しました');
      setOpenPreview(false);
    } catch (err) {
      console.error('エラー:', err);
      setError(`置換に失敗しました: ${err.message}`);
    } finally {
      setIsReplacing(false);
    }
  };

  const handleImageClick = (oldUrl, newUrl) => {
    setEnlargedImages({ oldUrl, newUrl });
  };

  const handleSkipToggle = (rowIndex) => {
    setSkippedRows((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(rowIndex)) {
        newSet.delete(rowIndex);
      } else {
        newSet.add(rowIndex);
      }
      return newSet;
    });
  };

  return (
    <Box
      sx={{
        mt: 0,
        p: 1,
        backgroundColor: theme.palette.background.paper,
        borderRadius: theme.shape.borderRadius,
      }}
    >
      <Typography variant="h6" gutterBottom>
        画像URLの一括変更
      </Typography>
      <Typography variant="body2" sx={{ mb: 2 }}>
        画像URLリンクをそのまま貼り付けてください。URLのファイル名に "image-rowX-imgY" または "image_rowX_imgY"
        などの形式で行番号(X)と画像番号(Y)を含めると、その画像が変更されます。
        一度に最大150行まで処理できます。
      </Typography>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} sm={3}>
          <TextField
            label="Start Row"
            type="number"
            value={startRow}
            onChange={handleStartRowChange}
            fullWidth
            InputProps={{ inputProps: { min: 1 } }}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            label="End Row"
            type="number"
            value={endRow}
            onChange={handleEndRowChange}
            fullWidth
            InputProps={{ inputProps: { min: startRow, max: calculateMaxEndRow() } }}
          />
        </Grid>
      </Grid>
      <TextField
        label="新しいURL（1行に1つずつ）"
        multiline
        rows={6}
        value={newUrls}
        onChange={(e) => setNewUrls(e.target.value)}
        fullWidth
        sx={{ my: 2 }}
        placeholder={`例:\nhttps://example.com/image_row1_img1.jpg\nhttps://example.com/image-row2-img1.jpg`}
      />
      <Box sx={{ mt: 0 }}>
        <Button
          variant="outlined"
          onClick={generatePreviewData}
          sx={{ mr: 2 }}
        >
          プレビュー
        </Button>
        <Button
          variant="contained"
          onClick={handleReplace}
          disabled={
            isReplacing ||
            !data ||
            data.length === 0 ||
            previewData.length === 0
          }
        >
          {isReplacing ? (
            <CircularProgress size={24} />
          ) : (
            '画像URLを一括変更する'
          )}
        </Button>
      </Box>
      {error && (
        <Alert severity="error" sx={{ mt: 2 }}>
          {error}
        </Alert>
      )}
      {success && (
        <Alert severity="success" sx={{ mt: 2 }}>
          {success}
        </Alert>
      )}

      {/* プレビューダイアログ */}
      <Dialog
        open={openPreview}
        onClose={() => setOpenPreview(false)}
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle>
          画像プレビュー
          <IconButton
            aria-label="close"
            onClick={() => setOpenPreview(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {previewData.map((item, index) => (
            <Box key={index} sx={{ mb: 4 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4} md={3}>
                  <Typography variant="h6">行 {item.rowIndex + 1}</Typography>
                  <Typography variant="subtitle1">{item.title}</Typography>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={!skippedRows.has(item.rowIndex)}
                        onChange={() => handleSkipToggle(item.rowIndex)}
                      />
                    }
                    label="この商品の画像を変更する"
                  />
                </Grid>
                <Grid item xs={12} sm={8} md={9}>
                  <Typography variant="subtitle1">変更前の画像</Typography>
                  <Grid container spacing={1}>
                    {item.oldUrls.map((url, idx) => (
                      <Grid item xs={4} sm={3} md={2} key={idx}>
                        <Paper
                          elevation={3}
                          sx={{ p: 1, textAlign: 'center', cursor: 'pointer' }}
                          onClick={() =>
                            handleImageClick(url, item.updatedUrls[idx])
                          }
                        >
                          <img
                            src={url}
                            alt={`Old image ${idx + 1}`}
                            style={{
                              maxWidth: '100%',
                              maxHeight: '100px',
                              objectFit: 'contain'
                            }}
                          />
                          <Typography variant="caption">img{idx + 1}</Typography>
                        </Paper>
                      </Grid>
                    ))}
                  </Grid>
                  <Typography variant="subtitle1" sx={{ mt: 2 }}>
                    変更後の画像
                  </Typography>
                  <Grid container spacing={1}>
                    {item.updatedUrls.map((url, idx) => (
                      <Grid item xs={4} sm={3} md={2} key={idx}>
                        <Paper
                          elevation={3}
                          sx={{ p: 1, textAlign: 'center', cursor: 'pointer' }}
                          onClick={() =>
                            handleImageClick(item.oldUrls[idx], url)
                          }
                        >
                          <img
                            src={url}
                            alt={`Updated image ${idx + 1}`}
                            style={{
                              maxWidth: '100%',
                              maxHeight: '100px',
                              objectFit: 'contain'
                            }}
                          />
                          <Typography variant="caption">img{idx + 1}</Typography>
                        </Paper>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
              <Divider sx={{ mt: 2 }} />
            </Box>
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenPreview(false)}>閉じる</Button>
          <Button
            onClick={handleReplace}
            color="primary"
            variant="contained"
            disabled={isReplacing}
          >
            {isReplacing ? (
              <CircularProgress size={24} />
            ) : (
              '画像URLを一括変更する'
            )}
          </Button>
        </DialogActions>
      </Dialog>

      {/* 拡大画像プレビュー */}
      <Dialog
        open={!!enlargedImages}
        onClose={() => setEnlargedImages(null)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          画像プレビュー
          <IconButton
            aria-label="close"
            onClick={() => setEnlargedImages(null)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: theme.palette.grey[500]
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant="subtitle1" align="center">
                変更前
              </Typography>
              <Paper
                elevation={3}
                sx={{ p: 1, textAlign: 'center', bgcolor: '#f5f5f5' }}
              >
                <img
                  src={enlargedImages?.oldUrl}
                  alt="現在の画像"
                  style={{
                    maxWidth: '100%',
                    maxHeight: '500px',
                    objectFit: 'contain'
                  }}
                />
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle1" align="center">
                変更後
              </Typography>
              <Paper
                elevation={3}
                sx={{ p: 1, textAlign: 'center', bgcolor: '#f5f5f5' }}
              >
                <img
                  src={enlargedImages?.newUrl}
                  alt="変更後の画像"
                  style={{
                    maxWidth: '100%',
                    maxHeight: '500px',
                    objectFit: 'contain'
                  }}
                />
              </Paper>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </Box>
  );
}

export default ImageUrlReplacer;





// // ImageUrlReplacer.js

// import React, { useState, useEffect } from 'react';
// import {
//   Button,
//   TextField,
//   Typography,
//   Box,
//   CircularProgress,
//   Alert,
//   Dialog,
//   DialogTitle,
//   DialogContent,
//   DialogActions,
//   Grid,
//   IconButton,
//   Paper,
//   Checkbox,
//   FormControlLabel,
//   Divider,
//   Tooltip,
// } from '@mui/material';
// import CloseIcon from '@mui/icons-material/Close';
// import { useTheme } from '@mui/material/styles';

// function ImageUrlReplacer({ data, setData }) {
//   const [startRow, setStartRow] = useState(1);
//   const [endRow, setEndRow] = useState(150);
//   const [newUrls, setNewUrls] = useState('');
//   const [isReplacing, setIsReplacing] = useState(false);
//   const [error, setError] = useState('');
//   const [success, setSuccess] = useState('');
//   const [openPreview, setOpenPreview] = useState(false);
//   const [previewData, setPreviewData] = useState([]);
//   const [enlargedImages, setEnlargedImages] = useState(null);
//   const [skippedRows, setSkippedRows] = useState(new Set());

//   const theme = useTheme();

//   // maxEndRow を計算する関数
//   const calculateMaxEndRow = () => {
//     if (data && data.length > 0) {
//       return Math.min(startRow + 149, data.length);
//     } else {
//       return startRow + 149;
//     }
//   };

//   useEffect(() => {
//     if (data && data.length > 0) {
//       setStartRow(1);
//       setEndRow(Math.min(startRow + 149, data.length));
//     }
//   }, [data]);

//   const handleStartRowChange = (e) => {
//     const value = parseInt(e.target.value);
//     setStartRow(value);

//     // maxEndRow を再計算
//     const maxEndRow = calculateMaxEndRow();
//     if (endRow < value || endRow > maxEndRow || isNaN(endRow)) {
//       setEndRow(maxEndRow);
//     }
//   };

//   const handleEndRowChange = (e) => {
//     const value = parseInt(e.target.value);
//     const maxEndRow = calculateMaxEndRow();
//     setEndRow(Math.min(Math.max(value, startRow), maxEndRow));
//   };

//   const generatePreviewData = () => {
//     setError('');
//     setSuccess('');

//     // 行範囲のバリデーション
//     const isValidRowRange =
//       startRow !== '' &&
//       endRow !== '' &&
//       !isNaN(startRow) &&
//       !isNaN(endRow) &&
//       endRow >= startRow &&
//       data &&
//       data.length > 0;
//     if (!isValidRowRange) {
//       setError('Start Row と End Row を正しく指定してください');
//       return;
//     }

//     const picUrlKey = Object.keys(data[0]).find(
//       (key) => key.toLowerCase() === 'picurl'
//     );
//     if (!picUrlKey) {
//       setError('PicURL カラムが見つかりません');
//       return;
//     }

//     // 商品タイトルのキーを取得
//     const titleKey = Object.keys(data[0]).find(
//       (key) => key.toLowerCase() === 'title'
//     );
//     if (!titleKey) {
//       setError('Title カラムが見つかりません');
//       return;
//     }

//     const actualStartRow = parseInt(startRow) - 1; // インデックスは0から開始
//     const actualEndRow = parseInt(endRow) - 1;

//     let newUrlList = newUrls.split('\n').filter((url) => url.trim() !== '');

//     // 画像番号とURLのマップを作成
//     const urlMap = new Map();
//     newUrlList.forEach((url) => {
//       // 正規表現を修正
//       const match = url.match(/image-row(\d+)-img(\d+)/);
//       if (match) {
//         const rowIndex = parseInt(match[1]) - 1; // インデックスは0から開始
//         const imgIndex = parseInt(match[2]) - 1; // インデックスは0から開始
//         if (!urlMap.has(rowIndex)) {
//           urlMap.set(rowIndex, []);
//         }
//         urlMap.get(rowIndex).push({ imgIndex, url });
//       }
//     });

//     const previewItems = [];
//     for (let i = actualStartRow; i <= actualEndRow; i++) {
//       const item = data[i];
//       if (!item) continue; // データがない行はスキップ

//       const title = item[titleKey] || '';
//       const oldUrls = item[picUrlKey]?.split('|') || [];

//       if (urlMap.has(i)) {
//         const newImages = urlMap.get(i);
//         // oldUrlsのコピーを作成
//         const updatedUrls = [...oldUrls];

//         newImages.forEach(({ imgIndex, url }) => {
//           updatedUrls[imgIndex] = url; // imgIndexは0から始まる
//         });

//         previewItems.push({
//           rowIndex: i,
//           title,
//           oldUrls,
//           updatedUrls,
//         });
//       }
//     }

//     if (previewItems.length === 0) {
//       setError('選択した行に一致するURLが見つかりません');
//       return;
//     }

//     setPreviewData(previewItems);
//     setOpenPreview(true);
//   };

//   const handleReplace = () => {
//     setIsReplacing(true);
//     setError('');
//     setSuccess('');

//     try {
//       // 行範囲のバリデーション
//       const isValidRowRange =
//         startRow !== '' &&
//         endRow !== '' &&
//         !isNaN(startRow) &&
//         !isNaN(endRow) &&
//         endRow >= startRow &&
//         data &&
//         data.length > 0;
//       if (!isValidRowRange) {
//         throw new Error('Start Row と End Row を正しく指定してください');
//       }

//       const picUrlKey = Object.keys(data[0]).find(
//         (key) => key.toLowerCase() === 'picurl'
//       );
//       if (!picUrlKey) {
//         throw new Error('PicURL カラムが見つかりません');
//       }

//       const actualStartRow = parseInt(startRow) - 1; // インデックスは0から開始
//       const actualEndRow = parseInt(endRow) - 1;

//       let newUrlList = newUrls.split('\n').filter((url) => url.trim() !== '');

//       // 画像番号とURLのマップを作成
//       const urlMap = new Map();
//       newUrlList.forEach((url) => {
//         // 正規表現を修正
//         const match = url.match(/image-row(\d+)-img(\d+)/);
//         if (match) {
//           const rowIndex = parseInt(match[1]) - 1; // インデックスは0から開始
//           const imgIndex = parseInt(match[2]) - 1; // インデックスは0から開始
//           if (!urlMap.has(rowIndex)) {
//             urlMap.set(rowIndex, []);
//           }
//           urlMap.get(rowIndex).push({ imgIndex, url });
//         }
//       });

//       const updatedData = [...data];

//       for (let i = actualStartRow; i <= actualEndRow; i++) {
//         if (!skippedRows.has(i)) {
//           const item = { ...updatedData[i] };
//           if (!item) continue; // データがない行はスキップ

//           if (urlMap.has(i)) {
//             const currentUrls = item[picUrlKey]?.split('|') || [];
//             const newImages = urlMap.get(i);

//             newImages.forEach(({ imgIndex, url }) => {
//               currentUrls[imgIndex] = url; // imgIndexは0から始まる
//             });

//             item[picUrlKey] = currentUrls.join('|');
//             updatedData[i] = item;
//           }
//         }
//       }

//       setData(updatedData);
//       setSuccess('URLの置換が成功しました');
//       setOpenPreview(false);
//     } catch (err) {
//       console.error('エラー:', err);
//       setError(`置換に失敗しました: ${err.message}`);
//     } finally {
//       setIsReplacing(false);
//     }
//   };

//   const handleImageClick = (oldUrl, newUrl) => {
//     setEnlargedImages({ oldUrl, newUrl });
//   };

//   const handleSkipToggle = (rowIndex) => {
//     setSkippedRows((prev) => {
//       const newSet = new Set(prev);
//       if (newSet.has(rowIndex)) {
//         newSet.delete(rowIndex);
//       } else {
//         newSet.add(rowIndex);
//       }
//       return newSet;
//     });
//   };

//   return (
//     <Box
//       sx={{
//         mt: 0,
//         p: 1,
//         backgroundColor: theme.palette.background.paper,
//         borderRadius: theme.shape.borderRadius,
//       }}
//     >
//       <Typography variant="h6" gutterBottom>
//         画像URLの一括変更
//       </Typography>
//       <Typography variant="body2" sx={{ mb: 2 }}>
//         画像URLリンクをそのまま貼り付けてください。URLのファイル名に含まれる "image-rowX-imgY" のXが行番号、Yが画像番号と一致する画像が変更されます。
//         一度に最大150行まで処理できます。
//       </Typography>
//       <Grid container spacing={2} alignItems="center">
//         <Grid item xs={12} sm={3}>
//           <TextField
//             label="Start Row"
//             type="number"
//             value={startRow}
//             onChange={handleStartRowChange}
//             fullWidth
//             InputProps={{ inputProps: { min: 1 } }}
//           />
//         </Grid>
//         <Grid item xs={12} sm={3}>
//           <TextField
//             label="End Row"
//             type="number"
//             value={endRow}
//             onChange={handleEndRowChange}
//             fullWidth
//             InputProps={{ inputProps: { min: startRow, max: calculateMaxEndRow() } }}
//           />
//         </Grid>
//       </Grid>
//       <TextField
//         label="新しいURL（1行に1つずつ）"
//         multiline
//         rows={6}
//         value={newUrls}
//         onChange={(e) => setNewUrls(e.target.value)}
//         fullWidth
//         sx={{ my: 2 }}
//         placeholder={`例:\nhttps://example.com/image-row1-img1.jpg\nhttps://example.com/image-row1-img2.jpg`}
//       />
//       <Box sx={{ mt: 0 }}>
//         <Button
//           variant="outlined"
//           onClick={generatePreviewData}
//           sx={{ mr: 2 }}
//         >
//           プレビュー
//         </Button>
//         <Button
//           variant="contained"
//           onClick={handleReplace}
//           disabled={
//             isReplacing ||
//             !data ||
//             data.length === 0 ||
//             previewData.length === 0
//           }
//         >
//           {isReplacing ? (
//             <CircularProgress size={24} />
//           ) : (
//             '画像URLを一括変更する'
//           )}
//         </Button>
//       </Box>
//       {error && (
//         <Alert severity="error" sx={{ mt: 2 }}>
//           {error}
//         </Alert>
//       )}
//       {success && (
//         <Alert severity="success" sx={{ mt: 2 }}>
//           {success}
//         </Alert>
//       )}

//       {/* プレビューダイアログ */}
//       <Dialog
//         open={openPreview}
//         onClose={() => setOpenPreview(false)}
//         maxWidth="lg"
//         fullWidth
//       >
//         <DialogTitle>
//           画像プレビュー
//           <IconButton
//             aria-label="close"
//             onClick={() => setOpenPreview(false)}
//             sx={{
//               position: 'absolute',
//               right: 8,
//               top: 8,
//               color: theme.palette.grey[500],
//             }}
//           >
//             <CloseIcon />
//           </IconButton>
//         </DialogTitle>
//         <DialogContent>
//           {previewData.map((item, index) => (
//             <Box key={index} sx={{ mb: 4 }}>
//               <Grid container spacing={2}>
//                 <Grid item xs={12} sm={4} md={3}>
//                   <Typography variant="h6">行 {item.rowIndex + 1}</Typography>
//                   <Typography variant="subtitle1">{item.title}</Typography>
//                   <FormControlLabel
//                     control={
//                       <Checkbox
//                         checked={!skippedRows.has(item.rowIndex)}
//                         onChange={() => handleSkipToggle(item.rowIndex)}
//                       />
//                     }
//                     label="この商品の画像を変更する"
//                   />
//                 </Grid>
//                 <Grid item xs={12} sm={8} md={9}>
//                   <Typography variant="subtitle1">変更前の画像</Typography>
//                   <Grid container spacing={1}>
//                     {item.oldUrls.map((url, idx) => (
//                       <Grid item xs={4} sm={3} md={2} key={idx}>
//                         <Paper
//                           elevation={3}
//                           sx={{ p: 1, textAlign: 'center', cursor: 'pointer' }}
//                           onClick={() =>
//                             handleImageClick(url, item.updatedUrls[idx])
//                           }
//                         >
//                           <img
//                             src={url}
//                             alt={`Old image ${idx + 1}`}
//                             style={{
//                               maxWidth: '100%',
//                               maxHeight: '100px',
//                               objectFit: 'contain'
//                             }}
//                           />
//                           <Typography variant="caption">img{idx + 1}</Typography>
//                         </Paper>
//                       </Grid>
//                     ))}
//                   </Grid>
//                   <Typography variant="subtitle1" sx={{ mt: 2 }}>
//                     変更後の画像
//                   </Typography>
//                   <Grid container spacing={1}>
//                     {item.updatedUrls.map((url, idx) => (
//                       <Grid item xs={4} sm={3} md={2} key={idx}>
//                         <Paper
//                           elevation={3}
//                           sx={{ p: 1, textAlign: 'center', cursor: 'pointer' }}
//                           onClick={() =>
//                             handleImageClick(item.oldUrls[idx], url)
//                           }
//                         >
//                           <img
//                             src={url}
//                             alt={`Updated image ${idx + 1}`}
//                             style={{
//                               maxWidth: '100%',
//                               maxHeight: '100px',
//                               objectFit: 'contain'
//                             }}
//                           />
//                           <Typography variant="caption">img{idx + 1}</Typography>
//                         </Paper>
//                       </Grid>
//                     ))}
//                   </Grid>
//                 </Grid>
//               </Grid>
//               <Divider sx={{ mt: 2 }} />
//             </Box>
//           ))}
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => setOpenPreview(false)}>閉じる</Button>
//           <Button
//             onClick={handleReplace}
//             color="primary"
//             variant="contained"
//             disabled={isReplacing}
//           >
//             {isReplacing ? (
//               <CircularProgress size={24} />
//             ) : (
//               '画像URLを一括変更する'
//             )}
//           </Button>
//         </DialogActions>
//       </Dialog>

//       {/* 画像を拡大表示するダイアログ */}
//       <Dialog
//         open={!!enlargedImages}
//         onClose={() => setEnlargedImages(null)}
//         maxWidth="md"
//         fullWidth
//       >
//         <DialogTitle>
//           画像プレビュー
//           <IconButton
//             aria-label="close"
//             onClick={() => setEnlargedImages(null)}
//             sx={{
//               position: 'absolute',
//               right: 8,
//               top: 8,
//               color: theme.palette.grey[500]
//             }}
//           >
//             <CloseIcon />
//           </IconButton>
//         </DialogTitle>
//         <DialogContent>
//           <Grid container spacing={2}>
//             <Grid item xs={6}>
//               <Typography variant="subtitle1" align="center">
//                 変更前
//               </Typography>
//               <Paper
//                 elevation={3}
//                 sx={{ p: 1, textAlign: 'center', bgcolor: '#f5f5f5' }}
//               >
//                 <img
//                   src={enlargedImages?.oldUrl}
//                   alt="現在の画像"
//                   style={{
//                     maxWidth: '100%',
//                     maxHeight: '500px',
//                     objectFit: 'contain'
//                   }}
//                 />
//               </Paper>
//             </Grid>
//             <Grid item xs={6}>
//               <Typography variant="subtitle1" align="center">
//                 変更後
//               </Typography>
//               <Paper
//                 elevation={3}
//                 sx={{ p: 1, textAlign: 'center', bgcolor: '#f5f5f5' }}
//               >
//                 <img
//                   src={enlargedImages?.newUrl}
//                   alt="変更後の画像"
//                   style={{
//                     maxWidth: '100%',
//                     maxHeight: '500px',
//                     objectFit: 'contain'
//                   }}
//                 />
//               </Paper>
//             </Grid>
//           </Grid>
//         </DialogContent>
//       </Dialog>
//     </Box>
//   );
// }

// export default ImageUrlReplacer;