// OpenAIDescriptionGenerator.js

import React, { useState, useEffect, useCallback, useMemo } from 'react';
import axios from 'axios';
import {
  Paper,
  Button,
  Box,
  Grid,
  Typography,
  Snackbar,
  Alert,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CachedIcon from '@mui/icons-material/Cached';
import PQueue from 'p-queue';
import OpenAIDescriptionDialog from './OpenAIDescriptionDialog';
import designTemplates from './designTemplates';

import { applyFilters } from './filters';
import { generateProductFeatures as generateLowCostFeatures } from './LowCostAIGenerator';

// sanitizeDescription と generateFormattedDescription をエクスポート
export const sanitizeDescription = (description) => {
  return description.replace(/<\/?(meta|link)(.|\s)*?>/gi, '');
};

export const cleanHTML = (html) => {
  let cleanedHtml = html.replace(/<style[^>]*>[\s\S]*?<\/style>/gi, '');
  cleanedHtml = cleanedHtml.replace(/\sclass="[^"]*"/gi, '');
  cleanedHtml = cleanedHtml.replace(/\s+/g, ' ').trim();
  cleanedHtml = cleanedHtml.replace(/>\s+</g, '><');
  return cleanedHtml;
};

export const generateFormattedDescription = (
  title,
  features,
  description,
  specifications,
  selectedTemplate
) => {
  const template = designTemplates[selectedTemplate];
  const html = template.generateHTML(title, features, description, specifications);
  const cleanedHtml = cleanHTML(html);
  return cleanedHtml;
};

const BATCH_SIZE = 5;
const INITIAL_RETRY_DELAY = 1000;
const MAX_RETRIES = 5;
const CONCURRENCY = 2;
const INTERVAL = 1000;
const INTERVAL_CAP = 5;

const OpenAIDescriptionGenerator = ({ data, setData, apiKey, user }) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [lowCostLoading, setLowCostLoading] = useState(false);
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const [products, setProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [focusedProduct, setFocusedProduct] = useState(null);
  const [showPreviewDialog, setShowPreviewDialog] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('info');
  const [previewProduct, setPreviewProduct] = useState(null);
  const [selectedTemplate, setSelectedTemplate] = useState('professional');
  const [progress, setProgress] = useState(0);
  const [customPromptDialogOpen, setCustomPromptDialogOpen] = useState(false);
  const [resetDialogOpen, setResetDialogOpen] = useState(false);

  // フィルタリングオプションのステート
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [customFilterInput, setCustomFilterInput] = useState('');
  const [customFilters, setCustomFilters] = useState([]);

  // テキスト変換のステート
  const [sentencesToDelete, setSentencesToDelete] = useState([]);
  const [wordsToReplace, setWordsToReplace] = useState([]);

  // 必須キーワードのステート
  const [mandatoryKeywords, setMandatoryKeywords] = useState([]);

  // 画像解析の説明を使用するかどうかのステート
  const [useImageDescription, setUseImageDescription] = useState(false);

  const queue = useMemo(
    () =>
      new PQueue({
        concurrency: CONCURRENCY,
        interval: INTERVAL,
        intervalCap: INTERVAL_CAP,
      }),
    []
  );

  const itemsPerPageOptions = [50, 100, 'All'];
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(itemsPerPageOptions[0]);

  useEffect(() => {
    if (open) {
      initializeProducts();
    }
  }, [open, useImageDescription]);

  const handleOpen = () => {
    if (!data || data.length === 0) {
      setError('データがありません。CSVファイルをインポートしてください。');
      setSnackbarMessage('データがありません');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      return;
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setFocusedProduct(null);
  };

  // 商品データを初期化する関数
  const initializeProducts = () => {
    const headers = Object.keys(data[0]);
    const titleIndex = headers.findIndex((header) => header.toLowerCase() === 'title');
    const jpDescIndex = headers.findIndex((header) => header.toLowerCase() === 'jp_desc');
    const jpImageDescIndex = headers.findIndex((header) => header.toLowerCase() === 'jp_image_description');
    const descriptionIndex = headers.findIndex((header) => header.toLowerCase() === 'description');

    if (titleIndex === -1 || descriptionIndex === -1) {
      setError('必要な列（Title, Description）が見つかりません');
      return;
    }

    // C:~~で始まるカラムを取得
    const cColumns = headers.filter((header) => header.startsWith('C:'));

    const productData = data.map((row, index) => {
      const productId = index;

      // ここで使用する説明文を選択
      let originalJpDesc = '';
      if (useImageDescription) {
        originalJpDesc = row['jp_image_description'] || '';
      } else {
        originalJpDesc = row['jp_desc'] || '';
      }

      // Specificationsのためのキーと値のパラメータを収集
      const specifications = {};

      cColumns.forEach((header) => {
        const value = row[header];
        if (value && value !== 'NA') {
          specifications[header] = value;
        }
      });

      const filteredJpDesc = applyFilters(originalJpDesc, selectedFilters, customFilters);

      return {
        id: productId,
        title: row['Title'] || row['title'] || '',
        jpDesc: originalJpDesc,
        editableJpDesc: filteredJpDesc,
        originalDescription: row['Description'] || row['description'] || '',
        description: row['Description'] || row['description'] || '',
        generatedFeatures: '',
        editableGeneratedFeatures: '',
        updatedDescription: '',
        selected: false,
        isUpdated: false,
        editableFields: {},
        specifications,
      };
    });

    setProducts(productData);
    setSelectedProducts([]);
    setSnackbarMessage('データを正常に読み込みました');
    setSnackbarSeverity('success');
    setSnackbarOpen(true);
  };

  const handleFilterChange = useCallback(
    (label) => {
      setSelectedFilters((prev) =>
        prev.includes(label) ? prev.filter((item) => item !== label) : [...prev, label]
      );
    },
    []
  );

  const applyFiltersToProducts = useCallback(() => {
    setProducts((prevProducts) =>
      prevProducts.map((product) => {
        const filteredDesc = applyFilters(product.jpDesc, selectedFilters, customFilters);
        return { ...product, editableJpDesc: filteredDesc };
      })
    );
  }, [selectedFilters, customFilters]);

  useEffect(() => {
    applyFiltersToProducts();
  }, [selectedFilters, customFilters, applyFiltersToProducts]);

  const handleAddCustomFilter = () => {
    if (customFilterInput.trim() !== '') {
      setCustomFilters([...customFilters, customFilterInput.trim()]);
      setCustomFilterInput('');
    }
  };

  const handleDeleteCustomFilter = (index) => {
    const updatedFilters = [...customFilters];
    updatedFilters.splice(index, 1);
    setCustomFilters(updatedFilters);
  };

  useEffect(() => {
    applyFiltersToProducts();
  }, [customFilters, applyFiltersToProducts]);

  const handleEditJpDesc = useCallback((productId, newJpDesc) => {
    setProducts((prevProducts) =>
      prevProducts.map((product) =>
        product.id === productId ? { ...product, editableJpDesc: newJpDesc } : product
      )
    );
  }, []);

  const generateProductFeatures = async () => {
    if (!apiKey) {
      setError('OpenAI APIキーが設定されていません');
      setSnackbarMessage('OpenAI APIキーが必要です');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      return;
    }

    if (selectedProducts.length === 0) {
      setError('少なくとも一つの商品を選択してください');
      setSnackbarMessage('商品が選択されていません');
      setSnackbarSeverity('warning');
      setSnackbarOpen(true);
      return;
    }

    setLoading(true);
    setError('');
    setMessage('');
    setProgress(0);

    const selectedProductsData = products.filter(
      (product) => selectedProducts.includes(product.id) && !product.isUpdated
    );
    let processedCount = 0;

    if (selectedProductsData.length === 0) {
      setLoading(false);
      setMessage('選択された商品はすでに更新されています');
      setSnackbarMessage('選択された商品はすでに更新されています');
      setSnackbarSeverity('info');
      setSnackbarOpen(true);
      return;
    }

    for (let i = 0; i < selectedProductsData.length; i += BATCH_SIZE) {
      const batch = selectedProductsData.slice(i, i + BATCH_SIZE);
      try {
        const updatedBatch = await processBatch(batch);
        setProducts((prevProducts) => {
          const newProducts = [...prevProducts];
          updatedBatch.forEach((updatedProduct) => {
            const index = newProducts.findIndex((p) => p.id === updatedProduct.id);
            if (index !== -1) {
              newProducts[index] = updatedProduct;
            }
          });
          return newProducts;
        });
        processedCount += batch.length;
        setProgress((processedCount / selectedProductsData.length) * 100);
      } catch (err) {
        console.error('バッチ処理エラー:', err);
        setError(`バッチ処理に失敗しました: ${err.message}`);
        setSnackbarMessage(`エラー: ${err.message}`);
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
        break;
      }
    }

    setLoading(false);
    setMessage('商品特徴の生成が完了しました');
    setSnackbarMessage('商品特徴の生成が完了しました');
    setSnackbarSeverity('success');
    setSnackbarOpen(true);
    setShowPreviewDialog(true);
  };

  const processBatch = async (batch) => {
    return await Promise.all(
      batch.map(async (product) => {
        let generatedFeatures;
        const productId = product.id;

        // APIコールを行い、説明を生成
        generatedFeatures = await generateFeaturesWithRetry(product.editableJpDesc);

        const sanitizedDescription = sanitizeDescription(product.originalDescription);

        const updatedDescription = generateFormattedDescription(
          product.title,
          generatedFeatures,
          sanitizedDescription,
          product.specifications,
          selectedTemplate
        );

        return {
          ...product,
          generatedFeatures,
          editableGeneratedFeatures: generatedFeatures,
          updatedDescription,
          isUpdated: true,
        };
      })
    );
  };

  const generateFeaturesWithRetry = async (jpDesc, retryCount = 0) => {
    try {
      return await queue.add(() => generateFeatures(jpDesc));
    } catch (error) {
      if (error.response && error.response.status === 429 && retryCount < MAX_RETRIES) {
        const retryAfter = error.response.headers['retry-after'];
        const delay = retryAfter
          ? parseInt(retryAfter) * 1000
          : INITIAL_RETRY_DELAY * Math.pow(2, retryCount);
        const jitter = Math.random() * 1000;
        await new Promise((resolve) => setTimeout(resolve, delay + jitter));
        return generateFeaturesWithRetry(jpDesc, retryCount + 1);
      }
      throw error;
    }
  };

  const generateFeatures = async (jpDesc, maxTokens = 200) => {
    const mandatoryKeywordsPrompt =
      mandatoryKeywords.length > 0
        ? `Also, be sure to include the following keywords: ${mandatoryKeywords.join(', ')}.`
        : '';

    const response = await axios.post(
      'https://api.openai.com/v1/chat/completions',
      {
        model: 'gpt-4o-mini',
        messages: [
          {
            role: 'system',
            content: `You are eBay's AI assistant specializing in product description optimization. Create effective English product descriptions based on the given Japanese product information. ${mandatoryKeywordsPrompt}

Please provide accurate information to buyers, including the following elements:

1. **Product Description** (if applicable)
   - Brand name (if applicable)
   - Product type
   - Model or product name
2. **Detailed Features** (if applicable)
   - Color, material, size
3. **Condition of the Item** (if applicable)
   - Details of usage, scratches, stains, etc. (if used)

**Additionally, extract the following item specifics from the product information and provide them as key-value pairs:**

**Output the item specifics in the following format:**

Item Specifics:
[Item Specific Key]: [value]
...

**Notes:**
- Ensure that the item specifics are accurate based on the product information.
- If an item specific is not available or unclear, omit it.
- No content other than the above will be output.
- Additional information is not required.
- Do not output information related to shipping or options.
- Use clear and concise English.
- Use bullet points to ensure readability.
- If information is unclear or ambiguous, omit the item.
- Use only reliable information and do not include speculative or uncertain information.
- If there is nothing in the Japanese product description, please do not output anything in particular. Only the title is acceptable.

Analyze the given product information and follow the guidelines above to generate the best possible product description in English. Try to provide a detailed and engaging description that will help buyers make a purchase decision. Stay within the specified token count limit.`,
          },
          {
            role: 'user',
            content: `以下の日本語の商品説明を分析し、eBayの商品説明として最適化された英語の説明を生成してください。また、指定された item specifics を抽出してください。出力は${maxTokens}トークン以内に収めてください：「${jpDesc}」`,
          },
        ],
        max_tokens: maxTokens,
        temperature: 0.1,
      },
      {
        headers: {
          Authorization: `Bearer ${apiKey}`,
          'Content-Type': 'application/json',
        },
      }
    );

    return response.data.choices[0].message.content.trim();
  };

//   const generateFeatures = async (jpDesc, maxTokens = 200) => {
//     const mandatoryKeywordsPrompt =
//       mandatoryKeywords.length > 0
//         ? `Also, be sure to include the following keywords: ${mandatoryKeywords.join(', ')}.`
//         : '';

//     const response = await axios.post(
//       'https://api.openai.com/v1/chat/completions',
//       {
//         model: 'gpt-4o-2024-08-06',
//         messages: [
//           {
//             role: 'system',
//             content: `You are eBay's AI assistant specializing in product description optimization. Create effective English product descriptions based on the given Japanese product information. ${mandatoryKeywordsPrompt}
// Please provide accurate information to buyers, including the following elements

// 1. Product Description (if applicable)
//    - Brand name (if applicable)
//    - Product type
//    - Model or product name
// 2. Detailed Features (if applicable)
//    - Color, material, size
// 3. Condition of the Item (if applicable)
//    - Details of usage, scratches, stains, etc. (if used)

// Notes:
// - No content other than the above will be output.
// - Additional information is not required.
// - Do not output information related to shipping or options.
// - Use clear and concise English.
// - Use bullet points to ensure readability.
// - If information is unclear or ambiguous, omit the item.
// - Use only reliable information and do not include speculative or uncertain information.
// - If there is nothing in the Japanese product description, please do not output anything in particular. Only the title is acceptable.

// Analyze the given product information and follow the guidelines above to generate the best possible product description in English. Try to provide a detailed and engaging description that will help buyers make a purchase decision. Stay within the specified token count limit.`,
//           },
//           {
//             role: 'user',
//             content: `以下の日本語の商品説明を分析し、eBayの商品説明として最適化された英語の説明を生成してください。出力は${maxTokens}トークン以内に収めてください：「${jpDesc}」`,
//           },
//         ],
//         max_tokens: maxTokens,
//         temperature: 0.1,
//       },
//       {
//         headers: {
//           Authorization: `Bearer ${apiKey}`,
//           'Content-Type': 'application/json',
//         },
//       }
//     );

//     return response.data.choices[0].message.content.trim();
//   };

  const handleSelectAll = useCallback(
    (event) => {
      const updatedProducts = products.map((product) => ({
        ...product,
        selected: event.target.checked,
      }));
      setProducts(updatedProducts);
      setSelectedProducts(event.target.checked ? updatedProducts.map((p) => p.id) : []);
    },
    [products]
  );

  const handleSelectProduct = useCallback(
    (productId) => {
      const updatedProducts = products.map((product) =>
        product.id === productId ? { ...product, selected: !product.selected } : product
      );
      setProducts(updatedProducts);
      setSelectedProducts(updatedProducts.filter((p) => p.selected).map((p) => p.id));
    },
    [products]
  );

  const handleFocusProduct = useCallback((productId) => {
    setFocusedProduct(productId);
  }, []);

  const applyUpdatedDescriptions = async () => {
    setLoading(true);
    setError('');
    setMessage('');

    try {
      const updatedData = [...data];

      products.forEach((product) => {
        if (product.selected && product.updatedDescription) {
          const index = product.id;
          updatedData[index] = {
            ...updatedData[index],
            Description: product.updatedDescription,
          };
        }
      });

      setData(updatedData);
      setMessage('説明文を正常に更新しました');
      setSnackbarMessage('説明文を正常に更新しました');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (err) {
      console.error('説明文更新エラー:', err);
      setError(`説明文の更新に失敗しました: ${err.message}`);
      setSnackbarMessage(`エラー: ${err.message}`);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const handlePreviewOpen = (product) => {
    setPreviewProduct(product);
    setShowPreviewDialog(true);
  };

  const handlePreviewClose = () => {
    setPreviewProduct(null);
    setShowPreviewDialog(false);
  };

  const handleEditFeatures = useCallback(
    (productId, newFeatures) => {
      setProducts((prevProducts) =>
        prevProducts.map((product) =>
          product.id === productId
            ? {
                ...product,
                editableGeneratedFeatures: newFeatures,
                updatedDescription: generateFormattedDescription(
                  product.title,
                  newFeatures,
                  sanitizeDescription(product.originalDescription),
                  product.specifications,
                  selectedTemplate
                ),
              }
            : product
        )
      );
    },
    [selectedTemplate]
  );

  const clearCache = () => {
    clearGeneratedDescriptions();
    setProducts((prevProducts) =>
      prevProducts.map((product) => ({
        ...product,
        generatedFeatures: '',
        editableGeneratedFeatures: '',
        updatedDescription: '',
        isUpdated: false,
      }))
    );
  };

  const resetSelectedProductsToInitialCSVData = () => {
    const initialCsvData = JSON.parse(localStorage.getItem('csvData'));

    if (initialCsvData && initialCsvData.length > 0) {
      const updatedData = [...data];

      const headers = Object.keys(initialCsvData[0]);
      const descriptionIndex = headers.findIndex((header) => header.toLowerCase() === 'description');

      if (descriptionIndex === -1) {
        setError('必要な列（Description）が見つかりません');
        return;
      }

      const updatedProducts = products.map((product) => {
        if (product.selected) {
          const index = product.id;
          const initialRow = initialCsvData[index];
          const originalDescription = initialRow['Description'] || initialRow['description'] || '';

          updatedData[index] = {
            ...updatedData[index],
            Description: originalDescription,
          };

          return {
            ...product,
            originalDescription: originalDescription,
            description: originalDescription,
            generatedFeatures: '',
            editableGeneratedFeatures: '',
            updatedDescription: '',
            isUpdated: false,
          };
        }
        return product;
      });

      setProducts(updatedProducts);
      setData(updatedData);

      clearGeneratedDescriptions(false);

      setSnackbarMessage('選択した商品の説明を初期値にリセットしました');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } else {
      setSnackbarMessage('初期データが見つかりません');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const clearGeneratedDescriptions = (showMessage = true) => {
    localStorage.removeItem('generatedDescriptions');
    if (showMessage) {
      setSnackbarMessage('生成済みの説明をクリアしました');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    }
  };

  const indexOfLastItem =
    currentPage * (itemsPerPage === 'All' ? products.length : itemsPerPage);
  const indexOfFirstItem = indexOfLastItem - (itemsPerPage === 'All' ? products.length : itemsPerPage);
  const currentProducts = products.slice(indexOfFirstItem, indexOfLastItem);

  const handleSelectAllOnPage = useCallback(
    (event) => {
      const isSelected = event.target.checked;
      const updatedProducts = products.map((product) => {
        if (currentProducts.some((p) => p.id === product.id)) {
          return { ...product, selected: isSelected };
        }
        return product;
      });
      setProducts(updatedProducts);
      setSelectedProducts(updatedProducts.filter((p) => p.selected).map((p) => p.id));
    },
    [products, currentProducts]
  );

  const handleEditDescription = (productId, newDescription) => {
    setProducts((prevProducts) =>
      prevProducts.map((p) =>
        p.id === productId ? { ...p, updatedDescription: newDescription } : p
      )
    );
  };

  const applyCustomTransformations = (text) => {
    let transformedText = text;

    sentencesToDelete.forEach((sentence) => {
      if (sentence.trim() !== '') {
        const escapedSentence = sentence.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
        const regex = new RegExp(escapedSentence, 'g');
        transformedText = transformedText.replace(regex, '');
      }
    });

    wordsToReplace.forEach(({ from, to }) => {
      if (from.trim() !== '') {
        const escapedFrom = from.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
        const regex = new RegExp(escapedFrom, 'g');
        transformedText = transformedText.replace(regex, to);
      }
    });

    return transformedText;
  };

  const applyTransformationsToSelectedProducts = () => {
    const updatedProducts = products.map((product) => {
      if (product.selected) {
        const transformedFeatures = applyCustomTransformations(product.editableGeneratedFeatures);
        const updatedDescription = generateFormattedDescription(
          product.title,
          transformedFeatures,
          sanitizeDescription(product.originalDescription),
          product.specifications,
          selectedTemplate
        );
        return {
          ...product,
          editableGeneratedFeatures: transformedFeatures,
          updatedDescription,
        };
      }
      return product;
    });
    setProducts(updatedProducts);
  };

  // LowCostAIGeneratorの機能を直接使用
  const handleLowCostAIGeneration = async () => {
    if (!apiKey) {
      setError('OpenAI APIキーが設定されていません');
      setSnackbarMessage('OpenAI APIキーが必要です');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      return;
    }

    if (selectedProducts.length === 0) {
      setError('少なくとも一つの商品を選択してください');
      setSnackbarMessage('商品が選択されていません');
      setSnackbarSeverity('warning');
      setSnackbarOpen(true);
      return;
    }

    setLowCostLoading(true);
    setError('');
    setMessage('');
    setProgress(0);

    try {
      await generateLowCostFeatures({
        products,
        setProducts,
        selectedProducts,
        apiKey,
        setSnackbarMessage,
        setSnackbarSeverity,
        setSnackbarOpen,
        setError,
        setMessage,
        setProgress,
        queue,
        selectedTemplate,
        applyCustomTransformations,
        sanitizeDescription,
        mandatoryKeywords,
      });
      setShowPreviewDialog(true);
    } catch (error) {
      console.error('低コストAI生成エラー:', error);
      setError(`低コストAI生成に失敗しました: ${error.message}`);
      setSnackbarMessage(`エラー: ${error.message}`);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } finally {
      setLowCostLoading(false);
    }
  };

  return (
    <Paper
      elevation={3}
      sx={{
        p: 4,
        mt: 1,
        mb: 1,
        maxWidth: '100%',
        margin: '0 auto',
        overflow: 'hidden',
      }}
    >
      <Box sx={{ maxWidth: '1600px', margin: '0 auto' }}>
        {/* ヘッダーセクション */}
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} md={6}>
            <Typography variant="h5" gutterBottom>
              AIを活用した商品説明生成
            </Typography>
            <Typography variant="body1" paragraph>
              日本語の商品説明から英語の商品特徴を自動生成します。
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" gutterBottom>
              主な機能：
            </Typography>
            <Grid container spacing={1}>
              {[
                '日本語の商品説明から英語の商品特徴を抽出',
                'デザインテンプレートからデザインを選択',
                '複数の商品を一括で処理',
                '説明のプレビューと編集',
              ].map((feature, index) => (
                <Grid item xs={6} key={index}>
                  <Typography variant="body2">• {feature}</Typography>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>

        {/* アクションボタンセクション */}
        <Box sx={{ mt: 3, display: 'flex', gap: '10px', justifyContent: 'center' }}>
          <Button variant="contained" color="primary" onClick={handleOpen} startIcon={<AddIcon />}>
            商品説明をAIで生成する
          </Button>
          <Button variant="outlined" color="primary" onClick={clearCache} startIcon={<CachedIcon />}>
            キャッシュをクリア
          </Button>
        </Box>

        {/* ダイアログコンポーネントをここで使用 */}
        <OpenAIDescriptionDialog
          open={open}
          handleClose={handleClose}
          products={products}
          setProducts={setProducts}
          data={data}
          setData={setData}
          apiKey={apiKey}
          user={user}
          selectedProducts={selectedProducts}
          setSelectedProducts={setSelectedProducts}
          selectedTemplate={selectedTemplate}
          setSelectedTemplate={setSelectedTemplate}
          loading={loading}
          setLoading={setLoading}
          error={error}
          setError={setError}
          message={message}
          setMessage={setMessage}
          generateProductFeatures={generateProductFeatures}
          applyUpdatedDescriptions={applyUpdatedDescriptions}
          handleSelectAll={handleSelectAll}
          handleSelectProduct={handleSelectProduct}
          handleFocusProduct={handleFocusProduct}
          focusedProduct={focusedProduct}
          setFocusedProduct={setFocusedProduct}
          handleEditJpDesc={handleEditJpDesc}
          handleEditDescription={handleEditDescription}
          handleEditFeatures={handleEditFeatures}
          handlePreviewOpen={handlePreviewOpen}
          currentProducts={currentProducts}
          itemsPerPage={itemsPerPage}
          setItemsPerPage={setItemsPerPage}
          itemsPerPageOptions={itemsPerPageOptions}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          handleSelectAllOnPage={handleSelectAllOnPage}
          applyCustomTransformations={applyCustomTransformations}
          sentencesToDelete={sentencesToDelete}
          setSentencesToDelete={setSentencesToDelete}
          wordsToReplace={wordsToReplace}
          setWordsToReplace={setWordsToReplace}
          selectedFilters={selectedFilters}
          setSelectedFilters={setSelectedFilters}
          handleFilterChange={handleFilterChange}
          customFilterInput={customFilterInput}
          setCustomFilterInput={setCustomFilterInput}
          customFilters={customFilters}
          setCustomFilters={setCustomFilters}
          handleAddCustomFilter={handleAddCustomFilter}
          handleDeleteCustomFilter={handleDeleteCustomFilter}
          progress={progress}
          lowCostLoading={lowCostLoading}
          handleLowCostAIGeneration={handleLowCostAIGeneration}
          setCustomPromptDialogOpen={setCustomPromptDialogOpen}
          customPromptDialogOpen={customPromptDialogOpen}
          setResetDialogOpen={setResetDialogOpen}
          resetDialogOpen={resetDialogOpen}
          resetSelectedProductsToInitialCSVData={resetSelectedProductsToInitialCSVData}
          queue={queue}
          applyTransformationsToSelectedProducts={applyTransformationsToSelectedProducts}
          clearCache={clearCache}
          previewProduct={previewProduct}
          setPreviewProduct={setPreviewProduct}
          showPreviewDialog={showPreviewDialog}
          setShowPreviewDialog={setShowPreviewDialog}
          handlePreviewClose={handlePreviewClose}
          snackbarOpen={snackbarOpen}
          setSnackbarOpen={setSnackbarOpen}
          snackbarMessage={snackbarMessage}
          snackbarSeverity={snackbarSeverity}
          setSnackbarMessage={setSnackbarMessage}
          setSnackbarSeverity={setSnackbarSeverity}
          setProgress={setProgress}
          mandatoryKeywords={mandatoryKeywords}
          setMandatoryKeywords={setMandatoryKeywords}
          useImageDescription={useImageDescription}
          setUseImageDescription={setUseImageDescription}
        />
      </Box>

      {/* スナックバー通知 */}
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Paper>
  );
};

export default OpenAIDescriptionGenerator;